import { NETWORK_RESTICTION_TYPES, NETWORK_TYPE } from '../../context/model';

export const maxBudgetOptions = [
  {
    label: 'No Maximum',
    value: 0,
  },
  {
    label: 'Up to $250',
    value: 250,
  },
  {
    label: 'Up to $1,000',
    value: 1000,
  },
  {
    label: 'Up to $2,500',
    value: 2500,
  },
  {
    label: 'Up to $10,000',
    value: 10000,
  },
];

export const networkSliders = {
  [NETWORK_TYPE.INSTAGRAM]: [
    {
      title: 'Likes per post',
      min: 0,
      max: 50000,
      key: NETWORK_RESTICTION_TYPES.ENGAGEMENTS,
    },
    {
      title: 'Followers',
      min: 1000,
      max: 1000000,
      key: NETWORK_RESTICTION_TYPES.FOLLOWERS,
    },
    {
      title: 'Audience Authenticity',
      min: 0,
      defaultMin: 70,
      max: 85,
      key: NETWORK_RESTICTION_TYPES.AUTHENTICITY,
      isPercentSlider: true,
      minOnly: true,
      tooltip: 'Audience authenticity algorithmically determines what % of an influencer\'s following '
       + 'appears legitimate. Please note that many influencers will accumulate some fake followers '
       + 'through no fault of their own, and a 70+ rating is typical.',
    },
    {
      title: 'Engagement Ratio %',
      min: 0,
      max: 5,
      step: 0.1,
      key: NETWORK_RESTICTION_TYPES.ENGAGEMENT_RATIO,
      isPercentSlider: true,
      minOnly: true,
      tooltip: 'Require a minimum ratio of likes & comments over followers. Please '
       + 'note that a median engagement ratio is about 2.5%, so setting higher thresholds'
       + 'may severely limit your project\'s exposure.',
    },
  ],
  [NETWORK_TYPE.YOUTUBE]: [
    {
      title: 'Views per video',
      min: 0,
      max: 50000,
      key: NETWORK_RESTICTION_TYPES.ENGAGEMENTS,
    },
    {
      title: 'Subscribers',
      min: 1000,
      max: 1000000,
      key: NETWORK_RESTICTION_TYPES.FOLLOWERS,
    },
  ],
  [NETWORK_TYPE.TWITTER]: [
    {
      title: 'Followers',
      min: 0,
      max: 1000000,
      key: NETWORK_RESTICTION_TYPES.FOLLOWERS,
    },
  ],
  [NETWORK_TYPE.FACEBOOK]: [
    {
      title: 'Likes',
      min: 0,
      max: 1000000,
      key: NETWORK_RESTICTION_TYPES.ENGAGEMENTS,
    },
  ],
  [NETWORK_TYPE.TIKTOK]: [
    {
      title: 'Followers',
      min: 1000,
      defaultMin: 1000,
      max: 1000000,
      key: NETWORK_RESTICTION_TYPES.FOLLOWERS,
    },
    {
      title: 'Views per video',
      min: 0,
      defaultMin: 0,
      max: 1000000,
      key: NETWORK_RESTICTION_TYPES.VIEWS,
    },
  ],
};
