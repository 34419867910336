import * as React from 'react';
import { map, isFunction } from 'lodash';

import { useElementsVisible, useScrollableStatus } from '@frontend/utils';

const { useEffect, useRef } = React;

export interface IInfiniteListItemProps {
  visible: boolean;
}

interface IInfiniteListProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/no-unused-prop-types
  children: Array<React.ReactElement<any>>;

  // eslint-disable-next-line react/no-unused-prop-types
  passVisibleInfo?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  onReachedBottom?(): void;
}

/**
 * @type {React.RefForwardingComponent}
 */
export const InfiniteList: React.RefForwardingComponent<HTMLDivElement, IInfiniteListProps> = React.memo(
  React.forwardRef((props: IInfiniteListProps, forwardedRef: React.RefObject<HTMLDivElement>) => {
    const {
      children,
      onReachedBottom,
      passVisibleInfo = true,
      ...restProps
    } = props;
    const containerRef = forwardedRef || useRef<HTMLDivElement>(null);

    // notifies parent if scrolled to bottom
    const { canScrollDown } = useScrollableStatus(containerRef);
    useEffect(() => {
      if (canScrollDown === false && isFunction(onReachedBottom)) {
        onReachedBottom();
      }
    }, [canScrollDown, onReachedBottom]);

    const itemList = containerRef.current && containerRef.current.children;
    // children is needed for updated info, because itemList is ref to the container,
    // and InfiniteList is mounted before parent, and itemList is not updated at that time.
    const itemsVisible = useElementsVisible(itemList, [children]);

    return (
      <div ref={containerRef} {...restProps}>
        {map(props.children, (item, index) => (
          <div key={index} data-key={index}>
            {React.cloneElement(item, {
              visible: passVisibleInfo ? itemsVisible[index] : undefined,
            })}
          </div>
        ))}
      </div>
    );
  }),
);

InfiniteList.displayName = 'InfiniteList';
