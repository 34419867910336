import * as React from 'react';
import moment from 'moment';

import {
  filter,
  isNil,
} from 'lodash';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';

import {
  useGetCampaignByProjectId,
  useSaveProjectCampaign,
} from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';

import {
  Alert,
  useBreakpoint,
} from '@revfluence/fresh';

import { useGetProjectLandingPageUrl } from '@frontend/app/containers/Onboarding/OnboardingWizard/hooks/useGetProjectLandingPageUrl';

import { TProject } from '../types';

import {
  ScreenSizes,
  ProjectStatus,
} from '../OverviewPage/Header/constants';

import styles from '../OverviewPage/OverviewPage.scss';

import { UtmSource } from './useMembershipSources';

const {
  useState,
  useMemo,
} = React;

export const useOverviewPage = (project?:TProject) => {
  const addEvent = useEventContext();
  const { getFullPageUrl } = useGetProjectLandingPageUrl();
  const [isDeactivatingCampaign, setDeactivatingCampaign] = useState<boolean>(false);
  const [isReactivatingCampaign, setReactivatingCampaign] = useState<boolean>(false);

  const {
    data: {
      campaign = null,
    } = {},
    refetch: refetchCampaign,
  } = useGetCampaignByProjectId(project?.id, {
    onError: (error) => {
      showError(error);
    },
    skip: !project?.id,
  });

  const {
    showError,
    showSuccessMessage,
  } = useMessagingContext();
  const [saveProjectCampaign] = useSaveProjectCampaign({
    onCompleted: async () => {
      if (isReactivatingCampaign) {
        showSuccessMessage(
          'Your project has been added to Aspire Creator Marketplace',
        );
      }

      if (isDeactivatingCampaign) {
        showSuccessMessage(
          'Your project has been removed from Aspire Creator Marketplace',
        );
      }

      setDeactivatingCampaign(false);
      setReactivatingCampaign(false);
    },
    onError: (error) => {
      setDeactivatingCampaign(false);
      setReactivatingCampaign(false);
      showError(error);
    },
  });

  const handleReactivateCampaign = async () => {
    setReactivatingCampaign(true);
    try {
      addEvent(
        EventName.MarketplaceReactivate,
        {
          source: 'Relist',
          project_id: project.id,
          project_name: project.title,
        },
      );

      let listingUrl = campaign?.external_listing_url;
      if (!listingUrl) {
        listingUrl = getFullPageUrl(project.customLandingPagePath, UtmSource.Marketplace);
      }

      await saveProjectCampaign({
        variables: {
          projectId: project.id,
          campaign: {
            status: 'PUBLISH',
            external_listing_url: listingUrl,
          },
        },
      });
      await refetchCampaign();
    } catch (error) {
      showError(error);
    }
  };

  const handleStartMarketplace = () => {
    addEvent(
      EventName.MarketplacePublishStart,
      {
        source: 'Start',
        project_id: project.id,
        project_name: project.title,
      },
    );
  };

  const handleCheckScreenSize = () => {
    const {
      ExtraSmall,
      Small,
      Medium,
      Large,
      ExtraLarge,
      ExtraExtralarge,
    } = ScreenSizes;

    const screens = useBreakpoint();
    const currentBreakpoints = Object
      .entries(screens)
      .filter((screen) => !!screen[1])
      .map((screen) => screen[0]);

    const isMobile = useMemo(() => (
      filter(
        currentBreakpoints,
        (breakpoint:ScreenSizes) => (
          [
            ExtraSmall,
            Small,
            Medium,
          ].includes(breakpoint)
        ),
      ) && !currentBreakpoints.includes(Large)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [currentBreakpoints]);

    const isWide = useMemo(() => (
      filter(
        currentBreakpoints,
        (breakpoint:ScreenSizes) => [
          Large,
          ExtraLarge,
          ExtraExtralarge,
        ].includes(breakpoint),
      ) && !currentBreakpoints.includes(Medium)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [currentBreakpoints]);

    return {
      isMobile,
      isWide,
      currentBreakpoints,
    };
  };

  const renderArchivedNotice = React.useMemo(() => {
    if (project?.status === ProjectStatus.Archived
      && !isNil(project.archivedByUser)
      && !isNil(project.archivedDate)
    ) {
      return (
        <div className={styles.archivedNotice}>
          <Alert
            message={`This project was archived on ${moment(project?.archivedDate).format('MM/DD/YY')} by ${project?.archivedByUser?.name}. All project insights are still available but you can no longer add new members.`}
            type="warning"
          />
        </div>
      );
    }
  }, [project]);

  return {
    handleReactivateCampaign,
    handleStartMarketplace,
    handleCheckScreenSize,
    renderArchivedNotice,
  };
};
