import * as React from 'react';
import { Typography, Badge, Button } from '@revfluence/fresh';
import { Link } from 'react-router-dom';

import { useOverviewPage } from '@frontend/app/containers/Projects/hooks';

import styles from './Status.module.scss';

import { STATUS } from '../../constants';

import { ProjectMarketplaceStatus } from '../../constants';

const { useMemo } = React;
const { Text } = Typography;

type Props = {
  status: string;
  date?: string;
  url?: string;
  handleCTAClick?(): void;
};

const Status: React.FC<Props> = React.memo(({
 status, date, url, handleCTAClick,
}) => {
  const {
 message, messageResponsive, color, cta,
} = useMemo(() => {
    if (status) return STATUS[status];
    return {
      message: '',
      messageResponsive: '',
      color: '',
    };
  }, [status]);

  const { handleCheckScreenSize } = useOverviewPage();

  const { isMobile } = handleCheckScreenSize();

  return (
    <Text className={styles.statusWrapper}>
      <Badge color={color} className={styles.badgeIcon} />
      <Text style={{ color }}>
        {isMobile ? messageResponsive : message}
        {
          [
            ProjectMarketplaceStatus.AutoUnlistedFromCreatorMarketplace,
            ProjectMarketplaceStatus.UserUnlistedFromCreatorMarketplace,
          ].includes(status as ProjectMarketplaceStatus) && date
        }
        {' '}
      </Text>
      {!!cta?.label && (
        <Button className={styles.buttonWrapper} type="link" onClick={handleCTAClick}>
          {url && <Link to={url}>{cta.label}</Link>}
          {!url && cta.label}
        </Button>
      )}
    </Text>
  );
});

Status.displayName = 'Status';

export default Status;
