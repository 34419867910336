import { Moment } from 'moment';

import {
  OFFER_CONVERSION_TYPE,
  OFFER_PAYOUT_TYPE_PROMO,
  OFFER_PAYOUT_TYPE,
  OFFER_PRICE_RULE_TYPE,
  OFFER_PROMO_USAGE_LIMIT_RULE,
  OFFER_SOURCE,
  OFFER_STATUS,
  OFFER_TRACKING_TYPE,
  CLIENT_CONNECTION_STATUS,
} from '@affiliates/types/globalTypes';
import { GraphQLObjectType } from 'graphql/type';
import { GetConnectedShopify_clientConnections } from '../../queries/types/GetConnectedShopify';
import { OFFER_FORM_LOCKING_KEY } from '../../types';

export interface ICommonFormValues {
  // conversionType is specific to affiliate links, but used in common section.
  conversionType?: OFFER_CONVERSION_TYPE | null;
  description: string;
  flatPayout: string | null;
  imageUrl: string | null;
  payoutType: OFFER_PAYOUT_TYPE | OFFER_PAYOUT_TYPE_PROMO | null;
  percentPayout: string | null;
  name: string;
  payoutOptions?: IPayoutVariant[] | null;
}

export interface IPayoutVariant {
  id?: number;
  label: string;
  payoutType: string;
  flatPayout?: number;
  percentPayout?: number;
  isDefault?: boolean;
  isMemberAdded: boolean;
  createdDate?: Date;
}

export interface MultiplePayoutAsyncActionPayload {
  index: number;
  payoutType?: OFFER_PAYOUT_TYPE;
}
export interface UpdateFieldAsyncActionPayload {
  key: string;
  value: string | number | boolean;
}
export interface MultipleDiscountCheckboxAsyncActionPayload {
  advertiserId: number;
  isSelected: boolean;
}
export interface FormAsyncAction {
  action: string;
  payload: MultiplePayoutAsyncActionPayload | UpdateFieldAsyncActionPayload | MultipleDiscountCheckboxAsyncActionPayload;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
export interface IAffiliateLinksFormValues extends IUTMFields {
  conversionTrackingType: OFFER_TRACKING_TYPE | null;
  expirationDate: Moment;
  payoutType: OFFER_PAYOUT_TYPE | null;
  source: OFFER_SOURCE.TUNE;
  status: OFFER_STATUS;
  url: string;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  isAdvanceUrlEnable?: boolean,
  multipleDomain?: string[],
  creatorDeeplink?: boolean,
}

export interface CustomUTMField {
  key: string;
  value: string;
}
export interface IUTMFields extends ICommonFormValues {
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  utmTerm?: string
  utmContent?: string
  customUTMParameters?: CustomUTMField[]
  utmFields?: GraphQLObjectType
}

export interface AdvanceUrlSettingValues extends ICommonFormValues {
  name: string;
  advanceUrlSettingType: string;
}

export enum PrefixTypeStrategy {
  FIRST_INITIAL_LAST_NAME_ONLY = 'first initial + last name',
  FIRST_INITIAL_LAST_NAME_PLUS_SUFFIX = 'first initial + last name + suffix',
  FIRST_NAME_LAST_INITIAL_ONLY = 'first name + last initial',
  FIRST_NAME_LAST_INITIAL_PLUS_SUFFIX = 'first name + last initial + suffix',
  FULL_NAME_ONLY = 'full name',
  FULL_NAME_PLUS_SUFFIX = 'full name + suffix',
  INSTAGRAM_ONLY = 'instagram',
  INSTAGRAM_PLUS_SUFFIX = 'instagram + suffix',
}
export enum RecurringCycleLimit {
  LIMIT_DISCOUNT_TO_THE_FIRST_PAYMENT = 'Limit discount to the first payment',
  LIMIT_DISCOUNT_TO_MULTIPLE_RECURRING_PAYMENTS = 'Limit discount to multiple recurring payments',
  DISCOUNT_APPLIES_TO_ALL_RECURRING_PAYMENTS = 'Discount applies to all recurring payments',
}

export enum PurchaseType {
  ONE_TIME = 'One-time',
  SUBSCRIPTION = 'Subscription',
  BOTH = 'Both',
}
export interface IShopifyPromoCodeFormValues extends ICommonFormValues {
  codeSuffix: string;
  groupName: string;
  payoutType: OFFER_PAYOUT_TYPE_PROMO | null;
  prefixType: PrefixTypeStrategy | null;
  priceRuleAmount: string | null;
  priceRuleType: OFFER_PRICE_RULE_TYPE | null;
  source: OFFER_SOURCE.SHOPIFY;
  specialLimitNewCustomersOnly: boolean;
  specialLimitOnePerSale: boolean;
  specialLimitUsageCapAmount: string | null;
  specialLimitUsageCapEnabled: boolean;
  usageLimitAmount: string | null;
  usageLimitRule: OFFER_PROMO_USAGE_LIMIT_RULE | null;
  productCollections?: number[] | null; // string of a json.stringified array of shopify collections
  codePrefix?: string
  purchaseType?: string
  status: OFFER_STATUS;
  activeTime: Moment;
  activeDate: Moment;
  endDate: Moment | null;
  endTime: Moment | null;
  offerCodePurchaseRestrictionsRule?: string;
  recurringCycleLimitAmount: number | null;
  isNewFlow: boolean;
  isEndDateEnable?: boolean | false;
  isPrefixSelected?: boolean | false;
  isSuffixSelected?: boolean | false;
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  isMultipleShopifySyncEnabled: boolean;
  multiShopifyEnabled: boolean | false;
  isSameDiscountMultipleShopify: boolean;
  clientsForSync: ClientForSync[];
  connectedAdvertiserForSync: GetConnectedShopify_clientConnections[];
  allClientForSync: ClientForSync[];
  isUngrouped: boolean;
  lockEditing: OFFER_FORM_LOCKING_KEY[];
}
export interface IPayoutOption {
  payoutType: string
  payoutName: string
  payoutValue: string
}
export interface ProductCollections {
  id: number;
  title: string;
}
export interface IShopifyPromoCodeFormOptions {
  productCollectionOptions?: ProductCollections[] | null; // is null when feature is off
}
export type TFormOptionData = IShopifyPromoCodeFormOptions;

export type TFormValues = IAffiliateLinksFormValues | IShopifyPromoCodeFormValues;

export type TDisabledMap<Keys extends TFormValues> = Required<{
  [key in keyof Omit<Keys, 'source'>]: boolean;
}>;

export enum OfferFormModes {
  Create = 'Create',
  Edit = 'Edit',
  Reactivate = 'Reactivate',
}

export interface ICommonFormElementProps<Name extends keyof ICommonFormValues> {
  disabled: boolean;
  name: Name;
}

export interface IShopifyFormElementProps<Name extends keyof IShopifyPromoCodeFormValues> {
  disabled: boolean;
  name: Name;
}

export interface IAffiliateLinksFormElementProps<Name extends keyof IAffiliateLinksFormValues> {
  disabled: boolean;
  name: Name;
}
export interface AdvanceUrlSettingProps<Name extends keyof AdvanceUrlSettingValues> {
  disabled: boolean;
  name: Name;
  utmSource: string,
  utmMedium: string,
  utmCampaign: string,
  utmTerm: string,
  utmContent: string
  CustomTypeFirst: string
  CustomtTypeSecond: string
  CustomTypeThird: string
  CustomValueFirst: string
  CustomValueSecond: string
  CustomValueThird: string
  isAdvanceUrlEnable?: boolean,
  utmFields?: GraphQLObjectType
}

export interface UTMFieldsProps<Name extends keyof AdvanceUrlSettingValues> {
  name: Name,
  keyName: string,
  label: string
  disabled: boolean;
  required?: boolean
}

export interface UTMCustomFieldsProps<Name extends keyof AdvanceUrlSettingValues> {
  name: Name,
  customName: number,
  key: number,
  disabled: boolean,
  index: number,
  remove: (index: number | number[]) => void;
}

export interface ClientForSync {
  advertiserId: number;
  priceRuleAmount?: number | null;
  priceRuleType?: OFFER_PRICE_RULE_TYPE | null;
  status?: CLIENT_CONNECTION_STATUS;
  isSelected?: boolean;
}

export enum OfferFormAsyncActions {
  DEFAULT_PAYOUT = 'DefaultPayout',
  PAYOUT_TYPE = 'PayoutType',
  UPDATE_FIELD = 'UpdateField',
  MULTIPLE_STORE_SELECT = 'MultipleStoreSelect',
}
