import * as React from 'react';
import {
  Alert,
  Button,
} from '@revfluence/fresh';
import {
  PlusIcon,
  UserPlusIcon,
} from '@revfluence/fresh-icons/regular/esm';
import {
  filter,
  findIndex,
  isNumber,
  map,
  orderBy,
} from 'lodash';
import { useHistory } from 'react-router-dom';

import { ContentCard } from '@frontend/app/components/ContentCard/ContentCard';
import { LoadingCard } from '@frontend/app/components/ContentCard/LoadingCard';
import {
  useCommunitiesQuery,
  useMemberSearchCountQuery,
} from '@frontend/app/hooks';
import { IHomePageSection } from '../types';

const FALLBACK_GROUP_URL = 'https://storage.googleapis.com/aspirex-static-files/home/groups.png';
const FALLBACK_FAVORITES_URL = 'https://storage.googleapis.com/aspirex-static-files/home/favorites.png';

export const useHomePageGroups = (
  socialDiscoveryAppId: string,
): IHomePageSection => {
  const groupsQuery = useCommunitiesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const retryGroupsQuery = React.useCallback(() => {
    groupsQuery.refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsQuery.refetch]);

  const allContactCountQuery = useMemberSearchCountQuery({
    variables: {
      query: {
        communityId: null,
        isContact: true,
      },
    },
  });

  const history = useHistory();
  const goToNewGroup = React.useCallback((e) => {
    e.preventDefault();
    history.push('communities/new');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const goToFindCreators = React.useCallback((e) => {
    e.preventDefault();
    history.push(`app/${socialDiscoveryAppId}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialDiscoveryAppId]);

  return React.useMemo((): IHomePageSection => {
    const actions = [(
      <Button
        key="find_creators"
        href={`app/${encodeURIComponent(socialDiscoveryAppId)}`}
        onClick={goToFindCreators}
        type="default"
        style={{
          fontWeight: 'normal',
        }}
      >
        <UserPlusIcon />
        <span>
          Find Creators
        </span>
      </Button>
    ), (
      <Button
        key="new_group"
        href="communities/new"
        onClick={goToNewGroup}
        type="default"
        style={{
          fontWeight: 'normal',
        }}
      >
        <PlusIcon />
        <span>
          New Group
        </span>
      </Button>
    )];
    const title = 'Groups';

    if (groupsQuery.loading && !groupsQuery.data) {
      return {
        actions,
        cards: [
          <LoadingCard key="group_loading_1" size="small" />,
          <LoadingCard key="group_loading_2" size="small" />,
          <LoadingCard key="group_loading_3" size="small" />,
          <LoadingCard key="group_loading_4" size="small" />,
          <LoadingCard key="group_loading_5" size="small" />,
          <LoadingCard key="group_loading_6" size="small" />,
        ],
        title,
      };
    }

    if (groupsQuery.error) {
      return {
        actions,
        cards: [],
        error: (
          <Alert
            action={(
              <Button
                onClick={retryGroupsQuery}
                size="small"
                type="ghost"
              >
                Retry
              </Button>
            )}
            message="Oops! Something went wrong fetching your list of groups."
            showIcon
            type="error"
          />
        ),
        title,
      };
    }

    const { communities } = groupsQuery.data;

    const myFavoritesIndex = findIndex(communities, { title: 'My Favorites' });
    const favoriteGroup = myFavoritesIndex >= 0 ? communities[myFavoritesIndex] : null;
    const orderedGroups = orderBy(
      filter(communities, (_, idx) => idx !== myFavoritesIndex),
      ['memberCount', 'title', 'id'],
      ['desc', 'asc', 'asc'],
    );

    let allContactsDescription = '';
    if (!allContactCountQuery.loading && !allContactCountQuery.error && isNumber(allContactCountQuery.data?.count)) {
      const { data: { count } } = allContactCountQuery;
      if (count === 1) {
        allContactsDescription = '1 Member';
      } else if (count < 1000) {
        allContactsDescription = `${count} Members`;
      } else {
        const roundedCount = Math.round(count / 100);
        if (roundedCount % 10 === 0) {
          allContactsDescription = `${(roundedCount / 10).toFixed(0)}K Members`;
        } else {
          allContactsDescription = `${(roundedCount / 10).toFixed(1)}K Members`;
        }
      }
    }

    const cards = [(
      <ContentCard
        cardId="group-all-contacts"
        description={allContactsDescription}
        href="member_table?communityId="
        key="all_contacts_group"
        image={{
          fallbackUrl: FALLBACK_GROUP_URL,
          title: 'All Contacts',
          url: FALLBACK_GROUP_URL,
        }}
        notificationCount={0}
        settingsHref="communities/all_contacts/settings"
        size="small"
        title="All Contacts"
      />
    )];
    if (favoriteGroup) {
      cards.push(
        <ContentCard
          cardId={`group-${favoriteGroup.id}`}
          description={
            favoriteGroup.memberCount === 1
              ? '1 Member'
              : `${favoriteGroup.memberCount} Members`
          }
          href={`member_table?communityId=${encodeURIComponent(favoriteGroup.id)}`}
          key={favoriteGroup.id.toString()}
          image={{
            fallbackUrl: FALLBACK_FAVORITES_URL,
            title: 'Favorites',
            url: favoriteGroup.splashImageUrl || FALLBACK_FAVORITES_URL,
          }}
          notificationCount={0}
          settingsHref={`communities/${encodeURIComponent(favoriteGroup.id)}/edit`}
          size="small"
          title="Favorites"
        />,
      );
    }

    return {
      actions,
      cards: cards.concat(map(orderedGroups, (group) => {
        const {
          id,
          memberCount,
          splashImageUrl,
          title,
        } = group;

        let image = {
          fallbackUrl: FALLBACK_GROUP_URL,
          title,
          url: 'https://www.example.com/fallback',
        };
        if (splashImageUrl) {
          image = {
            fallbackUrl: FALLBACK_GROUP_URL,
            title,
            url: splashImageUrl,
          };
        }

        let description = '1 Member';
        if (memberCount !== 1) {
          description = `${memberCount.toLocaleString()} Members`;
        }

        return (
          <ContentCard
            cardId={`group-${id}`}
            description={description}
            href={`member_table?communityId=${encodeURIComponent(id)}`}
            key={id.toString()}
            image={image}
            notificationCount={0}
            settingsHref={`communities/${encodeURIComponent(id)}/edit`}
            size="small"
            title={title}
          />
        );
      })),
      title,
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allContactCountQuery,
    groupsQuery,
  ]);
};
