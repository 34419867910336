import * as React from 'react';
import {
 Card, Divider, Tag, Typography,
} from '@revfluence/fresh';
import { CoinsIcon } from '@revfluence/fresh-icons/regular/esm';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { IPaymentSources } from '@frontend/applications/PaymentsApp/models';
import { DataFormat, formatValue } from '@frontend/applications/AffiliatesApp/utils';
import { TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import { getCardIcon } from '@frontend/applications/PaymentsApp/utils/getCardIcon';
import { PaymentCreationSource } from '@frontend/applications/PaymentsApp/types';
import styles from './PaymentDetail.scss';
import { SectionHeader } from '../SectionHeader';
import { SubItemSmall } from '../SubItemSmall';
import { SubItemLarge } from '../SubItemLarge';

const { useMemo } = React;
const { Text } = Typography;
interface IProps {
  amountPaying: number;
  brief?: IAgreement;
  paymentSources?: IPaymentSources;
  state: TState;
}

export const PaymentDetail: React.FC<IProps> = (props) => {
  const {
 amountPaying, brief, paymentSources, state,
} = props;
  const availableBalance = paymentSources?.balance_in_cents ? paymentSources.balance_in_cents / 100 : 0;
  const paymentRequiredThroughCard = availableBalance - state.amountPaying >= 0 ? 0 : Math.abs(availableBalance - state.amountPaying);
  const paymentFromBalance = state.amountPaying - paymentRequiredThroughCard;
  const selectedCard = useMemo(() => {
    if (state.selectedCardId) {
      return state?.paymentSource?.cards?.find((card) => card.id === state.selectedCardId);
    }
    return null;
  }, [state]);
  return (
    <div className={styles.paymentDetailContainer}>
      <SectionHeader
        title="Payment Details"
        sectionIcon={<CoinsIcon />}
        tooltipMessage="Payment Details"
        rightContect={
          state.paymentCreationSource === PaymentCreationSource.REASSIGN ? null : (
            <Tag color="success">{`Available Balance: $${formatValue(DataFormat.Money, availableBalance)} USD`}</Tag>
          )
        }
      />
      {state.paymentCreationSource === PaymentCreationSource.REASSIGN ? (
        <Card className={styles.paymentCard}>
          <SubItemLarge name="Total Payment" value={`$${formatValue(DataFormat.Money, amountPaying)} USD`} />
        </Card>
      ) : (
        <Card className={styles.paymentCard}>
          <div className={styles.mainCharge}>
            <SubItemSmall name="Amount to Pay" value={`$${formatValue(DataFormat.Money, amountPaying)} USD`} />
            {brief && <SubItemSmall name="Remaining Due Amount" value="$20.00 USD" />}
          </div>
          <Divider dashed className={styles.divider} />
          {selectedCard && (
            <>
              <div className={styles.cardCharge}>
                <SubItemSmall
                  name="Payment From Available Balance"
                  value={`$${formatValue(DataFormat.Money, paymentFromBalance)} USD`}
                />
                <SubItemSmall
                  name="Charge Remaining Payment to "
                  value={(
                    <div>
                      <img src={getCardIcon(selectedCard.brand)} className={styles.cardIcon} />
                      {' '}
                      {`${selectedCard.brand} (${selectedCard.last4})`}
                    </div>
                  )}
                />
              </div>
              <Divider dashed className={styles.divider} />
              <div className={styles.cardCharge}>
                <SubItemSmall
                  name="Amount to Charge to Card"
                  value={`$${formatValue(DataFormat.Money, paymentRequiredThroughCard)} USD`}
                />
                <SubItemSmall
                  name="Stripe card transaction fee (3%)"
                  value={`$${formatValue(DataFormat.Money, paymentRequiredThroughCard * 0.03)} USD`}
                  tooltipMessage="A 3% processing fee applies to each transaction via Stripe."
                />
                {state.requireHandlingFee && (
                  <SubItemSmall
                    name="Handling fee (2%)"
                    value={`$${formatValue(DataFormat.Money, paymentRequiredThroughCard * 0.02)} USD`}
                    tooltipMessage="A 2% handling fee will be levied on each transaction"
                  />
                )}
                <SubItemSmall
                  name="Total Payment From Card"
                  value={`$${formatValue(
                    DataFormat.Money,
                    paymentRequiredThroughCard
                      + paymentRequiredThroughCard * 0.03
                      + (state.requireHandlingFee ? paymentRequiredThroughCard * 0.02 : 0),
                  )} USD`}
                />
              </div>
              <Divider dashed className={styles.divider} />
            </>
          )}
          <div className={styles.finalSettlement}>
            <SubItemLarge
              name="Total Payment"
              value={`$${formatValue(
                DataFormat.Money,
                paymentFromBalance
                  + paymentRequiredThroughCard
                  + paymentRequiredThroughCard * 0.03
                  + (state.requireHandlingFee ? paymentRequiredThroughCard * 0.02 : 0),
              )} USD`}
            />
            <div className={styles.subMessage}>
              <Text className={styles.message}>
                Paying
                {` $${formatValue(DataFormat.Money, paymentFromBalance)}`}
                {' '}
                USD from available balance.
              </Text>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
};
