/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_wn47v_157 {
  display: flex;
}

._justify-content-space-between_wn47v_161 {
  justify-content: space-between;
}

._tabular-nums_wn47v_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_wn47v_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._table_wn47v_178 .ant-table-thead .ant-table-cell {
  background-color: var(--white);
  font-size: var(--font-size-sm);
  color: var(--gray-7);
}
._table_wn47v_178 .ant-table-thead .ant-table-cell::before {
  display: none;
}
._table_wn47v_178 .ant-table-cell-fix-right {
  box-shadow: none !important;
}
._table_wn47v_178 .ant-table-row-level-0 .ant-table-cell-fix-left {
  display: flex;
  align-items: center;
  background: white;
}
._table_wn47v_178 .ant-table-cell.ant-table-cell-with-append .ant-table-row-expand-icon {
  margin-top: var(--spacing-xs);
}
._table_wn47v_178 .ant-table-cell .ant-space-item .ant-image {
  height: 100%;
  width: 100%;
}
._table_wn47v_178 .ant-table-cell .ant-space-item .ant-image img {
  height: 100%;
  object-fit: cover;
}
._table_wn47v_178 ._deliverables_wn47v_205 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
._table_wn47v_178 ._status_wn47v_211 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

._ContentCell_wn47v_218 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._ContentCell_wn47v_218 ._icon_wn47v_223 {
  font-size: 18px;
}
._ContentCell_wn47v_218 ._link_wn47v_226 {
  padding: 4px 8px;
}

._OverlayContent_wn47v_230 {
  padding: 12px;
  overflow: hidden;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
._OverlayContent_wn47v_230 ._image_wn47v_239, ._OverlayContent_wn47v_230 ._video_wn47v_239 {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center center;
}

._nameColumn_wn47v_246 {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-left: 0.75rem;
}

._expandButton_wn47v_253 {
  box-shadow: none;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  margin-right: 1.5rem;
  align-items: center;
  justify-content: center;
}