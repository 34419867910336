/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { isEmpty, map, trim } from 'lodash';
import moment from 'moment';

import {
 Space, Typography, Button, Input, Spinner, Select, DatePicker, Tooltip,
} from '@revfluence/fresh';
import { PencilIcon, XmarkIcon, CheckIcon } from '@revfluence/fresh-icons/solid/esm';
import { CopyIcon } from '@revfluence/fresh-icons/regular/esm';
import { utcToLocal } from '@frontend/app/utils';
import { CustomFieldType } from '@services/communities/types/CustomFieldType';
import RenderedValue from './RenderedValue';

import styles from './FieldRow.scss';

const { useMemo, useState, useEffect } = React;
const { Text } = Typography;
const { Option } = Select;

const toBoolean = (val: string) => (val === 'true' ? true : val === 'false' ? false : null);

const toValue = (val: any, type: string) => {
  if (type === 'DATE' || type === 'ANNUAL') {
    return val ? (val as moment.Moment).toISOString() : '';
  } else if (type === 'BOOLEAN') {
    return val ? toBoolean(val) : null;
  } else if (typeof val === 'string') {
    return isEmpty(val) ? '' : trim(val);
  }

  return val;
};

const fromValue = (val: any, type: string) => {
  if (type === 'BOOLEAN') {
    return `${val ?? ''}`;
  } else if (type === 'DATE' || type === 'ANNUAL') {
    if (!val) {
      return '';
    }

    const d = utcToLocal(new Date(val as string));
    return moment(d);
  }

  return val;
};

interface Props {
  direction?: 'vertical' | 'horizontal';
  label: string;
  type?: string;
  choices?: string[];
  value: string;
  editable?: boolean;
  copyable?: boolean;
  editing?: boolean;
  loading?: boolean;
  hideActions?: boolean;
  renderLabel?: (name: string) => JSX.Element;
  renderValue?: (val: string) => JSX.Element;
  onSave?: (value: string) => Promise<void>;
  onUpdate?: (value: string) => void;
}

const FieldRow = React.memo((props: Props) => {
  const {
    direction,
    label,
    renderLabel,
    value,
    renderValue,
    editable,
    type,
    choices,
    loading,
    copyable,
    onSave,
    hideActions,
    onUpdate,
  } = props;

  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [inputVal, setInputValue] = useState<any>();

  const isEditing = editing || props.editing;

  const renderedLabel = useMemo(() => {
    if (renderLabel) {
      return renderLabel(label);
    }

    return (
      <Text type="secondary" ellipsis>
        {label}
      </Text>
    );
  }, [label, renderLabel]);

  const handleSave = async () => {
    if (!onSave) {
      return;
    }

    setEditing(false);
    setSaving(true);
    try {
      await onSave(toValue(inputVal, type));
    } catch {
      setInputValue(fromValue(value, type));
    }

    setSaving(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUpdate = (val: any) => {
    setInputValue(val);
    onUpdate && onUpdate(toValue(val, type));
  };

  const renderEditableField = () => {
    if (type === 'BOOLEAN') {
      return (
        <Select style={{ width: 80 }} value={inputVal} onChange={handleUpdate}>
          <Option value="">N/A</Option>
          <Option value="true">Yes</Option>
          <Option value="false">No</Option>
        </Select>
      );
    } else if (type === 'DATE' || type === 'ANNUAL') {
      return (
        <DatePicker
          onChange={handleUpdate}
          defaultValue={inputVal}
          format="MM/DD/YYYY"
        />
      );
    } else if (choices) {
      return (
        <Select
          style={{ width: '100%' }}
          mode={type === 'ARRAY' ? 'multiple' : undefined}
          value={inputVal || []}
          onChange={handleUpdate}
        >
          {map(choices, (choice) => (
            <Option key={choice} value={choice}>
              {choice}
            </Option>
          ))}
        </Select>
      );
    }

    return (
      <Input
        value={inputVal}
        type={type === 'NUMBER' || type === 'CURRENCY' ? 'number' : 'text'}
        onPressEnter={handleSave}
        onChange={(ev) => {
          handleUpdate(ev.target.value);
        }}
      />
    );
  };

  useEffect(() => {
    setInputValue(fromValue(value, type));
  }, [value, type]);

  return (
    <Space className={styles.row} direction={direction || 'vertical'} size={direction === 'horizontal' ? 'small' : 0}>
      {renderedLabel}
      <div className={styles.value}>
        {isEditing ? (
          renderEditableField()
        ) : (
          <>
            <RenderedValue value={inputVal} type={type as CustomFieldType} renderValue={renderValue} />
            {loading || saving ? (
              <Spinner />
            ) : editable ? (
              <Button
                className={styles.edit}
                type="link"
                size="small"
                icon={<PencilIcon />}
                onClick={() => {
                  setEditing(true);
                }}
              />
            ) : null}
          </>
        )}
      </div>
      {!saving && !isEditing && copyable && value && (
        <Tooltip title="Copy">
          <Button
            icon={<CopyIcon />}
            size="small"
            onClick={() => {
              navigator.clipboard.writeText(value);
            }}
          />
        </Tooltip>
      )}
      {isEditing && !saving && !hideActions && (
        <div className={styles.actions}>
          <Button
            type="text"
            size="small"
            icon={<XmarkIcon />}
            onClick={() => {
              setInputValue(fromValue(value, type));
              setEditing(false);
            }}
          />
          <Button type="link" size="small" icon={<CheckIcon />} onClick={handleSave} />
        </div>
      )}
    </Space>
  );
});

FieldRow.displayName = 'FieldRow';

export default FieldRow;
