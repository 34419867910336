import * as React from 'react';
import cx from 'classnames';
import {
  find, map,
} from 'lodash';

import { SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';
import { GetAllProjectsQuery_projects } from '@frontend/app/queries/types/GetAllProjectsQuery';
import {
  Button, Select, Tabs,
} from '@revfluence/fresh';
import { UserIcon } from '@revfluence/fresh-icons/regular/esm';

import { Header } from './Header';

import { Deliverables } from './Deliverables';
import { Activities } from './Activities';
import { MemberDetails } from './MemberDetails';

import {
 IMemberDetails, IApplication, ICollaborationDetails, ActivityFilters, ActivityFiltersLabels,
} from '../types';
import { ApplicationList } from '../../Application/ApplicationList';

import styles from './MemberSection.scss';
import { MemberThreads } from './MemberThreads';
import { MemberNotes } from './MemberNotes';

const { useCallback, useMemo, useState } = React;

enum MemberDetailTabs {
  MESSAGES = 'MESSAGES',
  DELIVERABLES = 'DELIVERABLES',
  ACTIVITY = 'ACTIVITY',
  NOTES = 'NOTES',
}

interface IProps {
  apps: IApplication[];
  disabledApplicationIds?: string[];
  member: IMemberDetails;
  projectId?: number;
  projects?: GetAllProjectsQuery_projects[];
  isImportant: boolean;
  className?: string;
  content: ICollaborationDetails[];
  onBackButtonClicked(): void;
  onAddToProjectAction(): void;
  onComposeAction(): void;
  onAddToGroupAction(): void;
  onRemoveFromProjectAction(): void;
  onFavoriteAction(): void;
  onMarkCompleteAction(contentReviewId: number, shouldMarkComplete: boolean): void;
  onCopyTermsLinkAction(termsUrl: string): void;
  // eslint-disable-next-line
  onAppClickAction(app: IApplication | null, deepLinkParameters?: any): void;
  onReviewClick(contentReviewId: number): void;
  onTermsClick(agreementIterationId: string, termsType: string, agreementId: string): void;
  sendTermsAction(): void;
  loading: boolean;
  isInSentTermsStage: boolean;
  isDeliverablesHidden?: boolean;
}

export const MemberSection: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    member,
    apps,
    projectId,
    projects,
    disabledApplicationIds,
    className,
    content,
    isImportant,
    onBackButtonClicked,
    onFavoriteAction,
    onComposeAction,
    onAddToGroupAction,
    onAddToProjectAction,
    onRemoveFromProjectAction,
    onMarkCompleteAction,
    onCopyTermsLinkAction,
    onAppClickAction,
    onReviewClick,
    loading,
    isInSentTermsStage,
    sendTermsAction,
    onTermsClick,
    isDeliverablesHidden = false,
  } = props;

  const [activeKey, setActiveKey] = useState<MemberDetailTabs>(MemberDetailTabs.MESSAGES);
  const [isProfileOpen, setIsProfileOpen] = useState(true);

  const [selectedActivityFilter, setSelectedActivityFilter] = useState<ActivityFilters>(ActivityFilters.AppNotifications);

  const handleTabClick = useCallback((key) => (
    setActiveKey(key)
  ), [setActiveKey]);

  const memberDetailsPanel = useMemo(() =>
      (
        isProfileOpen ? (
          <div className={styles.sideBarPlaceHolder}>
            <MemberDetails
              memberId={member?.id}
              projects={projects}
              onRequestInsights={() => {
                  const socialPostApp = find(apps, { id: SOCIAL_POST_APP_ID });
                  onAppClickAction(socialPostApp, { action: 'verification' });
                }}
              onClose={() => {
                  setIsProfileOpen(false);
                }}
            />
          </div>
          ) : null
      ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [member, apps, projects, isProfileOpen]);

  const tabs = useMemo(() => {
    const baseTabs = [
      {
        key: MemberDetailTabs.MESSAGES,
        label: 'Messages',
        children: (
          <MemberThreads
            member={member}
            isLoadingMember={loading}
          />
        ),
      },
      {
        key: MemberDetailTabs.DELIVERABLES,
        label: 'Deliverables',
        disabled: isDeliverablesHidden,
        children: (
          <Deliverables
            onMarkCompleteAction={onMarkCompleteAction}
            onCopyTermsLinkAction={onCopyTermsLinkAction}
            collaborationDetails={content}
            onReviewClick={onReviewClick}
            onTermsClick={onTermsClick}
            isInSentTermsStage={isInSentTermsStage}
            sendTermsAction={sendTermsAction}
            loading={loading}
            hideDecorators
          />
        ),
      },
      {
        key: MemberDetailTabs.ACTIVITY,
        label: 'Activity',
        children: (
          <Activities
            loading={loading}
            member={member}
            selectedFilter={selectedActivityFilter}
          />
        ),
      },
      {
        key: MemberDetailTabs.NOTES,
        label: 'Notes',
        children: (
          <MemberNotes
            member={member}
            isLoadingMember={loading}
          />
        ),
      },
    ];

    return baseTabs;
  }, [
    content,
    isInSentTermsStage,
    loading,
    member,
    selectedActivityFilter,
    onCopyTermsLinkAction,
    onMarkCompleteAction,
    onReviewClick,
    onTermsClick,
    sendTermsAction,
    isDeliverablesHidden,
  ]);

  const activityFiltersOptions = useMemo(() => (
    map(Object.values(ActivityFilters), (f) => ({
      value: f,
      label: ActivityFiltersLabels[f],
    }))
  ), []);

  const tabsExtraContent = useMemo(() => (
    <div className={styles.tabsExtraContent}>
      {activeKey === MemberDetailTabs.ACTIVITY && (
        <Select
          options={activityFiltersOptions}
          value={selectedActivityFilter}
          onChange={setSelectedActivityFilter}
        />
      )}
      {!isProfileOpen && (
        <Button
          className={styles.openProfileButton}
          icon={<UserIcon />}
          onClick={() => setIsProfileOpen(true)}
        />
      )}
    </div>
    ), [activeKey, selectedActivityFilter, isProfileOpen, activityFiltersOptions]);

  return (
    <div className={cx(styles.MemberSection, className)}>
      <Header
        onBackButtonClicked={onBackButtonClicked}
        member={member}
        apps={apps}
        loading={loading}
        isImportant={isImportant}
        isInProject={!!projectId}
        onAddToProjectAction={onAddToProjectAction}
        onComposeAction={onComposeAction}
        onAddToGroupAction={onAddToGroupAction}
        onRemoveFromProjectAction={onRemoveFromProjectAction}
        onFavoriteAction={onFavoriteAction}
        onAppClickAction={onAppClickAction}
      />
      <div className={styles.mainSection}>
        <div className={styles.content}>
          <Tabs
            onTabClick={handleTabClick}
            items={tabs}
            tabBarExtraContent={{
              right: tabsExtraContent,
            }}
          />
        </div>
        {memberDetailsPanel}
      </div>
      <ApplicationList
        memberId={member?.id?.toString()}
        hideLauncher
        disabledApplicationIds={disabledApplicationIds}
      />
    </div>
  );
});

MemberSection.displayName = 'MemberSection';
