import React, {
 useEffect, useMemo, useRef, useState,
} from 'react';
import {
 Button, Col, Input, InputNumber, Modal, Row, Select, Skeleton, Space, Spinner, Tag, Tooltip, Typography,
} from '@revfluence/fresh';
import { CircleExclamationIcon, PlusIcon, XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { useHistory, useParams } from 'react-router-dom';
import {
  CatalogSelectionRuleConditionOperationType,
  CatalogType,
  CreateCatalogCollectionInput,
  SelectionType,
} from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import {
  GET_CATALOG_COLLECTIONS_QUERY,
  GET_CATALOG_COLLECTION_BY_ID_QUERY,
  GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY,
} from '@frontend/applications/ProductFulfillmentApp/queries/catalogCollections';
import { useMessagingContext } from '@frontend/hooks';
import { GET_BRAND_CATALOGS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/brandCatalog';
import { v4 as uuidv4 } from 'uuid';
import {
  GetProductAttributesByClientId_getProductAttributesByClientId_collections,
  GetProductAttributesByClientId_getProductAttributesByClientId_options,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductAttributesByClientId';
import { logger } from '@common';
import { pluralize } from '@frontend/app/utils/strings';
import styles from './AdvancedSelectionModal.scss';
import { useCreateCatalogCollection } from '../hooks/useCreateCatalogCollection';
import { useUpdateCatalogCollection } from '../hooks/useUpdateCatalogCollection';
import { useCreateCatalog } from '../hooks/useCreateCatalog';
import { useCatalogDetailsContext } from '../CatalogDetails/CatalogDetailsContext';
import { pfaV2Routes } from '../../routes';
import { KeyAndValue, useAdvancedSelectionContext } from './AdvancedSelectionContext';
import { useGetProductAttributesByClientId } from '../hooks/useGetProductAttributesByClientId';
import { useBasicSelectionContext } from '../CatalogSelectionModal/BasicSelectionContext';
import { useGetAdvancedCollectionStats } from '../hooks/useGetAdvancedCollectionStats';
import { getCleanedTag } from '../../utils';

const { Text } = Typography;

enum DropdownLabels {
  collections = 'Collection',
  vendors = 'Vendor',
  productTypes = 'Product Type',
  tags = 'Tag',
  categories = 'Category',
  options = 'Option',
  price = 'Price',
}

interface SubConditionType {
  id: string;
  name: keyof typeof DropdownLabels;
  optionName?: string;
}

function isCollectionValues(
  param:
    | string[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_collections[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_options[],
): param is GetProductAttributesByClientId_getProductAttributesByClientId_collections[] {
  return (
    param.length > 0
    && (param[0] as GetProductAttributesByClientId_getProductAttributesByClientId_collections).__typename
      === 'CollectionPicklist'
  );
}

function isOptionValues(
  param:
    | string[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_collections[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_options[],
): param is GetProductAttributesByClientId_getProductAttributesByClientId_options[] {
  return (
    param.length > 0
    && (param[0] as GetProductAttributesByClientId_getProductAttributesByClientId_options).__typename === 'OptionPicklist'
  );
}

function isStringValues(
  param:
    | string[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_collections[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_options[],
): param is string[] {
  return typeof param[0] === 'string';
}

export const SingleCondition = ({
  metaData,
  removeCondition,
  localConditions,
  setLocalConditions,
}: {
  metaData: SubConditionType;
  removeCondition: () => void;
  localConditions: SubConditionType[],
  setLocalConditions: React.Dispatch<React.SetStateAction<SubConditionType[]>>
}) => {
  const { productAttributes } = useGetProductAttributesByClientId();

  const {
    priceMin,
    priceMax,
    collections,
    options,
    addConditionField,
    setOptionConditionField,
    removeConditionField,
    ...rest
  } = useAdvancedSelectionContext();

  const [selectedDropdown, setSelectedDropdown] = useState<keyof typeof DropdownLabels>(null);
  const [selectedOptionValue, setSelectedOptionValue] = useState<string>(null);

  const dropdownOptions = Object.entries({
    ...(productAttributes || {}),
    price: [],
  })
    .map(([key]) => ({
      label: DropdownLabels[key as keyof typeof DropdownLabels],
      value: key,
    }))
    .filter((option) => !!option.label);

  const selectedDropdownOption = useMemo(() => {
    let tempSelectedDropdownOption = productAttributes?.[selectedDropdown] || [];
    if (isCollectionValues(tempSelectedDropdownOption)) {
      const uniqueValues = tempSelectedDropdownOption.filter(
        (value, index, self) => self.findIndex((t) => t.id === value.id) === index,
      );
      tempSelectedDropdownOption = uniqueValues;
    }
    if (isStringValues(tempSelectedDropdownOption)) {
      const uniqueValues = tempSelectedDropdownOption.filter((value, index, self) => self.indexOf(value) === index);
      tempSelectedDropdownOption = uniqueValues;
    }
    return tempSelectedDropdownOption;
  }, [productAttributes, selectedDropdown]);

  const selectedOptionUniqueValues = useMemo(() => {
    if (isOptionValues(selectedDropdownOption)) {
      return selectedDropdownOption
        .find((option) => option.name === selectedOptionValue)
        ?.values.filter((value, index, self) => self.indexOf(value) === index);
    }
    return [];
  }, [selectedDropdownOption, selectedOptionValue]);

  const handleCollectionValuesChange = (values: number[]) => {
    const availableCollections = selectedDropdownOption as GetProductAttributesByClientId_getProductAttributesByClientId_collections[];
    addConditionField({
      collections: availableCollections.filter((c) => values.includes(c.internalId)).map((c) => ({
        id: String(c.internalId),
        name: c.name,
      })),
    });
  };

  const handleConditionFieldChange = (newField: keyof typeof DropdownLabels) => {
    if (selectedDropdown) {
      if (selectedDropdown === 'price') {
        removeConditionField({
          priceMin: null,
          priceMax: null,
        } as KeyAndValue);
      } else if (selectedDropdown === 'options') {
        setOptionConditionField(selectedOptionValue, []);
      } else {
        removeConditionField({
          [selectedDropdown]: [],
        } as KeyAndValue);
      }
    }
    setSelectedDropdown(newField);
    setLocalConditions((prev) => prev.map((c) => (c.id === metaData.id ? { ...c, name: newField } : c)));
  };

  const handleOptionValuesChange = (selectedOptionValue: string, values: string[]) => {
    setOptionConditionField(selectedOptionValue, values);
  };

  const handleStringValuesChange = (values: string[]) => {
    addConditionField({ [selectedDropdown]: values } as KeyAndValue);
  };

  const handlePriceValuesChange = (key: 'priceMin' | 'priceMax', value: number) => {
    addConditionField({ [key]: value } as KeyAndValue);
  };

  const handleRemoveCondition = () => {
    if (selectedDropdown) {
      if (selectedDropdown === 'price') {
        removeConditionField({
          priceMin: null,
          priceMax: null,
        } as KeyAndValue);
      } else if (selectedDropdown === 'options') {
        setOptionConditionField(selectedOptionValue, []);
      } else {
        removeConditionField({
          [selectedDropdown]: [],
        } as KeyAndValue);
      }
    }
    removeCondition();
  };

  const handleSelectedOptionValueChange = (value: string) => {
    setOptionConditionField(value, []);
    setSelectedOptionValue(value);
    setLocalConditions((prev) => prev.map((c) => (c.id === metaData.id ? { ...c, optionName: value } : c)));
  };

  useEffect(() => {
    if (metaData.name) {
      setSelectedDropdown(metaData.name);
    }
    if (metaData.optionName) {
      setSelectedOptionValue(metaData.optionName);
    }
  }, [metaData]);

  const checkConditionOptionDisabled = (option: string) => {
    if (option === 'options') {
      return productAttributes?.options?.every((o) => localConditions.some((c) => c.name === 'options' && c.optionName === o.name));
    }
    return localConditions.some((c) => c.name === option);
  };

  const checkConditionOptionDisabledForOption = (optionName: string) => localConditions.some((c) => c.name === 'options' && c.optionName === optionName);

  return (
    <>
      <Col span={6}>
        <Select
          placeholder="Select"
          value={selectedDropdown}
          onChange={handleConditionFieldChange}
          className={styles.conditionSelect}
        >
          {dropdownOptions.map((option) => (
            <Select.Option key={option.value} value={option.value} disabled={checkConditionOptionDisabled(option.value)}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col flex={1}>
        {selectedDropdown === 'price' && (
          <Space className={styles.priceConditionContainer}>
            <InputNumber
              placeholder="Min"
              min={0}
              max={1000000}
              value={priceMin}
              onChange={(value) => handlePriceValuesChange('priceMin', Number(value))}
            />
            <Text>to</Text>
            <InputNumber
              placeholder="Max"
              min={0}
              max={1000000}
              value={priceMax}
              onChange={(value) => handlePriceValuesChange('priceMax', Number(value))}
            />
          </Space>
        )}
        {isCollectionValues(selectedDropdownOption) && (
          <Select
            mode="tags"
            placeholder="Value"
            style={{ width: '100%' }}
            value={collections.map((c) => Number(c.id))}
            onChange={handleCollectionValuesChange}
          >
            {selectedDropdownOption.map((option) => (
              <Select.Option key={option.internalId} value={option.internalId}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        )}
        {isOptionValues(selectedDropdownOption) && (
          <Row align="middle" gutter={8} wrap={false}>
            <Col>
              <Select placeholder="Select" value={selectedOptionValue} onChange={handleSelectedOptionValueChange}>
                {selectedDropdownOption.map((option) => (
                  <Select.Option key={option.name} value={option.name} disabled={checkConditionOptionDisabledForOption(option.name)}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col flex={1}>
              <Select
                mode="tags"
                placeholder="Value"
                style={{ width: '100%' }}
                value={options.find((o) => o.name === selectedOptionValue)?.values}
                onChange={(values) => handleOptionValuesChange(selectedOptionValue, values)}
              >
                {selectedOptionUniqueValues?.map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        )}
        {isStringValues(selectedDropdownOption) && (
          <Select
            mode="tags"
            placeholder="Value"
            style={{ width: '100%' }}
            value={rest[selectedDropdown]}
            onChange={handleStringValuesChange}
          >
            {selectedDropdownOption.map((option) => (
              <Select.Option key={option} value={option}>
                {getCleanedTag(option)}
              </Select.Option>
            ))}
          </Select>
        )}
        {!selectedDropdown && <Select mode="tags" placeholder="Value" style={{ width: '100%' }} />}
      </Col>
      {removeCondition && <Button icon={<XmarkIcon />} type="text" onClick={handleRemoveCondition} />}
    </>
  );
};

export const Conditions = () => {
  const { productAttributes, loading } = useGetProductAttributesByClientId();

  const [conditions, setConditions] = useState<SubConditionType[]>([]);

  const previousConditions = useRef<SubConditionType[]>([]);

  const {
    categories,
    tags,
    vendors,
    productTypes,
    options,
    collections,
    priceMax,
    priceMin,
    isOpen,
    removeConditionField,
    setOptionConditionField,
  } = useAdvancedSelectionContext();

  const removeCondition = (conditionToRemove: SubConditionType) => {
    setConditions((prev) => prev.filter((condition) => condition.id !== conditionToRemove.id));
    if (conditionToRemove.name === 'options') {
      setOptionConditionField(conditionToRemove.optionName, []);
      return;
    }
    removeConditionField({
      [conditionToRemove.name]: [],
    } as KeyAndValue);
  };

  const checkAddConditionDisabled = () => {
    const allConditionsUsed = Object.keys(DropdownLabels).every((key) => conditions.some((c) => c.name === key));
    const allOptionsUsed = productAttributes?.options?.every((o) => conditions.some((c) => c.name === 'options' && c.optionName === o.name));
    return allConditionsUsed && allOptionsUsed;
  };

  useEffect(() => {
    const savedConditions = [];
    if (conditions.length > 0 || !isOpen) {
      return;
    }
    if (categories.length) {
      savedConditions.push({
        id: uuidv4(),
        name: 'categories',
      });
    }
    if (tags.length) {
      savedConditions.push({
        id: uuidv4(),
        name: 'tags',
      });
    }
    if (vendors.length) {
      savedConditions.push({
        id: uuidv4(),
        name: 'vendors',
      });
    }
    if (productTypes.length) {
      savedConditions.push({
        id: uuidv4(),
        name: 'productTypes',
      });
    }
    if (options.length) {
      options.forEach((option) => {
        if (option.values.length) {
          savedConditions.push({
            id: uuidv4(),
            name: 'options',
            optionName: option.name,
          });
        }
      });
    }
    if (collections.length) {
      savedConditions.push({
        id: uuidv4(),
        name: 'collections',
      });
    }
    if (priceMin || priceMax) {
      savedConditions.push({
        id: uuidv4(),
        name: 'price',
      });
    }
    setConditions(savedConditions.length > 0 ? savedConditions : [{ id: uuidv4(), name: null }]);
  }, [
    categories.length,
    collections.length,
    conditions.length,
    options,
    priceMax,
    priceMin,
    productTypes.length,
    tags.length,
    vendors.length,
    isOpen,
  ]);

  useEffect(() => {
    if (!isOpen) {
      setConditions([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (conditions.length > previousConditions.current.length) {
      document.querySelector<HTMLDivElement>(`.${styles.scrollableContainer}`)?.scrollTo({
        top: document.querySelector<HTMLDivElement>(`.${styles.scrollableContainer}`).scrollHeight,
        behavior: 'smooth',
      });
    }
    previousConditions.current = conditions;
  }, [conditions]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <Space direction="vertical" className={styles.scrollableContainer}>
      {conditions.map((c, i) => (
        <Row align="middle" key={c.id} wrap={false}>
          <SingleCondition
            metaData={c}
            removeCondition={i !== 0 ? () => removeCondition(c) : null}
            localConditions={conditions}
            setLocalConditions={setConditions}
          />
        </Row>
      ))}
      <Button
        icon={<PlusIcon />}
        size="small"
        type="link"
        onClick={() => {
          setConditions((prev) => [
            ...prev,
            {
              id: uuidv4(),
              name: null,
            },
          ]);
        }}
        disabled={checkAddConditionDisabled()}
      >
        Add more
      </Button>
    </Space>
  );
};

const useValidation = (checkLabel = true) => {
  const {
    label,
    categories,
    collections,
    productTypes,
    tags,
    vendors,
    options,
    priceMin,
    priceMax,
   } = useAdvancedSelectionContext();
  let isValid = true;
  let errorMessage = '';
  if (checkLabel && !label) {
    isValid = false;
    errorMessage = 'Label is required';
  } else {
    if (categories.length === 0
      && collections.length === 0
      && productTypes.length === 0
      && tags.length === 0
      && vendors.length === 0
      && options.every((option) => option.values.length === 0)
      && !priceMin
      && !priceMax) {
      isValid = false;
      errorMessage = 'At least one filter is required to save the collection item';
    }
  }
  return { isValid, errorMessage };
};

export const AdvancedSelectionModal = () => {
  const {
    isOpen,
    isEditing,
    catalogCollectionId,
    label,
    categories,
    tags,
    vendors,
    productTypes,
    priceMin,
    priceMax,
    options,
    collections,
    operation,
    setLabel,
    setOperation,
    reset,
  } = useAdvancedSelectionContext();
  const { setIsOpen: setBasicSelectionIsOpen, openEditingModal: openBasicSelectionEditingModal } = useBasicSelectionContext();
  const { title, status, description } = useCatalogDetailsContext();
  const history = useHistory();

  const { catalogId } = useParams<{ catalogId: string }>();

  const { showErrorMessage } = useMessagingContext();

  const { stats: { productCount, variantCount }, loading: isStatsLoading } = useGetAdvancedCollectionStats({
    variables: {
      advanceSelectionConfig: {
        conditionOperation: operation,
        categories,
        tags,
        vendors,
        productTypes,
        priceMin,
        priceMax,
        options: options.filter((option) => !!option.values.length).map((option) => ({
          name: option.name,
          values: option.values,
        })) ?? [],
        collections: collections.map((c) => ({
          id: c.id,
          name: c.name,
        })),
      },
    },
    skip: !isOpen || !useValidation(false).isValid,
  });

  const { createCatalog } = useCreateCatalog({
    onCompleted: (data) => {
      history.push(
        pfaV2Routes.settings.brandCatalogCollections.replace(':catalogId', String(data?.createBrandCatalog?.id)),
      );
    },
    onError: (error) => {
      logger.error('Error while creating catalog in AdvancedSelectionModal', error);
      showErrorMessage(error.message || 'Failed to create catalog');
    },
    refetchQueries: [GET_BRAND_CATALOGS_QUERY],
  });
  const { createCollectionItem, loading: isCreateCollectionLoading } = useCreateCatalogCollection({
    onError: (error) => {
      logger.error('Error while creating collection item in AdvancedSelectionModal', error);
      showErrorMessage(error.message || 'Failed to create collection item');
    },
  });
  const { updateCollectionItem, loading: isUpdateCollectionLoading } = useUpdateCatalogCollection({
    onError: (error) => {
      logger.error('Error while updating collection item in AdvancedSelectionModal', error);
      showErrorMessage(error.message || 'Failed to update collection item');
    },
  });

  const resetDrawer = () => {
    reset();
  };

  const handleCreateCollectionItem = async () => {
    let newCatalogId = Number(catalogId);
    if (catalogId === 'new') {
      const { data: catalog } = await createCatalog({
        variables: {
          input: {
            name: title,
            type: CatalogType.CUSTOM,
            status,
            description,
          },
        },
      });
      if (!catalog) {
        return;
      }
      newCatalogId = catalog.createBrandCatalog.id;
    }
    const payload: CreateCatalogCollectionInput = {
      brandCatalogId: newCatalogId,
      selectionType: SelectionType.ADVANCED,
      advanceSelectionConfig: {
        categories,
        tags,
        vendors,
        productTypes,
        priceMin,
        priceMax,
        options: options.filter((option) => !!option.values.length).map((option) => ({
          name: option.name,
          values: option.values,
        })),
        collections: collections.map((c) => ({
          id: c.id,
          name: c.name,
        })),
        conditionOperation: operation,
      },
      label,
    };
    if (isEditing) {
      return updateCollectionItem({
        refetchQueries: [
          GET_CATALOG_COLLECTIONS_QUERY,
          GET_CATALOG_COLLECTION_BY_ID_QUERY,
          GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY,
        ],
        variables: {
          input: payload,
          catalogCollectionId,
        },
        onCompleted: () => {
          resetDrawer();
        },
        update: (cache) => {
          cache.evict({ fieldName: 'getStatsForAllCatalogs' });
          cache.evict({ fieldName: 'getStatsByCatalogId' });
        },
      });
    }
    createCollectionItem({
      refetchQueries: [GET_CATALOG_COLLECTIONS_QUERY, GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY],
      variables: {
        input: payload,
      },
      onCompleted: () => {
        resetDrawer();
      },
      update: (cache) => {
        cache.evict({ fieldName: 'getStatsForAllCatalogs' });
        cache.evict({ fieldName: 'getStatsByCatalogId' });
      },
    });
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(e.target.value);
  };

  const handleOperationChange = (value: CatalogSelectionRuleConditionOperationType) => {
    setOperation(value);
  };

  const switchToBasicSelection = () => {
    if (isEditing) {
      Modal.confirm({
        title: 'Are you sure you want to switch to manual products selection for this item?',
        icon: <CircleExclamationIcon />,
        content: "Please note, switching to manual selection will remove all the existing products from this collection item. You can always switch back to advance selection, however, you'll need to re-define the conditions.",
        onOk: () => {
          updateCollectionItem({
            refetchQueries: [
              GET_CATALOG_COLLECTIONS_QUERY,
              GET_CATALOG_COLLECTION_BY_ID_QUERY,
              GET_STATS_BY_CATALOG_COLLECTION_ID_QUERY,
            ],
            variables: {
              input: {
                brandCatalogId: Number(catalogId),
                selectionType: SelectionType.BASIC,
              },
              catalogCollectionId,
            },
            update: (cache) => {
              cache.evict({ fieldName: 'getStatsForAllCatalogs' });
              cache.evict({ fieldName: 'getStatsByCatalogId' });
            },
            onCompleted: () => {
              openBasicSelectionEditingModal({
                collectionId: null,
                catalogCollectionId,
              });
              reset();
            },
          });
        },
        okText: 'Switch',
        width: '570px',
      });
      return;
    }
    setBasicSelectionIsOpen(true);
    reset();
  };

  const { isValid, errorMessage } = useValidation();

  return (
    <Modal
      title="Collection item"
      open={isOpen}
      width={620}
      className={styles.CatalogSelectionModal}
      onCancel={resetDrawer}
      footer={(
        <Row justify="space-between">
          <Space>
            <Text>Total:</Text>
            {!isStatsLoading && (
            <Tag>
              {productCount}
              {' '}
              {pluralize(productCount, 'Product')}
              {' '}
              |
              {' '}
              {variantCount}
              {' '}
              {pluralize(variantCount, 'Variant')}
            </Tag>
            )}
            <Spinner spinning={isStatsLoading} size="small" />
          </Space>
          <Space>
            <Button onClick={resetDrawer} disabled={isCreateCollectionLoading || isUpdateCollectionLoading}>Cancel</Button>
            <Tooltip title={errorMessage}>
              <Button
                type="primary"
                onClick={handleCreateCollectionItem}
                loading={isCreateCollectionLoading || isUpdateCollectionLoading}
                disabled={!isValid}
              >
                {isEditing ? 'Update' : 'Add Collection Item'}
              </Button>
            </Tooltip>
          </Space>
        </Row>
      )}
    >
      <Space direction="vertical" size="large">
        <Space direction="vertical">
          <Row justify="space-between" align="middle">
            <Text weight="semibold">Add a Label</Text>
            <Button
              type="link"
              size="small"
              onClick={switchToBasicSelection}
              loading={isUpdateCollectionLoading}
            >
              Switch To Basic Selection
            </Button>
          </Row>
          <Input placeholder="Add a Label" value={label} onChange={handleLabelChange} />
        </Space>
        <Space direction="vertical">
          <Space align="center">
            <Text weight="semibold">Products must match</Text>
            <Select
              size="small"
              defaultValue={CatalogSelectionRuleConditionOperationType.OR}
              bordered={false}
              value={operation}
              onChange={handleOperationChange}
            >
              <Select.Option value={CatalogSelectionRuleConditionOperationType.OR}>any condition</Select.Option>
              <Select.Option value={CatalogSelectionRuleConditionOperationType.AND}>all conditions</Select.Option>
            </Select>
          </Space>
          <Conditions key={collections.length} />
        </Space>
      </Space>
    </Modal>
  );
};
