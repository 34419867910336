/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import cx from 'classnames';
import {
 map, flatMap, isNil, isEmpty, filter as lodashFilter, filter, isFunction,
} from 'lodash';
import { Avatar, Button, Typography } from '@revfluence/fresh';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  EnvelopeDotIcon,
  EnvelopesIcon,
  PaperPlaneTopIcon,
  PenToSquareIcon,
} from '@revfluence/fresh-icons/regular/esm';

import {
  isSelectAllThreadsVar,
  selectedThreadsVar,
} from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';

import { OUTLOOK_APP_ID, GMAIL_APP_ID, SOCIAL_POST_APP_ID } from '@frontend/app/constants/applicationIds';

import { CommunityDropdown, EmailComposerModal } from '@frontend/app/components';
import { ClientFeature } from '@frontend/app/constants';
import { useResourceContext } from '@frontend/app/context';
import { useAuth } from '@frontend/context/authContext';
import {
 useApplicationNavigation, useInstagramDMFlag, useClientFeatureEnabled, useGetAllUsers,
} from '@frontend/app/hooks';
import { ResourceType } from '@frontend/app/types/globalTypes';

import { useMessagingContext, TAssigneeType } from '../context/MessagingAppContext';
import { getResourceDisplayName, getThreadIcon } from '../utils/thread';
import { getUserInitials } from '../../Shared/utils';

import styles from './ThreadFolderSection.scss';
import { initSearchFilter, searchFilterReactiveVars } from '../reativeVars/searchFilter';

const { Text } = Typography;
const { useMemo } = React;

interface IProps {
  isExpanded: boolean;
  onExpandToggle: () => void;
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
export const ThreadFolderSection: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    onExpandToggle,
    isExpanded,
    className,
  } = props;

  const { user: authUser } = useAuth();
  const { users } = useGetAllUsers();

  const {
    threadCountsByResourceId,
    assigneeType,
    setAssigneeType,
    setCurrentFolderLabel,
    resourceId,
    setResourceId,
    setResourceEmail,
    userThreadsCount,
    isAllFolderOpen,
    setIsAllFolderOpen,
  } = useMessagingContext();

  const {
    messageResources,
    activeMessageResources,
    addAccount,
    sharedMessageResources,
    individualMessageResources,
  } = useResourceContext();

  const { goTo: navigateTo } = useApplicationNavigation();

  const { isInstagramDMEnabled } = useInstagramDMFlag();
  const showInstagramMeta = useClientFeatureEnabled(ClientFeature.SHOW_INSTAGRAM_META);
  const isInboxSenderFolder = useClientFeatureEnabled(ClientFeature.INBOX_SENDER_FOLDER);
  const isInboxSearchFilter = useClientFeatureEnabled(ClientFeature.INBOX_SEARCH_FILTER);

  const threadFilterConfig: {
    [key: string]: {
      icon: any;
      assignee: TAssigneeType;
      label: string;
      count?: number;
      onClick?: () => void;
    };
  } = {
    you: {
      icon: (
        <Avatar
          src={authUser.picture}
          size={18}
          className={styles.avatar}
        >
          {getUserInitials(authUser)}
        </Avatar>
      ),
      assignee: 'you',
      count: userThreadsCount,
      label: 'Assigned to Me',
    },
    all: {
      icon: <EnvelopesIcon />,
      assignee: 'all',
      label: 'All',
      onClick: () => setIsAllFolderOpen(!isAllFolderOpen),
    },
    appNotification: {
      icon: <EnvelopeDotIcon />,
      assignee: 'appNotification',
      label: 'App Notifications',
    },
  };

  if (isInboxSenderFolder) {
    threadFilterConfig.sent = {
      icon: <PaperPlaneTopIcon />,
      assignee: 'sent',
      count: null,
      label: 'Sent',
    };
  }

  const disconnectedResources = useMemo(
    () =>
      lodashFilter(
        messageResources,
        (resource) => !resource.authProvider.userRevokedAccess && resource.authProvider.systemRevokedAccess,
      ),
    [messageResources],
  );

  const goToSettings = (resourceType: ResourceType) => {
    if (resourceType === ResourceType.GMAIL) {
      navigateTo(GMAIL_APP_ID, '/settings');
    } else if (resourceType === ResourceType.OUTLOOK) {
      navigateTo(OUTLOOK_APP_ID, '/settings');
    } else if (resourceType === ResourceType.IGDM) {
      navigateTo(SOCIAL_POST_APP_ID, '/settings');
    }
  };

  return (
    <div
      className={cx(styles.ThreadFolderSection, className, {
        [styles.expanded]: isExpanded,
      })}
    >
      <div className={styles.actionsWrapper}>
        <div className={styles.toggleWrapper}>
          <div className={styles.toggleWrapperTitle}>
            Inbox
          </div>
          <Button
            size="small"
            icon={isExpanded ? <ChevronsLeftIcon /> : <ChevronsRightIcon />}
            type="text"
            onClick={onExpandToggle}
          />
        </div>
        <div className={styles.composerWrapper}>
          <EmailComposerModal
            allowSendingAsSeparate
            className={styles.messageComposer}
            label="Compose"
            hideLabel={!isExpanded}
            icon={<PenToSquareIcon />}
          />
        </div>
      </div>
      <div className={styles.dropdownWrapper}>
        <CommunityDropdown optionAll useQueryParam={false} hideSelectedTitle={!isExpanded} />
      </div>
      <div className={styles.threadFilter}>
        {flatMap(threadFilterConfig, (config) => {
          const components = [
            <div
              key={config.assignee}
              className={cx(styles.filterItem, {
                [styles.active]: isNil(resourceId) && config.assignee === assigneeType,
              })}
              onClick={() => {
                if (isFunction(config.onClick)) {
                  config.onClick();
                }

                if (config.assignee !== 'all') {
                  setAssigneeType(config.assignee);
                  setCurrentFolderLabel(config.label);
                  setResourceId(null);
                  selectedThreadsVar([]);
                  isSelectAllThreadsVar(false);
                  setResourceEmail('');
                  if (isInboxSearchFilter) {
                    searchFilterReactiveVars(initSearchFilter);
                  }
                }
              }}
            >
              <div className={styles.iconWrapper}>{config.icon}</div>
              <div className={styles.label}>{config.label}</div>
              {
                !isNil(config.count) && (
                  <div className={styles.count}>{config.count || 0}</div>
                )
              }
              {
                config.assignee === 'all' && (
                  <Button
                    size="small"
                    icon={isAllFolderOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    type="text"
                    className={styles.folderToggleIcon}
                    onClick={() => setIsAllFolderOpen(!isAllFolderOpen)}
                  />
                )
              }
            </div>,
          ];

          if (
            config.assignee === 'all'
            && isAllFolderOpen
            && !isEmpty(activeMessageResources)
          ) {
            const accounts = [...sharedMessageResources, ...individualMessageResources];
            const nonInstagramAccounts = filter(accounts, (account) => account.type !== ResourceType.IGDM);
            const filteredAccounts = isInstagramDMEnabled || showInstagramMeta ? accounts : nonInstagramAccounts;

            components.push(
              ...map(filteredAccounts, (resource) => {
                const isIndividual = resource.isAdmin;

                return (
                  <div
                    key={resource.id}
                    className={cx(styles.filterItem, styles.secondary, {
                      [styles.active]:
                        resourceId && (resourceId === resource.id || resourceId === resource.adminResourceId),
                    })}
                    onClick={() => {
                      setAssigneeType('all');
                      setCurrentFolderLabel('All');
                      selectedThreadsVar([]);
                      isSelectAllThreadsVar(false);
                      if (resource.isAdmin) {
                        setResourceId(resource.id);
                      } else {
                        setResourceId(resource.adminResourceId);
                      }
                      setResourceEmail(resource.authProvider.userExternalDisplayId);
                      if (isInboxSearchFilter) {
                        searchFilterReactiveVars(initSearchFilter);
                      }
                    }}
                  >
                    <div className={styles.iconWrapper}>{getThreadIcon(resource.type, isIndividual)}</div>
                    <Text ellipsis className={styles.label}>
                      {getResourceDisplayName(resource, users)}
                    </Text>
                    <div className={styles.count}>{threadCountsByResourceId[resource.id]}</div>
                  </div>
                );
              }),
            );
          }

          return components;
        })}

        {map(disconnectedResources, (resource) => (
          <div key={resource.id} className={styles.errorNotice}>
            <div className={styles.content}>
              <div className={styles.errorTitle}>
                {resource.authProvider.userExternalDisplayId}
                {' '}
                is not connected
              </div>
              <div className={styles.errorText}>There is a problem with your account</div>
            </div>
            <div className={styles.actions}>
              {resource.type !== ResourceType.IGDM && (
                <div
                  className={cx(styles.actionButton, styles.reconnect)}
                  onMouseUp={() => addAccount(resource.type, resource.authProvider.isShared)}
                >
                  Reconnect
                </div>
              )}
              <div className={styles.actionButton} onMouseUp={() => goToSettings(resource.type)}>
                Settings
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

ThreadFolderSection.displayName = 'ThreadFolderSection';
