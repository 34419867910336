import * as React from 'react';
import { Col, Row } from 'antd';
import { get, isNull, isUndefined } from 'lodash';
import { useLocation, useParams } from 'react-router-dom';

import { LoadSpinner } from '@components';

import {
  Notice,
  PageSection,
} from '@affiliates/AspireUI';
import {
  OfferForm,
  OfferFormModes,
  OfferSummaryCard,
  TFormValues,
} from '@affiliates/components';
import { EditOfferContainer } from '@affiliates/containers';
import {
  IShopifyCredentials, useApolloClient, useGetOfferByIdQuery, useRedirectOnOfferFormSubmit,
} from '@affiliates/hooks';
import { GetOfferById } from '@affiliates/queries/types/GetOfferById';
import { useEffect, useState } from 'react';
import { Alert } from '@revfluence/fresh';
import moment from 'moment';
import { BoxArchiveIcon } from '@revfluence/fresh-icons/regular';
import { IChildArgs } from '../containers/OfferFormContainer/types';
import { OFFER_STATUS, UserInfoInput } from '../types/globalTypes';
import styles from './OfferFormPage.scss';
import { useShopifyShopQuery } from '../hooks/useShopifyShopQuery';
import { useMigrateToMultiplePayout } from '../hooks/useMigrateToMultiplePayout';
import { GetConnectedShopify_clientConnections } from '../queries/types/GetConnectedShopify';
import { GET_CONNECTED_SHOPIFY } from '../queries/getConnectedShopifyQuery';

const { useCallback, Suspense } = React;

interface IProps {
  baseUri: string;
  shopifyCredentials: IShopifyCredentials;
  profile: UserInfoInput;
  migrateToGraphQL: boolean;
  isEnabledMultipleShopify?: boolean;
  allowedDomains: string[];
}

interface IParams {
  offerId: string;
}
interface StateType {
  isNewFlow: boolean;
  isMigrationEnabled: boolean;
}

export const EditOfferPage: React.FC<Readonly<IProps>> = ({
  baseUri, shopifyCredentials, profile, migrateToGraphQL, isEnabledMultipleShopify, allowedDomains,
}) => {
  const { offerId } = useParams<IParams>();
  const offerQuery = useGetOfferByIdQuery({
    variables: {
      id: Number(offerId),
    },
  });
  const { refetch } = offerQuery;
  const [migrateOfferToMultiplePayout] = useMigrateToMultiplePayout({
    onCompleted() {
      refetch();
      window.location.reload();
    },
    onError() {
    },
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (offerQuery.error) {
          return;
        }
        const offer = get(offerQuery, ['data', 'offer'], null);
        if (migrateToGraphQL && !isNull(offer) && offer.links.length && offer.links[0].defaultPayoutId === null) {
          await migrateOfferToMultiplePayout({
            variables: {
              id: offerQuery?.data?.offer?.id,
            },
          });
        }
        if (migrateToGraphQL && !isNull(offer) && offer.promos.length && offer.promos[0].defaultPayoutId === null) {
          await migrateOfferToMultiplePayout({
            variables: {
              id: offerQuery?.data?.offer?.id,
            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [offerQuery, migrateOfferToMultiplePayout, migrateToGraphQL]);

  const { onSubmit } = useRedirectOnOfferFormSubmit(baseUri, migrateToGraphQL);
  const shopifySubscriptionCheck = useShopifyShopQuery();
  const [isSubscriptionEnable, setIsSubscriptionEnable] = useState(false);
  const [connectedShopifyData, setConnectedShopifyData] = useState<GetConnectedShopify_clientConnections[]>([]);
  const staApolloClient = useApolloClient();

  let isNewFlow = false;
  let isMigrationEnabled = false;
  const location = useLocation<StateType | undefined>();
  const stateData = location.state;
  if (!isUndefined(stateData)) {
    isNewFlow = stateData.isNewFlow;
    isMigrationEnabled = stateData.isMigrationEnabled;
  }
  useEffect(() => {
    if (isEnabledMultipleShopify || offerQuery?.data?.offer.promos[0]?.connectedClientMetadata?.length) {
      (async () => {
        const { data } = await staApolloClient.query({ query: GET_CONNECTED_SHOPIFY });
        if (data?.clientConnections) {
          const allConnections: GetConnectedShopify_clientConnections[] = data.clientConnections;
          setConnectedShopifyData(allConnections);
        }
      })();
    }
  }, [isEnabledMultipleShopify, staApolloClient, offerQuery?.data?.offer?.promos]);

  React.useEffect(() => {
    if (!shopifySubscriptionCheck.loading) {
      if (shopifySubscriptionCheck?.data && shopifySubscriptionCheck.data.getShopifyStoreData.eligibleForSubscriptions) {
        setIsSubscriptionEnable(shopifySubscriptionCheck.data.getShopifyStoreData.eligibleForSubscriptions);
      }
    }
  }, [shopifySubscriptionCheck]);

  const renderComponents = useCallback(
    ({
      actions,
      disabledFields,
      formRef,
      formOptionData,
      initialValues,
      isSaving,
      offerDetails,
      requiredFields,
      tooltip,
      values,
      handleFormAsyncActions,
    }: IChildArgs<TFormValues>) => (
      <Row gutter={40}>
        <Col span={10}>
          <OfferForm
            disabledFields={disabledFields}
            formRef={formRef}
            hasAffiliateLink={offerDetails.hasMembers}
            initialValues={initialValues}
            isDeleted={offerDetails.isDeleted}
            isExpired={offerDetails.isExpired}
            isSaving={isSaving}
            mode={OfferFormModes.Edit}
            onDelete={actions.onDelete}
            onFieldFocused={actions.onFieldFocused}
            onFinish={actions.onFinish}
            onUnDelete={actions.onUnDelete}
            onValuesChange={actions.onChangeValues}
            requiredFields={requiredFields}
            values={values}
            formOptionData={formOptionData}
            migrateToGraphQL={migrateToGraphQL}
            isNewFlow={isNewFlow} // Check Whether Promocode Flow is New or Old
            isMigrationEnabled={isMigrationEnabled}
            isSubscriptionEnable={isSubscriptionEnable}
            isEnabledMultipleShopify={isEnabledMultipleShopify}
            handleFormAsyncActions={handleFormAsyncActions}
            isReadOnly={offerDetails.isReadOnly}
            connectedAdvertiserForSync={connectedShopifyData.filter((c) => !c.isPrimary)}
            hasMembers={offerDetails.hasMembers}
            connectedClients={connectedShopifyData}
            isOfferArchived={offerDetails.isOfferArchived}
            initialAllowedDomains={allowedDomains}
          />
        </Col>
        <Col span={7}>
          <OfferSummaryCard
            offerFormVariant
            onChangeImage={actions.handleChangeOfferImage}
            status={offerDetails.isDeleted ? OFFER_STATUS.DELETED : OFFER_STATUS.ACTIVE}
            values={values}
            isExpired={offerDetails.isExpired}
            isNewFlow={isNewFlow}
            migrateToGraphQL={migrateToGraphQL}
            isOfferArchived={offerDetails.isOfferArchived}
          />
          {tooltip}
        </Col>
      </Row>
    ),
    [
      isNewFlow,
      migrateToGraphQL,
      isMigrationEnabled,
      isSubscriptionEnable,
      isEnabledMultipleShopify,
      connectedShopifyData,
      allowedDomains,
    ],
  );

  const renderPageContents = (): React.ReactElement => {
    if (offerQuery.loading) {
      return <LoadSpinner />;
    }

    if (offerQuery.error) {
      return <Notice type="error" message={offerQuery.error.message} />;
    }

    const offer: GetOfferById['offer'] | null = get(offerQuery, ['data', 'offer'], null);
    if (isNull(offer)) {
      return <Notice type="error" message="Unable to retrieve offer." />;
    }
    return (
      <>
        <div className={styles.OfferFormPage}>
          <PageSection>
            <h1 className={styles.title}>
              Update Offer
              {' '}
              {offer.name && (
                <>
                  &ldquo;
                  {offer.name}
                  &rdquo;
                </>
              )}
            </h1>
          </PageSection>
          <PageSection>
            {
              (offerQuery?.data?.offer.archivedDate)
              && (
                <div className={styles.archiveOfferAlert}>
                  <Alert
                    message={`This offer was archived on ${moment(offerQuery?.data?.offer?.archivedDate).format('MM/DD/YY')} by ${offerQuery?.data?.offer?.archivedUser?.name}. All offer insights are still available but you can no longer add new members.`}
                    type="warning"
                    icon={<BoxArchiveIcon />}
                    showIcon
                  />
                </div>
              )
            }
          </PageSection>
          <PageSection>
            <Suspense fallback={<LoadSpinner />}>

              <EditOfferContainer
                offer={offer}
                mode={OfferFormModes.Edit}
                shopifyCredentials={shopifyCredentials}
                onSubmit={onSubmit}
                profile={profile}
                isSubscriptionEnable={isSubscriptionEnable}
                migrateToGraphQL={migrateToGraphQL}
                isEnabledMultipleShopify={isEnabledMultipleShopify}
                allowedDomains={allowedDomains}
              >
                {renderComponents}
              </EditOfferContainer>
            </Suspense>

          </PageSection>

        </div>
      </>
    );
  };

  return <div>{renderPageContents()}</div>;
};
