import {
 filter, first, isEmpty, map,
} from 'lodash';
import * as React from 'react';

import { InfoCard } from '@frontend/app/components';
import {
 useListTikTokSettingsAccountsQuery, useTikTokSettingsDisconnectAccountMutation, useTikTokSettingsDisconnectAdAccountMutation, useListTikTokSettingsAdAccountsQuery, useTikTokSettingsUpdateAdAccountMutation,
 useClientFeatureEnabled,
} from '@frontend/app/hooks';
import { LIST_TIKTOK_SETTINGS_ACCOUNTS, LIST_TIKTOK_SETTINGS_AD_ACCOUNTS } from '@frontend/app/queries';
import {
 Button, message, Modal, Space,
} from '@revfluence/fresh';
import { EarIcon, HandshakeIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';

import { ClientFeature } from '@frontend/app/constants';
import { ILoadingRows, TikTokAccountsTable } from '../components/TikTokAccountsTable';
import { TikTokAdAccountsTable } from '../components/TikTokAdAccountsTable';
import { useOAuthConnectTikTok } from '../hooks/useOAuthConnectTikTok';
import { useOAuthConnectTikTokAdAccount } from '../hooks/useOAuthConnectTikTokAdAccount';
import { Layout } from './Layout';

const {
 useCallback, useEffect, useState, useMemo,
} = React;

export interface AdAccountLoadingState {
  removingLoading?: true;
  sparkAdsLoading?: true;
}

export interface AdAccountTableLoadingRows {
  [key: string]: AdAccountLoadingState;
}

const DEFAULT_PAGING = {
  limit: 10,
  offset: 0,
};

const RemoveTikTokAccountConfirmModal = (confirmAction: () => void) => {
  const content = (
    <p>
      Please note that after removing your TikTok account, we will no longer capture any data for social listening
      purposes. This means that we will not be able to gather insights or monitor any awareness or engagement related to
      your account.
    </p>
  );

  return Modal.confirm({
    title: 'Remove TikTok Account',
    content,
    okText: 'Remove',
    cancelText: 'Cancel',
    onOk: confirmAction,
  });
};

// TODO: (an): Confirm copy.
const RemoveTikTokAdAccountConfirmModal = (confirmAction: () => void) => {
  const content = (
    <p>
      Please note that after removing your TikTok Ad account, we will no longer be able to request posts to be used for Spark Ads.
    </p>
  );

  return Modal.confirm({
    title: 'Remove TikTok Ad Account',
    content,
    okText: 'Remove',
    cancelText: 'Cancel',
    onOk: confirmAction,
  });
};

export const TiktokSettingsPage = () => {
  const tiktokAdAccountsFeatureEnabled = useClientFeatureEnabled(ClientFeature.TIKTOK_SPARK_ADS);

  const [adAccountLoadingRows, setAdAccountLoadingState] = useState<AdAccountTableLoadingRows>({});

  const setAdAccountLoadingRows = (adAccountLoadingRow: AdAccountTableLoadingRows) => {
    setAdAccountLoadingState((state) => ({
      ...state,
      ...adAccountLoadingRow,
    }));
  };
  const clearAdAccountLoadingRows = useCallback(() => {
    setAdAccountLoadingState({});
  }, []);

  const accountsQuery = useListTikTokSettingsAccountsQuery({
    variables: {
      paging: DEFAULT_PAGING,
    },
  });
  const { refetch: accountsRefetch } = accountsQuery;
  const accountsPaging = useMemo(() => accountsQuery.data?.tiktokSettingsListAccounts?.paging, [accountsQuery]);
  const accountsResults = useMemo(() => accountsQuery.data?.tiktokSettingsListAccounts?.results || [], [accountsQuery]);
  const [accountsLoadingRows, setAccountsLoadingState] = useState<ILoadingRows>({});
  const oauthConnectTikTokAccount = useOAuthConnectTikTok();
  const [disconnectAccount, disconnectAccountResult] = useTikTokSettingsDisconnectAccountMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LIST_TIKTOK_SETTINGS_ACCOUNTS,
        variables: {
          paging: DEFAULT_PAGING,
        },
      },
    ],
  });

  const adAccountsQuery = useListTikTokSettingsAdAccountsQuery({
    variables: {
      paging: DEFAULT_PAGING,
    },
  });
  const { refetch: adAccountsRefetch } = adAccountsQuery;
  const adAccountsPaging = useMemo(() => adAccountsQuery.data?.tiktokSettingsListAdAccounts?.paging, [adAccountsQuery]);
  const adAccountsResults = useMemo(() => adAccountsQuery.data?.tiktokSettingsListAdAccounts?.results || [], [adAccountsQuery]);
  const oauthConnectTikTokAdAccount = useOAuthConnectTikTokAdAccount();
  const [disconnectAdAccount, disconnectAdAccountResult] = useTikTokSettingsDisconnectAdAccountMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LIST_TIKTOK_SETTINGS_AD_ACCOUNTS,
        variables: {
          paging: DEFAULT_PAGING,
        },
      },
    ],
  });

  const [updateAdAccount, updateAdAccountResult] = useTikTokSettingsUpdateAdAccountMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: LIST_TIKTOK_SETTINGS_AD_ACCOUNTS,
        variables: {
          paging: DEFAULT_PAGING,
        },
      },
    ],
  });

  useEffect(() => {
    const size = accountsResults.length || 0;
    const offset = accountsPaging?.offset || DEFAULT_PAGING.offset;
    if (size === 0 && offset > 0) {
      accountsRefetch({
        paging: {
          limit: accountsPaging.limit,
          offset: Math.max(offset - accountsPaging.limit, 0),
        },
      });
    }
  }, [accountsResults, accountsPaging, accountsRefetch]);

  useEffect(() => {
    const size = adAccountsResults.length || 0;
    const offset = adAccountsPaging?.offset || DEFAULT_PAGING.offset;
    if (size === 0 && offset > 0) {
      adAccountsRefetch({
        paging: {
          limit: adAccountsPaging.limit,
          offset: Math.max(offset - adAccountsPaging.limit, 0),
        },
      });
    }
  }, [adAccountsResults, adAccountsPaging, adAccountsRefetch]);

  const someAccountQueryError = useMemo(() => !!accountsQuery.error?.message, [accountsQuery]);
  const someDisconnectAccountError = useMemo(() => disconnectAccountResult?.error?.message, [disconnectAccountResult]);
  const setLoadingAccountRows = (loadingRow: ILoadingRows) => {
    setAccountsLoadingState((state) => ({
      ...state,
      ...loadingRow,
    }));
  };

  const someAdAccountQueryError = useMemo(() => !!adAccountsQuery.error?.message, [adAccountsQuery]);
  const someDisconnectAdAccountError = useMemo(() => disconnectAdAccountResult?.error?.message, [disconnectAdAccountResult]);
  const someUpdateAdAccountError = useMemo(() => updateAdAccountResult?.error?.message, [updateAdAccountResult]);

  const clearAccountsLoadingRows = useCallback(() => {
    setAccountsLoadingState({});
  }, []);

  useEffect(() => {
    if (someAccountQueryError || someDisconnectAccountError || someAdAccountQueryError || someDisconnectAdAccountError || someUpdateAdAccountError) {
      message.error({
        icon: <TriangleExclamationIcon />,
        content:
          'We were unable to load your settings. Please try again later, please contact help@aspireiq.com if your problem persists.',
      });
    }
  }, [someAccountQueryError, someDisconnectAccountError, someAdAccountQueryError, someDisconnectAdAccountError, someUpdateAdAccountError]);

  useEffect(() => {
    const hasAccountError = !!accountsQuery.error || !!disconnectAccountResult.error;
    const notAccountLoading = !(accountsQuery.loading || disconnectAccountResult.loading);
    const hasLoadingRows = !isEmpty(accountsLoadingRows);
    if ((hasAccountError || notAccountLoading) && hasLoadingRows) {
      clearAccountsLoadingRows();
    }
  }, [accountsQuery, disconnectAccountResult, clearAccountsLoadingRows, accountsLoadingRows]);

  useEffect(() => {
    const hasAdAccountError = !!adAccountsQuery.error || !!disconnectAdAccountResult.error || !!updateAdAccountResult.error;
    const notLoading = !(adAccountsQuery.loading || disconnectAdAccountResult.loading || updateAdAccountResult.loading);
    const hasLoadingRows = !isEmpty(adAccountLoadingRows);
    if ((hasAdAccountError || notLoading) && hasLoadingRows) {
      clearAdAccountLoadingRows();
    }
  }, [adAccountsQuery, disconnectAdAccountResult, updateAdAccountResult, clearAdAccountLoadingRows, adAccountLoadingRows]);

  const isFirstLoad = (accountsQuery.loading && isEmpty(accountsQuery?.data?.tiktokSettingsListAccounts?.results))
    || (adAccountsQuery.loading && isEmpty(adAccountsQuery?.data?.tiktokSettingsListAdAccounts?.results));
  if (isFirstLoad) {
    return null;
  }

  const selectedTiktokSparkAdsAccount = first(
    map(
      filter(
        adAccountsResults,
        (adAccount) => adAccount.sparkAds.enabled,
      ),
      (adAccount) => adAccount.username,
    ),
  );

  const addTiktokAdAccountButton = !tiktokAdAccountsFeatureEnabled ? null : (
    <Button onClick={() => oauthConnectTikTokAdAccount()} type="primary">
      Add TikTok Ad Account
    </Button>
  );

  return (
    <Layout
      SettingsInfoCards={(
        <Space direction="horizontal" align="start" size="middle" wrap>
          <InfoCard
            title="Listening"
            description="Monitor and identify branded content by members that mention your brand on TikTok."
            icon={<EarIcon />}
            width={415}
          />
          {tiktokAdAccountsFeatureEnabled && (
          <InfoCard
            title="Spark Ads"
            description="Request permission from creators to create Spark Ads from UGC."
            icon={<HandshakeIcon />}
            width={415}
          />
)}
        </Space>
      )}
      AddTiktokAccountButton={(
        <Button onClick={() => oauthConnectTikTokAccount()} type="primary">
          Add TikTok Account
        </Button>
      )}
      TiktokAccountsTable={(
        <TikTokAccountsTable
          pagination={{
            onChange(page, size) {
              accountsRefetch({
                paging: {
                  limit: size,
                  offset: (page - 1) * size,
                },
              });
            },
            total: accountsPaging.count,
            pageSize: accountsPaging.limit,
            current: accountsPaging.offset / accountsPaging.limit + 1,
          }}
          loadingRows={accountsLoadingRows}
          data={accountsResults}
          removeAccount={(accountId) => {
            RemoveTikTokAccountConfirmModal(() => {
              setLoadingAccountRows({ [accountId]: true });
              disconnectAccount({
                variables: {
                  accountId,
                },
              });
            });
          }}
        />
      )}
      AddTiktokAdAccountButton={addTiktokAdAccountButton}
      TiktokAdAccountsTable={(
        <TikTokAdAccountsTable
          tiktokAdAccountsFeatureEnabled={tiktokAdAccountsFeatureEnabled}
          selectedTiktokSparkAdsAccount={selectedTiktokSparkAdsAccount}
          pagination={{
            onChange(page, size) {
              adAccountsRefetch({
                paging: {
                  limit: size,
                  offset: (page - 1) * size,
                },
              });
            },
            total: adAccountsPaging.count,
            pageSize: adAccountsPaging.limit,
            current: adAccountsPaging.offset / adAccountsPaging.limit + 1,
          }}
          loadingRows={adAccountLoadingRows}
          data={adAccountsResults}
          removeAccount={(accountId) => {
            RemoveTikTokAdAccountConfirmModal(() => {
              setAdAccountLoadingRows({ [accountId]: { removingLoading: true } });
              disconnectAdAccount({
                variables: {
                  accountId,
                },
              });
            });
          }}
          updateAccount={(variables) => {
            setAdAccountLoadingRows({ [variables.accountId]: { sparkAdsLoading: true } });
            updateAdAccount({
              variables,
            });
          }}
        />
      )}
    />
  );
};
