import cx from 'classnames';
import { format as formatDate } from 'date-fns';
import moment from 'moment';
import * as React from 'react';

import { useClientFeatureEnabled, useGetCurrentClientMetadata } from '@frontend/app/hooks';
import { useRefreshClientSocialAnalytics } from '@frontend/app/hooks/useClientSocialAnalyticsRefresh';
import { GET_CURRENT_CLIENT_METADATA_QUERY } from '@frontend/app/queries';
import {
 Button, Modal, Tooltip, Typography, message,
} from '@revfluence/fresh';
import { ArrowRotateRightIcon } from '@revfluence/fresh-icons/regular/esm';

import { ClientFeature } from '@frontend/app/constants';
import styles from './RefreshSocialAnalyticsButton.scss';

const { useMemo, useState } = React;
const { Paragraph, Title } = Typography;

interface IProps {
  className?: string;
}

const REFRESH_COOLDOWN_HOURS = 24;

function showLearnMoreModal() {
  Modal.info({
    title: <Title level={3}>Understanding Manual Data Refresh for Social Analytics</Title>,
    content: (
      <>
        <Paragraph>
          We are introducing the On-Demand Refresh request for social analytics data for the
          {' '}
          <strong>last 6 months</strong>
          , which can be requested once every 24 hours per client. The automatic scheduled
          {' '}
          data refreshes will continue as planned for
          {' '}
          <strong>all data</strong>
          .
        </Paragraph>
        <Paragraph>
          When capturing social analytics data, we rely on the Insight Date as the reference point. The Insight Date
          {' '}
          represents the last update of social data for a particular post within our platform. The inclusion of posts in
          {' '}
          the data is based on whether they have generated new metrics, such as engagements and impressions, during the
          {' '}
          selected timeframe.
        </Paragraph>
        <Paragraph>
          To maintain data relevancy and optimize system performance, we focus on updating data for the last six months
          {' '}
          when you are trying to do an on-demand social analytics data refresh. This approach allows us to balance
          {' '}
          providing meaningful insights for recent activities and preventing excessive data storage and processing
          {' '}
          requirements. By prioritizing the most recent data, we ensure that the information available reflects the
          {' '}
          current state of social engagements and metrics.
        </Paragraph>
      </>
    ),
    autoFocusButton: null,
    icon: null,
    mask: true,
    maskClosable: true,
    okText: 'Ok',
    onOk() {},
    width: 768,
  });
}

/**
 * @type {React.FunctionComponent}
 */
export const RefreshSocialAnalyticsButton: React.FunctionComponent<IProps> = React.memo((props) => {
  const isEnabled = useClientFeatureEnabled(ClientFeature.ON_DEMAND_SOCIAL_ANALYTICS_REFRESH);
  const { clientMetadata, refetch: refetchClientMetadata } = useGetCurrentClientMetadata();

  const [refreshClientSocialAnalytics, { loading: isProcessing }] = useRefreshClientSocialAnalytics({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GET_CURRENT_CLIENT_METADATA_QUERY }],
    onCompleted: () => {
      message.success(
        'Your data refresh request has been successfully sent. Generating the report could take up to a few hours hours.',
      );
    },
    onError: () => {
      message.error('There was an error refreshing this report. Please try refreshing again.');
      refetchClientMetadata();
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const lastRefreshDate = clientMetadata?.lastOnDemandSocialAnalyticsRefreshDate
    ? new Date(clientMetadata.lastOnDemandSocialAnalyticsRefreshDate)
    : null;

  const canRefresh = useMemo(() => {
    if (!lastRefreshDate) {
      return true;
    }

    const lastRefreshSeconds = moment().diff(lastRefreshDate, 'seconds');
    const refreshCooldownSeconds = REFRESH_COOLDOWN_HOURS * 60 * 60;
    return lastRefreshSeconds >= refreshCooldownSeconds;
  }, [lastRefreshDate]);

  const [showTooltip, setShowTooltip] = useState(false);
  const dismissTooltip = () => setShowTooltip(false);
  const tooltipBody = isEnabled ? (
    <>
      {lastRefreshDate && (
        <p>
          Last manual data refresh request:
          <br />
          <strong>{formatDate(lastRefreshDate, 'Pp')}</strong>
          .
        </p>
      )}
      <p>
        You can manually request a refresh once every 24 hours. Automatically scheduled data refreshes will continue as
        planned.
      </p>
      <a
        className={styles.tooltipLink}
        onClick={() => {
          dismissTooltip();
          showLearnMoreModal();
        }}
      >
        Learn more
      </a>
    </>
  ) : (
    'This feature is currently unavailable.'
  );

  return (
    <>
      <Tooltip placement="bottomRight" title={tooltipBody} open={showTooltip} onOpenChange={setShowTooltip}>
        <Button
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          type="secondary"
          loading={isProcessing}
          disabled={!isEnabled || isProcessing || !canRefresh}
          onClick={() => {
            dismissTooltip();
            refreshClientSocialAnalytics();
          }}
          icon={<ArrowRotateRightIcon />}
          className={cx(styles.RefreshSocialAnalyticsButton, props.className)}
        >
          {isProcessing ? 'Sending Request' : 'Refresh Data'}
        </Button>
      </Tooltip>
    </>
  );
});
