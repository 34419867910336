import * as React from 'react';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';
import PaymentListPage from './PaymentListPage';

const application = {
  displayName: 'Payments',
  iconUrl: 'https://storage.googleapis.com/aspirex-static-files/payment.svg',
};

const navSettings = [
  {
    route: 'reports',
    displayName: 'My Payments',
    component: PaymentListPage,
  },
];

const PaymentAppDashboard: React.FunctionComponent = () => (
  <AppDashboardNav
    application={application}
    navLinks={navSettings}
    defaultRoute="reports"
    hideTabs
  />
);

export default PaymentAppDashboard;
