import * as React from 'react';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';
import {
  useMemberPageContext,
} from '../hooks';
import { SettingsButton } from './SettingsButton/SettingsButton';
import { AddMemberButton } from './AddMemberButton/AddMemberButton';
import { CsvDropdown } from './CsvDropdown/CsvDropdown';
import { LastSynced } from './LastSynced/LastSynced';

import styles from './PageHeader.scss';

interface IProps {
  memberQueryCount: number;
  segmentType?: string;
  isApplicantList?: boolean;
  selectedMemberIds?: number[];
}

export const PageHeader: React.FC<IProps> = (props) => {
  const {
    sourcingGroupId,
  } = useMemberPageContext();
  const { segment } = useMemberListContext();

  return (
    <div className={styles.PageHeader}>
      <div className={styles.header}>
        {segment?.title}
      </div>
      <div className={styles.rightSide}>
        {
          sourcingGroupId && (
            <LastSynced
              sourcingGroupId={sourcingGroupId}
            />
          )
        }
        <CsvDropdown
          segmentType={props.segmentType}
          memberCount={props.memberQueryCount}
          selectedMemberIds={props.selectedMemberIds}
        />
        {!props.isApplicantList && (
          <AddMemberButton />
        )}
        <SettingsButton />
      </div>
    </div>
  );
};

PageHeader.defaultProps = {
  isApplicantList: false,
};
