/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1fign_157 {
  display: flex;
}

._justify-content-space-between_1fign_161 {
  justify-content: space-between;
}

._tabular-nums_1fign_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1fign_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._ManageOfferDrawer_1fign_178 .ant-drawer-body {
  padding: 0;
}
._ManageOfferDrawer_1fign_178 ._header_1fign_181 {
  position: absolute;
  left: 1.5rem;
  display: flex;
  align-items: center;
}
._ManageOfferDrawer_1fign_178 ._offerDetails_1fign_187 {
  border-bottom: 0.0625rem solid #f0f0f0;
  padding: 1rem 1.5rem;
}
._ManageOfferDrawer_1fign_178 ._offerDetails_1fign_187 .ant-avatar {
  margin-right: 0.5rem;
}
._ManageOfferDrawer_1fign_178 ._offerDetails_1fign_187 .ant-tag {
  border-radius: 0.375rem;
}
._ManageOfferDrawer_1fign_178 ._offerDetails_1fign_187 ._offerName_1fign_197 {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._ManageOfferDrawer_1fign_178 ._offerDetails_1fign_187 ._img_1fign_201 {
  object-fit: cover;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 0.5rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferwrapper_1fign_209 {
  padding: 1.5rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferwrapper_1fign_209 ._manageOfferButtons_1fign_212 {
  margin-top: 1.125rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferwrapper_1fign_209 ._manageOfferButtons_1fign_212 .ant-btn {
  height: auto;
  width: 100%;
  border: none;
  text-align: unset;
  padding: 0px;
  font-weight: initial;
  margin-bottom: 0.5rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferwrapper_1fign_209 ._manageOfferButtons_1fign_212 ._buttonsAction_1fign_224 {
  padding: 1rem;
  border: 0.0625rem solid rgb(240, 240, 240);
  border-radius: 0.375rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferwrapper_1fign_209 ._manageOfferButtons_1fign_212 ._buttonsAction_1fign_224 .ant-avatar {
  background-color: #006462;
  vertical-align: middle;
}
._ManageOfferDrawer_1fign_178 ._manageOfferwrapper_1fign_209 ._manageOfferButtons_1fign_212 ._buttonsAction_1fign_224 ._buttonTitle_1fign_233 {
  margin-left: 0.75rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferwrapper_1fign_209 ._manageOfferButtons_1fign_212 ._buttonsAction_1fign_224 ._buttonTitle_1fign_233 .ant-typography {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._ManageOfferDrawer_1fign_178 ._manageOfferwrapper_1fign_209 ._manageOfferButtons_1fign_212 .ant-alert {
  text-wrap: wrap;
}
._ManageOfferDrawer_1fign_178 ._manageOfferwrapper_1fign_209 ._img_1fign_201 {
  object-fit: cover;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-right: 0.5rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferwrapper_1fign_209 ._mb0_1fign_251 {
  margin-bottom: 0;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 {
  padding: 1.5rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 .ant-typography {
  margin-right: 0.5rem;
  margin-bottom: 0;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 .ant-select {
  width: 100%;
  margin-bottom: 0.5rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 .ant-collapse {
  margin-top: 2rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._boldText_1fign_268 {
  font-size: 1rem;
  font-weight: 600;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._memberCount_1fign_272 {
  color: #505256;
  font-weight: 400;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._ManageOfferAction_1fign_276 {
  padding: 1rem;
  border: 0.0625rem solid rgb(240, 240, 240);
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._customInputBox_1fign_284 {
  border-radius: 0.375rem;
  border: 0.0625rem solid rgb(217, 217, 217);
  background: rgb(255, 255, 255);
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._customInputBox_1fign_284 input {
  text-transform: uppercase;
  border: 0.0625rem solid rgb(217, 217, 217);
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._customInputBox_1fign_284 ._tooltipError_1fign_293 {
  position: absolute;
  right: 1.75rem;
  top: 1.3125rem;
  color: #F1515F;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._customInputBoxError_1fign_299 {
  color: #F1515F;
  font-size: 0.75rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._memberCount_1fign_272 {
  margin-top: 1rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._activeDateContainer_1fign_306 {
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._activeDateContainer_1fign_306 ._card_1fign_311 {
  width: 100%;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._activeDateContainer_1fign_306 ._dateHeader_1fign_314 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._activeDateContainer_1fign_306 ._dateContainer_1fign_320 {
  width: 100%;
}
._ManageOfferDrawer_1fign_178 ._manageOfferContainer_1fign_254 ._activeDateContainer_1fign_306 ._dateInput_1fign_323 {
  border-color: none;
  width: 100%;
}
._ManageOfferDrawer_1fign_178 ._noticeContainer_1fign_327 {
  margin-bottom: 12px;
}
._ManageOfferDrawer_1fign_178 ._noticeContainer_1fign_327 .ant-typography {
  color: #F1515F;
}
._ManageOfferDrawer_1fign_178 ._noticeContainer_1fign_327 ._iconWrapper_1fign_333 span {
  color: #F1515F;
  width: 100%;
  font-size: 21px;
}