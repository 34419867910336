import React from 'react';
import {
 Button, Card, Col, Row, Select, Space, Switch, Tabs, Tag, Typography,
} from '@revfluence/fresh';
import { XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { useApolloClient } from '@apollo/client';
import { useGetImportDataFields } from '../hooks/useGetImportDataFields';
import drawerStyles from './ImportSettingsDrawer.scss';
import styles from './ProductDataSettings.scss';
import { GET_IMPORT_DATA_FIELDS } from '../queries/importSettings';
import { IDataField, IImportDataSettings } from '../types';

const { Text } = Typography;

type OnChange = (id: string, fieldKey: string, value: string | boolean) => void;
type RemoveField = (id: string) => void;

const DefaultField = ({ field, onChange }: { field: IDataField; onChange: OnChange }) => (
  <Card key={field.id} className={styles.fieldCard}>
    <Row justify="space-between" align="middle">
      <Col>
        <Space>
          <Text>{field.fieldDisplay}</Text>
          {field.isRequired && <Tag color="processing">Required</Tag>}
        </Space>
      </Col>
      <Col>
        <Switch
          checked={field.isEnabled}
          disabled
          onChange={(checked) => onChange(field.id, 'isEnabled', checked)}
          size="small"
        />
      </Col>
    </Row>
  </Card>
);

const CustomField = ({
  field,
  onChange,
  onRemove,
}: {
  field: IDataField;
  onRemove: RemoveField;
  onChange: OnChange;
}) => (
  <Card key={field.id} className={styles.fieldCard}>
    <Row justify="space-between" align="middle">
      <Col>
        <Text>{field.fieldDisplay}</Text>
      </Col>
      <Col>
        <Space align="center">
          <Select
            size="large"
            value={field.fieldKey}
            onChange={(value) => onChange(field.id, 'fieldKey', value)}
            className={styles.selectField}
          >
            <Select.Option value="1">Metadata 1</Select.Option>
            <Select.Option value="2">Metadata 2</Select.Option>
            <Select.Option value="3">Metadata 3</Select.Option>
          </Select>
          <Button icon={<XmarkIcon />} type="text" onClick={() => onRemove(field.id)} />
        </Space>
      </Col>
    </Row>
  </Card>
);

const FieldSettings = ({ type, title }: { type: keyof IImportDataSettings, title: string }) => {
  const { importDataFields } = useGetImportDataFields();
  const client = useApolloClient();

  if (!importDataFields) {
    return null;
  }

  // const addCustomField = () => {
  //   let existingCustomFields = 0;
  //   client.writeQuery({
  //     query: GET_IMPORT_DATA_FIELDS,
  //     data: {
  //       importDataFields: {
  //           ...importDataFields,
  //         [type]: [
  //           ...importDataFields[type].map((field) => {
  //             if (field.isCustom) {
  //               existingCustomFields += 1;
  //               return { ...field, fieldDisplay: `Additional Data ${existingCustomFields}` };
  //             }
  //             return field;
  //           }),
  //           {
  //             id: `${Math.random()}`,
  //             order: importDataFields[type].length,
  //             fieldKey: '1',
  //             fieldDisplay: `Additional Data ${existingCustomFields + 1}`,
  //             isEnabled: true,
  //             isCustom: true,
  //             isRequired: false,
  //           },
  //         ],
  //       },
  //     },
  //   });
  // };

  const removeCustomField = (id: string) => {
    client.writeQuery({
      query: GET_IMPORT_DATA_FIELDS,
      data: {
        importDataFields: {
            ...importDataFields,
          [type]: importDataFields[type].filter((field) => field.id !== id),
        },
      },
    });
  };

  const updateField: OnChange = (id, fieldKey, value) => {
    client.writeQuery({
      query: GET_IMPORT_DATA_FIELDS,
      data: {
        importDataFields: {
            ...importDataFields,
          [type]: importDataFields[type].map((field) =>
            (field.id === id ? { ...field, [fieldKey]: value } : field)),
        },
      },
    });
  };

  return (
    <Space direction="vertical" className={`${drawerStyles.spaceFlex} ${styles.ProductDataSettings}`}>
      <Text weight="semibold">{title}</Text>
      {importDataFields[type]?.map((field) =>
        (field.isCustom ? (
          <CustomField field={field} onChange={updateField} onRemove={removeCustomField} />
        ) : (
          <DefaultField field={field} onChange={updateField} />
        )))}
      {/* <Button type="link" icon={<PlusIcon />} onClick={addCustomField}>
        Add Additional Field
      </Button> */}
    </Space>
  );
};

export const ProductDataSettingsFooter = () => (
  <Row align="middle" justify="center" gutter={24}>
    {/* <Col flex="215px">
      <Button className={drawerStyles.footerBtn} size="large">Cancel</Button>
    </Col>
    <Col flex="215px">
      <Button type="primary" className={drawerStyles.footerBtn} size="large">
        Update Products
        <ChevronRightIcon />
      </Button>
    </Col> */}
  </Row>
    );

export const ProductDataSettings = () => (
  <Space direction="vertical" size="large" style={{ width: '100%' }}>
    <Space direction="vertical" size={0}>
      {/* <Text>Select the product data you want to import</Text> */}
      <Text>Fields to be imported</Text>
      {/* <Text>
        You can select the specific product data fields to import into the Aspire product master. All products will
        consistently have the same data fields.
      </Text> */}
    </Space>
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          children: <FieldSettings type="collectionFields" title="Import Collection Data Fields " />,
          key: '1',
          label: 'Collection Data',
        },
        {
          children: <FieldSettings type="productFields" title="Import Product Data Fields " />,
          key: '2',
          label: 'Product Data',
        },
        {
          children: <FieldSettings type="variantFields" title="Import Product Variant Data Fields " />,
          key: '3',
          label: 'Variant Data',
        },
      ]}
    />
  </Space>
);
