import * as React from 'react';
import cx from 'classnames';
import {
 size, filter, uniq,
} from 'lodash';

import {
  IContent, ILicensedContent,
  Notice, Toast, IToastRefHandles,
  OverlaySpinner, UploadModal,
} from '@components';
import {
  useMobileStatus,
  useElementResize,
} from '@frontend/utils';
import ContentFiltersHeader from './ContentFiltersHeader';
import ContentFilters from './ContentFilters';
import { ContentLibraryList } from './ContentLibraryList';
import ContentListEmpty from './ContentListEmpty';
import ContentActionBar from './ContentActionBar';
import TagsModal from './TagsModal';
import EditPermissionsModal, { IPermissionUpdate } from './EditPermissionsModal';
import ContentOverlay from './ContentOverlay';
import { IContentFilters, IStore, TSortType } from './redux/models';
import { getNetworkOptions } from './utils/networkOptions';
import { getLikedContentIds } from './utils/contentIds';

const {
 useMemo, useState, useRef, useEffect,
} = React;

import styles from './ContentPage.scss';

interface IProps extends IStore {
  onChangeFilters(filters: IContentFilters);
  onSelectedContentChange(contentId: string, selected: boolean);
  onToggleLikedContent?(content: ILicensedContent);
  onConfirmContentUpload(content: IContent[]);
  onToggleSelectAllContent();
  onSaveTags(content: ILicensedContent[], tags: string[]);
  onRemoveTag(content: ILicensedContent, tag: string);
  onSavePermissions(content: ILicensedContent[], update: IPermissionUpdate);
  onDeleteContent(content: ILicensedContent[]);
  onDownloadContent(content: ILicensedContent[]);
  onChangeSort(sort: TSortType);
  onLoadNextPage();

  onSearchClick?(content: ILicensedContent, event: React.MouseEvent);
  onAssignClick?(content: ILicensedContent, event: React.MouseEvent);
  onVisitProfileClick(content: ILicensedContent, event: React.MouseEvent);
  onVisitManageClick(content: ILicensedContent, event: React.MouseEvent);
  onVisitAdminClick(content: ILicensedContent, event: React.MouseEvent);
  onToggleDetailView(content: ILicensedContent, event: React.MouseEvent);

  className?: string;
}

const ContentPage: React.FunctionComponent<IProps> = React.memo((props) => {
  const toastRef = useRef<IToastRefHandles>();
  const contentRef = useRef<HTMLDivElement>();
  const contentDOMRect = useElementResize(contentRef);
  const mobileType = useMobileStatus();
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showEditPermissionModal, setShowEditPermissionsModal] = useState(false);
  const [selectedContentForEdit, setSelectedContentForEdit] = useState([]);

  useEffect(() => {
    if (props.toast) {
      toastRef.current.showMessage({
        ...props.toast,
        duration: 5000,
      });

      if (showUploadModal) {
        handleCloseUploadModal();
      }
    }
  }, [props.toast, showUploadModal]);

  const getSelectedContent = () =>
    filter(props.content, (content) => props.selectedContent[content.id]);

  const handleClickAddContent = () => setShowUploadModal(true);
  const handleCloseUploadModal = () => setShowUploadModal(false);
  const handleCloseEditTags = () => setShowTagsModal(false);
  const handleCloseEditPermissions = () => setShowEditPermissionsModal(false);

  const handleClickEditTags = (content: ILicensedContent) => {
    setSelectedContentForEdit([content]);
    setShowTagsModal(true);
  };

  const handleClickEditPermissions = () => {
    setSelectedContentForEdit(getSelectedContent());
    setShowEditPermissionsModal(true);
  };

  const handleClickEditTagsFromActionBar = () => {
    setSelectedContentForEdit(getSelectedContent());
    setShowTagsModal(true);
  };

  const handleUploadConfirmClick = (content: IContent[]) => {
    props.onConfirmContentUpload(content);
  };

  const handleSaveTags = (content: ILicensedContent[], tags: string[]) => {
    handleCloseEditTags();
    props.onSaveTags(content, tags);
  };

  const handleSavePermissions = (content: ILicensedContent[], update: IPermissionUpdate) => {
    handleCloseEditPermissions();
    props.onSavePermissions(content, update);
  };

  const handleDeleteTag = (tag: string, content: ILicensedContent) => {
    props.onRemoveTag(content, tag);
  };

  const handleTagClick = (_, tag: string) => {
    const newFilters: IContentFilters = {
      ...props.filters,
      selectedTags: uniq([...props.filters.selectedTags, tag]),
    };
    props.onChangeFilters(newFilters);
  };

  const handleClickDeleteContent = () => {
    props.onDeleteContent(getSelectedContent());
  };

  const handleClickDownloadContent = () => {
    props.onDownloadContent(getSelectedContent());
  };

  const handleClickDownloadSingleContent = (content: ILicensedContent) => {
    props.onDownloadContent([content]);
  };

  const handleCloseDetailView = () =>
    props.onToggleDetailView(props.content[props.contentDetailIndex], null);

  const networks = useMemo(() => getNetworkOptions(props.organization), [props.organization]);

  const likedContentIds = useMemo(() => getLikedContentIds(props.activeBrand), [props.activeBrand]);

  const showCreateFeatures = props.organization !== null && props.organization !== undefined;

  return (
    <div className={cx(styles.ContentPage, props.className, { [styles.mobile]: !!mobileType })}>
      {!props.hideFilters && (
        <div className={styles.leftCol}>
          <div className={styles.leftFixed}>
            {showCreateFeatures && props.activeBrand
              && (
              <ContentFiltersHeader
                activeBrand={props.activeBrand}
                contentShareURL={props.activeBrand.content_share_url}
                onClickAddContent={handleClickAddContent}
              />
)}
            <ContentFilters
              filters={props.filters}
              campaigns={props.campaigns}
              networks={networks}
              likedContentIds={likedContentIds}
              onChange={props.onChangeFilters}
              additionalFilterOptions={props.additionalFilterOptions}
            />
          </div>
        </div>
)}
      <div className={cx({
        [styles.rightCol]: !props.hideFilters,
      })}
      >
        <div className={styles.toolbar} style={{ width: contentDOMRect && contentDOMRect.width }}>
          <ContentActionBar
            selectedContentSize={size(props.selectedContent)}
            isAllSelected={props.isAllSelected}
            sort={props.sort}
            contentSize={size(props.content)}
            totalContent={props.totalCount}
            isLoading={props.isLoading}
            onToggleSelectAll={props.onToggleSelectAllContent}
            onClickEditTags={handleClickEditTagsFromActionBar}
            onClickEditPermissions={handleClickEditPermissions}
            onClickDeleteContent={handleClickDeleteContent}
            onClickDownload={handleClickDownloadContent}
            onChangeSort={props.onChangeSort}
          />
        </div>
        <div ref={contentRef}>
          {props.errorMessage ? (
            <Notice type="error" showDivider={false}>
              {props.errorMessage}
            </Notice>
          ) : null}
          <ContentLibraryList
            contents={props.content}
            isLoading={props.isLoading}
            onReachBottom={props.onLoadNextPage}
            onSearchClick={props.onSearchClick}
            onAssignClick={props.onAssignClick}
            onEditTagsClick={handleClickEditTags}
            onRemoveTagClick={handleDeleteTag}
            onTagClick={handleTagClick}
            onVisitManageClick={props.onVisitManageClick}
            onVisitProfileClick={props.onVisitProfileClick}
            onViewDetailClick={props.onToggleDetailView}
            selectedContent={props.selectedContent}
            onSelectedContentChange={props.onSelectedContentChange}
            showVisitManageLink={props.showVisitManageLink}
            toggleLikedContent={props.onToggleLikedContent}
            likedContent={props.likedContent}
            showCreateFeatures={showCreateFeatures}
            isQa={props.isQA}
            onVisitAdminClick={props.onVisitAdminClick}
            emptyListElement={
              props.errorMessage ? null : (
                <ContentListEmpty
                  filters={props.filters}
                  hideUploadContentButton={!showCreateFeatures}
                  onClickUploadContent={handleClickAddContent}
                />
              )
            }
          />
        </div>
      </div>
      <UploadModal
        title="Upload content"
        show={showUploadModal}
        postEndpoint={props.contentUploadPostEndpoint}
        uploadFolder={props.contentUploadFolder}
        onRequestClose={handleCloseUploadModal}
        onConfirmClick={handleUploadConfirmClick}
      />
      <TagsModal
        show={showTagsModal}
        onRequestClose={handleCloseEditTags}
        content={selectedContentForEdit}
        onClickSave={handleSaveTags}
      />
      <EditPermissionsModal
        show={showEditPermissionModal}
        onRequestClose={handleCloseEditPermissions}
        content={selectedContentForEdit}
        onClickSave={handleSavePermissions}
      />
      <ContentOverlay
        show={props.showContentDetail}
        initialContentIndex={props.contentDetailIndex}
        contents={props.content}
        onRequestClose={handleCloseDetailView}
        onClickDownload={handleClickDownloadSingleContent}
      />
      {props.showSpinner && <OverlaySpinner />}
      <Toast ref={toastRef} />
    </div>
  );
});

export default ContentPage;
