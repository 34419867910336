import {
  IState,
  Step,
} from '../../types/state';

export const getInitialState = (featureFlags: IState['featureFlags']): IState => ({
  collaborationDetails: {
    adsPermissions: {
      requireIgPartnershipAdsAccess: false,
      metaBamAccess: {
        toggle: false,
        startDate: null,
        endDate: null,
      },
      bcPromoteAccess: {
        toggle: false,
        startDate: null,
        endDate: null,
      },
      tiktokSparkAdsAccess: {
        toggle: false,
        duration: null,
      },
    },
    brief: {
      toggleBrief: false,
      briefUrl: '',
    },
    reward: {
      payment: {
        showHideNewPrice: false,
        toggleSpecialPaymentTerms: false,
        newPrice: null,
        specialPaymentTerms: '',
      },
      freeProduct: {
        showHideDescription: false,
        description: '',
      },
      commission: {
        showHideDescription: false,
        description: '',
      },
      noCompensation: {
        showHideDescription: false,
      },
    },
    contentRight: {
      type: '',
      text: '',
    },
    bam: {
      toggleInfluencerBrandedContent: false,
      toggleInfluencerWhitelist: false,
      toggleInstagramInsights: false,
      startDateAccess: null,
      endDateAccess: null,
    },
  },
  contentGuidelines: [],
  resourceId: null,
  emailContent: null,
  members: [],
  saving: false,
  emailSubject: 'Please Review Our Collaboration Terms',
  emailAttachments: [],
  step: Step.AddContentGuidelines,
  featureFlags,
});
