import 'css-chunk:src/applications/AffiliatesApp/components/MembersWizard/components/MembersWizard.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_8bvok_157",
  "justify-content-space-between": "_justify-content-space-between_8bvok_161",
  "tabular-nums": "_tabular-nums_8bvok_165",
  "MembersWizard": "_MembersWizard_8bvok_169",
  "inputWrapper": "_inputWrapper_8bvok_169",
  "linkInput": "_linkInput_8bvok_169",
  "codeInput": "_codeInput_8bvok_169",
  "wizardNextButton": "_wizardNextButton_8bvok_610",
  "statsCard": "_statsCard_8bvok_626",
  "alert": "_alert_8bvok_630",
  "activeDatesForm": "_activeDatesForm_8bvok_633",
  "dateTimePicker": "_dateTimePicker_8bvok_640",
  "icon": "_icon_8bvok_643",
  "confirmClose": "_confirmClose_8bvok_646",
  "membersListWarning": "_membersListWarning_8bvok_647",
  "title": "_title_8bvok_664",
  "buttons": "_buttons_8bvok_668",
  "urlInputWarning": "_urlInputWarning_8bvok_730",
  "urlInputError": "_urlInputError_8bvok_733",
  "codeHasError": "_codeHasError_8bvok_736",
  "enterCodeLabel": "_enterCodeLabel_8bvok_747",
  "wizardButton": "_wizardButton_8bvok_750",
  "hidden": "_hidden_8bvok_753",
  "tablePadding": "_tablePadding_8bvok_767",
  "fullWidth": "_fullWidth_8bvok_770",
  "projectSelect": "_projectSelect_8bvok_773",
  "emptyContainerMargin": "_emptyContainerMargin_8bvok_776",
  "loading": "_loading_8bvok_779",
  "show": "_show_8bvok_1"
};