import gql from 'graphql-tag';

export const GET_DASHBOARD_STATS_QUERY = gql`
  query GetDashboardStatistics($startDate: DateTime, $endDate: DateTime) {
    summary: advertiserStatSummary(startDate: $startDate, endDate: $endDate) {
      avgSale
      clicks
      conversions
      members
      offers
      payoutEarned
      payoutMade
      sales
    }
  }
`;
