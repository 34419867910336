import * as React from 'react';
import { useEffect, useState } from 'react';
import { flatMap, map } from 'lodash';

import { Radio } from '@revfluence/fresh';
import { ArrowsRotateIcon, CircleCheckIcon as CircleCheckIconSolid } from '@revfluence/fresh-icons/solid/esm';

import { Comments } from '@frontend/app/components/GroupContentReview/Comments';
import { Guidelines } from '@frontend/app/components/GroupContentReview/Guidelines';
import { ActionButtons } from '@frontend/app/components/GroupContentReview/ActionButtons';
import { ActionFeedbackForm } from '@frontend/app/components/GroupContentReview/ActionFeedbackForm';
import { TCheckoffGuidelines } from '@frontend/app/types/GroupContentReview';

import { TDetails } from '../../types';
import styles from './Details.module.scss';

export const Details = ({
  comments, guidelines,
  takingActions, canTakeActions,
  onApprove, canApprove,
  onReject, canReject,
}: TDetails) => {
  const [view, setView] = React.useState('comments');
  const [isRejecting, setIsRejecting] = useState(false);
  const [comment, setComment] = useState('');
  const [guidelineCheckOffs, setGuidelineCheckOffs] = useState<TCheckoffGuidelines[]>([]);

  useEffect(() => {
    setGuidelineCheckOffs(guidelines.data);
  }, [guidelines]);

  const onRejectStateChange = (comment: string, checkOffs?: boolean[]) => {
    setIsRejecting(false);
    onReject(comment, checkOffs);
  };

  const onSubmissionClick = () => {
    const checkOffs = flatMap(guidelineCheckOffs, (g) => map(g.rules, (r) => r.required));
    onRejectStateChange(comment, checkOffs);
  };

  const buttonConfigs = [
    {
      label: 'Request Changes',
      onClick: () => setIsRejecting(true),
      icon: <ArrowsRotateIcon style={{ color: '#167CF4' }} />,
      disabled: !canReject,
    },
    {
      label: 'Approve',
      onClick: onApprove,
      icon: <CircleCheckIconSolid style={{ color: '#389E0D' }} />,
      disabled: !canApprove,
    },
  ];

  return (
    <div className={styles.Details}>
      <Radio.Group
        value={view}
        onChange={(event) => setView(event.target.value)}
        buttonStyle="solid"
        className={styles.navigation}
        size="middle"
      >
        <Radio.Button value="comments">Comments</Radio.Button>
        <Radio.Button value="guidelines">Guidelines</Radio.Button>
      </Radio.Group>
      {isRejecting && (
        <ActionFeedbackForm
          onCancel={() => setIsRejecting(false)}
          comment={comment}
          onCommentChange={(comment) => setComment(comment)}
          instructionText="Which guidelines are not addressed?"
          submissionCTA="Submit Rejection"
          onSubmissionClick={onSubmissionClick}
          guidelineCheckOffs={guidelineCheckOffs}
          onGuidelineChange={(guidelines) => setGuidelineCheckOffs(guidelines)}
        />
      )}
      {!isRejecting && (
        <div className={styles.main}>
          <div className={styles.content}>
            {view === 'comments' && <Comments {...comments} />}
            {view === 'guidelines' && <Guidelines {...guidelines} />}
          </div>
          {canTakeActions && (
            <div className={styles.actions}>
              <ActionButtons
                actionOptions={buttonConfigs}
                takingActions={takingActions}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
