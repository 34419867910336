/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_tizcn_157 {
  display: flex;
}

._justify-content-space-between_tizcn_161 {
  justify-content: space-between;
}

._tabular-nums_tizcn_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_tizcn_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_tizcn_157 {
  display: flex;
}

._justify-content-space-between_tizcn_161 {
  justify-content: space-between;
}

._tabular-nums_tizcn_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_tizcn_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._StartProjectButton_tizcn_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._StartProjectButton_tizcn_355 {
  border-color: var(--primary);
  background-color: var(--primary);
}
._StartProjectButton_tizcn_355:focus, ._StartProjectButton_tizcn_355:hover {
  background-color: #2e8af5;
  color: #fff;
}
._StartProjectButton_tizcn_355:disabled {
  color: #fff;
  opacity: 0.8;
}

._StartProjectButton_tizcn_355 {
  padding-left: 0.9375rem;
}
._StartProjectButton_tizcn_355[disabled] {
  color: #8f8d91;
}
._StartProjectButton_tizcn_355 ._icon_tizcn_384 {
  margin-left: 0.25rem;
}
._StartProjectButton_tizcn_355:focus {
  border-color: transparent !important;
}

._overlay_tizcn_391 {
  background-color: #fff;
}

._label_tizcn_395 {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  height: 2rem;
}
._label_tizcn_395 ._thumb_tizcn_402 {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
  margin-right: 0.5rem;
  border-radius: 0.25rem;
}

._menu_tizcn_410 {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 15rem;
  max-height: 23.875rem;
  padding-bottom: 0 !important;
}
._menu_tizcn_410 .ant-dropdown-menu-submenu-expand-icon {
  top: 50%;
  transform: translateY(-50%);
}
._menu_tizcn_410 ._createProjectItem_tizcn_422 {
  position: sticky;
  bottom: 0;
  margin-top: 0.5rem;
  padding: 0.75rem 1rem !important;
  border-top: 0.0625rem solid #ececec;
  background-color: #fdfdfd;
}
._menu_tizcn_410 ._createProjectItem_tizcn_422:hover {
  color: #0075ff;
}

._projectTitleItem_tizcn_434 {
  max-width: 15rem;
  white-space: normal;
}