export enum SpecialFilters {
  PROGRAMS = 'programs',
  ACTIVATIONS = 'activations',
  TAGS = 'tags',
  COMMUNITIES = 'communities',
  OWNERS = 'owners',
  HIGHLIGHTS = 'highlights',
  OVERDUE_REQUIREMENTS = 'overdueRequirements',
  INCOMPLETE_REQUIREMENTS = 'incompleteRequirements',
  INVITED_PROGRAMS = 'invitedPrograms',
  SUBMITTED_PROGRAMS = 'submittedPrograms',
  REJECTED_PROGRAMS = 'rejectedPrograms',
  DATA_SOURCES = 'dataSources',
  APPLICANT_SOURCE = 'applicantSource',
  QUICK_FILTER = 'quickFilter',
}
