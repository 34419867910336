import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  some,
  find,
  get,
  isArray,
  isEmpty,
  isFunction,
  isNil,
  isUndefined,
  isString,
  keyBy,
  map,
  isObject,
  trim,
} from 'lodash';
import {
  Button,
  Popover,
  Select,
} from 'antd';
import { PopoverProps } from 'antd/lib/popover';

import {
  Operator,
  ProgramMembershipStatusType,
} from '@frontend/app/types/MemberSearch';
import { FieldType } from '@frontend/app/types/Fields';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';

import {
  ActivationInclusion,
  AnnualBetween,
  AnnualEqual,
  AnnualRelative,
  BooleanEqual,
  DateBetween,
  DateEqual,
  DaysRelative,
  HighlightInclusion,
  IImagesEqualHandles,
  ImagesEqual,
  INumberBetweenHandles,
  INumberEqualHandles,
  ITextEqualHandles,
  NumberBetween,
  NumberEqual,
  OwnerInclusion,
  ProgramInclusion,
  TagInclusion,
  TextEqual,
} from './fieldTypes';

import { IFilter } from './model';
import { CommunityInclusion } from './fieldTypes/CommunityInclusion';
import { ArrayInclusion } from './fieldTypes/ArrayInclusion';

import styles from './FilterFormPopover.scss';

const {
 useEffect, useImperativeHandle, useMemo, useRef, useState,
} = React;

interface IProps {
  field?: IField;
  fields: IField[];
  filter?: IFilter;
  onAddFilter(filter: IFilter): void;
  popoverProps?: PopoverProps;
}

export enum RelativeDateDirection {
  PAST = 'past',
  FUTURE = 'future',
}
export interface IFilterFormPopoverHandles {
  hide(): void;
  show(): void;
}

type TSelectedOperator = (
  Operator.EQUAL |
  Operator.NOT_EQUAL |
  Operator.BETWEEN |
  Operator.CONTAINS |
  Operator.GREATER_THAN |
  Operator.LESS_THAN |
  Operator.NOT_NULL |
  Operator.IN_THE_LAST |
  Operator.IN_THE_NEXT |
  Operator.MORE_THAN_AGO |
  Operator.IS_NULL
);

export enum ProgramStatus {
  INVITED = 'invitedPrograms',
  SUBMITTED = 'submittedPrograms',
  REJECTED = 'rejectedPrograms',
}
interface IOperator {
  value: TSelectedOperator;
  label: string;
}

const TextOperatorOptions: IOperator[] = [
  { value: Operator.EQUAL, label: 'is' },
  { value: Operator.NOT_EQUAL, label: 'is not' },
  { value: Operator.CONTAINS, label: 'contains' },
  { value: Operator.NOT_NULL, label: 'has value' },
  { value: Operator.IS_NULL, label: 'has no value' },
];

const ArrayOperatorOptions: IOperator[] = [
  { value: Operator.CONTAINS, label: 'is' },
  { value: Operator.NOT_NULL, label: 'has value' },
  { value: Operator.IS_NULL, label: 'has no value' },
];

const ImagesOperatorOptions: IOperator[] = [
  { value: Operator.EQUAL, label: 'is' },
  { value: Operator.NOT_EQUAL, label: 'is not' },
  { value: Operator.CONTAINS, label: 'contains' },
  { value: Operator.NOT_NULL, label: 'has value' },
  { value: Operator.IS_NULL, label: 'has no value' },
];

const BooleanOperatorOptions: IOperator[] = [
  { value: Operator.EQUAL, label: 'is' },
  { value: Operator.NOT_EQUAL, label: 'is not' },
  { value: Operator.NOT_NULL, label: 'has value' },
  { value: Operator.IS_NULL, label: 'has no value' },
];

const NumberOperatorOptions: IOperator[] = [
  { value: Operator.EQUAL, label: 'is' },
  { value: Operator.NOT_EQUAL, label: 'is not' },
  { value: Operator.BETWEEN, label: 'between' },
  { value: Operator.GREATER_THAN, label: 'greater than' },
  { value: Operator.LESS_THAN, label: 'less than' },
  { value: Operator.NOT_NULL, label: 'has value' },
  { value: Operator.IS_NULL, label: 'has no value' },
];

const AnnualOperatorOptions: IOperator[] = [
  { value: Operator.EQUAL, label: 'is' },
  { value: Operator.NOT_EQUAL, label: 'is not' },
  { value: Operator.BETWEEN, label: 'between' },
  { value: Operator.GREATER_THAN, label: 'after' },
  { value: Operator.LESS_THAN, label: 'before' },
  { value: Operator.IN_THE_LAST, label: 'in the last ...' },
  { value: Operator.IN_THE_NEXT, label: 'in the next ...' },
  { value: Operator.MORE_THAN_AGO, label: 'more than ... days ago' },
  { value: Operator.NOT_NULL, label: 'has value' },
  { value: Operator.IS_NULL, label: 'has no value' },
];

const DateOperatorOptions: IOperator[] = [
  { value: Operator.EQUAL, label: 'is' },
  { value: Operator.NOT_EQUAL, label: 'is not' },
  { value: Operator.BETWEEN, label: 'between' },
  { value: Operator.GREATER_THAN, label: 'after' },
  { value: Operator.LESS_THAN, label: 'before' },
  { value: Operator.IN_THE_LAST, label: 'in the last...' },
  { value: Operator.IN_THE_NEXT, label: 'in the next...' },
  { value: Operator.MORE_THAN_AGO, label: 'more than ... days ago' },
  { value: Operator.NOT_NULL, label: 'has value' },
  { value: Operator.IS_NULL, label: 'has no value' },
];

const SpecialOperatorOptions: IOperator[] = [
  { value: Operator.EQUAL, label: 'is' },
  { value: Operator.NOT_EQUAL, label: 'is not' },
  { value: Operator.NOT_NULL, label: 'has value' },
  { value: Operator.IS_NULL, label: 'has no value' },
];

const DynamicOperatorOptions: IOperator[] = [
  { value: Operator.EQUAL, label: 'is' },
  { value: Operator.NOT_EQUAL, label: 'is not' },
];

const CommunitiesStatusOperatorOptions: IOperator[] = [
  { value: Operator.EQUAL, label: 'is' },
  { value: Operator.NOT_EQUAL, label: 'is not' },
  { value: Operator.NOT_NULL, label: 'has value' },
  { value: Operator.IS_NULL, label: 'has no value' },
];

const ProgramsStatusOperatorOptions: IOperator[] = [
  { value: Operator.EQUAL, label: 'is' },
  { value: Operator.NOT_EQUAL, label: 'is not' },
  { value: Operator.NOT_NULL, label: 'has value' },
  { value: Operator.IS_NULL, label: 'has no value' },
];

const DefaultFieldValues = {
  [FieldType.NUMBER]: {
    [Operator.EQUAL]: null,
    [Operator.NOT_EQUAL]: null,
    [Operator.BETWEEN]: null,
    [Operator.GREATER_THAN]: null,
    [Operator.LESS_THAN]: null,
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.BOOLEAN]: {
    [Operator.EQUAL]: true,
    [Operator.NOT_EQUAL]: true,
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.DATE]: {
    [Operator.EQUAL]: null,
    [Operator.NOT_EQUAL]: null,
    [Operator.BETWEEN]: null,
    [Operator.GREATER_THAN]: null,
    [Operator.IN_THE_LAST]: '',
    [Operator.IN_THE_NEXT]: '',
    [Operator.MORE_THAN_AGO]: '',
    [Operator.LESS_THAN]: null,
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.ANNUAL]: {
    [Operator.EQUAL]: null,
    [Operator.NOT_EQUAL]: null,
    [Operator.BETWEEN]: null,
    [Operator.GREATER_THAN]: null,
    [Operator.IN_THE_LAST]: '',
    [Operator.IN_THE_NEXT]: '',
    [Operator.MORE_THAN_AGO]: '',
    [Operator.LESS_THAN]: null,
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.TEXT]: {
    [Operator.EQUAL]: '',
    [Operator.NOT_EQUAL]: '',
    [Operator.CONTAINS]: '',
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.EMAIL]: {
    [Operator.EQUAL]: '',
    [Operator.NOT_EQUAL]: '',
    [Operator.CONTAINS]: '',
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.IMAGES]: {
    [Operator.EQUAL]: '',
    [Operator.NOT_EQUAL]: '',
    [Operator.CONTAINS]: '',
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.ACTIVATION]: {
    [Operator.EQUAL]: [],
    [Operator.NOT_EQUAL]: [],
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.TAG]: {
    [Operator.EQUAL]: [],
    [Operator.NOT_EQUAL]: [],
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.PROGRAM]: {
    [Operator.EQUAL]: {
      programIds: [],
      programStatus: ProgramMembershipStatusType.APPROVED,
    },
    [Operator.NOT_EQUAL]: {
      programIds: [],
      programStatus: ProgramMembershipStatusType.APPROVED,
    },
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.COMMUNITY]: {
    [Operator.EQUAL]: {
      communityIds: [],
    },
    [Operator.NOT_EQUAL]: {
      communityIds: [],
    },
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.OWNERS]: {
    [Operator.EQUAL]: [],
    [Operator.NOT_EQUAL]: [],
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.HIGHLIGHT]: {
    [Operator.EQUAL]: [],
    [Operator.NOT_EQUAL]: [],
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
  [FieldType.ARRAY]: {
    [Operator.CONTAINS]: [],
    [Operator.NOT_NULL]: true,
    [Operator.IS_NULL]: true,
  },
};

export const FilterFormPopover = React.forwardRef<IFilterFormPopoverHandles, React.PropsWithChildren<IProps>>((props, ref) => {
  const {
    children,
    fields,
    field,
    filter,
    onAddFilter,
    popoverProps,
  } = props;

  const [isVisible, setVisible] = useState(false);
  useImperativeHandle(ref, () => ({
    hide: () => setVisible(false),
    show: () => setVisible(true),
  }));

  const fieldsByIdentifier = useMemo(() => (
    keyBy(fields, 'field')
  ), [fields]);

  const selectedSchema = useMemo(() => {
    if (filter) {
      const { memberFieldSchemaId, column } = filter;
      return memberFieldSchemaId
        ? fieldsByIdentifier[memberFieldSchemaId]
        : fieldsByIdentifier[column];
    }
  }, [filter, fieldsByIdentifier]);

  const selectedField = useMemo(() => (
    field || selectedSchema
  ), [field, selectedSchema]);

  const defaultOperator = useMemo<TSelectedOperator>(() => {
    if (!filter || !selectedField) {
      return;
    }

    const {
      memberFieldSchemaId: schemaId,
    } = filter;
    if (schemaId) {
      const schema = fieldsByIdentifier[schemaId];
      if (!schema || schema.field !== selectedField?.field) {
        return Operator.EQUAL;
      }
    } else if (filter.column !== selectedField?.field) {
      return Operator.EQUAL;
    }

    // Set operator values defined in filter by default
    return find<TSelectedOperator>(
      [
        Operator.BETWEEN,
        Operator.EQUAL,
        Operator.NOT_EQUAL,
        Operator.CONTAINS,
        Operator.GREATER_THAN,
        Operator.LESS_THAN,
        Operator.IN_THE_NEXT,
        Operator.IN_THE_LAST,
        Operator.MORE_THAN_AGO,
        Operator.NOT_NULL,
        Operator.IS_NULL,
      ],
      (operator) => !isUndefined(get(filter, operator)),
    ) || Operator.EQUAL;
  }, [filter, selectedField, fieldsByIdentifier]);

  const [selectedOperator, setSelectedOperator] = useState<TSelectedOperator>(defaultOperator);

  // Update operator on field change
  useEffect(() => {
    setSelectedOperator(defaultOperator);
  }, [defaultOperator, selectedField]);

  const isPercentage = useMemo(() => selectedField?.type === FieldType.PERCENTAGE, [selectedField?.type]);

  const defaultValue = useMemo(() => {
    if (selectedSchema && selectedField && selectedSchema.type === selectedField.type) {
      const {
        [selectedOperator]: newValue,
      } = filter;

      if (newValue) {
        return newValue;
      }
    }
    if (selectedField?.type && selectedOperator) {
      const fieldValues = DefaultFieldValues[selectedField.type];
      if (fieldValues) {
        return fieldValues[selectedOperator];
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOperator, selectedField, selectedSchema]);

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const [value, setValue] = useState<any>(defaultValue);

  useEffect(() => {
    if (!defaultValue && value && selectedField?.type === FieldType.TEXT) {
      // Just reuse either equal or contains for text type.
      if (value === true || value === 'true') {
        /** When moving from has value to contains value */
        setValue('');
      }

      return;
    }
    setValue(defaultValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const operatorOptions: IOperator[] = useMemo(() => {
    switch (selectedField?.type) {
      case FieldType.TEXT:
      case FieldType.EMAIL:
        return TextOperatorOptions;

      case FieldType.ARRAY:
        return ArrayOperatorOptions;

      case FieldType.BOOLEAN:
        return BooleanOperatorOptions;

      case FieldType.PERCENTAGE:
      case FieldType.NUMBER:
        return NumberOperatorOptions;

      case FieldType.DATE:
        return DateOperatorOptions;

      case FieldType.ANNUAL:
        return AnnualOperatorOptions;

      case FieldType.IMAGES:
        return ImagesOperatorOptions;

      case FieldType.COMMUNITY:
        return CommunitiesStatusOperatorOptions;

      case FieldType.DYNAMIC_SELECT:
        return DynamicOperatorOptions;

      case FieldType.ACTIVATION:
      case FieldType.TAG:
      case FieldType.PROGRAM:
      case FieldType.OWNERS:
      case FieldType.HIGHLIGHT:
        const { field } = selectedField;
        switch (field) {
          case ProgramStatus.INVITED:
          case ProgramStatus.SUBMITTED:
          case ProgramStatus.REJECTED:
            return ProgramsStatusOperatorOptions;
          default:
            return SpecialOperatorOptions;
        }
      default:
        return [];
    }
  }, [selectedField]);

  useEffect(() => {
    if (!filter) {
      return;
    }
    // Set operator values defined in filter by default.
    const value = filter[defaultOperator];
    if (!isUndefined(value)) {
      if (isPercentage) {
        setValue(isArray(value) ? map(value, (val) => val * 100) : value * 100);
      } else {
        setValue(value);
      }
      setSelectedOperator(defaultOperator);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, defaultOperator]);

  const handleAddFilter = () => {
    const memberFilter: IFilter = {
      id: filter ? filter.id : uuidv4(), // use either the filter's own id or generate a random one.
      [selectedOperator]: value,
    };
    const memberFieldSchemaId = selectedField?.schemaId;
    if (memberFieldSchemaId) {
      memberFilter.memberFieldSchemaId = memberFieldSchemaId;
    } else {
      memberFilter.column = selectedField?.field;
    }

    if (isPercentage) {
      if (selectedOperator === Operator.IS_NULL || selectedOperator === Operator.NOT_NULL) {
        memberFilter[selectedOperator] = true;
      } else {
        memberFilter[selectedOperator] = isArray(memberFilter[selectedOperator])
          ? map(memberFilter[selectedOperator], (v) => v / 100)
          : memberFilter[selectedOperator] / 100;
      }
    }
    onAddFilter(memberFilter);
    setVisible(false);
  };

  /**
   * Flags for showing fields
   */
  const showTextEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.CONTAINS)
    && selectedField?.type === FieldType.TEXT
    && !isArray(selectedField?.choices);

  const showEmailEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.CONTAINS)
    && selectedField?.type === FieldType.EMAIL
    && !isArray(selectedField?.choices);

  const showArrayEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.CONTAINS
  ) && isArray(selectedField?.choices) && (selectedField.type !== FieldType.DYNAMIC_SELECT);

  const showDynamicArray = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.CONTAINS
  ) && isArray(selectedField?.choices) && (selectedField.type === FieldType.DYNAMIC_SELECT);

  const showNumberEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.GREATER_THAN
    || selectedOperator === Operator.LESS_THAN
  ) && (selectedField?.type === FieldType.NUMBER || isPercentage);

  const showNumberBetween = (
    selectedOperator === Operator.BETWEEN
    && (selectedField?.type === FieldType.NUMBER || isPercentage)
  );

  const showBooleanEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
  ) && selectedField?.type === FieldType.BOOLEAN;

  const showImagesEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.CONTAINS
  ) && selectedField?.type === FieldType.IMAGES;

  const showAnnualEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.GREATER_THAN
    || selectedOperator === Operator.LESS_THAN
  ) && selectedField?.type === FieldType.ANNUAL;

  const showDateEqual = (
    selectedOperator === Operator.EQUAL
    || selectedOperator === Operator.NOT_EQUAL
    || selectedOperator === Operator.GREATER_THAN
    || selectedOperator === Operator.LESS_THAN
  ) && selectedField?.type === FieldType.DATE;

  const showInTheLastNextMore = (
    selectedOperator === Operator.IN_THE_LAST
    || selectedOperator === Operator.IN_THE_NEXT
    || selectedOperator === Operator.MORE_THAN_AGO
  ) && selectedField?.type === FieldType.DATE;

  const showAnnualInTheLastNextMore = (
    selectedOperator === Operator.IN_THE_LAST
    || selectedOperator === Operator.IN_THE_NEXT
    || selectedOperator === Operator.MORE_THAN_AGO
  ) && selectedField?.type === FieldType.ANNUAL;

  const showDateBetween = (
    selectedOperator === Operator.BETWEEN
    && selectedField?.type === FieldType.DATE
  );

  const showAnnualBetween = (
    selectedOperator === Operator.BETWEEN
    && selectedField?.type === FieldType.ANNUAL
  );

  const showHasValue = (
    selectedOperator === Operator.NOT_NULL
  );

  const showHasNoValue = (
    selectedOperator === Operator.IS_NULL
  );

  const showProgramInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.PROGRAM
  );

  const showCommunityInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.COMMUNITY
  );

  const showActivationInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.ACTIVATION
  );

  const showTagInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.TAG
  );

  const showOwnerInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.OWNERS
  );

  const showHighlightInclusion = (
    (
      selectedOperator === Operator.EQUAL
      || selectedOperator === Operator.NOT_EQUAL
    )
    && selectedField?.type === FieldType.HIGHLIGHT
  );

  /**
   * Focus on input after selecting an operator
   */
  const textEqualRef = useRef<ITextEqualHandles>();
  const emailEqualRef = useRef<ITextEqualHandles>();
  const numberEqualRef = useRef<INumberEqualHandles>();
  const numberBetweenRef = useRef<INumberBetweenHandles>();
  const imagesEqualRef = useRef<IImagesEqualHandles>();

  useEffect(() => {
    if (textEqualRef.current) {
      textEqualRef.current.focus();
    } else if (numberEqualRef.current) {
      numberEqualRef.current.focus();
    } else if (numberBetweenRef.current) {
      numberBetweenRef.current.focus();
    } else if (imagesEqualRef.current) {
      imagesEqualRef.current.focus();
    } else if (emailEqualRef.current) {
      emailEqualRef.current.focus();
    }
  }, [selectedOperator]);

  /**
   * Simple value validation
   */
  const isButtonDisabled = useMemo(() => {
    if (!selectedOperator) {
      return true;
    }

    if (showBooleanEqual) {
      return false;
    } else if (
      showTextEqual
        || showImagesEqual
        || showInTheLastNextMore
        || showAnnualInTheLastNextMore
        || showEmailEqual
    ) {
      return (
        isNil(value)
          || (isString(value) && isEmpty(trim(value)))
      );
    } else if (
      showNumberEqual
        || showAnnualEqual
        || showDateEqual
    ) {
      return isNil(value);
    } else if (
      showDateBetween
        || showAnnualBetween
        || showNumberBetween
        || showActivationInclusion
        || showTagInclusion
        || showOwnerInclusion
        || showHighlightInclusion
    ) {
      return (
        !isArray(value)
          || isEmpty(value)
          || some(value, (v) => isNil(v))
          || ((showDateBetween || showAnnualBetween || showNumberBetween) && value.length !== 2)
      );
    } else if (showProgramInclusion) {
      return (
        !isObject(value)
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        || isEmpty((value as any).programIds)
      );
    } else if (showCommunityInclusion) {
      return (
        !isObject(value)
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        || isEmpty((value as any).communityIds)
      );
    } else if (showArrayEqual || showDynamicArray) {
      if (isUndefined(value)) {
        return true;
      }

      if (isString(value)) {
        return !trim(value);
      } else {
        return isEmpty(value);
      }
    }
  }, [
    showActivationInclusion,
    showAnnualBetween,
    showArrayEqual,
    showDynamicArray,
    showAnnualEqual,
    showAnnualInTheLastNextMore,
    showBooleanEqual,
    showCommunityInclusion,
    showDateBetween,
    showDateEqual,
    showHighlightInclusion,
    showImagesEqual,
    showInTheLastNextMore,
    showNumberBetween,
    showNumberEqual,
    showOwnerInclusion,
    showProgramInclusion,
    showTagInclusion,
    showTextEqual,
    showEmailEqual,
    value,
    selectedOperator,
  ]);

  const renderForm = () => (
    <div className={styles.FilterForm}>
      <Select<TSelectedOperator>
        className={styles.select}
        value={selectedOperator}
        onChange={setSelectedOperator}
      >
        {map(operatorOptions, (operator) => (
          <Select.Option
            className={styles.selectItem}
            key={operator.value}
            value={operator.value}
          >
            {operator.label}
          </Select.Option>
        ))}
      </Select>

      {showTextEqual && (
        <TextEqual
          value={value}
          onChange={setValue}
          ref={textEqualRef}
        />
      )}

      {showEmailEqual && (
        <TextEqual
          value={value}
          onChange={setValue}
          ref={emailEqualRef}
        />
      )}

      {(showArrayEqual || showDynamicArray) && (
        <ArrayInclusion
          field={selectedField}
          onChange={setValue}
          defaultValue={value}
          isDynamic={showDynamicArray}
        />
      )}

      {showImagesEqual && (
        <ImagesEqual
          value={value}
          onChange={setValue}
          ref={imagesEqualRef}
        />
      )}
      {showBooleanEqual && (
        <BooleanEqual
          value={value}
          onChange={setValue}
        />
      )}
      {showHasValue && null}
      {showHasNoValue && null}
      {showNumberEqual && (
        <NumberEqual
          value={value}
          isPercentage={isPercentage}
          onChange={setValue}
          ref={numberEqualRef}
        />
      )}
      {showNumberBetween && (
        <NumberBetween
          value={value}
          isPercentage={isPercentage}
          onChange={setValue}
          ref={numberBetweenRef}
        />
      )}
      {showDateEqual && (
        <DateEqual
          value={value}
          onChange={setValue}
        />
      )}
      {showAnnualEqual && (
        <AnnualEqual
          value={value}
          onChange={setValue}
        />
      )}
      {showInTheLastNextMore && (
        <DaysRelative
          value={value}
          onChange={setValue}
        />
      )}
      {showDateBetween && (
        <DateBetween
          value={value}
          onChange={setValue}
        />
      )}
      {showAnnualInTheLastNextMore && (
        <AnnualRelative
          value={value}
          onChange={setValue}
        />
      )}
      {showAnnualBetween && (
        <AnnualBetween
          value={value}
          onChange={setValue}
        />
      )}
      {showProgramInclusion && (
        <ProgramInclusion
          onChange={setValue}
          defaultValue={value}
          visible={isVisible}
        />
      )}
      {showCommunityInclusion && (
        <CommunityInclusion
          onChange={setValue}
          defaultValue={value}
        />
      )}
      {showActivationInclusion && (
        <ActivationInclusion
          onChange={setValue}
          defaultValue={value}
        />
      )}
      {showTagInclusion && (
        <TagInclusion
          onChange={setValue}
          defaultValue={value}
          visible={isVisible}
        />
      )}
      {showOwnerInclusion && (
        <OwnerInclusion
          onChange={setValue}
          defaultValue={value}
          visible={isVisible}
        />
      )}

      {showHighlightInclusion && (
        <HighlightInclusion
          onChange={setValue}
          defaultValue={value}
        />
      )}

      <Button
        className={styles.button}
        type="primary"
        onClick={handleAddFilter}
        disabled={isButtonDisabled}
      >
        {filter ? 'Update Filter' : 'Add Filter'}
      </Button>
    </div>
  );

  return (
    <Popover
      trigger="click"
      placement="bottomLeft"
      title={selectedField?.headerName}
      overlayClassName={styles.FilterFormPopover}
      {...popoverProps}
      visible={isVisible}
      content={renderForm()}
      onVisibleChange={(visible) => {
        if (isFunction(popoverProps?.onVisibleChange)) {
          popoverProps.onVisibleChange(visible);
        }
        if (visible !== isVisible) {
          setVisible(visible);
        }
      }}
    >
      {children}
    </Popover>
  );
});

FilterFormPopover.displayName = 'FilterFormPopover';
