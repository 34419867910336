import { useApolloClient, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_IMPORT_DATA_FIELDS } from '../queries/importSettings';
import { IImportDataSettings } from '../types';

export const useGetImportDataFields = () => {
  const {
 data, loading, error, refetch,
} = useQuery<{ importDataFields: IImportDataSettings }>(GET_IMPORT_DATA_FIELDS);

  const client = useApolloClient();

  useEffect(() => {
    client.writeQuery({
      query: GET_IMPORT_DATA_FIELDS,
      data: {
        importDataFields: {
          collectionFields: [
            {
              id: '1',
              order: 0,
              fieldKey: 'collectionId',
              fieldDisplay: 'Collection ID',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '2',
              order: 1,
              fieldKey: 'collectionName',
              fieldDisplay: 'Collection Name',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '3',
              order: 2,
              fieldKey: 'collectionImage',
              fieldDisplay: 'Collection Image',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '4',
              order: 3,
              fieldKey: 'productsCount',
              fieldDisplay: 'Products count',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '5',
              order: 4,
              fieldKey: 'lastSyndDate',
              fieldDisplay: 'Last Sync Date',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '6',
              order: 5,
              fieldKey: 'collectionDescription',
              fieldDisplay: 'Collection Description',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '7',
              order: 6,
              fieldKey: 'collectionHandle',
              fieldDisplay: 'Collection Handle',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
          ],
          productFields: [
            {
              id: '1',
              order: 0,
              fieldKey: 'productId',
              fieldDisplay: 'Product ID',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '2',
              order: 1,
              fieldKey: 'productName',
              fieldDisplay: 'Product Name',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '3',
              order: 2,
              fieldKey: 'productImage',
              fieldDisplay: 'Product Image',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '4',
              order: 3,
              fieldKey: 'productPrice',
              fieldDisplay: 'Product Price',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '5',
              order: 4,
              fieldKey: 'productCost',
              fieldDisplay: 'Product Cost',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '6',
              order: 5,
              fieldKey: 'totalVariants',
              fieldDisplay: 'Total Variants',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '7',
              order: 6,
              fieldKey: 'inventoryQuantity',
              fieldDisplay: 'Inventory Quantity',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '8',
              order: 7,
              fieldKey: 'productDescription',
              fieldDisplay: 'Product Description',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '9',
              order: 8,
              fieldKey: 'productType',
              fieldDisplay: 'Product Type',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '10',
              order: 9,
              fieldKey: 'productVendor',
              fieldDisplay: 'Product Vendor',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '11',
              order: 10,
              fieldKey: 'productTags',
              fieldDisplay: 'Product Tags',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '12',
              order: 11,
              fieldKey: 'productHandle',
              fieldDisplay: 'Product Handle',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '13',
              order: 12,
              fieldKey: 'status',
              fieldDisplay: 'Status',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '14',
              order: 13,
              fieldKey: 'productOptions',
              fieldDisplay: 'Product Options',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
          ],
          variantFields: [
            {
              id: '1',
              order: 0,
              fieldKey: 'variantName',
              fieldDisplay: 'Variant Name',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '2',
              order: 1,
              fieldKey: 'variantSku',
              fieldDisplay: 'Variant SKU',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '3',
              order: 2,
              fieldKey: 'variantImage',
              fieldDisplay: 'Variant Image',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '4',
              order: 3,
              fieldKey: 'variantInventory',
              fieldDisplay: 'Variant Inventory',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '5',
              order: 4,
              fieldKey: 'variantPrice',
              fieldDisplay: 'Variant Price',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '6',
              order: 5,
              fieldKey: 'variantCost',
              fieldDisplay: 'Variant Cost',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '7',
              order: 6,
              fieldKey: 'displayName',
              fieldDisplay: 'Display Name',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
            {
              id: '8',
              order: 7,
              fieldKey: 'compareAtPrice',
              fieldDisplay: 'Compare At Price',
              isEnabled: true,
              isCustom: false,
              isRequired: false,
            },
          ],
        },
      },
    });
  }, [client]);

  return {
    importDataFields: data?.importDataFields,
    loading,
    error,
    refetch,
  };
};
