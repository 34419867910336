import * as React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import {
 filter,
 map,
 find,
 toString,
 isEqual,
 isNil,
 size,
 isArray,
 isEmpty,
} from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
 LazyImage, Button, LinkButton, EditIcon, CloseIcon, ExternalLinkIcon,
} from '@components';

import { Button as AntButton, Input, Modal } from 'antd';
import { ArrowLeftOutlined, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { useAuth } from '@frontend/context/authContext';
import { OUTLOOK_APP_ID, GMAIL_APP_ID } from '@frontend/app/constants/applicationIds';
import { LoadSpinner, LightningFilledIcon } from '@frontend/app/components';
import { useMessagingContext } from '@frontend/hooks';
import {
  useGetInstalledApplicationIds,
  useGetResources,
  useGetTermsName,
} from '@frontend/app/hooks';
import {
  TemplateType, TemplateTransportType, TemplateBodyType, ResourceType,
} from '@frontend/app/types/globalTypes';

import {
  useGetMessageTemplates,
  useUpdateMessageTemplateMutation,
  useCreateMessageTemplateMutation,
  useResetMessageTemplateMutation,
  useDeleteMessageTemplateMutation,
  useGetProgramById,
  useQueryParams,
  useConfirmOnExit,
} from '@frontend/app/hooks';
import {
  EmailEditor,
  IEmailEditorState,
} from '@frontend/app/components/MessageComposer/EmailEditor';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName, logger } from '@common';
import { useManageAttachments } from '@frontend/app/components';

import styles from './MessageTemplatesPage.scss';

const {
 useCallback, useState, useMemo, useEffect,
} = React;
const { confirm } = Modal;

interface IProps {
  selected;
  onSelectTemplate;
  template;
}
const getTemplateTitle = (type: TemplateType, transportType: TemplateTransportType, singularTermsName: string) => {
  const title = type === TemplateType.INVITE ? 'Invite template' : `${singularTermsName} template`;
  return title.concat(transportType === TemplateTransportType.IGDM ? ' (IGDM)' : '');
};
const TemplateRow: React.FC<IProps> = React.memo(({
  template,
  selected,
  onSelectTemplate,
}) => {
  const { singularTermsName } = useGetTermsName();
  const {
    data: {
      program = null,
    } = {},
  } = useGetProgramById({
    variables: {
      id: template?.programId,
    },
    skip: !template.programId,
  });

  return (
    <div
      key={template.id}
      className={cx([styles.templateRow,
    { [styles.selected]: selected },
    ])}
      onClick={() => {
      onSelectTemplate(template);
    }}
    >
      {
      [TemplateType.INVITE, TemplateType.TERMS].includes(template.type)
      && (
      <div className={styles.program}>
        <LightningFilledIcon
          className={styles.programIcon}
          size={12}
        />
        {program?.title}
      </div>
)
    }
      <div className={styles.title}>
        {template.title}
      </div>

      { template.transportType !== TemplateTransportType.IGDM
        && (
        <div className={styles.subject}>
          <span>Subject: </span>
          {template.subject}
        </div>
)}
      {
        [TemplateType.INVITE, TemplateType.TERMS].includes(template.type)
        && (
          <div className={cx(styles.label, { [styles.selected]: selected })}>
            { getTemplateTitle(template.type, template.transportType, singularTermsName) }
          </div>
        )
      }
    </div>
);
});

interface IMessageTemplateProps {
  classes?: string[];
  showHeader?: boolean;
}

export const MessageTemplatesPage: React.FC<IMessageTemplateProps> = (props) => {
  const history = useHistory();
  const query = useQueryParams();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [hasChanged, setHasChanged] = useState(false);
  const [editTitle, setEditTitle] = useState(false);
  const [tempTitle, setTempTitle] = useState('');
  const [tempSubject, setTempSubject] = useState('');
  const [editorState, setEditorState] = useState<IEmailEditorState>(null);
  const [newTemplates, setNewTemplates] = useState([]);

  const handleAttachmentsChange = useCallback(() => {
    setHasChanged(true);
  }, []);

  const {
    attachments,
    setInitialAttachments,
    handleSelectedFilesChange,
    handleDeleteAttachment,
    isUploadingAttachments,
  } = useManageAttachments({
    onAttachmentsChange: handleAttachmentsChange,
  });

  const { showMessage, showError, showErrorMessage } = useMessagingContext();

  const [updateTemplate] = useUpdateMessageTemplateMutation();
  const [createTemplate] = useCreateMessageTemplateMutation();
  const [resetTemplate] = useResetMessageTemplateMutation();
  const [deleteTemplate] = useDeleteMessageTemplateMutation();

  const addEvent = useEventContext();

  const installedApps = useGetInstalledApplicationIds();

  const {
    clientInfo,
  } = useAuth();

  const {
    loading,
    templates: existingTemplates,
  } = useGetMessageTemplates({
    fetchPolicy: 'cache-and-network',
  });
  const {
    resources,
    loading: loadingResources,
  } = useGetResources({
    fetchPolicy: 'no-cache',
    variables: {
    },
  });
  const filteredTemplates = useMemo(() => {
    const igdmResource = find(resources, (r) =>
      r.authProvider && !r.authProvider.systemRevokedAccess && !r.authProvider.userRevokedAccess && r.type === ResourceType.IGDM
      && !isEmpty(r.authProvider.access) && r.authProvider.access.includes('creator_marketplace'));

    return igdmResource ? existingTemplates : filter(existingTemplates, ['transportType', TemplateTransportType.EMAIL]);
  }, [existingTemplates, resources]);

  const emailAppId = useMemo(() => (
    installedApps[GMAIL_APP_ID] ? GMAIL_APP_ID : OUTLOOK_APP_ID
  ), [installedApps]);

  useEffect(() => {
    if (selectedTemplate) {
      setTempTitle(selectedTemplate.title);
      setTempSubject(selectedTemplate.subject);
      setHasChanged(false);
    }
  }, [selectedTemplate, setTempTitle, setTempSubject, setHasChanged]);

  const classNames = useMemo(() => {
    let pageClassName = styles.MessageTemplatesPage;
    let headerClassName = styles.header;
    if (isArray(props.classes)) {
      if (props.classes.length > 0) {
        pageClassName = cx(pageClassName, props.classes[0]);
      }
      if (props.classes.length > 1) {
        headerClassName = cx(headerClassName, props.classes[1]);
      }
    }
    return { pageClassName, headerClassName };
  }, [props.classes]);

  const templates = useMemo(() => {
    const allTemplates = [...newTemplates, ...filteredTemplates];
    if (searchQuery) {
      return filter(allTemplates, (template) => [template.title?.toLowerCase(), template.subject?.toLowerCase()]
          .some((str) => {
            if (isNil(str)) {
              return false;
            }
            return str.includes(searchQuery.toLowerCase());
          }));
    }

    return allTemplates;
  }, [filteredTemplates, searchQuery, newTemplates]);

  useEffect(() => {
    const templateId = query.get('templateId');
    if (templateId) {
      setSelectedTemplate(find(templates, (temp) => toString(temp.id) === templateId));
    } else if (size(templates) > 0) {
      setSelectedTemplate(templates[0]);
    }
  }, [query, templates]);

  useEffect(() => {
    if (selectedTemplate) {
      setInitialAttachments(selectedTemplate.attachments);
    }
  }, [selectedTemplate, setInitialAttachments]);

  const cleanUp = (template) => {
    setHasChanged(false);
    if (template) {
      const params = new URLSearchParams();
      params.append('templateId', template.id);
      history.push({ search: toString(params) });
    } else {
      setSelectedTemplate(null);
    }
  };

  const additionalVariables = useMemo(() => {
    switch (selectedTemplate?.type) {
      case TemplateType.BRIEF_ACCEPTED_FOLLOW_UP:
      case TemplateType.BRIEF_REMINDER:
      case TemplateType.TERMS:
        return { LINK_TO_TERMS: { label: 'Link To Brief' } };
      case TemplateType.ORDER_SELECTION_REMINDER:
        return { PRODUCT_SELECTION_PAGE: { label: 'Product Selection Page' } };
      default:
        return {};
    }
  }, [selectedTemplate]);

  const onSave = async () => {
    if (!editorState || !selectedTemplate || isUploadingAttachments()) {
      return;
    }
    if (selectedTemplate.transportType === TemplateTransportType.IGDM) {
      const textToSave = editorState.html.replace(/(<([^>]+)>)/ig, '');
      if (textToSave.length > 700) {
        showErrorMessage('IGDM template must be less than 700 characters');
        return;
      }
    }

    setHasChanged(false);

    try {
      let updatedTemplate;
      const preparedAttachments = map(attachments, (attachment) => ({
        id: attachment.id,
        name: attachment.name,
        url: attachment.fileUrl,
        size: attachment.size,
        type: attachment.type,
        uuid: attachment.uuid,
        fileUrl: attachment.fileUrl,
        localSrc: attachment.localSrc,
      }));

      if (selectedTemplate?.isNew) {
        const { data: { template } } = await createTemplate({
          variables: {
            data: {
              title: tempTitle,
              subject: tempSubject,
              text: JSON.stringify(editorState.raw),
              attachments: preparedAttachments,
            },
          },
        });
        updatedTemplate = template;
      } else {
        const { data: { template } } = await updateTemplate({
          variables: {
            id: selectedTemplate?.id,
            data: {
              title: tempTitle,
              subject: tempSubject,
              text: JSON.stringify(editorState.raw),
              attachments: preparedAttachments,
            },
          },
        });
        updatedTemplate = template;
      }

      if (selectedTemplate?.isNew) {
        showMessage({ content: 'Template has been created' });
        setNewTemplates(filter(newTemplates, (temp) => temp.id !== selectedTemplate.id));
        addEvent(EventName.MessageTemplates, {
          template_id: updatedTemplate.id,
          template_name: selectedTemplate.title,
          action: 'create',
        });
      } else {
        showMessage({
          type: 'success',
          content: 'Template has been saved',
        });
        addEvent(EventName.MessageTemplates, {
          template_id: selectedTemplate?.id,
          template_name: selectedTemplate?.title,
          action: 'save',
        });
      }
      cleanUp(updatedTemplate);
      setEditTitle(false);
    } catch (error) {
      showError(error);
    }
  };

  const onReset = () => {
    confirm({
      title: 'Are you sure you want to reset to default?',
      icon: <ExclamationCircleOutlined />,
      content: 'Resetting the template will discard all updates you’ve made after creating the template',
      okText: 'Reset',
      onOk() {
        resetTemplate({
          variables: { id: selectedTemplate?.id },
        }).then(({ data: { template } }) => {
          showMessage({ content: 'Template has been reset to default' });
          addEvent(EventName.MessageTemplates, {
            template_id: selectedTemplate?.id,
            template_name: selectedTemplate?.title,
            action: 'reset',
          });
          setSelectedTemplate(null); // To force the editor to flush
          cleanUp(template);
        })
        .catch(logger.error);
      },
    });
  };

  const onConfirmDelete = () => {
    if (!selectedTemplate?.isNew) {
      deleteTemplate({
        variables: { id: selectedTemplate?.id },
      }).then(({ data: { success } }) => {
        if (success) {
          addEvent(EventName.MessageTemplates, {
            template_id: selectedTemplate?.id,
            template_name: selectedTemplate?.title,
            action: 'delete',
          });
          showMessage({
            type: 'success',
            content: 'Template has been deleted',
          });
          cleanUp(null);
        }
      })
      .catch(logger.error);
    } else {
      setNewTemplates(filter(newTemplates, (temp) => temp.id !== selectedTemplate?.id));
      showMessage({
        type: 'success',
        content: 'Template has been deleted',
      });
      cleanUp(null);
    }
    setEditTitle(false);
  };

  const onDelete = () => {
    confirm({
      title: 'Are you sure you want to delete this template?',
      icon: <ExclamationCircleOutlined />,
      content: 'You can\'t undo this action',
      okText: 'Delete',
      onOk: onConfirmDelete,
      centered: true,
    });
  };

  const onSelectTemplate = (template) => {
    cleanUp(template);
    setEditTitle(false);
  };

  const handleChangeEditorState = useCallback((state: IEmailEditorState) => {
    if (!!editorState && !isEqual(editorState.html, state.html)) {
      setHasChanged(true);
    }
    setEditorState(state);
  }, [editorState]);

  const createNewTemplate = () => {
    // TODO Get default template values from backend. Hard coded to work on prod. first name member field schema may not line up on dev/local.
    const newTemplate = {
      isNew: true,
      id: uuidv4(),
      title: 'New Template',
      subject: 'Enter the subject here',
      text: '{"blocks":[{"key":"fe0b3","text":"Hi First Name,","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[{"offset":3,"length":10,"key":0}],"data":{}},{"key":"bv5k0","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"fkkd1","text":"Put the content for your template here.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3j351","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"3jndo","text":"Best,","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"av23b","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{"0":{"type":"variable","mutability":"IMMUTABLE","data":{"data":{"field":"MEMBER_FIELDS.39"}}}}}',
    };
    setSearchQuery('');
    setNewTemplates([newTemplate, ...newTemplates]);
    onSelectTemplate(newTemplate);
  };

  const onCustomModalOk = async (unblock, transition) => {
    try {
      if (!selectedTemplate) {
        return;
      }

      await onSave();
      addEvent(EventName.MessageTemplates, {
        template_id: selectedTemplate.id,
        template_name: selectedTemplate.title,
        action: 'save',
      });

      unblock();
      if (transition.pathname.startsWith('/settings/messageTemplates')) {
        const params = new URLSearchParams(transition.search);
        cleanUp({ id: params.get('templateId') });
      } else {
        cleanUp(null);
        history.push(transition.pathname);
      }
    } catch (error) {
      logger.error(error);
    }
  };

  const onCustomModalCancel = (unblock, transition) => {
    Promise.resolve()
    .then(cleanUp)
    .then(unblock)
    .then(() => {
      if (transition.pathname.startsWith('/settings/messageTemplates')) {
        history.push(`${transition.pathname}${transition.search}`);
      } else {
        history.push(transition.pathname);
      }
    })
    .catch(logger.error);
  };

  useConfirmOnExit({
    shouldOkNavigate: false,
    show: hasChanged,
    okText: 'Save',
    cancelText: "Don't save",
    confirmMessage: 'This template has unsaved changes. Changes that are not saved will be discarded.',
    customModalProps: {
      closeIcon: <CloseIcon size={12} />,
      closable: true,
      maskClosable: true,
      okButtonProps: {
        danger: false,
      },
    },
    onCustomModalOk,
    onCustomModalCancel,
  });

  const handleTemplateTitleKeydown = (e) => {
    if (e.key === 'Enter') {
      onSave();
    }
  };

  return (
    <div className={classNames.pageClassName}>
      { props.showHeader
        && (
        <header className={styles.pageHeader}>
          <AntButton
            className={styles.backButton}
            onClick={() => history.goBack()}
            type="text"
          >
            <ArrowLeftOutlined className={styles.leftArrowIcon} />
          </AntButton>
          <LazyImage
            src="https://storage.googleapis.com/aspirex-static-files/email-template-icon-v2.png"
            className={styles.image}
          />
          <h1 className={styles.title}>Saved Templates</h1>
        </header>
      )}
      {loading || loadingResources
        ? <LoadSpinner className={styles.loadSpinner} />
        : (
          <>
            <div className={classNames.headerClassName}>
              Message templates
              <LinkButton onClick={createNewTemplate}>Create new template</LinkButton>
            </div>
            <Input
              placeholder="Search templates..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.searchInput}
              prefix={<SearchOutlined />}
            />
            <div className={styles.container}>
              <div className={styles.left}>
                {
                map(templates, (template) => (
                  <TemplateRow
                    key={template.id}
                    template={template}
                    selected={template.id === selectedTemplate?.id}
                    onSelectTemplate={onSelectTemplate}
                  />
                ))
              }
              </div>
              <div className={styles.right}>
                {!!selectedTemplate && (
                  <div className={styles.EmailComposer}>
                    <div className={cx([styles.section, styles.titleSection])}>
                      {editTitle
                        ? (
                          <>
                            <Input
                              placeholder="Enter template title"
                              value={tempTitle}
                              onKeyDown={handleTemplateTitleKeydown}
                              onChange={(e) => {
                              setTempTitle(e.target.value);
                              setHasChanged(true);
                            }}
                            />
                            <div
                              className={cx([styles.action, styles.cancel])}
                              onClick={() => setEditTitle(false)}
                            >
                              Cancel
                            </div>
                            <div
                              className={styles.action}
                              onClick={() => {
                              onSave();
                              setEditTitle(false);
                            }}
                            >
                              Save
                            </div>
                          </>
                        )
                        : (
                          <>
                            <div className={styles.title}>
                              {selectedTemplate.title}
                            </div>
                            <div onClick={() => setEditTitle(true)} className={styles.edit}>
                              <EditIcon size={18} />
                            </div>
                          </>
                      )}
                    </div>
                    {!selectedTemplate.excludeSubject
                      && (
                        <div className={cx(styles.section, styles.subject)}>
                          <span className={styles.label}>Subject:</span>
                          <Input
                            placeholder="Enter email subject"
                            value={tempSubject}
                            onChange={(e) => {
                              setTempSubject(e.target.value);
                              setHasChanged(true);
                            }}
                          />
                        </div>
                      )}
                    <EmailEditor
                      key={selectedTemplate.id}
                      initialValue={selectedTemplate.text}
                      plaintextOnly={selectedTemplate.bodyType === TemplateBodyType.PLAINTEXT}
                      onChange={handleChangeEditorState}
                      enableVariableReplacement
                      attachments={attachments}
                      onAttachmentsSelected={handleSelectedFilesChange}
                      onDeleteAttachment={handleDeleteAttachment}
                      additionalVariables={additionalVariables}
                      // only enable program variables if it's program invite/terms template
                      showProgramVariables={!isNil(selectedTemplate.programId)}
                    />
                    <div className={cx(styles.section, styles.actions)}>
                      {
                        !selectedTemplate.programId
                        && (
                          <Button label="Delete" className={styles.deleteButton} theme="danger" onClick={onDelete} />
                        )
                      }
                      <div className={styles.primaryActions}>
                        <Button label="Reset" disabled={selectedTemplate.isNew} theme="info" onClick={onReset} />
                        <Button label="Save" theme="primary" onClick={onSave} disabled={!hasChanged} />
                      </div>
                    </div>
                  </div>
                )}
                <div className={styles.signature}>
                  Your signature will be included in all your templates.
                  {' '}
                  <a target="_blank" href={`/client/${clientInfo.id}/settings/${emailAppId}`}>
                    Edit Signature
                    <ExternalLinkIcon size={14} className={styles.externalLinkIcon} />
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

MessageTemplatesPage.displayName = 'MessageTemplatesPage';

MessageTemplatesPage.defaultProps = {
  showHeader: true,
};
