import * as React from 'react';
import {
  isEmpty,
  map,
  filter,
  isNull,
} from 'lodash';

import {
  ISocialAccount,
  SocialInsightTimeframe,
} from '@components';

import { Tag, Tooltip } from '@revfluence/fresh';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './DataTimeframeTag.scss';
import { getDataTimeframeLabel, getDataTimeframeTooltip } from '../utils';

const {} = React;

interface IProps {
  socialAccount?: ISocialAccount;
  onDataTimeframeLabelChange?: (label: string) => void;
}

const { useMemo, useEffect } = React;

export const DataTimeframeTag: React.FC<IProps> = React.memo((props) => {
  const { socialAccount, onDataTimeframeLabelChange } = props;

  if (!socialAccount || isEmpty(socialAccount.demographics_report)) {
    return <></>;
  }

  const { demographics_report: demographicsReport } = socialAccount;

  const timeframe = React.useMemo(() => {
    const dataTimeframePrioirty = [
      SocialInsightTimeframe.PAST_14_DAYS,
      SocialInsightTimeframe.PAST_30_DAYS,
      SocialInsightTimeframe.PAST_90_DAYS,
      SocialInsightTimeframe.ALL,
      SocialInsightTimeframe.ESTIMATED,
    ];

    let lowestIndex = null;
    let lowestTimeframe = null;

    let timeframes = [
      demographicsReport.age?.[0]?.timeframe || null,
      demographicsReport.city?.[0]?.timeframe || null,
      demographicsReport.country?.[0]?.timeframe || null,
      demographicsReport.ethnicity?.[0]?.timeframe || null,
      demographicsReport.gender_age?.[0]?.timeframe || null,
      demographicsReport.gender?.[0]?.timeframe || null,
      demographicsReport.interest?.[0]?.timeframe || null,
      demographicsReport.language?.[0]?.timeframe || null,
      demographicsReport.state?.[0]?.timeframe || null,
    ];
    timeframes = filter(timeframes, (timeframe) => timeframe !== null);

    map(timeframes, (timeframe) => {
      const index = dataTimeframePrioirty.indexOf(timeframe);
      if (index !== -1 && (isNull(lowestIndex) || index < lowestIndex)) {
        lowestIndex = index;
        lowestTimeframe = timeframe;
      }
    });
    return lowestTimeframe;
  }, [demographicsReport]);

  const timeframeLabel = useMemo(() => (
    getDataTimeframeLabel(timeframe)
  ), [timeframe]);

  useEffect(() => {
    if (onDataTimeframeLabelChange) {
      onDataTimeframeLabelChange(timeframeLabel);
    }
  }, [timeframeLabel, onDataTimeframeLabelChange]);

  return (
    timeframeLabel && (
      <Tooltip title={getDataTimeframeTooltip(timeframe)} zIndex={10000} placement="bottom">
        <Tag className={styles.DataTimeframeTag} icon={<CircleInfoIcon />} size="small" color="#CAE6EB">
          {timeframeLabel}
        </Tag>
      </Tooltip>
    )
  );
});

DataTimeframeTag.displayName = 'DataTimeframeTag';
