import React, { useState, useEffect } from 'react';
import {
 Avatar, Button, Drawer, Table, Tag, Typography,
} from '@revfluence/fresh';
import { ColumnsType, TableProps } from 'antd/lib/table';
import { useGetPaymenstHistoryWithBudgetLog } from '@frontend/app/hooks/budgetAllocation/useGetPaymenstHistoryWithBudgetLog';
import { DollarSignIcon } from '@revfluence/fresh-icons/regular/esm';
import { DownloadIcon } from '@revfluence/fresh-icons/regular';
import { IStepInfo as Step, WizardContainer as Wizard } from '@frontend/applications/Shared/Wizard/container';
import { SettingFilled } from '@ant-design/icons';
import { TSortType } from '@components';
import { useAuth } from '@frontend/context/authContext';
import { CustomPagination } from './CustomPagination';
import styles from './NewPaymentTable.scss';
import { AssignPaymentTo, PaymentCreationSource, TPaymentHistory } from '../../types';
import { usePayments } from '../../hooks/usePayments';
import { useExportPayments } from '../../useExportPayments';

const { Title } = Typography;

const columns: ColumnsType<TPaymentHistory> = [
  {
    title: 'DATE',
    dataIndex: 'paidDate',
    key: 'paidDate',
    sorter: true,
    render: (paidDate: Date) =>
      paidDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
    width: '10%',
  },
  {
    title: 'MEMBER',
    dataIndex: 'memberName',
    key: 'memberName',
    width: '20%',
  },
  {
    title: 'PROJECTS',
    dataIndex: 'projects',
    key: 'projects',
    width: '20%',
  },
  {
    title: 'SENT TO',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
    width: '10%',
  },
  {
    title: 'AMOUNT',
    dataIndex: 'amount',
    key: 'amount',
    sorter: true,
    render: (amount: number) => `$${amount.toFixed(2)}`,
    width: '10%',
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
  },
  {
    title: 'BUDGETS',
    dataIndex: 'budgetAccount',
    key: 'budgetAccount',
    render: (budgets: string[]) => (
      <>
        {budgets.map((budget) => (
          <Tag key={budget}>{budget}</Tag>
        ))}
      </>
    ),
    width: '20%',
  },
];

interface OrderParams {
  orderBy?: string;
  orderDirection?: TSortType;
}

const COL_NAME_TO_PROPERTY = {
  paidDate: 'date_created',
  email: 'paypal',
  amount: 'amount_spent',
};
const PAGE_SIZE = 100;
export const NewPaymentTable: React.FunctionComponent = () => {
  const [data, setData] = useState<TPaymentHistory[]>([]);
  const [current, setCurrent] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [orderParams, setOrderParams] = useState<OrderParams>({
    orderBy: 'created_date',
    orderDirection: 'DESC',
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const { user } = useAuth();
  const { save: exportPayments } = useExportPayments();
  const { state, steps, actions } = usePayments({
    memberId: null,
    paymentCreationSource: PaymentCreationSource.REASSIGN,
    assignPaymentTo: AssignPaymentTo.Project,
    applicationProps: null,
    closeModal: () => setShowDrawer(false),
  });

  const { paymentsLog, loading: paymentsHistoryLoading } = useGetPaymenstHistoryWithBudgetLog({
    variables: {
      pagination: {
        page: current,
        orderBy: orderParams?.orderBy ? COL_NAME_TO_PROPERTY[orderParams.orderBy] : null,
        orderDirection: orderParams?.orderDirection ? orderParams.orderDirection : null,
      },
    },
  });

  useEffect(() => {
    setLoading(true);
    if (!paymentsHistoryLoading) {
      const transformedData: TPaymentHistory[] = paymentsLog.paymentHistory.map((payment) => ({
        key: payment.paymentId.toString(),
        amount: Number((payment.amount / 100).toFixed(2)),
        budgetAccount: payment.budgetAccountNames,
        email: payment?.paypal ? payment.paypal : 'Pending Info',
        paidDate: new Date(parseInt(payment.paidDate, 10) * 1000),
        projects: payment.projectName,
        memberName: payment.memberName,
        status: payment.status,
      }));
      setData(transformedData);
      setTotal(paymentsLog.totalCount);
      setLoading(false);
    }
  }, [paymentsHistoryLoading, paymentsLog, setData, setTotal, setLoading]);

  useEffect(() => {
    const selectedPayments = data.filter((paymentItem) =>
      state.selectedBudgetReassign.includes(paymentItem.key));
    const amount = selectedPayments.reduce((acc, payment) => acc + payment.amount, 0);
    actions.setAmountPaying(amount);
  }, [state.selectedBudgetReassign, data, actions]);
  const handlePageChange = (page: number) => {
    setCurrent(page);
    setLoading(true);
  };

  const handleTableChange: TableProps<TPaymentHistory>['onChange'] = (_pagination, _filters, sorter) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { field, order } = sorter as any;
    const orderDirection = order === 'ascend' ? 'ASC' : 'DESC';
    if (!order) {
      setOrderParams({ orderBy: null, orderDirection: null });
    } else {
      setOrderParams({ orderBy: field, orderDirection });
    }
    setLoading(true);
  };
  // Not keeping for now
  // const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
  //   actions.setSelectedBudgetReassign(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys: state.selectedBudgetReassign,
  //   onChange: onSelectChange,
  // };

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <div className={styles.tableHeaderLeft}>
          <Title level={4}>
            Showing
            {' '}
            {data.length}
            {' '}
            payments
          </Title>
          {/* Not keeping for now, product decision */}
          {/* <Button onClick={() => setShowDrawer(true)} disabled={!state.selectedBudgetReassign.length}>
            <CoinsIcon />
            Assign To Budget
          </Button> */}
        </div>
        <div className={styles.tableHeaderRight}>
          {/* Not keeping for now */}
          {/* <Button>
            <FilterIcon />
            Filter
          </Button> */}
          <Button onClick={() => exportPayments(user.email)}>
            <DownloadIcon />
            Export
          </Button>

          <CustomPagination
            total={total}
            page={current}
            setPage={(page) => handlePageChange(page)}
            pageSize={PAGE_SIZE}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={loading}
        onChange={handleTableChange}
      />
      <Drawer
        open={showDrawer}
        width={1000}
        onClose={() => setShowDrawer(false)}
        title={(
          <div className={styles.drawerTitleContainer}>
            <Avatar shape="square" className={styles.icon} icon={<DollarSignIcon style={{ margin: '0px' }} />} />
            <Title className={styles.drawerTitle} level={4}>
              Reassign Payment to Budget
            </Title>
          </div>
        )}
        className={styles.paymentAppPadding}
      >
        <Wizard
          hideNavigation={false}
          icon={<SettingFilled />}
          step={state.currentStep}
          stepsInfo={steps as Step[]}
          noHeader
          noBodyPadding
        />
      </Drawer>
    </div>
  );
};
