import React, { useState } from 'react';
import {
 Button, Checkbox, Collapse, Input, Row, Typography, Form,
} from '@revfluence/fresh';
import { UserIcon, PencilIcon } from '@revfluence/fresh-icons/solid/esm';
import { XmarkIcon, CheckIcon, TrashIcon } from '@revfluence/fresh-icons/regular/esm';
import { MemberFragment_talentAgents } from '@frontend/app/queries/fragments/types/MemberFragment';
import styles from './TalentAgentsPanel.scss';

const { Text } = Typography;

export type formValues = {
  agentId: string;
  agentIndex: number;
  agentName: string;
  agentEmail: string;
  alwaysCC: boolean;
};

interface Props {
  agent?: MemberFragment_talentAgents;
  agentIndex: number;
  openModal?: boolean;
  onFinish: (values: formValues) => void;
  onDelete: (agentIndex: number) => void;
  existingAgentEmails: string[];
}

export const TalentAgentsPanel = (props: Props) => {
  const {
 agent, agentIndex, onFinish, onDelete, openModal, existingAgentEmails,
} = props;
  const [isOpen, setIsOpen] = useState(openModal ?? false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<formValues>();
  const agentName = agent?.agentName || '';
  const agentEmail = agent?.agentEmail || '';
  const agentId = agent?.agentId || '';
  const alwaysCC = agent?.alwaysCC || false;

  const handleValuesChange = (changedValues) => {
    setFormData((prevData) => ({
      ...prevData,
      ...changedValues,
    }));
  };

  return (
    <Collapse activeKey={isOpen ? ['1'] : []} onChange={() => setIsOpen((prev) => !prev)} style={{ overflow: 'hidden' }}>
      <Collapse.Panel
        header={(
          <>
            <UserIcon className={styles.userIcon} />
            <Text weight="semibold" className={styles.agentName}>
              Manager #
              {agentIndex}
              :
              {' '}
              {agentName || agentEmail || formData?.agentName || ''}
            </Text>
            <PencilIcon className={styles.pencilIcon} />
          </>
        )}
        showArrow={false}
        key="1"
        className={styles.agentPanelContent}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            await onFinish(values);
            setIsOpen((prev) => !prev);
          }}
          validateTrigger="onSubmit"
          onValuesChange={handleValuesChange}
          layout="vertical"
        >
          <Form.Item label="Id" name="agentId" initialValue={agentId} className={styles.hidden}>
            <Input disabled />
          </Form.Item>
          <Form.Item label="agentIndex" name="agentIndex" initialValue={agentIndex} className={styles.hidden}>
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Name"
            name="agentName"
            rules={[
              {
                required: true,
                message: "Please enter the agent's name.",
              },
            ]}
            initialValue={agentName}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="agentEmail"
            rules={[
              {
                type: 'email',
                message: 'The input is not a valid email.',
              },
              {
                required: true,
                message: 'Please enter a valid email address.',
              },
              {
                validator: (_, value) => {
                  if (existingAgentEmails?.includes(value.toLowerCase())) {
                    return Promise.reject(new Error('This agent is already added.'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            initialValue={agentEmail}
          >
            <Input />
          </Form.Item>
          <Form.Item name="alwaysCC" valuePropName="checked" initialValue={alwaysCC}>
            <Checkbox>
              <span>Always CC in emails</span>
            </Checkbox>
          </Form.Item>
          <Row align="middle">
            <Button icon={<XmarkIcon />} type="text" onClick={() => setIsOpen((prev) => !prev)} />
            <Button icon={<CheckIcon style={{ color: 'blue' }} />} type="text" htmlType="submit" />
            <Button
              icon={<TrashIcon />}
              type="text"
              className={styles.lastFormButton}
              onClick={() => onDelete(agentIndex)}
            />
          </Row>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};
