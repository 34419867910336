import * as React from 'react';
import cx from 'classnames';
import {
  first, find, map, isEmpty, unescape,
} from 'lodash';
import { Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { LazyImage } from '@components';
import { UserAvatar, AttachmentItem, IAttachment } from '@frontend/app/components';
import { AUTOMATION_IMAGE_URL } from '@frontend/app/constants/imageUrls';
import { TContentType } from '@frontend/app/hooks';
import { GetMembersQuery_members as IMember } from '@frontend/app/queries/types/GetMembersQuery';

const { Text } = Typography;

import { formatMessageDate } from '@frontend/app/utils';

import { useAuth } from '@frontend/context/authContext';
import { GetThreadQuery_thread_messages as IMessage } from '@frontend/app/queries/types/GetThreadQuery';

import { ResourceType } from '@frontend/app/types/globalTypes';
import { getMessagePayload } from '@frontend/app/containers/Shared/utils';
import { useReactiveVar } from '@apollo/client';
import { expandedMessagesVar } from '@frontend/app/containers/MessagingApp/context/MessagingAppContext';
import { GetAllUsersQuery_users } from '@frontend/app/queries/types/GetAllUsersQuery';
import styles from './EmailMessageItem.scss';

const { useMemo } = React;

interface IProps {
  members: IMember[];
  message: IMessage;
  className?: string;
  resourceType?: ResourceType;
  onToggleMessageCollapsed?: (isCollapsed: boolean, messageId: string) => void;
  users: GetAllUsersQuery_users[];
}

/**
 * @type {React.FunctionComponent}
 */
/**
 * @type {React.FunctionComponent}
 */
export const EmailMessageItem: React.FunctionComponent<IProps> = React.memo((props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    message,
    members,
    onToggleMessageCollapsed,
    users,
  } = props;
  const { user } = useAuth();

  const expandedMessages = useReactiveVar(expandedMessagesVar);
  const collapsed = !expandedMessages.includes(message.id);

  const from: {
    name: string;
    email: string;
    memberId?: number;
  } = useMemo(() => {
    if (message.payload.from) {
      if (message.payload.from === user.email) {
        return {
          name: user.name || user.nickname,
          email: user.email,
        };
      }

      return {
        name: find(users, { email: message.payload.from })?.name || message.payload.from,
        email: message.payload.from,
      };
    }

    const member = first(message.members);
    if (member) {
      return {
        name: member.name,
        email: member.email,
        memberId: member.id,
      };
    }

    return {
      name: '',
      email: '',
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, members, message]);
  const messagePayload = useMemo(
    () => getMessagePayload(message.payload),
    [message.payload],
  );

  const toggleCollapsed = () => {
    onToggleMessageCollapsed(collapsed, message.id);
  };

  const goToMemberDetails = (memberId) => {
    history.push({
      ...location,
      pathname: `/members/${memberId}`,
    });
  };

  return (
    <div
      className={cx(styles.EmailMessageItem, props.className, {
        [styles.collapsed]: collapsed,
      })}
    >
      <div
        className={styles.header}
        onClick={toggleCollapsed}
      >
        <UserAvatar className={styles.avatar} name={from.name} size={32} />
        {message.isAutomated
          && (
            <LazyImage
              src={AUTOMATION_IMAGE_URL}
              className={styles.automationIcon}
            />
          )}
        <div className={styles.info}>
          {collapsed && (
            <>
              <div className={styles.nameAndDate}>
                <Text ellipsis className={styles.name}>
                  {from.name}
                </Text>
                <div className={styles.date}>{formatMessageDate(message.internalDate)}</div>
              </div>
              <Text ellipsis className={styles.snippet}>{unescape(message.snippet)}</Text>
            </>
          )}
          {!collapsed && (
            <>
              <div className={styles.nameAndDate}>
                <Text ellipsis className={styles.name}>
                  {from.memberId
                    ? (
                      <span
                        className={styles.memberLink}
                        onClick={goToMemberDetails.bind(this, from.memberId)}
                      >
                        {from.name}
                      </span>
                    )
                    : from.name}
                  {' '}
                  {from.email != from.name && (
                  <span className={styles.email}>
                    {from.email}
                  </span>
)}
                </Text>
                <div className={styles.date}>{formatMessageDate(message.internalDate)}</div>
              </div>
              <Text ellipsis className={styles.text}>
                To:
                {' '}
                {map(message.payload.to, (emailTo, index) => {
                  const memberTo = find(members, (member) => member.email === emailTo);
                  if (memberTo) {
                    return (
                      <span key={index}>
                        {index > 0 && ', '}
                        <span
                          className={styles.memberLink}
                          onClick={goToMemberDetails.bind(this, memberTo.id)}
                        >
                          {memberTo.name}
                        </span>
                      </span>
                    );
                  }

                  return (
                    <span key={index}>
                      {index > 0 && ', '}
                      {emailTo}
                    </span>
                  );
                })}
              </Text>
            </>
          )}
        </div>
      </div>
      <div
        className={cx(styles.messageDetail, {
          [styles.collapsed]: collapsed,
        })}
      >
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: messagePayload,
          }}
        />
        {!isEmpty(message.attachments) && (
          <div className={styles.attachments}>
            {map(message.attachments, (attachment, index) => {
              if (attachment.isInline) {
                return null;
              }
              const content: IAttachment = {
                type: attachment.type as TContentType,
                size: attachment.size,
                fileUrl: attachment.href,
                name: attachment.filename,
              };

              return (
                <AttachmentItem
                  key={index}
                  resourceType={props.resourceType}
                  content={content}
                  editable={false}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
});

EmailMessageItem.displayName = 'EmailMessageItem';
