import gql from 'graphql-tag';

export const OFFER_FRAGMENT = gql`
  fragment OfferFragment on Offer {
    createdDate
    description
    expirationDate
    expired
    id
    imageUrl
    programId
    showSendConfirmationScreen
    isNewFlow
    isReadOnly
    payouts {
      id
      payoutType
      label
      flatPayout
      percentPayout
      offerId
      isMemberAdded
      createdDate
    }
    links {
      affiliates {
        affiliateId
        offerPayoutId
        affiliate {
          email
          memberId
          name
          profilePicture
          status
        }
        id
        deletedDate
      }
      defaultPayoutId
      affiliateOfferLinkGenerated
      conversionTrackingType
      conversionType
      flatPayout
      id
      payoutType
      percentPayout
      pixelCode
      postbackUrl
      status
      url
      utmFields
      domains
      creatorDeeplink
    }
    promos {
      affiliates {
        affiliateId
        offerPayoutId
        affiliate {
          email
          memberId
          name
          profilePicture
          status
        }
        endDate
        externalDiscountCode
        externalDiscountCodeGId
        id
        startDate
        deletedDate
        status
        providerMetadata
      }
      codeSuffix
      codePrefix
      disabled
      flatPayout
      id
      name
      offerId
      payoutType
      percentPayout
      prefixType
      priceRuleAmount
      priceRuleType
      source
      specialLimitNewCustomersOnly
      specialLimitOnePerSale
      specialLimitUsageCapAmount
      specialLimitUsageCapEnabled
      status
      defaultPayoutId
      usageLimitAmount
      usageLimitRule
      providerMetadata {
        productCollections {
          id
          title
        }
      }
      isSubscription
      isUngrouped
      isOneTime
      recurringCycleLimit
      discountCodeGId
      startDate
      endDate
      multiShopifyEnabled
      connectedClientMetadata{
        advertiserId
        priceRuleType
        priceRuleAmount
        status
      }
    }
    name
    type
    archivedDate
    archivedUser{
      name
      email
    }
  }
`;
