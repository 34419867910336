import * as React from 'react';
import {
 map, isNumber, size, isEmpty,
} from 'lodash';

import {
  Button,
  Dropdown,
  Menu,
} from '@revfluence/fresh';

import {
  EmailComposerModal,
  StartProjectButton,
  EllipsisIcon,
} from '@frontend/app/components';

import {
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';

import {
  useClientFeatureEnabled,
  useInstalledApplicationInstances,
  useGetApplicationsByIds,
} from '@frontend/app/hooks';

import { useMemberListContext } from '@frontend/app/context/MemberListContext';
import { ClientFeature } from '@frontend/app/constants';
import { OrganizeDropdown } from './OrganizeDropdown';
import { EditColumns } from './EditColumns';
import { RemoveFromGroupButton } from './RemoveFromGroupButton';
import { AddTags } from './AddTags';
import { useMemberPageContext } from '../hooks';

import styles from './ListHeader.scss';
import { AssignOwnersMenuItem } from './AssignOwnersMenuItem';
import { DeleteMemberMenuItem } from './DeleteMemberMenuItem';
import { BulkAppActionMenuItem } from './BulkAppActionMenuItem';
import { AddToGroupMenuItem } from './AddToGroupMenuItem';

const { useCallback, useMemo } = React;

export enum BulkMenuAction {
  AssignOwner = 'assign_owner',
  DeleteMember = 'delete_member',
  AddToGroup = 'add_to_group',
}

interface IProps {
  matchingMembers?: number;
  isApplicantList?: boolean;
  selectedMembers: IMember[];
  segmentType?: string;
  onClickApproveAll?();
  onClickRejectAll?();
  onDelete?();
}

const people = (n: number) => (n === 1 ? 'person' : 'people');
const applicants = (n: number) => (n === 1 ? 'applicant' : 'applicants');

export const ListHeader: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    searchQuery,
    columnVisibility,
    segment,
    updateColumnVisibility,
  } = useMemberListContext();
  const { communityId } = useMemberPageContext();
  const isWorkflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);

  const {
    data: {
      instances: applicationInstances = [],
    } = {},
    loading: isLoadingInstalledApplications,
  } = useInstalledApplicationInstances({
    fetchPolicy: 'no-cache',
  });

  const {
    data: {
      applications = null,
    } = {},
    loading: isLoadingApplications,
  } = useGetApplicationsByIds(applicationInstances ? map(applicationInstances, 'applicationId') : [], {
    fetchPolicy: 'no-cache',
  });

  const isLoading = useMemo(() => (
    isLoadingApplications || isLoadingInstalledApplications
  ), [isLoadingApplications, isLoadingInstalledApplications]);

  const memberTitle = useCallback((n: number) => (props.isApplicantList ? applicants(n) : people(n)), [props.isApplicantList]);

  const hasSelectedMembers = useMemo(
    () => !isEmpty(props.selectedMembers),
    [props.selectedMembers],
  );

  return (
    <div className={styles.ListHeader}>
      <div className={styles.row}>
        {hasSelectedMembers && (
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          <div className={(styles as any).count}>
            {size(props.selectedMembers)?.toLocaleString()}
            {' '}
            {memberTitle(size(props.selectedMembers))}
            {' '}
            selected
          </div>
        )}
        {!hasSelectedMembers && isNumber(props.matchingMembers) && (
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          <div className={(styles as any).count}>
            {props.matchingMembers?.toLocaleString()}
            {' '}
            {memberTitle(props.matchingMembers)}
          </div>
        )}

        {props.isApplicantList && props.matchingMembers > 0 && (
          <div className={styles.approveReject}>
            <Button
              type="primary"
              onClick={props.onClickApproveAll}
            >
              Approve All
              {' '}
              (
              {props.matchingMembers}
              )
            </Button>
            <Button
              className={styles.reject}
              onClick={props.onClickRejectAll}
            >
              Reject All
              {' '}
              (
              {props.matchingMembers}
              )
            </Button>
          </div>
        )}

        <EditColumns
          communityId={communityId}
          isWorkflowEnabled={isWorkflowEnabled}
          segment={segment}
          visibility={columnVisibility}
          onChange={updateColumnVisibility}
        />

        {
          isWorkflowEnabled && (
            <RemoveFromGroupButton
              memberIds={map(props.selectedMembers, 'id')}
              memberCount={props.matchingMembers}
              disabled={!hasSelectedMembers}
            />
          )
        }

        {
          isWorkflowEnabled && (
            <AddTags
              memberIds={map(props.selectedMembers, 'id')}
              memberCount={props.matchingMembers}
              searchQuery={searchQuery}
              disabled={!hasSelectedMembers}
            />
          )
        }

        <EmailComposerModal
          allowSendingAsSeparate
          members={props.selectedMembers}
          memberQuery={searchQuery}
          memberCount={props.matchingMembers}
          className={styles.messageComposer}
          isWorkflowEnabled={isWorkflowEnabled}
          disabled={!hasSelectedMembers}
        />

        {
          isWorkflowEnabled === false && (
            <OrganizeDropdown
              isWorkflowEnabled={isWorkflowEnabled}
              selectedMemberIds={map(props.selectedMembers, 'id')}
              memberCount={props.matchingMembers}
              isApplicantList={props.isApplicantList}
            />
          )
        }

        <Dropdown
          overlay={(
            <Menu>
              <AddToGroupMenuItem
                memberIds={map(props.selectedMembers, 'id')}
                memberCount={props.matchingMembers}
                disabled={!hasSelectedMembers}
              />
              <AssignOwnersMenuItem
                memberIds={map(props.selectedMembers, 'id')}
                disabled={!hasSelectedMembers}
              />
              <BulkAppActionMenuItem
                isWorkflowEnabled={isWorkflowEnabled}
                members={props.selectedMembers}
                memberQuery={searchQuery}
                memberCount={props.matchingMembers}
                installedApplications={applications}
              />
              <Menu.Divider />
              <DeleteMemberMenuItem
                onDelete={props.onDelete}
                memberIds={map(props.selectedMembers, 'id')}
              />
            </Menu>
          )}
          placement="bottomLeft"
          trigger={['click']}
          disabled={isLoading}
        >
          <Button
            className={styles.menuButton}
            icon={<EllipsisIcon size={18} />}
          />
        </Dropdown>

        {isWorkflowEnabled && (
          <StartProjectButton
            selectedMembers={props.selectedMembers}
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            searchQuery={searchQuery as any}
            memberCount={props.matchingMembers}
          />
        )}
      </div>
    </div>
  );
});

ListHeader.defaultProps = {
  isApplicantList: false,
};

ListHeader.displayName = 'MembersListHeader';
