import * as React from 'react';
import {
  Button, Card, Input, Select,
  Spinner,
} from '@revfluence/fresh';
import { ChartSimpleIcon } from '@revfluence/fresh-icons/solid/esm';
import {
  AssignPaymentTo,
  PaymentCreationSource,
  TBudgetPayment,
  TBudgetPeriodDetails,
} from '@frontend/applications/PaymentsApp/types';
import { TActions, TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import { useGetBudgetPeriodDetails } from '@frontend/app/hooks/budgetAllocation/useGetBudgetPeriodDetails';
import { TrashIcon } from '@revfluence/fresh-icons/regular';
import { v4 as uuidv4 } from 'uuid';
import { SubItemSmall } from '../SubItemSmall';
import styles from './BudgetSpentItem.scss';

const { Option } = Select;
const {
 useMemo, useState, useEffect, useCallback,
} = React;

interface IProps {
  actions: TActions;
  state: TState;
  paymentItem: TBudgetPayment;
  removeSplitPayment: (id: string) => void;
  programId?: number;
  isOverflowPayment?: boolean;
}

export const BudgetSpentItem: React.FC<IProps> = (props) => {
  const {
 actions, state, paymentItem, removeSplitPayment, programId, isOverflowPayment,
} = props;
  const { budgetAccounts } = state;
  const [periods, setPeriods] = useState<TBudgetPeriodDetails[]>([]);
  const { loading: loadingBudgetPeriod, periodDetails } = useGetBudgetPeriodDetails({
    variables: {
      budgetAccountId:
        ((state.paymentCreationSource === PaymentCreationSource.REASSIGN || state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING)
          && state.assignPaymentTo === AssignPaymentTo.Project
          && isOverflowPayment)
        || state.assignPaymentTo === AssignPaymentTo.Other
          ? paymentItem.budgetId
          : null,
      programId:
        state.assignPaymentTo === AssignPaymentTo.Project
        && state.paymentCreationSource === PaymentCreationSource.PROJECT
          ? programId
          : null,
    },
    skip: !paymentItem.budgetId || ![AssignPaymentTo.Other, AssignPaymentTo.Project].includes(state.assignPaymentTo),
  });

  useEffect(() => {
    if (!loadingBudgetPeriod && periodDetails?.length) {
      setPeriods(periodDetails);
    }
  }, [loadingBudgetPeriod, periodDetails]);

  const financialYear = useMemo(() => {
    if (periods.length) {
      const fiscalPeriod = [];
      periods.forEach((period) => {
        fiscalPeriod.push({
          key: uuidv4(),
          id: period.fiscalYearPeriodDefinitionId,
          label: period.fiscalYearLabel,
          granularityLabelForPayload: 'FY',
        });
        if (period.quarterDistributions?.length) {
          period.quarterDistributions.forEach((quarter) => {
            fiscalPeriod.push({
              key: uuidv4(),
              id: quarter.quarterPeriodDefinitionId,
              label: quarter.quarterLabel,
              granularityLabelForPayload: quarter.quarterKey,
            });
          });
        }
      });
      return fiscalPeriod;
    }
    return [];
  }, [periods]);

  const onChangePaymentsItem = useCallback(
    (id: string, field: string, value: number | string | null) => {
      if (!isOverflowPayment) {
        const updatedPayments = state.payments.map((payment) => {
          if (payment.id === id) {
            if (field === 'fiscalKeySelected') {
              const budgetPeriodSelected = financialYear?.find((period) => period.key === value);
              return {
                ...payment,
                fiscalKeySelected: value as string,
                budgetGranularityLabel: budgetPeriodSelected?.label,
                periodId: budgetPeriodSelected?.id,
                granularityLabelForPayload: budgetPeriodSelected.granularityLabelForPayload,
              };
            } else if (field === 'amount') {
              let newValue = value as number;
              const currentSum = state.payments.reduce((sum, p) => sum + (p.id === id ? 0 : p.amount), 0);
              const newSum = currentSum + newValue;
              if (newSum > state.amountPaying) {
                newValue = state.amountPaying - currentSum;
              }
              return { ...payment, [field]: newValue };
            }
            return { ...payment, [field]: value };
          }
          return payment;
        });

        actions.setBudgetPayments(updatedPayments);
      } else {
        const updatedPayments = state.overflowPayments.map((payment) => {
          if (payment.id === id) {
            if (field === 'fiscalKeySelected') {
              const budgetPeriodSelected = financialYear?.find((period) => period.key === value);
              return {
                ...payment,
                fiscalKeySelected: value as string,
                budgetGranularityLabel: budgetPeriodSelected?.label,
                periodId: budgetPeriodSelected?.id,
                granularityLabelForPayload: budgetPeriodSelected.granularityLabelForPayload,
              };
            } else if (field === 'amount') {
              let newValue = value as number;
              const currentSum = state.overflowPayments.reduce((sum, p) => sum + (p.id === id ? 0 : p.amount), 0);
              const newSum = currentSum + newValue;
              if (newSum > state.amountPaying) {
                newValue = state.amountPaying - currentSum;
              }
              return { ...payment, [field]: newValue };
            }
            return { ...payment, [field]: value };
          }
          return payment;
        });

        actions.setOverflowBudgetPayments(updatedPayments);
      }
    },
    [actions, isOverflowPayment, state.overflowPayments, financialYear, state.payments, state.amountPaying],
  );

  useEffect(() => {
    if (periods.length && financialYear.length) {
      onChangePaymentsItem(paymentItem.id, 'fiscalKeySelected', financialYear[0].key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periods, financialYear, paymentItem.id]);

  if (loadingBudgetPeriod) {
    return <Spinner />;
  }

  return (
    <div className={styles.budgetSpentItemContainer}>
      <Card className={styles.card}>
        <div className={styles.cardInner}>
          <SubItemSmall
            name="Budget Account"
            value={(
              <div className={styles.accountName}>
                {((state.paymentCreationSource === PaymentCreationSource.REASSIGN || state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING)
                  && state.assignPaymentTo === AssignPaymentTo.Project
                  && isOverflowPayment)
                || state.assignPaymentTo === AssignPaymentTo.Other ? (
                  <Select
                    size="small"
                    value={paymentItem.budgetId}
                    onChange={(e) => onChangePaymentsItem(paymentItem.id, 'budgetId', e)}
                    style={{ width: 140 }}
                  >
                    {budgetAccounts?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    ))}
                  </Select>
                ) : (
                  <Select size="small" disabled style={{ width: 140 }} value={1}>
                    <Option value={1}>Project’s Total Budget</Option>
                  </Select>
                )}
                <Button
                  type="text"
                  icon={<ChartSimpleIcon style={{ color: '#167CF4', fontSize: 12 }} />}
                  style={{ padding: 0, width: '20px', height: '20px' }}
                />
              </div>
            )}
          />
          <SubItemSmall
            name="Fiscal Year Period"
            value={(
              <Select
                size="small"
                value={paymentItem.fiscalKeySelected}
                onChange={(e) => onChangePaymentsItem(paymentItem.id, 'fiscalKeySelected', e)}
                style={{ width: 120 }}
              >
                {financialYear.map((option) => (
                  <Option key={option.key} value={option.key}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            )}
          />
          {![PaymentCreationSource.REASSIGN, PaymentCreationSource.SALES_TRACKING].includes(state.paymentCreationSource) && (
            <SubItemSmall
              name="Total Amount"
              value={(
                <Input
                  type="number"
                  min={0}
                  prefix="$"
                  size="small"
                  style={{ width: '120px' }}
                  value={paymentItem.amount}
                  onChange={(e) =>
                    onChangePaymentsItem(
                      paymentItem.id,
                      'amount',
                      e.target?.value ? parseInt(e.target.value, 10) : null,
                    )}
                />
              )}
            />
          )}

          {state.assignPaymentTo === AssignPaymentTo.Other
            && (![PaymentCreationSource.REASSIGN, PaymentCreationSource.SALES_TRACKING].includes(state.paymentCreationSource)) && (
              <Button
                type="link"
                size="middle"
                danger
                style={{ width: '50%', textAlign: 'left', padding: '4px' }}
                onClick={() => removeSplitPayment(paymentItem.id)}
              >
                <TrashIcon />
                Remove
              </Button>
            )}
        </div>
      </Card>
    </div>
  );
};
