import * as React from 'react';

import {
  Card,
  Col,
  Divider,
  Row,
  Skeleton,
} from '@revfluence/fresh';

import { formatISO } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { useHomePageClientStatusQuery, useProjectOverviewQuery } from '@frontend/app/hooks';
import { backendServerWebEndpoint } from '@frontend/applications/Shared/serviceHosts';
import { useEventContext } from '@frontend/app/context/EventContext';
import { ILink } from '@frontend/app/components/StatisticsCard';

import { EventName } from '@common';

import { ConversionsCard } from '../../../HomePageMetrics/components/ConversionsCard';
import { SocialCard } from '../../../HomePageMetrics/components/SocialCard';
import { IHomePageMetricsSettings } from '../../../HomePageMetrics/components/HomePageMetrics';
import { useProjectOverviewStat } from '../../../HomePageMetrics/useProjectOverviewStat';
import { DateRangesLabel } from '../../../HomePageMetrics/components/DateSelect';
import { ISimpleMetric, StatsAPIState } from '../../../HomePageMetrics/types';
import { TProject } from '../../types';

const homePageInitialSettings: IHomePageMetricsSettings = {
  dateRanges: [undefined, undefined],
  projectIdsList: [],
  dateRangeLabel: DateRangesLabel.ALL_TIME,
};

const formatDateToQuery = (date: Date | undefined, startOfDay: boolean) => {
  const time = startOfDay ? '00:00:00' : '23:59:59';
  return date
  // Format date to UTC, ignores user timezone
  ? `${formatISO(date, { representation: 'date' })}T${time}Z`
  : undefined;
};

// used to populate stats even when sales tracking isn't connected
const zeroStat: StatsAPIState<ISimpleMetric> = {
  data: { diff: 0 },
  state: 'ready',
};

const {
  useCallback,
  useState,
  useEffect,
  useMemo,
} = React;

interface IProps {
  affiliatesAppId: string;
  clientId: string;
  contentAppId: string;
  socialAppId: string;
  project: TProject;
}

const WidgetPerformance: React.FC<IProps> = React.memo((props) => {
  const {
    affiliatesAppId,
    clientId,
    contentAppId,
    socialAppId,
    project,
  } = props;

  const settings = homePageInitialSettings;

  // determine if instagram is connected and if sales tracking is configured
  const statusQuery = useHomePageClientStatusQuery(false);
  const isInstagramConnected = !!statusQuery.data?.clientHomePageStatsStatus?.isInstagramConnected;
  const isSalesTrackingSetup = !!statusQuery.data?.clientHomePageStatsStatus?.isSalesTrackingSetup;
  const [startDate, endDate] = settings.dateRanges;
  const queryVariables = {
    startDate: formatDateToQuery(startDate, true),
    endDate: formatDateToQuery(endDate, false),
    projectIds: [project.id],
  };
  const queryResult = useProjectOverviewQuery({ variables: queryVariables });
  // fetch the list of stats
  let affiliates = useProjectOverviewStat('affiliates', queryResult);
  let conversions = useProjectOverviewStat('conversions', queryResult);
  const creators = useProjectOverviewStat('creatorCount', queryResult);
  const engagements = useProjectOverviewStat('engagements', queryResult);
  // @TODO P2 fetch this stat
  const impressions = useProjectOverviewStat('impressions', queryResult);
  const posts = useProjectOverviewStat('postCount', queryResult);
  const reach = useProjectOverviewStat('reach', queryResult);
  let sales = useProjectOverviewStat('sales', queryResult);
  const tmv = useProjectOverviewStat('tmv', queryResult);
  // @TODO P1 fetch this stat
  const videoViews = useProjectOverviewStat('views', queryResult);
  // configure the "Connect Instagram" onClick flow
  const addEvent = useEventContext();
  const oauthUrl = useMemo((): string => {
    const backend = backendServerWebEndpoint();
    const oauthEndpoint = `${backend}brands/${encodeURIComponent(clientId)}/facebook/aspirex_oauth`;
    const redirect = encodeURIComponent(window.location.href);
    const oauthScope = encodeURIComponent('aspirex_add_brand_account_mentions');
    return `${oauthEndpoint}?oauth_type=${oauthScope}&redirect=${redirect}`;
  }, [clientId]);
  const onClickConnectInstagram = useCallback((e) => {
    e.preventDefault();

    addEvent(EventName.OAuthGrantStart, { app: 'home_page' });
    window.localStorage.removeItem('ig_connect_success_track');
    window.location.href = oauthUrl;
  }, [addEvent, oauthUrl]);

  const [salesTrackingSetupDismissed, setSalesTrackingSetupDismissed] = useState(
    window.localStorage.getItem('HOME_PAGE_SALES_TRACKING_SETUP_DISMISSED') === 'true',
  );
  const onClickDismissSalesTrackingSetup = useCallback(() => {
    setSalesTrackingSetupDismissed(true);
  }, []);
  useEffect(() => {
    window.localStorage.setItem('HOME_PAGE_SALES_TRACKING_SETUP_DISMISSED', salesTrackingSetupDismissed.toString());
  }, [salesTrackingSetupDismissed]);

  // configure the client-side links
  const history = useHistory();
  const salesLinks = useMemo((): ILink[] => {
    const affiliatesPath = `app/${encodeURIComponent(affiliatesAppId)}/dashboard`;
    return [
      {
        href: `/client/${encodeURIComponent(clientId)}/${affiliatesPath}`,
        label: 'All Sales',
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliatesAppId, clientId, history]);

  const socialLinks = useMemo((): ILink[] => {
    const projectInfo = `?projectId=${project.id}&projectName=${project.title}`;
    const contentPath = `app/${encodeURIComponent(contentAppId)}/reports${projectInfo}`;
    const socialPath = `app/${encodeURIComponent(socialAppId)}/reports${projectInfo}`;
    return [
      {
        href: `/client/${encodeURIComponent(clientId)}/${socialPath}`,
        label: 'Social Data',
      },
      {
        href: `/client/${encodeURIComponent(clientId)}/${contentPath}`,
        label: 'Content Data',
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, contentAppId, history, socialAppId]);

  if (!isSalesTrackingSetup && salesTrackingSetupDismissed) {
    affiliates = zeroStat;
    conversions = zeroStat;
    sales = zeroStat;
  }

  const socialCard = (
    <SocialCard
      creators={creators}
      engagements={engagements}
      impressions={impressions}
      isInstagramConnected={isInstagramConnected}
      links={socialLinks}
      onClickConnectInstagram={onClickConnectInstagram}
      posts={posts}
      reach={reach}
      tmv={tmv}
      videoViews={videoViews}
      subtitle={settings.dateRangeLabel}
    />
  );

  const conversionsCard = (
    <ConversionsCard
      affiliates={affiliates}
      conversions={conversions}
      isSalesTrackingSetup={isSalesTrackingSetup}
      links={salesLinks}
      onClickDismissSetup={onClickDismissSalesTrackingSetup}
      sales={sales}
      subtitle={settings.dateRangeLabel}
    />
  );

  if (statusQuery.loading) {
    return (
      <Row gutter={[32, 32]}>
        <Col
          xs={24}
          lg={16}
        >
          <Card>
            <Skeleton
              active
              paragraph
            />
            <Divider />
            <Skeleton
              active
              paragraph
            />
          </Card>
        </Col>
        <Col
          xs={24}
          lg={8}
        >
          <Card>
            <Skeleton
              active
              paragraph
            />
            <Divider />
            <Skeleton
              active
              paragraph
            />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[32, 32]}>
      <Col
        xs={24}
        lg={16}
      >
        {socialCard}
      </Col>
      <Col
        xs={24}
        lg={8}
      >
        {conversionsCard}
      </Col>
    </Row>
  );
});

WidgetPerformance.displayName = 'WidgetPerformance';
export default WidgetPerformance;
