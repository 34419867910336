import { MemberFieldSchema } from '../models';

type ClientMemberFieldSchema = Pick<MemberFieldSchema, 'id' | 'name' | 'type' | 'applicationId' | 'isDefault' | 'choices' | 'metaData'>;

export interface IMemberFieldSchemaSections {
  social: ClientMemberFieldSchema[];
  permissions: ClientMemberFieldSchema[];
  socialPerformance: ClientMemberFieldSchema[];
  memberData: ClientMemberFieldSchema[];
  customData: ClientMemberFieldSchema[];
  salesTracking: ClientMemberFieldSchema[];
  payments: ClientMemberFieldSchema[];
  socialAnalytics: ClientMemberFieldSchema[];
  recommendedPayments: ClientMemberFieldSchema[];
  otherApps: {
    appId: string;
    appName: string;
    fields: ClientMemberFieldSchema[];
  }[];
}
