import * as React from 'react';
import { Alert, Typography } from '@revfluence/fresh';
import { HomeLayout } from '../../Home';
import Header from '../Header';
import Impact from '../Impact';
import MetricsPrimary from '../MetricsPrimary';
import MetricsSecondary from '../MetricsSecondary';

const emptyTrend = {
  percentDiff: null,
  value: null,
};

const emptyData = {
  historicalData: [],
  percentDiff: null,
  value: null,
};

const { Link } = Typography;

export const AlertError = () => (
  <Alert
    message="Data syncing issue"
    description={(
      <span>
        Sorry, there was an issue loading some or all of this data. Try refreshing page, come back in a bit or
        {' '}
        <Link href="mailto:help@aspireiq.com">contact support</Link>
        {' '}
        if this keeps happening.
      </span>
    )}
    type="error"
    showIcon
    style={{ width: '100%' }}
  />
);

interface IHomeErrorProps {
  startDate: Date;
  endDate: Date;
  Advice: React.ReactNode,
}

export const HomeError = ({
  startDate,
  endDate,
  Advice,
}: IHomeErrorProps) => {
  const impactData = {
    totalValue: null,
    investment: null,
    impact: null,
    percentDiff: null,
  };
  const trends = {
    reach: emptyTrend,
    views: emptyTrend,
    conversions: emptyTrend,
    creators: emptyTrend,
    cpm: emptyTrend,
    cpe: emptyTrend,
    tmv: emptyTrend,
  };
  return (
    <HomeLayout
      Header={<Header startDate={startDate} endDate={endDate} />}
      Advice={Advice}
      Alert={<AlertError />}
      ImpactComponent={<Impact impactData={impactData} />}
      MetricsPrimary={(
        <MetricsPrimary
          contentData={emptyData}
          impressionsData={emptyData}
          engagementsData={emptyData}
          salesData={emptyData}
        />
      )}
      MetricsSecondary={(
        <MetricsSecondary trends={trends} />
      )}
    />
  );
};
