import gql from 'graphql-tag';

export const GET_SHOPIFY_COLLECTIONS_QUERY = gql`
  query GetShopifyCollections($input: GetShopifyCollectionsInput!) {
    getShopifyCollections(input: $input) {
      collections {
        id
        title
        image
        description
        productsCount
        currentStatus
        handle
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GET_IMPORTED_COLLECTIONS_STATS_QUERY = gql`
  query GetImportedCollectionsStats {
    getImportedCollectionsStats {
      shopifyCollectionCount
    }
    getImportedProductsStats {
      shopifyProductsCount
      shopifyVariantsCount
    }
  }
`;
