import React, { useState, useMemo } from 'react';
import {
  Menu, Dropdown, Button, Typography,
 } from '@revfluence/fresh';
import { EllipsisVerticalIcon } from '@revfluence/fresh-icons/regular/esm';
import { map } from 'lodash';
import styles from '../FiscalYears.scss';

const { Text } = Typography;

const FiscalYearConfig = ({
 fiscalYear, fiscalYears, handleFiscalYearChange, handleFiscalYearsChange,
}) => {
  const [selectedKey, setSelectedKey] = useState(null);

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
    if (e.key === 'archive') {
        handleFiscalYearChange({ ...fiscalYear, isArchived: true });
    } else if (e.key === 'remove') {
      const updatedFiscalYears = fiscalYears.filter((fiscalYearValue) => fiscalYearValue.year !== fiscalYear.year);
      handleFiscalYearsChange(updatedFiscalYears);
    } else if (e.key === 'markAsCurrentYear') {
      const updatedFiscalYears = fiscalYears.map((fiscalYearValue) => {
        if (fiscalYearValue.year === fiscalYear.year) {
          return { ...fiscalYearValue, isCurrentYear: true };
        }
        return { ...fiscalYearValue, isCurrentYear: false };
      });
      handleFiscalYearsChange(updatedFiscalYears);
    }
  };

  const config = useMemo(() => [
    { key: 'markAsCurrentYear', label: 'Mark as Current Year', shouldDisplay: true },
    {
 key: 'archive', label: 'Archive Fiscal Year', className: styles.archiveFiscalYear, shouldDisplay: true,
},
    {
 key: 'remove', label: 'Remove Fiscal Year', className: styles.removeFiscalYear, shouldDisplay: fiscalYear?.isNew,
},
  ], [fiscalYear]);

  const menu = (
    <Menu onClick={handleMenuClick} selectedKeys={[selectedKey]}>
      {map(config, (item) => item?.shouldDisplay && (
        <Menu.Item key={item.key}>
          <Text className={item.className}>{item.label}</Text>
        </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button icon={(<EllipsisVerticalIcon />)} style={{ width: '40px' }} />
    </Dropdown>
  );
};

export default FiscalYearConfig;

FiscalYearConfig.displayName = 'FiscalYearConfig';
