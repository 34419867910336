import * as React from 'react';
import cx from 'classnames';
import { UserPlusIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  Button,
  Space,
  Tooltip,
  Typography,
} from '@revfluence/fresh';

import { OFFER_SOURCE, OFFER_STATUS } from '@affiliates/types/globalTypes';

import styles from './ShareLinkPane.scss';

const { Paragraph, Title } = Typography;

interface IProps {
  missingShopifyCredentials: boolean;
  offerSource: OFFER_SOURCE;
  offerStatus: string;
  onClick: (event: React.MouseEvent) => void;
  isReadOnly: boolean;
  disableAddMemberButton: boolean;
}

export const ShareLinkPane: React.FC<IProps> = (props) => {
  const {
    missingShopifyCredentials,
    offerSource,
    offerStatus,
    onClick,
    isReadOnly,
    disableAddMemberButton,
  } = props;
  const className = cx(styles.ShareLinkPane, {
    [styles.shopifyCodes]: offerSource === OFFER_SOURCE.SHOPIFY,
  });
  let title;
  let description;

  switch (offerSource && offerStatus) {
    case offerSource === OFFER_SOURCE.SHOPIFY && OFFER_STATUS.ACTIVE:
      title = <>To start generating promo codes, add members to this offer</>;
      description = (
        <>Generate unique promo codes to track conversions and sales for each member you add to this offer</>
      );
      break;
    case offerSource === OFFER_SOURCE.SHOPIFY && OFFER_STATUS.PAUSED:
      title = <>Let&rsquo;s give some members their promo codes</>;
      description = (
        <>Generate unique promo codes to track conversions and sales for each member you add to this offer</>
      );
      break;
    case offerSource === OFFER_SOURCE.SHOPIFY && OFFER_STATUS.DELETED:
      title = <>This offer has been deleted</>;
      description = (
        <>To add members to this offer, click on the pencil to edit the offer and click Un-delete offer</>
      );
      break;
    case offerSource === OFFER_SOURCE.TUNE && OFFER_STATUS.ACTIVE:
      title = <>To start generating sales links, add members to this offer</>;
      description = (
        <>Generate unique affiliate links to track clicks, conversions, and sales by adding members to this offer.</>
      );
      break;
    case offerSource === OFFER_SOURCE.TUNE && OFFER_STATUS.PAUSED:
      title = <>To start generating sales links, add members to this offer</>;
      description = (
        <>Generate unique affiliate links to track clicks, conversions, and sales by adding members to this offer.</>
      );
      break;
    case offerSource === OFFER_SOURCE.TUNE && OFFER_STATUS.DELETED:
      title = <>This offer has been deleted</>;
      description = (
        <>To add members to this offer, click on the pencil to edit the offer and click Un-delete offer</>
      );
      break;
  }

  const needsShopifySetup = missingShopifyCredentials && offerSource === OFFER_SOURCE.SHOPIFY;
  const tooltipText = needsShopifySetup ? 'In order to add members to this Offer, you must first reconnect your Shopify store' : '';
  const isDisabled = needsShopifySetup;
  const buttonType = isDisabled ? 'default' : 'primary';

  const getCorrectIcon = () => {
    if (offerSource === OFFER_SOURCE.TUNE) {
      return (
        <UserPlusIcon
          color="#f0f0f0"
          height="4rem"
          width="4rem"
        />
      );
    }
    return (
      <UserPlusIcon
        color="#f0f0f0"
        height="4rem"
        width="4rem"
      />
    );
  };

  return (
    <Space align="center" className={className} direction="vertical">
      {getCorrectIcon()}
      <Title level={4}>{title}</Title>
      <Paragraph>{description}</Paragraph>
      {offerStatus !== OFFER_STATUS.DELETED && (
        <Tooltip
          title={tooltipText}
        >
          <Button disabled={isDisabled || offerStatus === OFFER_STATUS.PAUSED || isReadOnly || disableAddMemberButton} size="large" type={buttonType} onClick={onClick}>
            Add Members
          </Button>
        </Tooltip>
      )}
    </Space>
  );
};
