import { ContentUsageRightsType } from '@frontend/app/types/globalTypes';

import {
  IState,
} from '@frontend/app/containers/Projects/TermsPage/components/types/state';
import {
  ALLOWEDCONTENTTYPES,
} from '@frontend/app/containers/Projects/TermsPage/components/ContentTypeList';

export const getInitialState = (): IState => ({
  introMessage: '',
  contentRequirements: {
    areDueDatesSelected: false,
    deliverablesDueDates: [],
    deliverablesWithNoDueDates: 0,
    contentTypes: ALLOWEDCONTENTTYPES,
  },
  contentGuideline: null,
  compensation: {
    payment: {
      toggleNewPrice: false,
      newPrice: null,
    },
    freeProduct: {
      toggleDescription: false,
      description: '',
    },
    commission: {
      toggleDescription: false,
      description: '',
    },
    noCompensation: {
      toggleNoCompensation: false,
    },
  },
  contentRight: {
    type: ContentUsageRightsType.LIMITED,
    text: '',
  },
  emailContent: null,
  emailSubject: 'Please Review Our Collaboration Brief',
  hasUnsavedChanges: false,
  defaultActiveKey: [],
  emailAttachments: [],
  resourceId: null,
  saving: false,
});
