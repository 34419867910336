import React, { useEffect } from 'react';
import {
 Button, Space, Tooltip, Typography, Modal,
} from '@revfluence/fresh';
import { PlusIcon, TriangleExclamationIcon } from '@revfluence/fresh-icons/regular/esm';
import { MemberFragment_talentAgents } from '@frontend/app/queries/fragments/types/MemberFragment';
import { UPSERT_MEMBER_AGENT } from '@frontend/app/queries/UpsertMemberAgent';
import { DELETE_MEMBER_AGENT } from '@frontend/app/queries/DeleteMemberAgent';
import { useMutation } from '@apollo/react-hooks';
import { logger } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { UpsertMemberAgent, UpsertMemberAgentVariables } from '@frontend/app/queries/types/UpsertMemberAgent';
import { DeleteMemberAgent, DeleteMemberAgentVariables } from '@frontend/app/queries/types/DeleteMemberAgent';
import { GetMemberQuery_member } from '@frontend/app/queries/types/GetMemberQuery';
import { MemberInput } from '@frontend/app/types/globalTypes';
import { TalentAgentsPanel, formValues } from './TalentAgentsPanel';
import styles from './TalentAgents.scss';

const { Text } = Typography;

const { useState } = React;

interface Props {
  member: GetMemberQuery_member | MemberInput;
  memberId: number;
  talentAgents: MemberFragment_talentAgents[];
  refetch: () => void;
  isAddingMember?: boolean; // for adding new member flow
  selectedAgents?: UpsertMemberAgentVariables[]; // for adding new member flow
  setSelectedAgents?: (agents: UpsertMemberAgentVariables[]) => void; // for adding new member flow
}

const TalentAgents: React.FunctionComponent<Props> = (props: Props) => {
  const {
    member, memberId = null, talentAgents = [], refetch, selectedAgents = [], setSelectedAgents, isAddingMember = false,
  } = props;
  const { showMessage, showErrorMessage } = useMessagingContext();
  const newAgentPanelArray = (agentsCount: number) => Array.from({ length: agentsCount }, (_, index) => index);
  const [newAgentsPanel, setNewAgentsPanel] = useState(isAddingMember && newAgentPanelArray(selectedAgents.length) || []);
  useEffect(() => {
    if (isAddingMember) {
      setNewAgentsPanel(newAgentPanelArray(selectedAgents.length));
    }
  }, [selectedAgents, isAddingMember]);
  const [upsertMemberAgent] = useMutation<UpsertMemberAgent, UpsertMemberAgentVariables>(UPSERT_MEMBER_AGENT, {
    onCompleted: async (data) => {
      if (data) {
        logger.debug('Upsert Member Agent successful', data);
        showMessage({
          type: 'success',
          content: 'Talent manager successfully updated',
        });
      }
    },
    onError: (error) => {
      showErrorMessage('Unable to update talent manager. Please try again or contact our support team.');
      logger.error('Error updating talent manager', error);
    },
  });

  const [deleteMemberAgent] = useMutation<DeleteMemberAgent, DeleteMemberAgentVariables>(DELETE_MEMBER_AGENT, {
    onCompleted: async (data) => {
      if (data) {
        logger.debug('Delete Member Agent successful', data);
        showMessage({
          type: 'success',
          content: 'Talent manager successfully deleted from member profile',
        });
        await refetch();
      }
    },
    onError: (error) => {
      showErrorMessage('Unable to delete talent manager. Please try again or contact our support team.');
      logger.error('Error updating talent manager', error);
    },
  });

  const renderManagerPanel = (talentAgents: MemberFragment_talentAgents[]) => {
    if (!talentAgents) {
      return null;
    }

    const agentsPanel = talentAgents.map((agent, index) => (
      <TalentAgentsPanel
        key={agent.agentId}
        agent={agent}
        agentIndex={index + 1}
        onFinish={onFinish}
        onDelete={onDelete}
        existingAgentEmails={talentAgents
          .map((otherAgent) => otherAgent.agentEmail.toLowerCase())
          .filter((email) => email !== agent.agentEmail.toLowerCase())}
      />
    ));

    return agentsPanel;
  };

  const renderNewManagerPanel = (newAgentsPanel: number[]) => newAgentsPanel.map((newAgentIndex) => (
    <TalentAgentsPanel
      key={newAgentIndex + talentAgents.length}
      agentIndex={newAgentIndex + talentAgents.length}
      openModal
      onFinish={onFinish}
      onDelete={onDelete}
      existingAgentEmails={talentAgents.map((otherAgent) => otherAgent.agentEmail.toLowerCase())}
    />
    ));

  const onFinish = async (values: formValues) => {
    const {
      agentEmail, agentName, alwaysCC, agentId, agentIndex,
    } = values;
    const params = {
      agentEmail,
      agentName,
      alwaysCC,
      agentId,
      memberId,
    };
    if (isAddingMember) {
      selectedAgents[agentIndex] = { params };
      setSelectedAgents(selectedAgents);
    } else {
      await upsertMemberAgent({
        variables: {
          params,
        },
      });
      if (!agentId) {
        setNewAgentsPanel([]);
      }
      await refetch();
    }
  };

  const onDelete = async (agentIndex: number) => {
    if (agentIndex <= talentAgents.length) {
      const agent = talentAgents[agentIndex - 1];
      const agentDisplay = agent?.agentName || agent?.agentEmail;
      const memberName = member?.name;
      Modal.confirm({
        title: 'Are you sure you want to delete this talent manager?',
        content: (
          <>
            <span className={styles.ConfirmationModal}>{agentDisplay}</span>
            {' '}
            will be removed from
            {' '}
            <span className={styles.ConfirmationModal}>{memberName}</span>
            ’s profile.
            {' '}
            You will lose the ability to
            automatically CC them on all messages.
          </>
        ),
        icon: <TriangleExclamationIcon style={{ color: 'red' }} />,
        okText: 'Delete',
        cancelText: 'Cancel',
        closable: true,
        okButtonProps: {
          danger: true,
          type: 'ghost',
        },
        onOk: async () => {
          await deleteMemberAgent({
            variables: {
              params: {
                memberId,
                agentId: agent.agentId,
              },
            },
          });
        },
      });
    } else {
      const newPanelNumber = agentIndex - talentAgents.length;
      setNewAgentsPanel(newAgentsPanel.filter((item) => item !== newPanelNumber));
    }
  };

  const handleAddNewAgentPanel = () => {
    // only allow 3 new agent panels for now
    if ((talentAgents?.length || 0) + newAgentsPanel.length < 3) {
      const newPanel = (newAgentsPanel[newAgentsPanel.length - 1] || newAgentsPanel.length) + 1;
      setNewAgentsPanel([...newAgentsPanel, newPanel]);
    }
  };

  const talentAgentCount = talentAgents?.length ?? 0;
  const newAgentCount = newAgentsPanel?.length ?? 0;
  const totalAgents = talentAgentCount + newAgentCount;

  return (
    <Space direction="vertical">
      {renderManagerPanel(talentAgents)}
      {renderNewManagerPanel(newAgentsPanel)}
      {totalAgents >= 3 ? (
        <Text type="secondary">Max 3 talent managers per member.</Text>
      ) : (
        <Tooltip title="Add Talent Manager">
          <Button icon={<PlusIcon />} size="small" onClick={handleAddNewAgentPanel} />
        </Tooltip>
      )}
    </Space>
  );
};

export default TalentAgents;
