/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1rz6d_157 {
  display: flex;
}

._justify-content-space-between_1rz6d_161 {
  justify-content: space-between;
}

._tabular-nums_1rz6d_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1rz6d_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferSummaryCard_1rz6d_178 {
  border: 0.0625rem solid #dadcde;
  border-radius: 0.5rem;
  color: #8f8d91;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  position: relative;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 {
  display: flex;
  flex: 1 1 auto;
  line-height: 1.125rem;
  min-height: 0;
  padding: 0.5rem;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._offerImageContainer_1rz6d_194 {
  flex: 0 0 6rem;
  margin-right: 0.5rem;
  height: 6rem;
  position: relative;
  border-radius: inherit;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._offerImageContainer_1rz6d_194 img {
  width: 6rem;
  height: 6rem;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  gap: 0.25rem;
  min-width: 0;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._titleWrapper_1rz6d_212 {
  align-items: center;
  display: flex;
  min-width: 0.0625rem;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._titleWrapper_1rz6d_212 ._title_1rz6d_212 {
  color: #1a1818;
  flex: 1 1 auto;
  font-size: 1rem;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._titleWrapper_1rz6d_212 ._editBtn_1rz6d_226 {
  flex: 0 0 auto;
  margin-left: 0.5rem;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._titleWrapper_1rz6d_212 ._editBtn_1rz6d_226 ._editIcon_1rz6d_230 {
  color: #3996e0;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._titleWrapper_1rz6d_212 ._editBtn_1rz6d_226 ._editIcon_1rz6d_230 .anticon {
  display: block;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._descriptionWrapper_1rz6d_236 {
  flex: 0 1 auto;
  min-width: 0.0625rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._additionalDetailsWrapper_1rz6d_243 {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._additionalDetailsWrapper_1rz6d_243 ._url_1rz6d_248 {
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  min-width: 0;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._additionalDetailsWrapper_1rz6d_243 ._url_1rz6d_248 .anticon {
  flex: 0 0 auto;
  margin-right: 0.5rem;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._additionalDetailsWrapper_1rz6d_243 ._url_1rz6d_248 span {
  flex: 1 1 auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._additionalDetailsWrapper_1rz6d_243 ._instructionsWrapper_1rz6d_265 {
  flex: 0 0 auto;
}
._OfferSummaryCard_1rz6d_178 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._additionalDetailsWrapper_1rz6d_243 ._instructionsWrapper_1rz6d_265 ._divider_1rz6d_268 {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
._OfferSummaryCard_1rz6d_178 ._footer_1rz6d_273 {
  align-items: center;
  border-top: 0.0625rem solid #dadcde;
  display: flex;
  height: 2.5rem;
  justify-content: space-between;
  overflow: hidden;
  padding: 0.75rem;
}
._OfferSummaryCard_1rz6d_178 ._footer_1rz6d_273 ._payout_1rz6d_282 {
  flex: 1 1 auto;
}
._OfferSummaryCard_1rz6d_178 ._footer_1rz6d_273 ._expirationDate_1rz6d_285 {
  flex: 1 1 auto;
}
._OfferSummaryCard_1rz6d_178 ._footer_1rz6d_273 ._tag_1rz6d_288 {
  flex: 0 0 auto;
  padding: 0.125rem 0.375rem;
  border-radius: 6.25rem;
  color: #fdfdfd;
  font-weight: 600;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: 0.078125rem;
  margin-left: auto;
  text-transform: uppercase;
}
._OfferSummaryCard_1rz6d_178 ._footer_1rz6d_273 ._tag_1rz6d_288._active_1rz6d_300 {
  background-color: #4eb468;
}
._OfferSummaryCard_1rz6d_178 ._footer_1rz6d_273 ._tag_1rz6d_288._expired_1rz6d_303 {
  background-color: #f1515f;
}
._OfferSummaryCard_1rz6d_178 ._footer_1rz6d_273 ._tag_1rz6d_288._draft_1rz6d_306 {
  background-color: #bdbdbd;
}
._OfferSummaryCard_1rz6d_178 ._footer_1rz6d_273 ._tag_1rz6d_288._paused_1rz6d_309 {
  background-color: #eacd60;
}
._OfferSummaryCard_1rz6d_178 ._footer_1rz6d_273 ._tag_1rz6d_288._archived_1rz6d_312 {
  background-color: #FFFBE6;
  color: #d48806;
}
._OfferSummaryCard_1rz6d_178._offerFormVariant_1rz6d_316 {
  position: fixed;
  top: 12.8125rem;
  width: 30.75rem;
}
._OfferSummaryCard_1rz6d_178._offerFormVariant_1rz6d_316 ._body_1rz6d_187 ._offerImageContainer_1rz6d_194 {
  flex: 0 0 6rem;
}
._OfferSummaryCard_1rz6d_178._offerFormVariant_1rz6d_316 ._body_1rz6d_187 ._bodyText_1rz6d_205 ._descriptionWrapper_1rz6d_236 {
  white-space: normal;
}
._OfferSummaryCard_1rz6d_178._offerFormVariant_1rz6d_316 ._bodyText_1rz6d_205 ._additionalDetailsWrapper_1rz6d_243 {
  position: absolute;
  top: 10.25rem;
  left: 0;
  right: 0;
  width: 100%;
}
._OfferSummaryCard_1rz6d_178._offerFormVariant_1rz6d_316 ._bodyText_1rz6d_205 ._additionalDetailsWrapper_1rz6d_243 ._instructionsWrapper_1rz6d_265 {
  display: none;
}
._OfferSummaryCard_1rz6d_178 ._linkIcon_1rz6d_337 {
  transform: rotate(-45deg);
}

._addndetails_1rz6d_341 {
  position: fixed;
  top: 23.125rem;
}

._tag_1rz6d_288 {
  flex: 0 0 auto;
  padding: 0.125rem 0.375rem;
  border-radius: 6.25rem;
  color: #fdfdfd;
  font-weight: 600;
  font-style: normal;
  line-height: 1rem;
  letter-spacing: 0.078125rem;
  margin-left: auto;
  text-transform: uppercase;
}
._tag_1rz6d_288._active_1rz6d_300 {
  background-color: #4eb468;
}
._tag_1rz6d_288._expired_1rz6d_303 {
  background-color: #f1515f;
}
._tag_1rz6d_288._draft_1rz6d_306 {
  background-color: #bdbdbd;
}
._tag_1rz6d_288._paused_1rz6d_309 {
  background-color: #eacd60;
}
._tag_1rz6d_288._archived_1rz6d_312 {
  background-color: #FFFBE6;
  color: #d48806;
}
._tag_1rz6d_288._mPayout_1rz6d_374 {
  float: right;
  text-transform: none;
  border-radius: 0.3125rem;
  padding: 0.25rem 0.625rem;
}

._multiplePayoutSummaryCard_1rz6d_381 {
  min-height: 210px;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #F0F0F0;
  padding: 16px;
  z-index: 100;
}
._multiplePayoutSummaryCard_1rz6d_381 ._imageContainer_1rz6d_389 {
  height: 80px;
  width: 80px;
  display: flex;
  position: relative;
  flex-direction: column;
}
._multiplePayoutSummaryCard_1rz6d_381 ._offerName_1rz6d_396 {
  font-size: 14px;
  display: block;
  font-weight: 600;
  color: #8C8C8C;
}
._multiplePayoutSummaryCard_1rz6d_381 ._offerExpirationContainer_1rz6d_402 {
  display: flex;
  align-items: center;
}
._multiplePayoutSummaryCard_1rz6d_381 ._offerExpirationContainer_1rz6d_402 ._offerLabel_1rz6d_406 {
  font-weight: 600;
}
._multiplePayoutSummaryCard_1rz6d_381 ._offerExpirationContainer_1rz6d_402 ._offerValue_1rz6d_409 {
  margin-left: 5px;
  font-size: 13px;
}
._multiplePayoutSummaryCard_1rz6d_381 ._offerDiscountConatiner_1rz6d_413 {
  margin: 5px 0 0;
  font-size: 20px;
  display: block;
  font-weight: 600;
}
._multiplePayoutSummaryCard_1rz6d_381 ._offerDiscount_1rz6d_413 {
  margin: 0 0 8px 0;
  font-size: 20px;
  display: block;
}
._multiplePayoutSummaryCard_1rz6d_381 ._offerDescription_1rz6d_424 {
  font-size: 14px;
  color: #8C8C8C;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 8px;
}
._multiplePayoutSummaryCard_1rz6d_381 ._payoutsContainer_1rz6d_431 {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
._multiplePayoutSummaryCard_1rz6d_381 ._payoutsContainer_1rz6d_431 ._payoutsLabel_1rz6d_436 {
  font-weight: 600;
  margin-bottom: 8px;
}
._multiplePayoutSummaryCard_1rz6d_381 ._payoutsContainer_1rz6d_431 ._payoutsValueContainer_1rz6d_440 {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
}
._multiplePayoutSummaryCard_1rz6d_381 ._payoutsContainer_1rz6d_431 ._payoutsValueContainer_1rz6d_440 ._payoutsValue_1rz6d_440 {
  padding: 4px 8px;
  background-color: #E6F7FF;
  border-radius: 4px;
  font-size: 12px;
}