import 'css-chunk:src/applications/AffiliatesApp/components/OfferSummaryCard/OfferSummaryCard.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1rz6d_157",
  "justify-content-space-between": "_justify-content-space-between_1rz6d_161",
  "tabular-nums": "_tabular-nums_1rz6d_165",
  "OfferSummaryCard": "_OfferSummaryCard_1rz6d_178",
  "body": "_body_1rz6d_187",
  "offerImageContainer": "_offerImageContainer_1rz6d_194",
  "bodyText": "_bodyText_1rz6d_205",
  "titleWrapper": "_titleWrapper_1rz6d_212",
  "title": "_title_1rz6d_212",
  "editBtn": "_editBtn_1rz6d_226",
  "editIcon": "_editIcon_1rz6d_230",
  "descriptionWrapper": "_descriptionWrapper_1rz6d_236",
  "additionalDetailsWrapper": "_additionalDetailsWrapper_1rz6d_243",
  "url": "_url_1rz6d_248",
  "instructionsWrapper": "_instructionsWrapper_1rz6d_265",
  "divider": "_divider_1rz6d_268",
  "footer": "_footer_1rz6d_273",
  "payout": "_payout_1rz6d_282",
  "expirationDate": "_expirationDate_1rz6d_285",
  "tag": "_tag_1rz6d_288",
  "active": "_active_1rz6d_300",
  "expired": "_expired_1rz6d_303",
  "draft": "_draft_1rz6d_306",
  "paused": "_paused_1rz6d_309",
  "archived": "_archived_1rz6d_312",
  "offerFormVariant": "_offerFormVariant_1rz6d_316",
  "linkIcon": "_linkIcon_1rz6d_337",
  "addndetails": "_addndetails_1rz6d_341",
  "mPayout": "_mPayout_1rz6d_374",
  "multiplePayoutSummaryCard": "_multiplePayoutSummaryCard_1rz6d_381",
  "imageContainer": "_imageContainer_1rz6d_389",
  "offerName": "_offerName_1rz6d_396",
  "offerExpirationContainer": "_offerExpirationContainer_1rz6d_402",
  "offerLabel": "_offerLabel_1rz6d_406",
  "offerValue": "_offerValue_1rz6d_409",
  "offerDiscountConatiner": "_offerDiscountConatiner_1rz6d_413",
  "offerDiscount": "_offerDiscount_1rz6d_413",
  "offerDescription": "_offerDescription_1rz6d_424",
  "payoutsContainer": "_payoutsContainer_1rz6d_431",
  "payoutsLabel": "_payoutsLabel_1rz6d_436",
  "payoutsValueContainer": "_payoutsValueContainer_1rz6d_440",
  "payoutsValue": "_payoutsValue_1rz6d_440",
  "show": "_show_1rz6d_1"
};