import * as React from 'react';
import {
  AutoComplete,
  Form, Input, Tooltip, Button,
} from '@revfluence/fresh';
import styles from '@affiliates/components/OfferForm/OfferForm.scss';
import { useState } from 'react';
import { TrashCanIcon } from '@revfluence/fresh-icons/regular/esm';
import { UTMCustomFieldsProps } from '../../../types';

interface IProps extends UTMCustomFieldsProps<'advanceUrlSettingType'> {
  events: {
    onBlur: () => void;
    onFocus: () => void;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
  };
}

// eslint-disable-next-line react/display-name
export const UtmCustomFields: React.FC<Readonly<IProps>> = React.memo(({
  key,
  customName,
  disabled,
  index,
  remove,
}) => {
  const utmcustomStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    columnGap: '8px',
  };
  const handleInputChange = (newValue) => {
    setValue(newValue);
    setCustomText(newValue);
  };
  const dynamicUtmOption = {
    creatorHandle: '{creator_name}',
    instaHandle: '{creator_ig_username}',
    projectName: '{project_name}',
    linkOfferName: '{link_offer_name}',
  };
  const [, setValue] = useState('');
  const [customText, setCustomText] = useState('');
  const dataSource = ['Dynamic Parameter', ...Object.values(dynamicUtmOption)];
  const disabledOptionStyles = {
    color: 'var(--neutral-color-palette-gray-9, #1F1F21)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
  };
  const getHashValidationRule = () => ({
    validator: (_, value) => {
      if (value && (value.includes('#') || value.includes(' ') || value.includes('='))) {
        return Promise.reject(new Error('Value cannot contain the \'#\', \'=\' or space character'));
      } else if (!value) {
        return Promise.reject(new Error('Cannot be empty'));
      }
      return Promise.resolve();
    },
  });
  return (
    <div key={key} style={utmcustomStyle}>
      <Tooltip title="Parameter Key">
        <Form.Item name={[customName, 'key']} rules={[getHashValidationRule()]}>
          <Input
            disabled={disabled}
            placeholder="Parameter Key"
            size="large"
          />
        </Form.Item>
      </Tooltip>
      <div className={styles.Closefield}>
        <Tooltip title="Parameter Value">
          <Form.Item name={[customName, 'value']} rules={[getHashValidationRule()]}>
            <AutoComplete
              value={customText}
              onChange={handleInputChange}
              disabled={disabled}
              placeholder="Parameter Value"
              size="large"
            >
              {dataSource.map((option, index) => (
                <AutoComplete.Option key={index} value={option} disabled={option == 'Dynamic Parameter'} style={option === 'Dynamic Parameter' ? disabledOptionStyles : null}>
                  {option}
                </AutoComplete.Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="Remove Parameter">
          {!disabled && (
            <Button className="custom-button" icon={<TrashCanIcon />} size="large" onClick={() => remove(index)} />
          )}
        </Tooltip>
      </div>
    </div>
  );
});
