export enum CustomFieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  ANNUAL = 'ANNUAL',
  IMAGES = 'IMAGES',
  ARRAY = 'ARRAY',
  EMAIL = 'EMAIL',
  LINK = 'LINK',
  TAG = 'TAG',
  ACTIVATION = 'ACTIVATION',
  COMMUNITY = 'COMMUNITY',
  HIGHLIGHT = 'HIGHLIGHT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  DYNAMIC_SELECT = 'DYNAMIC_SELECT',
  OWNERS = 'OWNERS',
  PROGRAM = 'PROGRAM',
  PROGRAM_MEMBERSHIP = 'PROGRAM_MEMBERSHIP',
}
