import * as React from 'react';
import {
  map,
  size,
  chain,
  upperFirst,
  filter,
  includes,
  forEach,
  isString,
  groupBy,
  isNumber,
  find,
  isFunction,
} from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import {
  Modal,
  Button,
  Select,
  List,
  Checkbox,
  Input,
  Tooltip,
} from '@revfluence/fresh';
import { getErrorMessageFromGraphQL } from '@frontend/utils';
import { SelectedColumnsInput } from '@frontend/app/types/globalTypes';
import {
  IApplication,
} from '@frontend/app/containers/Members/types/MemberFieldsWithSources';
import { IColumnVisibility } from '@frontend/app/types/Columns';
import { ColumnsIconV3 } from '@frontend/app/components';
import {
  useFuzzySearchByKeys,
  useSaveSegmentColumns,
} from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { MemberPageSegment as ISegment } from '@frontend/app/queries/fragments/types/MemberPageSegment';
import {
  IRenderParams,
  ISortableItem,
  SortableList,
} from '@frontend/app/components/SortableList/SortableList';
import {
  PredefinedSegmentsQuery_segments as IPredefinedSegment,
} from '@frontend/app/queries/types/PredefinedSegmentsQuery';
import { IColumn } from '@frontend/app/types/Columns';
import {
  IField,
  IFieldOption,
  FieldSource,
} from '@frontend/app/containers/Members/types/MemberFieldsWithSources';

import styles from './EditProjectColumns.scss';

const {
  useState, useCallback, useMemo, useEffect,
} = React;

interface IProps {
  communityId?: number;
  segment?: ISegment | IPredefinedSegment;
  fields: IField[];
  appsWithFields: IApplication[];
  initialSelectedColumns?: IColumn[];
  defaultColumns?: IColumn[];
  visibility?: IColumnVisibility;
  className?: string;
  predefinedSegmentId: string;
  isLoadingSegments?: boolean;
  fieldById: Map<string, IField>;
  onSelectedColumnsUpdated?: (selectedColumns: IColumn[]) => void;
  onChange?(columnVisibility: IColumnVisibility);
  onColumnsUpdated?: (reset: boolean) => void;
}

const MSG_DURATION = 3000;

type TSortableColumnItem = ISortableItem<IField>;

const SELECT_ALL_FIELD = 'select_all';
const FIXED_FIELDS = ['name'];

const getFieldSource = (field: IField): FieldSource => (
  (isString(field?.source)
      ? field?.source
      : (field?.source?.id || 'unknown')
    ) as FieldSource
);

export const EditProjectColumns: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    initialSelectedColumns,
    defaultColumns,
    fields,
    appsWithFields,
    predefinedSegmentId,
    isLoadingSegments,
    fieldById,
    onColumnsUpdated,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSource, setSelectedSource] = useState<FieldSource>(FieldSource.All);
  const [selectedColumns, setSelectedColumns] = useState<IField[]>([]);
  const [selectedFieldsBySource, setSelectedFieldsBySource] = useState<Map<FieldSource, Set<IField['field']>>>(new Map<FieldSource, Set<IField['field']>>());
  const [searchText, setSearchText] = useState<string>();
  const [filteredFields, setFilteredFields] = useState<IField[]>([]);
  const [searchResult, setSearchResult] = useState<IField[]>([]);
  const [fieldsBySource, setFieldsBySource] = useState<Map<FieldSource, IField[]>>(new Map<FieldSource, IField[]>());
  const searchDataSource = useFuzzySearchByKeys<IField>(fields, ['headerName']);
  const [saveSegmentColumns, { loading: isSavingColumns }] = useSaveSegmentColumns();
  const { showSuccessMessage, showErrorMessage } = useMessagingContext();

  // group fields by source
  useEffect(() => {
    const result = new Map<FieldSource, IField[]>();
    result.set(FieldSource.All, []);
    forEach(fields, (field) => {
      const source = getFieldSource(field);
      if (!result.has(source)) {
        result.set(source, []);
      }
      result.get(source).push(field);
      result.get(FieldSource.All).push(field);
    });
    setFieldsBySource(result);
  }, [fields]);

  const fixedColumns = useMemo(() => (
    filter(initialSelectedColumns, (f) => includes(FIXED_FIELDS, f?.field))
  ), [initialSelectedColumns]);

  const readonlyColumns = useMemo(() => (
    filter(initialSelectedColumns, (f) => f?.readonly)
  ), [initialSelectedColumns]);

  const readonlyColumnsBySource = useMemo(() => {
    const grouped = groupBy(readonlyColumns, (f) => getFieldSource(f));
    grouped[FieldSource.All] = readonlyColumns;
    return grouped;
  }, [readonlyColumns]);

  const initSelectedColumns = useCallback((cols: IField[]) => {
    const updatedSelection = new Map<FieldSource, Set<IField['field']>>();
    const nonFixedColumns = filter(
      cols,
      (f) => !includes(FIXED_FIELDS, f?.field),
    );
    const columns = [...fixedColumns, ...nonFixedColumns];
    forEach(columns, (column) => {
      if (!column) return;
      const source = getFieldSource(column);
      if (!updatedSelection.has(source)) {
        updatedSelection.set(source, new Set<IField['field']>());
      }
      if (!updatedSelection.has(FieldSource.All)) {
        updatedSelection.set(FieldSource.All, new Set<IField['field']>());
      }
      updatedSelection.get(source).add(column.field);
      updatedSelection.get(FieldSource.All).add(column.field);

      if (size(fieldsBySource.get(source)) === size(updatedSelection.get(source))) {
        updatedSelection.get(source).add(SELECT_ALL_FIELD);
      }
      if (size(fieldsBySource.get(FieldSource.All)) === size(updatedSelection.get(FieldSource.All))) {
        updatedSelection.get(FieldSource.All).add(SELECT_ALL_FIELD);
      }
    });
    setSelectedFieldsBySource(updatedSelection);
    setSelectedColumns(columns);
  }, [
    fieldsBySource,
    fixedColumns,
    setSelectedFieldsBySource,
    setSelectedColumns,
  ]);

  const selectedColumnsInput: SelectedColumnsInput = useMemo(() => {
    const projectColumns = filter(
      selectedColumns,
      (column: IField) => column?.source === FieldSource.Project,
    );
    const memberFieldSchemaColumns = filter(
      selectedColumns,
      (column: IField) => isNumber(column?.schemaId),
    );
    const dbColumns = filter(
      selectedColumns,
      (column: IField) => !column?.schemaId && column?.source !== FieldSource.Project,
    );
    const order = map(
      selectedColumns,
      (column: IField) => ({
        dbColumn: !column?.schemaId && column?.source !== FieldSource.Project ? column?.field : null,
        memberFieldSchemaId: isNumber(column?.schemaId) ? column?.schemaId : null,
        projectColumn: column?.source === FieldSource.Project ? column.field : null,
      }),
    );
    return {
      dbColumns: map(dbColumns, (f) => f?.field),
      memberFieldSchemaIds: map(memberFieldSchemaColumns, (f) => f?.schemaId),
      projectColumns: map(projectColumns, (f) => f?.field),
      order,
    };
  }, [selectedColumns]);

  // load initial selections of the columns
  useEffect(() => {
    initSelectedColumns(initialSelectedColumns);
  }, [initSelectedColumns, initialSelectedColumns]);

  // filter columns when typing in search box
  useEffect(() => {
    let filtered = fieldsBySource.get(selectedSource);
    if (searchText) {
      const search = new Set<IField['field']>(map(searchResult, (f) => f.field));
      filtered = filter(filtered, (f: IField) => search.has(f.field));
    }
    setFilteredFields(filtered);
  }, [
    searchText,
    selectedSource,
    fieldsBySource,
    searchResult,
  ]);

  const selectedCountBySource = useMemo(() => {
    let count = size(selectedFieldsBySource.get(selectedSource));
    if (selectedFieldsBySource.get(selectedSource)?.has(SELECT_ALL_FIELD)) {
      count -= 1;
    }
    return count;
  }, [selectedSource, selectedFieldsBySource]);

  const sources = useMemo(() => [
    ...map(FieldSource, (source) => ({
      label: source,
      value: source,
    })),
    ...map(appsWithFields, (app) => ({
      label: app.name,
      value: app.id,
    })),
  ], [appsWithFields]);

  const selectAllOption = useMemo(() => {
    const selectedCount = size(selectedFieldsBySource.get(selectedSource));
    const label = selectedCount > 0 ? 'Unselect All' : 'Select All';
    return {
      label,
      value: { headerName: label, field: SELECT_ALL_FIELD },
    };
  }, [selectedSource, selectedFieldsBySource]);

  const fieldOptions = useMemo(() => {
    const options = chain(filteredFields)
      .sortBy((field) => field.headerName.toLocaleLowerCase())
      .map((col) => ({
        label: upperFirst(col.headerName),
        value: col,
      }))
      .value();

    const fixedOptions = filter(
      options,
      (option) => includes(FIXED_FIELDS, option.value.field),
    );
    const nonFixedOptions = filter(
      options,
      (option) => !includes(FIXED_FIELDS, option.value.field),
    );

    return searchText
      ? [...fixedOptions, ...nonFixedOptions]
      : [selectAllOption, ...fixedOptions, ...nonFixedOptions];
  }, [searchText, selectAllOption, filteredFields]);

  const renderSelectedColumnItem = useCallback(
    (renderParam: IRenderParams<IField>) => <>{renderParam.item.data.headerName}</>,
    [],
  );

  const selectedColumnItems: TSortableColumnItem[] = useMemo(
    () => map(
      selectedColumns,
      (column: IField): TSortableColumnItem => ({
        id: column?.field,
        data: column,
        render: renderSelectedColumnItem,
        disableSort: false,
        removable: !column.readonly,
      }),
    ),
    [selectedColumns, renderSelectedColumnItem],
  );

  const isAllFieldIndeterminate = useMemo(() => {
    const selectedFieldsCount = size(selectedFieldsBySource.get(selectedSource));
    const fieldsCount = size(fieldsBySource.get(selectedSource));
    return selectedFieldsCount > 0 && selectedFieldsCount < fieldsCount;
  }, [selectedSource, selectedFieldsBySource, fieldsBySource]);

  const reset = useCallback(() => {
    setSelectedSource(FieldSource.All);
    setSearchText('');
    setFilteredFields(fields);
    document.getElementsByClassName(styles.columnsList)[0]?.scroll({ top: 0 });
  }, [
    fields,
    setSelectedSource,
    setSearchText,
    setFilteredFields,
  ]);

  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const handleResetToDefault = useCallback(() => {
    initSelectedColumns(defaultColumns);
    reset();
  }, [
    defaultColumns,
    initSelectedColumns,
    reset,
  ]);

  const handleSave = useCallback(async () => {
    try {
      await saveSegmentColumns({
        variables: {
          metadata: {
            predefinedSegmentId,
            columns: selectedColumnsInput,
          },
        },
      });
      showSuccessMessage('Columns successfully updated', MSG_DURATION);
      if (isFunction(onColumnsUpdated)) {
        onColumnsUpdated(true);
      }
      reset();
      setIsModalOpen(false);
    } catch (error) {
      showErrorMessage(getErrorMessageFromGraphQL(error), MSG_DURATION);
    }
  }, [
    predefinedSegmentId,
    selectedColumnsInput,
    saveSegmentColumns,
    setIsModalOpen,
    onColumnsUpdated,
    showSuccessMessage,
    showErrorMessage,
    reset,
  ]);

  const handleCancel = useCallback(() => {
    initSelectedColumns(initialSelectedColumns);
    reset();
    setIsModalOpen(false);
  }, [
    setIsModalOpen,
    initialSelectedColumns,
    initSelectedColumns,
    reset,
  ]);

  const handleColumnSearch = useCallback((e) => {
    const updatedText = e.target.value;
    setSearchText(updatedText);
    setSearchResult(searchDataSource(updatedText));
  }, [setSearchText, setSearchResult, searchDataSource]);

  const handleColumnSourceChange = useCallback((selected: string) => {
    setSelectedSource(selected as FieldSource);
  }, [setSelectedSource]);

  const handleItemRemoved = useCallback(
    (newItems: TSortableColumnItem[], removedItem: TSortableColumnItem) => {
      const updatedSelectedColumns = map(newItems, (item) => item.data);
      setSelectedColumns(updatedSelectedColumns);
      const updatedSelection = new Map<FieldSource, Set<IField['field']>>(selectedFieldsBySource);
      const field = fieldById.get(removedItem.id);
      updatedSelection.get(selectedSource).delete(field.field);
      updatedSelection.get(selectedSource).delete(SELECT_ALL_FIELD);
      if (selectedSource === FieldSource.All) {
        const source = getFieldSource(field);
        updatedSelection.get(source).delete(field.field);
        updatedSelection.get(source).delete(SELECT_ALL_FIELD);
      } else {
        updatedSelection.get(FieldSource.All).delete(field.field);
        updatedSelection.get(FieldSource.All).delete(SELECT_ALL_FIELD);
      }
      setSelectedFieldsBySource(updatedSelection);
    },
    [
      fieldById,
      selectedSource,
      selectedFieldsBySource,
      setSelectedFieldsBySource,
      setSelectedColumns,
    ],
  );

  const handleUpdateSort = useCallback(
    (newItems: TSortableColumnItem[]) => {
      const updatedSelectedColumns = map(newItems, (item) => item.data);
      setSelectedColumns(updatedSelectedColumns);
    },
    [setSelectedColumns],
  );

  const handleColumnSelection = useCallback((column) => {
    if (size(filteredFields) === 0) return;

    const updatedSelection = new Map<FieldSource, Set<IField['field']>>(selectedFieldsBySource);
    if (column.field === SELECT_ALL_FIELD) {
      // Unselect all clicked
      if (size(updatedSelection.get(selectedSource)) > size(readonlyColumnsBySource[selectedSource])) {
        if (selectedSource !== FieldSource.All) {
          updatedSelection.get(selectedSource).delete(SELECT_ALL_FIELD);
          updatedSelection.get(selectedSource).forEach((field) => {
            updatedSelection.get(FieldSource.All).delete(field);
          });
        } else {
          updatedSelection.get(selectedSource).forEach((field) => {
            selectedFieldsBySource.forEach((selection) => {
              selection.delete(field);
            });
          });
        }
        updatedSelection.set(selectedSource, new Set<IField['field']>());
        forEach(readonlyColumns, (column) => {
          const source = getFieldSource(column);
          if (source === selectedSource) {
            updatedSelection.get(selectedSource).add(column.field);
          }
          updatedSelection.get(FieldSource.All).add(column.field);
        });
      } else {
        // Select all clicked
        const selections = new Set<IField['field']>(
          [
            SELECT_ALL_FIELD,
            ...map(filteredFields, (f) => f.field),
          ],
        );
        if (selectedSource !== FieldSource.All) {
          const selections = new Set<IField['field']>(
            [
              ...Array.from(updatedSelection.get(FieldSource.All) || []),
              ...map(filteredFields, (f) => f.field),
            ],
          );
          updatedSelection.set(FieldSource.All, selections);
        } else {
          fieldsBySource.get(FieldSource.All).forEach((f) => {
            const source = getFieldSource(f);
            if (!updatedSelection.get(source)) {
              updatedSelection.set(source, new Set<IField['field']>());
            }
            updatedSelection.get(source).add(f.field);
            updatedSelection.get(source).add(SELECT_ALL_FIELD);
          });
        }
        updatedSelection.set(selectedSource, selections);
      }
    } else if (updatedSelection.get(selectedSource)?.has(column.field)) {
      // single column unchecked
      updatedSelection.get(selectedSource).delete(column.field);
      updatedSelection.get(selectedSource).delete(SELECT_ALL_FIELD);

      if (selectedSource === FieldSource.All) {
        const source = getFieldSource(column);
        updatedSelection.get(source).delete(column.field);
        updatedSelection.get(source).delete(SELECT_ALL_FIELD);
      } else {
        updatedSelection.get(FieldSource.All).delete(column.field);
        updatedSelection.get(FieldSource.All).delete(SELECT_ALL_FIELD);
      }
    } else {
      // single column checked
      if (!updatedSelection.get(selectedSource)) {
        updatedSelection.set(selectedSource, new Set<IField['field']>());
      }
      updatedSelection.get(selectedSource).add(column.field);

      if (selectedSource === FieldSource.All) {
        const source = getFieldSource(column);
        if (!updatedSelection.get(source)) {
          updatedSelection.set(source, new Set<IField['field']>());
        }
        updatedSelection.get(source).add(column.field);
      } else {
        updatedSelection.get(FieldSource.All).add(column.field);
      }

      if (size(fieldsBySource.get(selectedSource)) === size(updatedSelection.get(selectedSource))) {
        updatedSelection.get(selectedSource).add(SELECT_ALL_FIELD);
      }
      if (size(fieldsBySource.get(FieldSource.All)) === size(updatedSelection.get(FieldSource.All))) {
        updatedSelection.get(FieldSource.All).add(SELECT_ALL_FIELD);
      }
    }

    setSelectedColumns([
      ...filter(
        selectedColumns,
        (col) => updatedSelection.get(FieldSource.All).has(col.field),
      ),
      ...filter(
        fields,
        (f) => updatedSelection.get(FieldSource.All).has(f.field)
          && !find(selectedColumns, (col) => col.field === f.field),
      ),
    ]);
    setSelectedFieldsBySource(updatedSelection);
  }, [
    selectedSource,
    selectedFieldsBySource,
    filteredFields,
    fieldsBySource,
    selectedColumns,
    readonlyColumns,
    readonlyColumnsBySource,
    fields,
    setSelectedColumns,
    setSelectedFieldsBySource,
  ]);

  const renderItem = (item: IFieldOption, index: number) => {
    const column = item.value;
    const isIntederminate = column.field === SELECT_ALL_FIELD && isAllFieldIndeterminate;
    const isChecked = selectedFieldsBySource.get(selectedSource)?.has(column.field);
    return (
      <Checkbox
        key={`${column.field}-${index}`}
        checked={isChecked}
        indeterminate={isIntederminate}
        onChange={handleColumnSelection.bind(null, column)}
        disabled={includes(FIXED_FIELDS, column.field) || column.readonly}
      >
        <div className={styles.columnLabel}>
          {`${item.value.headerName}${column.field === SELECT_ALL_FIELD ? ` (${selectedCountBySource})` : ''}`}
        </div>
      </Checkbox>
    );
  };

  return (
    <div>
      <Modal
        width={700}
        title={(
          <p className={styles.modalHeader}>
            Edit Columns
          </p>
        )}
        open={isModalOpen}
        onOk={handleSave}
        onCancel={handleCancel}
        okText="Save"
        footer={[
          <div key="footerButtons" className={styles.modalFooter}>
            <div key="resetToDefault">
              <Button
                loading={isLoadingSegments}
                onClick={handleResetToDefault}
              >
                Reset to Default
              </Button>
            </div>
            <div key="cancelSave">
              <Button onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                loading={isSavingColumns}
                type="primary"
                onClick={handleSave}
              >
                Save
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className={styles.subTitle}>
          Your changes will apply to this stage in this project for all users.
        </div>
        <div className={styles.columns}>
          <div className={styles.column}>
            <div className={styles.columnTitle}>Add Columns</div>
            <Select
              className={styles.columnSourceSelect}
              value={selectedSource}
              onChange={handleColumnSourceChange}
            >
              {map(sources, (source) => (
                <Select.Option
                  key={source.label}
                  value={source.value}
                >
                  {source.label}
                </Select.Option>
              ))}
            </Select>
            <Input
              className={styles.columnSearch}
              value={searchText}
              allowClear
              prefix={<SearchOutlined />}
              onChange={handleColumnSearch}
              placeholder="Search"
              autoFocus
            />
            <List<IFieldOption>
              className={styles.columnsList}
              size="small"
              bordered={false}
              dataSource={fieldOptions}
              renderItem={(item, index) => (
                <List.Item>
                  {renderItem(item, index)}
                </List.Item>
              )}
            />
          </div>
          <div className={styles.column}>
            <div className={styles.columnTitle}>Selected Columns</div>
            <div className={styles.selectedColumns}>
              {/* TODO (rl) make sure that the styling is still correct here when feature flag enabled */}
              <SortableList<IField>
                fixedItemCount={size(FIXED_FIELDS)}
                itemClassName={styles.selectedColumnItem}
                onChange={handleUpdateSort}
                onItemRemoved={handleItemRemoved}
                options={selectedColumnItems}
                withHoverBackgroundColor
                flex
              />
            </div>
          </div>
        </div>
      </Modal>
      <Tooltip title="Edit Columns">
        <Button
          className={styles.openButton}
          icon={<ColumnsIconV3 viewBox="9 7 14 16" size={16} />}
          onClick={showModal}
        />
      </Tooltip>
    </div>
  );
});

EditProjectColumns.displayName = 'EditProjectColumns';
