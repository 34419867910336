import { useMutation, MutationHookOptions } from '@apollo/client';

import {
    CREATE_ORDER_REQUEST_CREATOR_INTERACTION,
} from '../queries';
import {
    CreateOrderRequestCreatorInteractionMutation,
    CreateOrderRequestCreatorInteractionMutationVariables,
} from '../queries/types/CreateOrderRequestCreatorInteractionMutation';

type IOptions = MutationHookOptions<CreateOrderRequestCreatorInteractionMutation, CreateOrderRequestCreatorInteractionMutationVariables>;

export function useCreateOrderRequestCreateInteraction(options: IOptions = {}) {
  const [createOrderRequestCreatorInteraction, { loading, error }] = useMutation<
  CreateOrderRequestCreatorInteractionMutation,
  CreateOrderRequestCreatorInteractionMutationVariables
  >(CREATE_ORDER_REQUEST_CREATOR_INTERACTION, options);

  return {
    createOrderRequestCreatorInteraction,
    loading,
    error,
  };
}
