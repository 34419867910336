import * as React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { logger } from '@common';

import { useAuth } from '@frontend/context/authContext';

const { useEffect, useMemo } = React;

export const useApolloClientForAspirex = (attachClient: boolean = false, authToken?: string) => {
  const { token: authTokenFromHook } = authToken ? { token: authToken } : useAuth();
  const token = authToken || authTokenFromHook;

  const apolloClient = useMemo(() => {
    const httpLink = new HttpLink({
      uri: '/api/graphql',
    });

    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((e) => {
          logger.error('Sales tracking: GraphQL error occurred.', e);
          console.error('Sales tracking: GraphQL error occurred.', e);
        });
      }
      if (networkError) {
        logger.error('Sales tracking: Unable to reach GraphQL server.', networkError);
        console.error('Sales tracking: Unable to reach GraphQL server.', networkError);
      }
    });

    const client = new ApolloClient({
      link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
      cache: new InMemoryCache(),
    });

    return client;
  }, [token]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (attachClient && (window as any)?.__APOLLO_CLIENT__) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__APOLLO_CLIENT__ = apolloClient;
    }
  }, [apolloClient, attachClient]);

  return apolloClient;
};
