import * as React from 'react';
import { Route, useLocation } from 'react-router-dom';

import {
  Row, Col, Space, useBreakpoint,
  Dropdown,
  Button,
  Menu,
} from '@revfluence/fresh';
import { STAPaymentApp } from '@affiliates/containers';
import { STAPaymentAppContextProvider } from '@affiliates/contexts';
import { DateFilter, IDateRangeSettings } from '@frontend/app/components';
import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import { EllipsisIcon } from '@revfluence/fresh-icons/solid';
import { BoxArchiveIcon } from '@revfluence/fresh-icons/regular';
import { BackLink } from './components/BackLink';
import { PageTitle } from './components/PageTitle';
import styles from './Affiliates.scss';
import { OfferDetailCard } from './components/OfferDetailCard';

const affiliateIcon = require('@frontend/app/assets/svgs/affiliates_app.svg');

const { useCallback, useMemo } = React;

interface IProps {
  baseUri: string;
  clientId: string;
  clientName: string;
  dateRangeSettings: IDateRangeSettings;
  onClickDashboardLink: () => void;
  migrateToGraphQL: boolean
  isEnabledMultipleShopify: boolean
  archiveOffer: boolean
  setShowArchiveOffer: (value: boolean) => void;
}

export const AffiliatesApp: React.FC<Readonly<IProps>> = (props) => {
  const screens = useBreakpoint();
  const {
    baseUri, clientId, clientName, children, dateRangeSettings, onClickDashboardLink, migrateToGraphQL, archiveOffer, setShowArchiveOffer,
  } = props;
  const { earliestDate } = dateRangeSettings;

  const location = useLocation();
  const isDashboardPage = useMemo(() => location.pathname.indexOf('/dashboard') !== -1, [location.pathname]);
  const isOfferDetailsPage = useMemo(() => location.pathname.indexOf('/details') !== -1, [location.pathname]);
  const { isOldOffer } = useOfferDetailsContext();
  const onClickLink = useCallback(
    (e) => {
      e.preventDefault();
      onClickDashboardLink();
    },
    [onClickDashboardLink],
  );
  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<BoxArchiveIcon />} onClick={() => setShowArchiveOffer(true)}>
        Archive Offer
      </Menu.Item>
    </Menu>
  );

  const ActionsButtons = () => {
    const buttons = [
      (isOfferDetailsPage && (!migrateToGraphQL || (migrateToGraphQL && isOldOffer))) && (
        <Col key="dateFilter">
          <DateFilter earliestDate={earliestDate} settings={dateRangeSettings} />
        </Col>
      ),
      (isDashboardPage || (isOfferDetailsPage && (!migrateToGraphQL || isOldOffer))) && (
        <>
          <Col key="paymentApp">
            <STAPaymentApp
              clientId={clientId}
              clientName={clientName}
              initialDateFilterSettings={dateRangeSettings}
              migrateToGraphQL={migrateToGraphQL}
            />
          </Col>
        </>
      ),
      ((isDashboardPage && archiveOffer) && (
        <Col>
          <Dropdown overlay={menu}>
            <Button>
              <EllipsisIcon />
            </Button>
          </Dropdown>
        </Col>
      )),
    ];
    return <Row gutter={8}>{buttons}</Row>;
  };
  return (
    <Space direction="vertical" size={0} style={{ width: '100%', overflowX: 'hidden' }}>
      <STAPaymentAppContextProvider>
        <Row gutter={0}>
          <Col xs={24}>
            <Row className={styles.appDashboardHeader} gutter={0}>
              <Col>
                <BackLink baseUri={baseUri} />
              </Col>
              <Col flex={1}>
                <div className={styles.logoWrap}>
                  {(isOfferDetailsPage && migrateToGraphQL && !isOldOffer) ? <OfferDetailCard migrateToGraphQL={migrateToGraphQL} clientId={clientId} clientName={clientName} dateRangeSettings={dateRangeSettings} archiveOffer={archiveOffer} showCta /> : (
                    <a className={styles.appLogoLink} href={baseUri} onClick={onClickLink}>
                      {isDashboardPage && <img className={styles.appIcon} src={affiliateIcon} />}
                      <PageTitle />
                    </a>
                  )}
                </div>
              </Col>
              <Col style={{ marginTop: screens.xs && 8 }}>
                <Space size={16}>
                  <Route
                    render={({ location: { pathname } }) => {
                      const showActions = pathname.includes('/details') || pathname.includes('/dashboard');
                      if (showActions) {
                        return <ActionsButtons />;
                      }
                      return null;
                    }}
                  />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={0}>
          <Col xs={24}>
            {children}
          </Col>
        </Row>
      </STAPaymentAppContextProvider>

    </Space>
  );
};
