import * as React from 'react';
import cx from 'classnames';
import { pick, first, isEmpty } from 'lodash';
import * as qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { Input, Button } from '@components';

import { useParsedRouterSearch, useFeatureFlagVerbiage } from '@frontend/app/hooks';
import { SimpleField, SimpleForm, OwnerSelect } from '@frontend/app/components';
import {
  ProgramInput,
} from '@frontend/app/types/globalTypes';

import styles from './ProgramBasics.scss';

const { useMemo } = React;

interface IProps {
  communityId: number;
  programInput: ProgramInput;
  onChange(programInput: ProgramInput);
  onSubmit();
  className?: string;
}

export const ProgramBasics = React.forwardRef<SimpleForm, IProps>((props, ref) => {
  const history = useHistory();
  const location = useLocation();
  const searchQuery = useParsedRouterSearch();

  const verbiage = useFeatureFlagVerbiage();

  const handleChange = (fieldName: string, value: string) => {
    let customUrl = props.programInput.customLandingPagePath;

    // Default the custom landing page to program name.
    if (!props.programInput.id && fieldName === 'title') {
      customUrl = value;
    }

    props.onChange({
      ...props.programInput,
      [fieldName]: value,
      customLandingPagePath: customUrl,
    });
  };

  const handleChangeOwner = (ownerIds: string[]) => {
    props.onChange({
      ...props.programInput,
      owner: first(ownerIds),
    });
  };

  if (!props.programInput) {
    return null;
  }

  const programId = props.programInput.id;

  const handleViewMembers = () => {
    const newSearch = {
      ...searchQuery,
      segmentId: `programs_${programId}`,
      communityId: props.communityId,
    };

    history.push({
      ...location,
      pathname: '/member_table',
      search: qs.stringify(newSearch),
    });
  };

  const formValues = pick(props.programInput, 'title');

  const ownerIds = useMemo(
    () => (isEmpty(props.programInput.owner) ? undefined : [props.programInput.owner]),
    [props.programInput.owner],
  );

  return (
    <div className={cx(styles.ProgramBasics, props.className)}>
      <SimpleForm
        ref={ref}
        onSubmit={props.onSubmit}
        onChange={handleChange}
        values={formValues}
      >
        <div className={styles.label}>
          Name of
          {verbiage.program}
          :
        </div>
        <SimpleField name="title" type="string" required label={`${verbiage.Program} name`}>
          <Input className={styles.input} theme="info" />
        </SimpleField>
      </SimpleForm>

      <div className={styles.label} />
      <OwnerSelect ownerIds={ownerIds} onChangeOwners={handleChangeOwner} />
      <div>
        This owner will automatically be assigned as the owner for any member added to this
        {verbiage.program}
      </div>

      {programId && (
        <>
          <div className={styles.label}>Members</div>
          <Button
            label="View Members"
            theme="info"
            onClick={handleViewMembers}
          />
        </>
      )}
    </div>
  );
});

ProgramBasics.displayName = 'ProgramBasics';
