import * as React from 'react';
import { some } from 'lodash';
import { useHistory } from 'react-router-dom';

import { LoadSpinner } from '@components';

import {
  Notice,
  PageSection,
} from '@affiliates/AspireUI';
import {
  Dashboard,
  IDashboardOffer,
  OfferTable,
  ShopifyScopesMissing,
} from '@affiliates/components';
import {
  DashboardQueriesStatus,
  useDashboardQueries,
} from '@affiliates/hooks';
import { IEnabledOfferSources } from '@affiliates/types';
import { DateFilter, IDateRangeSettings } from '@frontend/app/components';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { GET_ALL_PROGRAMS } from '@frontend/app/queries';
import { useState } from 'react';
import { GetAllPrograms_programs } from '@frontend/app/queries/types/GetAllPrograms';
import {
  Alert, Button, Col, Row, Typography,
} from '@revfluence/fresh';
import { ChartSimpleIcon } from '@revfluence/fresh-icons/solid/esm';
import { BoxArchiveIcon } from '@revfluence/fresh-icons/regular';
import { useApolloClientForAspirex } from '../../hooks/useApolloClientForAspirex';
import styles from '../OfferFormPage.scss';
import { logger } from '../../../../../../common/Logger';
import { StatisticsBlock } from '../../components/StatisticsBlock';
import { DataFormat } from '../../utils';
import { IStatisticsItem } from '../../components/StatisticsBlock/types';

const { useEffect } = React;
const { Title } = Typography;
interface IProps {
  baseUri: string;
  clientName: string;
  dateRangeSettings: IDateRangeSettings;
  shopifyAppId: string;
  sources: IEnabledOfferSources;
  showArchiveOffer: boolean;
  setShowArchiveOffer: (value: boolean) => void;
}

export const DashboardPage: React.FC<Readonly<IProps>> = (props) => {
  const {
    baseUri,
    clientName,
    dateRangeSettings,
    shopifyAppId,
    sources,
    showArchiveOffer,
    setShowArchiveOffer,
  } = props;

  const queries = useDashboardQueries(
    baseUri,
    clientName,
    dateRangeSettings,
    sources,
  );

  const history = useHistory();
  const aspirexApolloClient = useApolloClientForAspirex();
  const [programs, setPrograms] = useState<GetAllPrograms_programs[]>();
  const { earliestDate } = dateRangeSettings;
  useEffect(() => {
    if (queries.status === DashboardQueriesStatus.Ready) {
      dateRangeSettings.setEarliestDate(queries.advertiser.createdDate);
    }
    aspirexApolloClient.query({
      query: GET_ALL_PROGRAMS,
    }).then((data) => {
      setPrograms(data.data.programs);
    }).catch((error) => {
      logger.error(error);
    });
  }, [queries, dateRangeSettings, aspirexApolloClient]);
  const getDashboardPageContents = () => {
    switch (queries.status) {
      case DashboardQueriesStatus.Loading:
        return <LoadSpinner />;
      case DashboardQueriesStatus.Failed:
        return <Notice type="error" message={queries.error.message} />;
      default:
        const hasShopifyOffer = some(
          queries.offers,
          (offer: IDashboardOffer) => offer.source === OFFER_SOURCE.SHOPIFY,
        );
        const data = queries.stats;
        const statisticsItem: IStatisticsItem[] = [
          {
            title: 'Offers',
            value: data.offers,
            format: DataFormat.Integer,
          },
          {
            title: 'Members',
            value: data.members,
            format: DataFormat.Integer,
          },
          {
            title: 'Clicks',
            value: data.clicks,
            format: DataFormat.Integer,
          },
          {
            title: 'Conversions',
            value: data.conversions,
            format: DataFormat.Integer,
          },
          {
            title: 'Sales',
            value: data.sales,
            prefix: '$',
            format: DataFormat.Money,
          },
          {
            title: 'Avg. Sale',
            value: data.avgSale,
            prefix: '$',
            format: DataFormat.Money,
          },
          {
            title: 'Commissions Earned ',
            value: data.payoutEarned,
            prefix: '$',
            format: DataFormat.Money,
          },
          {
            title: 'Payout',
            value: data.payoutMade,
            prefix: '$',
            format: DataFormat.Money,
          },
        ];

        return (
          <>
            {showArchiveOffer && (
              <PageSection className={styles.offerDashboardPage}>
                <Alert
                  message="You are viewing Archive offers."
                  type="warning"
                  icon={<BoxArchiveIcon />}
                  showIcon
                  action={(
                    <Button size="small" type="ghost" onClick={() => setShowArchiveOffer(false)}>
                      Close Archive View
                    </Button>
                  )}
                />
              </PageSection>
            )}
            <PageSection id="dashboard-statistics" className={styles.offerDashboardPage}>
              <Row justify="space-between" align="middle">
                <Row gutter={8}>
                  <Col>
                    {' '}
                    <ChartSimpleIcon fontSize={18} />
                  </Col>
                  <Col>
                    {' '}
                    <Title level={5}>Sales Stats</Title>
                  </Col>
                </Row>
                <Col>
                  <DateFilter earliestDate={earliestDate} settings={dateRangeSettings} />
                </Col>
              </Row>
              <div className={styles.offerDashboardHeader}>
                <StatisticsBlock statisticsItem={statisticsItem} />
              </div>
            </PageSection>
            {hasShopifyOffer && queries.missingShopifyCredentials && (
              <PageSection id="shopify-scope-check">
                <ShopifyScopesMissing onClick={() => {
                  history.push({
                    ...location,
                    pathname: `/settings/${encodeURIComponent(shopifyAppId)}`,
                  });
                }}
                />
              </PageSection>
            )}
            <PageSection id="offer-table">
              <OfferTable
                data={queries.offers}
                missingShopifyCredentials={queries.missingShopifyCredentials}
                onClickAddOffer={queries.offerActions.onClickAddOffer}
                onClickExport={queries.offerActions.onClickExport}
                onOfferClicked={queries.offerActions.onOfferClicked}
                sources={sources}
                programs={programs}
                showArchiveOffer={showArchiveOffer}
              />
            </PageSection>
          </>
        );
    }
  };
  return (
    <Dashboard>
      {getDashboardPageContents()}
    </Dashboard>
  );
};
