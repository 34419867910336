import * as React from 'react';
import * as qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';

import {
  useParsedRouterSearch,
} from '@frontend/app/hooks';
import { useInitPendo, useInitPlanhat } from './utils';

type TSelectedSetting = 'account' | 'notification' | 'integration' | 'conversation_privacy';
const ALL_SETTINGS: TSelectedSetting[] = [
  'account', 'notification', 'integration', 'conversation_privacy',
];
interface ISearchPageContext {
  selectedSetting: TSelectedSetting;
  setSelectedSetting(setting: TSelectedSetting): void;
}

const { useContext, useState, useEffect } = React;

const SettingsContext = React.createContext<ISearchPageContext>(null);
export const useSettingsContext = () => useContext(SettingsContext);
export const SettingsContextProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const urlSearch = useParsedRouterSearch();
  const [selectedSetting, setSelectedSetting] = useState(
    ALL_SETTINGS.includes(urlSearch.settings) ? urlSearch.settings : undefined,
  );

  useInitPendo();
  useInitPlanhat();

  useEffect(() => {
    if (selectedSetting !== undefined) {
      history.replace({
        ...location,
        search: qs.stringify({
          settings: selectedSetting,
        }, { skipNulls: true }),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSetting]);

  return (
    <SettingsContext.Provider
      value={{
        selectedSetting,
        setSelectedSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
