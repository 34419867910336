import * as React from 'react';
import cx from 'classnames';
import {
  useHistory,
} from 'react-router-dom';
import {
  get,
  isEmpty,
  isFunction,
  map,
  reduce,
  pick,
  size,
  first,
  sumBy,
  filter,
  isUndefined,
  keys,
  values,
  isNull,
  trim,
  isEqual,
} from 'lodash';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import {
  Tooltip,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  Checkbox,
  Typography,
  message,
  Space,
} from '@revfluence/fresh';
import {
  CircleExclamationIcon,
  CircleCheckIcon,
  TriangleExclamationIcon,
} from '@revfluence/fresh-icons/regular/esm';

import { ProgramInput as ProjectInput } from '@api/src/graphql/inputs';

import {
  EventName,
  logger,
} from '@common';

import {
  LoadSpinner,
  ManagerSelect,
  OwnerSelect,
  ThumbnailUpload,
} from '@frontend/app/components';
import {
  useSaveProgramMutation,
} from '@frontend/app/containers/Communities/AddOrEditCommunity/hooks/useSaveProgramMutation';
import {
  SortableWorklet,
  TWorklet,
  TProject,
} from '@frontend/app/containers/Projects/types';
import { useEventContext } from '@frontend/app/context';
import {
  useAddSpecificationMutation,
  useGetInstalledApplicationIds,
  useUpdateSpecificationMutation,
  useDeleteSpecificationMutation,
  useDeleteWorkletMutation,
  useUploadContent,
  useConfirmOnExit,
  useGetAllTasksQuery,
  useGetCountsForProjectQuery,
  useClientFeatureEnabled,
  useDeleteProgramById,
  useArchiveProgramById,
  useGetCampaignByProjectId,
  IContent,
} from '@frontend/app/hooks';
import { SaveProgramMutation_program } from '@frontend/app/queries/types/SaveProgramMutation';
import { useMessagingContext } from '@frontend/hooks';
import { useOverviewPage } from '@frontend/app/containers/Projects/hooks';
import { useAuth } from '@frontend/context/authContext';

import { ClientFeature } from '@frontend/app/constants';
import { WorkletsList } from './WorkletsList';
import { useAddWorklets } from '../hooks';
import { useDeleteStage } from '../hooks/useDeleteStage';
import { useValidateProjectName } from '../hooks/useValidateProjectName';
import {
  CustomProjectTemplateName,
  ProjectsRouteRoot,
} from '../constants';

import {
  PROJECT_NAME_ERRORS,
  PROJECT_NAME_MAX_LENGTH,
  validateProjectSpec,
  sleep,
} from '../utils';
import { DeleteStageModal } from './DeleteStageModal';

import styles from './ProjectDetailsForm.scss';

const {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} = React;

const { confirm } = Modal;
const { Text, Paragraph, Link } = Typography;

interface IProps {
  /**
   * Class name
   */
  className?: string;
  /**
   * Default worklets to display (usually custom worklets)
   */
  defaultWorklets?: TWorklet[];
  /**
   * Disable the form
   */
  disabled?: boolean;
  /**
   * Loading
   */
  isLoading?: boolean;
  /**
   * Form mode
   */
  mode: 'add' | 'edit';
  /**
   * Callback when the form is submitted
   */
  onSubmit?: (project: ProjectInput) => void;
  /**
   * Callback when the form is submitting
   */
  onSubmitting?: (isSubmitting: boolean) => void;
  /**
   * Parent spec key
   */
  parentSpecKey?: string;
  /**
   * Project ID for edit mode
   */
  projectId?: number;
  /**
   * Project input
   */
  projectInput?: ProjectInput;
  /**
   * Template name -- only used for event logging
   */
  templateName?: string;
  /**
   * Hide confirmation modal
   */
  hideConfirmation?: boolean;
  refetchProjects?: () => void;
}

export const ProjectDetailsForm: React.FC<IProps> = (
  {
    defaultWorklets,
    disabled,
    isLoading: isLoadingProp,
    mode,
    onSubmit,
    onSubmitting,
    parentSpecKey,
    projectId,
    projectInput: projectInputProp,
    templateName,
    hideConfirmation,
    refetchProjects,
  },
) => {
  const addEvent = useEventContext();
  const {
    isPaymentRequiredError,
    showError,
    showErrorMessage,
    showSuccessMessage,
  } = useMessagingContext();

  const installedApps = useGetInstalledApplicationIds();

  const [form] = Form.useForm();

  const { user } = useAuth();

  /**
   * Input state
   */
  const [projectInput, setProjectInput] = useState<ProjectInput>(projectInputProp);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [forceHideConfirmation, setForceHideConfirmation] = useState(false);
  const [duplicateProjectName, setDuplicateProjectName] = useState(false);

  const history = useHistory();

  const isGCREnabled = useClientFeatureEnabled(ClientFeature.GROUP_CONTENT_REVIEW);
  const isArchiveProjectEnabled = useClientFeatureEnabled(ClientFeature.ARCHIVE_PROJECT);

  const { renderArchivedNotice } = useOverviewPage(projectInput as TProject);

  const isArchivedProject = useMemo(
    () => !isNull(projectInputProp?.archivedDate) && !isNull(projectInputProp?.archivedBy),
    [projectInputProp],
  );

  const {
    refetch: refetchProjectCampaign,
  } = useGetCampaignByProjectId(projectInputProp?.id, {
    onError: (error) => {
      showError(error);
    },
    skip: !projectInputProp?.id,
  });

  const [archiveProgramById, {
    loading: isArchivingProject,
  }] = useArchiveProgramById({
    async onCompleted() {
      await refetchProjectCampaign();
      message.success({
        icon: <CircleCheckIcon />,
        content: 'Project Successfully Archived',
        duration: 2,
      });
      history.replace({
        pathname: `${ProjectsRouteRoot}/${projectInput.id}/overview`,
      });
    },
    onError(error) {
      message.error({
        content: error.message,
        duration: 2,
      });
    },
  });

  const { deleteProgramById, loading: isDeletingProject } = useDeleteProgramById({
    onCompleted() {
      message.success({
        icon: <CircleCheckIcon />,
        content: `${projectInput?.title} has been deleted.`,
        duration: 2,
      });

      if (isFunction(refetchProjects)) {
        refetchProjects();
      }

      history.push('/home');
    },
    onError(error) {
      message.error({
        content: error.message,
        duration: 2,
      });
    },
  });

  const handleFormChange = useCallback(
    (changedValues: { [key: string] : unknown }) => {
      const fieldName = keys(changedValues)[0];
      const fieldValue = values(changedValues)[0];
      setProjectInput((details) => ({
        ...details,
        [fieldName]: fieldValue,
      }));
      setHasUnsavedChanges(true);
      if (fieldName === 'title') {
        setDuplicateProjectName(false);
      }
    },
    [setProjectInput],
  );

  /**
   * Edit worklets
   */
  const [worklets, setWorklets] = useState<SortableWorklet[]>([]);
  const [isEditingWorklets, setEditingWorklets] = useState(false);
  const hasNewWorklets = worklets.length > (defaultWorklets?.length || 0);

  const handleChangeWorklets = useCallback(
    (newWorklets: Partial<SortableWorklet>[], shouldSubmit = false) => {
      setWorklets(map(
        newWorklets,
        (worklet) => ({
          __typename: 'Worklet',
          specKey: worklet.specKey,
          specLogoURL: worklet.specLogoURL,
          specTitle: worklet.specTitle,
          specURI: worklet.specURI,
          canEdit: worklet.canEdit,
        }),
      ));

      if (shouldSubmit) {
        form.submit();
      } else {
        setHasUnsavedChanges(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setWorklets],
  );

  useEffect(() => {
    setWorklets(defaultWorklets?.map((worklet) => ({ ...worklet, canEdit: false })) || []);
  }, [defaultWorklets]);

  /**
   * Delete stages
   */
  const {
    modalVisible: deleteStageModalVisible,
    status: deletingStatus,
    completedPercent: deletingPercentage,
    totalCount: currentStageMemberCount,
    handleRemoveExistingWorklet,
    hideDeleteStageModal,
    skipWorklet,
  } = useDeleteStage(parentSpecKey, projectId, showSuccessMessage, showErrorMessage);

  /**
   * Tasks
   */
  const {
    data: { tasks } = {},
    loading: isTasksLoading,
  } = useGetAllTasksQuery({
    variables: {
      specKey: projectInput?.specKey,
    },
    skip: !projectInput?.specKey,
  });

  /**
   * Counts
   */
  const {
    data: {
      counts = undefined,
    } = {},
    loading: isCountsLoading,
    refetch: refetchCounts,
  } = useGetCountsForProjectQuery({
    variables: { projectId },
    skip: !projectId,
  });

  useEffect(() => {
    if (counts) {
      refetchCounts({ projectId });
    }
    // eslint-disable-next-line
  }, []);

  const totalCount = useMemo(() => {
    if (isCountsLoading || !counts) {
      return 0;
    }
    return counts.all_in_progress + counts.new + counts.invited;
  }, [isCountsLoading, counts]);

  /**
   * Counts by worklet
   */
  const countsByWorklet = useMemo(() => reduce(worklets, (prev, worklet) => ({
      ...prev,
      [worklet.specKey]: sumBy(
        filter(tasks, { workletSpecKey: worklet.specKey }),
        (task) => (counts?.[task.workletSpecUri]?.[task.taskId]?.total || 0),
      ) || 0,
    }), {}), [worklets, counts, tasks]);

  /**
   * Input owner
   */
  const [ownerId, setOwnerId] = useState((mode === 'add') ? user.sub : projectInput?.owner || null);
  const handleChangeOwner = async (ownerIds: string[]) => {
    setOwnerId(first(ownerIds));
    setHasUnsavedChanges(true);
  };

  /**
   * Thumbnail upload
   */
  const [tempImageSrc, setTempImageSrc] = useState<string>();
  const [tempImageFile, setTempImageFile] = useState<File>();
  const {
    content: uploadContent,
    error: uploadError,
    upload: uploadImage,
  } = useUploadContent({
    serviceName: 'community', // TODO: COL-2072 Fix program bucket
    parentFolder: 'temp',
  });

  /**
   * Unpaid offer
   */
  const [hasUnpaidOffer, setHasUnpaidOffer] = useState(projectInputProp?.hasUnpaidOffer || false);
  const [gcrEnabled, setGcrEnabled] = useState(projectInputProp?.gcrEnabled || false);
  const [gcrApproverIds, setGcrApproverIds] = useState(projectInputProp?.gcrApproverIds || []);
  const handleChangeHasUnpaidOffer = (event: CheckboxChangeEvent) => {
    setHasUnpaidOffer(event.target.checked);
    setHasUnsavedChanges(true);
  };
  const handleChangeGcrEnabled = (event: CheckboxChangeEvent) => {
    setGcrEnabled(event.target.checked);
    setHasUnsavedChanges(true);
  };
  const handleChangeGcrApproverIds = (ids: string[]) => {
    setGcrApproverIds(ids);
    setHasUnsavedChanges(true);
  };

  const setTempImage = useCallback((src: string) => {
    setTempImageSrc(src);
    setHasUnsavedChanges(true);
  }, []);

  useEffect(
    () => {
      if (projectInputProp?.splashImageUrl) {
        setTempImageSrc(projectInputProp.splashImageUrl);
      }
    },
    [projectInputProp?.splashImageUrl],
  );
  useEffect(
    () => {
      if (uploadError && !uploadError.isUploadAborted) {
        logger.error(uploadError);
        showErrorMessage(uploadError.message);
      }
    },
    [uploadError, showErrorMessage],
  );

  const handleImageUpload = useCallback(
    async (imageFile: File) => {
      if (imageFile) {
        logger.debug('Uploading project thumbnail');
        return uploadImage(imageFile, 'image', { imageCompressionEnabled: true });
      }
      logger.debug('Unable to upload an empty image file');
      return null;
    },
    [uploadImage],
  );

  /**
   * Worklets
   */
  const { addWorklets } = useAddWorklets();
  const [
    deleteWorklet,
    { loading: isDeletingWorklet },
  ] = useDeleteWorkletMutation();

  /**
   * Specification
   */
  const [addSpecification] = useAddSpecificationMutation();
  const [updateSpecification] = useUpdateSpecificationMutation();
  const [
    deleteSpecification,
    { loading: isDeletingSpecification },
  ] = useDeleteSpecificationMutation();

  /**
   * Save project/program
   */
  const [saveProgram] = useSaveProgramMutation();

  const projectName = useMemo(() => projectInput?.title, [projectInput]);

  const {
    validate: validateProjectName,
  } = useValidateProjectName({
    projectName,
    emptyMessage: PROJECT_NAME_ERRORS.EMPTY_INPUT_PROJECT,
  });

  const {
    isValid: isValidTitle,
    error: errorMessageTitle,
  } = useMemo(
    () => validateProjectName(duplicateProjectName),
    [validateProjectName, duplicateProjectName],
  );

  /**
   * Rollback
   */
  const recentlyAddedWorkletSpecKeysRef = useRef<string[]>();
  const recentlyAddedSpecificationSpecKeyRef = useRef<string>();

  const errorTooltip = useMemo(() => {
    if (!projectInput?.title) {
      return 'Please enter a name for your project';
    } if (!projectInput?.description) {
      return 'Please enter a purpose for your project';
    } if (!projectInput?.splashImageUrl && !tempImageSrc) {
      return 'Please upload an image for your project';
    } if (!worklets || worklets.length === 0) {
      return 'Please add at least one workflow stage to save your project';
    } if (!ownerId) {
      return 'Please assign an owner for your project';
    } if (!isValidTitle) {
      return `Project Name: ${errorMessageTitle}`;
    }

    return '';
  }, [
    projectInput,
    worklets,
    tempImageSrc,
    ownerId,
    isValidTitle,
    errorMessageTitle,
  ]);

  const clearRollbackSpecKeys = () => {
    recentlyAddedWorkletSpecKeysRef.current = undefined;
    recentlyAddedSpecificationSpecKeyRef.current = undefined;
  };

  const rollbackSpec = async (): Promise<void> => {
    logger.debug('Rolling back created worklets and spec');
    await Promise.limitedAll(
      recentlyAddedWorkletSpecKeysRef.current,
      async (specKey) => {
        if (!specKey) {
          return;
        }
        try {
          logger.debug(`Deleting worklet: ${specKey}`);
          await deleteWorklet({ variables: { specKey } });
          logger.debug(`Deleting specification: ${specKey}`);
          await deleteSpecification({ variables: { specKey } });
        } catch (error) {
          logger.error(`Unable to delete worklet specification for spec: ${specKey}`, error);
        }
      },
    );
    if (!parentSpecKey) {
      logger.debug(`Deleting specification: ${recentlyAddedSpecificationSpecKeyRef.current}`);
      try {
        await deleteSpecification({
          variables: {
            specKey: recentlyAddedSpecificationSpecKeyRef.current,
          },
        });
      } catch (error) {
        logger.error(`Unable to delete parent specification for spec: ${parentSpecKey}`, error);
      }
    }
    clearRollbackSpecKeys();
  };

  /**
   * Create new custom worklets
   */
  const createCustomWorklets = async (
    customWorklets: TWorklet[],
  ): Promise<string[]> => {
    logger.debug('Adding worklets...', { customWorklets });
    const workletSpecKeys = !isEmpty(customWorklets)
      ? await addWorklets(customWorklets)
      : [];
    // Only keep the new worklets (to delete later if other operation/s fail)
    recentlyAddedWorkletSpecKeysRef.current = reduce(
      customWorklets,
      (result, worklet, index) => {
        if (!worklet.specKey && workletSpecKeys[index]) {
          result.push(workletSpecKeys[index]);
        }
        return result;
      },
      [],
    );
    logger.debug('Added worklets', { workletSpecKeys });
    return workletSpecKeys;
  };

  /**
   * Create new specification
   */
  const createSpecification = async (workletSpecKeys: string[]): Promise<string> => {
    logger.debug('Adding specification...', { workletSpecKeys });
    const {
      data: {
        specKey = null,
      } = {},
    } = !isEmpty(worklets)
      ? await addSpecification({
        variables: {
          workletSpecKeys,
        },
      })
      : {};
    recentlyAddedSpecificationSpecKeyRef.current = specKey;
    logger.debug('Added specification', { specKey });
    return specKey;
  };

  /**
   * Update specification
   */
  const updateExistingSpecification = async (workletSpecKeys: string[]): Promise<string> => {
    if (isEmpty(worklets)) {
      return null;
    }

    const { data: { specKey = null } = {} } = await updateSpecification({
      variables: {
        specKey: parentSpecKey,
        workletSpecKeys,
      },
    });
    logger.debug('Updated specification', { specKey });
    return specKey;
  };

  /**
   * Save project
   */
  const saveProject = async (
    formData: ProjectInput,
    specKey: string,
    splashImageUrl: string,
    ownerIdArg: string,
    hasUnpaidOffer: boolean,
    gcrEnabled: boolean,
    gcrApproverIds?: string[],
  ): Promise<SaveProgramMutation_program> => {
    // Assign program input
    const input: ProjectInput = {
      ...formData,
      description: trim(projectInput.description),
      id: projectInputProp?.id || null,
      specKey: specKey || null,
      splashImageUrl: splashImageUrl || null,
      title: trim(projectInput.title),
      owner: ownerIdArg || null,
      hasUnpaidOffer,
      gcrEnabled,
      gcrApproverIds,
    };
    if (!input.applicationFormFields) {
      input.applicationFormFields = {
        memberFieldSchemas: map(
          projectInput.applicationFormFields?.memberFieldSchemas || [],
          (schema) => pick(schema, 'schemaId', 'label', 'required', 'order'),
        ),
        dbColumns: map(
          projectInput.applicationFormFields?.dbColumns || [{ name: 'email', label: 'Email', required: true }],
          (col) => pick(col, 'name', 'label', 'required', 'order'),
        ),
      };
    }
    if (!input.columns) {
      input.columns = {
        memberFieldSchemaIds: map(
          input.applicationFormFields.memberFieldSchemas,
          (field) => field.schemaId,
        ), // TODO: first and last name
        dbColumns: map(input.applicationFormFields.dbColumns, (col) => col.name),
      };
    }
    logger.debug('Saving project...', { input });

    // Save!
    const {
      data: {
        program: project = undefined,
      } = {},
    } = await saveProgram({
      variables: {
        program: {
          ...input,
          templateName,
        },
      },
    });
    logger.debug('Project saved', { project });
    return project;
  };

  /**
   * Upload image to bucket
   */
  const uploadImageToBucket = async (): Promise<string> => {
    if (projectInput?.splashImageUrl === tempImageSrc) {
      logger.debug('No need to upload a new image');
      return projectInput?.splashImageUrl;
    }
    logger.debug('Uploading new project image...');
    const splashImageUrl = await handleImageUpload(tempImageFile);
    logger.debug('New project image:', { splashImageUrl });
    return splashImageUrl;
  };

  /**
   * Form submit
   */
  const [isSubmittingForm, setSubmittingForm] = useState(false);

  const handleSubmitForm = async (formData: ProjectInput) => {
    setSubmittingForm(true);
    setForceHideConfirmation(false);

    try {
      const workletSpecKeys = (!parentSpecKey || hasNewWorklets) ? await createCustomWorklets(worklets) : undefined;
      if (workletSpecKeys) {
        // Temporary validation until we can have a more extensible solution
        const [error, errorMessage] = validateProjectSpec(workletSpecKeys, installedApps, !parentSpecKey);
        if (error) {
          setSubmittingForm(false);
          showErrorMessage(errorMessage || 'There was an unexpected error.', 5000);
          if (mode === 'edit') {
            // If there is an error in validation when editing project, reset to the existing worklets
            setWorklets(defaultWorklets?.map((worklet) => ({ ...worklet, canEdit: false })) || []);
          }
          return;
        }
      }

      let specKey = parentSpecKey;
      if (!parentSpecKey) {
        specKey = await createSpecification(workletSpecKeys);
      } else if (hasNewWorklets) {
        specKey = await updateExistingSpecification(workletSpecKeys);
      }

      const splashImageUrl = await uploadImageToBucket();
      // Persist splashImageUrl to projectInput to prevent duplicate uploads
      // TODO(jb) This is a band-aid solution and this form in general needs to be refactored
      setProjectInput({ ...projectInput, splashImageUrl });

      const newProject = await saveProject(
        formData, specKey, splashImageUrl,
        ownerId, hasUnpaidOffer, gcrEnabled, gcrApproverIds,
      );

      if (newProject?.id) {
        if (isFunction(onSubmit)) {
          onSubmit(newProject);
        }
        if (mode === 'add') {
          addEvent(
            EventName.CreatedProject,
            {
              spec_size: size(workletSpecKeys),
              spec: workletSpecKeys,
              projectId: newProject.id,
              template: templateName || CustomProjectTemplateName,
            },
          );
        }
        setHasUnsavedChanges(false);
      } else {
        logger.warn('Project is empty', { newProject });
        rollbackSpec();
      }

      setWorklets(worklets.map((worklet) => ({
        ...worklet,
        canEdit: false,
      })));

      clearRollbackSpecKeys();
      setSubmittingForm(false);
    } catch (error) {
      logger.error(error);
      await rollbackSpec(); // Rollback created specs
      setSubmittingForm(false);

      // Catch known error when project title is a duplicate
      const errorMessage: string = get(error, 'graphQLErrors[0].message');
      if (errorMessage?.startsWith('Program with this title already exists')) {
        showErrorMessage('Looks like a project was created with the same name in the past. Please pick a different name instead');
        setDuplicateProjectName(true);
      } else {
        showError(error);

        if (isPaymentRequiredError(error)) {
           setForceHideConfirmation(true);
        }
      }
    }
  };

  const handleSubmitButtonClick = async () => {
    // Check before submitting form
    const hasNoWorkletsOnNewProject = (!projectId || !parentSpecKey) && isEmpty(worklets);
    if (!hasNoWorkletsOnNewProject && !isEditingWorklets) {
      form.submit();
    }
  };

  /**
   * UI State
   */
  const formValues = {
    ...projectInput,
    splashImageUrl: tempImageSrc || projectInput?.splashImageUrl,
    owner: ownerId || projectInput?.owner,
    hasUnpaidOffer: hasUnpaidOffer || projectInput?.hasUnpaidOffer,
    gcrEnabled: gcrEnabled || projectInput?.gcrEnabled,
    gcrApproverIds: gcrApproverIds || projectInput?.gcrApproverIds,
  };

  useEffect(
    () => {
      if (isFunction(onSubmitting)) {
        onSubmitting(isSubmittingForm);
      }
    },
    [isSubmittingForm, onSubmitting],
  );

  const didFormChange = (
    mode === 'edit' && !isUndefined(projectInputProp)
      ? (
        formValues.title !== projectInputProp.title
        || formValues.description !== projectInputProp.description
        || formValues.splashImageUrl !== projectInputProp.splashImageUrl
        || formValues.owner !== projectInputProp.owner
        || hasUnpaidOffer !== projectInputProp.hasUnpaidOffer
        || gcrEnabled !== projectInputProp.gcrEnabled
        || !isEqual(gcrApproverIds, projectInputProp.gcrApproverIds)
      )
      : true
  );

  useConfirmOnExit({
    show: didFormChange && hasUnsavedChanges && !hideConfirmation && !forceHideConfirmation,
  });

  const isDeleting = (
    isDeletingWorklet
    || isDeletingSpecification
  );
  const isAllowedToEditWorklets = !parentSpecKey;
  const isAllowedToSubmit = (
    // Has parentSpecKey (edit mode) or valid worklets (add/edit mode)
    (
      parentSpecKey
      || (
        isAllowedToEditWorklets
        && !isEditingWorklets
        && !isEmpty(worklets)
      )
    )
    // Form completeness
    && projectInput?.title
    && projectInput?.description
    && tempImageSrc
    && ownerId
  );
  const isLoading = (
    isLoadingProp
    || isTasksLoading
    || isCountsLoading
    || isSubmittingForm
    || isDeleting
    || isArchivingProject
    || isDeletingProject
  );
  const isDisabled = (
    disabled
    || isLoading
  );
  const isWorkletsDisabled = (
    isDisabled
    || !isAllowedToEditWorklets
  );
  const isSubmitButtonDisabled = (
    isDisabled
    || !isAllowedToSubmit
    || isSubmittingForm
    || !didFormChange
    || !isValidTitle
  );

  const renderSubmitButton = (renderMode: 'add' | 'edit') => {
    if (mode !== renderMode) {
      return;
    }

    return (
      <Tooltip
        title={errorTooltip}
        placement="top"
      >
        <Button
          block
          disabled={isSubmitButtonDisabled}
          onClick={handleSubmitButtonClick}
          size="large"
          type="primary"
        >
          {mode === 'edit' ? 'Save' : 'Create'}
        </Button>
      </Tooltip>
    );
  };

  const archiveModalText = useMemo(
    () => (
      <>
        <p>
          Are you sure you want to archive this project? All project analytics will still be available but you will not be able to add or accept new members.
        </p>
        <p>
          You will not be able to unarchive at this time.
        </p>
      </>
    ),
    [],
  );

  const showArchiveModal = useCallback(
    () => {
    confirm({
      title: 'Archive Project',
      icon: <CircleExclamationIcon />,
      content: archiveModalText,
      onOk() {
        archiveProgramById({
          variables: {
            id: projectInput.id,
          },
        });
      },
      autoFocusButton: null,
      okText: 'Archive',
    });
  },
  [
    projectInput?.id,
    archiveModalText,
    archiveProgramById,
  ],
);

const ownerIds = useMemo(
  () => (isEmpty(ownerId) ? undefined : [ownerId]),
  [ownerId],
);

const showDeleteProjectModal = useCallback(
  () => {
    confirm({
      title: `Permanently Delete ${projectInput?.title}?`,
      content: (
        <>
          <p>Are you sure you want to permanently delete this project? This will have a number of consequences across Aspire:</p>
          <ul>
            <li>Your landing page for this project will be disabled. Any invites you have sent will no longer work.</li>
            <li>
              You will no longer be able to filter reports on content, posts, payments, or others by this project.
            </li>
            {totalCount > 0 && (
              <li>
                {totalCount}
                {' '}
                member
                {totalCount > 1 ? 's' : ''}
                {' '}
                in the workflow will have their workflows cancelled.
              </li>
            )}
          </ul>
        </>
      ),
      async onOk() {
        // adding a fake 5s delay
        // so that it feels real
        await sleep(5000);
        deleteProgramById({
          variables: {
            id: projectInput.id,
          },
        });
      },
      okType: 'danger',
      icon: <TriangleExclamationIcon />,
      cancelText: 'Cancel',
      okText: 'Yes, Delete',
    });
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    projectInput?.title,
    projectInput?.id,
    totalCount,
  ],
);

  const formItemLayout = {
    labelCol: {
      xs: { span: 20, offset: 2 },
      sm: { span: 20, offset: 2 },
      md: { span: 20, offset: 2 },
      lg: { span: 20, offset: 2 },
    },
    wrapperCol: {
      xs: { span: 20, offset: 2 },
      sm: { span: 20, offset: 2 },
      md: { span: 20, offset: 2 },
      lg: { span: 20, offset: 2 },
    },
  };

  return (
    <Space
      direction="vertical"
      size={[32, 32]}
      className={cx(
        styles.ProjectDetailsForm,
        { [styles.loading]: isLoading },
        )}
    >
      { mode === 'edit'
        && isArchivedProject
        && isArchiveProjectEnabled
        && renderArchivedNotice }
      <Form
        {...formItemLayout}
        layout="vertical"
        form={form}
        initialValues={formValues}
        onValuesChange={handleFormChange}
        onFinish={handleSubmitForm}
        className={styles.form}
      >
        <Row className={styles.row}>
          <Col xs={24} lg={12}>
            <Form.Item
              name="title"
              label="Project Name"
              validateStatus={!isValidTitle ? 'error' : 'success'}
              help={!isValidTitle ? errorMessageTitle : null}
              hasFeedback={!isValidTitle}
              required={false}
              rules={[
                {
                  required: true,
                  message: 'Project Name is required.',
                },
               ]}
            >
              <Input
                placeholder="Name"
                disabled={isDisabled}
                showCount
                maxLength={PROJECT_NAME_MAX_LENGTH}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label="Purpose"
              required={false}
              rules={[
                {
                  message: 'Enter a purpose',
                  required: true,
                },
              ]}
            >
              <Input.TextArea
                placeholder={mode === 'add' ? 'Describe the collab and goals (internal)' : ''}
                disabled={isDisabled}
                rows={3}
              />
            </Form.Item>
            <Form.Item
              label="Image"
              name="splashImageUrl"
            >
              <ThumbnailUpload
                disabled={isDisabled}
                onFileSelected={setTempImageFile}
                onThumbnailSelected={setTempImage}
                progressPercentage={(uploadContent as IContent)?.progress?.percentage}
                thumbnail={tempImageSrc}
              />
            </Form.Item>
            <Form.Item
              label="Project Owner"
              name="owner"
            >
              <Paragraph className={styles.text}>
                This owner will automatically be assigned as the owner for any member added to this program
              </Paragraph>
              <OwnerSelect
                ownerIds={ownerIds}
                multi={false}
                onChangeOwners={handleChangeOwner}
                label="Assigned to:"
              />
            </Form.Item>

            <Form.Item
              htmlFor="hasUnpaidOffer"
            >
              <Checkbox onChange={handleChangeHasUnpaidOffer} checked={hasUnpaidOffer}>
                <Typography.Text>Mark as unpaid offer</Typography.Text>
              </Checkbox>
              <Paragraph className={styles.text}>
                Checking this box will reflect on the the application form of your landing page that there is no possibility of a paid collaboration.
              </Paragraph>
            </Form.Item>

            {isGCREnabled && (
              <>
                <Form.Item
                  htmlFor="gcrEnabled"
                >
                  <Checkbox onChange={handleChangeGcrEnabled} checked={gcrEnabled}>
                    <Typography.Text>Enable Group Content Review</Typography.Text>
                  </Checkbox>
                  <Paragraph className={styles.text}>
                    Checking this box will enable creator-submitted content to be reviewed by users before approval.
                  </Paragraph>
                </Form.Item>
                {gcrEnabled && (
                  <Form.Item
                    label={(
                      <div>
                        Additional Content Approvers
                        {' '}
                        <Text type="secondary">(optional)</Text>
                      </div>
                    )}
                    name="gcrApproverIds"
                    extra="Users selected as Content Reviewers will be able to approve or reject content directly"
                  >
                    <ManagerSelect onChange={handleChangeGcrApproverIds} value={gcrApproverIds} />
                  </Form.Item>
                )}
              </>
            )}
            {mode === 'edit' && (
              <Form.Item>
                {renderSubmitButton('edit')}
              </Form.Item>
            )}
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label="Stages"
            >
              <Paragraph className={styles.text}>
                {mode === 'add' ? 'Manage and track project progress with stages' : 'Review the project structure and progress'}
              </Paragraph>
              {isLoading
                ? <LoadSpinner />
                : (
                  <WorkletsList
                    onChange={handleChangeWorklets}
                    onDelete={handleRemoveExistingWorklet}
                    onEditing={setEditingWorklets}
                    readOnly={isWorkletsDisabled}
                    worklets={worklets}
                    installedApps={installedApps}
                    counts={countsByWorklet}
                  />
                )}
            </Form.Item>
            <Form.Item>
              {renderSubmitButton('add')}
            </Form.Item>
          </Col>
        </Row>
        {mode === 'edit' && (
          <Row className={styles.row}>
            { isArchiveProjectEnabled && (
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Archive project"
                >
                  { !isArchivedProject && (
                    <>
                      <Paragraph className={styles.text}>
                        This project will be deactivated and will not count towards the active project cap for your plan. Members can still continue to completion of their tasks and their data will continue to be available in the project. You will not be able to unarchive at this time.
                        {' '}
                        <Link href="https://intercom.help/aspireiq_elevate/en/articles/6523259-how-to-archive-projects" target="_blank">Learn more</Link>
                      </Paragraph>
                      <Button
                        onClick={showArchiveModal}
                      >
                        Archive Project
                      </Button>
                    </>
                  )}
                  { isArchivedProject && (
                    <Paragraph className={styles.text}>
                      This project was deactivated and will not count towards the active project cap for your plan. Members can still continue to completion of their tasks and their data will continue to be available in the project.
                      {' '}
                      <Link href="https://intercom.help/aspireiq_elevate/en/articles/6523259-how-to-archive-projects" target="_blank">Learn more</Link>
                    </Paragraph>
                  )}
                </Form.Item>
              </Col>
            )}
            <Col xs={24} lg={12}>
              <Form.Item
                label="Permanently delete project"
              >
                <Paragraph className={styles.text}>
                  This will remove your project completely.
                  {' '}
                  Use caution as this cannot be undone and member progress could be lost.
                </Paragraph>
                <Button
                  type="primary"
                  danger
                  ghost
                  onClick={showDeleteProjectModal}
                >
                  Delete Project
                </Button>
              </Form.Item>
              <DeleteStageModal
                open={deleteStageModalVisible}
                count={currentStageMemberCount}
                status={deletingStatus}
                percentage={deletingPercentage}
                onRequestClose={hideDeleteStageModal}
                onDelete={skipWorklet}
              />
            </Col>
          </Row>
        )}
      </Form>
    </Space>
  );
};
