import {
  OverlaySpinner,
  ChevronRightIcon,
} from '@components';
import {
  concat,
  map,
  filter,
  isNil,
} from 'lodash';
import * as qs from 'qs';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { useClientFeatureEnabled } from '@frontend/app/hooks/useClientFeatureEnabled';
import { useGetAllSpecificationTemplatesQuery } from '@frontend/app/hooks';
import {
  GetAllSpecificationTemplatesQuery_templates,
} from '@frontend/app/queries/types/GetAllSpecificationTemplatesQuery';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';

import { ClientFeature } from '@frontend/app/constants';
import styles from './TemplatesPage.scss';

import {
  CustomProjectTemplateName,
  ProjectsRouteRoot,
} from '../constants';

const { useMemo } = React;

export enum TemplateName {
  InfluencerCampaign = 'Influencer Campaign',
  ProductGifting = 'Product Seeding',
  AmbassadorProject = 'Ambassador Program',
}

const templateConfig = {
  [TemplateName.InfluencerCampaign]: {
    order: 0,
    name: 'Influencer Campaign',
    label: 'Short Term Influencer Project',
    description: 'Work with creators in exchange for content or promotion of your products.',
    logo: 'https://storage.googleapis.com/aspirex-static-files/projects-app/influencer_campaign_project_icon_3.png',
  },
  [TemplateName.ProductGifting]: {
    order: 1,
    name: 'Product Seeding',
    label: 'Product Gifting Project',
    description: 'Gift your products with no obligation to post, great for getting creators and customers familiar with your brand.',
    logo: 'https://storage.googleapis.com/aspirex-static-files/projects-app/product_gifting_project_icon_3.png',
  },
  [TemplateName.AmbassadorProject]: {
    order: 2,
    name: 'Ambassador Program',
    label: 'Long Term Influencer Project',
    description: 'Work with creators over an extended period of time in exchange for content or promotion of your products.',
    logo: 'https://storage.googleapis.com/aspirex-static-files/projects-app/long_term_influencer_project_icon_3.png',
  },
  [CustomProjectTemplateName]: {
    order: 3,
    name: CustomProjectTemplateName,
    label: 'Custom Project',
    description: 'Create a unique project with your own custom stages depending on your campaign goals.',
    logo: 'https://storage.googleapis.com/aspirex-static-files/projects-app/custom_project_icon_3.png',
  },
};

export const TEMPLATES_FOR_SALES = [
  {
    templateName: templateConfig[TemplateName.InfluencerCampaign].name,
    templateLogoURL: templateConfig[TemplateName.InfluencerCampaign].logo,
    workletSpecKeys: [
      'UID_0f195f0c-8623-409c-8e47-ddfcb4adf4fe',
      'UID_82122190-ff78-414d-9e16-53101e9ef20b',
      'UID_d247e1d4-4841-4707-868b-1bc4baad0985',
      'UID_a6f4a27c-fbc7-46e4-a47c-1a625bd4dfd1',
      'UID_d386978a-0c42-4942-a5f1-50120bd80dde',
    ],
  },
  {
    templateName: templateConfig[TemplateName.AmbassadorProject].name,
    templateLogoURL: templateConfig[TemplateName.AmbassadorProject].logo,
    workletSpecKeys: [
      'UID_0f195f0c-8623-409c-8e47-ddfcb4adf4fe',
      'UID_82122190-ff78-414d-9e16-53101e9ef20b',
      'UID_d247e1d4-4841-4707-868b-1bc4baad0985',
      'UID_a6f4a27c-fbc7-46e4-a47c-1a625bd4dfd1',
      'UID_d386978a-0c42-4942-a5f1-50120bd80dde',
    ],
  },
  {
    templateName: templateConfig[TemplateName.ProductGifting].name,
    templateLogoURL: templateConfig[TemplateName.ProductGifting].logo,
    workletSpecKeys: [
      'UID_0f195f0c-8623-409c-8e47-ddfcb4adf4fe',
      'UID_82122190-ff78-414d-9e16-53101e9ef20b',
      'UID_d247e1d4-4841-4707-868b-1bc4baad0985',
      'UID_a6f4a27c-fbc7-46e4-a47c-1a625bd4dfd1',
      'UID_d386978a-0c42-4942-a5f1-50120bd80dde',
    ],
  },
];

type TTemplate = Omit<GetAllSpecificationTemplatesQuery_templates, '__typename'>;

export const TemplatesPage: React.FC = () => {
  const {
    loading: isLoading,
    data: {
      templates: templatesFromQuery = [],
    } = {},
  } = useGetAllSpecificationTemplatesQuery();
  const isDemoAccount = useClientFeatureEnabled(ClientFeature.DEMO);

  const sortedTemplates = useMemo(() => {
    const templates = concat<TTemplate>(
      isDemoAccount ? TEMPLATES_FOR_SALES : templatesFromQuery,
      {
        templateName: templateConfig[CustomProjectTemplateName].name,
        templateLogoURL: templateConfig[CustomProjectTemplateName].logo,
        workletSpecKeys: [],
      },
    );
    const filteredTemplates = filter(templates, (t) => !isNil(templateConfig[t.templateName]));
    return filteredTemplates.sort(
      (a: TTemplate, b: TTemplate) => templateConfig[a.templateName].order - templateConfig[b.templateName].order,
    );
  }, [templatesFromQuery, isDemoAccount]);

  const addEvent = useEventContext();

  const templateItemsElem = map(
    sortedTemplates,
    ({ templateName }, index) => (
      <NavLink
        key={templateName}
        to={{
          pathname: `${ProjectsRouteRoot}/new`,
          search: index < sortedTemplates.length - 1
            ? qs.stringify({ templateName })
            : undefined,
        }}
        onClick={() => {
          addEvent(EventName.SelectProjectTemplate, {
            template: templateName,
          });
        }}
      >
        <li className={styles.template}>
          <div className={styles.templateIcon}>
            <img src={templateConfig[templateName].logo} />
          </div>
          <div className={styles.templateText}>
            <p className={styles.templateLabel}>
              {templateConfig[templateName].label}
            </p>
            <p className={styles.templateDescription}>
              {templateConfig[templateName].description}
            </p>
          </div>
          <div className={styles.templateAction}>
            <span className={styles.templateActionText}>
              Create
            </span>
            {' '}
            <ChevronRightIcon className={styles.templateActionIcon} size={10} />
          </div>
        </li>
      </NavLink>
    ),
  );

  return (
    <div className={styles.TemplatesPage}>
      <header className={styles.templatesPageHeader}>
        <h1 className={styles.title}>Create a Project</h1>
      </header>
      <div className={styles.templatesPageContent}>
        <p className={styles.blurb}>
          A project can be anything from a long term program to a seasonal campaign.
          <br />
          Select a template below to get started!
        </p>
        <ul className={styles.templates}>
          {templateItemsElem}
        </ul>
        {isLoading && <OverlaySpinner />}
      </div>
    </div>
  );
};
