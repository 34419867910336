import * as React from 'react';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { isNil } from 'lodash';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import { GET_PRODUCTS_V3_QUERY } from '../queries';
import {
  GetProductsV3Query,
  GetProductsV3QueryVariables,
  GetProductsV3Query_productCollection_products,
} from '../queries/types/GetProductsV3Query';

export type IProduct = GetProductsV3Query_productCollection_products;
type IOptions = QueryHookOptions<GetProductsV3Query, GetProductsV3QueryVariables>;

const { useEffect, useMemo } = React;

export function useGetProductsV3(options: IOptions = {}) {
  const addEvent = useEventContext();
  const {
    loading, data, error, refetch, fetchMore,
  } = useQuery<
    GetProductsV3Query,
    GetProductsV3QueryVariables
  >(
    GET_PRODUCTS_V3_QUERY,
    {
      ...options,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: false,
    },
  );
  const productCollection = useMemo(() => data?.productCollection || {
    pageInfo: { cursor: '', hasNextPage: false }, products: [],
  }, [data?.productCollection]);
  useEffect(() => {
    if (options.variables?.resourceId && !isNil(error)) {
      addEvent(EventName.ShopifyInvalidToken, {
        resourceId: options.variables?.resourceId,
        error,
      });
    }
  }, [options.variables, error, addEvent]);

  return {
    loading,
    productCollection,
    error,
    refetch,
    fetchMore,
  };
}
