import React, { useEffect, useState } from 'react';
import { lowerCase, map } from 'lodash';
import { Tag } from '@revfluence/fresh';
import styles from './CreatorProfile.scss';

interface ITag {
    name?: string;
    color?: string;
}
const TagRuleset = [
    {
        name: 'Lifestyle',
        color: '#D3F1F5',
    },
    {
        name: 'Beauty',
        color: '#E3F1BB',
    },
    {
        name: 'Fashion',
        color: '#BDD7C8',
    },
    {
        name: 'Vlogger',
        color: '#F5D9D3',
    },
    {
        name: 'NYC',
        color: '#F4F2DE',
    },
    {
        name: 'LA',
        color: '#F0DACD',
    },
    {
        name: 'Millennial',
        color: '#FFFBE6',
    },
    {
        name: 'Gen Z',
        color: '#F6F3EF',
    },
];

const Tags = ({ tags }) => {
    const [tagsToShow, setTagsToShow] = useState<ITag[]>([]);
    useEffect(() => {
        const tagSet = new Set(map(tags, (tag) => lowerCase(tag)));
        const tag_ar = [];
        map(TagRuleset, (tagRule) => {
            if (tagSet.has(lowerCase(tagRule.name))) {
                tag_ar.push(tagRule);
            }
        });
        setTagsToShow(tag_ar);
    }, [tags]);
    return (
        tagsToShow.length
            ? (
              <div className={styles.tags}>
                {
                    map(tagsToShow, (tag) => <Tag color={tag.color}>{tag.name}</Tag>)
                }
              </div>
)
        : null
    );
};

export default Tags;
