import * as React from 'react';
import cx from 'classnames';
import { first, isEmpty } from 'lodash';

import { ConversionTrackingPane, ShareLinkPane } from '@affiliates/components';
import { OFFER_SOURCE } from '@affiliates/types/globalTypes';
import { GetOfferById_offer } from '../../queries/types/GetOfferById';

import styles from '../OfferDetails/OfferDetails.scss';

interface IProps {
  missingShopifyCredentials: boolean;
  offer: GetOfferById_offer;
  offerSource: OFFER_SOURCE;
  offerStatus: string;
  onClickGenerateLink: () => void;
  disableAddMemberButton: boolean;
}

const CONVERSION_TRACKING_TITLE = `
  Nice! Now add conversion tracking to your site.
`.trim();

export const ZeroState: React.FC<Readonly<IProps>> = (props) => {
  const {
    offer,
    missingShopifyCredentials,
    offerSource,
    offerStatus,
    onClickGenerateLink,
    disableAddMemberButton,
  } = props;

  let conversionTrackingPane = null;
  if (offerSource === OFFER_SOURCE.TUNE && !isEmpty(offer.links)) {
    const link = first(offer.links);

    const conversionTrackingPaneClassName = cx(
      styles.conversionPane,
      styles.twoColumn,
    );
    conversionTrackingPane = (
      <div className={conversionTrackingPaneClassName}>
        <ConversionTrackingPane
          title={CONVERSION_TRACKING_TITLE}
          offerTrackingType={link.conversionTrackingType}
          pixelCode={link.pixelCode}
          postbackUrl={link.postbackUrl}
        />
      </div>
    );
  }

  const shareLinkWrapperClassName = cx(
    styles.pixelPane,
    {
      [styles.twoColumn]: offerSource === OFFER_SOURCE.TUNE,
    },
  );

  return (
    <div className={styles.zeroState}>
      {conversionTrackingPane}
      <div className={shareLinkWrapperClassName}>
        <ShareLinkPane
          offerStatus={offerStatus}
          missingShopifyCredentials={missingShopifyCredentials}
          offerSource={offerSource}
          onClick={onClickGenerateLink}
          isReadOnly={offer.isReadOnly}
          disableAddMemberButton={disableAddMemberButton}
        />
      </div>
    </div>
  );
};
