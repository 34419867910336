/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_vaveb_157 {
  display: flex;
}

._justify-content-space-between_vaveb_161 {
  justify-content: space-between;
}

._tabular-nums_vaveb_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_vaveb_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._BackLink_vaveb_178 {
  margin-right: 1rem;
}
._BackLink_vaveb_178 ._icon_vaveb_181 {
  margin-right: 1rem;
  font-size: 1.5rem;
  color: #1a1818;
}
._BackLink_vaveb_178 span {
  margin-left: 8px;
}

._backLinkSection_vaveb_190 {
  margin-bottom: 1rem;
}