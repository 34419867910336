import * as React from 'react';
import cx from 'classnames';
import { isEmpty, size } from 'lodash';
import { RawDraftContentState } from 'draft-js';

import { IPreviewConfig } from '@frontend/app/components/MessageComposer//EmailComposer';
import { EmailPreviewer } from '@frontend/app/components/MessageComposer/EmailPreviewer';

// import { IProduct } from '../useFetchProducts';
import { Footer } from '../components/Footer';
import MessageComposer from '../components/MessageComposer';

import styles from './ComposeMessage.scss';

const { useState } = React;

interface IProps {
  // products: IProduct[];
  // shops: any;
  resource,
  setResourceId,
  subject,
  setSubject,
  editorState,
  setEditorState
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  members: any[];
  incrementStep?(): void;
  onSkip?(): void;
  initialValue?: string | RawDraftContentState;
}

export const ComposeMessage: React.FunctionComponent<IProps> = (props) => {
  const {
    incrementStep,
    onSkip,
    resource,
    setResourceId,
    subject,
    setSubject,
    editorState,
    setEditorState,
    initialValue,
  } = props;

  const [previewConfig,
    setPreviewConfig] = useState<IPreviewConfig>(null);

  return (
    <div className={styles.composeMessage}>
      <div className={styles.scrollable}>
        <MessageComposer
          className={cx(styles.messageForm, {
            [styles.hide]: !!previewConfig,
          })}
          resource={resource}
          onResourceChange={setResourceId}
          subject={subject}
          onSubjectChange={setSubject}
          editorState={editorState}
          onEditorStateChange={(state) => {
            setEditorState(state);
          }}
          members={props.members}
          onPreview={(config) => setPreviewConfig(config)}
          memberCount={size(props.members)}
          initialValue={initialValue}
        />
        {previewConfig && (
          <div className={styles.previewContainer}>
            <EmailPreviewer
              canSendMessages={false}
              previewConfig={previewConfig}
              onBack={() => setPreviewConfig(null)}
              memberCount={size(props.members)}
              disableRemoveRecipient
            />
          </div>
        )}
        {incrementStep
          && (
          <Footer
            primaryButtonText="Next"
            primaryButtonAction={incrementStep}
            secondaryButtonText="Continue without Message"
            secondaryButtonAction={onSkip}
            disablePrimaryAction={isEmpty(props.members) || subject === ''}
            disableSecondaryAction={isEmpty(props.members) || subject === ''}
          />
)}
      </div>
    </div>
  );
};
