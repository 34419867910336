import * as React from 'react';
import { format } from 'date-fns';
import {
  isEmpty,
  isFunction,
  map,
  orderBy,
  uniq,
} from 'lodash';

import { SideBarButton } from '@affiliates/AspireUI';
import { PaymentsOverview } from '@affiliates/components';
import { STABulkPayments } from '@affiliates/components';
import { useSTAPaymentAppContext } from '@affiliates/contexts';
import {
  useApolloClient,
  useGetAdvertiserSignupDate,
  useGetPaymentAppBalance,
  useGetPaymentGroupLogEntries,
} from '@affiliates/hooks';
import { GetPaymentGroupLogEntries_paymentGroupLogEntries as IPaymentGroupLog } from '@affiliates/queries/types/GetPaymentGroupLogEntries';
import { logger } from '@common';
import { INewPayment, IPaymentGroupMetadata, INewPaymentMetadata } from '@frontend/applications/PaymentsApp/BulkPaymentsApp/containers';
import { IPayment, IPaymentGroup } from '@frontend/applications/PaymentsApp/models';
import { getDateLabelFilterRange } from '@frontend/app/components/DateFilter/getDateFilterLabel';
import { useCreateProvisionalPaymentGroupMutation } from '@affiliates/hooks/useCreatePaymentGroupMutation';
import { useCreatePaymentLogMutation } from '@affiliates/hooks/useCreatePaymentLogMutation';
import { IDateRangeSettings } from '@frontend/app/components';
import { useMapDateRangeToVariables } from '@frontend/app/hooks';
import { AffiliatePaymentsOwed, AffiliatePaymentsOwedVariables } from '../../queries/types/AffiliatePaymentsOwed';
import { AFFILIATE_PAYMENTS_OWED } from '../../queries';

const { useState, useEffect, useMemo } = React;

const paymentIcon = require('@frontend/app/assets/svgs/payment_circle.svg');

interface ISTAPaymentMetadata extends INewPaymentMetadata {
  columns: {
    offerName: string;
    previousPayout: number;
  };
  affiliateOfferLinkId?: number;
  affiliateOfferPromoId?: number;
  externalNote?: string; // used to display note in member activity feed
}

interface ISTANewPayment extends INewPayment {
  metadata: ISTAPaymentMetadata;
  mixpanelFields: {
    offerSource: string;
    offerId: number;
  }
}
interface ISTAPaymentAppProps {
  clientId: string;
  clientName: string;
  initialDateFilterSettings: IDateRangeSettings;
  migrateToGraphQL: boolean;
}

const STA_PAYMENT_GROUP_METADATA: IPaymentGroupMetadata = {
  columns: [
    {
      field: 'offerName',
      headerName: 'Offer',
    },
    {
      field: 'previousPayout',
      headerName: 'Prev. Payout',
      cellType: 'numeric',
      isPrice: true,
      width: 140,
    },
  ],
};

const PAYMENT_GROUP_NAME_DATE_FORMAT = 'MMM dd, yyyy';

export const STAPaymentApp: React.FC<Readonly<ISTAPaymentAppProps>> = (props) => {
  const {
    dateFilter,
    paymentsDue,
    offers,
    refreshCurrentDashboard,
    refreshCurrentTable,
  } = useSTAPaymentAppContext();
  const client = useApolloClient();
  const [pendingPaymentGroups, setPendingPaymentGroups] = useState<IPaymentGroupLog[]>([]);
  const [paidPaymentGroups, setPaidPaymentGroups] = useState<IPaymentGroupLog[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [paymentFlowOn, setPaymentFlowOn] = useState<boolean>(false);
  const [isGeneratingPayments, setIsGeneratingPayments] = useState<boolean>(false);
  const [newPayments, setNewPayments] = useState<ISTANewPayment[]>([]);
  const [paymentGroupName, setPaymentGroupName] = useState('');

  const [createProvisionalPaymentGroup] = useCreateProvisionalPaymentGroupMutation();

  const [createProvisionalPayment] = useCreatePaymentLogMutation();

  const advertiserSignupQuery = useGetAdvertiserSignupDate();
  const earliestDate: Date | null = useMemo(() => {
    if (advertiserSignupQuery.loading || advertiserSignupQuery.error) {
      return null;
    }
    return advertiserSignupQuery.data?.advertiser?.createdDate || null;
  }, [advertiserSignupQuery]);

  const dateFilterRange = useMapDateRangeToVariables(dateFilter.dateRange);

  const paymentGroupMetadata = useMemo((): IPaymentGroupMetadata => ({
    ...STA_PAYMENT_GROUP_METADATA,
    start_date: dateFilterRange.startDate?.toString(),
    end_date: dateFilterRange.endDate?.toString(),
  }), [dateFilterRange.endDate, dateFilterRange.startDate]);

  const createProvisionalPaymentGroupFromResponse = async (paymentGroupResponse: IPaymentGroup) => {
    const payload = {
      variables: {
        data: {
          id: paymentGroupResponse.id,
          name: paymentGroupResponse.name,
          client_id: paymentGroupResponse.client_id,
          created_ts: paymentGroupResponse.created_ts,
          aspirex_application: paymentGroupResponse.aspirex_application,
          start_date: dateFilterRange.startDate || null,
          end_date: dateFilterRange.endDate || null,
        },
      },
    };
    await createProvisionalPaymentGroup(payload);
  };

  const createProvisionalPaymentFromResponse = async (paymentResponse: IPayment) => {
    const payload = {
      variables: {
        data: {
          id: paymentResponse.id,
          payment_group_id: paymentResponse.payment_group_id,
          has_paid_publisher: paymentResponse.has_paid_publisher,
          canceled: paymentResponse.canceled,
          amount_intended_for_publisher: paymentResponse.amount_intended_for_publisher,
          currency_code: paymentResponse.currency_code,
          currency_xrate: paymentResponse.currency_xrate,
          created_ts: paymentResponse.created_ts,
          paypal: paymentResponse.paypal,
          payee_name: paymentResponse.payee_name,
          aspirex_application: paymentResponse.aspirex_application,
          metadata: paymentResponse.metadata,
          client_id: paymentResponse.client_id,
        },
      },
    };
    await createProvisionalPayment(payload);
  };

  // Turn off apollo cache for this request, because paymentGroupLogs can have
  // the same id and apollo create cache keys using __typename and id, so it
  // thinks is the same object, causing it to duplicate it.
  const {
    data: {
      paymentGroupLogEntries = null,
    } = {},
    refetch: refreshPaymentGroupLogEntries,
  } = useGetPaymentGroupLogEntries({
    fetchPolicy: 'no-cache',
  });

  const {
    data: {
      paymentAppBalance = null,
    } = {},
    refetch: refreshPaymentAppBalance,
  } = useGetPaymentAppBalance();

  useEffect(() => {
    if (paymentGroupLogEntries) {
      const newPendingPaymentGroups = paymentGroupLogEntries.filter((paymentGroup) => paymentGroup.status === 'PENDING');
      const orderedPendingPaymentGroups = orderBy(newPendingPaymentGroups, ['externalDateCreated'], ['desc']);
      const newPaidPaymentGroups = paymentGroupLogEntries.filter((paymentGroup) => paymentGroup.status === 'PAID');
      const orderedPaidPaymentGroups = orderBy(newPaidPaymentGroups, ['externalDateCreated'], ['desc']);
      setPendingPaymentGroups(orderedPendingPaymentGroups);
      setPaidPaymentGroups(orderedPaidPaymentGroups);
    }
  }, [paymentGroupLogEntries, setPendingPaymentGroups, setPaidPaymentGroups]);

  const paymentsDateFilter = useMapDateRangeToVariables(dateFilter.dateRange);
  const paymentPeriod = getDateLabelFilterRange(dateFilter.dateRange, earliestDate);

  const initializePaymentFlow = async () => {
    if (isEmpty(offers)) {
      return;
    }

    setIsGeneratingPayments(true);
    try {
      const offerIds = uniq(map(offers, 'id'));
      const newPaymentsResult = await client.query<AffiliatePaymentsOwed, AffiliatePaymentsOwedVariables>({
        fetchPolicy: 'no-cache',
        query: AFFILIATE_PAYMENTS_OWED,
        variables: {
          endDate: paymentsDateFilter.endDate || null,
          offerIds,
          startDate: paymentsDateFilter.startDate || null,
        },
      });
      const generatedNewPayments = map(newPaymentsResult.data.affiliatePaymentsOwed, (payment): ISTANewPayment => {
        const {
          metadata: {
            affiliateOfferLinkId,
            affiliateOfferPromoId,
            ...remainingMetadata
          },
          ...remainingPaymentProps
        } = payment;

        if (affiliateOfferLinkId) {
          return {
            ...remainingPaymentProps,
            metadata: {
              ...remainingMetadata,
              affiliateOfferLinkId,
            },
          };
        }
        if (affiliateOfferPromoId) {
          return {
            ...remainingPaymentProps,
            metadata: {
              ...remainingMetadata,
              affiliateOfferPromoId,
            },
          };
        }

        throw new Error('Payment missing both affiliate offer link and affiliate offer promo.');
      });
      setPaymentGroupName(format(new Date(), PAYMENT_GROUP_NAME_DATE_FORMAT));
      setNewPayments(generatedNewPayments);
      setPaymentFlowOn(true);
    } catch (err) {
      logger.error(err);
    }
    setIsGeneratingPayments(false);
  };

  const onPaymentsOverviewClose = () => {
    setVisible(false);
    setPaymentFlowOn(false);
  };

  const onBulkPaymentsClose = () => {
    setPaymentFlowOn(false);

    if (isFunction(refreshCurrentDashboard)) {
      refreshCurrentDashboard();
    }

    if (isFunction(refreshCurrentTable)) {
      refreshCurrentTable();
    }

    refreshPaymentAppBalance();
    refreshPaymentGroupLogEntries();
  };

  return (
    <div>
      <SideBarButton onClick={() => setVisible(true)} text="Payment Overview" icon={paymentIcon} />
      <STABulkPayments
        visible={paymentFlowOn && visible}
        onClose={onBulkPaymentsClose}
        clientId={props.clientId}
        clientName={props.clientName}
        newPayments={newPayments}
        paymentGroupName={paymentGroupName}
        paymentGroupMetadata={paymentGroupMetadata}
        onPaymentGroupMade={createProvisionalPaymentGroupFromResponse}
        onPaymentCompleted={createProvisionalPaymentFromResponse}
        paymentPeriod={paymentPeriod}
        migrateToGraphQL={props.migrateToGraphQL}
        dateFilterRange={dateFilterRange}
      />
      <PaymentsOverview
        clientId={props.clientId}
        clientName={props.clientName}
        dateFilter={dateFilter}
        earliestDate={earliestDate}
        pendingPaymentGroups={pendingPaymentGroups}
        paidPaymentGroups={paidPaymentGroups}
        makeAPayment={initializePaymentFlow}
        isMakeAPaymentLoading={isGeneratingPayments}
        totalPaymentsDue={paymentsDue}
        forOneOffer={offers.length === 1}
        balance={paymentAppBalance}
        visible={!paymentFlowOn && visible}
        onClose={onPaymentsOverviewClose}
      />
    </div>
  );
};
