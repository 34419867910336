import 'css-chunk:src/applications/AffiliatesApp/components/ManageOffer/ManageOffer.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1fign_157",
  "justify-content-space-between": "_justify-content-space-between_1fign_161",
  "tabular-nums": "_tabular-nums_1fign_165",
  "ManageOfferDrawer": "_ManageOfferDrawer_1fign_178",
  "header": "_header_1fign_181",
  "offerDetails": "_offerDetails_1fign_187",
  "offerName": "_offerName_1fign_197",
  "img": "_img_1fign_201",
  "manageOfferwrapper": "_manageOfferwrapper_1fign_209",
  "manageOfferButtons": "_manageOfferButtons_1fign_212",
  "buttonsAction": "_buttonsAction_1fign_224",
  "buttonTitle": "_buttonTitle_1fign_233",
  "mb0": "_mb0_1fign_251",
  "manageOfferContainer": "_manageOfferContainer_1fign_254",
  "boldText": "_boldText_1fign_268",
  "memberCount": "_memberCount_1fign_272",
  "ManageOfferAction": "_ManageOfferAction_1fign_276",
  "customInputBox": "_customInputBox_1fign_284",
  "tooltipError": "_tooltipError_1fign_293",
  "customInputBoxError": "_customInputBoxError_1fign_299",
  "activeDateContainer": "_activeDateContainer_1fign_306",
  "card": "_card_1fign_311",
  "dateHeader": "_dateHeader_1fign_314",
  "dateContainer": "_dateContainer_1fign_320",
  "dateInput": "_dateInput_1fign_323",
  "noticeContainer": "_noticeContainer_1fign_327",
  "iconWrapper": "_iconWrapper_1fign_333",
  "show": "_show_1fign_1"
};