import * as React from 'react';
import cx from 'classnames';
import {
  map, toLower, includes, filter,
  isEmpty, differenceWith, isNumber,
} from 'lodash';

import { AlertIcon, CloseIcon } from '@components';
import { Popover } from '@components';
import { Toast, IToastRefHandles } from '@components';

const {
  useRef, useEffect,
  useState, useCallback,
} = React;
import styles from './EmailInput.scss';

export interface IEmailRecipient {
  email: string;
  isValid: boolean;
}
export interface ISuggestedRecipient {
  email: string;
  name: string;
}
interface IProps {
  onRecipientsChange(recipients: IEmailRecipient[]): void;
  disabled?: boolean;
  onChange?(value: string): void;
  defaultRecipients?: IEmailRecipient[];
  suggestedRecipients?: ISuggestedRecipient[];
  className?: string;
}

export const emailRegex = /^([a-zA-Z0-9_\-.]+)(\+[a-zA-Z0-9_\-.]+)?@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,9})$/;

const EmailInputComponent = React.forwardRef((props: IProps, ref: React.RefObject<HTMLDivElement>) => {
  const {
    defaultRecipients,
    suggestedRecipients,
    onRecipientsChange,
    disabled,
  } = props;

  const [value, setValue] = useState('');
  const [recipients, setRecipients] = useState<IEmailRecipient[]>([]);
  const [suggestedIndex, setSuggestedIndex] = useState<number>(null);
  const [focus, setFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const toastRef = useRef<IToastRefHandles>(null);
  const containerRef = ref || useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (defaultRecipients) {
      setRecipients([
        ...defaultRecipients,
      ]);
    }
  }, [defaultRecipients, setRecipients]);

  const getSuggestedRecipients = useCallback(() => {
    const lowerVal = toLower(value);

    return filter(
      differenceWith(suggestedRecipients, recipients, (r1, r2) => r1.email === r2.email),
      (recipient) => includes(recipient.email, lowerVal) || includes(toLower(recipient.name), lowerVal),
    );
  }, [suggestedRecipients, recipients, value]);

  const deleteRecipient = useCallback((index: number) => {
    const newRecipients = [...recipients.slice(0, index), ...recipients.slice(index + 1)];
    setRecipients(newRecipients);

    onRecipientsChange(newRecipients);
  }, [recipients, setRecipients, onRecipientsChange]);

  const addRecipient = useCallback((index?: number) => {
    const toast = toastRef.current;
    const suggestedRecipients = getSuggestedRecipients();
    const suggestedRecipient = suggestedRecipients[isNumber(index) ? index : suggestedIndex];

    const inputValue = suggestedRecipient ? suggestedRecipient.email : value;

    if (isEmpty(inputValue)) {
      return;
    }

    const recipient = recipients.find((recipient) => recipient.email === inputValue);
    if (recipient) {
      toast.showMessage({
        content: (
          <div>
            Recipient
            {' '}
            <span>{inputValue}</span>
            {' '}
            already added.
          </div>
        ),
      });

      setValue(inputValue);

      return;
    }

    recipients.push({
      email: inputValue,
      isValid: emailRegex.test(inputValue),
    });

    onRecipientsChange(recipients);

    setValue('');
    props.onChange('');
    setSuggestedIndex(0);
    setRecipients([...recipients]);
  }, [getSuggestedRecipients, suggestedIndex, value, recipients, onRecipientsChange, props]);

  const handleKeyPress = useCallback((event) => {
    const { keyCode } = event;
    const suggestedRecipients = getSuggestedRecipients();

    if (!focus) {
      return;
    }

    // 27: ESC
    if (keyCode === 27) {
      inputRef.current.blur();

      return;
    }

    // 9:tab, 13: enter, 32: space, 59: semicolon, 188: comma
    if ([9, 13, 32, 186, 188].includes(keyCode)) {
      event.preventDefault();

      addRecipient();
    }

    // 38: up arrow, 40: down arrow
    if (keyCode === 38) {
      setSuggestedIndex(Math.max(0, suggestedIndex - 1));
    } else if (keyCode === 40) {
      setSuggestedIndex(Math.min(suggestedRecipients.length - 1, suggestedIndex + 1));
    }

    // delete recipient
    if (isEmpty(value) && keyCode === 8) {
      deleteRecipient(recipients.length - 1);
    }
  }, [
    getSuggestedRecipients, inputRef, addRecipient,
    setSuggestedIndex, suggestedIndex, deleteRecipient,
    focus, value, recipients,
  ]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress]);

  const handleOptionClick = (index: number) => {
    addRecipient(index);
  };

  const focusInput = (event) => {
    event.preventDefault();

    inputRef.current.focus();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setValue(toLower(value));
    setSuggestedIndex(0);

    props.onChange(value);
  };

  const handleInputFocus = () => {
    setFocus(true);
    setSuggestedIndex(0);
  };

  const handleInputBlur = () => {
    setFocus(false);
    setSuggestedIndex(-1);
  };

  const availableSuggestedRecipients = getSuggestedRecipients();

  return (
    <div
      ref={containerRef}
      className={cx(styles.EmailInput, props.className)}
      onMouseDown={focusInput}
    >
      {isEmpty(recipients) && !value && !focus && (
        <div className={styles.placeholder}>Add email addresses</div>
      )}
      {map(recipients, (recipient, index) => (
        <span
          key={recipient.email}
          className={cx(styles.item, {
              [styles.error]: !recipient.isValid,
            })}
        >
          {recipient.isValid ? null : <AlertIcon size={16} className={styles.alertIcon} />}
          {recipient.email}
          {!disabled && (
            <CloseIcon
              size={10}
              className={styles.deleteIcon}
              onMouseDown={() => deleteRecipient(index)}
            />
          )}
        </span>
        ))}
      <input
        value={value}
        ref={inputRef}
        onChange={handleChange}
        className={styles.input}
        autoCapitalize="off"
        autoCorrect="off"
        autoComplete="off"
        spellCheck={false}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        disabled={disabled}
      />
      <Toast ref={toastRef} />
      <Popover
        className={styles.Popover}
        mountRef={inputRef}
        anchorOrigin="start"
        arrowPosition="start"
        renderMask={false}
        showArrow={false}
        minWidth={300}
        show={!isEmpty(availableSuggestedRecipients) && focus}
      >
        <div className={styles.list}>
          {map(availableSuggestedRecipients, (recipient, index) => (
            <div
              key={recipient.email}
              className={cx(styles.option, {
                [styles.active]: suggestedIndex === index,
              })}
              onMouseDown={() => handleOptionClick(index)}
            >
              <div className={styles.name}>{recipient.name}</div>
              <div className={styles.email}>{recipient.email}</div>
            </div>
          ))}
        </div>
      </Popover>
    </div>
  );
});

EmailInputComponent.defaultProps = {
  onChange: () => undefined,
  defaultRecipients: [],
  suggestedRecipients: [],
};
EmailInputComponent.displayName = 'EmailInputComponent';

export const EmailInput = React.memo(EmailInputComponent);
