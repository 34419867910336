import * as React from 'react';
import cx from 'classnames';

import {
  ArrowDownFilledIcon,
  CheckIcon,
  DownloadIcon,
  LockIcon,
  TagIcon,
  TrashcanIcon,
} from '@components';
import {
  Button,
  IconButton,
  Badge,
  Checkbox,
  Select,
} from '@components';
import { Modal } from '@revfluence/fresh';

import { TSortType } from './redux/models';

import styles from './ContentActionBar.scss';

interface IProps {
  isLoading: boolean;
  selectedContentSize: number;
  isAllSelected: boolean;
  sort: string;
  contentSize: number;
  totalContent: number;

  onToggleSelectAll?();
  onClickEditPermissions?();
  onClickDownload?();
  onClickEditTags?();
  onClickDeleteContent?();
  onChangeSort?(newSort: TSortType);

  className?: string;
}

const SortOptions = [
  { value: 'date', label: 'Date' },
  { value: 'creator', label: 'Creator' },
];

const SelectAllLabel: React.FunctionComponent<{ contentSize: number }> = React.memo((props) => {
  if (props.contentSize > 0) {
    return (
      <>
        <Badge theme="primary" className={styles.badge}>
          {props.contentSize}
        </Badge>
        <span className={styles.contentSelected}>Content Selected</span>
      </>
    );
  } else {
    return <span>Select All</span>;
  }
});

const SortLabel: React.FunctionComponent<{ sort: string }> = React.memo(({ sort }) => (
  <Button
    className={(styles as any).sortLabel}
    theme="light"
    label={sort ? `Sort by ${sort}` : 'Sort'}
    icon={<ArrowDownFilledIcon size={10} />}
    iconSide="right"
  />
));

const ContentActionBar: React.FunctionComponent<IProps> = React.memo((props) => {
  const renderSortOption = (option: { value: string; label: string }) => (
    <>
      {props.sort === option.value && <CheckIcon size={18} />}
      <span className={styles.sortOptionLabel}>{option.label}</span>
    </>
  );

  const handleDeleteContent = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this content?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: props.onClickDeleteContent,
    });
  };

  return (
    <div className={cx(styles.ContentActionBar, props.className)}>
      <div className={styles.contentCount}>
        {props.isLoading && props.contentSize === 0 && 'Loading content...'}
        {!props.isLoading && props.contentSize === 0 && 'No Content Found'}
        {props.contentSize > 0
          && `Showing ${props.contentSize} of ${props.totalContent} matching content`}
      </div>

      <div className={styles.actions}>
        <Checkbox
          label={<SelectAllLabel contentSize={props.selectedContentSize} />}
          checked={props.isAllSelected}
          onChange={props.onToggleSelectAll}
          className={styles.selectAll}
        />
        <IconButton
          icon={<LockIcon />}
          tooltip="Edit Permissions"
          tooltipPlacement="bottom"
          onClick={props.onClickEditPermissions}
          className={styles.button}
          disabled={props.selectedContentSize === 0}
        />
        <IconButton
          icon={<DownloadIcon />}
          tooltip="Download"
          tooltipPlacement="bottom"
          onClick={props.onClickDownload}
          className={styles.button}
          disabled={props.selectedContentSize === 0}
        />
        <IconButton
          icon={<TagIcon />}
          tooltip="Edit Tag(s)"
          tooltipPlacement="bottom"
          onClick={props.onClickEditTags}
          className={styles.button}
          disabled={props.selectedContentSize === 0}
        />
        <IconButton
          icon={<TrashcanIcon />}
          tooltip="Delete Content(s)"
          tooltipPlacement="bottom"
          onClick={handleDeleteContent}
          className={styles.button}
          disabled={props.selectedContentSize === 0}
        />
        <Select
          options={SortOptions}
          renderOptionElement={renderSortOption}
          onChange={props.onChangeSort}
          customLabelElement={<SortLabel sort={props.sort} />}
          className={styles.sort}
          popoverProps={{
            minWidth: 160,
          }}
        />
      </div>
    </div>
  );
});

export default ContentActionBar;
