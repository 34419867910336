/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_sp1m3_157 {
  display: flex;
}

._justify-content-space-between_sp1m3_161 {
  justify-content: space-between;
}

._tabular-nums_sp1m3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_sp1m3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._OfferForm_sp1m3_178 ._instructions_sp1m3_178 ._title_sp1m3_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._OfferForm_sp1m3_178 {
  position: relative;
}
._OfferForm_sp1m3_178 ._instructions_sp1m3_178 {
  margin-bottom: 2.1875rem;
}
._OfferForm_sp1m3_178 ._instructions_sp1m3_178 ._description_sp1m3_225 {
  color: #8f8d91;
  font-weight: 400;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 {
  margin-bottom: 1.5rem;
  padding: 0px 24px 0 24px;
  border-radius: 6px;
  border: 1px solid var(--neutral-color-palette-gray-4, #F0F0F0);
  background: var(--neutral-color-palette-gray-1, #FFF);
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 ._title_sp1m3_178 {
  align-items: center;
  color: #1a1818;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 ._title_sp1m3_178 .anticon,
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 ._title_sp1m3_178 ._icon_sp1m3_244 {
  margin-right: 0.5rem;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 .ant-form-item-extra {
  margin-bottom: 0;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 .ant-input[type=text] {
  padding-left: 0.6875rem;
  padding-right: 0.6875rem;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 .ant-input-affix-wrapper-sm {
  padding-left: 0.6875rem;
  padding-right: 0.6875rem;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 ._headerSpace_sp1m3_258 {
  margin-bottom: 24px;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 ._dividerColor_sp1m3_261 {
  color: #F0F0F0;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 .ant-collapse-header-text {
  font-size: 16px;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 .ant-collapse-content-box {
  padding: 0 !important;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 .ant-collapse-header {
  padding: 24px 40px 12px 0px !important;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 ._customLabelText_sp1m3_273 {
  margin-bottom: 1rem;
}
._OfferForm_sp1m3_178 ._formSection_sp1m3_229 ._AlertWrapper_sp1m3_276 {
  margin-bottom: 1.5rem;
}
._OfferForm_sp1m3_178 ._codeSuffixWrapper_sp1m3_279 {
  margin-left: auto;
  margin-right: 0;
  width: 95%;
}
._OfferForm_sp1m3_178 ._halfRow_sp1m3_284 {
  margin-bottom: 0.75rem;
}
._OfferForm_sp1m3_178 ._indented_sp1m3_287 {
  padding-left: 1.5rem;
}
._OfferForm_sp1m3_178 ._indented_sp1m3_287 .ant-form-item-control {
  max-width: 9.375rem !important;
}
._OfferForm_sp1m3_178 ._centered_sp1m3_293 {
  text-align: center;
}
._OfferForm_sp1m3_178 ._radioGroup_sp1m3_296 {
  width: 100%;
}
._OfferForm_sp1m3_178 ._radioGroup_sp1m3_296 ._radio_sp1m3_296 {
  display: block;
  margin-bottom: 0.75rem;
}
._OfferForm_sp1m3_178 ._radioGroup_sp1m3_296 ._radio_sp1m3_296:last-child {
  margin-bottom: 0;
}
._OfferForm_sp1m3_178 ._expiredMessage_sp1m3_306 {
  box-sizing: border-box;
  border: 0.0625rem solid #3996e0;
  border-radius: 0.25rem;
  background: #eff5f9;
  color: #3996e0;
  font-weight: normal;
}
._OfferForm_sp1m3_178 ._datePicker_sp1m3_314 {
  border: 1px solid #bdbdbd;
  border-radius: 0.5rem;
  height: 2.5rem;
  width: 100%;
}
._OfferForm_sp1m3_178 ._datePicker_sp1m3_314 .ant-picker-input input {
  font-size: 0.875rem;
}
._OfferForm_sp1m3_178 ._tooltipList_sp1m3_323 {
  margin: 0;
  padding: 0 1.125rem;
}
._OfferForm_sp1m3_178 ._tooltipList_sp1m3_323 li {
  margin: 0.375rem 0;
  line-height: 1.125rem;
}
._OfferForm_sp1m3_178 ._hThreeRem_sp1m3_331 {
  height: 3rem;
}
._OfferForm_sp1m3_178 ._hasCharacterCount_sp1m3_334 {
  margin-bottom: 0;
}
._OfferForm_sp1m3_178 ._tagIcon_sp1m3_337 {
  transform: rotate(90deg);
}
._OfferForm_sp1m3_178 ._fullWidth_sp1m3_340 {
  width: 100%;
}
._OfferForm_sp1m3_178 ._archiveOfferAlert_sp1m3_343 {
  padding: 1.5rem 1.5rem 0rem 1.5rem;
}

._utmWrapper_sp1m3_347 {
  margin: 40px 0;
  border-radius: 6px;
  border: 1px solid var(--neutral-color-palette-gray-5, #D9D9D9);
  background: var(--neutral-color-palette-gray-1, #FFF);
}

._urlwrapper_sp1m3_354 {
  border-radius: 6px;
  background-color: #FFF;
  padding: 0 24px 1px 24px;
}
._urlwrapper_sp1m3_354 ._boxwrapper_sp1m3_359 {
  margin-bottom: 0.5rem;
  padding-top: 1.5rem;
  display: flex;
  column-gap: 8px;
}
._urlwrapper_sp1m3_354 ._boxwrapper_sp1m3_359 ._urltitle_sp1m3_365 {
  font-size: 1rem;
  font-weight: 600;
}
._urlwrapper_sp1m3_354 ._subheading_sp1m3_369 {
  font-size: 0.875rem;
  font-weight: 400;
}
._urlwrapper_sp1m3_354 ._utmexample_sp1m3_373 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--neutral-color-palette-gray-7, #8c8c8c);
}
._urlwrapper_sp1m3_354 ._customText_sp1m3_380 {
  margin-bottom: 8px;
  color: var(--neutral-color-palette-gray-6, #BFBFBF);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 157.143% */
}
._urlwrapper_sp1m3_354 ._inputBox_sp1m3_389 {
  margin-bottom: 1.5rem;
}
._urlwrapper_sp1m3_354 ._Closefield_sp1m3_392 {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
._urlwrapper_sp1m3_354 ._Closefield_sp1m3_392 .ant-select-selector {
  width: 8.125rem !important;
}
._urlwrapper_sp1m3_354 ._disable-option_sp1m3_400 ._ant-select-item-option-content_sp1m3_400 {
  color: var(--neutral-color-palette-gray-9, #1F1F21);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  border: 2px solid red !important;
}

._disable-option_sp1m3_400 {
  color: var(--neutral-color-palette-gray-9, #1F1F21);
  font-family: "SF Pro Text";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
}

.custom-button {
  border: none;
  background: none !important;
}
.custom-button svg {
  color: #000;
}

.custom-button:hover svg {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

._promoCodeContainer_sp1m3_431 ._descTextColor_sp1m3_431 {
  color: var(--neutral-color-palette-gray-8, #505256);
  margin-bottom: 1.5rem;
}
._promoCodeContainer_sp1m3_431 ._customRadioGroup_sp1m3_435 {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}
._promoCodeContainer_sp1m3_431 ._customRadioGroup_sp1m3_435 span {
  text-transform: capitalize;
}
._promoCodeContainer_sp1m3_431 ._samplePromoContainer_sp1m3_442 {
  padding: 12px;
  border-radius: 6px;
  background: var(--branding-accents-background-1, #F0F2F5);
}

._purchaseTypeContainer_sp1m3_448 .ant-radio-wrapper {
  display: block;
  margin-bottom: 1rem;
}
._purchaseTypeContainer_sp1m3_448 .ant-radio-wrapper:last-child {
  margin-bottom: 0;
}

._offerSyncContainer_sp1m3_456 .ant-switch {
  margin-right: 0.5rem;
}
._offerSyncContainer_sp1m3_456 ._customSyncText_sp1m3_459 {
  margin-top: 0.5rem;
  color: var(--neutral-color-palette-gray-9, #1F1F21);
}
._offerSyncContainer_sp1m3_456 ._offerSyncForm_sp1m3_463 {
  margin-bottom: 0;
}
._offerSyncContainer_sp1m3_456 ._syncContainer_sp1m3_466 {
  display: flex;
}

._offerStatusContainer_sp1m3_470 .ant-picker {
  width: 100%;
}

._CustomTextColor_sp1m3_474 {
  color: var(--neutral-color-palette-gray-9, #1F1F21);
  margin-bottom: 0.5rem;
}

.radioCustom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.radioCustom .ant-radio-wrapper {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 4px;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radioCustom .ant-radio-wrapper-checked {
  border: 2px solid #167CF4;
  border-radius: 8px;
  padding: 4px;
  background-color: #F4FCFF;
  z-index: 1;
}
.radioCustom .ant-radio {
  position: absolute;
  left: -9999px;
  overflow: hidden;
}
.radioCustom .ant-radio + * {
  display: block;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
  overflow: hidden;
}

._disableRadio_sp1m3_513 {
  opacity: 0.5;
  pointer-events: none;
}

._payoutOptionContainer_sp1m3_518 {
  margin-bottom: 24px;
}

._startDateBorder_sp1m3_522 .ant-picker-status-error.ant-picker,
._startDateBorder_sp1m3_522 .ant-picker-status-error.ant-picker:not([disabled]):hover {
  border: 1px solid #d9d9d9;
  box-shadow: none;
}

._mPayoutConatiner_sp1m3_528 {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}
._mPayoutConatiner_sp1m3_528 ._addPayoutButton_sp1m3_533 {
  width: 100%;
  font-size: 14px;
}
._mPayoutConatiner_sp1m3_528 ._defaultPayout_sp1m3_537 {
  background-color: #F1E5AC;
  padding: 0 8px;
  margin: 0 5px;
  border-radius: 5px;
}

._radioCustomContainer_sp1m3_544 {
  margin: 24px 0px;
  display: flex;
  justify-content: space-between;
}
._radioCustomContainer_sp1m3_544 ._radioItem_sp1m3_549 {
  padding: 0.5rem;
}
._radioCustomContainer_sp1m3_544 ._radioItem_sp1m3_549 ._image_sp1m3_552 {
  width: 100%;
}
._radioCustomContainer_sp1m3_544 ._radioItem_sp1m3_549 ._description_sp1m3_225 {
  font-weight: bold;
}

._marginOnAlert_sp1m3_559 {
  margin-top: 0.5rem;
}

._errorMessage_sp1m3_563 {
  color: #FF4D4F;
}

._alertContainer_sp1m3_567 {
  margin-bottom: 24px;
}