import * as React from 'react';
import { filter, isNull } from 'lodash';
import { IStepInfo as Step } from '@frontend/applications/Shared/Wizard/container';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { ActiveDates } from '../../components/ActiveDates';
import { ReviewMembers } from '../../components/ReviewMembers';
import { SelectMembers } from '../../components/SelectMembers';
import { StatsCardPromos } from '../../components/StatsCardPromos';
import { TRefreshMembersProps } from '../../types';
import { TActions, TState } from './state';
import { StatsCardLinks } from '../../components/StatsCardLinks';

const { useMemo } = React;

interface IStepConfig {
  instructions: Step['instructions'];
  nextButtonConfig: Step['nextButtonConfig'];
  previousButtonConfig: Step['previousButtonConfig'];
  stepNum: Step['stepNum'];
}

type TStepFactory = (config: IStepConfig, overrides?: Partial<Step>) => Step;

export const getSteps = (props: TRefreshMembersProps, state: TState, actions: TActions) => {
  const {
    offerSource, offerName, programLabel, refreshDatesOnly,
  } = props;
  const refreshDatesTitle = 'Edit Active Dates';
  let title: string;
  let statsCard: React.ReactNode;
  const selectedMembers = isNull(state.members) ? [] : filter(state.members, (m) => m.selected);
  const forceCheckInMembers = isNull(state.members) ? [] : filter(state.members, (m) => m.forceCheckIn);
  const allMembers = isNull(state.members) ? [] : state.members;

  switch (props.offerSource) {
    case OFFER_SOURCE.SHOPIFY:
      title = 'Refresh Members';
      statsCard = (
        <StatsCardPromos
          currentActiveDatesLabel={state.currentActiveDatesLabel}
          activeDates={state.activeDates}
          discountAmount={props.discountAmount}
          discountType={props.discountType}
          members={state.members}
          mode={refreshDatesOnly ? 'refreshDates' : 'refresh'}
          offerName={offerName}
          isNewFlow={props.isNewFlow}
          payoutType={props.payoutType}
          percentPayout={props.percentPayout}
          flatPayout={props.flatPayout}
          startDate={props.startDate}
          endDate={props.endDate}
          offerStartDate={props.offerStartDate}
          offerEndDate={props.offerEndDate}
        />
      );
      break;
    case OFFER_SOURCE.TUNE:
      title = 'Reactivate Links';
      statsCard = (
        <StatsCardLinks
          payoutType={props.payoutType}
          expirationDate={props.expirationDate}
          flatPayout={props.flatPayout}
          percentPayout={props.percentPayout}
          members={state.members}
          mode="refresh"
        />
      );
      break;
  }

  const selectMembers: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        {props.isNewFlow ? null : statsCard}
        <SelectMembers
          offerSource={offerSource}
          markMembersSelected={actions.selectMembers}
          members={state.members}
          mode="refresh"
          offerName={offerName}
          programLabel={programLabel}
          refreshDatesOnly={refreshDatesOnly}
          updateMemberCode={actions.updateMemberCode}
          isWorkflow
          searchKey={state.searchKey}
          updateSearchKey={actions.updateSearchKey}
          isNewFlow={props.isNewFlow}
        />
      </>
    ),
    title: refreshDatesOnly ? refreshDatesTitle : title,
    ...config,
    ...overrides,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;

  const selectActiveDates: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        {statsCard}
        <ActiveDates
          activeDates={state.activeDates}
          updateDates={actions.updateActiveDateField}
        />
      </>
    ),
    title: refreshDatesOnly ? refreshDatesTitle : title,
    ...config,
    ...overrides,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;

  const reviewMembers: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        {props.isNewFlow ? null : statsCard}
        <ReviewMembers
          offerSource={offerSource}
          activeDates={state.activeDates}
          members={selectedMembers}
          mode={refreshDatesOnly ? 'refreshDates' : 'refresh'}
          offerName={offerName}
          programLabel={programLabel}
          isWorkflow={props.isWorkflow}
          updateMemberCode={actions.updateMemberCode}
          isEditablerow
          isNewFlow={props.isNewFlow}
        />
      </>
    ),
    title: refreshDatesOnly ? refreshDatesTitle : title,
    ...config,
    ...overrides,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;

  const completed: TStepFactory = (config, overrides = {}): Step => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const members = useMemo(() => [...selectedMembers, ...forceCheckInMembers], [selectedMembers, forceCheckInMembers]);

    return {
      actionComponents: (
        <>
          {props.isNewFlow ? null : statsCard}
          <ReviewMembers
            offerSource={offerSource}
            activeDates={state.activeDates}
            members={members}
            mode={refreshDatesOnly ? 'refreshDates' : 'refresh'}
            offerName={offerName}
            programLabel={programLabel}
            isWorkflow={props.isWorkflow}
            updateMemberCode={actions.updateMemberCode}
            isEditablerow={false}
            isNewFlow={props.isNewFlow}
          />
        </>
      ),
      title: refreshDatesOnly ? refreshDatesTitle : title,
      ...config,
      ...overrides,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
  };

  return {
    selectMembers,
    selectActiveDates,
    reviewMembers,
    completed,
    selectedMembers,
    forceCheckInMembers,
    allMembers,
  };
};
