import * as React from 'react';
import cx from 'classnames';
import {
  map, find,
} from 'lodash';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';

import {
  LazyImage,
} from '@components';
import { Button, Typography } from '@revfluence/fresh';
import { MessageActionType } from '@frontend/app/types/globalTypes';

import { CONTENT_APP_ID } from '@frontend/app/constants/applicationIds';
import { AUTOMATION_IMAGE_URL } from '@frontend/app/constants/imageUrls';
import { useAuth } from '@frontend/context/authContext';
import styles from './MemberActivityItem.scss';
import { IApplication, IMemberActivity } from './types';

const { useMemo, useCallback } = React;
const { Text } = Typography;

interface IProps {
  memberName: string;
  message: IMemberActivity;
  applications: IApplication[];
  className?: string;
}

/**
 * @type {React.FC}
 */
export const MemberActivityItem: React.FC<IProps> = React.memo((props) => {
  const { applications, message } = props;
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { clientInfo } = useAuth();

  const handleAction = (action) => {
    if (message.applicationId === CONTENT_APP_ID) {
      history.push({
        ...location,
        pathname: `${match.url}/app/${message.applicationId}/content`,
        search: `link_params=${encodeURIComponent(JSON.stringify(action.parameters))}`,
      });
    } else {
      history.push({
        ...location,
        pathname: `${match.url}/app/${message.applicationId}`,
        search: `link_params=${encodeURIComponent(JSON.stringify(action.parameters))}`,
      });
    }
  };
  const isMessageActivity = useMemo(() => message.type === 'GMAIL' || message.type === 'OUTLOOK', [message]);
  const messageThreadUrl = useMemo(() => (
    `${window.location.origin}/client/${clientInfo?.id}/messages/${message?.threadId}`
    ), [clientInfo?.id, message?.threadId]);

    const currentApplication = useMemo(() => find(applications, (app) => app.id === message.applicationId), [
      applications,
      message,
    ]);

  const renderTitle = useCallback(() => {
    if (isMessageActivity) {
      return (
        <Text>{`${message.payload.from} sent a message`}</Text>
      );
    }
    return <Text>{message.title || message.subject}</Text>;
  }, [isMessageActivity, message]);

  return (
    <div className={cx(styles.MemberActivityItem, props.className)}>
      <div className={styles.appDetails}>
        <div className={styles.appIcon}>
          {message.applicationId
          ? currentApplication
            ? (
              <>
                <LazyImage className={styles.appIcon} src={currentApplication.icon} />
                {message.isAutomated
                  && (
                    <LazyImage
                      src={AUTOMATION_IMAGE_URL}
                      className={styles.automationIcon}
                    />
                  )}
              </>
)
            : <div className={styles.appIcon} />
          : <div className={styles.gmailIcon} />}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.label}>
          {renderTitle()}
        </div>
        <div className={styles.actions}>
          {map(message.payload.messageActions, (action) => (
            <React.Fragment key={action.actionText}>
              {action.actionType === MessageActionType.openUrl && (
                <Button
                  type="link"
                  href={action.parameters.url}
                  target="_blank"
                >
                  {action.actionText}
                </Button>
              )}
              {action.actionType === MessageActionType.openModal && (
                <Button
                  type="link"
                  onClick={() => handleAction(action)}
                >
                  {action.actionText}
                </Button>
              )}

            </React.Fragment>
          ))}
          {isMessageActivity && (
            <Button
              type="link"
              href={messageThreadUrl}
              target="_blank"
            >
              Go To Thread
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

MemberActivityItem.displayName = 'MemberActivityItem';
