._Collapse_asyhh_1 > .ant-collapse-item > .ant-collapse-header {
  border-top: 1px solid var(--gray-5);
  padding-top: 16px;
}
._Collapse_asyhh_1 > .ant-collapse-item > .ant-collapse-header, ._Collapse_asyhh_1 > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
}
._Collapse_asyhh_1 > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box .ant-space {
  width: 100%;
}
._Collapse_asyhh_1 .ant-collapse-header-text {
  margin: 0;
}
._Collapse_asyhh_1 .ant-collapse-item-active .ant-collapse-header svg.ant-collapse-arrow, ._Collapse_asyhh_1 .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon svg {
  right: 0;
  margin-top: 0 !important;
}
._Collapse_asyhh_1 .ant-collapse-content-box {
  padding-bottom: 16px;
}

._memberDetailsCard_asyhh_23 {
  padding: 12px 24px;
}
._memberDetailsCard_asyhh_23 .ant-card-head,
._memberDetailsCard_asyhh_23 .ant-card-body,
._memberDetailsCard_asyhh_23 .ant-card-head-title {
  padding: 0;
}

._header_asyhh_32 {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 16px;
}
._header_asyhh_32 ._headerUserInfo_asyhh_38 {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
}
._header_asyhh_32 ._headerUserInfo_asyhh_38 ._headerUserName_asyhh_44 {
  flex: 1;
  color: var(--gray-9);
  font-weight: 600;
}
._header_asyhh_32 .ant-btn {
  border: 0;
  padding: 0;
}

._agentInfoIcon_asyhh_54 {
  font-size: 12px;
  vertical-align: middle;
  margin-left: 8px;
}