import * as React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import {
 Button, Col, Row, Space, Statistic, Tooltip,
} from '@revfluence/fresh';
import { CircleInfoIcon } from '@revfluence/fresh-icons/regular/esm';
import { formatters } from '@frontend/app/components/StatisticsCard/formatters';
import { getTrendTooltip, getLastFullMonths } from '@frontend/applications/ReportsApp/containers/utils';

import styles from './Impact.module.scss';
import Statistics from '../../../../components/Statistics';
import Trend from '../../../../components/Trend';
import { IImpactHomeProps } from '../../Home';
import { getTrendProps } from '../../../Reporting/components/MetricsSecondary/MetricsSecondary';
import { composeImpactValue } from '../../../../utils/reportingFunctions';

interface IImpactProps {
  impactData: IImpactHomeProps['impactData'];
}

const Impact = ({ impactData }: IImpactProps) => {
  const history = useHistory();

  return (
    <Space direction="vertical" size="large" align="center" className={cn(styles.container)}>
      <Statistic
        title={(
          <>
            Impact Value
            <Tooltip
              title="We calculate Impact Value by adding the values for Impression, Engagement, Content, and Sales and divide the total by the amount you have paid creators."
              placement="top"
            >
              <CircleInfoIcon />
            </Tooltip>
          </>
        )}
        value={composeImpactValue(impactData.impact)}
        suffix="x"
        className={cn(styles.value)}
      />
      <Statistics
        size="small"
        valueRender={() => formatters.default((Math.abs(impactData.percentDiff) || 0), { precision: 0 })}
        suffix="%"
        prefix={<Trend {...getTrendProps(impactData.percentDiff || 0)} />}
        {...getTrendProps(impactData.percentDiff || 0)}
        bordered
        tooltip={getTrendTooltip(getLastFullMonths(2))}
        className={cn(styles.trend)}
      />
      <Row gutter={[24, 24]} justify="center">
        <Col xs={12}>
          <Statistic
            title="Total Value"
            valueRender={() => (impactData.totalValue ? formatters.default(impactData.totalValue) : '-')}
            prefix={impactData.totalValue ? '$' : ''}
            className={cn(styles.statistic)}
          />
        </Col>
        <Col xs={12}>
          <Statistic
            title="Investment"
            valueRender={() => (impactData.investment ? formatters.default(impactData.investment) : '-')}
            prefix={impactData.investment ? '$' : ''}
            className={cn(styles.statistic)}
          />
        </Col>
        <Col>
          <Button
            onClick={() => history.push('/impact_dashboard')}
          >
            View Impact Dashboard
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

export default Impact;
