import * as React from 'react';
import { EyeIcon, UserIcon } from '@revfluence/fresh-icons/regular/esm';
import { Divider, Tag } from '@revfluence/fresh';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { fromUnixTime, format } from 'date-fns';
import { PaymentCreationSource, PaymentsProgram, TGroup } from '@frontend/applications/PaymentsApp/types';
import styles from './MemberPaymentSummary.scss';
import { SectionHeader } from '../SectionHeader';
import { EditableInput } from '../EditableInput';
import { EditableSelect } from '../EditableSelect';
import { SubItemSmall } from '../SubItemSmall';

const { useMemo } = React;
interface IProps {
  brief?: IAgreement;
  name: string;
  payPalEmail: string;
  groups: TGroup[];
  selectedGroupId: number | null;
  setFieldValue: (fieldName: string, value: number | string) => void;
  programs: PaymentsProgram[];
  selectedProgramId: number | null;
  paymentCreationSource: PaymentCreationSource;
  updateMemberEmail: (paypalAddress: string) => void;
  staPaymentCount?:number;
}

export const MemberPaymentSummary: React.FC<IProps> = (props) => {
  const {
    brief,
    name,
    payPalEmail,
    groups,
    selectedGroupId,
    setFieldValue,
    programs,
    selectedProgramId,
    paymentCreationSource,
    updateMemberEmail,
    staPaymentCount,
  } = props;
  const groupOptions = useMemo(() => groups.map((group) => ({ label: group.title, value: group.id })), [groups]);
  const programOptions = useMemo(() => programs.map((program) => ({ label: program.title, value: program.id })), [
    programs,
  ]);
  return (
    <div className={styles.memberPaymentSummaryContainer}>
      <SectionHeader title="Overview" sectionIcon={<UserIcon />} tooltipMessage="Overview" />
      <Divider className={styles.divider} />
      <div className={styles.summmary}>
        {paymentCreationSource === PaymentCreationSource.SALES_TRACKING ? (
          <SubItemSmall name="Paying To" value={`${staPaymentCount} Members`} />
        ) : (
          <>
            <SubItemSmall name="Member" value={name} />
            <SubItemSmall
              name="PayPal Address"
              tooltipMessage="If the member does not have a PayPal address, Aspire will email them to request their PayPal address."
              value={(
                <EditableInput
                  value={payPalEmail}
                  onChange={(newValue) => {
                    setFieldValue('paypalAddress', newValue);
                    updateMemberEmail(newValue);
                  }}
                  type="email"
                />
              )}
            />
            <SubItemSmall
              name="Project (Optional)"
              value={
                paymentCreationSource === PaymentCreationSource.MEMBER ? (
                  <EditableSelect
                    value={selectedProgramId}
                    onChange={(newValue) => setFieldValue('selectedProgramId', newValue)}
                    options={programOptions}
                  />
                ) : (
                  `${programOptions?.find((program) => program.value === selectedProgramId)?.label || '-'}`
                )
              }
            />
            <SubItemSmall
              name="Group (Optional)"
              value={(
                <EditableSelect
                  value={selectedGroupId}
                  onChange={(newValue) => setFieldValue('selectedGroupId', newValue)}
                  options={groupOptions}
                />
              )}
            />
            {brief && (
              <>
                <SubItemSmall
                  name="Brief Created"
                  value={`${brief?.created_at ? format(fromUnixTime(brief.created_at), 'MMMM d, yyyy') : ''}`}
                />
                <SubItemSmall
                  name="Brief Status"
                  value={(
                    <Tag icon={<EyeIcon />} color="success">
                      {`Agreed on ${brief?.agreed_ts ? format(fromUnixTime(brief.agreed_ts), 'MMMM d, yyyy') : ''}`}
                    </Tag>
                  )}
                />
                <SubItemSmall name="Agreed Amount" value={`$${brief.price}`} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
