import * as React from 'react';
import cx from 'classnames';
import { isFunction, map, size } from 'lodash';

import {
 Col, Row, Select, Typography, Popover, Tag,
} from '@revfluence/fresh';

import { useQuery } from '@apollo/client';
import { emailRegex } from '@components';
import { IMemberSearchQuery, Operator } from '@frontend/app/types/MemberSearch';
import { SEARCH_MEMBERS_QUERY } from '@frontend/app/queries';
import { GetMembersQuery_members as IMember } from '@frontend/app/queries/types/GetMembersQuery';
import {
  SearchMembersQuery,
  SearchMembersQueryVariables,
} from '@frontend/app/queries/types/SearchMembersQuery';

import styles from './AutocompleteMemberInput.scss';

const {
  useMemo, useState, useEffect,
} = React;

type SelectMember = IMember & { label: string, value: string };

interface IProps {
  validMembers?: IMember[];
  onChange(members: IMember[]);
  clearBackupPlans?(): void;
  disableInput?: boolean;
  members?: IMember[];
  debounce?: number;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const AutocompleteMemberInput: React.FunctionComponent<IProps> = React.memo((props) => {
  const [text, setText] = useState<string>('');
  const [recipientsByLabel, setRecipientsByLabel] = useState<Record<string, IMember>>({});

  const query: IMemberSearchQuery = {
    fields: {
      and: [
        {
          or: [
            { column: 'email', [Operator.CONTAINS]: text },
            { column: 'name', [Operator.CONTAINS]: text },
          ],
        },
        { column: 'email', [Operator.NOT_NULL]: true },
      ],
    },
  };

  const {
    data: {
      members: membersData = [],
    } = {},
  } = useQuery<SearchMembersQuery, SearchMembersQueryVariables>(SEARCH_MEMBERS_QUERY, {
    variables: {
      query,
      take: 10,
      skip: 0,
    },
    skip: !text,
  });

  const defaultRecipients = useMemo(() => (
    props.validMembers.map(
      (member) => ({
        ...member,
        label: member.name
        ? (
          member.name + (size(member.talentAgents) > 0 ? (` + ${size(member.talentAgents)}`) : '')
        ) : member.email,
        value: member.email,
      }),
    )
  ), [props.validMembers]);

  const [selectedRecipients, setSelectedRecipients] = useState<SelectMember[]>(defaultRecipients);

  useEffect(() => {
    setSelectedRecipients(defaultRecipients);
  }, [defaultRecipients]);

  useEffect(() => {
    setRecipientsByLabel(
      selectedRecipients.reduce((acc, recipient) => {
        acc[recipient.label] = recipient;
        return acc;
      }, {}),
    );
  }, [selectedRecipients]);

  const handleSelect = (email) => {
    if (!emailRegex.test(email)) {
      return;
    }

    let newRecipients = [];
    setSelectedRecipients((prevValue) => {
      const member = membersData.find((m) => m.email === email);
      if (member) {
        newRecipients = [
          ...prevValue,
          {
            label: member.name + (size(member.talentAgents) > 0 ? ` + ${size(member.talentAgents)}` : ''),
            value: email,
            ...member,
          },
        ];
      } else {
        newRecipients = [...prevValue, { label: email, value: email, email }];
      }

      props.onChange(newRecipients as SelectMember[]);
      if (isFunction(props.clearBackupPlans)) {
        props.clearBackupPlans();
      }
      return newRecipients;
    });
  };

  const handleDeSelect = (email) => {
    let filtered = [];
    setSelectedRecipients((prevValue) => {
      filtered = prevValue?.filter((obj) => obj.value !== email);
      props.onChange(filtered);
      return filtered;
    });
  };

  const suggestedRecipients = useMemo(() => {
    if (!text) {
      return [];
    }

    if (size(membersData) > 0) {
      return map(membersData, (member) => ({
        label: member.name,
        value: member.email,
      }));
    }

    return [{ label: text, value: text }];
  }, [text, membersData]);

  const handleSearch = (value: string) => {
    setText(value);
  };

  const renderPopoverContent = (label: string) => (
    <div
      onMouseDown={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={styles.recipientPopoverContent}
    >
      <div className={styles.section}>
        <h4>Member</h4>
        <p>{recipientsByLabel[label]?.name}</p>
        <p>{recipientsByLabel[label]?.email}</p>
      </div>
      {map(recipientsByLabel[label]?.talentAgents, (agent, i) => (
        <div className={styles.section}>
          <h4>
            Talent Manager #
            {i + 1}
          </h4>
          <p>{agent.agentName}</p>
          <p>{agent.agentEmail}</p>
        </div>
      ))}
    </div>
  );

  const renderTag = (props) => (
    <Popover
      content={renderPopoverContent(props.label)}
      placement="bottom"
    >
      <Tag
        closable={props.closable}
        onClose={props.onClose}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className={styles.recipientTag}
      >
        {props.label}
      </Tag>
    </Popover>
  );

  return (
    <div className={cx(props.className)}>
      <Select
        className={styles.recipientsSelect}
        mode="multiple"
        placeholder={props.placeholder || 'Select recipients'}
        tagRender={renderTag}
        value={selectedRecipients}
        onSelect={handleSelect}
        onDeselect={handleDeSelect}
        onSearch={handleSearch}
        autoFocus={selectedRecipients.length === 0}
        disabled={props.disableInput}
      >
        {suggestedRecipients.map((member) => (
          <Select.Option
            value={member.value}
            key={member.value}
            label={member.value}
          >
            <Row justify="space-between">
              <Col>
                <Typography.Text>
                  {member.label}
                  {` <${member.value}>`}
                </Typography.Text>
              </Col>
            </Row>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
});

AutocompleteMemberInput.defaultProps = {
  debounce: 500,
};
