import {
 Button, Col, Drawer, Row, Space, Typography,
} from '@revfluence/fresh';
import React, { useMemo } from 'react';
import { ArrowLeftIcon } from '@revfluence/fresh-icons/regular/esm';
import { ProductContextState, useProductsContext } from '../ProductsContext';
import styles from './ImportSettingsDrawer.scss';
import { RootScreen } from './RootScreen';
import { ProductDataSettings, ProductDataSettingsFooter } from './ProductDataSettings';
import { SyncPreferenceFooter, SyncPreferencesSettings } from './SyncPreferencesSettings';
import { InventoryManagement, InventoryManagementFooter } from './InventoryManagement';
import { ImportSettingsProvider } from './ImportSettingsContext';

const { Title } = Typography;

const importSettingsTitleMap: Record<ProductContextState['importSettingScreen'], string> = {
  root: 'Configure Import Settings',
  metadata: 'Product Metadata Settings',
  sync: 'Sync & Data Import Preferences',
  inventory: 'Inventory Management Options',
};

export const ImportSettingsDrawer = () => {
  const {
    isImportSettingsDrawerOpen,
    setIsImportSettingsDrawerOpen,
    importSettingScreen,
    setImportSettingScreen,
  } = useProductsContext();

  const screen = useMemo(
    () =>
      ({
        root: <RootScreen />,
        metadata: <ProductDataSettings />,
        sync: <SyncPreferencesSettings />,
        inventory: <InventoryManagement />,
      }[importSettingScreen]),
    [importSettingScreen],
  );

  const footer = useMemo(
    () =>
      ({
        root: null,
        metadata: <ProductDataSettingsFooter />,
        sync: <SyncPreferenceFooter />,
        inventory: <InventoryManagementFooter />,
      }[importSettingScreen]),
    [importSettingScreen],
  );

  const handleClose = () => {
    setIsImportSettingsDrawerOpen(false);
    setImportSettingScreen('root');
  };

  return (
    <ImportSettingsProvider>
      <Drawer
        width={572}
        title={(
          <Row align="middle" justify="space-between">
            <Col>
              {importSettingScreen !== 'root' && (
              <Button icon={<ArrowLeftIcon />} onClick={() => setImportSettingScreen('root')} data-testid="backBtn" />
            )}
            </Col>
            <Col>
              <Space align="center" size={12}>
                <Title level={4}>{importSettingsTitleMap[importSettingScreen]}</Title>
              </Space>
            </Col>
            <Col />
          </Row>
      )}
        open={isImportSettingsDrawerOpen}
        onClose={handleClose}
        className={styles.ImportSettingsDrawer}
        footer={footer}
      >
        {screen}
      </Drawer>
    </ImportSettingsProvider>
  );
};
