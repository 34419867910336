import {
  CheckCircleIconV2,
  XCircleIconV2,
  SpinnerIcon,
} from '@components';
import { Button } from 'antd';
import cx from 'classnames';
import {
  isEmpty,
  isFunction,
} from 'lodash';
import * as React from 'react';

import { logger } from '@common';
import { useMessagingContext } from '@frontend/hooks';
import { TProject } from '@frontend/app/containers/Projects/types';
import { useBulkMemberApplicantOperationMutation } from '@frontend/app/hooks';
import {
  BulkMemberApplicantOperationMutation,
} from '@frontend/app/queries/types/BulkMemberApplicantOperationMutation';
import {
  MemberSearchQuery_members as IMember,
} from '@frontend/app/queries/types/MemberSearchQuery';

import { MemberApplicantOperation } from '@frontend/app/types/globalTypes';
import styles from './ApplicantOperationButtons.scss';

const {
  useMemo,
  useRef,
} = React;

interface IProps {
  disabled?: boolean;
  hideApproveButton?: boolean;
  hideRejectButton?: boolean;
  hideApplicantCount?: boolean;
  memberApplicantOperation?: MemberApplicantOperation;
  inline?: boolean;
  memberIds?: IMember['id'][];
  onMemberApplicantOperationSuccess?: (memberIds?: IMember['id'][], operation?: MemberApplicantOperation) => void;
  onButtonClicked?: (operation?: MemberApplicantOperation) => void;
  onError?: (reason: unknown) => void;
  project: TProject;
}

export const ApplicantOperationButtons: React.FC<IProps> = (props) => {
  const {
    disabled,
    hideApproveButton = false,
    hideRejectButton = false,
    hideApplicantCount = false,
    memberApplicantOperation,
    inline,
    memberIds,
    onMemberApplicantOperationSuccess,
    onButtonClicked,
    onError,
    project,
  } = props;
  const { showError } = useMessagingContext();

  const processedMemberApplicantIds = useRef<number[]>();

  /**
   * Mutation
   */
  const [
    bulkMemberApplicantOperation,
    { loading: isMakingMemberApplicantsOperation },
  ] = useBulkMemberApplicantOperationMutation();

  const onCompleted = (
    { success }: BulkMemberApplicantOperationMutation,
    operation: MemberApplicantOperation,
  ) => {
    if (success && isFunction(onMemberApplicantOperationSuccess)) {
      onMemberApplicantOperationSuccess(processedMemberApplicantIds?.current, operation);
    }
  };

  const handleError = (error) => {
    logger.error(error);
    showError(error);
    if (isFunction(onError)) {
      onError(error);
    }
  };

  /**
   * Specific members -- if `memberIds` is provided
   */
  const memberApplicantsOperation = async (ids: number[], operation: MemberApplicantOperation) => {
    if (isEmpty(ids) || !project?.id) {
      logger.debug(
        'Need to provide both memberIds and projectId. Provided:',
        {
          memberIds: ids,
          projectId: project?.id,
        },
      );
      return;
    }
    processedMemberApplicantIds.current = ids;
    bulkMemberApplicantOperation({
      variables: {
        memberIds: ids,
        projectId: project.id,
        operation,
      },
    })
      .then((result) => onCompleted(result.data, operation))
      .catch(handleError);
  };

  /**
   * Handlers
   */
  const handleApproveButtonClicked = () => {
    if (isFunction(onButtonClicked)) {
      onButtonClicked(MemberApplicantOperation.Approve);
    }
    if (!isEmpty(memberIds)) {
      memberApplicantsOperation(memberIds, MemberApplicantOperation.Approve);
    }
  };

  const handleRejectButtonClicked = () => {
    if (isFunction(onButtonClicked)) {
      onButtonClicked(MemberApplicantOperation.Reject);
    }
    if (!isEmpty(memberIds)) {
      memberApplicantsOperation(memberIds, MemberApplicantOperation.Reject);
    }
  };

  const isApproving = useMemo(() => (
    memberApplicantOperation === MemberApplicantOperation.Approve
  ), [memberApplicantOperation]);

  const isRejecting = useMemo(() => (
    memberApplicantOperation === MemberApplicantOperation.Reject
  ), [memberApplicantOperation]);

  /**
   * Render
   */
  const countText = useMemo(
    () => (!hideApplicantCount && !isEmpty(memberIds)
      ? ` (${memberIds.length})`
      : ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [memberIds],
  );

  const isDisabled = (
    disabled
    || isMakingMemberApplicantsOperation
    || (!inline && isEmpty(memberIds))
  );

  return (
    <>
      {!hideApproveButton && (
      <Button
        disabled={isDisabled}
        className={cx({
          [styles.button]: !inline,
          [styles.inline]: inline,
          [styles.disabled]: isDisabled,
        }, styles.actionButton)}
        icon={
          isApproving ? (
            <SpinnerIcon
              className={styles.icon}
              size={inline ? 24 : 16}
            />
          ) : (
            <CheckCircleIconV2
              disabled={isDisabled}
              className={cx(styles.icon, styles.approveIcon)}
              size={inline ? 30 : 20}
            />
          )
        }
        onClick={handleApproveButtonClicked}
        type={inline ? 'link' : 'default'}
      >
        {!inline && (isApproving ? `Approving${countText}` : `Approve${countText}`)}
      </Button>
    )}
      {!hideRejectButton && (
      <Button
        disabled={isDisabled}
        className={cx({
          [styles.button]: !inline,
          [styles.inline]: inline,
          [styles.disabled]: isDisabled,
        }, styles.actionButton)}
        icon={
          isRejecting ? (
            <SpinnerIcon
              className={styles.icon}
              size={inline ? 24 : 16}
            />
          ) : (
            <XCircleIconV2
              disabled={isDisabled}
              className={cx(styles.icon, styles.rejectIcon)}
              size={inline ? 30 : 20}
            />
          )
        }
        onClick={handleRejectButtonClicked}
        type={inline ? 'link' : 'default'}
      >

        {!inline && (isRejecting ? `Rejecting${countText}` : `Reject${countText}`)}
      </Button>
    )}
    </>
  );
};

ApplicantOperationButtons.defaultProps = {
  disabled: false,
  hideApproveButton: false,
  hideRejectButton: false,
  hideApplicantCount: false,
  inline: false,
  memberIds: [],
  onMemberApplicantOperationSuccess: () => {},
  onButtonClicked: () => {},
  onError: () => {},
};
