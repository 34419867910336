import React, { useCallback, useMemo } from 'react';
import {
  IColumnsType, Table,
} from '@revfluence/fresh';
import { GetProductDetails_getProductVariantsByProductId_productVariants, GetProductDetails_getProductVariantsByProductId_productVariants_inventory } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductDetails';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import { useAuth } from '@frontend/context/authContext';
import { InventorySource } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { NameWithImageRenderer } from '../../components/NameWithImageRenderer/NameWithImageRenderer';
import { IVariantsRow } from '../types';
// import styles from './ProductDetailsDrawer.scss';

export interface IProductVariantsTableProps {
  variants: GetProductDetails_getProductVariantsByProductId_productVariants[]
}

export const ProductVariantsTable = ({
  variants,
}: IProductVariantsTableProps) => {
  const { clientConfig } = useGetClientConfig({
    variables: {
      clientId: useAuth().clientInfo?.id,
    },
  });

  const columns = useMemo<IColumnsType<IVariantsRow>>(() => [
    {
      title: 'Product',
      dataIndex: '_raw',
      key: 'product',
      render: NameWithImageRenderer,
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Inventory',
      dataIndex: 'inventory',
      key: 'inventory',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
    },
    // {
    //   title: 'Visibility',
    //   dataIndex: 'visibility',
    //   key: 'visibility',
    //   render: (visibility) => (
    //     <Button icon={visibility ? <EyeIcon /> : <EyeSlashIcon />} className={styles.tableRowActions} />
    //   ),
    // },
  ], []);

  const getInventory = useCallback((inventory: GetProductDetails_getProductVariantsByProductId_productVariants_inventory) => {
    if (clientConfig?.inventorySettings?.inventorySource === InventorySource.LOCATION_BASED_INVENTORY) {
      return inventory?.allLocationsInventory;
    } else if (clientConfig?.inventorySettings?.inventorySource === InventorySource.ALL_AVAILABLE_INVENTORY) {
      return inventory?.inventoryQuantity;
    }
    return inventory?.sellableOnlineQuantity;
  }, [clientConfig]);

  const tableData = useMemo<IVariantsRow[]>(
    () =>
      variants.map((variant) => {
      const transformedVariant = ({
        key: String(variant.id),
        id: String(variant.id),
        sku: variant.sku,
        title: variant.name,
        image: variant.images?.[0],
        inventory: getInventory(variant.inventory),
        price: variant.price,
        cost: variant?.metadata?.cost ? Number(variant.metadata.cost) : null,
        visibility: true,
      });
      return { ...transformedVariant, _raw: transformedVariant };
    }),
    [getInventory, variants],
  );

  return <Table columns={columns} dataSource={tableData} pagination={false} bordered />;
};
