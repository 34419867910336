import * as React from 'react';

import { Col, Row } from '@revfluence/fresh';

import { TContentDetails } from './types';
import { Gallery } from './components/Gallery';
import { Details } from './components/Details';

export const ContentDetails = ({
  gallery, comments, guidelines, onApprove, onReject, takingActions, canTakeActions, memberData,
}: TContentDetails) => (
  <Row style={{ height: '100%' }} gutter={[48, 24]}>
    <Col span={12} style={{ borderRight: '1px solid var(--gray-5)', overflow: 'hidden' }}>
      <Gallery {...gallery} memberData={memberData} />
    </Col>
    <Col span={12} style={{ height: '100%', overflowY: 'auto' }}>
      <Details
        comments={comments}
        guidelines={guidelines}
        canTakeActions={canTakeActions}
        onApprove={onApprove}
        onReject={onReject}
        takingActions={takingActions}
      />
    </Col>
  </Row>
);
