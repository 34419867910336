import * as React from 'react';
import { useState } from 'react';

import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { useGetOrdersByIds } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetOrdersByIds';
import { IWorkItem } from '@services/workflow';
import { Spinner, Typography, message } from '@revfluence/fresh';
import { ProjectConfigType } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useGetProjectConfigByType } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetProjectConfigByType';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { logger } from '@common';

import { useBulkReviewOrderRequests } from '@frontend/applications/ProductFulfillmentApp/hooks/useBulkReviewOrderRequests';
import { getOrderIdFromWorkItem } from '@frontend/applications/ProductFulfillmentApp/utils';
import { RejectOrderRequestEmail } from '@frontend/applications/ProductFulfillmentApp/CreatorProductSelection/components/RejectEmailComposer/RejectEmailComposer';
import { SendMessageInput } from '@frontend/app/types/globalTypes';
import { CSVErrorModal } from '../../components/CSVErrorModal/CSVErrorModal';
import { orderToCsv } from '../../utils/orderToCSV';
import { OrderReview } from '../../components/OrderReview/OrderReview';

export const ReviewOrderContainer = () => {
    const {
        workItems,
    } = useProductFulfillmentContext();
    const orderIds = workItems.map((workItem) => getOrderIdFromWorkItem(workItem));
    const orderIdWorkItemMap = workItems.reduce((acc, workItem) => {
        const orderId = getOrderIdFromWorkItem(workItem);
        if (orderId) {
            acc[orderId] = workItem.id;
        }
        return acc;
    }, {} as Record<string, IWorkItem>);

    const { closeModal, workflowActionParameters } = useApplication();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showRejectEmail, setShowRejectEmail] = useState(false);

    const { programId, programName } = workflowActionParameters;
    const {
        orders, loading: isLoadingOrders, error: getOrdersError,
    } = useGetOrdersByIds({
        variables: {
            ids: orderIds,
        },
    });
    const {
        projectConfig,
        loading: isLoadingProjectConfig,
    } = useGetProjectConfigByType({
        variables: {
            projectId: programId,
            type: ProjectConfigType.NoCatalogue,
        },
        skip: !programId,
    });
    const { bulkReviewOrderRequest } = useBulkReviewOrderRequests();
    const today = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    const defaultFileName = `${programName.replace(' ', '_')}_orders_${today}`;

    const onApprove = async () => {
        const formFields = projectConfig?.noCatalogueConfig?.formFields?.map((field) => field.key) || [];

        try {
            message.loading('Generating your CSV file. Your Download will begin automatically');
            await orderToCsv(defaultFileName, orders, formFields);
        } catch (error) {
            setIsModalOpen(true);
            logger.error(error);
            return;
        }

        await bulkReviewOrderRequest({
            variables: {
                params: {
                    approved: true,
                    orderInputs: orders.map((order) => ({
                        creatorOrderRequest: order.creatorOrderRequest,
                        orderId: order.id,
                        workItemId: orderIdWorkItemMap[order.id],
                    })),
                },
            },
            onCompleted: () => {
                closeModal();
                message.success('Order requests successfully reviewed');
            },
        });
    };

    const onReject = () => {
        setShowRejectEmail(true);
    };

    const rejectAndSendEmail = async (messageParams: SendMessageInput): Promise<void> => {
        await bulkReviewOrderRequest({
            variables: {
                params: {
                    approved: false,
                    messageParams: {
                        attachments: messageParams.attachments,
                        members: messageParams.members,
                        membersSearchQuery: messageParams.membersSearchQuery,
                        message: messageParams.message,
                        resourceId: messageParams.resourceId,
                        subject: messageParams.subject,
                        type: messageParams.type.toLowerCase(),
                    },
                    orderInputs: orders.map((order) => ({
                        creatorOrderRequest: order.creatorOrderRequest,
                        orderId: order.id,
                        workItemId: orderIdWorkItemMap[order.id],
                    })),
                },
            },
            onCompleted: () => {
                message.success('Order requests successfully rejected');
                closeModal();
            },
        });
    };
    const handleOk = () => {
        setIsModalOpen(false);
        onApprove();
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
      <>
        {(isLoadingProjectConfig || isLoadingOrders) && <Spinner />}
        {getOrdersError && <Typography.Text type="danger">{getOrdersError.message}</Typography.Text>}
        {orders && !showRejectEmail && (
        <OrderReview
          loading={isLoadingOrders}
          orders={orders}
          onApprove={onApprove}
          onReject={onReject}
        />
            )}
        <CSVErrorModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          defaultFileName={defaultFileName}
        />
        {showRejectEmail && <RejectOrderRequestEmail sendRejectionEmail={rejectAndSendEmail} />}
      </>
    );
};
