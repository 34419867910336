import gql from 'graphql-tag';

export const CREATE_IMPORTER_TASK_MUTATION = gql`
  mutation CreateImporterTask($params: CreateImporterTaskParams!) {
    createImporterTask(params: $params) {
      id
      status
    }
  }
`;

export const DELETE_IMPORTER_TASK_MUTATION = gql`
  mutation DeleteImporterTask($collectionId: Float!) {
    deleteWaitingImportTask(collectionId: $collectionId) {
      id
      collectionId
      status
    }
  }
`;

export const GET_IMPORTED_COLLECTIONS_BY_CLIENT_ID_QUERY = gql`
  query GetImportedCollectionsByClientId {
    getImportedCollections {
      id
      userInfo {
        name
      }
      collectionId
      status
      operation
      collection {
        id
        externalId
        name
        productCount
        source
        images
        lastSyncDate
        metadata {
          handle
          importedProductCount
          importedVariantCount
        }
      }
    }
  }
`;

export const GET_IMPORTED_COLLECTIONS_STATUSES_QUERY = gql`
  query GetImportedCollectionsStatusesQuery {
    getImportedCollections {
      id
      collectionId
      status
      collection {
        externalId
        lastSyncDate
        metadata {
          importedProductCount
          importedVariantCount
        }
      }
    }
  }
`;

export const RSYNC_IMPORTED_COLLECTION_BY_ID_MUTATION = gql`
  mutation ResyncImportedCollectionById($collectionId: Int!) {
    resyncImportedCollectionById(id: $collectionId) {
      id
      status
    }
  }
`;
