import 'css-chunk:src/app/containers/BudgetReporting/BudgetReportingContainer/components/budgetAccountForm/budgetAccountForm.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_iuy75_157",
  "justify-content-space-between": "_justify-content-space-between_iuy75_161",
  "tabular-nums": "_tabular-nums_iuy75_165",
  "alert": "_alert_iuy75_178",
  "budgetToLink": "_budgetToLink_iuy75_195",
  "budgetToLinkContainer": "_budgetToLinkContainer_iuy75_204",
  "budgetToLinkInfo": "_budgetToLinkInfo_iuy75_217",
  "parentBudgetDetailsPopover": "_parentBudgetDetailsPopover_iuy75_225",
  "parentBudgetPopoverTitle": "_parentBudgetPopoverTitle_iuy75_229",
  "parentBudgetAmountsContainer": "_parentBudgetAmountsContainer_iuy75_235",
  "parentBudgetAmount": "_parentBudgetAmount_iuy75_235",
  "availableBudgetIndicator": "_availableBudgetIndicator_iuy75_248",
  "parentBudgetAmountLabelContainer": "_parentBudgetAmountLabelContainer_iuy75_255",
  "warningIcon": "_warningIcon_iuy75_262",
  "budgetAccountContainer": "_budgetAccountContainer_iuy75_270",
  "formContainer": "_formContainer_iuy75_280",
  "form": "_form_iuy75_280",
  "rightSectionContainer": "_rightSectionContainer_iuy75_294",
  "loadSpinner": "_loadSpinner_iuy75_302",
  "show": "_show_iuy75_1"
};