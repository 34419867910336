import { CustomFieldType } from './CustomFieldType';

// names for default stage name
export const AllInProgressStageTitle = 'All In Progress';
export const InStageTitle = 'In Stage';

export enum StageTask {
  AllInProgress = 'all_in_progress', // not used in code

  // SendTermsWorkletSpecification
  SendTermsWorkletSpecification = 'SendTermsWorkletSpecification',
  SendTermsTask = 'send_terms_task',
  WaitingForAgreementTask = 'waiting_for_agreement_task',
  FixErrorSendingTermsTask = 'fix_error_sending_terms_task',
  ReviewEditedTermsTask = 'review_changes_task',

  // ReviewContentWorkletSpecification
  ReviewContentWorkletSpecification = 'ReviewContentWorkletSpecification',
  WaitingForContentTask = 'waiting_for_content_task',
  ReviewContentTask = 'review_content_task',
  WaitingForPostsTask = 'waiting_for_posts_task',

  // TrackPostsWorkletSpecification
  TrackPostsWorkletSpecification = 'TrackPostsWorkletSpecification',
  WaitingForPostsTaskTrackPosts = 'waiting_for_posts_task_track_posts',
  ReviewPostsTask = 'review_posts_task',

  // ConfirmAddressWorkletSpecification, SendProductWorkletSpecification
  ConfirmAddressWorkletSpecification = 'ConfirmAddressWorkletSpecification',
  ConfirmAddressesTask = 'confirm_addresses_task',
  WaitingForAddressConfirmationTask = 'waiting_for_address_confirmation_task',

  // SalesLinkWorkletSpecification, PromoCodesWorkletSpecification, SalesLinkPromoCodesWorkletSpecification
  // TODO: RL this is not done until STA data is in
  SalesLinkPromoCodesWorkletSpecification = 'SalesLinkPromoCodesWorkletSpecification',
  GenerateLinkTask = 'generate_link_task',
  SendLinkTask = 'send_link_task',
  GeneratePromoTask = 'generate_promo_task',
  SendPromoTask = 'send_promo_task',
  SendLinkAndPromoTask = 'send_link_and_promo_task',
  FixErrorPromoTask = 'fix_error_promo_task',

  // PaymentWorkletSpecification
  PaymentWorkletSpecification = 'PaymentWorkletSpecification',
  SendPaymentTask = 'send_payment_task',

  // CreatorProductSelectionWorkletSpecification
  CreatorProductSelectionWorkletSpecification = 'CreatorProductSelectionWorkletSpecification',
  SendOrderRequestTask = 'send_order_request_task',
  WaitingForOrderRequestTask = 'waiting_for_order_request_task',
  ReviewOrderRequestTask = 'review_order_request_task',
  ProcessingCreatorOrderTask = 'processing_creator_order_task',
  CreatorOrderInTransitTask = 'creator_order_in_transit_task',

  // CreatorProductSelectionWorkletSpecificationV2
  CreatorProductSelectionWorkletSpecificationV2 = 'SendBrandProductCatalogWorkletSpecification',
  SendOrderRequestTaskV2 = 'pfa_v2_send_order_request_task',
  WaitingForOrderRequestTaskV2 = 'pfa_v2_waiting_for_order_request_task',
  ReviewOrderRequestTaskV2 = 'pfa_v2_review_order_request_task',
  ProcessingCreatorOrderTaskV2 = 'pfa_v2_processing_creator_order_task',
  CreatorOrderInTransitTaskV2 = 'pfa_v2_creator_order_in_transit_task',

  // ProductFulfillment
  SendProductWorkletSpecification = 'SendProductWorkletSpecification',
  SubmitOrderTask = 'submit_order_task',
  ProcessingOrder = 'send_order_task',
  ProductInTransit = 'order_in_transit_task',

  // Custom Stages
  CustomStage = 'custom_stage',

  // SendContractWorkletSpecification
  SendContractWorkletSpecification = 'SendContractWorkletSpecification',
  SubmitContractTask = 'submit_contract_task',
  SendContractTask = 'send_contract_task',
  MemberSignContractTask = 'member_sign_contract_task',
  BrandSignContractTask = 'brand_sign_contract_task',

  // SalesLinkWorkletSpecification
  SalesLinkWorkletSpecification = 'SalesLinkWorkletSpecification',

  // PromoCodesWorkletSpecification
  PromoCodesWorkletSpecification = 'PromoCodesWorkletSpecification',

  // OfflineCreatorProductSelectionWorkletSpecification
  OfflineCreatorProductSelectionWorkletSpecification = 'OfflineCreatorProductSelectionWorkletSpecification',
  OfflineSendOrderRequestTask = 'offline_send_order_request_task',
  OfflineWaitingForOrderRequestTask = 'offline_waiting_for_order_request_task',
  OfflineReviewOrderRequestTask = 'offline_review_order_request_task',
  OfflineFulfillOrderTask = 'offline_fulfill_order_task',
  OfflineOrderInTransitTask = 'offline_order_in_transit_task',
}

export enum ColumnKey {
  // defaults
  Email = 'email',
  CTA = 'work_item__cta',
  DateAdded = 'work_item__date_added',
  DateCompleted = 'work_item__date_completed',
  DaysInStage = 'work_item__days_in_stage',
  LastMessage = 'lastMessage',
  Instagram = 'instagram',
  TikTok = 'tiktok',
  ID = 'id',
  WorkletName = 'work_item__worklet_name',
  WorkletTaskName = 'work_item__worklet_task_name',
  TaskName = 'work_item__task_name',
  Owners = 'owners',

  // member_sign_contract_task, brand_sign_contract_task
  PendingContracts = 'pending_contracts',

  // send_payment_task
  PaypalAddress = 'payPalAddress',
  LastPaymentSent = 'last_payment_sent',

  // waiting_for_agreement_task, fix_error_sending_terms_task
  Message = 'message',

  // review_terms_task
  Deliverables = 'deliverables',
  Price = 'price',

  // waiting_for_content_task, review_content_task, waiting_for_posts_task
  NextDeadline = 'next_deadline',
  OpenDeliverables = 'open_deliverables',
  CompletedDeliverables = 'completed_deliverables',
  Mentions = 'mentions',
  LastMention = 'last_mention',
  MentionsCount = 'mentions_count',

  // confirm_addresses_task, waiting_for_address_confirmation_task, submit_order_task
  Address = 'Address',
  Address1 = 'Address1',
  Address2 = 'Address2',
  City = 'City',
  State = 'State',
  PostalCode = 'PostalCode',

  // confirm_addresses_task, waiting_for_address_confirmation_task,
  // submit_order_task, review_order_request_task, offline_review_order_request_task,
  Country = 'Country',
  ShippingCountry = 'ShippingCountry',

  // review_order_request_task
  OrderStatus = 'status',

  // order_in_transit_task, creator_order_in_transit_task
  OrderNumber = 'pfa__orderNumber',
  OrderDate = 'pfa__orderDate',
  OrderFulfillmentStatus = 'pfa__fulfillmentStatus',
  OrderShipmentStatus = 'pfa__orderShipmentStatus',
  OrderTrackingNumber = 'pfa__orderTrackingNumber',

  // offline_review_order_request_task, offline_fulfill_order_task
  Quantity = 'quantity',
  Products = 'products',

  // review_order_request_task
  TotalOrderedProductsInPending = 'pfa__productShippedCountInPending',
  TotalOrderedMarketValueInPending = 'pfa__totalOrdersCostInPending',

  TotalShippedProducts = 'pfa__productShippedCount',
  TotalShippedMarketValue = 'pfa__totalOrdersCost',

  // review_order_request_task, offline_review_order_request_task
  ShippingAddress = 'address',

  // fix_error_promo_task, send_promo_task, send_link_and_promo_task
  PromoCode = 'promoCode',

  // fix_error_promo_task
  PromoCodeStatus = 'promoCodeStatus',

  // send_link_task, send_link_and_promo_task
  AffiliateLink = 'affiliateLink',

  InstagramInsights = 'instagramInsights',
  InstagramPaidPartnershipAds = 'instagramPaidPartnershipAds',
  InstagramAllowlisting = 'instagramAllowlisting',

  Notes = 'notes',
  MemberSince = 'createdDate',
  Tags = 'tags',
  Highlights = 'highlights',
  InvitedPrograms = 'invitedPrograms',
  SubmittedPrograms = 'submittedPrograms',
  RejectedPrograms = 'rejectedPrograms',
  Important = 'isImportant',
  Source = 'source',
  Name = 'name',
  // TotalSales this needs STA data

  // Column without stages
  ApplicantSource = 'applicantSource',
}

// projectColumn: column only exist in project, can be derived value
// dbColumn: column fetched from db
// memberFieldSchemaId: column fetched from memberFieldSchemaId
export enum FieldSource {
  ProjectColumn = 'projectColumn',
  DbColumn = 'dbColumn',
  MemberFieldSchemaId = 'memberFieldSchemaId',
  RelationshipColumn = 'relationshipColumn',
}

export interface TableColumn {
  title: string;
  type: CustomFieldType;
  source: FieldSource;
  field: ColumnKey,
  from?: 'pfa';
  fixed?: string;
  width?: number; // Width of the column on the frontend
  maxWidth?: number; // Max width of the column on the frontend
  minWidth?: number; // Min width of the column on the frontend
  isDefault?: boolean;
  dataIndex?: string;
  itemProp?: string;
  readonly?: boolean;
}

// , source: FieldSource.ProjectColumn | 'dbColumn' | 'memberFieldSchemaId' | 'relationshipColumn'
export const TableColumnMap: Record<ColumnKey, TableColumn> = {
  [ColumnKey.Name]: {
    title: 'Name',
    dataIndex: 'member',
    field: ColumnKey.Name,
    type: CustomFieldType.TEXT,
    source: FieldSource.DbColumn,
    width: 300,
    minWidth: 200,
    maxWidth: 400,
    isDefault: true,
    readonly: true,
    fixed: 'left',
  },
  [ColumnKey.Email]: {
    title: 'Email',
    dataIndex: ColumnKey.Email,
    field: ColumnKey.Email,
    type: CustomFieldType.TEXT,
    source: FieldSource.DbColumn,
    width: 250,
    minWidth: 120,
    maxWidth: 250,
    isDefault: true,
  },
  [ColumnKey.CTA]: {
    title: 'Action',
    dataIndex: ColumnKey.CTA,
    field: ColumnKey.CTA,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    readonly: true,
    width: 200,
    minWidth: 150,
    maxWidth: 300,
  },
  [ColumnKey.DateAdded]: {
    title: 'Date Added',
    dataIndex: ColumnKey.DateAdded,
    field: ColumnKey.DateAdded,
    type: CustomFieldType.DATE,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.DateCompleted]: {
    title: 'Date Completed',
    dataIndex: ColumnKey.DateCompleted,
    field: ColumnKey.DateCompleted,
    type: CustomFieldType.DATE,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.DaysInStage]: {
    title: 'Days In Stage',
    dataIndex: ColumnKey.DaysInStage,
    field: ColumnKey.DaysInStage,
    type: CustomFieldType.NUMBER,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.LastMessage]: {
    title: 'Last Message',
    dataIndex: 'member',
    field: ColumnKey.LastMessage,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.Instagram]: {
    title: 'Instagram',
    dataIndex: '_raw',
    field: ColumnKey.Instagram,
    type: CustomFieldType.TEXT,
    source: FieldSource.MemberFieldSchemaId,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.TikTok]: {
    title: 'TikTok',
    dataIndex: '_raw',
    field: ColumnKey.TikTok,
    type: CustomFieldType.TEXT,
    source: FieldSource.MemberFieldSchemaId,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.ID]: {
    title: 'ID',
    field: ColumnKey.ID,
    type: CustomFieldType.TEXT,
    source: FieldSource.DbColumn,
  },
  [ColumnKey.WorkletName]: {
    title: 'Stage',
    dataIndex: ColumnKey.WorkletName,
    field: ColumnKey.WorkletName,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    readonly: true,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.WorkletTaskName]: {
    title: 'Status',
    dataIndex: ColumnKey.WorkletTaskName,
    field: ColumnKey.WorkletTaskName,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    readonly: true,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.TaskName]: {
    title: 'Task Name',
    field: ColumnKey.TaskName,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.Owners]: {
    title: 'Owners',
    dataIndex: 'member',
    field: ColumnKey.Owners,
    type: CustomFieldType.OWNERS,
    source: FieldSource.DbColumn,
    width: 150,
    minWidth: 100,
    maxWidth: 250,
  },
  [ColumnKey.OrderFulfillmentStatus]: {
    title: 'Fulfillment Status',
    dataIndex: 'orders',
    field: ColumnKey.OrderFulfillmentStatus,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 200,
    maxWidth: 250,
  },
  [ColumnKey.OrderShipmentStatus]: {
    title: 'Shipment Status',
    dataIndex: 'orders',
    field: ColumnKey.OrderShipmentStatus,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 200,
    maxWidth: 250,
  },
  [ColumnKey.OrderTrackingNumber]: {
    title: 'Tracking Number',
    dataIndex: 'orders',
    field: ColumnKey.OrderTrackingNumber,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 200,
    maxWidth: 250,
  },
  [ColumnKey.Quantity]: {
    title: 'Quantity',
    dataIndex: ColumnKey.Quantity,
    field: ColumnKey.Quantity,
    type: CustomFieldType.NUMBER,
    source: FieldSource.ProjectColumn,
    width: 100,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.Deliverables]: {
    title: 'Deliverables',
    dataIndex: ColumnKey.Deliverables,
    field: ColumnKey.Deliverables,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 250,
    maxWidth: 300,
  },
  [ColumnKey.Price]: {
    title: 'Price',
    dataIndex: ColumnKey.Price,
    field: ColumnKey.Price,
    type: CustomFieldType.NUMBER,
    source: FieldSource.ProjectColumn,
    width: 100,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.NextDeadline]: {
    title: 'Next Deadline',
    dataIndex: ColumnKey.NextDeadline,
    field: ColumnKey.NextDeadline,
    type: CustomFieldType.DATE,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.OpenDeliverables]: {
    title: 'Open Deliverables',
    dataIndex: ColumnKey.OpenDeliverables,
    field: ColumnKey.OpenDeliverables,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.CompletedDeliverables]: {
    title: 'Completed Deliverables',
    dataIndex: ColumnKey.CompletedDeliverables,
    field: ColumnKey.CompletedDeliverables,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.Address]: {
    title: 'Address',
    dataIndex: 'member',
    field: ColumnKey.Address,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.Address1]: {
    title: 'Address1',
    dataIndex: '_raw',
    field: ColumnKey.Address1,
    type: CustomFieldType.TEXT,
    source: FieldSource.MemberFieldSchemaId,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.Address2]: {
    title: 'Address2',
    dataIndex: '_raw',
    field: ColumnKey.Address2,
    type: CustomFieldType.TEXT,
    source: FieldSource.MemberFieldSchemaId,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.City]: {
    title: 'City',
    field: ColumnKey.City,
    type: CustomFieldType.TEXT,
    source: FieldSource.MemberFieldSchemaId,
    width: 100,
    maxWidth: 120,
  },
  [ColumnKey.State]: {
    title: 'State',
    dataIndex: '_raw',
    field: ColumnKey.State,
    type: CustomFieldType.TEXT,
    source: FieldSource.MemberFieldSchemaId,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.PostalCode]: {
    title: 'Postal Code',
    dataIndex: '_raw',
    field: ColumnKey.PostalCode,
    type: CustomFieldType.TEXT,
    source: FieldSource.MemberFieldSchemaId,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.Country]: {
    title: 'Country',
    dataIndex: '_raw',
    field: ColumnKey.Country,
    type: CustomFieldType.TEXT,
    source: FieldSource.MemberFieldSchemaId,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.ShippingCountry]: {
    title: 'Shipping Country',
    dataIndex: 'country',
    field: ColumnKey.ShippingCountry,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.OrderStatus]: {
    title: 'Order Status',
    field: ColumnKey.OrderStatus,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.TotalShippedProducts]: {
    title: 'Total Shipped Products',
    dataIndex: 'orders',
    field: ColumnKey.TotalShippedProducts,
    type: CustomFieldType.NUMBER,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 200,
    maxWidth: 250,
  },
  [ColumnKey.TotalShippedMarketValue]: {
    title: 'Total Shipped Market Value',
    dataIndex: 'orders',
    field: ColumnKey.TotalShippedMarketValue,
    type: CustomFieldType.NUMBER,
    source: FieldSource.ProjectColumn,
    width: 250,
    minWidth: 200,
    maxWidth: 300,
  },
  [ColumnKey.TotalOrderedProductsInPending]: {
    title: 'Total Ordered Products',
    dataIndex: 'orders',
    field: ColumnKey.TotalOrderedProductsInPending,
    type: CustomFieldType.NUMBER,
    source: FieldSource.ProjectColumn,
    width: 250,
    minWidth: 200,
    maxWidth: 300,
  },
  [ColumnKey.TotalOrderedMarketValueInPending]: {
    title: 'Total Ordered Market Value',
    dataIndex: 'orders',
    field: ColumnKey.TotalOrderedMarketValueInPending,
    type: CustomFieldType.NUMBER,
    source: FieldSource.ProjectColumn,
    width: 300,
    minWidth: 250,
    maxWidth: 350,
  },
  [ColumnKey.OrderNumber]: {
    title: 'Order Number',
    dataIndex: 'orders',
    field: ColumnKey.OrderNumber,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 200,
    maxWidth: 250,
  },
  [ColumnKey.OrderDate]: {
    title: 'Order Date',
    dataIndex: 'orders',
    field: ColumnKey.OrderDate,
    type: CustomFieldType.DATE,
    source: FieldSource.ProjectColumn,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.Products]: {
    title: 'Products',
    dataIndex: ColumnKey.Products,
    field: ColumnKey.Products,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.PromoCode]: {
    title: 'Promo Code',
    dataIndex: ColumnKey.PromoCode,
    field: ColumnKey.PromoCode,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.PromoCodeStatus]: {
    title: 'Promo Code Status',
    dataIndex: ColumnKey.PromoCodeStatus,
    field: ColumnKey.PromoCodeStatus,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 130,
    minWidth: 120,
    maxWidth: 150,
  },
  [ColumnKey.AffiliateLink]: {
    title: 'Affiliate Link',
    dataIndex: ColumnKey.AffiliateLink,
    field: ColumnKey.AffiliateLink,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.ShippingAddress]: {
    title: 'Shipping Address',
    dataIndex: ColumnKey.ShippingAddress,
    field: ColumnKey.ShippingAddress,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 250,
    minWidth: 200,
    maxWidth: 400,
  },
  [ColumnKey.PendingContracts]: {
    title: 'Pending Contracts',
    field: ColumnKey.PendingContracts,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.PaypalAddress]: {
    title: 'Paypal Address',
    dataIndex: ColumnKey.PaypalAddress,
    field: ColumnKey.PaypalAddress,
    type: CustomFieldType.TEXT,
    source: FieldSource.MemberFieldSchemaId,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.LastPaymentSent]: {
    title: 'Last Payment Sent',
    dataIndex: ColumnKey.LastPaymentSent,
    field: ColumnKey.LastPaymentSent,
    type: CustomFieldType.DATE,
    source: FieldSource.ProjectColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.Message]: {
    // in some stages, this the error message, might want to change this column name
    title: 'Error',
    dataIndex: ColumnKey.Message,
    field: ColumnKey.Message,
    type: CustomFieldType.TEXT,
    source: FieldSource.ProjectColumn,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.InstagramInsights]: {
    title: 'Instagram Insights',
    field: ColumnKey.InstagramInsights,
    type: CustomFieldType.BOOLEAN,
    source: FieldSource.MemberFieldSchemaId,
    width: 150,
    minWidth: 100,
    maxWidth: 200,
  },
  [ColumnKey.InstagramPaidPartnershipAds]: {
    title: 'Instagram Paid Partnership Ads',
    dataIndex: '_row',
    field: ColumnKey.InstagramPaidPartnershipAds,
    type: CustomFieldType.BOOLEAN,
    source: FieldSource.MemberFieldSchemaId,
    width: 250,
    minWidth: 200,
    maxWidth: 350,
  },
  [ColumnKey.InstagramAllowlisting]: {
    title: 'Instagram Allowlisting',
    dataIndex: '_raw',
    field: ColumnKey.InstagramAllowlisting,
    type: CustomFieldType.BOOLEAN,
    source: FieldSource.MemberFieldSchemaId,
    width: 200,
    minWidth: 150,
    maxWidth: 300,
  },
  [ColumnKey.Mentions]: {
    title: 'Mentions',
    dataIndex: '_raw',
    field: ColumnKey.Mentions,
    type: CustomFieldType.IMAGES,
    source: FieldSource.MemberFieldSchemaId,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.LastMention]: {
    title: 'Last Mention Date',
    dataIndex: '_raw',
    field: ColumnKey.LastMention,
    type: CustomFieldType.DATE,
    source: FieldSource.MemberFieldSchemaId,
    width: 250,
    minWidth: 200,
    maxWidth: 350,
  },
  [ColumnKey.MentionsCount]: {
    title: 'Mentions Count',
    dataIndex: '_raw',
    field: ColumnKey.MentionsCount,
    type: CustomFieldType.NUMBER,
    source: FieldSource.MemberFieldSchemaId,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.Notes]: {
    title: 'Notes',
    field: ColumnKey.Notes,
    type: CustomFieldType.TEXT,
    source: FieldSource.DbColumn,
    isDefault: true,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.MemberSince]: {
    title: 'Member Since',
    dataIndex: 'member',
    field: ColumnKey.MemberSince,
    type: CustomFieldType.DATE,
    source: FieldSource.DbColumn,
    isDefault: true,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.Tags]: {
    title: 'Tags',
    field: ColumnKey.Tags,
    type: CustomFieldType.TAG,
    itemProp: 'name',
    source: FieldSource.RelationshipColumn,
    width: 300,
    minWidth: 250,
    maxWidth: 400,
  },
  [ColumnKey.Highlights]: {
    title: 'Highlights',
    field: ColumnKey.Highlights,
    type: CustomFieldType.TEXT,
    source: FieldSource.RelationshipColumn,
    width: 150,
    minWidth: 100,
    maxWidth: 250,
  },
  [ColumnKey.InvitedPrograms]: {
    title: 'Invited',
    field: ColumnKey.InvitedPrograms,
    type: CustomFieldType.PROGRAM,
    source: FieldSource.RelationshipColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.SubmittedPrograms]: {
    title: 'Submitted',
    dataIndex: ColumnKey.SubmittedPrograms,
    field: ColumnKey.SubmittedPrograms,
    type: CustomFieldType.PROGRAM,
    source: FieldSource.RelationshipColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.RejectedPrograms]: {
    title: 'Rejected',
    dataIndex: ColumnKey.RejectedPrograms,
    field: ColumnKey.RejectedPrograms,
    type: CustomFieldType.PROGRAM,
    source: FieldSource.RelationshipColumn,
    width: 200,
  },
  [ColumnKey.Important]: {
    title: 'Important',
    dataIndex: 'member',
    field: ColumnKey.Important,
    type: CustomFieldType.BOOLEAN,
    source: FieldSource.RelationshipColumn,
    isDefault: true,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.Source]: {
    title: 'Source',
    field: ColumnKey.Source,
    type: CustomFieldType.TEXT,
    source: FieldSource.DbColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
  [ColumnKey.ApplicantSource]: {
    title: 'Applicant Source',
    field: ColumnKey.ApplicantSource,
    type: CustomFieldType.DYNAMIC_SELECT,
    dataIndex: 'programMemberships',
    source: FieldSource.RelationshipColumn,
    width: 200,
    minWidth: 150,
    maxWidth: 250,
  },
};

export const DEFAULT_PROJECT_START_COLUMNS_WITH_CTA = [
  TableColumnMap[ColumnKey.Name], // should be name, db column
  TableColumnMap[ColumnKey.Email], // db column
  TableColumnMap[ColumnKey.LastMessage], // db column
  TableColumnMap[ColumnKey.CTA], // db column
  TableColumnMap[ColumnKey.WorkletName], // db column
  TableColumnMap[ColumnKey.WorkletTaskName], // db column
];

export const DEFAULT_PROJECT_START_COLUMNS = [
  TableColumnMap[ColumnKey.Name], // should be name, db column
  TableColumnMap[ColumnKey.Email], // db column
  TableColumnMap[ColumnKey.LastMessage], // db column
  TableColumnMap[ColumnKey.WorkletName], // db column
  TableColumnMap[ColumnKey.WorkletTaskName], // db column
];

export const DEFAULT_PROJECT_END_COLUMNS = [
  TableColumnMap[ColumnKey.Instagram], // mfs column
  TableColumnMap[ColumnKey.TikTok], // mfs column
  TableColumnMap[ColumnKey.Owners], // db column
  TableColumnMap[ColumnKey.DaysInStage], // project/db column
  TableColumnMap[ColumnKey.DateAdded], // project/db column
  TableColumnMap[ColumnKey.DateCompleted], // project/db column
];

const ContentColumns = [
  TableColumnMap[ColumnKey.NextDeadline],
  TableColumnMap[ColumnKey.OpenDeliverables],
  TableColumnMap[ColumnKey.CompletedDeliverables],
];

const TrackPostColumns = [
  TableColumnMap[ColumnKey.Mentions],
  TableColumnMap[ColumnKey.LastMention],
  TableColumnMap[ColumnKey.MentionsCount],
];

const ConfirmAddressesColumns = [
  TableColumnMap[ColumnKey.Address],
];

const ProductInTransitTaskColumns = [
  TableColumnMap[ColumnKey.OrderNumber],
  TableColumnMap[ColumnKey.OrderDate],
  TableColumnMap[ColumnKey.OrderFulfillmentStatus],
  TableColumnMap[ColumnKey.OrderShipmentStatus],
  TableColumnMap[ColumnKey.OrderTrackingNumber],
  TableColumnMap[ColumnKey.TotalShippedProducts],
  TableColumnMap[ColumnKey.TotalShippedMarketValue],
];

export const DefaultColumnsForStage = {
  // Briefs
  [StageTask.SendTermsWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.SendTermsTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...[
      TableColumnMap[ColumnKey.InstagramInsights],
      TableColumnMap[ColumnKey.InstagramPaidPartnershipAds],
      TableColumnMap[ColumnKey.InstagramAllowlisting],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.WaitingForAgreementTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.FixErrorSendingTermsTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...[TableColumnMap[ColumnKey.Message]],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.ReviewEditedTermsTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Content
  [StageTask.ReviewContentWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.WaitingForContentTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...ContentColumns,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.ReviewContentTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...ContentColumns,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.WaitingForPostsTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...ContentColumns,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Review Post
  [StageTask.TrackPostsWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.WaitingForPostsTaskTrackPosts]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...TrackPostColumns,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.ReviewPostsTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...TrackPostColumns,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Confirm address
  [StageTask.ConfirmAddressWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.ConfirmAddressesTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...ConfirmAddressesColumns,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.WaitingForAddressConfirmationTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...ConfirmAddressesColumns,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Sales link + promo
  [StageTask.SalesLinkPromoCodesWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.GenerateLinkTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[TableColumnMap[ColumnKey.AffiliateLink]],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.SendLinkTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[TableColumnMap[ColumnKey.AffiliateLink]],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.GeneratePromoTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[TableColumnMap[ColumnKey.PromoCode]],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.SendPromoTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[TableColumnMap[ColumnKey.PromoCode]],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.SendLinkAndPromoTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[
      TableColumnMap[ColumnKey.AffiliateLink],
      TableColumnMap[ColumnKey.PromoCode],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.FixErrorPromoTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[
      TableColumnMap[ColumnKey.AffiliateLink],
      TableColumnMap[ColumnKey.PromoCode],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Send Payment
  [StageTask.PaymentWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.SendPaymentTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...[
      TableColumnMap[ColumnKey.PaypalAddress],
      TableColumnMap[ColumnKey.LastPaymentSent],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Creator Product Selection
  [StageTask.CreatorProductSelectionWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.SendOrderRequestTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.WaitingForOrderRequestTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.ReviewOrderRequestTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.ProcessingCreatorOrderTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[
      TableColumnMap[ColumnKey.TotalShippedProducts],
      TableColumnMap[ColumnKey.TotalShippedMarketValue],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.CreatorOrderInTransitTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...ProductInTransitTaskColumns,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // PFA V2
  [StageTask.CreatorProductSelectionWorkletSpecificationV2]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.SendOrderRequestTaskV2]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.WaitingForOrderRequestTaskV2]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.ReviewOrderRequestTaskV2]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.ProcessingCreatorOrderTaskV2]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[
      TableColumnMap[ColumnKey.TotalShippedProducts],
      TableColumnMap[ColumnKey.TotalShippedMarketValue],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.CreatorOrderInTransitTaskV2]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...ProductInTransitTaskColumns,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Product Fulfillment
  [StageTask.SendProductWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.SubmitOrderTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...[
      TableColumnMap[ColumnKey.Address],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.ProcessingOrder]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[
      TableColumnMap[ColumnKey.TotalShippedProducts],
      TableColumnMap[ColumnKey.TotalShippedMarketValue],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.ProductInTransit]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...ProductInTransitTaskColumns,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Custom Stages
  [StageTask.CustomStage]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Send Contract
  [StageTask.SendContractWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.SubmitContractTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.SendContractTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.MemberSignContractTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[TableColumnMap[ColumnKey.PendingContracts]],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.BrandSignContractTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...[TableColumnMap[ColumnKey.PendingContracts]],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Sales Link
  [StageTask.SalesLinkWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Promo Codes
  [StageTask.PromoCodesWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],

  // Offline Creator Product Selection
  [StageTask.OfflineCreatorProductSelectionWorkletSpecification]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.OfflineSendOrderRequestTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.OfflineWaitingForOrderRequestTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS,
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.OfflineReviewOrderRequestTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...[
      TableColumnMap[ColumnKey.ShippingAddress],
      TableColumnMap[ColumnKey.ShippingCountry],
      TableColumnMap[ColumnKey.Quantity],
      TableColumnMap[ColumnKey.Products],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.OfflineFulfillOrderTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...[
      TableColumnMap[ColumnKey.Quantity],
      TableColumnMap[ColumnKey.Products],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
  [StageTask.OfflineOrderInTransitTask]: [
    ...DEFAULT_PROJECT_START_COLUMNS_WITH_CTA,
    ...[
      TableColumnMap[ColumnKey.OrderShipmentStatus],
      TableColumnMap[ColumnKey.OrderTrackingNumber],
    ],
    ...DEFAULT_PROJECT_END_COLUMNS,
  ],
};
