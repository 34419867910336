/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ywsvl_157 {
  display: flex;
}

._justify-content-space-between_ywsvl_161 {
  justify-content: space-between;
}

._tabular-nums_ywsvl_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ywsvl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_ywsvl_157 {
  display: flex;
}

._justify-content-space-between_ywsvl_161 {
  justify-content: space-between;
}

._tabular-nums_ywsvl_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_ywsvl_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._button_ywsvl_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._button_ywsvl_355 {
  min-width: 2.25rem;
}
._button_ywsvl_355 > svg:first-of-type:not(:only-child) {
  margin-right: 0.25rem;
}

._button_ywsvl_355:focus, ._button_ywsvl_355:hover {
  border-color: var(--primary);
  color: var(--primary);
}

._TableHeader_ywsvl_377 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -0.5rem;
}
._TableHeader_ywsvl_377 ._count_ywsvl_384 {
  margin: 0.5rem 1.5rem 1rem 0;
}
._TableHeader_ywsvl_377 ._buttonRow_ywsvl_387 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-right: auto;
}
._TableHeader_ywsvl_377 ._right_ywsvl_394 {
  flex-wrap: wrap;
  align-self: flex-end;
  margin-left: 0.5rem;
}

._button_ywsvl_355,
._buttonTooltip_ywsvl_401,
._primaryCTA_ywsvl_402 {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
._button_ywsvl_355 ._icon_ywsvl_406:not(:only-child),
._buttonTooltip_ywsvl_401 ._icon_ywsvl_406:not(:only-child),
._primaryCTA_ywsvl_402 ._icon_ywsvl_406:not(:only-child) {
  margin-right: 0.25rem;
}
._button_ywsvl_355._isPrimaryTheme_ywsvl_411:hover, ._button_ywsvl_355._isPrimaryTheme_ywsvl_411:focus,
._buttonTooltip_ywsvl_401._isPrimaryTheme_ywsvl_411:hover,
._buttonTooltip_ywsvl_401._isPrimaryTheme_ywsvl_411:focus,
._primaryCTA_ywsvl_402._isPrimaryTheme_ywsvl_411:hover,
._primaryCTA_ywsvl_402._isPrimaryTheme_ywsvl_411:focus {
  color: #fff !important;
}

._button_ywsvl_355._disabled_ywsvl_419 {
  background-color: #fdfdfd !important;
  cursor: no-drop;
  pointer-events: none;
}

._actionButton_ywsvl_425 {
  font-weight: 600;
}
._actionButton_ywsvl_425._disabled_ywsvl_419 {
  font-weight: 400;
}

._approveIcon_ywsvl_432 {
  margin-top: 0.375rem;
  margin-right: 0 !important;
  fill: #52c41a;
}

._rejectIcon_ywsvl_438 {
  margin-top: 0.375rem;
  margin-right: 0 !important;
  fill: #f5222d;
}

._inline_ywsvl_444 {
  transition: all 0.15s cubic-bezier(0.23, 1, 0.32, 1);
}
._inline_ywsvl_444:hover {
  transform: scale(1.1);
}
._inline_ywsvl_444._disabled_ywsvl_419 {
  opacity: 0.4;
}
._inline_ywsvl_444 ._approveIcon_ywsvl_432,
._inline_ywsvl_444 ._rejectIcon_ywsvl_438 {
  margin-top: 0.625rem;
}