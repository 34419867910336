/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_e4t3o_157 {
  display: flex;
}

._justify-content-space-between_e4t3o_161 {
  justify-content: space-between;
}

._tabular-nums_e4t3o_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_e4t3o_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._card_e4t3o_178 {
  background-color: #FFFFFF;
  border-radius: 0.5rem;
  height: fit-content;
  width: 100%;
  max-width: 37.5rem;
  margin: 0 auto;
  padding: 1rem;
}

._content_e4t3o_188 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

._titleSection_e4t3o_194 {
  display: flex;
  flex-direction: column;
}

._title_e4t3o_194 {
  margin-bottom: 0 !important;
}

._status_e4t3o_203 {
  width: 3.375rem;
}

._details_e4t3o_207 {
  display: flex;
  justify-content: start;
  gap: 3.75rem;
  flex-wrap: wrap;
}

._detail_e4t3o_207 {
  display: flex;
  flex-direction: column;
}

._detailValue_e4t3o_219 {
  margin-top: 0 !important;
}

._detailLabel_e4t3o_223 {
  font-weight: 400;
  color: #8c8c8c;
  font-size: 0.875rem;
}

._iconContainer_e4t3o_229 {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #5DB884;
}

._icon_e4t3o_229 {
  color: #FFFFFF;
  height: 1.25rem;
  width: 1.25rem;
}