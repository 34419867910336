import 'css-chunk:src/app/containers/Projects/ProjectsPage/WorkItemsList/WorkItemsList.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "spacing-xxxl": "80px",
  "spacing-xxl": "48px",
  "spacing-xl": "32px",
  "spacing-lg": "24px",
  "spacing-md": "16px",
  "spacing-sm": "12px",
  "spacing-xs": "8px",
  "spacing-xxs": "4px",
  "screen-xs": "480px",
  "screen-sm": "576px",
  "screen-md": "768px",
  "screen-lg": "992px",
  "screen-xl": "1200px",
  "screen-xxl": "1600px",
  "flex": "_flex_473w9_157",
  "justify-content-space-between": "_justify-content-space-between_473w9_161",
  "tabular-nums": "_tabular-nums_473w9_165",
  "ListDrawer": "_ListDrawer_473w9_355",
  "title": "_title_473w9_355",
  "controls": "_controls_473w9_355",
  "settingsIcon": "_settingsIcon_473w9_355",
  "divider": "_divider_473w9_380",
  "loading": "_loading_473w9_383",
  "drawer": "_drawer_473w9_386",
  "tableWrapper": "_tableWrapper_473w9_408",
  "loadSpinner": "_loadSpinner_473w9_412",
  "empty": "_empty_473w9_415",
  "filterList": "_filterList_473w9_421",
  "membersTable": "_membersTable_473w9_426",
  "tableHeader": "_tableHeader_473w9_441",
  "bodyRow": "_bodyRow_473w9_451",
  "checkboxCell": "_checkboxCell_473w9_454",
  "nameHeaderCell": "_nameHeaderCell_473w9_466",
  "singleCTAButton": "_singleCTAButton_473w9_469",
  "headerCell": "_headerCell_473w9_474",
  "newWorkItem": "_newWorkItem_473w9_522",
  "customTaskNotice": "_customTaskNotice_473w9_526",
  "listHeader": "_listHeader_473w9_534",
  "workItemBoxContainer": "_workItemBoxContainer_473w9_538",
  "content": "_content_473w9_561",
  "lastMessageColumn": "_lastMessageColumn_473w9_571",
  "spacer": "_spacer_473w9_586",
  "memberDetailsDrawer": "_memberDetailsDrawer_473w9_590",
  "searchIcon": "_searchIcon_473w9_602",
  "show": "_show_473w9_1"
};