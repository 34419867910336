/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1jfn3_157 {
  display: flex;
}

._justify-content-space-between_1jfn3_161 {
  justify-content: space-between;
}

._tabular-nums_1jfn3_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1jfn3_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._TopNavbarDropdown_1jfn3_209 {
  z-index: 10001;
  animation-duration: 0.1s;
}
._TopNavbarDropdown_1jfn3_209 .ant-dropdown-menu {
  transform: translateY(-0.5rem);
}

._TopNavbarSubmenu_1jfn3_217 {
  padding: 0 !important;
  overflow: hidden;
}
._TopNavbarSubmenu_1jfn3_217 ._userMenuContent_1jfn3_221 {
  padding: 0.25rem 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
}
._TopNavbarSubmenu_1jfn3_217 ._userMenuContent_1jfn3_221 ._userEmail_1jfn3_227 {
  color: #8f8d91;
}
._TopNavbarSubmenu_1jfn3_217 ._userMenuContent_1jfn3_221 ._switchAccounts_1jfn3_230 {
  margin-top: 0.25rem;
  pointer-events: auto;
  cursor: pointer;
  color: #167cf4;
}

._TopNavbarSubmenuLong_1jfn3_237 {
  min-height: max-content;
  max-height: 320px;
  overflow-y: auto;
}

._TopNavbarSubmenuImage_1jfn3_243 {
  margin-right: 8px;
  border-radius: 4px;
}

._TopNavbarSubmenuItemCreate_1jfn3_248 {
  height: 48px;
  border-top: 1px solid #dadcde;
  color: #167cf4;
}

._TopNavbarSubmenuText_1jfn3_254 {
  display: inline-flex;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._UserClientMenu_1jfn3_262 {
  max-height: 400px;
  overflow-y: auto;
}
._UserClientMenu_1jfn3_262 ._client_1jfn3_266 {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 0.375rem;
  will-change: background-color;
  transition: background-color 0.1s ease-out;
}
._UserClientMenu_1jfn3_262 ._client_1jfn3_266._active_1jfn3_273 {
  pointer-events: none;
  color: #167cf4;
}
._UserClientMenu_1jfn3_262 ._client_1jfn3_266:hover {
  background-color: #eff5f9;
}

._TopNavbarSubmenuItem_1jfn3_248 {
  padding: 0 1rem !important;
  font-size: 0.875rem;
  line-height: 3.25rem;
}
._TopNavbarSubmenuItem_1jfn3_248:hover {
  background-color: #f4fcff;
  color: #1890ff;
}
._TopNavbarSubmenuItem_1jfn3_248._selected_1jfn3_290 {
  background-color: #e6f7ff;
  color: #002766;
}