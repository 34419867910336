import * as React from 'react';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';

import { ProductFulfillmentShipmentPage } from './ProductFulfillmentShipmentPage';

interface IProps {}

const application = {
  displayName: 'Product Fulfillment',
  iconUrl: 'https://storage.googleapis.com/aspireiq-widgets/assets/product-shipment.svg',
};

export const ProductFulfillmentAppDashboard: React.FunctionComponent<IProps> = () => {
  const navSettings = [
    {
      route: 'shipments',
      displayName: 'My Shipments',
      component: ProductFulfillmentShipmentPage,
    },
  ];

  return (
    <AppDashboardNav
      application={application}
      navLinks={navSettings}
      hideTabs
      defaultRoute="shipments"
    />
  );
};
