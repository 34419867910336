import * as React from 'react';
import { isEmpty, find } from 'lodash';

import { useQuery } from '@apollo/client';
import {
  useMemberFieldSchemasQuery,
  useMemberSearchCountQuery,
} from '@frontend/app/hooks';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { SEARCH_MEMBERS_QUERY } from '@frontend/app/queries';
import {
  SearchMembersQuery,
  SearchMembersQueryVariables,
} from '@frontend/app/queries/types/SearchMembersQuery';

const { useMemo } = React;
const INSTAGRAM_FIELD_NAME = 'Instagram';
const TIKTOK_FIELD_NAME = 'TikTok';
const YOUTUBE_FIELD_NAME = 'YouTube';
const PINTEREST_FIELD_NAME = 'Pinterest';

export function useSearchMember(value: string, isContact?: boolean) {
  const {
    data: {
      schemas = null,
    } = {},
  } = useMemberFieldSchemasQuery();
  const instagramFieldId = useMemo(() => {
    const instagramSchema = find(schemas, (s) => s.name === INSTAGRAM_FIELD_NAME);

    return instagramSchema?.id;
  }, [schemas]);

  const tiktokFieldId = useMemo(() => {
    const tiktokSchema = find(schemas, (s) => s.name === TIKTOK_FIELD_NAME);

    return tiktokSchema?.id;
  }, [schemas]);

  const youtubeFieldId = useMemo(() => {
    const youtubeSchema = find(schemas, (s) => s.name === YOUTUBE_FIELD_NAME);

    return youtubeSchema?.id;
  }, [schemas]);

  const pinterestFieldId = useMemo(() => {
    const pinterestSchema = find(schemas, (s) => s.name === PINTEREST_FIELD_NAME);

    return pinterestSchema?.id;
  }, [schemas]);

  const query: IMemberSearchQuery = {
    isContact,
    fields: {
      or: [
        {
          contains: value,
          column: 'name',
        },
        {
          contains: value,
          column: 'email',
        },
        {
          contains: value,
          memberFieldSchemaId: instagramFieldId,
        },
        {
          contains: value,
          memberFieldSchemaId: tiktokFieldId,
        },
        {
          contains: value,
          memberFieldSchemaId: youtubeFieldId,
        },
        {
          contains: value,
          memberFieldSchemaId: pinterestFieldId,
        },
      ],
    },
  };

  const {
    loading: loadingMembers,
    error,
    data,
    refetch,
  } = useQuery<SearchMembersQuery, SearchMembersQueryVariables>(SEARCH_MEMBERS_QUERY, {
    variables: {
      query,
      take: 100,
      skip: 0,
    },
    skip: isEmpty(value),
    fetchPolicy: 'no-cache',
  });

  const {
    loading: loadingCount,
    data: {
      count,
    } = {},
  } = useMemberSearchCountQuery({
    variables: {
      query,
    },
    skip: isEmpty(value),
    fetchPolicy: 'no-cache',
  });

  const loading = loadingCount || loadingMembers;

  return {
    loading,
    error,
    count,
    data,
    refetch,
    instagramFieldId,
    tiktokFieldId,
    youtubeFieldId,
    pinterestFieldId,
  };
}
