import * as React from 'react';
import {
  Col, Row, Typography, Button, Space, Tabs,
  Tooltip,
} from '@revfluence/fresh';
import { ClipboardListCheckIcon, GearIcon } from '@revfluence/fresh-icons/regular/esm';
import { useHistory } from 'react-router-dom';
import { BagShoppingIcon } from '@revfluence/fresh-icons/regular/esm';
import { useResourceContext } from '@frontend/app/context';
import { Collections } from '../Collections/Collections';
import styles from './Products.scss';
import { useProductsContext } from '../ProductsContext';
import { ShopifyImportModal } from '../ShopifyImportModal/ShopifyImportModal';
// import { AllProductsWrapper } from '../AllProducts/AllProducts';
import { ImportSettingsDrawer } from '../ImportSettings/ImportSettingsDrawer';
import { pfaV2Routes } from '../../routes';
import { ShopifyImportModalProvider } from '../ShopifyImportModal/ShopifyImportModalContext';

const { Title, Text } = Typography;

export const Products = () => {
  const { setIsOpen, setIsImportSettingsDrawerOpen } = useProductsContext();
  const history = useHistory();
  const { isPrimaryShopifyConnected } = useResourceContext();
  return (
    <div className={styles.ProductFulfillmentProducts}>
      <Row className={styles.header}>
        <Col>
          <Title level={3}>Products</Title>
          <Text className={styles.headerSubTitle}>
            View, add, update, and organize your collection, products and variants imported into Aspire.
          </Text>
        </Col>
        <Space className={styles.headerCTA}>
          <Tooltip title="Import Logs">
            <Button icon={<ClipboardListCheckIcon />} onClick={() => history.push(pfaV2Routes.settings.importLogs)} />
          </Tooltip>
          <Tooltip title="Settings">
            <Button icon={<GearIcon />} onClick={() => setIsImportSettingsDrawerOpen(true)} />
          </Tooltip>
          <Tooltip title={isPrimaryShopifyConnected ? '' : 'Please connect a Shopify store to start importing products'}>
            <Button onClick={() => setIsOpen(true)} type="primary" icon={<BagShoppingIcon />} disabled={!isPrimaryShopifyConnected}>Import Products</Button>
          </Tooltip>
        </Space>
      </Row>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: 'Collections',
            key: '1',
            children: <Collections />,
          },
          // {
          //   label: 'All Products',
          //   key: '2',
          //   children: <AllProductsWrapper />,
          // },
        ]}
      />
      <ShopifyImportModalProvider>
        <ShopifyImportModal />
      </ShopifyImportModalProvider>
      <ImportSettingsDrawer />
    </div>
  );
};
