import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Space,
} from '@revfluence/fresh';
import cn from 'classnames';
import * as React from 'react';
import {
  filter,
  find,
  findIndex,
  isFunction,
  map,
  without,
} from 'lodash';

import { EventName } from '@common';
import { ISource } from '@frontend/app/components';
import { useEventContext } from '@frontend/app/context/EventContext';
import { IField } from '@frontend/app/containers/Members/types/MemberFieldsWithSources';

import { AddFilterPopover } from './AddFilterPopover';
import { FilterFormPopover } from './FilterFormPopover';
import { FilterItem } from './FilterItem';
import { IFilter } from './model';
import { QuickFilters } from './QuickFilters';

import styles from './FilterList.scss';

const { useMemo } = React;

const QuickFiltersOptions = [
  {
    label: 'Invited',
    filter: 'invitedMembers',
  },
  {
    label: "Aspire's Pick",
    filter: 'suggestedMembers',
  },
  {
    label: 'Applied Date > 14 days',
    filter: 'appliedRecently',
  },
];

interface IProps {
  additionalSources?: ISource[];
  className?: string;
  fields: IField[];
  filters: IFilter[];
  isFilterDisabled?(filter: IFilter): boolean;
  onChangeFilters?(filters: IFilter[]): void;
  displayQuickFilters?: boolean;
  quickFiltersCounts?: Record<string, number>;
}

export const FilterList: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    additionalSources,
    className,
    fields,
    filters,
    isFilterDisabled,
    onChangeFilters,
    displayQuickFilters,
    quickFiltersCounts,
  } = props;

  const handleAddFilter = (newFilter: IFilter) => {
    const nextFilters = [...filters, newFilter];
    if (onChangeFilters) {
      onChangeFilters(nextFilters);
    }
  };

  const handleChangeFilter = (_atIndex: number, updatedFilter: IFilter) => {
    const nextFilters = [...filters];
    const idx = findIndex(nextFilters, ['id', updatedFilter.id]);
    nextFilters[idx] = updatedFilter;

    if (isFunction(onChangeFilters)) {
      onChangeFilters(nextFilters);
    }
  };

  const handleSelectQuickFilter = (newQuickFilter: string) => {
    const nextFilters = [...filters];
    const currentIndex = findIndex(nextFilters, ['id', 'quickFilter']);

    const filter = {
      id: 'quickFilter',
      column: 'quickFilter',
      equal: newQuickFilter,
    };

    if (currentIndex > -1) {
      /** This acts as a toggle */
      if (nextFilters[currentIndex].equal === newQuickFilter) {
        nextFilters.splice(currentIndex, 1);
      } else {
        nextFilters[currentIndex] = filter;
      }
    } else {
      nextFilters.push(filter);
    }

    if (isFunction(onChangeFilters)) {
      onChangeFilters(nextFilters);
    }
  };

  const handleRemoveFilter = (filter: IFilter) => {
    const nextFilters = without(filters, filter);
    if (isFunction(onChangeFilters)) {
      onChangeFilters(nextFilters);
    }
  };

  const addEvent = useEventContext();

  const selectedQuickFilter = useMemo(() => {
    const quickFilter = find(filters, ['id', 'quickFilter']);

    if (!quickFilter) {
      return '';
    }

    return quickFilter.equal;
  }, [filters]);

  const displayedFilters = filter(filters, (f) => f.id !== 'quickFilter');

  return (
    <Space
      className={cn(styles.FilterList, className)}
      wrap
    >
      {
        displayQuickFilters
          && (
            <QuickFilters
              onSelect={handleSelectQuickFilter}
              selectedFilter={selectedQuickFilter}
              quickFiltersCounts={quickFiltersCounts}
              quickFiltersOptions={QuickFiltersOptions}
            />
          )
      }
      {map(displayedFilters, (filter, idx) => {
        if (isFilterDisabled(filter)) {
          return null;
        }

        return (
          <FilterFormPopover
            key={filter.id}
            filter={filter}
            fields={fields}
            onAddFilter={(updatedFilter) => handleChangeFilter(idx, updatedFilter)}
            popoverProps={{
            placement: 'bottomLeft',
          }}
          >
            <FilterItem
              filter={filter}
              fields={fields}
              onClickRemove={handleRemoveFilter}
            />
          </FilterFormPopover>
        );
      })}
      <AddFilterPopover
        fields={fields}
        filters={filters}
        onAddFilter={handleAddFilter}
        additionalSources={additionalSources}
      >
        <Button
          type="dashed"
          icon={<PlusOutlined size={18} />}
          onClick={() => addEvent(EventName.MemberFilterClick, {})}
        >
          Add Filters
        </Button>
      </AddFilterPopover>
    </Space>
  );
});

FilterList.defaultProps = {
  isFilterDisabled: () => false,
};

FilterList.displayName = 'FilterList';
