import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AutomationAuditTable } from '@frontend/app/components';

import { LoadSpinner } from '@components';
import TemplateModal from '@frontend/app/containers/Projects/AutomationPage/templateModal/TemplateModal';
import { ProjectsRouteRoot } from '@frontend/app/containers/Projects/constants';
import { TAutomation, useListAutomationsQuery } from '@frontend/app/hooks/automations';
import { AutomationStatus, OrderingDirection } from '@frontend/app/types/globalTypes';
import { Button, Tag, Tabs } from '@revfluence/fresh';
import { BoltIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './AutomationPage.scss';

const { useState } = React;

type TProps = {
  projectId: number;
};

enum TTab {
  automation = 'automations',
  history = 'history',
}

// TODO(jb) implement pagination
const AutomationPage = (props: TProps) => {
  const location = useLocation();
  const hashValue = location.hash.replace('#', '') === TTab.history ? TTab.history : TTab.automation;
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const { projectId } = props;
  const history = useHistory();

  const goToConfig = (automationId: string) => {
    history.push({
      pathname: `${ProjectsRouteRoot}/${projectId}/automation_detail/automation/${automationId}`,
    });
  };

  const openTemplateModal = () => {
    setIsTemplateModalVisible(true);
  };

  const closeTemplateModal = () => {
    setIsTemplateModalVisible(false);
  };

  const {
    data: {
      listAutomations: {
        results: automations,
      },
    } = {
      listAutomations: {
        results: [],
      },
    },
    loading: isLoadingAutomations,
  } = useListAutomationsQuery({
    variables: {
      paging: {
        offset: 0,
        limit: 10,
      },
      filters: {
        program: {
          id: projectId,
        },
      },
      ordering: {
        name: OrderingDirection.ASC,
      },
    },
    fetchPolicy: 'network-only',
  });

  const onTabSwitch = (key) => {
    history.push({
      hash: key,
    });
  };

  if (isLoadingAutomations) return <LoadSpinner />;
  return (
    <div className={styles.AutomationPage}>
      <div className={styles.header}>
        <div className={styles.title}>Automations</div>
        <Button type="primary" onClick={openTemplateModal}>Add Automation</Button>
      </div>
      <div>Automate project tasks to easily scale your campaigns. Add a new automation or configure an existing one.</div>
      <Tabs
        activeKey={hashValue}
        onTabClick={onTabSwitch}
        items={[
          {
            label: 'All Automations',
            key: TTab.automation,
            children: (
              <>
                {
                  automations.length > 0 && (
                    <div className={styles.automationGrid}>
                      {automations.map((automation: TAutomation) => (
                        // TODO(jb) refactor into child component <AutomationCard />
                        <div
                          className={styles.automationCard}
                          key={automation.id}
                          onClick={() => goToConfig(automation.id)}
                        >
                          <div className={styles.cardHeader}>
                            <div className={styles.title}>{automation.name}</div>
                            {
                              automation.status === AutomationStatus.ACTIVE
                              ? (
                                <Tag color="success">On</Tag>
                              ) : (
                                <Tag>Off</Tag>
                              )
                            }
                          </div>
                          <div className={styles.description}>{automation.description}</div>
                          <div className={styles.editAutomation}>View</div>
                        </div>
                      ))}
                    </div>
                  )
                }
                {
                  automations.length === 0 && (
                    <div className={styles.emptyAutomation}>
                      <div className={styles.emptyAutomationIcon}>
                        <BoltIcon />
                      </div>
                      <div className={styles.emptyTitle}>You have no automations</div>
                      <div className={styles.emptyDescription}>Easily add an automation from our automation library</div>
                    </div>
                  )
                }
                <TemplateModal
                  projectId={projectId}
                  isVisible={isTemplateModalVisible}
                  onCancel={closeTemplateModal}
                />
              </>
            ),
          },
          {
            label: 'Run History',
            key: TTab.history,
            children: <AutomationAuditTable projectId={projectId} />,
          },
        ]}
      />
    </div>
  );
};

AutomationPage.displayName = 'AutomationPage';

export default AutomationPage;
