import { GetBudgetDistributionsForBudgetAccountQuery_budgetDistributionsForBudgetAccount } from '@frontend/app/queries/types/GetBudgetDistributionsForBudgetAccountQuery';
import { IPaymentSources } from '@frontend/applications/PaymentsApp/models';
import {
    AssignPaymentTo,
    IFeatureFlagsOptions,
    PaymentCreationSource, PaymentsProgram, TBudgetAccount, TBudgetPayment, TGroup, TMemberInfoForPayment,
    TSTAPayment,
} from '@frontend/applications/PaymentsApp/types';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import React from 'react';

interface IState {
    currentStep: number;
    error: Error | null;
    memberId?: string;
    terms: IAgreement[];
    paymentCreationSource?: PaymentCreationSource,
    programs: PaymentsProgram[],
    memberInfo?: TMemberInfoForPayment,
    amountPaying: number,
    paymentSource?: IPaymentSources,
    assignPaymentTo: AssignPaymentTo,
    budgetAccounts: TBudgetAccount[],
    budgetDistributionsForBudgetAccount: GetBudgetDistributionsForBudgetAccountQuery_budgetDistributionsForBudgetAccount[],
    selectedBudgetAccountId?: number | null,
    selectedBudgetDistributionId?: number | null,
    selectedTermId?: number | null,
    payments: TBudgetPayment[],
    overflowPayments: TBudgetPayment[],
    groups: TGroup[],
    selectedGroupId: number | null,
    paypalAddress: string | null,
    selectedCardId: string | null,
    selectedProgramId: number | null,
    paypalFieldId: string | null,
    requireHandlingFee: boolean;
    selectedBudgetReassign: React.Key[]
    staPayments:TSTAPayment[],
    featureFlags:IFeatureFlagsOptions,
}
export type TState = Readonly<IState>;

interface IActions {
    setCurrentStep: (step: number) => void;
    setAmountPaying: (amountPaying: number) => void;
    setError: (error: Error) => void;
    setTerms: (terms: IAgreement[]) => void;
    setPaymentSource: (paymentSource: IPaymentSources) => void;
    setPrograms: (terms: PaymentsProgram[]) => void;
    setMemberInfo: (memberInfo: TMemberInfoForPayment) => void;
    setBudgetAccounts: (budgetAccounts: TBudgetAccount[]) => void;
    setBudgetDistributionsForBudgetAccount: (budgetDistributions: GetBudgetDistributionsForBudgetAccountQuery_budgetDistributionsForBudgetAccount[]) => void;
    setSelectedBudgetAccount: (selectedBudgetAccountId: number) => void;
    setSelectedBudgetDistribution: (selectedBudgetDistributionId: number) => void;
    setSelectedTermId: (id: number) => void;
    setBudgetPayments: (payments: TBudgetPayment[]) => void;
    setOverflowBudgetPayments: (payments: TBudgetPayment[]) => void;
    setAssignPaymentTo: (assignPaymentTo: AssignPaymentTo) => void;
    setGroups: (groups: TGroup[]) => void;
    setFieldValue: (fieldName: string, value: number | string | boolean) => void;
    setSelectedBudgetReassign: (selectedBudgetReassign:React.Key[]) => void;
    setSTAPayments: (staPayments:TSTAPayment[]) => void;
}
export type TActions = Readonly<IActions>;

export enum ActionType {
    SET_CURRENT_STEP = 'SET CURRENT STEP',
    SET_ERROR = 'SET ERROR',
    SET_TERMS = 'SET TERMS',
    SET_PROGRAMS = 'SET PROGRAMS',
    SET_MEMBER_INFO = 'SET MEMBER INFO',
    SET_AMOUNT_PAYING = 'SET AMOUNT PAYING',
    SET_PAYMENT_SOURCE = 'SET PAYMENT SOURCE',
    SET_BUDGET_ACCOUNTS = 'SET BUDGET ACCOUNTS',
    SET_BUDGET_DISTRIBUTIONS_FOR_BUDGET_ACCOUNT = 'SET BUDGET DISTRIBUTIONS FOR BUDGET ACCOUNT',
    SET_SELECTED_BUDGET_ACCOUNT = 'SET SELECTED BUDGET ACCOUNT',
    SET_SELECTED_BUDGET_DISTRIBUTION = 'SET BUDGET PERIOD DEFINITION',
    SET_SELECTED_TERM_ID = 'SET SELECTED TERM ID',
    SET_BUDGET_PAYMENTS = 'SET BUDGET PAYMENTS',
    SET_OVERFLOW_BUDGET_PAYMENTS = 'SET OVERFLOW BUDGET PAYMENTS',
    SET_ASSIGN_PAYMENT_TO = 'SET ASSIGN PAYMENT TO',
    SET_GROUPS = 'SET GROUPS',
    SET_FIELD_VALUE = 'SET FIELD VALUE',
    SET_BUDGET_REASSIGN = 'SET BUDGET REASSIGN',
    SET_STA_PAYMENTS = 'SET STA PAYMENTS',
}
