import * as React from 'react';
import {
  filter, isNull, keys, map,
} from 'lodash';
import { IStepInfo as Step } from '@frontend/applications/Shared/Wizard/container';
import { OFFER_SOURCE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import { useOfferDetailsContext } from '@frontend/context/OfferDetailsContext';
import { ActiveDates } from '../../components/ActiveDates';
import { NoMembersWarning } from '../../components/NoMembersWarning';
import { ReviewMembers } from '../../components/ReviewMembers';
import { SelectMembers } from '../../components/SelectMembers';
import { SelectPrograms } from '../../components/SelectPrograms';
import { StatsCardPromos } from '../../components/StatsCardPromos';
import { TAddMembersProps } from '../../types';
import { TActions, TState } from './state';
import { StatsCardLinks } from '../../components/StatsCardLinks';
import { BulkUpdate } from '../../components/BulkUpdate';

interface IStepConfig {
  instructions: Step['instructions'];
  nextButtonConfig: Step['nextButtonConfig'];
  previousButtonConfig: Step['previousButtonConfig'];
  actionButtonConfig?: Step['actionButtonConfig'];
  stepNum: Step['stepNum'];
}

type TStepFactory = (config: IStepConfig, overrides?: Partial<Step>) => Step;

export const getSteps = (props: TAddMembersProps, state: TState, actions: TActions) => {
  const {
    offerSource, fetchMembers, offerName, programLabel, onClose, migrateToGraphQL,
  } = props;
  const { isOldOffer } = useOfferDetailsContext() || {};
  const title = 'Add Members';
  const selectedMembers = isNull(state.members) ? [] : filter(state.members, (m) => m.selected);
  const forceCheckInMembers = isNull(state.members) ? [] : filter(state.members, (m) => m.forceCheckIn);
  const allMembers = isNull(state.members) ? [] : state.members;
  const codeGenerationStrategy = props.offerSource === OFFER_SOURCE.SHOPIFY
    ? props.codeGenerationStrategy
    : undefined;
  const offerLink = props.offerSource === OFFER_SOURCE.TUNE ? props.offerLink : undefined;
  const domains = props.offerSource === OFFER_SOURCE.TUNE ? props.domains : undefined;
  const isCreatorDeepLinkAllowed = props.offerSource === OFFER_SOURCE.TUNE ? props.isCreatorDeepLinkAllowed : undefined;
  const deepLink = props.offerSource === OFFER_SOURCE.TUNE;
  let statsCard: React.ReactNode;
  if (props.payouts.length) {
    statsCard = null;
  } else {
    switch (props.offerSource) {
      case OFFER_SOURCE.SHOPIFY:
        statsCard = (
          <StatsCardPromos
            activeDates={state.activeDates}
            discountAmount={props.discountAmount}
            discountType={props.discountType}
            members={state.members}
            mode="add"
            offerName={props.offerName}
            isNewFlow={props.isNewFlow}
            payoutType={props.payoutType}
            percentPayout={props.percentPayout}
            flatPayout={props.flatPayout}
            startDate={props.startDate}
            endDate={props.endDate}
          />
        );
        break;
      case OFFER_SOURCE.TUNE:
        statsCard = (
          <StatsCardLinks
            payoutType={props.payoutType}
            expirationDate={props.expirationDate}
            flatPayout={props.flatPayout}
            percentPayout={props.percentPayout}
            members={state.members}
            mode="add"
          />
        );
        break;
    }
  }
  const selectPrograms: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        {statsCard}
        <SelectPrograms
          offerSource={offerSource}
          markProgramsSelected={actions.selectPrograms}
          programs={state.programs}
          programLabel={programLabel}
          selectedPrograms={state.selectedPrograms}
          isNewFlow={props.isNewFlow}
        />
      </>
    ),
    title,
    ...config,
    ...overrides,
  } as Step);

  const selectMembers: TStepFactory = (config, overrides = {}): Step => {
    let noMembersWarning;
    if (!migrateToGraphQL && isOldOffer && state.showNoMembersWarning) {
      noMembersWarning = (
        <NoMembersWarning
          onClickBack={() => {
            actions.setCurrentStep(1);
          }}
          onDismissWarning={actions.dismissNoMembersWarning}
          programLabel={programLabel}
          isWorkflow={props.isWorkflow}
          codeGenerationStrategy={codeGenerationStrategy}
          isNewFlow={props.isNewFlow}
          onClose={onClose}
          members={selectedMembers}
        />
      );
    }
    return {
      actionComponents: (
        <>

          {statsCard}
          <SelectMembers
            offerSource={offerSource}
            fetchMembers={(page, pageSize, searchByName, programs = null) => {
              actions.updateIsLoading(true);
              fetchMembers(programs || map(keys(state.selectedPrograms), (id) => Number(id)), page, pageSize, searchByName, props.isNewFlow)
                .then((members) => {
                  actions.populateMembers(members);
                })
                .catch(actions.setError)
                .finally(() => {
                  actions.updateIsLoading(false);
                });
            }}
            fetchMembersCount={props.fetchMembersCount}
            markMembersSelected={actions.selectMembers}
            members={state.members}
            programs={state.programs}
            selectedPrograms={state.selectedPrograms}
            markProgramsSelected={actions.selectPrograms}
            offerLink={offerLink}
            updateMemberDeepLink={actions.updateMemberDeepLink(offerLink, domains, isCreatorDeepLinkAllowed)}
            mode="add"
            noMembersWarning={noMembersWarning}
            programLabel={programLabel}
            updateMemberCode={actions.updateMemberCode}
            isWorkflow={props.isWorkflow}
            deepLinkColumn={deepLink}
            isNewFlow={props.isNewFlow}
            programId={keys(state.selectedPrograms).map((id) => Number(id))}
            migrateToGraphQL={props.migrateToGraphQL}
            searchKey={state.searchKey}
            updateSearchKey={actions.updateSearchKey}
            selectedMembers={state.selectedMembers}
            resetMember={actions.resetMember}
            isLoading={state.isLoading}
            updateIsLoading={actions.updateIsLoading}
          />
        </>
      ),
      title,
      ...config,
      ...overrides,
    } as Step;
  };

  const selectPayoutAndDate: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        <BulkUpdate
          members={selectedMembers}
          payouts={props.payouts}
          defaultPayoutId={props.defaultPayoutId}
          bulkPayoutId={state.bulkPayoutId}
          bulkStartDate={state.bulkStartDate}
          bulkEndDate={state.bulkEndDate}
          showEndDate={state.showEndDate}
          offerStartDate={props.offerSource === OFFER_SOURCE.SHOPIFY ? props.startDate : null}
          onBulkPayoutIdChange={actions.updateBulkPayoutId}
          onBulkActiveDateChange={actions.updateBulkActiveDate}
          updateShowEndDate={actions.updateShowEndDate}
        />
      </>
    ),
    title,
    ...config,
    ...overrides,
  } as Step);
  const selectActiveDates: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        {statsCard}
        <ActiveDates activeDates={state.activeDates} updateDates={actions.updateActiveDateField} />
      </>
    ),
    title,
    ...config,
    ...overrides,
  } as Step);

  const reviewMembers: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        {statsCard}
        <ReviewMembers
          offerSource={offerSource}
          members={selectedMembers}
          mode="add"
          offerName={offerName}
          programLabel={programLabel}
          isWorkflow={props.isWorkflow}
          deepLinkColumn={deepLink}
          updateMemberCode={actions.updateMemberCode}
          updateMemberPayoutId={actions.updateMemberPayoutId}
          updateMemberActiveDate={actions.updateMemberActiveDate}
          isEditablerow
          isNewFlow={props.isNewFlow}
          payouts={props.payouts}
          defaultPayoutId={props.defaultPayoutId}
          migrateToGraphQL={migrateToGraphQL}
          isUngrouped={props.isUngrouped}
          bulkPayoutId={state.bulkPayoutId}
          bulkStartDate={state.bulkStartDate}
          bulkEndDate={state.bulkEndDate}
          offerStartDate={props.offerSource === OFFER_SOURCE.SHOPIFY ? props.startDate : null}
          isSaving={state.saving}
        />
      </>
    ),
    title,
    ...config,
    ...overrides,
  } as Step);

  const completed: TStepFactory = (config, overrides = {}): Step => ({
    actionComponents: (
      <>
        {statsCard}
        <ReviewMembers
          offerSource={offerSource}
          members={selectedMembers}
          mode="add"
          offerName={offerName}
          programLabel={programLabel}
          isWorkflow={props.isWorkflow}
          deepLinkColumn={deepLink}
          updateMemberCode={actions.updateMemberCode}
          isEditablerow={false}
          isNewFlow={props.isNewFlow}
          payouts={props.payouts}
          defaultPayoutId={props.defaultPayoutId}
          migrateToGraphQL={migrateToGraphQL}
        />
      </>
    ),
    title,
    ...config,
    ...overrides,
  } as Step);

  return {
    selectPrograms,
    selectMembers,
    selectPayoutAndDate,
    selectActiveDates,
    reviewMembers,
    completed,
    selectedMembers,
    forceCheckInMembers,
    allMembers,
  };
};
