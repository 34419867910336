import * as React from 'react';

import { useState } from 'react';

import {
  PostCard,
} from '@components';

import { useMessagingContext } from '@frontend/hooks';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { ArtifactAssignmentForm, IAssignmentAPIPayload } from '@frontend/applications/Shared/components/ArtifactAssignmentForm';
import { RequirementAssignmentForm } from '@frontend/applications/Shared/components/RequirementAssignmentForm';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { saveSocialData } from '../saveSocialPostData';
import { saveSocialPostToDeliverable } from '../savePostToDeliverable';

import { ISocialPostArtifact } from '../useFetchSocialPostData';

interface IProps {
  post?: ISocialPostArtifact;
  memberId: string;
  onSkip?(): void;
  onSuccess?(): void;
}

export const SocialPostAssignment: React.FunctionComponent<IProps> = (props) => {
  const {
    showGenericErrorMessage,
  } = useMessagingContext();
  const addEvent = useEventContext();

  const {
 post, onSkip, onSuccess, memberId,
} = props;
  const [error, setError] = useState<string>(null);
  const [savingAssignments, setSavingAssignments] = useState(false);
  const [savingRequirements, setSavingRequirements] = useState<boolean>(false);
  const [showRequirementsForm, setShowRequirementsForm] = useState<boolean>(false);

  const { backendServerApiEndpoint, clientId, workflowActionParameters } = useApplication();

  const onSave = (assignment: IAssignmentAPIPayload) => {
    const url = `${backendServerApiEndpoint}/social_mention/${post.mention_id}`;
    const params = {
      community_ids: assignment.community_ids,
      activation_ids: assignment.activation_ids,
      program_ids: assignment.program_ids,
      assigned: true,
      client_id: clientId,
      workflow_context: workflowActionParameters ? JSON.stringify({
        member_id: workflowActionParameters?.memberIds?.[0],
        program_id: workflowActionParameters?.programId,
        work_item_id: workflowActionParameters?.workItems?.[0]?.id,
      }) : null,
    };

    setSavingAssignments(true);
    saveSocialData(url, params)
      .then(() => onArtifactAssignmentSuccess(post, params))
      .catch((error) => {
        setError(error);
      })
      .finally(() => setSavingAssignments(false));
  };

  if (error) {
    showGenericErrorMessage();
  }

  const onError = () => {
    // ERROR TODO
  };

  const onArtifactAssignmentSuccess = (post, params) => {
    setShowRequirementsForm(true);
    addEvent(
      EventName.SocialPostAssigned,
      {
        post_id: post.post_id,
        post_type: post.post_type,
        network: post.network,
        mention_id: post.mention_id,
        reach: post.reach,
        datetime_posted: post.datetime_posted,
        profile_image_url: post.profile_image_url,
        social_account_name: post.social_account_name,
        social_account_link: post.social_account_link,
        image_url: post.image_url,
        link: post.link,
        creator: post.creator,
        post_text: post.post_text,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        member_id: memberId as any,
        program_ids: params.program_ids,
        activation_ids: params.activation_ids,
        community_ids: params.community_ids,
      },
    );
  };

  const onAddToRequirement = (requirementId: number, markComplete: boolean) => {
    if (!requirementId) {
      onSuccess();
      return;
    }

    setSavingRequirements(true);
    const params = {
      client_id: clientId,
      mention_id: post.mention_id,
      requirement_id: requirementId,
      mark_complete: markComplete,
    };
    const url = `${backendServerApiEndpoint}/social_mention_add_deliverable`;
    saveSocialPostToDeliverable(url, params).then(onSuccess).catch(onError).finally(() => setSavingRequirements(false));
  };

  const preview = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <PostCard isQa={false} post={post as any} hideValueSection />
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formParams: any = {};
  return (
    <div>
      {!showRequirementsForm
        ? (
          <ArtifactAssignmentForm
            {...formParams}
            artifact={post}
            artifactName="post"
            secondaryArtifactName="content"
            onSkip={onSkip}
            onSave={onSave}
            loading={savingAssignments}
            preview={preview}
          />
) : (
  <RequirementAssignmentForm
    artifactPreview={preview}
    memberId={Number(memberId)}
    artifactName="Post"
    onAddToRequirement={onAddToRequirement}
    saving={savingRequirements}
  />
      )}
    </div>
  );
};
