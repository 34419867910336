import * as React from 'react';
import { sortBy } from 'lodash';
import Filter from '../pages/Reporting/components/Filter/Filter';
import DateFilter from '../pages/Reporting/components/DateFilter/DateFilter';
import Header, { dateValues, HeaderLoading } from '../pages/Reporting/components/Header/Header';
import { HandleSettingsChange } from '../containers/Reporting/Reporting';

interface IReportingHeaderProps {
  clientStartDate: Date;
  onSettingChange: HandleSettingsChange;
  projects: { label: string, value: number }[];
  selectedProjects: { label: string, value: number }[];
  dateRangeLabel: string;
  loading: boolean;
  dateRange?: [Date?, Date?];
  isDateFilter?: boolean;
}

export const useReportingHeader = ({
  onSettingChange,
  projects,
  selectedProjects,
  dateRangeLabel,
  loading,
  clientStartDate,
  dateRange,
  isDateFilter,
}: IReportingHeaderProps) => {
  if (loading) {
    <HeaderLoading />;
  }

  return (
    <Header
      Actions={[
        <Filter
          key="project-filter"
          defaultLabel="All Data"
          multipleLabel="Multiple Projects"
          allLabel="All Projects"
          isCheckbox
          options={sortBy(projects, (e) => e.label.toLocaleLowerCase())}
          onFilterChange={(projects) => onSettingChange({ projectIdsList: projects })}
          selection={selectedProjects}

        />,
        <DateFilter
          key="date-filter"
          defaultLabel={dateRangeLabel}
          isCheckbox={false}
          options={dateValues}
          clientStartDate={clientStartDate}
          dateRange={dateRange}
          isCustomDate={isDateFilter}
          onFilterChange={([filter]) => filter && onSettingChange(
            {
              dateRanges: filter.value.dateRange,
              compareRange: filter.value.compareRange,
              dateRangeLabel: filter.label,
            },
          )}
        />,
      ]}
    />
  );
};
