import * as React from 'react';
import * as qs from 'qs';
import { pickBy } from 'lodash';

import { logger } from '@common';
import { ISocialPost } from '@components';
import { IArtifact } from '@frontend/applications/Shared/context/applicationContext';

export interface ISocialPostArtifact extends ISocialPost, IArtifact {}

const { useEffect, useState } = React;

const fetchSocialData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'text/plain',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    logger.error({ message: err });

    throw err;
  }
};

interface IFetchSocialPostsParams {
  clientId: string;
  memberId?: string;
  unassigned?: boolean;
  userId?: string;
  page?: number;
  pageSize?: number;
}

export function useFetchPostsData(
  url: string,
  params: IFetchSocialPostsParams,
) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ISocialPostArtifact[]>(null);
  const [error, setError] = useState(null);
  const [fetchTrigger, setFetchTrigger] = useState(0); // Used to trigger refetch

  const apiParams = {
    client_id: params.clientId,
    member_id: params.memberId,
    unassigned: params.unassigned,
    user_id: params.userId,
    page: params.page,
    page_size: params.pageSize,
  };

  url = `${url}?${qs.stringify(pickBy(apiParams))}`;

  useEffect(() => {
    setLoading(true);
    fetchSocialData(url)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [url, fetchTrigger]);

  // Function to trigger refetch
  const refetchData = () => {
    setFetchTrigger((prevTrigger) => prevTrigger + 1);
  };

  return {
    loading,
    data,
    error,
    refetchData,
  };
}

export function useFetchPostData<ISocialPostArtifact>(url: string, clientId: string, postId: string) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ISocialPostArtifact>(null);
  const [error, setError] = useState(null);

  url = `${url}/${postId}?client_id=${clientId}`;

  useEffect(() => {
    if (!postId) {
      return;
    }

    setLoading(true);
    fetchSocialData(url)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [url, postId]);

  return {
    loading,
    data,
    error,
  };
}
