import * as React from 'react';
import {
  Card as FreshCard,
  Row,
  Col,
  Skeleton,
  List,
} from '@revfluence/fresh';

import styles from './WidgetTikTokSparkAdsRecommendations.module.scss';

const LoadingLine = () => (
  <Skeleton
    paragraph={false}
    title
    loading
    active
  />
);

const LoadingBox = () => (
  <Skeleton
    className={styles.loadingImageBox}
    paragraph={false}
    style={{
      borderRadius: '6px',
    }}
    loading
    active
  />
);

const BlockLoadingLine = () => (
  <Row>
    <Col span={24}>
      <LoadingLine />
    </Col>
  </Row>
);

const RecommendationSkeleton = () => (
  <Col>
    <LoadingBox />
    <Row>
      <Col
        span={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '-24px',
        }}
      >
        <Skeleton.Avatar
          size="large"
          shape="square"
          style={{
            border: '2px solid white',
          }}
          active
        />
      </Col>
      <Col span={12}>
        <LoadingLine />
      </Col>
    </Row>
    <BlockLoadingLine />
    <BlockLoadingLine />
  </Col>
);

const LoadingButton = () => (
  <Row
    justify="center"
    align="middle"
  >
    <Col span={5}>
      <Skeleton.Button
        size="large"
        active
        block
      />
    </Col>
  </Row>
);

export const WidgetTikTokSparkAdsRecommendationsLoading: React.FC = React.memo(() => (
  <FreshCard>
    <LoadingLine />
    <FreshCard
      className={styles.TikTokSparkAdsRecommendationsWidgetCard}
      bordered={false}
      bodyStyle={{
        paddingLeft: '0',
        paddingRight: '0',
      }}
    >
      <List
        dataSource={[1, 2, 3, 4, 5]}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 5,
          xxl: 5,
        }}
        renderItem={(_: number) => (
          <List.Item>
            <RecommendationSkeleton />
          </List.Item>
        )}
      />
      <LoadingButton />
    </FreshCard>
  </FreshCard>
));

WidgetTikTokSparkAdsRecommendationsLoading.displayName = 'WidgetTikTokSparkAdsRecommendationsLoading';
