import gql from 'graphql-tag';

export enum SelectedField {
  ORDER = 'order',
}

type SelectedFields = Set<SelectedField>;

export function generateMemberSearchGraphQLQuery(selectedFields: SelectedFields = new Set()) {
  const hasOrderField = selectedFields && selectedFields.has(SelectedField.ORDER);
  const query = gql`
    query MemberSearchQuery(
      $query: JSONObject!,
      $take: Int!,
      $skip: Int!,
      $includeActivations: Boolean = false,
      $includeTags: Boolean = false,
      $includeCommunities: Boolean = false,
      $includeOwners: Boolean = false,
      $includePrograms: Boolean = false,
      $includeHighlights: Boolean = false,
      $includeLastMessage: Boolean = false,
      $includeProgramMemberships: Boolean = false,
      $includeProgramMembershipsLogs: Boolean = false,
    ) {
      members: membersForSearchQuery(query: $query, take: $take, skip: $skip) {
        id
        email
        name
        isContact
        isImportant
        profilePicture
        notes
        fields
        source
        createdDate
        owners {
          id
          name
        }
        activations @include(if: $includeActivations) {
          id
          name
        }
        tags @include(if: $includeTags) {
          id
          name
        }
        highlights @include(if: $includeHighlights) {
          id
          name
        }
        owners @include(if: $includeOwners) {
          email
          name
        }
        programs @include(if: $includePrograms) {
          id
          title
        }
        invitedPrograms: programs(status: "invited") @include(if: $includePrograms) {
          id
          title
        }
        submittedPrograms: programs(status: "new") @include(if: $includePrograms) {
          id
          title
        }
        rejectedPrograms: programs(status: "rejected") @include(if: $includePrograms) {
          id
          title
        }
        communities @include(if: $includeCommunities) {
          id
          title
        }
        lastMessage @include(if: $includeLastMessage) {
          id
          snippet
          threadId
          internalDate
          payload {
            from
          }
        }
        programMemberships @include(if: $includeProgramMemberships) {
          utmSource
          status
          programId
        }
        programMembershipsLogs @include(if: $includeProgramMembershipsLogs) {
          createdDate
          status
          programId
        }
        talentAgents {
          memberId
          agentId
          alwaysCC
          agentName
          agentEmail
          createdDate
          updatedDate 
        }
        ${hasOrderField ? '...orders' : ''}
      }
    }
    ${hasOrderField ? ORDER_FRAGMENT : ''}
  `;

  return query;
}

const ORDER_FRAGMENT = gql`
  fragment orders on Member {
    orders {
      id
      memberId
      programId
      orderNumber
      orderDate
      fulfillmentStatus
      shipmentStatus
      trackingNumber
      orderRequestStatus
      shipmentDate
      deliveryDate
      orderType
      orderCost
      productsOrdered
      productsOrderedShopify
      productsOrderedInPending
    }
  }
`;

// TODO: re-add this with apollo 3.0 cache policies
// (forSchemas: $schemaIds)
