import React from 'react';
import {
 Button, Col, IColumnsType, Input, Row, Space, Tag, Typography,
} from '@revfluence/fresh';
import {
 ChevronLeftIcon, ChevronRightIcon, MagnifyingGlassIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { TableHeader } from '@frontend/applications/AffiliatesApp/components';
import { filter, includes, size } from 'lodash';
import AffiliateTableSTA from '@frontend/applications/AffiliatesApp/components/AffiliateTableSTA/AffiliateTableSTA';
import { ShopifyIcon } from '@revfluence/fresh-icons/brands/esm';
import { FileCsvIcon } from '@revfluence/fresh-icons/solid/esm';
import { ImportStatus, Source } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { format } from 'date-fns';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import styles from './ImportLogs.scss';
import { TabContainer } from '../TabContainer/TabContainer';
import { IImportLog, IImportLogRow } from '../types';
import { useGetImportLogs } from '../hooks/useGetImportLogs';
import { ImportStatusRenderer } from '../ShopifyImportModal/ImportStatusRenderer';
import { pfaV2Routes } from '../../routes';

const { Text, Paragraph } = Typography;

const PAGE_SIZE = 10;

const NameRenderer = ({ id, collectionName, source }: IImportLogRow) => (
  <Space align="center">
    <div className={`${styles.nameIcon} ${source === Source.SHOPIFY ? styles.shopifyIcon : styles.csvIcon}`}>
      {source === Source.SHOPIFY ? <ShopifyIcon /> : <FileCsvIcon />}
    </div>
    <Space>
      <Text weight="semibold">
        #
        {id}
      </Text>
      <Text>{collectionName}</Text>
    </Space>
  </Space>
);

export const ImportLogs = () => {
  const [searchText, setSearchText] = React.useState<string>('');

  const { importLogs, refetch } = useGetImportLogs({
    pollInterval: 5000,
  });

  const columnConfig = React.useMemo<IColumnsType<IImportLogRow>>(
    () => [
      {
        title: 'Collection',
        dataIndex: '_raw',
        key: 'collection',
        align: 'left',
        width: 322,
        ellipsis: {
          showTitle: false,
        },
        render: NameRenderer,
      },
      {
        title: 'Imported/synced',
        dataIndex: 'imported',
        key: 'imported',
        width: 153,
        align: 'left',
      },
      // {
      //   title: 'Removed',
      //   dataIndex: 'removed',
      //   key: 'removed',
      //   width: 153,
      //   align: 'left',
      // },
      // {
      //   title: 'Error',
      //   dataIndex: 'errored',
      //   key: 'errored',
      //   align: 'left',
      //   width: 153,
      // },
      {
        title: 'Import Status',
        dataIndex: '_raw',
        key: 'importStatus',
        align: 'left',
        width: 155,
        render: (raw: IImportLogRow) => (
          <ImportStatusRenderer
            collection={{
              id: raw.collection.externalId,
              title: raw.collection.name,
              image: raw.collection.images?.[0],
              productsCount: raw.collection.productCount,
              currentStatus: raw.importStatus,
              handle: raw.handle,
            }}
            statusInfo={{
              id: raw.collection.id,
              externalId: raw.collection.externalId,
              status: raw.importStatus,
            }}
            refetch={refetch}
            showActions={false}
          />
        ),
      },
      {
        title: 'Imported By',
        dataIndex: 'importedBy',
        key: 'importedBy',
        align: 'left',
        width: 162,
      },
      {
        title: 'Created On',
        dataIndex: 'createdAt',
        key: 'createdAt',
        align: 'left',
        width: 180,
        render: (createdOn) => <Tag>{createdOn ? format(createdOn, "MMM dd, yyyy 'at' h:mmaaaaa'm'") : '' }</Tag>,
      },
      {
        title: 'Finished At',
        dataIndex: 'finishedAt',
        key: 'finishedAt',
        align: 'left',
        width: 180,
        render: (finishedAt) => <Tag>{finishedAt ? format(finishedAt, "MMM dd, yyyy 'at' h:mmaaaaa'm'") : ''}</Tag>,
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        align: 'left',
        width: 180,
        render: (message) =>
          (message ? (
            <Paragraph>
              <pre>{message}</pre>
            </Paragraph>
          ) : null),
      },
    ],
    [refetch],
  );

  const pagination = {
    total: importLogs.length,
    pageSize: PAGE_SIZE,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
    itemRender: (_current, type, originalElement) => {
      if (type === 'prev') {
        return <Button icon={<ChevronLeftIcon fontSize={24} />} />;
      }
      if (type === 'next') {
        return <Button icon={<ChevronRightIcon fontSize={24} />} />;
      }
      return originalElement;
    },
    showLessItems: true,
    className: 'customPagination',
  };

  const headerActions = React.useMemo(
    () => (
      <TableHeader
        renderCount={() => {
          const count = size(importLogs);
          return `${count} Log${count !== 1 ? 's' : ''}`;
        }}
      />
    ),
    [importLogs],
  );

  const searchBox = () => (
    <Row gutter={9}>
      <Col>
        <Input
          placeholder="Search"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          prefix={<MagnifyingGlassIcon />}
        />
      </Col>
    </Row>
  );

  const tableData = React.useMemo<IImportLogRow[]>(
    () =>
      importLogs.map((log) => {
        const transformedData: IImportLog = {
          key: log.id,
          id: String(log.id),
          collectionName: log.collection.name,
          collection: log.collection,
          imported: log.logPayload.importedProductCount,
          source: Source.SHOPIFY,
          importStatus: log.logPayload.status as ImportStatus,
          importedBy: log.userInfo.name,
          createdAt: log.logPayload.processStart ? new Date(log.logPayload.processStart) : null,
          finishedAt: log.logPayload.processEnd ? new Date(log.logPayload.processEnd) : null,
          message: log.logPayload.message,
          handle: log.collection.metadata.handle,
        };
        const importLogRow: IImportLogRow = {
          ...transformedData,
          _raw: transformedData,
        };
        return importLogRow;
    }),
    [importLogs],
  );

  const filteredData = React.useMemo(() => {
    if (!searchText) {
      return tableData;
    }
    return filter(tableData, (item: IImportLogRow) => includes(item.collectionName.toLowerCase(), searchText.toLowerCase()));
  }, [tableData, searchText]);

  return (
    <div className={styles.ImportLogs}>
      <PageHeader
        title="Importer Logs"
        backButtonUrl={pfaV2Routes.settings.imports}
      />
      <TabContainer>
        <AffiliateTableSTA<IImportLogRow>
          dataSource={filteredData}
          columns={columnConfig}
          pagination={pagination}
          headerActions={headerActions}
          searchBox={searchBox()}
          searchText={searchText}
          sortField="name"
          className="offerTable"
          enableEditColumn
        />
      </TabContainer>
    </div>
  );
};
