import * as React from 'react';
import cx from 'classnames';
import { isEmpty } from 'lodash';

import {
  Input, Tooltip, Typography,
} from 'antd';

import { Popover } from '@revfluence/fresh';

import { Button } from '@components';
import { ContentState, SelectionState, Modifier } from 'draft-js';

// eslint-disable-next-line
const URL_REGEX = new RegExp(/https?:\/\/.+/);
const { Text } = Typography;
const {
 useState, useMemo, useCallback, useEffect,
} = React;

import styles from './Link.module.scss';

interface IProps {
  decoratedText: string;

  start: number;
  end: number;
  entityKey: string;
  blockKey: string;
  contentState: ContentState;

  updateEntityData(newContentState: ContentState): void;
  setEditorDisabled(disabled: boolean): void;

  className?: string;
}

/**
 * @type {React.FC}
 */
export const Link: React.FC<IProps> = React.memo((props) => {
  const {
    start,
    end,
    contentState,
    entityKey,
    blockKey,
    decoratedText,
    setEditorDisabled,
    updateEntityData,
  } = props;
  const { src } = contentState.getEntity(entityKey).getData();
  const [linkSrc, setLinkSrc] = useState(src);
  const [linkText, setLinkText] = useState(decoratedText);
  const [isEditing, setIsEditing] = useState(isEmpty(linkSrc));
  const [popoverVisible, setPopoverVisible] = useState(null);
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => setLinkText(decoratedText), [decoratedText, setLinkText]);

  useEffect(() => {
    if (isEmpty(src)) {
      setPopoverVisible(true);
      setClicked(true);
    } else {
      setPopoverVisible(false);
    }
  }, [src, setPopoverVisible]);

  // the link is triggering a double click with one click.
  // only from certain areas where composer is embedded
  // this will prevent the second click from happening
  // through a flag (clicked) that switch true fast upon rendering Link
  // then we turn it off here after having blocked the double click event
  useEffect(() => {
    setTimeout(() => {
      setClicked(false);
    }, 500);
  }, [setClicked]);

  // const oldLinkValid = useMemo(() => URL_REGEX.test(src), [src]);
  const newLinkValid = useMemo(() => URL_REGEX.test(linkSrc), [linkSrc]);

  const inputInvalid = useMemo(() => (
    isEmpty(linkText) || !newLinkValid
  ), [linkText, newLinkValid]);

  const inputInvalidMessage = isEmpty(linkText) ? 'Link text cannot be empty.'
      : !newLinkValid ? 'Link URL is invalid.' : null;

  const unlink = useCallback(() => {
    // need to wait until editor is enabled.
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO: Fix in Node upgrade typing bash!
      const selectionState = new SelectionState({
        anchorKey: blockKey,
        focusKey: blockKey,
        anchorOffset: start,
        focusOffset: end,
      });

      const newContentState = Modifier.applyEntity(contentState, selectionState, null);

      updateEntityData(newContentState);
    }, 0);
  }, [start, end, blockKey, contentState, updateEntityData]);

  const updateLink = useCallback(() => {
    if (inputInvalid) {
      return;
    }
    setPopoverVisible(false);
    setIsEditing(false);
    setEditorDisabled(false);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TODO: Fix in Node upgrade typing bash!
    const selectionState = new SelectionState({
      anchorKey: blockKey,
      focusKey: blockKey,
      anchorOffset: start,
      focusOffset: end,
    });

    const contentWithNewSrc = contentState.mergeEntityData(entityKey, { src: linkSrc });
    const contentWithNewText = Modifier.replaceText(
      contentWithNewSrc,
      selectionState,
      linkText,
      null,
      entityKey,
    );

    updateEntityData(contentWithNewText);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blockKey,
    contentState,
    start,
    end,
    entityKey,
    updateEntityData,
    linkSrc,
    linkText,
    inputInvalid,
    setPopoverVisible,
    setIsEditing,
    setEditorDisabled,
  ]);

  const PopoverContent = useMemo(() => {
    if (popoverVisible === null) return;

    if (isEditing) {
      return (
        <div className={styles.editLink}>
          <div className={styles.section}>
            <div className={styles.title}>Enter URL:</div>
            <Input
              className={styles.input}
              autoFocus
              placeholder="Enter link URL"
              value={linkSrc}
              onChange={(event) => setLinkSrc(event.target.value)}
              onPressEnter={updateLink}
              onFocus={() => setEditorDisabled(true)}
              onBlur={() => setEditorDisabled(false)}
            />
            <div className={styles.hint}>
              Please enter a valid link starting with http:// or https://
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.title}>Text to display:</div>
            <Input
              className={styles.input}
              placeholder="Enter link text"
              value={linkText}
              onChange={(event) => setLinkText(event.target.value)}
              onPressEnter={updateLink}
              onFocus={() => setEditorDisabled(true)}
              onBlur={() => setEditorDisabled(false)}
            />
          </div>
          <div className={styles.editActions}>
            <Button
              className={styles.cancel}
              theme="info"
              onClick={unlink}
              label="Remove Link"
            />
            <Tooltip
              title={inputInvalidMessage}
              overlayStyle={{
                display: inputInvalid ? undefined : 'none',
              }}
            >
              <Button
                disabled={inputInvalid}
                className={styles.button}
                theme="primary"
                onClick={updateLink}
                label="Done"
              />
            </Tooltip>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.actions}>
        <div
          className={styles.link}
          onClick={() => window.open(src, '_blank')}
        >
          Go to link:
          <Text ellipsis className={styles.linkValue}>{src}</Text>
        </div>
        <div
          className={styles.action}
          onClick={() => setIsEditing(true)}
        >
          Change
        </div>
        <div className={styles.action} onClick={unlink}>Remove</div>
      </div>
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    popoverVisible,
    setEditorDisabled,
    updateLink,
    unlink,
    src,
    linkSrc,
    setLinkSrc,
    linkText,
    setLinkText,
    isEditing,
    setIsEditing,
    inputInvalid,
    inputInvalidMessage,
  ]);

  return (
    <Popover
      overlayClassName={styles.Popover}
      content={PopoverContent}
      trigger="click"
      open={popoverVisible}
      onOpenChange={(visible) => {
        if (clicked && !visible) {
          return;
        }

        setPopoverVisible(visible);
        if (!visible) {
          setLinkSrc(src);
          setLinkText(decoratedText);
          setEditorDisabled(false);

          if (!isEmpty(src)) {
            setIsEditing(false);
          }
          if (inputInvalid) {
            unlink();
          }
        }
      }}
    >
      <span className={cx(styles.Link, props.className)}>
        {props.children}
      </span>
    </Popover>
  );
});
