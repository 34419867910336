import * as React from 'react';
import { noop } from 'lodash';

import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Space,
  Tag,
  Typography,
} from '@revfluence/fresh';
import { LazyImage, Video } from '@components';

import { TContentSubmittedCard } from '../types';

export const ContentSubmittedCard = ({
  title,
  icon,
  description,
  status,
  media,
  canTakeAction,
  onReviewClick = noop,
  promotedToGCR,
  canPromoteToGCR,
  reUploadedContent,
}: TContentSubmittedCard) => (
  <Card>
    <Row gutter={16} align="middle">
      <Col>
        <Avatar
          size={56}
          shape="square"
          icon={(
            <>
              {media.type === 'image' && (
                <LazyImage src={media.url} />
              )}
              {media.type === 'video' && (
                <Video src={media.url} />
              )}
            </>
          )}
        />
      </Col>
      <Col flex={1}>
        <Space direction="vertical" size={2}>
          <Typography.Text strong noMargin>
            {icon}
            {' '}
            {title}
          </Typography.Text>
          <Typography.Text type="secondary" noMargin>
            {description}
          </Typography.Text>
          <Tag icon={status.icon} color={status.color}>
            {status.text}
          </Tag>
        </Space>
      </Col>
      {canTakeAction && (
        <Col>
          {promotedToGCR && (
            <>
              {reUploadedContent && (
                <Button
                  type="primary"
                  onClick={onReviewClick}
                >
                  Review Content
                </Button>
              )}
              {!reUploadedContent && (
                <Button
                  type="primary"
                  onClick={onReviewClick}
                >
                  Group Content Review
                </Button>
              )}
            </>
          )}
          {!promotedToGCR && (
            <Button
              type="primary"
              disabled={!canPromoteToGCR}
              onClick={onReviewClick}
            >
              Review Content
            </Button>
          )}
        </Col>
      )}
    </Row>
  </Card>
);
