import * as React from 'react';
import cx from 'classnames';

import {
 endpoints, POST_TYPE_NAME, NETWORK_NAME, genericAddEventLog, Notice, Table, LoadSpinner,
} from '@components';

import { isNumber, map, toNumber } from 'lodash';

import { ErrorBoundary } from '@frontend/utils';
import { getColumns, GroupBySetting } from './columns';
import { useFetchBreakdownData } from '../useFetchAnalyzeData';

import { useAnalyze } from '../useAnalyze';

const { useState, useCallback, useMemo } = React;

import styles from './BreakdownView.scss';
import { useLocation } from 'react-router-dom';
import { useClientFeatureEnabled } from '@frontend/app/hooks';
import { ClientFeature } from '@frontend/app/constants';

interface IProps {
  className?: string;
  useReportsV2?: boolean;
}

interface IBreakdownRow {
  post_type: string;
  tmv: string;
  reach: string;
  likes: string;
  comments: string;
  views: string;
  clicks: string;
  post_count: string;
  sales_amount: string;
  sales: string;
  link: string;
  image_url: string;
  creator: string;
  last_posted: string;
  datetime_posted: string;
  datetime_modified: string;
  engagements: string;
  engagement_rate: string;
  unique_engagement_rate: string;
  member_id: string;
  social_account_name: string;
  social_account_link: string;
  country_code: string;
  brand_name: string;
  brands: string;
  campaign_name: string;
  campaigns: string;
  network: string;
  networks: [string];
  creator_count: string;
  cost: string;
  product_cost: string;
  owners: [string];
  coupons: [string];
  favorites: string;
  shares: string;
  closeups: string;
  roi_pct: string;
  impressions: string;
  estimated_impressions?: string;
  unique_impressions: string;
  cost_per_engagement: string;
  impression_cpm: string;
  taps: string;
  exits: string;
}

interface ISocialBreakdownRow {
  post_type: string;
  image_url: string;
  link: string;
  text: string;
  member_id: string;
  social_account_name: string;
  social_account_link: string;
  country_code: string;
  network: string;
  datetime_posted: string;
  datetime_modified: string;
  estimated_closeups: string;
  authed_comments: string;
  estimated_comments: string;
  authed_completion_rate: string;
  estimated_dislikes: string;
  authed_engagements: string;
  estimated_engagements: string;
  authed_engagement_rate: string;
  estimated_engagement_rate: string;
  authed_impressions: string;
  estimated_impressions: string;
  ig_story_estimated_impressions: string;
  authed_likes: string;
  estimated_likes: string;
  authed_reach: string;
  estimated_reach: string;
  authed_replays: string;
  authed_replies: string;
  authed_saves: string;
  estimated_saves: string;
  authed_shares: string;
  estimated_shares: string;
  authed_six_second_views: string;
  authed_started_following: string;
  authed_swipe_forwards: string;
  authed_tap_backs: string;
  authed_tap_exits: string;
  estimated_exits: string;
  authed_tap_forwards: string;
  authed_taps: string;
  estimated_taps: string;
  authed_two_second_views: string;
  authed_unique_views: string;
  authed_views: string;
  estimated_views: string;
  authed_organic_views: string;
  authed_paid_views: string;
  authed_view_time_avg_seconds: string;
  authed_view_time_total_seconds: string;
  authed_mention_clicks: string;
  authed_cost: string;
  authed_product_cost: string;
  authed_sales: string;
  authed_sales_amount: string;
  authed_tmv: string;
  authed_cpm_cost: string;
  authed_cpm_product_cost: string;
}

interface IBreakdownResponse {
  total_count: number;
  data: IBreakdownRow[];
}

interface ISocialBreakdownResponse {
  total_count: number;
  data: ISocialBreakdownRow[];
}

interface IBreakdownParameters {
  groupBy: string;
  sortBy: string;
}

interface IBreakdownControlButtonProps {
  id: GroupBySetting;
  displayName: string;
  displayNamePlural: string;
}

const frontEndToBackendGroupMapping = {
  [GroupBySetting.post]: 'post',
  [GroupBySetting.creator]: 'creator',
  [GroupBySetting.network]: 'network',
  [GroupBySetting.aspirexPost]: 'post',
  [GroupBySetting.aspirexNetwork]: 'network',
  [GroupBySetting.member]: 'member',
};

const parseFloatNumber = (n: string, decimals = 2) => {
  const parsedN = parseFloat(n);
  if (isNumber(parsedN)) {
    return toNumber(parsedN.toFixed(decimals));
  }
  return 0;
};

/**
 * @type {React.FunctionComponent}
 */
const BreakdownView: React.FunctionComponent<IProps> = React.memo((props) => {
  const {
    className,
    useReportsV2,
  } = props;
  // Set up Constants
  const defaultSortOrder = '-date_posted';
  const emptyMessage = 'You have no data that matches those filters.';

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const breakdownGroupBy = GroupBySetting[(searchParams.get('breakdownGroupBy'))];
  const {
    filters, apiEndpoint, showCostMetrics, aspirexAnalytics,
  } = useAnalyze();
  const [groupBy, setGroupBy] = useState<GroupBySetting>(breakdownGroupBy || GroupBySetting.aspirexPost);
  const [rowDisplayName, setRowDisplayName] = useState<string>(breakdownGroupBy || 'Posts');
  const initialColumnSort = groupBy === GroupBySetting.member && breakdownGroupBy === GroupBySetting.member
    ? 'engagement_pct'
    : 'engagements';

  // Set up state + Prettier and eslint are fighting.
  /* eslint-disable */

  const [breakdownParameters, setBreakdownParameters] = useState<
    IBreakdownParameters
  >({
    groupBy: groupBy,
    sortBy: defaultSortOrder,
  });
  /* eslint-enable */

  // Front-end has different identifiers for grouping in order to allow
  // different columns to show even though the group by is actually the same
  // to backend

  const endpoint = useReportsV2 ? endpoints.mergedSocialReportsEndpoint : endpoints.reportsEndpoint;

  const { loading: loadingRequest, data, error } = useFetchBreakdownData<ISocialBreakdownResponse | IBreakdownResponse>(
    `${apiEndpoint}/${endpoint}/breakdown`,
    {
      ...breakdownParameters,
      groupBy: frontEndToBackendGroupMapping[breakdownParameters.groupBy],
    },
    showCostMetrics,
    filters,
  );

  const igEstimatedImpressionsFeatureFlag = useClientFeatureEnabled(ClientFeature.IG_ESTIMATED_IMPRESSIONS);

  const loading = useMemo(() => loadingRequest || igEstimatedImpressionsFeatureFlag === undefined || useReportsV2 === undefined, [igEstimatedImpressionsFeatureFlag, loadingRequest, useReportsV2]);

  const columns = useMemo(() => {
    const version = useReportsV2 ? 'v2' : 'v1';
    if (igEstimatedImpressionsFeatureFlag || igEstimatedImpressionsFeatureFlag === undefined) {
      return getColumns(groupBy, showCostMetrics, version);
    } else {
      return getColumns(groupBy, showCostMetrics, version).filter((column) => column.field !== 'estimated_impressions');
    }
  }, [groupBy, showCostMetrics, igEstimatedImpressionsFeatureFlag, useReportsV2]);


  const onGroupBySelected = useCallback(
    (newGroupBy: GroupBySetting, displayName: string) => {
      setBreakdownParameters({ ...breakdownParameters, groupBy: newGroupBy });
      setGroupBy(newGroupBy);
      setRowDisplayName(displayName);
      genericAddEventLog(
        'analyze_breakdown_group_by',
        {
          previous_group_by: groupBy,
          current_group_by: newGroupBy,
        },
        aspirexAnalytics,
      );
    },
    [aspirexAnalytics, breakdownParameters, groupBy],
  );

  /**
   * Massage data into state expected by Table
   */
  function getTableData(data) {
    return map(data.data, (breakdownRow, index) => ({
      id: index.toString(),
      _raw: breakdownRow,
      post_type: POST_TYPE_NAME[breakdownRow.post_type],
      tmv: parseFloatNumber(breakdownRow.tmv),
      reach: parseInt(breakdownRow.reach, 10),
      likes: parseInt(breakdownRow.likes, 10),
      views: parseInt(breakdownRow.views, 10),
      clicks: parseInt(breakdownRow.clicks, 10),
      post_count: parseInt(breakdownRow.post_count, 10),
      comments: parseInt(breakdownRow.comments, 10),
      engagement_pct: parseFloat(breakdownRow.engagement_rate),
      sales: parseInt(breakdownRow.sales, 10),
      sales_amount: parseFloat(breakdownRow.sales_amount),
      post_link: breakdownRow.link,
      media: [breakdownRow.image_url],
      creator: breakdownRow.creator,
      last_posted: breakdownRow.last_posted,
      datetime_posted: breakdownRow.datetime_posted,
      datetime_modified: breakdownRow.datetime_modified,
      engagement_rate: parseFloat(breakdownRow.engagement_rate),
      engagements: parseInt(breakdownRow.engagements, 10),
      member_link: `${window.location.origin}/client/${filters.clientId}/members/${breakdownRow.member_id}`,
      social_account_name: breakdownRow.social_account_name,
      social_account_link: breakdownRow.social_account_link,
      country_code: breakdownRow.country_code,
      brand_name: breakdownRow.brand_name,
      brands: breakdownRow.brands,
      campaign_name: breakdownRow.campaign_name,
      campaigns: breakdownRow.campaigns,
      network: NETWORK_NAME[breakdownRow.network],
      networks: `${ breakdownRow.networks ? map(breakdownRow.networks, (network) => NETWORK_NAME[network]).join(', ') : '' }`,
      creator_count: parseInt(breakdownRow.creator_count, 10),
      cost: parseFloat(breakdownRow.cost),
      product_cost: parseFloat(breakdownRow.product_cost),
      owners: `${breakdownRow.owners ? breakdownRow.owners.join(', ') : ''}`,
      coupons: `${breakdownRow.coupons ? breakdownRow.coupons.join(', ') : ''}`,
      favorites: parseInt(breakdownRow.favorites, 10),
      shares: parseInt(breakdownRow.shares, 10),
      closeups: parseInt(breakdownRow.closeups, 10),
      ...(showCostMetrics && {
        roi_pct: parseFloat(breakdownRow.roi_pct) / 100,
        impression_cpm: parseFloat(breakdownRow.impression_cpm),
        cost_per_engagement: parseFloat(breakdownRow.cost_per_engagement),
      }),
      impressions: parseInt(breakdownRow.impressions, 10),
      unique_impressions: parseInt(breakdownRow.unique_impressions, 10),
      estimated_impressions: parseInt(breakdownRow.estimated_impressions, 10),
      unique_engagement_rate: parseFloat(breakdownRow.unique_engagement_rate),
      taps: parseInt(breakdownRow.taps, 10),
      exits: parseInt(breakdownRow.exits, 10),
      text: breakdownRow.text,
    }));
  }

  function getMergedSocialTableData(data) {
    return map(data.data, (breakdownRow, index) => ({
      id: index.toString(),
      _raw: breakdownRow,
      post_type: POST_TYPE_NAME[breakdownRow.post_type],
      network: NETWORK_NAME[breakdownRow.network],
      networks: `${ breakdownRow.networks ? map(breakdownRow.networks, (network) => NETWORK_NAME[network]).join(', ') : '' }`,
      media: [breakdownRow.image_url],
      post_link: breakdownRow.link,
      text: breakdownRow.text,
      member_link: `${window.location.origin}/client/${filters.clientId}/members/${breakdownRow.member_id}`,
      social_account_name: breakdownRow.social_account_name,
      social_account_link: breakdownRow.social_account_link,
      country_code: breakdownRow.country_code,
      datetime_posted: breakdownRow.datetime_posted,
      datetime_modified: breakdownRow.datetime_modified,
      estimated_closeups: parseInt(breakdownRow.estimated_closeups, 10),
      authed_comments: parseInt(breakdownRow.authed_comments, 10),
      estimated_comments: parseInt(breakdownRow.estimated_comments, 10),
      authed_completion_rate: parseFloat(breakdownRow.authed_completion_rate),
      estimated_dislikes: parseInt(breakdownRow.estimated_dislikes, 10),
      authed_engagements: parseInt(breakdownRow.authed_engagements, 10),
      estimated_engagements: parseInt(breakdownRow.estimated_engagements, 10),
      authed_engagement_rate: parseFloat(breakdownRow.authed_engagement_rate),
      estimated_engagement_rate: parseFloat(breakdownRow.estimated_engagement_rate),
      authed_impressions: parseInt(breakdownRow.authed_impressions, 10),
      ig_story_estimated_impressions: parseInt(breakdownRow.ig_story_estimated_impressions, 10),
      authed_likes: parseInt(breakdownRow.authed_likes, 10),
      estimated_likes: parseInt(breakdownRow.estimated_likes, 10),
      authed_reach: parseInt(breakdownRow.authed_reach, 10),
      estimated_reach: parseInt(breakdownRow.estimated_reach, 10),
      authed_replays: parseInt(breakdownRow.authed_replays, 10),
      authed_replies: parseInt(breakdownRow.authed_replies, 10),
      authed_saves: parseInt(breakdownRow.authed_saves, 10),
      estimated_saves: parseInt(breakdownRow.estimated_saves, 10),
      authed_shares: parseInt(breakdownRow.authed_shares, 10),
      estimated_shares: parseInt(breakdownRow.estimated_shares, 10),
      authed_six_second_views: parseInt(breakdownRow.authed_six_second_views, 10),
      authed_started_following: parseInt(breakdownRow.authed_started_following, 10),
      authed_swipe_forwards: parseInt(breakdownRow.authed_swipe_forwards, 10),
      authed_tap_backs: parseInt(breakdownRow.authed_tap_backs, 10),
      authed_tap_exits: parseInt(breakdownRow.authed_tap_exits, 10),
      estimated_exits: parseInt(breakdownRow.estimated_exits, 10),
      authed_tap_forwards: parseInt(breakdownRow.authed_tap_forwards, 10),
      authed_taps: parseInt(breakdownRow.authed_taps, 10),
      estimated_taps: parseInt(breakdownRow.estimated_taps, 10),
      authed_two_second_views: parseInt(breakdownRow.authed_two_second_views, 10),
      authed_unique_views: parseInt(breakdownRow.authed_unique_views, 10),
      authed_views: parseInt(breakdownRow.authed_views, 10),
      estimated_views: parseInt(breakdownRow.estimated_views, 10),
      authed_organic_views: parseInt(breakdownRow.authed_organic_views, 10),
      authed_paid_views: parseInt(breakdownRow.authed_paid_views, 10),
      authed_view_time_avg_seconds: parseFloat(breakdownRow.authed_view_time_avg_seconds),
      authed_view_time_total_seconds: parseFloat(breakdownRow.authed_view_time_total_seconds),
      authed_mention_clicks: parseInt(breakdownRow.authed_mention_clicks, 10),
      authed_cost: parseFloat(breakdownRow.authed_cost),
      authed_product_cost: parseFloat(breakdownRow.authed_product_cost),
      authed_sales: parseInt(breakdownRow.authed_sales, 10),
      authed_sales_amount: parseFloat(breakdownRow.authed_sales_amount),
      authed_tmv: parseFloatNumber(breakdownRow.authed_tmv),
      authed_cpm_cost: parseFloat(breakdownRow.authed_cpm_cost),
      authed_cpm_product_cost: parseFloat(breakdownRow.authed_cpm_product_cost),
    }));
  }

  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    if (useReportsV2) {
      return getMergedSocialTableData(data);
    }
    return getTableData(data);
  }, [data, useReportsV2]);

  /**
   * Handle the group by control actions
   */
  const buttons: IBreakdownControlButtonProps[] = [
    {
      id: GroupBySetting.aspirexPost,
      displayName: 'Post',
      displayNamePlural: 'Posts',
    },
    {
      id: GroupBySetting.member,
      displayName: 'Creator',
      displayNamePlural: 'Creators',
    },
    {
      id: GroupBySetting.aspirexNetwork,
      displayName: 'Network',
      displayNamePlural: 'Networks',
    },
  ];

  /**
   * Actually renders the actions.
   *
   * @return {JSX.Element}
   */
  function renderActions(groupBy): JSX.Element {
    return (
      <div className={cx(className)}>
        <span className={styles.GroupByLabel}>Grouping by</span>
        {map(buttons, (buttonData) => (
          <button
            className={cx(
              styles.GroupByButton,
              groupBy === buttonData.id ? styles.active : '',
            )}
            key={buttonData.id}
            onClick={() =>
              onGroupBySelected(buttonData.id, buttonData.displayNamePlural)}
          >
            {buttonData.displayName}
          </button>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <Notice className={(styles as any).notice} type="error">
        There is an error when trying to fetch your breakdowns. Please try
        refreshing and contact help@aspireiq.com if the problem continues.
      </Notice>
    );
  }

  const exportCallback = (exportOptions) => {
    genericAddEventLog(
      'analyze_breakdown_export',
      {
        export_columns: exportOptions.headers,
        current_group_by: groupBy,
      },
      aspirexAnalytics,
    );
  };

  return (
    <ErrorBoundary>
      {useReportsV2 && <Notice type="info">Notice: Using Reports V2</Notice>}
      <div className={cx(styles.BreakdownView, className)}>
        {!loading && (
          <Table
            initialColumnSort={{ [initialColumnSort]: 'DESC' }}
            ref={null}
            data={tableData}
            columns={columns}
            disabled={false}
            emptyMessage={emptyMessage}
            paddingBottom={0}
            rowDisplayName={rowDisplayName}
            headerActions={renderActions(groupBy)}
            config={{
              allowSearch: true,
              configurableColumns: true,
              rowHeight: 58,
              pageSize: 20,
              striped: true,
              rowBorder: true,
              selectable: false,
            }}
            exportable
            onExportCallback={exportCallback}
          />
        )}
        {loading && <LoadSpinner centered />}
      </div>
    </ErrorBoundary>
  );
});

export default BreakdownView;
