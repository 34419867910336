import { useCallback, useState } from 'react';
import { isEmpty, map, size } from 'lodash';
import { ApolloError } from 'apollo-client';
import { useMutation } from '@apollo/react-hooks';
import {
  useSaveMemberMutation,
  useAddMembersToActivationsMutation,
  useAddMembersToTagsMutation,
  useModifyCommunityMembersMutation,
  useModifyProgramMembersMutation,
  useMarkMembersAsImportantMutation,
  useAssignOwnersToMembersMutation,
} from '@frontend/app/hooks';

import { IActivation, ITag } from '@frontend/app/hooks';
import { MemberInput } from '@frontend/app/types/globalTypes';
import {
  SaveMemberMutation_member as IMember,
} from '@frontend/app/queries/types/SaveMemberMutation';
import {
  MemberProgramsAndCommunitiesQuery_member_communities as ICommunity,
  MemberProgramsAndCommunitiesQuery_member_programs as IProgram,
} from '@frontend/app/queries/types/MemberProgramsAndCommunitiesQuery';
import { UpsertMemberAgent, UpsertMemberAgentVariables } from '@frontend/app/queries/types/UpsertMemberAgent';
import { UPSERT_MEMBER_AGENT } from '@frontend/app/queries/UpsertMemberAgent';

import { useRefetchSegments } from '@frontend/app/containers/Members/hooks';

interface ISaveMemberInput {
  member: MemberInput;
  activations: IActivation[];
  tags: ITag[];
  communities: ICommunity[];
  programs: IProgram[];
  isImportant: boolean;
  agents: UpsertMemberAgentVariables[];
  ownerIds: string[];
}

interface IOptions {
  onError?(err: ApolloError);
  onSuccess?(member: IMember);
}

export const useSaveMember = (options: IOptions = {}) => {
  const [error, setError] = useState<ApolloError>(null);
  const { refetchSegments } = useRefetchSegments({
    isContact: true,
  });
  const handleError = useCallback((err: ApolloError) => {
    if (!error) {
      setError(err);

      if (options.onError) {
        options.onError(err);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, setError, options.onError]);

  const [saveMember] = useSaveMemberMutation({
    onError: handleError,
  });

  const [saveActivations] = useAddMembersToActivationsMutation({
    onError: handleError,
  });

  const [saveTags] = useAddMembersToTagsMutation({
    onError: handleError,
  });

  const [saveCommunities] = useModifyCommunityMembersMutation('add', {
    onError: handleError,
  });

  const [savePrograms] = useModifyProgramMembersMutation('add', {
    onError: handleError,
  });

  const [saveOwners] = useAssignOwnersToMembersMutation({
    onError: handleError,
  });

  const [saveIsImportant] = useMarkMembersAsImportantMutation({
    onError: handleError,
  });
  const [upsertMemberAgent] = useMutation<UpsertMemberAgent, UpsertMemberAgentVariables>(UPSERT_MEMBER_AGENT);

  const saveOwnersFn = useCallback((memberIds: number[], ownerIds?: string[]) => {
    if (isEmpty(ownerIds)) {
      return;
    }

    return saveOwners({
      variables: {
        memberIds,
        userIds: ownerIds,
      },
    });
  }, [saveOwners]);

  return useCallback(async (memberInput: ISaveMemberInput) => {
    setError(null);

    const result = await saveMember({
      variables: {
        member: memberInput.member,
      },
    });

    if (!result.data) {
      // Its an error.
      return;
    }

    await Promise.allSettled([
      size(memberInput.activations) ? saveActivations({
        variables: {
          memberIds: [result.data.member.id],
          activationIds: map(memberInput.activations, ({ id }) => id),
        },
      }) : undefined,

      size(memberInput.tags) ? saveTags({
        variables: {
          memberIds: [result.data.member.id],
          tagIds: map(memberInput.tags, ({ id }) => id),
        },
      }) : undefined,

      size(memberInput.communities) ? saveCommunities({
        variables: {
          memberIds: [result.data.member.id],
          communityIds: map(memberInput.communities, ({ id }) => id),
        },
      }) : undefined,

      size(memberInput.programs) ? savePrograms({
        variables: {
          memberIds: [result.data.member.id],
          programIds: map(memberInput.programs, ({ id }) => id),
          status: 'approved',
        },
      }) : undefined,

      size(memberInput.agents) ? Promise.allSettled(map(memberInput.agents, (agent) => agent && upsertMemberAgent({
        variables: {
          params: {
            ...agent.params,
            ...{
              memberId: result.data.member.id,
            },
          },
        },
      }))) : undefined,

      saveOwnersFn([result.data.member.id], memberInput.ownerIds),

      memberInput.isImportant ? saveIsImportant({
        variables: {
          memberIds: [result.data.member.id],
          isImportant: memberInput.isImportant,
        },
      }) : undefined,
    ]);

    await refetchSegments();

    if (options.onSuccess) {
      options.onSuccess(result.data.member);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.onSuccess, setError, saveMember, saveActivations, saveCommunities, savePrograms, saveOwners, saveIsImportant]);
};
