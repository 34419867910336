import * as React from 'react';
import { TActions, TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import { useGetData } from '@frontend/applications/TermsApp/hooks/useGetData';
import { IAgreement } from '@frontend/applications/TermsApp/types/IAgreement';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import {
  useCommunitiesQuery, useGetMemberQuery, useMemberFieldSchemasQuery, useProgramsQuery,
} from '@frontend/app/hooks';
import { AssignPaymentTo, PaymentCreationSource, TPaymentHistory } from '@frontend/applications/PaymentsApp/types';
import { find } from 'lodash';
import { PAYPAL_APP_FIELD } from '@frontend/applications/PaymentsApp/appFields';
import { useGetPaymenstHistoryWithBudgetLog } from '@frontend/app/hooks/budgetAllocation/useGetPaymenstHistoryWithBudgetLog';
import { Spinner } from '@revfluence/fresh';
import { PaymentHistory } from '../common/PaymentHistory';
import { BriefsDue } from '../common/BriefsDue';
import { StepHeader } from '../common/StepHeader';
import styles from './PaymentsOverview.scss';

const { useEffect, useMemo } = React;
interface IProps {
  actions: TActions;
  state: TState;
}

export const PaymentsOverview: React.FC<IProps> = (props) => {
  const { actions, state } = props;
  const {
    backendServerApiEndpoint,
    memberId,
    workflowActionParameters,
  } = useApplication();

  const {
    loading: communityLoading,
    data: communityData,
  } = useCommunitiesQuery({ skip: false });
  const { data: terms = [], loading: isTermsLoading } = useGetData<IAgreement[]>(
    `${backendServerApiEndpoint}/agreements?member_id=${state.memberId}`,
  );
  const { data: programs, loading: programsLoading } = useProgramsQuery();
  const { paymentsLog, loading: paymentsHistoryLoading } = useGetPaymenstHistoryWithBudgetLog({
    variables: {
      memberId: parseInt(state.memberId, 10),
    },
  });
  const {
    loading: memberLoading,
    data: memberData,
  } = useGetMemberQuery(Number(memberId), {
    fetchPolicy: 'no-cache',
  });

  const {
    data: {
      schemas = null,
    } = {},
    loading: schemasLoading,
    // error: getMemberFieldError,
  } = useMemberFieldSchemasQuery({
    /**
     * Due to renaming of fields in API layer in a different query that returns the same GraphQL object
     * (memberFieldSchemasBySections) and how Apollo cache works, this query could result in data with non-DB names.
     * Since we use the DB name to find the corresponding PayPal field, always run this query with no-cache.
     */
    fetchPolicy: 'no-cache',
  });

  const paypalFieldId = useMemo(() => {
    if (!memberData || !schemas) {
      return null;
    }

    const paypalField = find(schemas, { name: PAYPAL_APP_FIELD });
    if (!paypalField) {
      return null;
    }

    return paypalField.id;
  }, [memberData, schemas]);

  useEffect(() => {
    if (paypalFieldId) {
      actions.setFieldValue('paypalFieldId', paypalFieldId);
    }
    if (memberData) {
      actions.setMemberInfo({
        id: memberData.member?.id,
        name: memberData.member?.name,
        profileImage: memberData.member.profilePicture,
        paypalAddress: memberData.member.fields[paypalFieldId],
        email: memberData.member.email,
      });
    }
  }, [memberData, paypalFieldId, actions]);

  const payemntsMade: TPaymentHistory[] = useMemo(() => {
    if (paymentsHistoryLoading || !paymentsLog?.paymentHistory?.length) return [];
    return paymentsLog.paymentHistory.map((payment): TPaymentHistory => ({
      key: payment.paymentId.toString(),
      amount: Number((payment.amount / 100).toFixed(2)),
      budgetAccount: payment.budgetAccountNames,
      email: payment?.paypal ? payment.paypal : 'Pending Info',
      paidDate: new Date(parseInt(payment.paidDate, 10) * 1000),
      projects: payment.projectName,
    }));
  }, [paymentsLog, paymentsHistoryLoading]);
  useEffect(() => {
    if (terms && workflowActionParameters?.programId && state.paymentCreationSource === PaymentCreationSource.PROJECT) {
      actions.setTerms(terms.filter((term) => term.status === 'agreed' && !!term.price && workflowActionParameters.programId === term.program_id));
    } else if (terms && state.paymentCreationSource === PaymentCreationSource.MEMBER) {
      actions.setTerms(terms.filter((term) => term.status === 'agreed' && !!term.price));
    } else {
      actions.setTerms([]);
    }
  }, [actions, workflowActionParameters?.programId, terms, state.paymentCreationSource]);
  useEffect(() => {
    if (programs?.programs) {
      actions.setPrograms(programs.programs);
    }
  }, [programs, programsLoading, actions]);
  useEffect(() => {
    if (!communityLoading && communityData?.communities?.length) {
      actions.setGroups(communityData.communities);
    }
  }, [communityLoading, actions, communityData]);
  const isQueryLoading: boolean = isTermsLoading || schemasLoading || memberLoading || programsLoading || communityLoading;
  useEffect(() => {
    if (workflowActionParameters?.programId) {
      actions.setFieldValue('selectedProgramId', workflowActionParameters.programId);
    }
  }, [workflowActionParameters, actions]);
  useEffect(() => {
    if (state.paymentCreationSource === PaymentCreationSource.PROJECT || state.paymentCreationSource === PaymentCreationSource.REASSIGN || state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING) {
      actions.setAssignPaymentTo(AssignPaymentTo.Project);
    } else {
      actions.setAssignPaymentTo(AssignPaymentTo.Other);
    }
  }, [state.paymentCreationSource, actions]);
  if (!state?.memberInfo || isQueryLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <Spinner size="large" />
      </div>
    );
  }

  return (
    <div className={styles.paymentsOverviewContainer}>
      <StepHeader
        name={state.memberInfo?.name}
        profileImage={state.memberInfo?.profileImage}
        action={() => actions.setCurrentStep(2)}
        actionText="Create New Payment"
      />
      {
        !!state?.terms?.length && (
          <BriefsDue
            terms={state?.terms}
            programs={state?.programs}
            actions={actions}
          />
        )
      }
      <div className={styles.space48} />
      <PaymentHistory
        payemntsMade={payemntsMade}
      />
    </div>
  );
};
