import * as React from 'react';
import { isNull, isUndefined } from 'lodash';
import { QueryHookOptions, useQuery } from '@apollo/client';

import { ClientFeature } from '@frontend/app/constants';
import { IS_CLIENT_FEATURE_ENABLED } from '../queries';
import { IsClientFeatureEnabled, IsClientFeatureEnabledVariables } from '../queries/types/IsClientFeatureEnabled';

const { useMemo } = React;

type IOptions = QueryHookOptions<IsClientFeatureEnabled, IsClientFeatureEnabledVariables>;

export const useClientFeatureEnabled = (name: ClientFeature, options?: IOptions): boolean => {
  const {
    loading,
    data: { isClientFeatureEnabled } = {},
  } = useQuery<IsClientFeatureEnabled>(
    IS_CLIENT_FEATURE_ENABLED,
    {
      ...options,
      variables: {
        name,
      },
      skip: isNull(name) || options?.skip,
    },
  );

  return useMemo((): boolean => {
    if (loading || isUndefined(isClientFeatureEnabled)) {
      return undefined;
    }
    return isClientFeatureEnabled;
  }, [isClientFeatureEnabled, loading]);
};
