import 'css-chunk:src/app/components/ContentReviewTable/components/Table/Table.module.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_wn47v_157",
  "justify-content-space-between": "_justify-content-space-between_wn47v_161",
  "tabular-nums": "_tabular-nums_wn47v_165",
  "table": "_table_wn47v_178",
  "deliverables": "_deliverables_wn47v_205",
  "status": "_status_wn47v_211",
  "ContentCell": "_ContentCell_wn47v_218",
  "icon": "_icon_wn47v_223",
  "link": "_link_wn47v_226",
  "OverlayContent": "_OverlayContent_wn47v_230",
  "image": "_image_wn47v_239",
  "video": "_video_wn47v_239",
  "nameColumn": "_nameColumn_wn47v_246",
  "expandButton": "_expandButton_wn47v_253",
  "show": "_show_wn47v_1"
};