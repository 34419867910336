/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1p3p8_157 {
  display: flex;
}

._justify-content-space-between_1p3p8_161 {
  justify-content: space-between;
}

._tabular-nums_1p3p8_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1p3p8_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SendEmailForm_1p3p8_178 ._info_1p3p8_178 ._memberCount_1p3p8_178 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._SendEmailForm_1p3p8_178 {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 80vh;
}
._SendEmailForm_1p3p8_178 > * {
  overflow: scroll;
}
._SendEmailForm_1p3p8_178._fullHeight_1p3p8_227 {
  height: 100%;
}
._SendEmailForm_1p3p8_178 ._info_1p3p8_178 {
  padding: 1rem 0.5rem 1rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  overflow: hidden;
}
._SendEmailForm_1p3p8_178 ._info_1p3p8_178._noTopBorder_1p3p8_239 {
  border-top: none;
}
._SendEmailForm_1p3p8_178 ._info_1p3p8_178._cursor_1p3p8_242 {
  cursor: pointer;
}
._SendEmailForm_1p3p8_178 ._info_1p3p8_178 ._validMember_1p3p8_245,
._SendEmailForm_1p3p8_178 ._info_1p3p8_178 ._additionalMembers_1p3p8_246 {
  background-color: #f4f5f5;
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
}
._SendEmailForm_1p3p8_178 ._info_1p3p8_178 ._memberCount_1p3p8_178 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 1.5rem;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  background: #e2e2e2;
}
._SendEmailForm_1p3p8_178 ._info_1p3p8_178 ._memberCc_1p3p8_260 {
  position: absolute;
  top: 0.375rem;
  right: 1rem;
  cursor: pointer;
}
._SendEmailForm_1p3p8_178 ._info_1p3p8_178 ._resourceSettingsButton_1p3p8_266 {
  display: inline-flex;
}
._SendEmailForm_1p3p8_178 ._info_1p3p8_178 ._messageTypeSelect_1p3p8_269 {
  flex-shrink: 0;
  width: 7.8125rem;
  margin-left: auto;
}
._SendEmailForm_1p3p8_178 ._editor_1p3p8_274 {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
._SendEmailForm_1p3p8_178 ._formSpace_1p3p8_280 {
  width: 100%;
  flex: 1;
  justify-content: space-between;
}
._SendEmailForm_1p3p8_178._headerForcedClose_1p3p8_285 ._memberInput_1p3p8_285 {
  pointer-events: none;
}
._SendEmailForm_1p3p8_178._headerForcedClose_1p3p8_285 ._memberInput_1p3p8_285 svg {
  display: none;
}

._newThread_1p3p8_292 {
  color: gray;
}

._sharedIcon_1p3p8_296 {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: decimal !important;
  position: inherit !important;
}