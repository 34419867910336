import * as React from 'react';
import cx from 'classnames';
import xss from 'xss';
import { useRenderSocialLinkIcon } from '@frontend/app/containers/Projects/LandingPages/hooks';
import { ApplicationPageBuilderComponent } from '@frontend/app/containers/Projects/applicationPageUtils';
import { ITemplateProps } from '../types';

import styles from './Intro.scss';
import {
  socialLinkFields,
  getNetworkUrl,
} from '../utils/socialLinks';
import { HeroMedia } from '../common/HeroMedia/HeroMedia';
import { defaultIntro } from './constants';
import { RichTextVisualizer } from '../../RichTextEditor/Visualizer';

export type IIntroProps = ITemplateProps['intro'];

interface IAdditionalProps {
  onJoinClick?: () => void,
  isFieldVisible: (fieldName: ApplicationPageBuilderComponent) => boolean,
}

export const Intro: React.FC<IIntroProps & IAdditionalProps> = (props) => {
  const {
    show = true,
    title,
    hero_image,
    hero_is_video,
    description,
    button_text,
    componentStyles,
    onJoinClick,
    isFieldVisible,
  } = props;

  if (!show) {
    return null;
  }

  const renderIcon = useRenderSocialLinkIcon();
  return (
    <div className={cx(styles.Intro)}>
      <h1
        className={styles.title}
        style={componentStyles.heading}
      >
        {title}
      </h1>

      <div className={styles.socialLinkCta}>
        {socialLinkFields.map((field) => (
          props[field.name]
            ? (
              <a
                key={field.name}
                target="_blank"
                href={getNetworkUrl(props[field.name], field.name)}
              >
                {
                renderIcon(
                  field.name,
                  18,
                  componentStyles?.heading?.color || '#fdfdfd',
                )
              }
              </a>
            )
            : null
        ))}
      </div>

      {
        isFieldVisible(ApplicationPageBuilderComponent.IntroHeroImageVideo)
          && (
            <HeroMedia
              heroSrc={hero_image ?? defaultIntro?.hero_image}
              isVideo={hero_is_video ?? false}
              className={styles.heroImage}
            />
          )
      }

      <RichTextVisualizer
        className={styles.description}
        style={componentStyles.body}
      >
        {xss(description)}
      </RichTextVisualizer>

      <button
        type="button"
        className={styles.joinButton}
        onClick={() => onJoinClick?.()}
        style={componentStyles.button}
      >
        {button_text}
      </button>
    </div>
  );
};
