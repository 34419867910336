import * as React from 'react';
import cx from 'classnames';
import { Button, SubmitButton } from '@components';
import { isNil } from 'lodash';

interface IProps {
  numberOfProductsSelected?: number;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  primaryButtonText;
  primaryButtonAction;
  disablePrimaryAction?: boolean;
  disableSecondaryAction?: boolean;
  loading?: boolean;
  className?: string;
}

import styles from './Footer.scss';

export const Footer: React.FunctionComponent<IProps> = (props) => {
  const {
    disablePrimaryAction = false,
    disableSecondaryAction = false,
    numberOfProductsSelected,
    primaryButtonText,
    primaryButtonAction,
    secondaryButtonText,
    secondaryButtonAction,
    loading,
  } = props;
  return (
    <div className={cx(styles.footer, props.className)}>
      <div className={styles.selection}>
        {
          !isNil(numberOfProductsSelected) && numberOfProductsSelected > 0
          && (
          <>
            <span className={styles.badge}>{numberOfProductsSelected}</span>
            {' '}
            products selected
          </>
)
        }
      </div>
      {!!secondaryButtonText
        && <Button disabled={disableSecondaryAction} className={styles.secondaryButton} theme="info" label={secondaryButtonText} onClick={() => secondaryButtonAction()} />}
      <SubmitButton
        disabled={disablePrimaryAction || loading}
        submittingLabel="Creating..."
        isSubmitting={loading}
        className={styles.primaryButton}
        label={primaryButtonText}
        onClick={() => primaryButtonAction()}
      />
    </div>
  );
};
