import * as React from 'react';
import cx from 'classnames';

import { Button, Tooltip, Modal as AntdModal } from '@revfluence/fresh';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { RawDraftContentState } from 'draft-js';
import {
  isArray, isEmpty, isFunction, size,
} from 'lodash';

import { EmailComposerIcon } from '@frontend/app/components';
import { Modal } from '@components';
import { IMember } from '@frontend/app/hooks';
import { useResourceContext } from '@frontend/app/context';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import { XmarkIcon } from '@revfluence/fresh-icons/regular/esm';
import { Space } from '@revfluence/fresh';

import { EmailPreviewer } from './EmailPreviewer';
import { EmailComposer, IEmailComposer, IPreviewConfig } from './EmailComposer';
import { useExcludeMemberIds } from './useExcludeMemberIds';

const {
  useCallback, useState, useEffect, useMemo, useRef,
} = React;

import styles from './EmailComposerModal.scss';

interface IProps {
  label?: string;
  members?: IMember[];
  allowSendingAsSeparate?: boolean;
  initialMessage?: string | RawDraftContentState;
  memberQuery?: IMemberSearchQuery;
  memberCount?: number;
  className?: string;
  source?: string;
  customButton?: (onClick: () => void) => React.ReactElement;
  isOpen?: boolean;
  /** @deprecated */
  isWorkflowEnabled?: boolean;
  disabled?: boolean;
  openComposer?: () => void;
  subject?: string;
  onMessageSent?: () => void;
  onClose?: () => void;
  hideLabel?: boolean;
  icon?: React.ReactElement;
  isSourceEditOffer?: boolean;
  hideComposerButton?: boolean;
  enableExpandingEmailEditor?: boolean;
}

/**
 * @type {React.FunctionComponent}
 */
export const EmailComposerModal: React.FunctionComponent<IProps> = React.memo(({
  allowSendingAsSeparate,
  className,
  customButton,
  isOpen,
  disabled,
  initialMessage,
  label,
  memberCount: memberCountProp,
  memberQuery,
  members,
  source,
  subject,
  openComposer,
  onMessageSent,
  onClose,
  hideLabel,
  hideComposerButton,
  enableExpandingEmailEditor,
  icon,
  isSourceEditOffer,
}) => {
  const addEvent = useEventContext();
  const { refetch: refetchResources } = useResourceContext();
  const [isComposerOpen, setIsComposerOpen] = useState(false);
  const openCompose = isOpen || isComposerOpen;
  const [previewConfig, setPreviewConfig] = useState<IPreviewConfig>(null);
  const messageFormRef = useRef<IEmailComposer>(null);

  const { excludeMemberIds, onExcludeMember, resetExcludeMemberIds } = useExcludeMemberIds();

  const [receipients, setReceipients] = useState<IMember[]>([]);

  const memberCount = useMemo(() => receipients.length || (members ? size(members) : memberCountProp) - size(excludeMemberIds), [members, memberCountProp, excludeMemberIds, receipients]);

  const addMessageEventLog = () => {
    if (members || memberQuery) {
      addEvent(
        EventName.AttemptBulkSendMessage,
        {
          member_count: memberCount,
          source,
        },
      );
    }
  };

  useEffect(() => {
    if (!openCompose) {
      setPreviewConfig(null);
      resetExcludeMemberIds();
    }
  }, [openCompose, setPreviewConfig, resetExcludeMemberIds]);
  useEffect(() => {
    if (openCompose) {
      refetchResources();
    }
  }, [openCompose, refetchResources]);

  const { filteredMembers, filteredMemberQuery } = useMemo(() => {
    let filteredMembers = undefined;
    let filteredMemberQuery = memberQuery;
    if (members) {
      filteredMembers = members.filter((m) => !excludeMemberIds.includes(m.id) && m.email);
    }
    if (memberQuery) {
      filteredMemberQuery = {
        ...memberQuery,
        includeMemberIds: memberQuery?.includeMemberIds?.filter((id) => !excludeMemberIds.includes(id)),
      };
    }
    return {
      filteredMembers,
      filteredMemberQuery,
    };
  }, [excludeMemberIds, members, memberQuery]);

  const handleClose = useCallback(() => {
    setIsComposerOpen(false);

    if (isFunction(onClose)) {
      onClose();
    }
  }, [onClose]);

  const onClick = useCallback(() => {
    if (isArray(members) && !isEmpty(members)) {
      if (filteredMembers.length === 0) {
        AntdModal.confirm({
          title: members.length === 1 ? 'Cannot email member' : 'Cannot email members',
          icon: <ExclamationCircleOutlined />,
          content: members.length === 1
            ? 'The member you selected does not have an email address. Add their email to send your message.'
            : `${members.length}/${members.length} members you selected do not have email addresses. Add their emails to send your message.`,
          okText: 'Okay',
          cancelButtonProps: {
            style: {
              display: 'none',
            },
          },
        });
        return;
      } else if (filteredMembers.length < members.length) {
        AntdModal.confirm({
          title: 'Some members don’t have email',
          icon: <ExclamationCircleOutlined />,
          content: `${members.length - filteredMembers.length}/${members.length} members you selected do not have email addresses. These members will not receive an email.`,
          okText: 'Continue',
          cancelText: 'Cancel',
          onOk: () => {
            setIsComposerOpen(true);
            addMessageEventLog();
          },
        });
        return;
      }
    }

    if (isFunction(openComposer)) {
      openComposer();
    } else {
      setIsComposerOpen(true);
    }
    addMessageEventLog();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addMessageEventLog, setIsComposerOpen, filteredMembers, members]);

  const onMessageSendSuccess = useCallback(() => {
    handleClose();
    if (onMessageSent) {
      onMessageSent();
    }
  }, [handleClose, onMessageSent]);

  const renderButton = () => {
    if (hideComposerButton) {
      return;
    }
    if (isFunction(customButton)) {
      return customButton(onClick);
    }
    return (
      <Tooltip title={label ? null : 'Compose'}>
        <span
          className={cx(
            styles.wrapper,
            className,
            { [styles.disabled]: disabled },
          )}
        >
          <Button
            disabled={disabled}
            onClick={onClick}
            className={styles.workflowButton}
            icon={icon || <EmailComposerIcon size={16} />}
          >
            {!hideLabel && label}
          </Button>
        </span>
      </Tooltip>
    );
  };
  const templateHeaderText = isSourceEditOffer ? 'Notify Member' : previewConfig ? 'Preview Personalizations' : 'New Message';
  return (
    <>
      {renderButton()}
      <Modal
        show={openCompose}
        showCloseIcon={false}
        onRequestClose={handleClose}
        className={styles.ComposeModal}
        dialogClassName={styles.dialog}
        contentClassName={styles.content}
      >
        <div className={styles.title}>

          <Space className={styles.composerSpace}>
            {templateHeaderText}
            <Button type="text" onClick={handleClose}><XmarkIcon /></Button>
          </Space>
        </div>
        {openCompose && (
          <>
            <EmailComposer
              ref={messageFormRef}
              className={cx(styles.messageForm, {
                [styles.hide]: !!previewConfig,
              })}
              hasTopBorder
              expandHeader
              onHideComposer={handleClose}
              onMessageSent={onMessageSendSuccess}
              initialMessage={initialMessage}
              allowSendingAsSeparate={allowSendingAsSeparate}
              members={filteredMembers}
              memberCount={memberCount}
              memberQuery={filteredMemberQuery}
              onMembersChange={setReceipients}
              onPreview={(config) => setPreviewConfig(config)}
              source={source}
              hasFixedHeight
              subject={subject}
              enableExpandingEmailEditor={enableExpandingEmailEditor}
              isSourceEditOffer={isSourceEditOffer}
              onClose={handleClose}
            />
            {previewConfig && (
              <EmailPreviewer
                excludeMemberIds={excludeMemberIds}
                onExcludeMember={onExcludeMember}
                previewConfig={previewConfig}
                onBack={() => setPreviewConfig(null)}
                onConfirm={() => {
                  setPreviewConfig(null);
                  messageFormRef.current.sendMessage(true, excludeMemberIds);
                }}
                memberCount={memberCount}
                memberQuery={filteredMemberQuery}
              />
            )}
          </>
        )}
      </Modal>
    </>
  );
});

EmailComposerModal.displayName = 'EmailComposerModal';
