import gql from 'graphql-tag';

export const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    type
    orderId
    resourceId
    fulfillmentStatus
    cost
    raw
    trackingId
    trackingCompany
    trackingUrls
    details
    member
    artifacts {
      communityIds
      programIds
      activationIds
      brandCatalogId
      selectionRuleId
    }
    orderRequestStatus
    creatorOrderRequest

    createdDate
    updatedDate
  }
`;
