import * as React from 'react';
import { sum } from 'lodash';
import {
  Statistic,
  Row,
  Col,
  Divider,
} from '@revfluence/fresh';

import { Link } from 'react-router-dom';

import { TWidgetCount } from '@frontend/app/containers/Projects/types';

import styles from './Statistics.module.scss';

interface IProps {
  actualNeedsAttentionCount: number;
  applicantsCount: TWidgetCount;
  completedCount: TWidgetCount;
  allInProgressCount: TWidgetCount;
}

const Statistics: React.FC<IProps> = React.memo((props) => {
  const {
    actualNeedsAttentionCount,
    applicantsCount,
    completedCount,
    allInProgressCount,
  } = props;

  const overallCount = sum([
    allInProgressCount,
    completedCount,
    applicantsCount,
  ]);

  return (
    <>
      <Row justify="space-around">
        <Statistic
          title="Creators Need Attention"
          value={`${actualNeedsAttentionCount} of ${overallCount}`}
          valueStyle={{ justifyContent: 'center' }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore TODO: Fix in Node upgrade typing bash!
          reverse
        />
      </Row>

      <Divider />

      <Row justify="space-around">
        <Col span={8} className={styles.link}>
          <Link to="applicants">
            <Statistic
              title="Applicants"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TODO: Fix in Node upgrade typing bash!
              value={applicantsCount}
              valueStyle={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TODO: Fix in Node upgrade typing bash!
                color: applicantsCount > 0
                  ? '#d48806'
                  : '#1f1f21',
                justifyContent: 'center',
              }}
              reverse
            />
          </Link>
        </Col>
        <Col span={8} className={styles.link}>
          <Link to="all_in_progress">
            <Statistic
              title="In Progress Tasks"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TODO: Fix in Node upgrade typing bash!
              value={allInProgressCount}
              valueStyle={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TODO: Fix in Node upgrade typing bash!
                color: allInProgressCount > 0
                  ? '#d48806'
                  : '#1f1f21',
                justifyContent: 'center',
                textAlign: 'center',
              }}
              reverse
            />
          </Link>
        </Col>
        <Col span={8} className={styles.link}>
          <Link to="completed">
            <Statistic
              title="Completed"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore TODO: Fix in Node upgrade typing bash!
              value={completedCount}
              valueStyle={{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore TODO: Fix in Node upgrade typing bash!
                color: completedCount > 0
                  ? '#389e0d'
                  : '#1f1f21',
                justifyContent: 'center',
                textAlign: 'center',
              }}
              reverse
            />
          </Link>
        </Col>
      </Row>
    </>
  );
});

export default Statistics;
Statistics.displayName = 'Statistics';
