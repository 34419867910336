import * as React from 'react';
import { ButtonProps } from 'antd/lib/button';

import { Button } from '@revfluence/fresh';

import { CoinsIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './SideBarButton.scss';

interface ISideBarButtonProps extends ButtonProps {
  icon: string;
  text: string;
}

export const SideBarButton: React.FC<Readonly<ISideBarButtonProps>> = (props) => {
  const { text, ...restprops } = props;

  return (
    <Button
      {...restprops}
      icon={<CoinsIcon />}
      size="middle"
      className={styles.SideBarButton}
    >
      {text}
    </Button>
  );
};
