import * as React from 'react';
import { Alert, IButtonProps } from '@revfluence/fresh';

import { ReviewAndSummary } from './ReviewAndSummary/ReviewAndSummary';
import {
  IState,
  Step,
  TDispatch,
} from './types/state';
import { ActionTypes } from './types/actionTypes';
import CollaborationDetails from './CollaborationDetails/CollaborationDetails';
import { TContentGuideline } from './types/ContentGuidelines';
import AddContentGuidelines from './ContentRequirements/AddContentGuidelines';
import { TContentUsageRights, WhitelistingLabel } from './types/CollaborationDetails';

const { useCallback } = React;

interface IProps {
  advancedTerms: boolean;
  daysToApproveContent: number;
  paymentPeriod: number;
  isBrandedContentEnabled: boolean;
  whitelistingLabel: WhitelistingLabel;
  isCreatorEditEnabled: boolean;
  dispatch: TDispatch;
  state: IState;
  contentRightsTemplates: Array<TContentUsageRights>;
  contentGuidelinesTemplates: Array<TContentGuideline>;
  linkInsightsAlertForBrandedContent: boolean;
  linkInsightsAlertForPartnershipAds: boolean;
  linkBCAAccountAlert: boolean;
  businessAccountMissing: boolean;
  tiktokSparkAdsAccountMissing: boolean;
  initialMessage: string;
  templateId: number;
  onClickConnectInstagram: IButtonProps['onClick'];
  renderEmailComposer: (
    templateId: number,
    initialMessage: string,
    state: IState,
    dispatch: TDispatch,
  ) => React.ReactElement;
}

export const MainContent: React.FC<Readonly<IProps>> = React.memo((props) => {
  const {
    state: {
      collaborationDetails,
      contentGuidelines,
      errorStepDescription,
      errorStepMessage,
      members,
      step,
    },
    contentRightsTemplates,
    contentGuidelinesTemplates,
    renderEmailComposer,
    dispatch,
    isCreatorEditEnabled,
    daysToApproveContent,
    paymentPeriod,
    isBrandedContentEnabled,
    whitelistingLabel,
    advancedTerms,
    linkInsightsAlertForBrandedContent,
    linkInsightsAlertForPartnershipAds,
    linkBCAAccountAlert,
    onClickConnectInstagram,
  } = props;

  const handleAddPostType = useCallback((contentGuideline: TContentGuideline, contentGuidelineInstanceId: number) => {
    dispatch({ type: ActionTypes.AddPostType, contentGuideline, contentGuidelineInstanceId });
  }, [dispatch]);

  const handleRemovePostType = useCallback(
    (contentGuideline: TContentGuideline, contentGuidelineInstanceId: number) => {
      dispatch({ type: ActionTypes.RemovePostType, contentGuideline, contentGuidelineInstanceId });
    }, [dispatch],
  );

  switch (step) {
    case Step.ErrorStep:
      return (
        <Alert
          description={errorStepDescription}
          message={errorStepMessage}
          showIcon
          type="error"
        />
      );
    case Step.CollaborationDetails:
      return (
        <CollaborationDetails
          paymentPeriod={paymentPeriod}
          businessAccountMissing={props.businessAccountMissing}
          linkInsightsAlertForBrandedContent={linkInsightsAlertForBrandedContent}
          linkInsightsAlertForPartnershipAds={linkInsightsAlertForPartnershipAds}
          linkBCAAccountAlert={linkBCAAccountAlert}
          tiktokSparkAdsAccountMissing={props.tiktokSparkAdsAccountMissing}
          dispatch={dispatch}
          contentRightsTemplates={contentRightsTemplates}
          contentRight={collaborationDetails.contentRight}
          brief={collaborationDetails.brief}
          bam={collaborationDetails.bam}
          contentGuidelinesTemplates={contentGuidelinesTemplates}
          contentGuidelinesWithDueDates={contentGuidelines}
          onClickConnectInstagram={onClickConnectInstagram}
          reward={collaborationDetails.reward}
          disableCreatorEdits={collaborationDetails.disableCreatorEdits}
          adsPermissions={collaborationDetails.adsPermissions}
          isCreatorEditEnabled={isCreatorEditEnabled}
          daysToApproveContent={daysToApproveContent}
          isBrandedContentEnabled={isBrandedContentEnabled}
          whitelistingLabel={whitelistingLabel}
          advancedTerms={advancedTerms}
        />
      );
    case Step.AddContentGuidelines:
      return (
        <AddContentGuidelines
          contentGuidelinesTemplates={contentGuidelinesTemplates}
          contentGuidelinesWithDueDates={contentGuidelines}
          onAddPostType={handleAddPostType}
          onRemovePostType={handleRemovePostType}
        />
      );
    case Step.ReviewAndSetOffers:
      return (
        <ReviewAndSummary
          collaborationDetails={collaborationDetails}
          contentGuidelines={contentGuidelines}
          dispatch={dispatch}
          members={members}
        />
      );
    case Step.SocialProfile:
      // @TODO render social profile component (not for P0)
      return (
        <div>Social profile goes here.</div>
      );
    case Step.WriteMessage:
      return renderEmailComposer(props.templateId, props.initialMessage, props.state, dispatch);
    default:
      throw new Error(`Unhandled main content step: "${step}".`);
  }
});
MainContent.displayName = 'MainContent';
