/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react';
import { get, isEmpty, isNil } from 'lodash';
import { GetMemberActivitiesQuery_activities_payload_story } from '@frontend/app/queries/types/GetMemberActivitiesQuery';
import { GetThreadQuery_thread_messages_payload_story } from '@frontend/app/queries/types/GetThreadQuery';
import {
  Typography,
  Image,
  Space,
} from '@revfluence/fresh';

const { useState, useEffect } = React;
const { Text } = Typography;

interface IIGDMStoryMessageItemProps {
  story: GetMemberActivitiesQuery_activities_payload_story | GetThreadQuery_thread_messages_payload_story | null;
  setIsExpired(value: boolean): void;
  title: string;
}

export const IGDMStoryMessageItem: React.FC<IIGDMStoryMessageItemProps> = ({
  story, title, setIsExpired,
}) => {
  if (isNil(story?.mention)) return null;

  const [imageError, setImageError] = useState(false);
  const [videoError, setVideoError] = useState(false);

  useEffect(() => {
    if ((imageError && videoError) || isEmpty(get(story, 'mention.link', ''))) {
      setIsExpired && setIsExpired(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageError, videoError, story]);

  const storyUrl = story.mention.link;
  return (
    <Space
      direction="vertical"
      size={8}
      wrap
    >
      {!isNil(title) && <Text>{title}</Text>}
      {!isNil(storyUrl && !isEmpty(storyUrl))
        && !imageError && (
          <a href={storyUrl} target="_blank">
            <Image
              preview={false}
              onError={() => setImageError(true)}
              src={storyUrl}
              width={201}
              height={300}
              style={{ borderRadius: 6 }}
            />
          </a>
        )}
      {!isNil(storyUrl) && !isEmpty(storyUrl)
        && imageError && !videoError && (
          <a href={storyUrl} target="_blank">
            <video
              src={storyUrl}
              style={{ width: '201px', height: '300px', borderRadius: 6 }}
              onError={() => setVideoError(true)}
            />
          </a>
        )}
    </Space>
  );
};
