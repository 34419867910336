import { SHOPIFY_APP_ID } from '@frontend/app/constants/applicationIds';
import { useResourceContext } from '@frontend/app/context';
import { Alert, Button, Space } from '@revfluence/fresh';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const ShopifyStoreDisconnectionAlert = () => {
    const { isAllShopifyNotConnected } = useResourceContext();
    const history = useHistory();
    return (
        isAllShopifyNotConnected && (
        <Alert
          message="Shopify Store Connection Issue"
          type="error"
          showIcon
          description="We have detected an issue with your connected Shopify store. To ensure seamless operation, please reconnect your store."
          action={(
            <Space>
              <Button size="small" type="ghost" onClick={() => history.push(`/settings/${SHOPIFY_APP_ID}`)}>
                Reconnect Your Shopify Store
              </Button>
            </Space>
                  )}
          style={{ marginBottom: 16 }}
        />
        )
    );
};

export default ShopifyStoreDisconnectionAlert;
