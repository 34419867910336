import * as React from 'react';
import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';

import {
  backendServerApiEndpoint,
  backendServerWebEndpoint,
  analyticsServerApiEndpoint,
} from '@frontend/applications/Shared/serviceHosts';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';

import { IWorkflowActionParameters } from '@frontend/app/containers/Application/ApplicationContainer';
import ContentAppModal from './ContentAppModal';
import ContentAppDashboard from './ContentAppDashboard';

const { useEffect } = React;
interface IProps {
  modalView: boolean;
  clientId: string;
  clientName: string;
  memberId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deepLinkParameters?: any;
  workflowActionParameters?: IWorkflowActionParameters;
}

const ContentApp: React.FunctionComponent<IProps> = (props) => {
  const { modalView } = props;
  const addEvent = useEventContext();
  useEffect(() => {
    addEvent(EventName.OpenApp, { source: 'nav', app: 'content' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApplicationProvider
      backendServerApiEndpoint={backendServerApiEndpoint()}
      analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
      backendServerWebEndpoint={backendServerWebEndpoint()}
      clientId={props.clientId}
      clientName={props.clientName}
      memberId={props.memberId}
      workflowActionParameters={props.workflowActionParameters}
    >
      {modalView ? (<ContentAppModal deepLinkParameters={props.deepLinkParameters} />) : (<ContentAppDashboard />)}
    </ApplicationProvider>
  );
};

export default ContentApp;
