/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1kp0l_157 {
  display: flex;
}

._justify-content-space-between_1kp0l_161 {
  justify-content: space-between;
}

._tabular-nums_1kp0l_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1kp0l_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_1kp0l_178 ._header_1kp0l_178 ._title_1kp0l_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._content_1kp0l_178 ._header_1kp0l_178 ._title_1kp0l_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1kp0l_157 {
  display: flex;
}

._justify-content-space-between_1kp0l_161 {
  justify-content: space-between;
}

._tabular-nums_1kp0l_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1kp0l_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._content_1kp0l_178 ._header_1kp0l_178 ._title_1kp0l_178 h3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #1a1818;
}

._content_1kp0l_178 ._header_1kp0l_178 ._title_1kp0l_178 h4 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  color: #1a1818;
}

._content_1kp0l_178 {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
._content_1kp0l_178 ._header_1kp0l_178 {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
._content_1kp0l_178 ._header_1kp0l_178 ._title_1kp0l_178 {
  display: flex;
  flex-direction: column;
}
._content_1kp0l_178 ._header_1kp0l_178 ._title_1kp0l_178 h3 {
  margin: 0.5rem 0 0.5rem 0.5rem;
  color: #2e426d;
}
._content_1kp0l_178 ._header_1kp0l_178 ._title_1kp0l_178 h4 {
  margin: 0 0 0.5rem 0.5rem;
  color: #8f8d91;
}
._content_1kp0l_178 ._header_1kp0l_178 ._select_1kp0l_451 {
  margin-left: auto;
}

._AudienceDemoSection_1kp0l_455 ._content_1kp0l_178 ._header_1kp0l_178 ._select_1kp0l_451 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._AudienceDemoSection_1kp0l_455 ._content_1kp0l_178 ._header_1kp0l_178 ._select_1kp0l_451 ._item_1kp0l_460 {
  margin-left: 1.5rem;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  font-size: 0.875rem;
  color: #aeaeae;
  will-change: color;
  transition: color 0.1s ease-out;
}
._AudienceDemoSection_1kp0l_455 ._content_1kp0l_178 ._header_1kp0l_178 ._select_1kp0l_451 ._item_1kp0l_460:hover, ._AudienceDemoSection_1kp0l_455 ._content_1kp0l_178 ._header_1kp0l_178 ._select_1kp0l_451 ._item_1kp0l_460._active_1kp0l_470 {
  color: #3996e0;
}
._AudienceDemoSection_1kp0l_455 ._content_1kp0l_178 ._main_1kp0l_473 {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}