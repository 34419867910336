import * as React from 'react';
import { ApolloError } from '@apollo/client';
import cx from 'classnames';
import { times } from 'lodash';

import { Checkbox, Button, TrashcanIcon } from '@components';
import { MemberIcon, SimpleTextarea } from '@frontend/app/components';
import { map, reduce } from 'lodash';
import { Footer } from '../components/Footer';
import { IProduct } from '../hooks';
import { Select } from '../components/Select';

interface IProps {
  createOrder?(): void;
  selectedProducts: { product: IProduct, variant, quantity: number }[];
  setSelectedProducts?(products): void;
  memberCount: number;
  embedded?: boolean;
  notes?: string;
  setNotes?(notes: string): void;
  sentReceipt?: boolean;
  creatingOrders?: boolean;
  setSendReceipt?(sentReceipt: boolean): void;
  error?: ApolloError;
}

import styles from './ConfirmationPage.scss';
import { ProductVariantInventoryPolicy } from '../types/globalTypes';

const MAXIMUM_PRODUCTS_TO_SEND = 100;

export const ConfirmationPage: React.FunctionComponent<IProps> = (props) => {
  const {
 createOrder,
 selectedProducts,
 setSelectedProducts,
 memberCount,
 embedded,
 notes,
 setNotes,
 sentReceipt,
 setSendReceipt,
 creatingOrders,
 error,
} = props;

  const totalPerMember = reduce(selectedProducts, (sum, s) => sum + s.quantity * s.variant.price, 0);
  const grandTotal = memberCount * totalPerMember;

  const setQuantity = (variant, quantity) => {
    setSelectedProducts({ ...selectedProducts, [variant.id]: { ...selectedProducts[variant.id], quantity } });
  };

  const removeSelection = (variant) => {
    const tmp = { ...selectedProducts };
    delete tmp[variant.id];
    setSelectedProducts(tmp);
  };

  return (
    <div className={cx(styles.confirmationPage, embedded && styles.embedded)}>
      <div className={styles.container}>
        <div className={styles.scrollable}>
          <div className={styles.products}>
            {/* <div className={styles.title}>
              Pricing details will not be sent to members.
            </div> */}
            <div className={styles.section}>
              <div className={styles.subTitle}>
                MEMBERS
              </div>
              <div className={styles.memberRow}>
                <div className={styles.iconSquare}>
                  <MemberIcon size={16} />
                </div>
                <div className={styles.boldText}>
                  You have
                  {' '}
                  {memberCount}
                  {' '}
                  {memberCount === 1 && 'member '}
                  {memberCount > 1 && 'members '}
                  selected
                </div>
              </div>
            </div>
            <div className={styles.productSection}>
              <div className={styles.subTitle}>
                PRODUCTS
              </div>
              <div className={styles.orders}>
                {map(selectedProducts, (selection) => {
                  const ignoreInventory = selection.variant?.inventoryPolicy === ProductVariantInventoryPolicy.CONTINUE;
                  const maxCount = ignoreInventory
                    ? MAXIMUM_PRODUCTS_TO_SEND
                    : Math.max(Math.min(selection.variant?.sellableOnlineQuantity, MAXIMUM_PRODUCTS_TO_SEND), 0);

                  const options = times(maxCount, (index) => ({ label: index + 1, value: index + 1 }));

                  return (
                    <div key={selection.variant.id} className={styles.order}>
                      <img src={selection.product.image?.src} className={styles.image} />
                      <div className={styles.details}>
                        <div className={styles.productTitle}>{selection.product.title}</div>
                        <div className={styles.sku}>
                          SKU:
                          {selection.variant.sku}
                        </div>
                      </div>
                      <div className={styles.price}>
                        <div className={styles.priceDetails}>
                          <div>{(Number(selection.variant.price)).toFixed(2)}</div>
                        </div>
                        <div>x</div>

                        {embedded
                          ? (
                            <div className={styles.priceDetails}>
                              {selection.quantity}
                            </div>
)
                          : (
                            <Select
                              placeholder="Select"
                              options={options}
                              className={[styles.priceDetails, styles.quantitySelect]}
                              onChange={(q) => setQuantity(selection.variant, q)}
                              selectedValue={selection.quantity}
                            />
)}
                        <div className={styles.total}>{`$${(selection.quantity * selection.variant.price).toFixed(2)}`}</div>
                        {!embedded
                          && (
                          <Button
                            theme="light"
                            label=""
                            className={styles.removeButton}
                            icon={<TrashcanIcon size={18} />}
                            onClick={() => removeSelection(selection.variant)}
                          />
)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className={styles.totals}>
              <div className={styles.totalSection}>
                <div className={styles.placeholder} />
                <div className={styles.totalDetails}>Total per member:</div>
                <div className={styles.total}>{`$${totalPerMember.toFixed(2)}`}</div>
              </div>
              <div className={styles.totalSection}>
                <div className={styles.placeholder} />
                <div className={styles.totalDetails}>
                  Grand total for all
                  {' '}
                  {memberCount}
                  {' '}
                  members:
                </div>
                <div className={styles.total}>{`$${(grandTotal).toFixed(2)}`}</div>
              </div>
            </div>

            {!embedded && (
            <>
              <div className={styles.section}>
                <div className={styles.subTitle}>
                  MEMBER RECEIPT
                </div>
                <Checkbox
                  label={(
                    <div>
                      <div className={styles.checkboxLabel}>
                        Send Shopify receipt to members
                      </div>
                      <div className={styles.checkboxDetails}>
                        Select this option if you want Shopify to send order confirmation & receipts for your orders to members.
                      </div>
                    </div>
                  )}
                  className={styles.checkbox}
                  checked={sentReceipt}
                  onChange={setSendReceipt}
                />
              </div>

              <div className={styles.section}>
                <div className={styles.subTitle}>
                  NOTES
                </div>
                <div className={styles.boldText}>
                  Internal note
                </div>
                <div className={styles.checkboxDetails}>
                  Write a note for internal use. By default, this note is not shown to members, but your store's confirmation email settings may vary.
                </div>
                <SimpleTextarea
                  className={styles.textarea}
                  value={notes}
                  onChange={setNotes}
                />
              </div>
            </>
)}
          </div>
        </div>
        {
          !!createOrder && (
            <Footer
              disablePrimaryAction={typeof error !== 'undefined'}
              numberOfProductsSelected={selectedProducts?.length}
              primaryButtonText="Create Order"
              primaryButtonAction={createOrder}
              loading={creatingOrders}
            />
          )
        }
      </div>
    </div>
  );
};
