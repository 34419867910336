import { QueryHookOptions, useQuery } from '@apollo/client';

import { LIST_EXECUTIONS } from '@frontend/app/queries';
import {
  ListExecutions,
  ListExecutionsVariables,
} from '@frontend/app/queries/types/ListExecutions';

type IOptions = QueryHookOptions<ListExecutions, ListExecutionsVariables>;

export const useListExecutions = (options: IOptions = {}) =>
  useQuery<ListExecutions, ListExecutionsVariables>(LIST_EXECUTIONS, options);
