import gql from 'graphql-tag';

export const CREATE_COST_CONFIG_MUTATION = gql`
  mutation CreateCostConfiguration($clientId: String!, $costConfig: CostConfigInput!) {
    createCostConfig(clientId: $clientId, costConfig: $costConfig) {
      clientId
      costConfig
      createdDate
      id
      shopifyOrderPayload
      updatedDate
    }
  }
`;

export const GET_COST_CONFIG_QUERY = gql`
  query GetCostConfiguration($clientId: String!) {
    clientConfig(clientId: $clientId) {
      clientId
      costConfig
      createdDate
      id
      updatedDate
    }
  }
`;

export const GET_CLIENT_CONFIG_QUERY = gql`
  query GetClientConfiguration($clientId: String!) {
    clientConfig(clientId: $clientId) {
      id
      inventorySettings {
        inventorySource
        stockAvailabilityCheck
        inventoryLocations {
          id
          name
        }
        reserveStockForPendingOrders
        maintainMinimumInventory
        minimumStockLevel
      }
      importSyncSettings {
        autoSyncFromShopify
        productsImportLimit
      }
      costConfig
      shopifyOrderPayload
    }
  }
`;

export const CREATE_INVENTORY_SETTINGS_MUTATION = gql`
  mutation CreateInventorySettings($inventorySettings: InventorySettingsInput!) {
    createInventorySetting(inventorySettings: $inventorySettings) {
      id
      inventorySettings {
        inventorySource
        stockAvailabilityCheck
        inventoryLocations {
          id
          name
        }
        reserveStockForPendingOrders
        maintainMinimumInventory
        minimumStockLevel
      }
      costConfig
      shopifyOrderPayload
    }
  }
`;

export const UPDATE_IMPORT_SYNC_SETTING = gql`
  mutation CreateOrUpdateImportSyncSettings($importSyncSettings: ImportSyncSettingsInput!) {
    createOrUpdateImportSyncSettings(importSyncSettings: $importSyncSettings) {
      id
      importSyncSettings {
        autoSyncFromShopify
      }
    }
  }
`;
