import * as React from 'react';
import cx from 'classnames';

import { AngleDownIcon } from '@revfluence/fresh-icons/solid/esm';
import { Button } from '@revfluence/fresh';
import { Dropdown } from 'antd-v5';
import { CalendarIcon } from '@revfluence/fresh-icons/regular/esm';
import { DateMenu } from './DateMenu';
import { getDateLabelFilter } from './getDateFilterLabel';
import { DateRangeOptions, IDateRangeSettings } from './types';

import styles from './DateFilter.scss';

const { useCallback, useState } = React;

interface IProps {
  buttonClassName?: string;
  menuClassName?: string;
  earliestDate: Date | null;
  settings: IDateRangeSettings;
}

export const DateFilter: React.FC<Readonly<IProps>> = (props) => {
  const {
    buttonClassName,
    earliestDate,
    menuClassName,
    settings,
  } = props;
  const [menuVisible, setMenuVisible] = useState(false);
  const onHideMenu = useCallback(() => {
    setMenuVisible(false);
  }, [setMenuVisible]);
  // flag indicating that the date picker has inconsistent state so if the
  // menu closes, we should reset to the default
  const [resetDateOnClose, updateResetDateOnClose] = useState(false);
  const onVisibleChange = useCallback((visible: boolean) => {
    setMenuVisible(visible);
    if (!visible && resetDateOnClose) {
      settings.onChange(DateRangeOptions.ALL_TIME);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetDateOnClose, settings.onChange, setMenuVisible]);
  const dateMenu = (
    <DateMenu
      className={menuClassName}
      onHideMenu={onHideMenu}
      settings={settings}
      updateResetDateOnClose={updateResetDateOnClose}
    />
  );

  const dateLabel = getDateLabelFilter(settings.selectedOption, settings.dateRange, earliestDate);

  return (
    <div className={styles.DateFilter} title={dateLabel}>
      <Dropdown
        data-testid="select"
        onVisibleChange={onVisibleChange}
        overlay={dateMenu}
        trigger={['click']}
        visible={menuVisible}
      >
        <Button className={cx(styles.pickButton, buttonClassName)}>
          <CalendarIcon className={styles.calendarIcon} />
          <div className={styles.label}>
            {dateLabel}
          </div>
          <AngleDownIcon />
        </Button>
      </Dropdown>
    </div>
  );
};
