import * as React from 'react';
import cx from 'classnames';
import {
  isNil, chain, size,
  filter, includes, isEmpty,
  map, get, isNumber, isFunction,
} from 'lodash';
import { Button, Space } from '@revfluence/fresh';
import { IconButton, ArrowLeftIcon, LoadSpinner } from '@components';

import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';
import { useMemberSearchQuery, useGetVariables } from '@frontend/app/hooks';
import { BackupType, MessageBackupPlanEntity } from '@frontend/app/types/globalTypes';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { getMemberFieldValue } from '@frontend/app/utils';
import { ListView } from './ListView';
import { DetailView } from './DetailView';
import { IPreviewConfig } from '../EmailComposer';

const { useState, useEffect, useMemo } = React;

import styles from './EmailPreviewer.scss';

interface IProps {
  previewConfig: IPreviewConfig;
  onBack(): void;
  onConfirm?(): void;
  // sending from members page
  memberQuery?: IMemberSearchQuery;
  memberCount?: number;
  canSendMessages?: boolean;
  sendButtonText?: string;

  className?: string;

  disableRemoveRecipient?: boolean;
  excludeMemberIds?: readonly number[];
  onExcludeMember?: (memberId: number) => void;
}

/**
 * @type {React.FC}
 */
export const EmailPreviewer: React.FC<IProps> = React.memo((props) => {
  const {
    canSendMessages = true,
    excludeMemberIds,
    previewConfig, disableRemoveRecipient,
    sendButtonText,
  } = props;

  // TODO - the PFA using proxy so need to pass via context
   const pfaVariables = useProductFulfillmentContext()?.usePfaContextVariables && useProductFulfillmentContext()?.variables;
   const { variables: queryVariables } = useGetVariables({ skip: !!pfaVariables });

  const variables = pfaVariables || queryVariables;

  const {
    loading,
    data: { members: membersData } = {},
  } = useMemberSearchQuery({
    variables: {
      query: props.memberQuery,
      skip: 0,
      take: 100,
    },
    skip: !props.memberQuery,
    fetchPolicy: 'no-cache',
  });
  const [view, setView] = useState<'detail' | 'list'>('detail');
  const [index, setIndex] = useState<number>(0);

  const requiredMemberFields: string[] = useMemo(() => chain(previewConfig.editorState?.backupPlans)
      .filter((p) => p.entity === MessageBackupPlanEntity.MEMBER && p.backupType === BackupType.REMOVE_INVALID)
      .map((p) => variables?.member[p.field]?.field)
      .value(), [previewConfig, variables]);
  const allMembers = map(
    isEmpty(previewConfig.members) ? membersData : previewConfig.members,
    (m, index) => {
      if (isNumber(m.id)) {
        return m;
      }

      return {
        ...m,
        id: 999999 + index,
      };
    },
  );
  const members = useMemo(() => filter(
      allMembers,
      (member) => {
        if (includes(excludeMemberIds, member.id)) {
          return false;
        }

        // checks if member has all the required fields
        for (const field of requiredMemberFields) {
          const fieldValue = getMemberFieldValue(member, field);
          if (isNil(fieldValue) || isEmpty(fieldValue)) {
            return false;
          }
        }

        return true;
      },
    ), [allMembers, excludeMemberIds, requiredMemberFields]);
  const selectedMember = useMemo(() => get(members, index), [members, index]);
  const showNavButtons = view === 'detail' && size(members) > 1;

  useEffect(() => {
    if (!loading && isEmpty(members)) {
      props.onBack();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, members, props.onBack]);

  const onExcludeMember = (memberId: number) => {
    if (selectedMember.id === memberId) {
      setIndex(0);
    }
    if (isFunction(props.onExcludeMember)) {
      props.onExcludeMember(memberId);
    }
  };

  if (loading) {
    return (
      <LoadSpinner className={styles.LoadingSpinner} centered />
    );
  }

  return (
    <div className={cx(styles.EmailPreviewer, props.className)}>
      <div className={styles.header}>
        {showNavButtons && (
          <IconButton
            className={styles.prevButton}
            icon={<ArrowLeftIcon size={16} />}
            theme="info"
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            disabled={view === 'list' as any || index === 0}
            onClick={(event) => {
              event.preventDefault();

              setIndex(Math.max(0, index - 1));
            }}
          />
        )}
        <div className={styles.viewSelect}>
          <div
            className={cx(styles.button, styles.singleView, {
              [styles.active]: view === 'detail',
            })}
            onClick={() => setView('detail')}
          >
            Single View
          </div>
          <div
            className={cx(styles.button, styles.listView, {
              [styles.active]: view === 'list',
            })}
            onClick={() => setView('list')}
          >
            List View
          </div>
        </div>
        {showNavButtons && (
          <IconButton
            className={styles.nextButton}
            icon={<ArrowLeftIcon className={styles.icon} size={16} />}
            theme="info"
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            disabled={view === 'list' as any || index === size(members) - 1}
            onClick={(event) => {
              event.preventDefault();

              setIndex(Math.min(size(members) - 1, index + 1));
            }}
          />
        )}
      </div>
      {view === 'detail' && (
        <DetailView
          member={selectedMember}
          previewConfig={previewConfig}
          onExcludeMember={onExcludeMember}
          disableRemoveRecipient={disableRemoveRecipient}
        />
      )}
      {view === 'list' && (
        <ListView
          members={members}
          previewConfig={previewConfig}
          onExcludeMember={onExcludeMember}
          disableRemoveRecipient={disableRemoveRecipient}
        />
      )}
      <Space
        className={styles.previewerSpace}
        direction="horizontal"
      >
        <Button
          key="back"
          type="text"
          onClick={() => props.onBack()}
        >
          Back
        </Button>
        {canSendMessages && (
          <Button
            key="submit"
            type="primary"
            disabled={!props.onConfirm}
            onClick={props.onConfirm}
          >
            {sendButtonText || `Send to ${size(members)} Recipient${size(members) > 1 ? 's' : ''}`}
          </Button>
        )}
      </Space>
    </div>
  );
});
