import gql from 'graphql-tag';

import { ORDER_FRAGMENT, ORDER_SHIPMENT_TRACKING_FRAGMENT } from './fragments';

export const GET_ORDERS_QUERY = gql`
  query GetOrdersQuery($limit: Int, $offset: Int) {
    orders: getOrders(limit: $limit, offset: $offset) {
      ...OrderFragment
      shipmentTracking {
        ...OrderShipmentTrackingFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
  ${ORDER_SHIPMENT_TRACKING_FRAGMENT}
`;

export const GET_ORDER_SUMMARY_QUERY = gql`
  query GetOrderSummaryQuery {
    summary: getOrderSummary
  }
`;

export const BULK_CREATE_ORDER_MUTATION = gql`
  mutation BulkCreateOrderMutation($resourceId: Float!, $params: [SaveOrderParams!]!) {
    result: bulkCreateOrder(resourceId: $resourceId, params: $params)
  }
`;

export const SYNC_ORDERS_MUTATION = gql`
  mutation SyncOrdersMutation {
    succeeded: syncOrders
  }
`;

export const GET_ORDER_BY_ID_QUERY = gql`
  query GetOrderById($id: String!) {
    order: orderById(id: $id) {
      ...OrderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;
export const GET_ORDERS_BY_IDS_QUERY = gql`
  query GetOrdersByIds($ids: [String!]!) {
    orders: ordersByIds(ids: $ids) {
      ...OrderFragment
      workItemId
    }
  }
  ${ORDER_FRAGMENT}
`;

export const BULK_FULFILL_ORDER_MUTATION = gql`
  mutation BulkFulfillOrderMutation($params: IBulkFulfillOrder!) {
    result: bulkFulfillOrder(params: $params) {
      id
    }
  }
`;
