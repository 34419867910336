import 'css-chunk:src/app/containers/Projects/ProjectApplicationPagePicker/ProjectApplicationPagePicker.scss';export default {
  "ProjectApplicationPagePicker": "_ProjectApplicationPagePicker_m00vs_1",
  "navigate": "_navigate_m00vs_1",
  "backButton": "_backButton_m00vs_10",
  "title": "_title_m00vs_13",
  "splashImage": "_splashImage_m00vs_19",
  "content": "_content_m00vs_24",
  "container": "_container_m00vs_32",
  "section": "_section_m00vs_42",
  "listGridView": "_listGridView_m00vs_53",
  "listItem": "_listItem_m00vs_60",
  "image": "_image_m00vs_67",
  "ctaBtns": "_ctaBtns_m00vs_73",
  "listItemTitle": "_listItemTitle_m00vs_97",
  "alignedLeftContent": "_alignedLeftContent_m00vs_114"
};