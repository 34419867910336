import * as React from 'react';
import {
  first,
  split,
} from 'lodash';
import { useHistory } from 'react-router-dom';

import { SOCIAL_DISCOVERY_APP_ID } from '@frontend/app/constants/applicationIds';
import {
  useGetCurrentClient,
  useGetProfile,
} from '@frontend/app/hooks';
import { useAuth } from '@frontend/context/authContext';
import HomeLoading from '@frontend/applications/ReportsApp/pages/Home/components/HomeLoading';
import { Layout } from './components/Layout';
import { useHomePageGroups } from './hooks/useHomePageGroups';
import { useHomePageProjects } from './hooks/useHomePageProjects';
import { ImpactHome } from '../../../applications/ReportsApp/containers/ImpactHome/ImpactHome';

export const GetStarted = () => {
  const { profile } = useGetProfile();
  const auth = useAuth();
  const history = useHistory();

  const welcome = React.useMemo((): React.ReactNode => {
    const tokens = split(profile.name, ' ');
    const firstName = first(tokens);
    if (!firstName) {
      return 'Welcome back.';
    }

    return `Welcome back, ${firstName}.`;
  }, [profile]);

  const projects = useHomePageProjects();
  const groups = useHomePageGroups(SOCIAL_DISCOVERY_APP_ID);

  const clientLookup = useGetCurrentClient();

  const onClickNewProject = React.useCallback((e) => {
    e.preventDefault();
    history.push(e.target.getAttribute('href'));
  }, [history]);

  const metrics = React.useMemo((): React.ReactNode => {
    if (clientLookup.loading) {
      return <HomeLoading />;
    }

    if (!clientLookup?.client?.createdDate) {
      return undefined;
    }

    return <ImpactHome clientId={auth.clientInfo.id} />;
  }, [
    auth.clientInfo.id,
    clientLookup,
  ]);

  return (
    <Layout
      groups={groups}
      metrics={metrics}
      onClickNewProject={onClickNewProject}
      projects={projects}
      welcome={welcome}
    />
  );
};
