import React from 'react';

import { Notice } from '@affiliates/AspireUI';
import { ISendPaymentRequest, savePaymentData, updatePaymentData } from '@frontend/applications/PaymentsApp/savePayment';
import { usePaymentSentEvent } from '@frontend/applications/PaymentsApp/hooks/usePaymentSentEvent';
import { useCreatePaymentLogs } from '@frontend/app/hooks/budgetAllocation/useCreatePaymentLogs';
import { useAssignBudgetToBulkPayments } from '@frontend/app/hooks/budgetAllocation/useAssignBudgetToBulkPayments';
import { BulkAssignBudgetDataInput, SaveBulkPaymentBudgetData } from '@frontend/app/types/globalTypes';
import { message } from '@revfluence/fresh';
import { useAddBulkPaymentPaymentData } from '@frontend/app/hooks/budgetAllocation/useAddBulkPaymentPaymentData';
import { useApolloClient } from '@frontend/applications/AffiliatesApp/hooks';
import { CREATE_PROVISONAL_PAYMENT_GROUP_LOG } from '@frontend/applications/AffiliatesApp/queries';
import { backendServerApiEndpoint as backendServerApiEndpointFn } from '@frontend/applications/Shared/serviceHosts';
import { getSteps } from './getSteps';
import { TActions, TState } from './state';
import { AssignPaymentTo, IPaymentProps, PaymentCreationSource } from '../../types';
import { logger } from '../../../../../../common/Logger';
import styles from './getPaymentsSteps.scss';
import { IPaymentGroupPayload, useCreatePaymentGroup } from '../../useCreatePaymentGroup';
import { IPaymentGroup } from '../../models';

export const getPayemntsSteps = (props: IPaymentProps, state: TState, actions: TActions) => {
    const [createPaymentLogs] = useCreatePaymentLogs();
    const [bulkReassignBudget] = useAssignBudgetToBulkPayments();
    const [addBulkPaymentData] = useAddBulkPaymentPaymentData();
    const staApolloClient = useApolloClient();

    const { backendServerApiEndpoint, workflowActionParameters, clientId } = props?.applicationProps || {};
    const availableBalance = state?.paymentSource?.balance_in_cents ? state?.paymentSource?.balance_in_cents / 100 : 0;
    const isCardRequired = availableBalance < state.amountPaying && !state.selectedCardId;
    const isTotalPaymentNotMatching = state.amountPaying !== state.payments.reduce((sum, payment) => sum + payment.amount, 0) || !!state.payments.filter((payment) => payment.amount === 0)?.length;
    const paymentSentHandler = usePaymentSentEvent();
    const { save: createPaymentGroup } = useCreatePaymentGroup();

    const sendPayment = async () => {
        if (state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING) {
          actions.setCurrentStep(4);
          try {
            const newPaymentGroup: IPaymentGroupPayload = {
              client_id: props.staPaymentsOptions.client_id,
              name: props.staPaymentsOptions.name,
              aspirex_application: props.staPaymentsOptions.aspirex_application,
              metadata: props.staPaymentsOptions.metadata,
            };
            if (state.selectedCardId) {
              newPaymentGroup.cardId = state.selectedCardId;
            }
            const backendServerPaymentGroupResponse: IPaymentGroup = await createPaymentGroup(newPaymentGroup);
            const provisionalLogPayload = {
              variables: {
                data: {
                  id: backendServerPaymentGroupResponse.id,
                  name: backendServerPaymentGroupResponse.name,
                  client_id: backendServerPaymentGroupResponse.client_id,
                  created_ts: backendServerPaymentGroupResponse.created_ts,
                  aspirex_application: backendServerPaymentGroupResponse.aspirex_application,
                  start_date: props.staPaymentsOptions.dateFilterRange?.startDate || null,
                  end_date: props.staPaymentsOptions.dateFilterRange?.endDate || null,
                },
              },
            };
            await staApolloClient.mutate({
              variables: provisionalLogPayload.variables,
              mutation: CREATE_PROVISONAL_PAYMENT_GROUP_LOG,
            });
            const splitPayment = state.payments.map((payment) => ({
              budgetAccountId: payment.budgetId,
              fiscalGranularityLabel: payment.granularityLabelForPayload,
              budgetPeriodDefinitionId: payment.periodId,
            }));
            const overFlowInput = state.overflowPayments.map((overflowPayment) => ({
                budgetAccountId: overflowPayment.budgetId,
                budgetPeriodDefinitionId: overflowPayment.periodId,
                fiscalGranularityLabel: overflowPayment.granularityLabelForPayload,
              }));
            const bulkPaymentBudgetData: SaveBulkPaymentBudgetData = {
              paymentGroupId: backendServerPaymentGroupResponse.id,
              budgetData: {
                ...(state.assignPaymentTo === AssignPaymentTo.Other
                  ? {
                      accountsPaymentBudgetInput: {
                        programId: state?.selectedProgramId,
                        splitInfo: splitPayment,
                      },
                    }
                  : {
                      projectPaymentBudgetInput: {
                        programId: state?.selectedProgramId,
                        programSplitFiscalInfo: {
                          budgetPeriodDefinitionId: state.payments[0].periodId,
                          fiscalGranularityLabel: state.payments[0].granularityLabelForPayload.split(' ')[0],
                        },
                        overflow: overFlowInput,
                      },
                    }),
              },
            };
            await addBulkPaymentData({
              variables: {
                bulkPaymentBudgetData,
              },
              onCompleted() {
                // Need to add handling here
              },
              onError(error) {
                logger.error({ message: error });
                actions.setError(error);
              },
            });
            for (const staPayment of state.staPayments) {
              if (!staPayment.isSelected) {
                continue;
              }

              try {
                const newPayment: ISendPaymentRequest = {
                  amount: parseFloat(staPayment.amountToPay),
                  amount_due: staPayment.amountDue,
                  member_id: staPayment.rowData.memberId,
                  client_id: props.staPaymentsOptions.client_id,
                  paypal_address: staPayment.rowData.paypalEmail,
                  member_name: staPayment.rowData.memberName,
                  member_email: staPayment.rowData.memberEmail,
                  metadata: {
                    ...staPayment.rowData.metadata,
                    offerId: staPayment.rowData.mixpanelFields?.offerId || '',
                  },
                  payment_group_id: backendServerPaymentGroupResponse.id,
                  aspirex_application: backendServerPaymentGroupResponse.aspirex_application,
                  ...(state?.selectedCardId && { card_id: state.selectedCardId }),
                };
                const url = `${backendServerApiEndpointFn()}/payment`;
                await savePaymentData(
                  url,
                  newPayment,
                  props.staPaymentsOptions.sentPaymentEventHandler,
                  staPayment.rowData.mixpanelFields,
                );
              } catch (error) {
                logger.error(error);
                continue;
              }
            }
          } catch (error) {
            logger.error(error);
          }
          actions.setCurrentStep(5);
        } else if (state.paymentCreationSource === PaymentCreationSource.REASSIGN) {
            const splitPayment = state.payments.map((payment) => ({
                budgetAccountId: payment.budgetId,
                fiscalGranularityLabel: payment.granularityLabelForPayload,
                budgetPeriodDefinitionId: payment.periodId,
            }));
            const overflow = state.overflowPayments.map((payment) => ({
                budgetAccountId: payment.budgetId,
                fiscalGranularityLabel: payment.granularityLabelForPayload,
                budgetPeriodDefinitionId: payment.periodId,
            }));
            const bulkAssignBudgetData: BulkAssignBudgetDataInput = {
                paymentIds: state.selectedBudgetReassign.map((id) => Number(id)),
                ...(state.assignPaymentTo === AssignPaymentTo.Other ? {
                    accountsPaymentBudgetInput: {
                        splitInfo: splitPayment,
                    },
                } : {
                    projectPaymentBudgetInput: {
                        programSplitFiscalInfo: {
                            budgetPeriodDefinitionId: state.payments[0].periodId,
                            fiscalGranularityLabel: state.payments[0].granularityLabelForPayload.split(' ')[0],
                        },
                        overflow,
                    },
                }),
            };
            try {
                await bulkReassignBudget({
                    variables: { bulkAssignBudgetData },
                    onCompleted() {
                        message.success('Budgets will be assigned in few mins');
                        closeModal();
                    },
                    onError(error) {
                        message.error('Failed updating');
                        logger.error({ message: error });
                        closeModal();
                    },
                });
            } catch (error) {
                actions.setError(error);
                logger.error({ message: error });
            }
        } else {
            const url = `${backendServerApiEndpoint}/payment`;
            const newPaymentWithWorkflow: ISendPaymentRequest = {
                amount: state.amountPaying,
                member_id: state.memberInfo.id,
                client_id: clientId,
                work_item_id: workflowActionParameters?.workItems?.[0]?.id,
                assigned: true,
                card_id: state.selectedCardId || null,
                member_name: state.memberInfo.name,
                paypal_address: state.paypalAddress,
                member_email: state.memberInfo.email,
                project_id: state?.terms?.find((term) => term.id === state.selectedTermId)?.project_id,
            };
            actions.setCurrentStep(4);
            try {
                const selectedProgram = state.programs.find((program) => program.id === state?.selectedProgramId);
                const groupSelected = state.groups.find((group) => group.id === state.selectedGroupId);
                const payment = await savePaymentData(url, newPaymentWithWorkflow, paymentSentHandler);
                const updatePayload = {
                    program_ids: selectedProgram?.id ? [selectedProgram.id] : [],
                    program_names: selectedProgram?.title ? [selectedProgram.title] : [],
                    community_ids: groupSelected ? [groupSelected.id] : [],
                    activation_ids: [],
                    activation_names: [],
                    assigned: true,
                    client_id: clientId,
                };
                await updatePaymentData(`${url}/${payment.id}`, updatePayload);
                const splitPayment = state.payments.map((payment) => ({
                    amount: payment.amount,
                    budgetAccountId: payment.budgetId,
                    fiscalGranularityLabel: payment.granularityLabelForPayload,
                    budgetPeriodDefinitionId: payment.periodId,
                }));

                const paymentLogVariables = {
                    budgetPaymentLogs: {
                        memberId: state.memberInfo.id,
                        paymentId: payment.id,
                        ...(state.assignPaymentTo === AssignPaymentTo.Other ? {
                            accountsPaymentBudgetInput: {
                                programId: state?.selectedProgramId,
                                splitInfo: splitPayment,
                            },
                        } : {
                            projectPaymentBudgetInput: {
                                programId: state?.selectedProgramId,
                                programSplitFiscalInfo: {
                                    budgetPeriodDefinitionId: state.payments[0].periodId,
                                    fiscalGranularityLabel: state.payments[0].granularityLabelForPayload.split(' ')[0],
                                },
                            },
                        }),
                    },
                };

                await createPaymentLogs({
                    variables: paymentLogVariables,
                    onCompleted() {
                        // Need to add handling here
                    },
                    onError(error) {
                        logger.error({ message: error });
                        actions.setError(error);
                    },
                });
                actions.setCurrentStep(5);
            } catch (error) {
                actions.setError(error);
                logger.error({ message: error });
            }
        }
    };

    const {
        closeModal,
    } = props;
    const {
        paymentOverview,
        paymentProcessing,
        paymentConfirm,
        paymentReview,
        paymentSuccess,
        paymentsError,
        staPayments,
    } = getSteps(props, state, actions);
    let steps = [
        paymentOverview({
            instructions: {
                description: '',
                title: '',
            },
            nextButtonConfig: {
                text: '',
                className: styles.hideButton,
            },
            previousButtonConfig: {
                action: () => closeModal(),
                showIcon: false,
            },
            stepNum: 1,
        }),
        paymentConfirm({
            instructions: {
                description: '',
                title: '',
            },
            nextButtonConfig: {
                text: 'Continue',
                action: () => actions.setCurrentStep(3),
                disabled: state.amountPaying <= 0 || isCardRequired,
            },
            previousButtonConfig: {
                text: 'View All Payments',
                action: () => actions.setCurrentStep(1),
                showIcon: true,
            },
            stepNum: 2,
        }),
        paymentReview({
            instructions: {
                description: '',
                title: '',
            },
            nextButtonConfig: {
                text: 'Send Payment',
                action: () => sendPayment(),
                showIcon: false,
                disabled: isTotalPaymentNotMatching || !state.budgetAccounts.length,
            },
            previousButtonConfig: {
                text: 'Back',
                action: () => actions.setCurrentStep(2),
                showIcon: true,
            },
            stepNum: 3,
        }),
        paymentProcessing({
            instructions: {
                description: '',
                title: '',
            },
            nextButtonConfig: {
                text: '',
                className: styles.hideButton,
            },
            previousButtonConfig: {
                className: styles.hideButton,
            },
            stepNum: 4,
        }),
        paymentSuccess({
            instructions: {
                description: '',
                title: '',
            },
            nextButtonConfig: {
                text: 'Close',
                action: () => closeModal(),
                showIcon: false,
            },
            previousButtonConfig: {
                text: 'View Payment History',
                action: () => actions.setCurrentStep(1),
                showIcon: true,
            },
            stepNum: 5,
        }),
        paymentsError({
            instructions: {
                description: '',
                title: '',
            },
            nextButtonConfig: {
                text: 'Close',
                action: () => closeModal(),
                showIcon: false,
                className: styles.hideButton,
            },
            previousButtonConfig: {
                text: 'View Payment History',
                showIcon: true,
                className: styles.hideButton,
            },
            stepNum: 6,
        }),
    ];
    if (state.paymentCreationSource === PaymentCreationSource.REASSIGN) {
        steps = [
            paymentReview({
                instructions: {
                    description: '',
                    title: '',
                },
                nextButtonConfig: {
                    text: 'Assign Budget',
                    action: () => sendPayment(),
                    showIcon: false,
                    disabled: !state.payments.length,
                },
                previousButtonConfig: {
                    text: 'Back',
                    action: () => closeModal(),
                    showIcon: true,
                },
                stepNum: 1,
            }),
        ];
    }
    if (state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING) {
      const isMemerPaymentSelected = !!state.staPayments.find((payment) => payment.isSelected);
      steps = [
        staPayments({
          instructions: {
            description: '',
            title: '',
          },
          nextButtonConfig: {
            text: 'Payment Details',
            action: () => actions.setCurrentStep(2),
            showIcon: false,
            disabled: !isMemerPaymentSelected,
          },
          previousButtonConfig: {
            text: 'Cancel',
            action: () => closeModal(),
            showIcon: true,
          },
          stepNum: 1,
        }),
        paymentConfirm({
          instructions: {
            description: '',
            title: '',
          },
          nextButtonConfig: {
            text: 'Continue',
            action: () => actions.setCurrentStep(3),
            disabled: state.amountPaying <= 0 || isCardRequired,
          },
          previousButtonConfig: {
            text: 'View All Payments',
            action: () => actions.setCurrentStep(1),
            showIcon: true,
          },
          stepNum: 2,
        }),
        paymentReview({
          instructions: {
            description: '',
            title: '',
          },
          nextButtonConfig: {
            text: 'Send Payment',
            action: () => sendPayment(),
            showIcon: false,
            disabled: isTotalPaymentNotMatching || !state.budgetAccounts.length,
          },
          previousButtonConfig: {
            text: 'Back',
            action: () => closeModal(),
            showIcon: true,
          },
          stepNum: 3,
        }),
        paymentProcessing({
          instructions: {
              description: '',
              title: '',
          },
          nextButtonConfig: {
              text: '',
              className: styles.hideButton,
          },
          previousButtonConfig: {
              className: styles.hideButton,
          },
          stepNum: 4,
        }),
        paymentSuccess({
            instructions: {
                description: '',
                title: '',
            },
            nextButtonConfig: {
                text: 'Close',
                action: () => closeModal(),
                showIcon: false,
            },
            previousButtonConfig: {
                text: 'View Payment History',
                action: () => actions.setCurrentStep(1),
                showIcon: true,
                className: styles.hideButton,
            },
            stepNum: 5,
        }),
        paymentsError({
            instructions: {
                description: '',
                title: '',
            },
            nextButtonConfig: {
                text: 'Close',
                action: () => closeModal(),
                showIcon: false,
                className: styles.hideButton,
            },
            previousButtonConfig: {
                text: 'View Payment History',
                showIcon: true,
                className: styles.hideButton,
            },
            stepNum: 6,
        }),
      ];
    }
    let currentSteps = steps;
    if (state.error) {
        currentSteps = currentSteps.map((currentSteps) => ({
            ...currentSteps,
            actionComponents: (
              <div className={styles.errorContainer}>
                <Notice type="error" message={state.error.message} />
              </div>
            ),
            previousButtonConfig: {
                text: 'Close',
                action: () => {
                    closeModal();
                    actions.setError(null);
                },
                showIcon: false,
            },
            nextButtonConfig: {
                text: '',
                className: styles.hideButton,
            },
        }));
    }

    return currentSteps;
};
