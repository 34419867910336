import * as React from 'react';
import {
  Space, Row, Col, Skeleton,
} from '@revfluence/fresh';

import styles from './HomeLoading.scss';
import { HomeLayout } from '../../Home';

export const TrendLoading = () => (
  <div className={styles.TrendLoading}>
    <Skeleton
      active
      loading
      title={false}
      paragraph={{ rows: 2 }}
    />
  </div>
);

export const AdviceLoading = () => (
  <div className={styles.AlertLoading}>
    <Row gutter={[8, 8]} align="middle">
      <Col>
        <Skeleton.Button
          active
          style={{ width: 60, height: 60 }}
        />
      </Col>
      <Col flex={1}>
        <Skeleton
          active
          loading
          title={false}
          paragraph={{ rows: 2 }}
        />
      </Col>
    </Row>
  </div>
);

const Impact = () => (
  <Space direction="vertical" size="large" align="center" className={styles.ImpactLoading}>
    <Space size={16} align="center" direction="vertical">
      <div className={styles.label}>
        <Skeleton
          active
          loading
          paragraph={false}
        />
      </div>
      <Skeleton.Button
        active
        style={{ width: 220, height: 60 }}
      />
    </Space>
    <Row justify="center">
      <Skeleton.Button
        active
        style={{ width: 60 }}
      />
    </Row>
    <Row justify="center">
      <Row gutter={0} style={{ width: 220, display: 'flex', gap: 40 }}>
        <Col flex="1">
          <Skeleton
            active
            loading
            title={false}
            paragraph={{ rows: 2, width: '100%' }}
          />
        </Col>
        <Col flex="1">
          <Skeleton
            active
            loading
            title={false}
            paragraph={{ rows: 2, width: '100%' }}
          />
        </Col>
      </Row>
    </Row>
    <Row justify="center">
      <Skeleton.Button
        active
        style={{ width: 180 }}
      />
    </Row>
  </Space>
);

const MetricsPrimary = () => (
  <Row gutter={[24, 24]}>
    <Col xs={24} md={24} sm={12} lg={12} xl={6} xxl={6}>
      <ChartLoading />
    </Col>
    <Col xs={24} md={24} sm={12} lg={12} xl={6} xxl={6}>
      <ChartLoading />
    </Col>
    <Col xs={24} md={24} sm={12} lg={12} xl={6} xxl={6}>
      <ChartLoading />
    </Col>
    <Col xs={24} md={24} sm={12} lg={12} xl={6} xxl={6}>
      <ChartLoading />
    </Col>
  </Row>
);

const ChartLoading = () => (
  <div className={styles.ChartLoading}>
    <Row justify="space-between" style={{ height: '100%' }}>
      <Col span={24}>
        <Skeleton
          active
          loading
          title={false}
          paragraph={{ rows: 1 }}
        />
        <Skeleton
          active
          loading
          title={false}
          paragraph={{ rows: 1 }}
        />
      </Col>
      <Col span={24}>
        <Skeleton.Button
          active
          size="small"
        />
      </Col>
    </Row>
  </div>
);

const MetricsSecondary = () => (
  <Row gutter={[24, 24]}>
    <Col flex={1}>
      <TrendLoading />
    </Col>
    <Col flex={1}>
      <TrendLoading />
    </Col>
    <Col flex={1}>
      <TrendLoading />
    </Col>
    <Col flex={1}>
      <TrendLoading />
    </Col>
    <Col flex={1}>
      <TrendLoading />
    </Col>
    <Col flex={1}>
      <TrendLoading />
    </Col>
    <Col flex={1}>
      <TrendLoading />
    </Col>
  </Row>
);

const Header = () => (
  <Col xs={24}>
    <Skeleton
      active
      loading
      // @ts-ignore TODO: Fix in Node upgrade typing bash!
      paragraph={{ rows: 1, width: '50%', marginBottom: 16 }}
    />
  </Col>
);

const HomeLoading = () => (
  <HomeLayout
    Header={<Header />}
    Advice={<AdviceLoading />}
    Alert={null}
    ImpactComponent={<Impact />}
    MetricsPrimary={<MetricsPrimary />}
    MetricsSecondary={<MetricsSecondary />}
  />
);

export default HomeLoading;
