import 'css-chunk:src/app/containers/Public/Onboarding/SignupForm.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_1pwt8_157",
  "justify-content-space-between": "_justify-content-space-between_1pwt8_161",
  "tabular-nums": "_tabular-nums_1pwt8_165",
  "Submitted": "_Submitted_1pwt8_178",
  "moreInfo": "_moreInfo_1pwt8_178",
  "submitted": "_submitted_1pwt8_178",
  "SignupForm": "_SignupForm_1pwt8_188",
  "label": "_label_1pwt8_188",
  "bold": "_bold_1pwt8_188",
  "checkbox": "_checkbox_1pwt8_198",
  "checkboxLabel": "_checkboxLabel_1pwt8_198",
  "required": "_required_1pwt8_237",
  "input": "_input_1pwt8_240",
  "dateField": "_dateField_1pwt8_241",
  "social": "_social_1pwt8_245",
  "socialPrefix": "_socialPrefix_1pwt8_248",
  "unpaidCollaborationCheckbox": "_unpaidCollaborationCheckbox_1pwt8_266",
  "unpaidDescriptionLabel": "_unpaidDescriptionLabel_1pwt8_273",
  "error": "_error_1pwt8_299",
  "copyright": "_copyright_1pwt8_322",
  "supportEmail": "_supportEmail_1pwt8_329",
  "show": "_show_1pwt8_1"
};