import * as React from 'react';
import cx from 'classnames';
import { isEmpty, size } from 'lodash';

import { Menu, Dropdown } from 'antd';

import {
  ArrowDownFilledIcon,
  Button,
  CloseIcon,
  FlagIcon,
  GroupIcon,
  InviteIcon,
  RoundAddCircleOutlineIcon,
  StarBorderIcon,
  TagIcon,
  WandIcon,
} from '@components';

import { useMessagingContext } from '@frontend/hooks';
import {
  AddAppIcon,
  InviteToProgramModal,
  ModifyActivationMembersModal,
  ModifyCommunityMembersModal,
  ModifyProgramMembersModal,
  ModifyTagMembersModal,
  RequirementsModal,
} from '@frontend/app/components';
import {
  useMarkMembersAsImportantMutation,
  useFeatureFlagVerbiage,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';

import { useEventContext } from '@frontend/app/context/EventContext';
import { EventName } from '@common';
import { useMemberListContext } from '@frontend/app/context/MemberListContext';

import { ClientFeature } from '@frontend/app/constants';
import styles from './OrganizeDropdown.scss';

const { useState } = React;

interface IProps {
  disabled?: boolean;
  selectedMemberIds?: number[];
  memberCount?: number;
  className?: string;
  isApplicantList: boolean;
  isWorkflowEnabled: boolean;
}

enum OrganizeModal {
  AddCommunities = 'addCommunities',
  AddPrograms = 'addPrograms',
  AddActivations = 'addActivations',
  AddTags = 'addTags',
  AddRequirements = 'addRequirements',
  InviteToProgram = 'inviteToProgram',
  RemoveCommunities = 'removeCommunities',
  RemovePrograms = 'removePrograms',
  RemoveActivations = 'removeActivations',
  RemoveTags = 'removeTags',
  RemoveRequirements = 'removeRequirements',
  UploadCSV = 'UploadCSV',
}

export const OrganizeDropdown: React.FunctionComponent<IProps> = React.memo((props) => {
  const [shownModal, setShownModal] = useState<OrganizeModal>(null);
  const { searchQuery } = useMemberListContext();

  const verbiage = useFeatureFlagVerbiage();
  const workflowEnabled = useClientFeatureEnabled(ClientFeature.WORKFLOW);

  const {
    showMessage,
  } = useMessagingContext();

  const [markAsImportant] = useMarkMembersAsImportantMutation();

  const handleMarkAsImportant = async () => {
    await markAsImportant({
      variables: {
        memberIds: props.selectedMemberIds,
        isImportant: true,
      },
    });

    showMessage({
      type: 'success',
      content: 'Members marked as important',
    });
  };

  const memberCount = size(props.selectedMemberIds) || props.memberCount;

  const addEvent = useEventContext();

  const MODAL_TO_ACTION = {
    [OrganizeModal.AddCommunities]: 'add_to_community',
    [OrganizeModal.AddPrograms]: 'add_to_program',
    [OrganizeModal.AddActivations]: 'add_to_activation',
    [OrganizeModal.AddTags]: 'add_to_tag',
    [OrganizeModal.AddRequirements]: 'add_requirement',
    [OrganizeModal.RemoveCommunities]: 'remove_from_community',
    [OrganizeModal.RemovePrograms]: 'remove_from_program',
    [OrganizeModal.RemoveActivations]: 'remove_from_activation',
    [OrganizeModal.RemoveTags]: 'remove_from_tag',
  };

  const onClick = async (modal) => {
    setShownModal(modal);
    addEvent(
      EventName.AttemptBulkAction,
      {
        action: MODAL_TO_ACTION[modal],
        member_count: memberCount,
      },
    );
  };

  const menu = (
    <Menu className={styles.menu}>
      <Menu.SubMenu
        title={(
          <>
            <RoundAddCircleOutlineIcon className={styles.icon} size={18} />
            Add to
          </>
)}
      >
        {!props.isApplicantList && (
          <>
            {
              !props.isWorkflowEnabled && (
                <Menu.Item onClick={() => onClick(OrganizeModal.AddCommunities)}>
                  <GroupIcon className={styles.icon} size={18} />
                  Add to
                  {' '}
                  {verbiage.Communities}
                </Menu.Item>
              )
            }
            <Menu.Item onClick={() => onClick(OrganizeModal.AddPrograms)}>
              <FlagIcon className={styles.icon} size={18} />
              Add to
              {' '}
              {verbiage.Programs}
            </Menu.Item>
            {workflowEnabled === false && (
              <Menu.Item onClick={() => onClick(OrganizeModal.AddActivations)}>
                <WandIcon className={styles.icon} size={18} />
                Add to Activations
              </Menu.Item>
            )}
          </>
        )}
        {
          !props.isWorkflowEnabled && (
            <Menu.Item onClick={() => onClick(OrganizeModal.AddTags)}>
              <TagIcon className={styles.icon} size={18} />
              Assign Tags
            </Menu.Item>
          )
        }
        {!props.isApplicantList && (
          <Menu.Item onClick={() => onClick(OrganizeModal.AddRequirements)}>
            <RoundAddCircleOutlineIcon className={styles.icon} size={18} />
            Add Requirement
          </Menu.Item>
        )}
      </Menu.SubMenu>
      <Menu.SubMenu
        title={(
          <>
            <CloseIcon className={styles.icon} size={14} />
            Remove from
          </>
)}
      >
        {!props.isApplicantList && (
          <>
            {
              !props.isWorkflowEnabled && (
                <Menu.Item onClick={() => onClick(OrganizeModal.RemoveCommunities)}>
                  Remove from
                  {' '}
                  {verbiage.Communities}
                  ...
                </Menu.Item>
              )
            }
            <Menu.Item onClick={() => onClick(OrganizeModal.RemovePrograms)}>
              Remove from
              {' '}
              {verbiage.Programs}
              ...
            </Menu.Item>
            {workflowEnabled === false && (
              <Menu.Item onClick={() => onClick(OrganizeModal.RemoveActivations)}>
                Remove from Activations...
              </Menu.Item>
            )}
          </>
        )}
        {
          !props.isWorkflowEnabled && (
            <Menu.Item onClick={() => onClick(OrganizeModal.RemoveTags)}>
              Remove Tags...
            </Menu.Item>
          )
        }
        {/*
        <Menu.Item onClick={setShownModal.bind(this, OrganizeModal.RemoveRequirements)}>
          Abandon Open Requirements
        </Menu.Item>
        */}
      </Menu.SubMenu>
      <Menu.Divider />
      {!props.isApplicantList && (
        <>
          <Menu.SubMenu
            title={(
              <>
                <InviteIcon className={styles.icon} size={18} />
                Invite to
              </>
)}
          >
            <Menu.Item onClick={() => onClick(OrganizeModal.InviteToProgram)}>
              <FlagIcon className={styles.icon} size={18} />
              Invite to
              {' '}
              {verbiage.Program}
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Divider />
        </>
      )}
      <Menu.Item
        onClick={handleMarkAsImportant}
        disabled={isEmpty(props.selectedMemberIds)}
      >
        <StarBorderIcon className={styles.icon} size={14} />
        Mark as Important
      </Menu.Item>
    </Menu>
  );

  const closeModal = () => {
    setShownModal(null);
  };

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']} disabled={props.disabled}>
        <Button
          theme="light"
          disabled={props.disabled}
          label={(
            <span className={styles.label}>
              Organize
              {' '}
              <ArrowDownFilledIcon size={10} className={styles.arrow} />
            </span>
          )}
          icon={<AddAppIcon size={18} />}
          className={cx(styles.OrganizeDropdown, props.className)}
          round={false}
        />
      </Dropdown>
      <ModifyCommunityMembersModal
        show={shownModal === OrganizeModal.AddCommunities || shownModal === OrganizeModal.RemoveCommunities}
        onRequestClose={closeModal}
        type={
          (shownModal === OrganizeModal.AddCommunities && 'add')
          || (shownModal === OrganizeModal.RemoveCommunities && 'remove')
          || null
        }
        query={searchQuery}
        memberIds={props.selectedMemberIds}
        memberCount={props.memberCount}
      />
      <ModifyProgramMembersModal
        show={shownModal === OrganizeModal.AddPrograms || shownModal === OrganizeModal.RemovePrograms}
        onRequestClose={closeModal}
        type={
          (shownModal === OrganizeModal.AddPrograms && 'add')
          || (shownModal === OrganizeModal.RemovePrograms && 'remove')
          || null
        }
        query={searchQuery}
        memberIds={props.selectedMemberIds}
        memberCount={props.memberCount}
      />
      <ModifyActivationMembersModal
        show={shownModal === OrganizeModal.AddActivations || shownModal === OrganizeModal.RemoveActivations}
        onRequestClose={closeModal}
        type={
          (shownModal === OrganizeModal.AddActivations && 'add')
          || (shownModal === OrganizeModal.RemoveActivations && 'remove')
          || null
        }
        query={searchQuery}
        memberIds={props.selectedMemberIds}
        memberCount={props.memberCount}
        showNewActivationButton
      />
      <ModifyTagMembersModal
        show={shownModal === OrganizeModal.AddTags || shownModal === OrganizeModal.RemoveTags}
        onRequestClose={closeModal}
        type={
          (shownModal === OrganizeModal.AddTags && 'add')
          || (shownModal === OrganizeModal.RemoveTags && 'remove')
          || null
        }
        query={searchQuery}
        memberIds={props.selectedMemberIds}
        memberCount={props.memberCount}
        showNewTagButton
      />
      <RequirementsModal
        show={shownModal === OrganizeModal.AddRequirements}
        query={searchQuery}
        memberIds={props.selectedMemberIds}
        memberCount={props.memberCount}
        source="member_list"
        onRequestClose={closeModal}
      />
      <InviteToProgramModal
        show={shownModal === OrganizeModal.InviteToProgram}
        memberIds={props.selectedMemberIds}
        memberCount={props.memberCount}
        onRequestClose={closeModal}
      />
    </>
  );
});

OrganizeDropdown.defaultProps = {
  disabled: false,
};

OrganizeDropdown.displayName = 'OrganizeDropdown';
