import { FieldType } from '@frontend/app/types/Fields';

export const getLabelForFieldType = (type: string, hasChoices: boolean = false) => {
  switch (type) {
    case FieldType.TEXT:
      if (hasChoices) {
        return 'Single Select';
      } else {
        return 'Free Text';
      }

    case FieldType.EMAIL:
      return 'Email';

    case FieldType.ARRAY:
      return 'Multi Select';

    case FieldType.NUMBER:
      return 'Number';

    case FieldType.BOOLEAN:
      return 'Yes/No';

    case FieldType.DATE:
      return 'Date';

    case FieldType.ANNUAL:
      return 'Annual Date';

    case FieldType.IMAGES:
      return 'Images';

    case FieldType.SINGLE_SELECT:
      return 'Single Select';

    case FieldType.MULTI_SELECT:
      return 'Multi Select';

    default:
      return 'Unknown Type';
  }
};
