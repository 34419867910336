import * as React from 'react';
import cx from 'classnames';
import { ApolloError } from 'apollo-client';
import {
  map, keyBy, findIndex, includes,
} from 'lodash';
import Modal from 'antd/lib/modal/Modal';
import { SearchOutlined } from '@ant-design/icons';

import { Input } from '@revfluence/fresh';
import { useMessagingContext } from '@frontend/hooks';
import { FieldType } from '@frontend/app/types/Fields';
import { FieldForm, IFieldForm } from '@frontend/app/containers/Communities/AddOrEditCommunity/CommunityIdentity/FieldForm';
import { requiredFieldNames } from '@frontend/app/containers/Projects/LandingPages/hooks';
import { useFuzzySearchByKeys, useSaveMemberFieldSchema } from '@frontend/app/hooks';

import { Field } from './Field';
import { IApplicationFormFields, TSchema } from '../../types';
import { useSchemas } from '../hooks';

import styles from './AllSchemas.scss';

const {
 useEffect, useMemo, useCallback, useState,
} = React;

interface IProps {
  applicationFormFields?: IApplicationFormFields;
  onChange?(formFields?: IApplicationFormFields);
  onClickBack?(e: React.MouseEvent<HTMLDivElement>);
  className?: string;
}

export const AllSchemas: React.FC<IProps> = (props) => {
  const {
    applicationFormFields,
    className,
    onClickBack,
    onChange,
  } = props;

  const {
    showError,
    showSuccessMessage,
  } = useMessagingContext();

  const [searchValue, setSearchValue] = useState('');
  const [showCreateFieldModal, setShowCreateFieldModal] = useState(false);
  const [isCreatingNewField, setIsCreatingNewField] = useState(false);

  const formFieldsBySchemaId = useMemo(() => keyBy(applicationFormFields?.memberFieldSchemas, (field) => field.schemaId), [applicationFormFields]);

  const {
    nonAppSchemas,
    appSchemas,
    refetchSchemas,
  } = useSchemas();

  // we allow user to modify non-app-schemas and app-schemas only if schema.metaData.edtiable
  const allSchemas = useMemo(() => [
      ...nonAppSchemas,
      ...appSchemas.filter((schema) => schema.metaData?.editable),
    ], [nonAppSchemas, appSchemas]);

  const [filteredSchemas, setFilteredSchemas] = useState(allSchemas);

  useEffect(() => {
    setFilteredSchemas(allSchemas);
  }, [allSchemas]);

  const handleSearch = useFuzzySearchByKeys(allSchemas, ['name']);

  const isSchemaActive = useCallback((schema: TSchema) => !!formFieldsBySchemaId[schema.id], [formFieldsBySchemaId]);

  const handleChange = useCallback((schema: TSchema, active: boolean, refreshList?: boolean) => {
    if (onChange) {
      const newFormSchemas = [...(applicationFormFields?.memberFieldSchemas || [])];
      if (active) {
        newFormSchemas.push({
          schemaId: schema.id,
          required: false,
          label: schema.name,
          active: true,
        });
      } else {
        const idx = findIndex(newFormSchemas, { schemaId: schema.id });
        newFormSchemas.splice(idx, 1);
      }
      const newFormFields: IApplicationFormFields = {
        ...applicationFormFields,
        memberFieldSchemas: newFormSchemas,
      };
      onChange(newFormFields);
    }

    if (refreshList) {
      refetchSchemas();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationFormFields, onChange]);

  useEffect(() => {
    const newFilteredSchemas = handleSearch(searchValue);
    setFilteredSchemas(newFilteredSchemas);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const [saveSchema] = useSaveMemberFieldSchema({
    onCompleted: (data) => {
      showSuccessMessage('Field created');
      setIsCreatingNewField(false);
      setShowCreateFieldModal(false);
      handleChange(data.schema, true, true);
    },
    onError: (error: ApolloError) => {
      setShowCreateFieldModal(false);
      setIsCreatingNewField(false);
      showError(error);
    },
  });

  const onCreateNewFieldIntent = useCallback(() => {
    setShowCreateFieldModal(true);
  }, [setShowCreateFieldModal]);

  const handleAddNewField = useCallback((newField: IFieldForm) => {
    setIsCreatingNewField(true);
    saveSchema({
      variables: {
        schema: {
          id: newField.id,
          name: newField.name,
          type: newField.type,
          choices: newField.choices,
        },
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsCreatingNewField]);

  return (
    <div className={cx(styles.AllSchemas, className)}>
      <div className={styles.header}>
        <div className={styles.linksContainer}>
          <div className={styles.link} onClick={onClickBack}>Back</div>
          <div className={styles.link} onClick={onCreateNewFieldIntent}>Create New Field</div>
        </div>

        <Input
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          prefix={<SearchOutlined />}
          className={styles.search}
          placeholder="Search fields"
        />
      </div>

      <div className={styles.schemas}>
        {map(filteredSchemas, (schema) => (
          <Field
            key={schema.id}
            name={schema.name}
            label={schema.name}
            type={schema.type}
            choices={schema.choices}
            active={isSchemaActive(schema)}
            disableSwitch={isSchemaActive(schema) && includes(requiredFieldNames, schema.name)}
            onToggleActive={handleChange.bind(this, schema)}
            className={styles.field}
          />
        ))}
      </div>

      <Modal
        title="Create New Field"
        visible={showCreateFieldModal}
        onCancel={() => setShowCreateFieldModal(false)}
        className={styles.CreateFieldModal}
      >
        <FieldForm
          field={{ id: null, name: '', type: FieldType.TEXT }}
          onSaveField={handleAddNewField}
          onCancel={() => setShowCreateFieldModal(false)}
          saving={isCreatingNewField}
          vertical
        />
      </Modal>

    </div>
  );
};
