/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1w59i_157 {
  display: flex;
}

._justify-content-space-between_1w59i_161 {
  justify-content: space-between;
}

._tabular-nums_1w59i_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1w59i_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_1w59i_157 {
  display: flex;
}

._justify-content-space-between_1w59i_161 {
  justify-content: space-between;
}

._tabular-nums_1w59i_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_1w59i_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SetupNotice_1w59i_355 ._actionsWrapper_1w59i_355 ._button_1w59i_355 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

._SetupNotice_1w59i_355 ._actionsWrapper_1w59i_355 ._button_1w59i_355 {
  border-color: var(--primary);
  background-color: var(--primary);
}
._SetupNotice_1w59i_355 ._actionsWrapper_1w59i_355 ._button_1w59i_355:focus, ._SetupNotice_1w59i_355 ._actionsWrapper_1w59i_355 ._button_1w59i_355:hover {
  background-color: #2e8af5;
  color: #fff;
}
._SetupNotice_1w59i_355 ._actionsWrapper_1w59i_355 ._button_1w59i_355:disabled {
  color: #fff;
  opacity: 0.8;
}

._SetupNotice_1w59i_355 ._title_1w59i_378 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.25rem;
}
._SetupNotice_1w59i_355 ._description_1w59i_384 {
  font-size: 0.875rem;
  margin: 0;
}
._SetupNotice_1w59i_355 ._actionsWrapper_1w59i_355 {
  padding-right: 1.625rem;
}
._SetupNotice_1w59i_355 ._actionsWrapper_1w59i_355 ._button_1w59i_355 {
  width: 10rem;
}