import * as React from 'react';
import {
  Alert,
  Col, Image, Input, Modal, Row, Space, Tag, Typography,
} from '@revfluence/fresh';
import { MagnifyingGlassIcon } from '@revfluence/fresh-icons/regular/esm';
import { pluralize } from '@frontend/app/utils/strings';
import { Spin } from 'antd';
import { ImportStatus } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import { useGetClientConfig } from '@frontend/applications/ProductFulfillmentApp/hooks/useGetClientConfig';
import styles from './ShopifyImportModal.scss';
import { useProductsContext } from '../ProductsContext';
import { useGetShopifyCollections } from '../hooks/useGetShopifyCollections';
import { Pagination } from './Pagination';
import { useShopifyImportModalContext } from './ShopifyImportModalContext';
import { ImportStatusRenderer } from './ImportStatusRenderer';
import { useGetCollectionStatuses } from '../hooks/useGetCollectionStatuses';
import { useGetImportedProductsStats } from '../hooks/useGetAllProductsStats';

const { Text, Title } = Typography;

const PAGE_SIZE = 10;

export const ShopifyImportModal = () => {
  const { isOpen, setIsOpen } = useProductsContext();
  const debounce = React.useRef<ReturnType<typeof setTimeout>>(null);

  const {
    pageNumber,
    search,
    goToNextPage,
    setSearch,
    goToPreviousPage,
    collections: collectionsMap,
    setCollectionsByPage,
    setPageNumber,
    setCollectionsBySearch,
  } = useShopifyImportModalContext();

  const {
 loading, collections: collectionsFromBE, pageInfo: pageInfoFromBE, refetch,
} = useGetShopifyCollections({
    variables: {
      input: {
        count: PAGE_SIZE,
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: !isOpen,
  });

  const { statuses } = useGetCollectionStatuses({
    pollInterval: 5000,
  });

  const { clientConfig, loading: isClientConfigLoading } = useGetClientConfig();

  const { data: productStats, loading: isStatsLoading, refetch: refetchStats } = useGetImportedProductsStats();
  if (isOpen) {
    refetchStats();
  }

  const importLimit = clientConfig?.importSyncSettings?.productsImportLimit || 50_000;

  const isProductMaxLimitReached = productStats?.shopifyProductsCount >= importLimit;
  const availableProductLimit = importLimit - (productStats?.shopifyProductsCount || 0);

  const { collections, pageInfo } = React.useMemo(() => {
    let collections = collectionsFromBE;
    let pageInfo = pageInfoFromBE;
    if (collectionsMap[pageNumber]) {
      collections = collectionsMap[pageNumber].collections;
      pageInfo = collectionsMap[pageNumber].pageInfo;
    } else if (collectionsFromBE) {
      setCollectionsByPage({ collections: collectionsFromBE, pageInfo });
    }
    return { collections, pageInfo };
  }, [collectionsFromBE, collectionsMap, pageInfoFromBE, pageNumber, setCollectionsByPage]);

  const { statusesMap } = React.useMemo(() => {
    const externalIdToStatusMap = new Map<string, { id: number, externalId: string, status: ImportStatus, lastSyncDate?: string }>();
    statuses.forEach((status) => {
      externalIdToStatusMap.set(status.collection.externalId, {
        id: status.collectionId,
        externalId: status.collection.externalId,
        status: status.status,
        lastSyncDate: status.collection.lastSyncDate,
      });
    });
    return { statusesMap: externalIdToStatusMap };
  }, [statuses]);

  const onNext = async () => {
    if (collectionsMap[pageNumber + 1]) {
      setPageNumber(pageNumber + 1);
      return;
    }
    const { data } = await refetch({
      input: {
        afterCursor: pageInfo?.endCursor,
        count: PAGE_SIZE,
      },
    });
    goToNextPage(data?.getShopifyCollections);
  };

  const onPrev = async () => {
    goToPreviousPage();
  };

  React.useEffect(() => {
    const fetchCollections = async (searchTitle?: string) => {
      const { data } = await refetch({
        input: {
          count: PAGE_SIZE,
          searchTitle,
        },
      });
      setCollectionsBySearch(data.getShopifyCollections);
    };
    if (debounce.current) {
      clearTimeout(debounce.current);
    }
    if (!search) {
      fetchCollections();
      return;
    }
    debounce.current = setTimeout(() => {
      fetchCollections(search);
    }, 1000);
  }, [refetch, search, setCollectionsBySearch]);

  const isLoading = isClientConfigLoading || isStatsLoading;

  return (
    <Modal
      title={(
        <Space className={styles.header}>
          <Title level={4}>Import Products</Title>
          {!isLoading && (
          <Tag color={isProductMaxLimitReached ? 'error' : 'success'}>
            Available Limit:
            {availableProductLimit}
            /
            {importLimit}
          </Tag>
)}
        </Space>
      )}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      width={699}
      className={styles.ShopifyImportModal}
      footer={null}
    >
      <Space direction="vertical">
        <Text className={styles.description}>
          Select Shopify collections you wish to import products from. All the products will be imported for selected
          collections.
        </Text>
        {!isLoading && isProductMaxLimitReached && <Alert message={`Maximum product limit reached. You can import up to ${importLimit} products only.`} type="error" showIcon />}
        <Input
          placeholder="Search Collection"
          prefix={<MagnifyingGlassIcon />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {loading ? (
          <Row justify="center" align="middle" className={styles.loaderContainer}>
            <Col>
              <Space direction="vertical" className={styles.space}>
                <Spin size="large" />
                <Text>Loading Shopify Collections...</Text>
              </Space>
            </Col>
          </Row>
        ) : (
          <>
            <Space className={styles.paginationContainer}>
              <Text weight="semibold">
                {collections?.length}
                {' '}
                {pluralize(collections?.length ?? 0, 'Collection')}
              </Text>
              <Pagination
                page={pageNumber}
                pageSize={PAGE_SIZE}
                items={collections?.length}
                hasNextPage={pageInfo?.hasNextPage}
                hasPreviousPage={pageNumber > 1}
                onNext={onNext}
                onPrev={onPrev}
              />
            </Space>
            <Space direction="vertical" className={styles.collectionTable}>
              {collections.map((collection) => {
                const statusInfo = statusesMap.get(collection.id);
                const lastSyncDate = statusInfo?.lastSyncDate;
                return (
                  <div className={styles.collectionListItem} key={collection.id}>
                    <Image src={collection.image || 'https://storage.googleapis.com/aspireiq-widgets/assets/content_image_placeholder.png'} width={40} height={40} />
                    <div className={styles.collectionListItemTitle}>
                      <Text weight="semibold">{collection.title}</Text>
                      <Text type="secondary">
                        {collection.productsCount}
                        {' '}
                        Products
                      </Text>
                    </div>
                    <ImportStatusRenderer collection={collection} lastSyncDate={lastSyncDate} statusInfo={statusInfo} showGoToCollection productLimit={availableProductLimit} />
                  </div>
                );
              })}
            </Space>
          </>
        )}
      </Space>
    </Modal>
  );
};
