import 'css-chunk:src/applications/AffiliatesApp/components/OfferForm/OfferForm.scss';export default {
  "black": "#1a1818",
  "border": "#dadcde",
  "borderLight": "#ececec",
  "bruise": "#70397c",
  "danger": "#d9534f",
  "dangerRed": "#d9534f",
  "darkBlue": "#3996e0",
  "darkBlueHover": "#4fa7ee",
  "darkGreyBlue": "#2e426d",
  "duckEggBlue": "#d6f1df",
  "grey": "#e9e8ea",
  "iceBlue": "#eff5f9",
  "iceBlueHover": "#e6edf2",
  "lightBlue": "#7fd4f4",
  "lightGrey": "#fafafa",
  "maize": "#eacd60",
  "pink": "#ea92af",
  "purpleyGrey": "#8f8d91",
  "purplishPink": "#c6549e",
  "red": "#da3737",
  "sand": "#ffe9d9",
  "success": "#4eb468",
  "successGreen": "#4eb468",
  "warmPink": "#ef5682",
  "watermelon": "#f1515f",
  "white": "#fdfdfd",
  "fontFamily": "system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", sans-serif",
  "fontSizeXS": "10",
  "fontSizeS": "12",
  "fontSize": "14",
  "fontSizeM": "16",
  "fontSizeL": "20",
  "borderRadius": "0.5rem",
  "borderRadiusMax": "624.9375rem",
  "extraSmallIconSize": "16",
  "smallIconSize": "24",
  "mediumIconSize": "40",
  "largeIconSize": "64",
  "extraLargeIconSize": "128",
  "flex": "_flex_sp1m3_157",
  "justify-content-space-between": "_justify-content-space-between_sp1m3_161",
  "tabular-nums": "_tabular-nums_sp1m3_165",
  "OfferForm": "_OfferForm_sp1m3_178",
  "instructions": "_instructions_sp1m3_178",
  "title": "_title_sp1m3_178",
  "description": "_description_sp1m3_225",
  "formSection": "_formSection_sp1m3_229",
  "icon": "_icon_sp1m3_244",
  "headerSpace": "_headerSpace_sp1m3_258",
  "dividerColor": "_dividerColor_sp1m3_261",
  "customLabelText": "_customLabelText_sp1m3_273",
  "AlertWrapper": "_AlertWrapper_sp1m3_276",
  "codeSuffixWrapper": "_codeSuffixWrapper_sp1m3_279",
  "halfRow": "_halfRow_sp1m3_284",
  "indented": "_indented_sp1m3_287",
  "centered": "_centered_sp1m3_293",
  "radioGroup": "_radioGroup_sp1m3_296",
  "radio": "_radio_sp1m3_296",
  "expiredMessage": "_expiredMessage_sp1m3_306",
  "datePicker": "_datePicker_sp1m3_314",
  "tooltipList": "_tooltipList_sp1m3_323",
  "hThreeRem": "_hThreeRem_sp1m3_331",
  "hasCharacterCount": "_hasCharacterCount_sp1m3_334",
  "tagIcon": "_tagIcon_sp1m3_337",
  "fullWidth": "_fullWidth_sp1m3_340",
  "archiveOfferAlert": "_archiveOfferAlert_sp1m3_343",
  "utmWrapper": "_utmWrapper_sp1m3_347",
  "urlwrapper": "_urlwrapper_sp1m3_354",
  "boxwrapper": "_boxwrapper_sp1m3_359",
  "urltitle": "_urltitle_sp1m3_365",
  "subheading": "_subheading_sp1m3_369",
  "utmexample": "_utmexample_sp1m3_373",
  "customText": "_customText_sp1m3_380",
  "inputBox": "_inputBox_sp1m3_389",
  "Closefield": "_Closefield_sp1m3_392",
  "disable-option": "_disable-option_sp1m3_400",
  "ant-select-item-option-content": "_ant-select-item-option-content_sp1m3_400",
  "promoCodeContainer": "_promoCodeContainer_sp1m3_431",
  "descTextColor": "_descTextColor_sp1m3_431",
  "customRadioGroup": "_customRadioGroup_sp1m3_435",
  "samplePromoContainer": "_samplePromoContainer_sp1m3_442",
  "purchaseTypeContainer": "_purchaseTypeContainer_sp1m3_448",
  "offerSyncContainer": "_offerSyncContainer_sp1m3_456",
  "customSyncText": "_customSyncText_sp1m3_459",
  "offerSyncForm": "_offerSyncForm_sp1m3_463",
  "syncContainer": "_syncContainer_sp1m3_466",
  "offerStatusContainer": "_offerStatusContainer_sp1m3_470",
  "CustomTextColor": "_CustomTextColor_sp1m3_474",
  "disableRadio": "_disableRadio_sp1m3_513",
  "payoutOptionContainer": "_payoutOptionContainer_sp1m3_518",
  "startDateBorder": "_startDateBorder_sp1m3_522",
  "mPayoutConatiner": "_mPayoutConatiner_sp1m3_528",
  "addPayoutButton": "_addPayoutButton_sp1m3_533",
  "defaultPayout": "_defaultPayout_sp1m3_537",
  "radioCustomContainer": "_radioCustomContainer_sp1m3_544",
  "radioItem": "_radioItem_sp1m3_549",
  "image": "_image_sp1m3_552",
  "marginOnAlert": "_marginOnAlert_sp1m3_559",
  "errorMessage": "_errorMessage_sp1m3_563",
  "alertContainer": "_alertContainer_sp1m3_567",
  "show": "_show_sp1m3_1"
};