import {
  find,
  first,
  get,
  isEmpty,
  isNil, map,
} from 'lodash';

import {
  GetThreadsQuery_threads as IThread,
  GetThreadsQuery_threads_lastMessage as IThreadMessage,
} from '@frontend/app/queries/types/GetThreadsQuery';
import {
  GetThreadQuery_thread,
} from '@frontend/app/queries/types/GetThreadQuery';
import { useMemberFieldPartitions } from '@frontend/app/containers/MemberDetail/MemberInfo/MemberOverview/hooks';
import { useResourceContext } from '@frontend/app/context';
import { IGDMMessageAttachmentType } from '@frontend/app/types/globalTypes';
import { getMentionIGDMMessage } from '@frontend/app/utils/igdm';
import { GetAllUsersQuery_users } from '@frontend/app/queries/types/GetAllUsersQuery';
import { isIGDMThread } from '.';
import { ResourceType } from '../../../types/globalTypes';

export function getThreadTitle(thread: GetThreadQuery_thread | IThread, users?: GetAllUsersQuery_users[]) {
  const { members, lastMessage, messages } = thread;
  const {
    sortedSocialSchemasByName,
  } = useMemberFieldPartitions({});
  const firstMember = first(members);

  const instagramSchema = find(sortedSocialSchemasByName, { name: 'Instagram' });

  if (isIGDMThread(thread)) {
    const recipientHandle = getInstagramRecipientHandle(lastMessage);
    if (isNil(firstMember)) {
      return `@${recipientHandle}`;
    }
    const name = get(firstMember?.fields, instagramSchema.id, null);
    return name ? `@${name}` : `@${recipientHandle}`;
  }

  const sendersSet = new Set(map(messages, (message) => {
    const from = message.payload.from;
    if (from) {
      const senderName = find(users, { email: from })?.name || find(members, { email: from })?.name;
      return senderName || message.payload.from;
    } else if (firstMember) {
      return firstMember.name;
    }
    return '';
  }));

  return Array.from(sendersSet).join(', ');
}

function getInstagramRecipientHandle(lastMessage: IThreadMessage) {
  const { messageResources } = useResourceContext();
  const igAccounts = [lastMessage?.payload?.from, ...lastMessage?.payload?.to];
  const resourceInfo = find(
    messageResources,
    (resource) => resource?.type === ResourceType.IGDM
      && igAccounts.includes(resource?.authProvider?.userExternalDisplayId),
  );
  const senderHandle = resourceInfo?.authProvider?.userExternalDisplayId;
  const recipientHandle = igAccounts.filter((account) => account !== senderHandle);
  return recipientHandle;
}

export function getThreadLastMessage(thread: IThread, userExternalDisplayId: string) {
  if (!isNil(thread.lastMessage?.payload.story?.mention)) {
    return getMentionIGDMMessage(thread.lastMessage?.payload?.from === userExternalDisplayId, thread.lastMessage?.payload.to[0], thread.lastMessage?.payload.from);
  }

  if (!isNil(thread.lastMessage?.payload.attachments) && !isEmpty(thread.lastMessage?.payload.attachments)) {
    let message = `sent a${thread.lastMessage?.payload.attachments[0].type === IGDMMessageAttachmentType.IMAGE ? 'n image' : ' video'}`;
    if (thread.lastMessage?.payload?.from === userExternalDisplayId) {
      message = `You ${message}`;
    } else {
      message = `@${thread.lastMessage?.payload?.from} ${message} to you`;
    }

    return message;
  }

  return unescape(thread.lastMessage?.snippet);
}
