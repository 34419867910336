import React from 'react';
import { TActions, TState } from '@frontend/applications/PaymentsApp/hooks/usePayments/state';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useGetBudgetAccounts } from '@frontend/app/hooks/budgetAllocation/useGetBudgetAccounts';
import { AssignPaymentTo, PaymentCreationSource } from '@frontend/applications/PaymentsApp/types';
import { useGetBudgetPeriodDetails } from '@frontend/app/hooks/budgetAllocation/useGetBudgetPeriodDetails';
import { Alert, Spinner, Typography } from '@revfluence/fresh';
import styles from './PaymentReview.scss';
import { StepHeader } from '../common/StepHeader';
import { StepDescription } from '../common/StepDescription';
import { PaymentDetail } from '../common/PaymentDetail';
import { BudgetTrackerReview } from '../common/BudgetTrackerReview/BudgetTrackerReview';

const { useEffect } = React;
const { Title } = Typography;
interface IProps {
  actions: TActions;
  state: TState;
}
export const PaymentReview: React.FC<IProps> = (props) => {
  const { state, actions } = props;
  const { workflowActionParameters } = state.paymentCreationSource === PaymentCreationSource.REASSIGN
      ? { workflowActionParameters: null }
      : useApplication();

  const { budgetAccounts, loading: budgetsLoading } = useGetBudgetAccounts({
    variables: {},
  });
  const { periodDetails, loading: budgetsPeriodLoading } = useGetBudgetPeriodDetails({
    variables: {
      programId:
        state.assignPaymentTo === AssignPaymentTo.Project
        && state.paymentCreationSource === PaymentCreationSource.PROJECT
          ? state.selectedProgramId
          : null,
    },
    skip: state.assignPaymentTo === AssignPaymentTo.Other,
  });

  const currentProgram = state.programs.find((program) => program.id === workflowActionParameters?.programId);
  useEffect(() => {
    if (!budgetsLoading && budgetAccounts?.length) {
      actions.setBudgetAccounts(budgetAccounts);
      actions.setSelectedBudgetAccount(budgetAccounts[0].id);
    }
  }, [budgetsLoading, budgetAccounts, actions]);
  if (budgetsLoading || budgetsPeriodLoading) {
    return <Spinner />;
  }
  return (
    <div className={styles.paymentReviewContainer}>
      {
      !state.budgetAccounts.length && <Alert message="Please set up a fiscal year and master budget before sending a payment" type="error" />
      }
      {state.paymentCreationSource === PaymentCreationSource.REASSIGN ? (
        <Title level={5}>
          Payment of
          {' '}
          {state.selectedBudgetReassign.length}
          {' '}
          Members
        </Title>
      ) : state.paymentCreationSource === PaymentCreationSource.SALES_TRACKING ? null : (
        <StepHeader
          name={state.memberInfo.name}
          profileImage={state.memberInfo.profileImage}
          programImage={currentProgram?.splashImageUrl}
          programName={currentProgram?.title}
        />
      )}
      <StepDescription
        title="Review Payment"
        description="Confirm payment method for this payment. If necessary, additional funds can be added to your account in Payment Settings"
      />
      <div className={styles.main}>
        <div className={styles.left}>
          <BudgetTrackerReview
            actions={actions}
            state={state}
            programId={workflowActionParameters?.programId}
            projectBudgetExist={!!periodDetails?.length}
          />
        </div>
        <div className={styles.right}>
          <PaymentDetail
            amountPaying={state.amountPaying}
            paymentSources={state.paymentSource}
            brief={null}
            state={state}
          />
        </div>
      </div>
    </div>
  );
};
