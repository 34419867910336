import * as React from 'react';
import cx from 'classnames';
import { isArray } from 'lodash';

import { Button } from '@components';

import { getLabelForFieldType } from './utils';
import { FieldForm } from './FieldForm';

import styles from './FieldListItem.scss';

const { useState, useEffect } = React;

interface ISchema {
  id?: number | null;
  type: string;
  name: string;
  choices?: Array<string>;
}

interface IProps {
  field: ISchema;
  isExistingField?: boolean;
  saving?: boolean;
  adding?: boolean;
  disabled?: boolean;
  defaultShowForm?: boolean;
  editable?: boolean;

  className?: string;

  onSaveField?(newField: ISchema);
  onRemoveField?(field: ISchema);
  onAddExistingSchema?(schemaId: number);
}

export const FieldListItem: React.FunctionComponent<IProps> = React.memo((props) => {
  const [showForm, setShowForm] = useState<boolean>(props.defaultShowForm);
  const [waiting, setWaiting] = useState<boolean>(false);

  useEffect(() => {
    if (props.saving || props.adding) {
      setWaiting(true);
    } else if (waiting) {
      setShowForm(false);
      setWaiting(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.saving, props.adding]);

  return (
    <div
      className={cx(
        styles.FieldListItem,
        props.className,
        { [styles.existing]: props.isExistingField },
      )}
    >
      {showForm && (
        <FieldForm
          className={styles.fieldForm}
          field={props.field}
          onCancel={setShowForm.bind(this, false)}
          onRemoveField={props.onRemoveField}
          onSaveField={props.onSaveField}
          onAddExistingSchema={props.onAddExistingSchema}
          saving={props.saving}
          disabled={props.disabled}
          showDelete={!props.isExistingField}
        />
      )}
      {!showForm && (
        <>
          <div className={styles.text}>
            <div className={styles.name}>{props.field.name}</div>
            <div className={styles.type}>
              {getLabelForFieldType(props.field.type, isArray(props.field.choices))}
            </div>
          </div>
          <div className={styles.actions}>
            {/* @todo. remove this section and appropriate graphql requests */}
            {/* {props.isExistingField && (
              <Button
                theme="info"
                label="Select"
                className={styles.btn}
                onClick={props.onAddExistingSchema.bind(this, props.field.id)}
                disabled={props.disabled}
                icon={props.adding && <SpinnerIcon size={16} />}
              />
            )}
            {!props.isExistingField && (
              <Button
                theme="info"
                label="Deselect"
                className={styles.btn}
                onClick={props.onRemoveField && props.onRemoveField.bind(this, props.field, false)}
                icon={props.saving && <SpinnerIcon size={16} />}
                disabled={props.disabled}
              />
            )} */}
            {props.editable && (
              <Button
                theme="info"
                label="Edit"
                className={styles.btn}
                onClick={setShowForm.bind(this, true)}
                disabled={props.disabled}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

FieldListItem.defaultProps = {
  isExistingField: false,
  defaultShowForm: false,
  adding: false,
  editable: false,
};

FieldListItem.displayName = 'FieldListItem';
