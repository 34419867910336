import * as React from 'react';

import { ClientFeature } from '@frontend/app/constants';
import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';
import {
  analyticsServerApiEndpoint,
  backendServerApiEndpoint,
  backendServerWebEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import { useAuth } from '@frontend/context/authContext';
import { useHistory } from 'react-router-dom';
import { useClientFeatureEnabled } from '../../../app/hooks';
import { IAccessTableRowData } from '../components/AccessTable/AccessTable';
import { useAccessData } from '../hooks/useAccessData';
import { usePagedAccessData } from '../hooks/usePagedAccessData';
import { AdvertiserAccessPage as AdvertiserAccessPageComponent } from '../pages/AdvertiserAccessPage/AdvertiserAccessPage';

interface IProps {
  clientId: string;
  limit: number;
  page: number;
}

const AdvertiserAccessContainer = ({
  clientId,
  limit,
  page,
}: IProps) => {
  const { replace } = useHistory();
  const isInfluencerWhitelistingEnabled = useClientFeatureEnabled(ClientFeature.UCE_INFLUENCER_WHITELISTING);
  const baseUrl = `${backendServerApiEndpoint()}/advertiser_access`;
  const {
    data, isLoading, error,
  } = useAccessData(baseUrl, clientId, limit, page);
  if (isInfluencerWhitelistingEnabled === false) {
    replace('/');
    return null;
  }

  const fetchMoreData = async (page: number, fetchSize: number): Promise<IAccessTableRowData[]> => usePagedAccessData(baseUrl, clientId, fetchSize, page);
  return (
    <AdvertiserAccessPageComponent
      tableData={data.requirements}
      totalCount={data.totalCount}
      pageSize={limit}
      fetchMoreData={fetchMoreData}
      usageLimit={data.usageLimit}
      currentMonthlyUsage={data.currentMonthlyUsage}
      errorMessage={error?.message}
      isLoading={isLoading}
    />
  );
};

export const AdvertiserAccessPage = () => {
  const { clientInfo } = useAuth();
  return (
    <ApplicationProvider
      backendServerApiEndpoint={backendServerApiEndpoint()}
      analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
      backendServerWebEndpoint={backendServerWebEndpoint()}
      clientId={clientInfo.id}
      clientName={clientInfo.name}
    >
      <AdvertiserAccessContainer
        clientId={clientInfo.id}
        limit={200}
        page={0}
      />
    </ApplicationProvider>
  );
};
