import * as React from 'react';
import {
  first, isEmpty, map, size, sortBy, toLower,
} from 'lodash';

import {
  Avatar,
  Button,
  Select,
  Space,
} from '@revfluence/fresh';

import { LoadSpinner, Notice } from '@components';
import { IClient } from '@frontend/app/hooks';
import { ShopIcon } from '@revfluence/fresh-icons/solid';

import { useApolloClientForAspirex } from '@frontend/applications/AffiliatesApp/hooks/useApolloClientForAspirex';
import { GET_CLIENT_LOGO_QUERY } from '@frontend/app/queries/GetClientLogoQuery';
import { logger } from '@common';
import styles from './ClientSelectView.scss';
import { useClientSelectContext, ClientSelectProvider } from './ClientSelectContext';

const brandImages = [
  {
    src: 'https://storage.googleapis.com/aspirex-dev-static.aspireiq-dev.com/app/public/images/monos2x.png',
  },
  {
    src: 'https://storage.googleapis.com/aspirex-dev-static.aspireiq-dev.com/app/public/images/truff2x.png',
  },
  {
    src: 'https://storage.googleapis.com/aspirex-dev-static.aspireiq-dev.com/app/public/images/dyson2x.png',
  },
  {
    src: 'https://storage.googleapis.com/aspirex-dev-static.aspireiq-dev.com/app/public/images/trueClassic2x.png',
  },
  {
    src: 'https://storage.googleapis.com/aspirex-dev-static.aspireiq-dev.com/app/public/images/samsung2x.png',
  },
  {
    src: 'https://storage.googleapis.com/aspirex-dev-static.aspireiq-dev.com/app/public/images/walmart2x.png',
  },
  {
    src: 'https://storage.googleapis.com/aspirex-dev-static.aspireiq-dev.com/app/public/images/m%26m2x.png',
  },
  {
    src: 'https://storage.googleapis.com/aspirex-dev-static.aspireiq-dev.com/app/public/images/loreal2x.png',
  },
];
const {
  useState, useLayoutEffect, useEffect, useMemo, useCallback,
} = React;
const _fetchUserClients = async (userId: string, token: string) => {
  try {
    const resp = await fetch(`/api/clients/get_for_user?userId=${userId}`, {
      method: 'GET',
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    const json = await resp.json();

    if (resp.status === 200) {
      return json;
    }

    throw new Error(json?.message);
  } catch (err) {
    console.log(err);

    throw err;
  }
};
const { Option } = Select;
const ClientSelectViewInternal: React.FunctionComponent = React.memo(() => {
  const {
    user, token, authError, isAuthenticated, logout,
  } = useClientSelectContext();
  const aspirexApolloClient = useApolloClientForAspirex(false, token);
  const [loading, setLoading] = useState(true);
  const [clientLogoLoading, setClientLogoLoading] = useState(true);
  const [fetchClientsError, setFetchClientError] = useState<string>(null);
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientLogos, setClientLogos] = useState([]);
  const errorMessage = useMemo(() => authError?.message || fetchClientsError, [authError, fetchClientsError]);
  const logIntoClient = useCallback((clientId: string) => window.location.href = `${window.origin}/client/${clientId}`, []);
  const [selectedClient, setSelectedClient] = useState<string>(null);
  const isAspireUser = useMemo(() => {
    const [_username, domain] = user.email.split('@');
    return domain === 'aspireiq.com';
  }, [user]);

  useLayoutEffect(() => {
    async function fetchUserClients() {
      try {
        const clients: IClient[] = await _fetchUserClients(user.sub, token);

        setClients(clients);
      } catch (err) {
        console.log({
          err,
        });
        setFetchClientError('There\'s an error when trying to load the client info, and if this errors persists, please contact help@aspireiq.com.');
      } finally {
        setLoading(false);
      }
    }

    if (isAuthenticated) {
      fetchUserClients();
    } else if (authError) {
      setLoading(false);
    }
  }, [isAuthenticated, authError, token, user, logIntoClient]);
  useEffect(() => {
    if (!loading && !isEmpty(clients)) {
      if (size(clients) === 1) {
        setLoading(true);

        logIntoClient(first(clients).id);
      }
    }
  }, [clients, loading, logIntoClient]);
  useEffect(() => {
    const fetchClientLogo = async () => {
      setClientLogoLoading(true);
      try {
        const clientLogos = await Promise.all(clients.map(async (client) => {
          const { data } = await aspirexApolloClient.query({
            query: GET_CLIENT_LOGO_QUERY,
            variables: { clientId: client.id },
          });
          return { clientId: client.id, logoUrl: data?.getBackendServerClientByClientId?.logo_url };
        }));

        setClientLogos((prevLogoDetail) => [
          ...(prevLogoDetail || []),
          ...clientLogos,
        ]);
      } catch (error) {
        logger.error(error.message);
      } finally {
        setClientLogoLoading(false);
      }
    };
    fetchClientLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  return (
    isAspireUser ? (
      <div className={styles.clientViewPage}>
        <div className={styles.leftColumn}>
          <header className={styles.header}>
            <div className={styles.brandLogo}>
              <img
                src="https://storage.googleapis.com/aspireiq-widgets/assets/aiq_logo_new.svg"
                alt="brand-logo"
              />
            </div>
          </header>
          <div className={styles.clientContent}>
            {(loading && clientLogoLoading) && <LoadSpinner />}
            {!loading && !clientLogoLoading && (
              <>
                {!errorMessage && size(clients) > 1 ? (
                  <>
                    <h2>Select your brand</h2>
                    <p className="font-inter">Please select the organization you wish to log in to.</p>
                    <div>
                      <Space size={28} direction="vertical">
                        <Select
                          size="large"
                          onChange={(clientId) => setSelectedClient(clientId)}
                          defaultValue="Select"
                          open
                        >
                          {map(sortBy(clients, (c) => toLower(c.name)), (client) => {
                            const logo = clientLogos.find((logos) => logos.clientId === client.id);
                            return (
                              <Option key={client.id} value={client.id}>
                                <Avatar
                                  src={logo?.logoUrl || <ShopIcon width={15} height={12} color="#5D6974" className={styles.defaultClientIcon} />}
                                  style={{ marginRight: 8, background: '#E5E8EB' }}
                                />
                                {client.name}
                              </Option>
                            );
                          })}
                        </Select>

                        <Button
                          className={styles.continueBtn}
                          size="large"
                          onClick={() => logIntoClient(selectedClient)}
                          type="primary"
                          disabled={!selectedClient}
                        >
                          Continue
                        </Button>
                        <Button
                          className={styles.logOutBtn}
                          onClick={logout}
                          type="text"
                          style={{ color: '#16282D' }}
                        >
                          Log out
                        </Button>
                      </Space>
                    </div>
                  </>
                ) : (!errorMessage && isEmpty(clients)) && (
                  <div className={styles.error}>
                    <h2>Sorry</h2>
                    <p className="font-inter">Looks like you don’t have access to any clients.</p>
                    <Button
                      className={styles.continueBtn}
                      size="large"
                      onClick={logout}
                      type="primary"
                    >
                      Go back
                    </Button>
                  </div>
                )}
              </>
            )}
            {errorMessage && (
              <div className={styles.error}>
                <Notice type="error" showDivider>
                  {authError?.message || fetchClientsError}
                </Notice>
                <Button onClick={logout}>
                  Go Back
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className={styles.rightColumn}>
          <img src="https://storage.googleapis.com/aspirex-dev-static.aspireiq-dev.com/app/public/images/login-group-img.png" alt="Aspire Logo" />
          <h3>
            Trusted by Leading
            <br />
            E-commerce Brands
          </h3>
          <div className={styles.brands}>
            {brandImages.map((image, index) => (
              <div className={styles.brandImg} key={index}>
                <img src={image.src} alt={`brand${index}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    ) : (
      <div className={styles.ClientSelectView}>
        <div className={styles.card}>
          {(loading && clientLogoLoading) && <LoadSpinner />}
          {!loading && !clientLogoLoading && (
            <>
              <div className={styles.logo} />
              <div className={styles.title}>Brand Login</div>
              {errorMessage && (
                <div className={styles.error}>
                  <Notice type="error" showDivider>
                    {authError?.message || fetchClientsError}
                  </Notice>
                  <Button onClick={logout}>
                    Go Back
                  </Button>
                </div>
              )}
              {!errorMessage && isEmpty(clients) && (
                <div className={styles.error}>
                  <Notice showDivider>
                    Looks like you don't have access to any clients.
                  </Notice>
                  <Button onClick={logout}>
                    Go Back
                  </Button>
                </div>
              )}
              {!errorMessage && size(clients) > 1 && (
                <>
                  <div className={styles.text}>
                    Please select the organization you wish to log in to.
                  </div>
                  <div className={styles.list}>
                    {map(sortBy(clients, (c) => toLower(c.name)), (client) => (
                      <Button key={client.id} className={styles.button} size="large" onClick={() => logIntoClient(client.id)}>
                        {client.name}
                      </Button>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    )
  );
});
ClientSelectViewInternal.displayName = 'ClientSelectViewInternal';

export const ClientSelectView = React.memo((props) => (
  <ClientSelectProvider>
    <ClientSelectViewInternal {...props} />
  </ClientSelectProvider>
));

ClientSelectView.displayName = 'ClientSelectView';
