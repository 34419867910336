/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  bind,
  chain,
  filter,
  isArray,
  isEmpty,
  isFunction,
  map,
  size,
  sortBy,
  toString,
} from 'lodash';

import {
  LikedCircleBlueIcon,
  SendOfferIcon,
  ShareIcon,
  Button,
  doesAccountBelongToProgram,
  IInviteContext,
  Invite,
  ReasonsDescriptions,
  useInviteContext,
  Favorite,
  ProgramsList,
  IToastRefHandles,
  IRowData,
  ITableConfig,
  ITableRefHandles,
  Table,
  countries,
  IProgram,
  ISocialAccount,
} from '@components';

import {
  useCommunitiesQuery,
  useGetAllProjectsQuery,
} from '@frontend/app/hooks';

import { useForceUpdate } from '@frontend/utils/hooks';

import CampaignInviteButton from './CampaignInviteButton';
import AddToListButton from './AddToListButton';
import { tableViewColumnConfig } from './tableViewColumnConfig';
import { ICreatorList } from './redux/creatorListModel';

const {
 useImperativeHandle, useEffect, useMemo, useRef, useState,
} = React;
import styles from './TableView.scss';

interface IOwnProps {
  className?: string;
  customConfig?: ITableConfig;

  onCreatorSelected(socialAccount: ISocialAccount);

  sendBulkOffer(socialAccounts: ISocialAccount[]);
  goToManage(relationId: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>);
  exportCsv(socialAccounts: ISocialAccount[]);

  onReachBottom();

  showCreateFeatures: boolean;
  toastRef?: React.RefObject<IToastRefHandles>;
  forwardedRef?: React.RefObject<ITableViewRef>;

  selectedSocialAccounts?: ISocialAccount[];
  onSelectedRowsChange?: (socialAccounts: ISocialAccount[]) => void;
}

interface IStateProps {
  socialAccounts: ISocialAccount[];
}

type TInviteContextProps = Partial<Pick<IInviteContext, 'memberPrograms'>>;

type IProps = IOwnProps & IStateProps & TInviteContextProps;

export interface ITableViewRef {
  container: HTMLDivElement;
  forceUpdate: () => void;
}

const mapStateToProps = (state: ICreatorList): IStateProps => ({
    socialAccounts: state.socialAccounts,
  });

const TableViewComponent: React.FC<IProps> = (props) => {
  const {
    className,
    customConfig,
    exportCsv: exportCsvProp,
    forwardedRef,
    goToManage,
    onCreatorSelected,
    onReachBottom,
    sendBulkOffer: sendBulkOfferProp,
    showCreateFeatures = true,
    socialAccounts,
    toastRef,
  } = props;

  // TODO: Handle this one better
  const {
    memberPrograms = null,
    selectedProgramId = null,
    isWorkflowEnabled = false,
  } = showCreateFeatures ? {} : useInviteContext();

  const [selectedUncontrolledSocialAccounts, setSelectedUncontrolledSocialAccount] = useState<ISocialAccount[]>();

  const [selectedSocialAccounts, setSelectedSocialAccouts] = useState<ISocialAccount[]>([]);

  const isSelectionControlled = isFunction(props.onSelectedRowsChange);

  const {
    loading: isFetchingCommunities,
    data: {
      communities = null,
    } = {},
  } = useCommunitiesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: {
      projects = [],
    } = {},
    loading: isFetchingAllPrograms,
  } = useGetAllProjectsQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setSelectedSocialAccouts(isSelectionControlled ? props.selectedSocialAccounts : selectedUncontrolledSocialAccounts);
  }, [isSelectionControlled, props.selectedSocialAccounts, selectedUncontrolledSocialAccounts]);

  const ref = useRef<HTMLDivElement>();
  const tableRef = useRef<ITableRefHandles>();
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    // Force selection on table if it's a controlled component.
    if (
      isSelectionControlled
        && isFunction(tableRef?.current?.selectRowsWithIds)
    ) {
      tableRef.current.selectRowsWithIds(
        map(selectedSocialAccounts, (socialAccount) => `${socialAccount.id}`),
        true,
      );
    }
  }, [tableRef, selectedSocialAccounts, isSelectionControlled]);

  useImperativeHandle(forwardedRef, () => ({
    container: ref.current,
    selectedSocialAccounts,
    forceUpdate: () => forceUpdate(),
    forceSelectedSocialAccounts: setSelectedUncontrolledSocialAccount,
  }));

  const filterOutSocialAccountsByProgramId = (socialAccounts: ISocialAccount[], programId: IProgram['id']) => (
    filter(
      socialAccounts,
      (socialAccount) => !doesAccountBelongToProgram(socialAccount, programId, memberPrograms),
    )
  );

  const onSelectedRowsChange = (socialAccounts: ISocialAccount[]) => {
    if (isSelectionControlled) {
      props.onSelectedRowsChange(socialAccounts);
    } else {
      setSelectedUncontrolledSocialAccount(socialAccounts);
    }
  };

  const clearSelectedRows = () => {
    setSelectedSocialAccouts([]);
    if (isFunction(tableRef?.current?.unsetSelectedRows)) {
      tableRef.current.unsetSelectedRows();
    }
  };

  const sendBulkOffer = () => {
    if (isFunction(sendBulkOfferProp)) {
      sendBulkOfferProp(selectedSocialAccounts);
    }
  };

  const exportCsv = () => {
    if (isFunction(exportCsvProp)) {
      exportCsvProp(selectedSocialAccounts);
    }
  };

  /**
   * Columns
   */
  const columnConfig = [...tableViewColumnConfig];
  if (!showCreateFeatures) {
    columnConfig.splice(4, 0, {
      headerName: isWorkflowEnabled ? 'Projects' : 'Programs',
      field: 'programs',
      width: 240,
      cellType: 'projects',
      grow: true,
    });
  }

  // construct data for table
  const data: IRowData[] = map(socialAccounts, (socialAccount) => {
    const isPreviouslyInvited = doesAccountBelongToProgram(socialAccount, selectedProgramId, memberPrograms);

    const disabledReason = (() => {
      if (!socialAccount.email && !socialAccount.is_in_instagram_marketplace) {
        return ReasonsDescriptions.NotContactable.message as string;
      }

      if (isPreviouslyInvited) {
        return ReasonsDescriptions.HasStatus.message as string;
      }

      return '';
    })();

    return {
      id: toString(socialAccount.id),
      username: socialAccount.username || socialAccount.name,
      usernameHandler: isFunction(onCreatorSelected) && bind(onCreatorSelected, {}, socialAccount),
      contents: socialAccount.images,
      reach: socialAccount.reach,
      engagement: socialAccount.engagement,
      engagement_ratio: socialAccount.engagement_ratio,
      networks: [
        {
          type: socialAccount.network_identifier,
        },
      ],
      country: countries[socialAccount.country_code],
      invited:
        socialAccount.invite && socialAccount.invite.approved ? <LikedCircleBlueIcon /> : '',
      relationId: socialAccount.has_relationship && socialAccount.relation_id,
      relationHandler: isFunction(goToManage) && bind(goToManage, {}, socialAccount.relation_id),
      addedBy: socialAccount.favorite_element && socialAccount.favorite_element.by_user_name,
      topAudienceCountry: (
        socialAccount.demographics_report
        && socialAccount.demographics_report.country
        && socialAccount.demographics_report.country.length
      )
        ? countries[sortBy(
          socialAccount.demographics_report.country[0].values,
          (value) => -value.percentage,
        )[0].dimension_values[0]]
        : '',
      topAudienceAge: (
        socialAccount.demographics_report
        && socialAccount.demographics_report.age
        && socialAccount.demographics_report.age.length > 0
      )
        ? sortBy(
          socialAccount.demographics_report.age[0].values,
          (value) => -value.percentage,
        )[0].dimension_values[0]
        : '',
      topAudienceInterests: (
        socialAccount.demographics_report
        && socialAccount.demographics_report.interest
        && socialAccount.demographics_report.interest.length
      )
        ? filter(
          sortBy(
            socialAccount.demographics_report.interest[0].values,
            (value) => -value.percentage,
          ).slice(0, 3),
          (value) => value.dimension_values[0],
        ).join(',')
        : '',
      programs: !showCreateFeatures && (
        <ProgramsList
          className={styles.programsList}
          socialAccount={socialAccount}
          showTooltip
          hideIcon
        />
      ),
      disableSelection: !showCreateFeatures && (
        (!socialAccount.email && !socialAccount.is_in_instagram_marketplace)
          || isPreviouslyInvited
      ),
      disabledReason,
      _raw: socialAccount,
    };
  });

  const prospect = useMemo(
    () => (
      !isEmpty(selectedSocialAccounts)
        ? chain(filterOutSocialAccountsByProgramId(selectedSocialAccounts, selectedProgramId))
          .filter((socialAccount) => socialAccount.can_contact || socialAccount.has_email)
          .map((socialAccount) => ({ socialAccount }))
          .value()
        : null
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterOutSocialAccountsByProgramId,
      selectedProgramId,
      selectedSocialAccounts,
      showCreateFeatures,
      selectedSocialAccounts,
    ],
  );

  const renderActions = () => {
    // TODO: call onchange from data table instead
    const accountIds = map(selectedSocialAccounts, (socialAccount) => socialAccount.id);
    const updatedSelectedSocialAccounts = socialAccounts.filter(
      (socialAccount) => accountIds.includes(socialAccount.id),
    );
    const uninvitedAccounts = updatedSelectedSocialAccounts.filter(
      (socialAccount) => !(socialAccount.invite && socialAccount.invite.approved),
    );

    const getAiqActions = () => (
      <>
        <Button
          label={`Send ${accountIds.length > 1000 ? '999+' : accountIds.length} Offers`}
          icon={<SendOfferIcon size={20} />}
          disabled={isEmpty(accountIds)}
          className={cx(styles.button, styles.sendBulkOffer)}
          onClick={sendBulkOffer}
        />
        <CampaignInviteButton classNames={[styles.button]} socialAccounts={uninvitedAccounts} />
        <Button
          label="Export"
          icon={<ShareIcon size={18} />}
          className={styles.button}
          theme="info"
          disabled={isEmpty(selectedSocialAccounts)}
          onClick={exportCsv}
        />
        <AddToListButton accountIds={accountIds} classNames={[styles.button]} />
      </>
    );

    const getAxActions = () => (
      <>
        <div className={styles.selectedRowsCount}>
          {size(selectedSocialAccounts)}
          {' '}
          selected
        </div>
        <Favorite
          prospect={prospect}
          className={styles.favorite}
          communities={communities}
          preloadProspects={map(socialAccounts, (socialAccount) => ({ socialAccount }))}
          onInvite={() => {
            forceUpdate();
            clearSelectedRows();
          }}
          onRemove={() => {
            forceUpdate();
            clearSelectedRows();
          }}
          shouldClosePopoverOnActionDone
        />
        <Invite
          prospect={prospect}
          preloadProspects={map(socialAccounts, (socialAccount) => ({ socialAccount }))}
          communities={communities}
          projects={projects}
          isFetchingCommunities={isFetchingCommunities}
          isFetchingAllPrograms={isFetchingAllPrograms}
          mode="withButton"
          withBorder
          onInvite={(prospect, programs) => {
            forceUpdate();
            if (toastRef && toastRef.current) {
              const subject = isArray(prospect)
                ? (
                  prospect.length > 1
                    ? `${prospect.length} creators`
                    : <span>{prospect[0].socialAccount?.username || prospect[0].socialAccount?.display_name}</span>
                )
                : <span>{prospect.socialAccount?.username || prospect.socialAccount?.display_name}</span>;
              toastRef.current.showMessage({
                content: (
                  <>
                    Invited
                    {' '}
                    {subject}
                    {' '}
                    to&nbsp;
                    <span>{map(programs, (p) => p.title).join(', ')}</span>
                  </>
                ),
                type: 'success',
              });
            }
            clearSelectedRows();
          }}
          onProgramSelect={(programId: IProgram['id']) => {
            forceUpdate();
            if (selectedProgramId !== programId) {
              // clear selection when selected program changes
              // because invite status will be updated for accounts
              clearSelectedRows();
            }
          }}
        />
      </>
    );

    return showCreateFeatures
      ? getAiqActions()
      : getAxActions();
  };

  return (
    <div
      className={cx(styles.TableView, className)}
      ref={ref}
    >
      {!isEmpty(socialAccounts) && (
        <Table
          ref={tableRef}
          columns={columnConfig}
          config={{
            rowHeight: 56,
            ...customConfig,
          }}
          paddingBottom={0}
          headerActions={!isEmpty(socialAccounts) && renderActions()}
          data={data}
          onSelectedDataChange={onSelectedRowsChange}
          onReachedBottom={onReachBottom}
          className={(styles as any).table}
        />
      )}
    </div>
  );
};

TableViewComponent.displayName = 'TableViewComponent';

const ConnectedTableView = connect<IStateProps, unknown, IOwnProps>(mapStateToProps)(TableViewComponent);

export const TableView = React.forwardRef((props: IOwnProps, ref: React.RefObject<ITableViewRef>) => (
  <ConnectedTableView {...props} forwardedRef={ref} />
));

TableView.displayName = 'TableView';
