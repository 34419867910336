/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_11al7_157 {
  display: flex;
}

._justify-content-space-between_11al7_161 {
  justify-content: space-between;
}

._tabular-nums_11al7_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_11al7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._iconContainer_11al7_178 {
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #5DB884;
}

._sackDollar_11al7_188 {
  color: #FFFFFF;
  font-size: 1.25rem;
}

._settings_11al7_193 {
  color: var(--neutral-color-palette-gray-9, #1F1F21);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
  width: 2rem;
}

._headerRightSection_11al7_207 {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

._tabsHeader_11al7_213 {
  background: #FFFFFF;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  padding-right: 2rem;
  height: 2.875rem;
  padding-left: 1rem;
  border-bottom: 0.0625rem solid #D9D9D9;
}

._contentContainer_11al7_227 {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

._loadSpinner_11al7_234 {
  margin: auto;
}