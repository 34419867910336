import { useCallback, useMemo, useState } from 'react';
import { isNil } from 'lodash';
import { format } from 'date-fns';
import { DateRangeOptions, IDateRange, IDateRangeSettings } from '../components/DateFilter';

const getDateRangeForOption = (option: DateRangeOptions): IDateRange | undefined => {
  switch (option) {
    case DateRangeOptions.THIS_WEEK: {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const startOfWeek = new Date(today);

      if (dayOfWeek !== 1) {
        startOfWeek.setDate(today.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)); // Go back to the most recent Monday
      }

      return { startDate: startOfWeek, endDate: today };
    }
    case DateRangeOptions.LAST_WEEK: {
      const today = new Date();
      const lastMonday = new Date(today.setDate(today.getDate() - today.getDay() + 1 - 7));
      const lastSunday = new Date(today.setDate(today.getDate() - today.getDay() + 7));

      return { startDate: lastMonday, endDate: lastSunday };
    }
    case DateRangeOptions.LAST_MONTH: {
      const startOfThisMonth = new Date();
      startOfThisMonth.setDate(1);
      const endOfLastMonth = new Date(startOfThisMonth.getTime());
      endOfLastMonth.setDate(endOfLastMonth.getDate() - 1);
      const startOfLastMonth = new Date(endOfLastMonth.getTime());
      startOfLastMonth.setDate(1);
      return { startDate: startOfLastMonth, endDate: endOfLastMonth };
    }
    case DateRangeOptions.MTD: {
      const startOfThisMonth = new Date();
      startOfThisMonth.setDate(1);
      return { startDate: startOfThisMonth, endDate: null };
    }
    case DateRangeOptions.QTD: {
      const today = new Date();
      const quarter = Math.floor((today.getMonth() / 3));
      const startOfQuarter = new Date(today.getFullYear(), quarter * 3, 1);

      return { startDate: startOfQuarter, endDate: today };
    }
    case DateRangeOptions.LAST_6_MONTHS: {
      const sixMonthsAgo = new Date();
      sixMonthsAgo.setDate(sixMonthsAgo.getDate() - 6 * 30);
      return { startDate: sixMonthsAgo, endDate: null };
    }
    case DateRangeOptions.YTD: {
      const startOfThisYear = new Date();
      startOfThisYear.setDate(1);
      startOfThisYear.setMonth(0);
      return { startDate: startOfThisYear, endDate: null };
    }
    default:
      return undefined;
  }
};

export interface IDateRangeVariables {
  endDate: string | null;
  startDate: string | null;
}
export const useMapDateRangeToVariables = (range: IDateRange | undefined): IDateRangeVariables => useMemo((): IDateRangeVariables => {
    let endDate = null;
    let startDate = null;
    if (!isNil(range)) {
      if (!isNil(range.endDate)) {
        endDate = format(range.endDate, "yyyy-MM-dd'T'23:59:59'Z'");
      }
      if (!isNil(range.startDate)) {
        startDate = format(range.startDate, "yyyy-MM-dd'T'00:00:00'Z'");
      }
    }

    return {
      endDate,
      startDate,
    };
  }, [range]);

export const useDateFilterSettings = (initialDateOption: DateRangeOptions): IDateRangeSettings => {
  const [selectedOption, setSelectedOption] = useState<DateRangeOptions>(
    initialDateOption,
  );
  const [earliestDate, setEarliestDate] = useState<Date>(new Date());
  const [dateRange, setDateRange] = useState<IDateRange>(
    getDateRangeForOption(initialDateOption),
  );
  const onChange: IDateRangeSettings['onChange'] = useCallback(
    (option: DateRangeOptions, range?: IDateRange) => {
      setSelectedOption(option);
      if (option === DateRangeOptions.CUSTOM && !!range) {
        setDateRange(range);
      } else {
        setDateRange(getDateRangeForOption(option));
      }
    },
    [setDateRange, setSelectedOption],
  );
  return useMemo((): IDateRangeSettings => ({
    dateRange,
    onChange,
    selectedOption,
    earliestDate,
    setEarliestDate,
  }), [dateRange, onChange, selectedOption, earliestDate, setEarliestDate]);
};
