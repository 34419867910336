import React, {
 useEffect, useState, useMemo, useCallback,
} from 'react';
import {
 Table, Card, Space, Input, Button, Tag,
} from '@revfluence/fresh';
import { isEmpty } from 'lodash';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { SackDollarIcon, SplitIcon } from '@revfluence/fresh-icons/regular/esm';
import { useGetBudgetDistributionsForFiscalYear } from '@frontend/app/hooks/budgetAllocation/useGetBudgetDistributionForFiscalYear';
import { LoadSpinner } from '@components';
import styles from './Accounts.scss';
import { BudgetAccount } from './Accounts.types';

const mapResponseToState = (budgetDistributions) =>
  budgetDistributions?.map(
    (distribution) =>
      ({
        id: distribution.budgetAccountId,
        parentBudgetId: distribution.parentBudgetId,
        budget: distribution.budgetName,
        totalAmount: distribution.totalAmount,
        allocatedAmount: distribution.allocatedAmount,
        spentAmount: distribution.spentAmount,
        availableAmount: distribution.availableAmount,
        committedAmount: distribution.committedAmount,
        paidCommittedAmount: distribution.paidCommittedAmount,
        paidCommissionAmount: distribution.paidCommissionAmount,
        quarterDistributions: distribution.quarterDistributions?.map((quarter) => ({
          quarterKey: quarter.quarterKey,
          quarterLabel: quarter.quarterLabel,
          quarterDistributionId: quarter.quarterDistributionId,
          totalAmount: quarter.totalAmount,
          allocatedAmount: quarter.allocatedAmount,
          spentAmount: quarter.spentAmount,
          availableAmount: quarter.availableAmount,
          committedAmount: quarter.committedAmount,
          paidCommittedAmount: quarter.paidCommittedAmount,
          paidCommissionAmount: quarter.paidCommissionAmount,
        })),
      } as BudgetAccount),
  );

interface IProps {
  fiscalYear?: string;
  quarterNames?: object;
}

const formatAmount = (value: number) =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

const getQuarterBorderColor = (quarterKey: string) => {
  switch (quarterKey) {
    case 'Q1':
      return '#FFCD5B';
    case 'Q2':
      return '#167CF4';
    case 'Q3':
      return '#1E4945';
    case 'Q4':
      return '#923562';
    default:
      return '#6C77DE';
  }
};

const getQuarterColumns = (quarterKey) => [
    {
      title: <div>Total Budget</div>,
      key: 'totalAmount' as string,
      dataIndex: 'quarterDistributions',
      className: styles.quarterAmount,
      width: 120,
      render: (data) => {
        const quarterData = data?.find((quarter) => quarter.quarterKey === quarterKey);
        return formatAmount(quarterData?.totalAmount);
      },
    },
    {
      title: <div>Allocated</div>,
      key: 'allocatedAmount' as string,
      dataIndex: 'quarterDistributions',
      className: styles.quarterAmount,
      width: 120,
      render: (data) => {
        const quarterData = data?.find((quarter) => quarter.quarterKey === quarterKey);
        return formatAmount(quarterData?.allocatedAmount);
      },
    },
    {
      title: <div>Spent</div>,
      key: 'spentAmount' as string,
      dataIndex: 'quarterDistributions',
      className: styles.quarterAmount,
      width: 120,
      render: (data) => {
        const quarterData = data?.find((quarter) => quarter.quarterKey === quarterKey);
        return formatAmount(quarterData?.spentAmount);
      },
    },
    {
      title: <div>Remaining</div>,
      key: 'availableAmount' as string,
      dataIndex: 'quarterDistributions',
      className: styles.quarterAmount,
      width: 120,
      render: (data) => {
        const quarterData = data?.find((quarter) => quarter.quarterKey === quarterKey);
        return formatAmount(quarterData?.availableAmount);
      },
    },
  ];

const BudgetAccounts = ({ fiscalYear, quarterNames }: IProps) => {
  const [searchText, setSearchText] = useState('');
  const [rawData, setRawData] = useState<BudgetAccount[] | null>(null);
  const [processedData, setProcessedData] = useState<BudgetAccount[] | null>(null);
  const [showQuarterlyAmounts, setShowQuarterlyAmounts] = useState<boolean>(false);
  const toggleQuarterlyAmounts = useCallback(() => setShowQuarterlyAmounts((prev) => !prev), [setShowQuarterlyAmounts]);
  const {
 budgetDistributionsForFiscalYear, loading, refetch, error,
} = useGetBudgetDistributionsForFiscalYear({
    variables: {
      fiscalYear: Number(fiscalYear),
    },
    fetchPolicy: 'no-cache',
    skip: !fiscalYear,
  });

  useEffect(() => {
    if (fiscalYear) {
      refetch();
    }
  }, [fiscalYear, refetch]);

  const serializedBudgetDistributions = useMemo(
    () => JSON.stringify(budgetDistributionsForFiscalYear),
    [budgetDistributionsForFiscalYear],
  );

  useEffect(() => {
    const deserializedBudgetDistributions = JSON.parse(serializedBudgetDistributions);
    if (fiscalYear && !loading && !isEmpty(deserializedBudgetDistributions)) {
      const data = mapResponseToState(deserializedBudgetDistributions);
      setRawData(data);
      setProcessedData(data);
    } else if (!fiscalYear) {
      setRawData([]);
      setProcessedData([]);
    }
  }, [fiscalYear, loading, serializedBudgetDistributions, refetch]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.toLowerCase();
      setSearchText(value);
      const filteredData = rawData.filter(
        (item) =>
          item.budget.toLowerCase().includes(value)
          || item.totalAmount.toString().includes(value)
          || item.allocatedAmount.toString().includes(value)
          || item.spentAmount.toString().includes(value)
          || item.availableAmount.toString().includes(value),
      );
      setProcessedData(filteredData);
    },
    [setSearchText, rawData, setProcessedData],
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        title: <b>Budget</b>,
        dataIndex: 'budget',
        key: 'budget',
        fixed: 'left' as const,
        sorter: (a: BudgetAccount, b: BudgetAccount) => a.budget.localeCompare(b.budget),
        className: styles.budgetNameColumn,
        render: (text: string, budget) => (
          <Space>
            <div className={styles.iconContainer}>
              <SackDollarIcon className={styles.sackDollar} />
            </div>
            <Button type="link">
              <Link to={`/budget/editBudget/${budget.id}`}>{text}</Link>
            </Button>
          </Space>
        ),
        width: '30%',
      },
      {
        title: <b>Source Budget</b>,
        dataIndex: 'parentBudgetId',
        key: 'parentBudgetId',
        className: styles.sourceBudgetColumn,
        render: (parentBudgetId: number) => {
          const parentBudget = rawData?.find((budget) => budget?.id === parentBudgetId);
          if (!parentBudget) return '-';
          return (
            <Space>
              <Tag>{parentBudget?.budget}</Tag>
            </Space>
          );
        },
        width: '30%',
      },
      {
        title: <b>Total Budget</b>,
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        className: styles.amountColumn,
        sorter: (a: BudgetAccount, b: BudgetAccount) => a.totalAmount - b.totalAmount,
        render: formatAmount,
        width: '10%',
      },
      {
        title: <b>Allocated</b>,
        dataIndex: 'allocatedAmount',
        key: 'allocatedAmount',
        className: styles.amountColumn,
        sorter: (a: BudgetAccount, b: BudgetAccount) => a.allocatedAmount - b.allocatedAmount,
        render: formatAmount,
        width: '10%',
      },
      {
        title: <b>Spent</b>,
        dataIndex: 'spentAmount',
        key: 'spentAmount',
        className: styles.amountColumn,
        sorter: (a: BudgetAccount, b: BudgetAccount) => a.spentAmount - b.spentAmount,
        render: formatAmount,
        width: '10%',
      },
      {
        title: <b>Available</b>,
        dataIndex: 'availableAmount',
        key: 'availableAmount',
        className: styles.amountColumn,
        sorter: (a: BudgetAccount, b: BudgetAccount) => a.availableAmount - b.availableAmount,
        render: formatAmount,
        width: '10%',
      },
    ];
    if (showQuarterlyAmounts && quarterNames) {
      Object.entries(quarterNames).forEach(([quarterKey, quarterName]) => {
        baseColumns.push({
          title: (
            <div style={{ borderBottom: `2px solid ${getQuarterBorderColor(quarterKey)}`, paddingBottom: '8px' }}>
              {quarterName}
            </div>
          ),
          key: quarterName as string,
          className: styles.quarter,
          // @ts-ignore
          // eslint-disable-next-line
          children: getQuarterColumns(quarterKey),
        });
      });
      baseColumns.push({
        title: (
          <div style={{ borderBottom: `2px solid ${getQuarterBorderColor('QO')}`, paddingBottom: '8px' }}>
            Others
          </div>
        ),
        key: 'Others',
        className: styles.quarter,
        // @ts-ignore
        // eslint-disable-next-line
        children: getQuarterColumns('QO'),
      });
    }

    return baseColumns;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, showQuarterlyAmounts, quarterNames]);

  if (loading && isEmpty(error)) return <LoadSpinner className={styles.loadSpinner} />;

  return (
    <Card
      title={<Space>Budget Accounts</Space>}
      extra={(
        <Space>
          <Button onClick={toggleQuarterlyAmounts} icon={(<SplitIcon />)} />
          <Input placeholder="Search" prefix={<SearchOutlined />} value={searchText} onChange={handleSearch} />
        </Space>
      )}
      className={styles.accountsTableWrapperCard}
    >
      <Table
        columns={columns}
        dataSource={processedData}
        pagination={false}
        rowClassName={styles.accountsTableRow}
        className={styles.accountsTable}
      />
    </Card>
  );
};

export default BudgetAccounts;
