import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { filter } from 'lodash';
import { message } from 'antd';

import {
 LoadSpinner, Notice, Toast, IToastRefHandles,
} from '@components';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { SocialPostAssignment } from './SocialPostAssignment';
import { SocialPostsList } from './SocialPostsList';
import { useFetchPostsData, useFetchPostData, ISocialPostArtifact } from '../useFetchSocialPostData';

import styles from './MemberSocialPostList.scss';

const {
 useEffect, useState, useMemo, useRef,
} = React;

const PAGE_SIZE = 500;

// TODO: Make design better so that we don't need this page

const UnassignedSocialPostList: React.FunctionComponent = () => {
  const toastRef = useRef<IToastRefHandles>(null);

  const [postToAssign, setPostToAssign] = useState<ISocialPostArtifact>(null);
  const [postIdToAssign, setPostIdToAssign] = useState<string>();
  const [assignedPostIds, setAssignedPostIds] = useState<string[]>([]);
  const location = useLocation();
  const history = useHistory();

  // Handle deep linking
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const params = query.get('postId');
    if (params) {
      setPostIdToAssign(JSON.parse(params));
    }
  }, [location]);

  useEffect(() => () => {
      const query = new URLSearchParams(location.search);
      query.delete('postId');
      history.replace({
        search: query.toString(),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const { backendServerApiEndpoint, memberId, clientId } = useApplication();
  const { data, loading, error } = useFetchPostsData(
    `${backendServerApiEndpoint}/social_mention`, {
      clientId,
      memberId,
      unassigned: true,
      pageSize: PAGE_SIZE,
    },
  );

  const { data: postData, loading: loadingPost, error: postError } = useFetchPostData(
    `${backendServerApiEndpoint}/social_mention`,
    clientId,
    postIdToAssign,
  );

  // Add loaded postData into state
  useEffect(() => {
    if (!postToAssign) {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      setPostToAssign(postData as any);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postData]);

  useEffect(() => {
    if (error) {
      message.error('Something went wrong');
    }
  }, [error]);

  const showSuccessToast = () => {
    const toast = toastRef.current;
    if (toast) {
      toast.showMessage({
        content: 'Post was updated',
        type: 'success',
      });
    }
  };

  const onAssignSuccess = () => {
    setAssignedPostIds(assignedPostIds.concat(postIdToAssign));
    setPostToAssign(null);
    setPostIdToAssign(null);

    showSuccessToast();
  };

  const assignPost = (post: ISocialPostArtifact) => {
    setPostToAssign(post);
    setPostIdToAssign(post.post_id);
  };

  const unassignedPosts = useMemo(() => {
    if (!data) {
      return [];
    }
    return filter(data, (post) => !assignedPostIds.includes(post.post_id));
  }, [data, assignedPostIds]);

  return (
    <div className={styles.MemberSocialPostList}>
      {!postToAssign && !postIdToAssign
        ? (
          <div>
            {loading
              ? <LoadSpinner centered />
              : <SocialPostsList posts={unassignedPosts} onPostClick={assignPost} postDisplayName="unassigned post" />}
          </div>
)
        : (
          <>
            {
            postToAssign
              ? (
                <SocialPostAssignment
                  memberId={memberId}
                  post={postToAssign}
                  onSuccess={onAssignSuccess}
                />
)
              : loadingPost
                ? <LoadSpinner centered />
                : (
                  <>
                    {postError && <Notice type="error">{postError.error_msg}</Notice>}
                  </>
)
          }
          </>
)}
      <Toast ref={toastRef} />
    </div>
  );
};

export default UnassignedSocialPostList;
