import * as React from 'react';
import { isEmpty } from 'lodash';

import { Image } from 'antd-v5';
import {
  Button,
  Card,
  Col,
  Row,
  Space,
  Typography,
} from '@revfluence/fresh';
import {
  ArrowUpRightAndArrowDownLeftFromCenterIcon,
  RotateLeftIcon,
  RotateRightIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  DownloadIcon,
} from '@revfluence/fresh-icons/regular/esm';

import { clickToDownload } from '@frontend/app/utils';
import { Link } from 'react-router-dom';
import { UserAvatar } from '@frontend/app/components';
import { Carousel } from './components/Carousel';
import { TContentGallery } from '../../types';

import styles from './Gallery.scss';

export const Gallery = ({
  resources,
  caption,
  description,
  fitPreview = 'contain',
  memberData,
}: TContentGallery) => {
  const [active, setActive] = React.useState(0);
  const [openPreview, setOpenPreview] = React.useState(false);

  if (isEmpty(resources)) {
    return null;
  }

  const mediaUrl = resources[active].src;

  const onDownload = () => {
    clickToDownload(mediaUrl);
  };

  return (
    <div className={styles.Gallery}>
      <Row gutter={8} align="middle">
        <Link
          to={{
            pathname: '/creator',
            search: `?creatorId=${memberData?.creatorId}`,
          }}
          target="_blank"
        >
          <Row gutter={8} align="middle">
            <Col>
              <UserAvatar
                name={memberData?.name}
                profilePicture={memberData?.creatorProfilePicture}
                size="default"
              />
            </Col>
            <Col className={styles.UserTitle}>
              <Typography.Title level={5}>
                {memberData?.name}
              </Typography.Title>
            </Col>
          </Row>
        </Link>
      </Row>

      <Card
        style={{
          marginTop: '24px',
          marginBottom: '24px',
          height: '475px',
          overflow: 'hidden',
        }}
        bodyStyle={{
          padding: 0,
          height: '100%',
        }}
      >
        <Button
          icon={<ArrowUpRightAndArrowDownLeftFromCenterIcon />}
          size="small"
          onClick={() => setOpenPreview(!openPreview)}
          style={{
            position: 'absolute', top: '8px', left: '8px', zIndex: 1,
          }}
        />
        {resources[active].type === 'image' && (
          <Image
            height="100%"
            width="100%"
            src={mediaUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: fitPreview,
            }}
            preview={
              openPreview
                ? {
                  visible: true,
                  onVisibleChange: (visible) => setOpenPreview(visible),
                  toolbarRender: (
                    _,
                    {
                      actions: {
                        onRotateLeft, onRotateRight, onZoomOut, onZoomIn,
                      },
                    },
                  ) => (
                    <Space size={12} className="toolbar-wrapper">
                      <DownloadIcon onClick={onDownload} />
                      <RotateLeftIcon onClick={onRotateLeft} />
                      <RotateRightIcon onClick={onRotateRight} />
                      <MagnifyingGlassMinusIcon onClick={onZoomOut} />
                      <MagnifyingGlassPlusIcon onClick={onZoomIn} />
                    </Space>
                  ),

                }
                : false
            }
          />
        )}
        {resources[active].type === 'video' && (
          /* eslint-disable jsx-a11y/media-has-caption */
          <video
            src={mediaUrl}
            controls
            style={{ width: '100%', height: '100%' }}
          />
        )}
        {resources[active].type === 'youtube' && (
          <Image
            height="100%"
            width="100%"
            src={resources[active].coverSrc}
            style={{
              cursor: 'pointer',
              width: '100%',
              height: '100%',
              objectFit: fitPreview,
            }}
            preview={false}
            onClick={() => {
              window.open(mediaUrl, '_blank');
            }}
          />
        )}
      </Card>

      {resources.length > 1 && (
        <Carousel
          resources={resources}
          onSelect={(index) => {
            setActive(index);
            setOpenPreview(false);
          }}
        />
      )}

      <Space direction="vertical" size={2} style={{ marginTop: '24px' }}>
        <Typography.Title level={4} style={{ margin: 0, wordBreak: 'break-all' }}>
          {caption}
        </Typography.Title>
        <Typography.Text
          className={styles.description}
          style={{ wordBreak: 'break-all' }}
        >
          {description}
        </Typography.Text>
      </Space>
    </div>
  );
};
