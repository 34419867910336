import * as React from 'react';
import { isEmpty } from 'lodash';
import {
  Checkbox,
  Divider,
  Typography,
  Space,
  Button,
  IButtonProps,
  Input,
} from '@revfluence/fresh';
import {
  PencilIcon,
} from '@revfluence/fresh-icons/solid/esm';
import {
  NetworkType,
} from '@frontend/app/types/globalTypes';
import { ClientFeature } from '@frontend/app/constants';
import {
  TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments,
} from '@frontend/app/queries/types/TermsConfigsQuery';
import { useClientFeatureEnabled } from '@frontend/app/hooks';

import Reward from './Reward/Reward';
import {
  TContentUsageRights,
  IReward,
  PaymentTypes,
  IBam,
  IBrief,
  WhitelistingLabel,
  ICollaborationDetails,
} from '../types/CollaborationDetails';
import {
  TContentGuideline,
  IContentGuidelinesWithDueDates,
  DueDateType,
} from '../types/ContentGuidelines';
import {
  TDispatch,
} from '../types/state';
import { ActionTypes } from '../types/actionTypes';
import AbTestContainer from '../UI/AbTestContainer';
import ContentRequirements from '../ContentRequirements/ContentRequirements';
import styles from './CollaborationDetails.scss';
import {
  IContentGuidelineInstruction,
  ISortableGuideline,
  IContentGuidelineTitle,
} from '../hooks/useState/actions';
import { TDateRequirement } from '../types/ContentDateRequirements';
import ContentUsageRights from './ContentUsageRights';
import InstagramInsights from './Bam/InstagramInsights';
import { hasSelectedInstagramStories } from '../utils';
import { AdsPermissionsSection } from './Bam/AdsPermissionsSection';

const {
  useCallback,
  useMemo,
} = React;
const {
  Title,
  Text,
  Link,
} = Typography;

interface IProps {
  contentGuidelinesTemplates: Array<TContentGuideline>;
  contentGuidelinesWithDueDates: Array<IContentGuidelinesWithDueDates>;
  reward: IReward;
  bam: IBam;
  brief: IBrief;
  contentRight: TContentUsageRights;
  adsPermissions: ICollaborationDetails['adsPermissions'];
  contentRightsTemplates: Array<TContentUsageRights>;
  isCreatorEditEnabled: boolean;
  disableCreatorEdits: boolean;
  daysToApproveContent: number;
  paymentPeriod: number;
  isBrandedContentEnabled: boolean;
  whitelistingLabel: WhitelistingLabel;
  advancedTerms: boolean;
  dispatch: TDispatch;
  onClickConnectInstagram: IButtonProps['onClick'];
  linkInsightsAlertForBrandedContent: boolean;
  linkInsightsAlertForPartnershipAds: boolean;
  linkBCAAccountAlert: boolean;
  businessAccountMissing: boolean;
  tiktokSparkAdsAccountMissing: boolean;
}

const CollaborationDetails: React.FC<IProps> = React.memo((props) => {
  const {
    advancedTerms,
    adsPermissions: adsPermissionsProp,
    daysToApproveContent,
    paymentPeriod,
    whitelistingLabel,
    contentGuidelinesWithDueDates,
    reward,
    bam,
    brief,
    contentRightsTemplates,
    contentRight,
    isCreatorEditEnabled,
    disableCreatorEdits,
    dispatch,
    linkInsightsAlertForBrandedContent,
    linkInsightsAlertForPartnershipAds,
    linkBCAAccountAlert,
    businessAccountMissing,
    tiktokSparkAdsAccountMissing,
  } = props;
  const InstagramPartnershipAdsFF = useClientFeatureEnabled(ClientFeature.INSTAGRAM_PARTNERSHIP_ADS, {
    nextFetchPolicy: 'cache-first',
  });
  const UCEInfluencerWhitelistingFF = useClientFeatureEnabled(ClientFeature.UCE_INFLUENCER_WHITELISTING, {
    nextFetchPolicy: 'cache-first',
  });
  const MetaBrandedContentFF = useClientFeatureEnabled(ClientFeature.META_BRANDED_CONTENT, {
    nextFetchPolicy: 'cache-first',
  });
  const TiktokSparkAdsFF = useClientFeatureEnabled(ClientFeature.TIKTOK_SPARK_ADS, {
    nextFetchPolicy: 'cache-first',
  });

  const defaultSparkAdsDuration = 60;

  const handleShowHideNewPrice = useCallback((showHideNewPrice: boolean) => {
    dispatch({ type: ActionTypes.UpdateShowHideNewPrice, showHideNewPrice });
    dispatch({
      type: ActionTypes.UpdateShowHidePaymentOption,
      paymentType: PaymentTypes.NoCompensation,
      showHideDescription: false,
    });
  }, [dispatch]);

  const handleUpdateNewPrice = useCallback((updatedNewPrice: number) => {
    dispatch({ type: ActionTypes.UpdateNewPrice, newPrice: updatedNewPrice });
  }, [dispatch]);

  const handleShowHideDescription = useCallback((paymentType: PaymentTypes, showHideDescription: boolean) => {
    if (paymentType === PaymentTypes.NoCompensation) {
      dispatch({
        type: ActionTypes.UpdateShowHidePaymentOption,
        paymentType: PaymentTypes.Commission,
        showHideDescription: false,
      });
      dispatch({
        type: ActionTypes.UpdateShowHidePaymentOption,
        paymentType: PaymentTypes.FreeProduct,
        showHideDescription: false,
      });
      dispatch({
        type: ActionTypes.UpdateShowHideNewPrice,
        showHideNewPrice: false,
      });
    } else {
      dispatch({
        type: ActionTypes.UpdateShowHidePaymentOption,
        paymentType: PaymentTypes.NoCompensation,
        showHideDescription: false,
      });
    }
    dispatch({ type: ActionTypes.UpdateShowHidePaymentOption, paymentType, showHideDescription });
  }, [dispatch]);

  const handleUpdateDescription = useCallback((paymentType: PaymentTypes, description: string) => {
    dispatch({ type: ActionTypes.UpdatePaymentOptionDescription, paymentType, description });
  }, [dispatch]);

  const handleToggleDisableCreatorEdits = useCallback((value: boolean) => {
    dispatch({ type: ActionTypes.ToggleDisableCreatorEdits, value });
  }, [dispatch]);

  const handleAddContentGuidelineInstruction = useCallback(
    (contentGuidelineInstruction: IContentGuidelineInstruction) => {
      dispatch({ type: ActionTypes.AddContentGuidelineInstruction, contentGuidelineInstruction });
    }, [dispatch],
  );

  const handleToggleReviewBeforeSubmission = useCallback(
    (requiresReviewBeforeSubmission: boolean, contentGuidelineInstanceId: number) => {
      dispatch({
        type: ActionTypes.ToggleReviewBeforeSubmission,
        requiresReviewBeforeSubmission,
        contentGuidelineInstanceId,
      });
    }, [dispatch],
  );

  const handleToggleRequiresBrandedContentTag = useCallback(
    (requiresBrandedContentTag: boolean, contentGuidelineInstanceId: number) => {
      dispatch({
        type: ActionTypes.ToggleRequiresBrandedContentTag,
        requiresBrandedContentTag,
        contentGuidelineInstanceId,
      });
    }, [dispatch],
  );

  const handleToggleReviewWithinThreeDays = useCallback(
    (requiresReviewWithinThreeDaysOfCreatorSubmission: boolean, contentGuidelineInstanceId: number) => {
      dispatch({
        type: ActionTypes.ToggleReviewWithinThreeDays,
        requiresReviewWithinThreeDaysOfCreatorSubmission,
        contentGuidelineInstanceId,
      });
    }, [dispatch],
  );

  const handleAddAttachment = useCallback(
    (
      attachment: TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments,
      id: number,
    ) => {
      dispatch({
        type: ActionTypes.AddAttachment,
        attachment,
        contentGuidelineInstanceId: id,
      });
    }, [dispatch],
  );

  const handleRemoveAttachment = useCallback(
    (
      attachments: Array<TermsConfigsQuery_termsConfig_settings_contentGuidelines_attachments>,
      id: number,
    ) => {
      dispatch({
        type: ActionTypes.RemoveAttachment,
        attachments,
        contentGuidelineInstanceId: id,
      });
    }, [dispatch],
  );

  const handleUpdateDueDate = useCallback(
    (
      dueDateType: DueDateType,
      contentGuidelineInstanceId: number,
      dueDateIndex: number,
      dueDate: TDateRequirement,
    ) => {
      dispatch({
        type: ActionTypes.UpdateDueDate,
        dueDateType,
        contentGuidelineInstanceId,
        dueDateIndex,
        dueDate,
      });
    }, [dispatch],
  );

  const handleSortableGuideline = useCallback(
    (
      id: number,
      sortableGuideline: ISortableGuideline,
    ) => {
      dispatch({
        type: ActionTypes.SortGuideline,
        contentGuidelineInstanceId: id,
        sortableGuideline,
      });
    }, [dispatch],
  );

  const handleUpdateContentRight = useCallback(
    (contentRightUpdated: TContentUsageRights) => {
      dispatch({ type: ActionTypes.UpdateContentRight, contentRight: contentRightUpdated });
    }, [dispatch],
  );

  const handleToggleInfluencerBrandedContent = useCallback(
    (toggleInfluencerBrandedContent: boolean) => {
      dispatch({ type: ActionTypes.ToggleInfluencerBrandedContent, toggleInfluencerBrandedContent });
    }, [dispatch],
  );

  const handleToggleInstagramInsights = useCallback(
    (toggleInstagramInsights: boolean) => {
      dispatch({ type: ActionTypes.ToggleInstagramInsights, toggleInstagramInsights });
    }, [dispatch],
  );

  const handleToggleBrief = useCallback(
    () => {
      dispatch({ type: ActionTypes.ToggleBrief, toggleBrief: !brief.toggleBrief });
    }, [dispatch, brief.toggleBrief],
  );

  const handleBriefUrlChange = useCallback(
    (e) => {
      dispatch({ type: ActionTypes.UpdateBriefUrl, briefUrl: e.target.value });
    }, [dispatch],
  );

  const handleToggleSpecialPaymentTerms = useCallback(
    (toggleSpecialPaymentTerms: boolean) => {
      dispatch({ type: ActionTypes.ToggleSpecialPaymentTerms, toggleSpecialPaymentTerms });
    }, [dispatch],
  );

  const handleUpdateSpecialPaymentTerms = useCallback(
    (specialPaymentTerms: string) => {
      dispatch({ type: ActionTypes.UpdateSpecialPaymentTerms, specialPaymentTerms });
    }, [dispatch],
  );

  const handleUpdateContentGuidelineTitle = useCallback(
    (contentGuidelineTitle: IContentGuidelineTitle) => {
      dispatch({ type: ActionTypes.UpdateContentGuidelineTitle, contentGuidelineTitle });
    }, [dispatch],
  );

  const handleAdsPermissionsChange = useCallback(
    (adsPermissions: Partial<ICollaborationDetails['adsPermissions']>) => {
      dispatch({ type: ActionTypes.UpdateAdsPermissions, adsPermissions });
    }, [dispatch],
  );

  const hasSelectedInstagramNetworkType = useMemo(() => contentGuidelinesWithDueDates.some(
    (contentGuidelinesWithDueDate) => contentGuidelinesWithDueDate.networkType === NetworkType.INSTAGRAM,
  ), [contentGuidelinesWithDueDates]);

  const hasSelectedInstagramOrCustomNetworkType = useMemo(() => contentGuidelinesWithDueDates.some(
    (contentGuidelinesWithDueDate) => [
      NetworkType.OTHER,
      NetworkType.INSTAGRAM,
    ].includes(contentGuidelinesWithDueDate.networkType),
  ), [contentGuidelinesWithDueDates]);

  const hasSelectedTiktokNetworkType = useMemo(() => contentGuidelinesWithDueDates.some(
    (contentGuidelinesWithDueDate) => contentGuidelinesWithDueDate.networkType === NetworkType.TIKTOK,
  ), [contentGuidelinesWithDueDates]);

  const hasInstagramContentBC = contentGuidelinesWithDueDates.some(({ networkType, requiresBrandedContentTag }) => (
    networkType === NetworkType.INSTAGRAM && requiresBrandedContentTag));

  React.useEffect(() => {
    if ((!InstagramPartnershipAdsFF && !MetaBrandedContentFF)
      && hasSelectedInstagramNetworkType && whitelistingLabel === WhitelistingLabel.BRANDED_CONTENT_ADS) {
      handleToggleInfluencerBrandedContent(true);
    }
  }, [InstagramPartnershipAdsFF, MetaBrandedContentFF,
    whitelistingLabel, handleToggleInfluencerBrandedContent, hasSelectedInstagramNetworkType]);

  React.useEffect(() => {
    if ((!InstagramPartnershipAdsFF && !MetaBrandedContentFF)
      && whitelistingLabel === WhitelistingLabel.BRANDED_CONTENT_ADS && bam.toggleInfluencerWhitelist) {
      handleToggleInfluencerBrandedContent(true);
    }
  }, [InstagramPartnershipAdsFF, MetaBrandedContentFF,
    whitelistingLabel, handleToggleInfluencerBrandedContent, bam.toggleInfluencerWhitelist]);

  return (
    <div className={styles.CollaborationDetails}>
      <section>
        <ContentRequirements
          daysToApproveContent={daysToApproveContent}
          contentGuidelinesWithDueDates={contentGuidelinesWithDueDates}
          onAddContentGuidelineInstruction={handleAddContentGuidelineInstruction}
          onToggleReviewBeforeSubmission={handleToggleReviewBeforeSubmission}
          onToggleRequiresBrandedContentTag={handleToggleRequiresBrandedContentTag}
          onToggleReviewWithinThreeDays={handleToggleReviewWithinThreeDays}
          onAddAttachment={handleAddAttachment}
          onRemoveAttachment={handleRemoveAttachment}
          onUpdateDueDate={handleUpdateDueDate}
          onSortGuideline={handleSortableGuideline}
          onUpdateContentGuidelineTitle={handleUpdateContentGuidelineTitle}
          linkInsightsAlertForBrandedContent={linkInsightsAlertForBrandedContent}
          linkBCAAccountAlert={linkBCAAccountAlert}
        />
      </section>
      {advancedTerms && (
        <div className={styles.briefDetails}>
          <Divider />
          <section>
            <Space align="baseline">
              <Title level={4}>Additional links</Title>
              <Button
                type="link"
                onClick={handleToggleBrief}
                icon={<PencilIcon />}
              />
            </Space>
            {brief.toggleBrief && (
              <Input
                placeholder="Brief url"
                onChange={handleBriefUrlChange}
                value={brief.briefUrl}
              />
            )}
            {!brief.toggleBrief && !isEmpty(brief.briefUrl) && (
              <Link href={brief.briefUrl} target="_blank">
                Link to brief
              </Link>
            )}
          </section>
        </div>
      )}
      <Divider />
      <section>
        <Reward
          paymentPeriod={paymentPeriod}
          advancedTerms={advancedTerms}
          payment={reward.payment}
          freeProduct={reward.freeProduct}
          commission={reward.commission}
          noCompensation={reward.noCompensation}
          onShowHideNewPrice={handleShowHideNewPrice}
          onUpdateNewPrice={handleUpdateNewPrice}
          onShowHideDescription={handleShowHideDescription}
          onUpdateDescription={handleUpdateDescription}
          onToggleSpecialPaymentTerms={handleToggleSpecialPaymentTerms}
          onUpdateSpecialPaymentTerms={handleUpdateSpecialPaymentTerms}
        />
      </section>
      <Divider />
      <section>
        <ContentUsageRights
          contentRightsTemplates={contentRightsTemplates}
          contentRight={contentRight}
          onUpdateContentRight={handleUpdateContentRight}
        />
      </section>
      <Divider />
      <section>
        <Space direction="vertical">
          <Title level={4}>Content Agreement</Title>
          <Text>The creator agrees that:</Text>
          <ul>
            <li>
              <Text>
                All content will reflect the creator&apos;s honest
                views and experiences with the brand&apos;s products and services
              </Text>
            </li>
            <li>
              <Text>
                They will at all times comply with all applicable laws, rules, regulations, and guides
              </Text>
            </li>
            <li>
              <Text>
                They will not submit or post content that violates,
                infringes, or misappropriates any rights of any third parties
              </Text>
            </li>
          </ul>
        </Space>
      </section>
      <Divider />
      <AdsPermissionsSection
        hidden={!hasSelectedInstagramOrCustomNetworkType && !hasSelectedTiktokNetworkType}
        metaAuthIssues={{
          adsPermissionsNeeded: linkBCAAccountAlert,
          instagramInsightsMissing: linkInsightsAlertForPartnershipAds,
          businessAccountMissing,
        }}
        instagramPaidPartnershipAds={{
          hidden: !hasSelectedInstagramOrCustomNetworkType,
          featureFlag: InstagramPartnershipAdsFF,
          onToggle: (value) => handleAdsPermissionsChange({ requireIgPartnershipAdsAccess: value }),
          toggle: adsPermissionsProp.requireIgPartnershipAdsAccess,
        }}
        instagramAllowlistening={{
          hidden: !hasSelectedInstagramOrCustomNetworkType,
          featureFlag: UCEInfluencerWhitelistingFF,
          onToggle: (value) => handleAdsPermissionsChange({
            metaBamAccess: {
              ...adsPermissionsProp.metaBamAccess,
              toggle: value,
            },
          }),
          toggle: adsPermissionsProp.metaBamAccess.toggle,
          dateRange: [adsPermissionsProp.metaBamAccess.startDate, adsPermissionsProp.metaBamAccess.endDate],
          onDateRangeChange: (dateRange) => handleAdsPermissionsChange({
            metaBamAccess: {
              ...adsPermissionsProp.metaBamAccess,
              startDate: dateRange[0],
              endDate: dateRange[1],
            },
          }),
        }}
        instagramBrandedContentAds={{
          hidden: !hasSelectedInstagramOrCustomNetworkType,
          featureFlag: MetaBrandedContentFF,
          onToggle: (value) => handleAdsPermissionsChange({
            bcPromoteAccess: {
              ...adsPermissionsProp.bcPromoteAccess,
              toggle: value,
            },
          }),
          toggle: adsPermissionsProp.bcPromoteAccess.toggle,
          dateRange: [adsPermissionsProp.bcPromoteAccess.startDate, adsPermissionsProp.bcPromoteAccess.endDate],
          onDateRangeChange: (dateRange) => handleAdsPermissionsChange({
            bcPromoteAccess: {
              ...adsPermissionsProp.bcPromoteAccess,
              startDate: dateRange[0],
              endDate: dateRange[1],
            },
          }),
        }}
        tiktokSparkAds={{
          hidden: !hasSelectedTiktokNetworkType,
          featureFlag: TiktokSparkAdsFF,
          onToggle: (toggle) => handleAdsPermissionsChange({
            tiktokSparkAdsAccess: {
              ...adsPermissionsProp.tiktokSparkAdsAccess,
              toggle,
              duration: toggle ? adsPermissionsProp.tiktokSparkAdsAccess.duration || defaultSparkAdsDuration : null,
            },
          }),
          toggle: adsPermissionsProp.tiktokSparkAdsAccess.toggle,
          duration: adsPermissionsProp.tiktokSparkAdsAccess.duration,
          onSparkAdsDurationChange: (duration) => handleAdsPermissionsChange({
            tiktokSparkAdsAccess: {
              ...adsPermissionsProp.tiktokSparkAdsAccess,
              duration,
            },
          }),
        }}
        tiktokSparkAdsAccountMissing={tiktokSparkAdsAccountMissing}
      />
      {hasSelectedInstagramOrCustomNetworkType && (
        <>
          <section>
            <InstagramInsights
              showNewAlert
              whitelistingLabel={whitelistingLabel}
              hasSelectedInstagramStories={hasSelectedInstagramStories(contentGuidelinesWithDueDates)}
              toggleInstagramInsights={bam.toggleInstagramInsights}
              toggleInfluencerWhitelist={adsPermissionsProp.metaBamAccess.toggle}
              toggleInfluencerBrandedContent={adsPermissionsProp.bcPromoteAccess.toggle || hasInstagramContentBC}
              onToggleInstagramInsights={handleToggleInstagramInsights}
            />
          </section>
          <Divider />
        </>
      )}
      <AbTestContainer
        showComponent={isCreatorEditEnabled}
      >
        <section>
          <Title level={4}>Prevent creators from editing brief</Title>
          <Checkbox
            onChange={(event) => handleToggleDisableCreatorEdits(event.target.checked)}
            checked={disableCreatorEdits}
          >
            <Text strong>Prevent creators from editing brief</Text>
          </Checkbox>
          <div>
            <Text italic>
              When this checkbox is selected, creators will not be able to propose changes to the brief.
            </Text>
          </div>
        </section>
      </AbTestContainer>
    </div>
  );
});

CollaborationDetails.displayName = 'CollaborationDetails';

export default CollaborationDetails;
