import * as React from 'react';
import {
  Col,
  IRowProps,
  Row,
  Typography,
  TypographyProps,
} from '@revfluence/fresh';
import cx from 'classnames';
import {
  isEmpty,
  map,
} from 'lodash';
import { ShopifyStoreDisconnectionAlert } from '@frontend/applications/AffiliatesApp/components/ShopifyStoreDisconnectionAlert/ShopifyStoreDisconnectionAlert';
import { IHomePageSection } from '../types';

import styles from './GetStarted.scss';

interface IProps {
  groups: IHomePageSection;
  metrics: React.ReactNode;
  projects: IHomePageSection;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TODO: Fix in Node upgrade typing bash!
  onClickNewProject: TypographyProps['onClick'];
  welcome: React.ReactNode;
}

const titleGutter: IRowProps['gutter'] = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const contentGutter: IRowProps['gutter'] = [{
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
}, {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
}];

const RESPONSIVE_CARD_SIZES = {
  groups: {
    lg: 4,
    md: 8,
    sm: 12,
    xs: 12,
  },
  projects: {
    lg: 8,
    md: 12,
    sm: 24,
    xs: 24,
  },
};

const HOME_PAGE_SECTIONS = ['projects', 'groups'] as const;

export const Layout = (props: IProps) => {
  const {
    groups,
    metrics,
    onClickNewProject,
    projects,
    welcome,
  } = props;
  const sectionContents = (
    sectionKey: 'projects' | 'groups',
    cards: IHomePageSection['cards'],
    error: IHomePageSection['error'],
  ): React.ReactNode => {
    if (error) {
      return (
        <Col
          lg={{ span: 12, offset: 6 }}
          sm={{ span: 18, offset: 3 }}
          xs={24}
        >
          {error}
        </Col>
      );
    }

    if (isEmpty(cards)) {
      if (sectionKey !== 'projects') {
        return null;
      }

      return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO: Fix in Node upgrade typing bash!
        <Col align="center" xs={24}>
          <Typography.Paragraph>
            No projects found.
            {' '}
            <Typography.Link
              href="projects/new/templates"
              onClick={onClickNewProject}
            >
              Create New Project
            </Typography.Link>
          </Typography.Paragraph>
        </Col>
      );
    }

    return map(cards, (card, index) => (
      <Col
        key={`${sectionKey}-card-${index + 1}`}
        lg={RESPONSIVE_CARD_SIZES[sectionKey].lg}
        md={RESPONSIVE_CARD_SIZES[sectionKey].md}
        sm={RESPONSIVE_CARD_SIZES[sectionKey].sm}
        xs={RESPONSIVE_CARD_SIZES[sectionKey].xs}
      >
        {card}
      </Col>
    ));
  };
  return (
    <div className={styles.Wrapper}>
      <div className={cx(styles.MetricsSection, styles.spacing)}>
        <Row className={cx(styles.sectionContent, styles.welcomeSection)}>
          <Col>
            <Typography.Title className={styles.title} level={2}>
              {welcome}
            </Typography.Title>
          </Col>
        </Row>
        <ShopifyStoreDisconnectionAlert />
        {metrics && (
          <Row className={styles.sectionContent}>
            <Col flex="auto">
              {metrics}
            </Col>
          </Row>
        )}
      </div>
      <div className={cx(styles.OtherSections, styles.spacing)}>
        {map(HOME_PAGE_SECTIONS, (sectionKey) => {
          let section;
          switch (sectionKey) {
            case 'groups':
              section = groups;
              break;
            case 'projects':
              section = projects;
              break;
            default:
              break;
          }
          if (!section) {
            throw new Error(`Unhandled section key: "${sectionKey}".`);
          }

          const {
            actions,
            cards,
            error,
            title,
          } = section;
          return (
            <React.Fragment key={sectionKey}>
              <Row
                align="middle"
                className={styles.sectionHeader}
                gutter={titleGutter}
              >
                <Col flex="auto">
                  <Typography.Title className={styles.title} level={3}>
                    {title}
                  </Typography.Title>
                </Col>
                {map(actions, (action, index) => (
                  <Col key={`${sectionKey}-action-${index + 1}`}>
                    {action}
                  </Col>
                ))}
              </Row>
              <Row
                className={styles.sectionContent}
                gutter={contentGutter}
                wrap
              >
                {sectionContents(
                  sectionKey,
                  cards,
                  error,
                )}
              </Row>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
