import { AssignPaymentTo, IPaymentProps } from '@frontend/applications/PaymentsApp/types';
import { TState } from './types';

export const getDefaultState = (props: IPaymentProps): TState => ({
  currentStep: props?.currentStep || 1,
  error: null,
  memberId: props?.memberId || null,
  paymentCreationSource: props?.paymentCreationSource || null,
  terms: props?.terms || [],
  programs: props?.programs || [],
  memberInfo: props?.memberInfo || null,
  amountPaying: props?.amountPaying || 0,
  paymentSource: props?.paymentSource || null,
  assignPaymentTo: props?.assignPaymentTo || AssignPaymentTo.Other,
  budgetAccounts: props?.budgetAccounts || [],
  selectedBudgetAccountId: props?.selectedBudgetAccountId || null,
  selectedBudgetDistributionId: props?.selectedBudgetDistributionId || null,
  budgetDistributionsForBudgetAccount: props?.budgetDistributionsForBudgetAccount || [],
  selectedTermId: props?.selectedTermId || null,
  payments: props?.payments || [],
  overflowPayments: props?.overflowPayments || [],
  groups: props?.groups || [],
  paypalAddress: props?.paypalAddress || '',
  selectedGroupId: props?.selectedGroupId || null,
  selectedCardId: props?.selectedCardId || null,
  selectedProgramId: props?.selectedProgramId || null,
  paypalFieldId: props?.paypalFieldId || null,
  requireHandlingFee: props?.requireHandlingFee || false,
  selectedBudgetReassign: [],
  staPayments: [],
  featureFlags: props?.featureFlags || {},
});
