import * as React from 'react';
import {
  Col,
  Image,
  Row,
  Typography,
} from '@revfluence/fresh';

import { useOverviewPage } from '@frontend/app/containers/Projects/hooks';

import styles from './Header.module.scss';

import QuickLinks from './components/QuickLinks';
import Status from './components/Status';
import DeepLink from './components/DeepLink';

const { Title } = Typography;
export interface IHeaderProps {
  campaignName: string;
  status: string;
  projectImage: string;
  url?: string;
  campaignUnlistDate?: string;
  projectId?: number;
  handleCTAClick?(): void;
}

export enum ButtonActions {
  AddToProject = 'addToProject',
  InviteToProject = 'inviteToProject',
}

const ASSETS = process.env.ASSETS;
const defaultImageSrc = `${ASSETS}/content_image_placeholder.png`;

const LARGE_TITLE_LEVEL = 3;
const MEDIUM_TITLE_LEVEL = 4;
const SMALL_TITLE_LEVEL = 5;

const Header: React.FC<IHeaderProps> = React.memo(({
  campaignName,
  status,
  url,
  campaignUnlistDate,
  projectId,
  handleCTAClick,
  projectImage,
}) => {
  const { useMemo } = React;

  const { handleCheckScreenSize } = useOverviewPage();

  const {
    isWide,
    isMobile,
    currentBreakpoints,
  } = handleCheckScreenSize();

  const titleLevel = useMemo(() => {
    if (isMobile) {
      return SMALL_TITLE_LEVEL;
    }
    if (isWide) {
      return LARGE_TITLE_LEVEL;
    }
    return MEDIUM_TITLE_LEVEL;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBreakpoints]);

  return (
    <Row
      align="middle"
      className={styles.header}
    >
      <Col>
        <Image
          className={styles.projectImage}
          src={projectImage || defaultImageSrc}
          preview={false}
        />
      </Col>
      <Col className={styles.titleStatus}>
        <Title
          ellipsis={{
            rows: 1,
            tooltip: campaignName,
          }}
          level={titleLevel}
          className={styles.title}
        >
          {campaignName}
          <DeepLink projectId={projectId} />
        </Title>
        <Status
          status={status}
          date={campaignUnlistDate}
          url={url}
          handleCTAClick={handleCTAClick}
        />
      </Col>
      <Col className={styles.quickLinks}>
        <QuickLinks projectId={projectId} />
      </Col>
    </Row>
  );
});

export default Header;
Header.displayName = 'Header';
