/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_19vjn_157 {
  display: flex;
}

._justify-content-space-between_19vjn_161 {
  justify-content: space-between;
}

._tabular-nums_19vjn_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_19vjn_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

/* ASPIRE UI THEME **/
/* ASPIRE IQ THEME */

._flex_19vjn_157 {
  display: flex;
}

._justify-content-space-between_19vjn_161 {
  justify-content: space-between;
}

._tabular-nums_19vjn_165 {
  font-variant-numeric: tabular-nums;
}

@keyframes _show_19vjn_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Text styles */
._SelectOffers_19vjn_355 ._nextButton_19vjn_355 {
  border-color: var(--primary);
  background-color: var(--primary);
}
._SelectOffers_19vjn_355 ._nextButton_19vjn_355:focus, ._SelectOffers_19vjn_355 ._nextButton_19vjn_355:hover {
  background-color: #2e8af5;
  color: #fff;
}
._SelectOffers_19vjn_355 ._nextButton_19vjn_355:disabled {
  color: #fff;
  opacity: 0.8;
}

._SelectOffers_19vjn_355 ._icon_19vjn_368 {
  max-width: 1.875rem;
}