import { ApolloProvider } from '@apollo/client';
import * as React from 'react';

import {
  IApplicationContainerHandle,
  IBulkActionParameters,
} from '@frontend/app/containers/Application/ApplicationContainer';
import { ApplicationProvider } from '@frontend/applications/Shared/context/applicationContext';

import { EventName } from '@common';
import { useEventContext } from '@frontend/app/context/EventContext';
import {
  analyticsServerApiEndpoint,
  backendServerApiEndpoint,
  backendServerWebEndpoint,
} from '@frontend/applications/Shared/serviceHosts';
import { useAuth } from '@frontend/context/authContext';

import { IWorkflowActionParameters } from '@frontend/app/containers/Application/ApplicationContainer';
import { map } from 'lodash';
import { ProductFulfillmentProvider, useApolloClient } from '../context';
import OrderCreationWizard, { ICreateOrderWizardRef } from './OrderCreationWizard';
import { ProductFulfillmentAppDashboard } from './ProductFulfillmentAppDashboard';

import { CreatorProductSelection } from './CreatorProductSelection';
import { CreatorProductSelectionV2 } from './CreatorProductSelectionV2';
import { OfflineCPS } from './OfflineCPS';
import styles from './ProductFulfillmentApp.styles.scss';

const { useEffect } = React;

interface IProps {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  members?: any;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  schemas?: any;
  modalView: boolean;
  clientId: string;
  clientName: string;
  memberId?: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  deepLinkParameters?: any;
  bulkActionParameters?: IBulkActionParameters;
  workflowActionParameters?: IWorkflowActionParameters;
  closeModal?(): void;
}

const { useRef, useImperativeHandle } = React;

const ProductFulfillmentAppInternal: React.ForwardRefRenderFunction<IApplicationContainerHandle, IProps> = (
  props,
  ref,
) => {
  const { modalView, workflowActionParameters } = props;
  const addEvent = useEventContext();

  const wizardRef = useRef<ICreateOrderWizardRef>(null);
  useImperativeHandle(ref, () => ({
    showWarningOnClose: () => {
      if (workflowActionParameters?.workletSpecUri === 'CreatorProductSelectionWorkletSpecification') {
        return false;
      }
      if (workflowActionParameters?.workletSpecUri === 'SendBrandProductCatalogWorkletSpecification') {
        return false;
      }
      if (workflowActionParameters?.workletSpecUri === 'OfflineCreatorProductSelectionWorkletSpecification') {
        return false;
      }

      const currentStep = wizardRef.current.getCurrentStep();
      return ![1, 4].includes(currentStep);
    },
  }));

  useEffect(() => {
    addEvent(EventName.OpenApp, { source: 'nav', app: 'contract' });
  }, [addEvent]);
  const renderPFAView = () => {
    if (workflowActionParameters?.workletSpecUri === 'CreatorProductSelectionWorkletSpecification') {
      return <CreatorProductSelection />;
    } else if (workflowActionParameters?.workletSpecUri === 'SendBrandProductCatalogWorkletSpecification') {
      return <CreatorProductSelectionV2 />;
    } else if (workflowActionParameters?.workletSpecUri === 'OfflineCreatorProductSelectionWorkletSpecification') {
      return <OfflineCPS />;
    } else {
      return (
        <>
          {modalView && <OrderCreationWizard ref={wizardRef} />}
          {!modalView && <ProductFulfillmentAppDashboard />}
        </>
      );
    }
  };
  return (
    <ApplicationProvider
      backendServerApiEndpoint={backendServerApiEndpoint()}
      analyticsServerApiEndpoint={analyticsServerApiEndpoint()}
      backendServerWebEndpoint={backendServerWebEndpoint()}
      clientId={props.clientId}
      clientName={props.clientName}
      memberId={props.memberId}
      bulkActionParameters={props.bulkActionParameters}
      closeModal={props.closeModal}
      workflowActionParameters={props.workflowActionParameters}
    >
      <div className={styles.productFulfillmentAppModal}>{renderPFAView()}</div>
    </ApplicationProvider>
  );
};

const ProductFulfillmentApp = React.forwardRef(ProductFulfillmentAppInternal);

const ProductFulfillmentAppWrapper = React.forwardRef<IApplicationContainerHandle, IProps>((props, ref) => {
  const { bulkActionParameters, workflowActionParameters, memberId } = props;
  const { token } = useAuth();
  const apolloClient = useApolloClient(token);
  const pfaRef = useRef<IApplicationContainerHandle>(null);

  useImperativeHandle(ref, () => ({
    showWarningOnClose: pfaRef.current?.showWarningOnClose,
  }));

  const memberIds = bulkActionParameters?.memberIds || workflowActionParameters?.memberIds || [memberId];

  // TODO: remove this casting when the workflowActionParameters are fixed to return numbers
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const memberIdsCasted = map(memberIds, (id) => parseInt(id as any, 10));

  return (
    <ProductFulfillmentProvider
      memberQueryJson={bulkActionParameters?.memberQueryJson}
      memberIds={memberIdsCasted}
      workItems={workflowActionParameters?.workItems}
    >
      <ApolloProvider client={apolloClient}>
        <ProductFulfillmentApp ref={pfaRef} {...props} />
      </ApolloProvider>
    </ProductFulfillmentProvider>
  );
});

ProductFulfillmentAppWrapper.displayName = 'ProductFulfillmentApp';

export default ProductFulfillmentAppWrapper;
