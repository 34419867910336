import * as React from 'react';
import {
  Alert,
  Button,
  IButtonProps,
  Space,
} from '@revfluence/fresh';
import { MessagesIcon } from '@revfluence/fresh-icons/regular/esm';
import { isEmpty } from 'lodash';

import {
  IBasicStat,
  IComplexStat,
  ILink,
  StatisticsCard,
} from '@frontend/app/components/StatisticsCard';
import { ISocialStats } from '../types';
import { ConnectInstagram } from './ConnectInstagram';
import {
  ILabelMap,
  useGetBasicStatsFromLabelMap,
  useGetComplexStatsFromLabelMap,
} from './useGetStatsFromLabelMap';

function reconnectIgAlert(show: boolean, onReconnect: IButtonProps['onClick']): React.ReactNode | undefined {
  if (!show) {
    return undefined;
  }
  return (
    <Alert
      message="You need to re-connect your IG account to make sure your data is up-to-date."
      type="error"
      showIcon
      action={(
        <Space>
          <Button onClick={onReconnect} size="small" type="ghost">
            Re-connect
          </Button>
        </Space>
      )}
      closable
    />
  );
}

interface IProps {
  creators?: ISocialStats['creatorCount'];
  engagements?: ISocialStats['engagements'];
  impressions?: ISocialStats['impressions'];
  isInstagramConnected: boolean;
  links: ILink[];
  onClickConnectInstagram: IButtonProps['onClick'];
  posts?: ISocialStats['postCount'];
  reach?: ISocialStats['reach'];
  tmv?: ISocialStats['tmv'];
  ugc?: ISocialStats['ugc'];
  videoViews?: ISocialStats['views'];
  subtitle: string;
}

// @TODO for p1 this will need to be calculated instead of hardcoded
// eslint-disable-next-line max-len
const SOCIAL_TOOLTIP = 'This section consists of data from all your projects and data from social listening tags and mentions for Instagram.';

const CARD_TITLE = 'Social';

const EMPTY_LIST = [];

export const SocialCard: React.FC<IProps> = React.memo((props) => {
  const {
    creators,
    engagements,
    impressions,
    isInstagramConnected,
    links,
    onClickConnectInstagram,
    posts,
    reach,
    tmv,
    ugc,
    videoViews,
    subtitle,
  } = props;

  const loading = (creators && creators.state === 'loading')
    || (engagements && engagements.state === 'loading')
    || (impressions && impressions.state === 'loading')
    || (posts && posts.state === 'loading')
    || (reach && reach.state === 'loading')
    || (tmv && tmv.state === 'loading')
    || (ugc && ugc.state === 'loading')
    || (videoViews && videoViews.state === 'loading');

  const smallStatsLabelMap = React.useMemo((): ILabelMap<IBasicStat> => ({
    'Posts': posts ? {
      format: 'default',
      stat: posts,
    } : undefined,
    'Creators': creators ? {
      format: 'default',
      stat: creators,
    } : undefined,
    'UGC': ugc ? {
      format: 'default',
      stat: ugc,
    } : undefined,
    'Video Views': videoViews ? {
      format: 'default',
      stat: videoViews,
    } : undefined,
    'TMV': tmv ? {
      format: 'wholeDollars',
      stat: tmv,
    } : undefined,
  }), [
    creators,
    posts,
    tmv,
    ugc,
    videoViews,
  ]);
  const smallStats = useGetBasicStatsFromLabelMap(smallStatsLabelMap);

  const statsLabelMap = React.useMemo((): ILabelMap<IComplexStat> => ({
    Reach: reach ? {
      format: 'default',
      stat: reach,
    } : undefined,
    Impressions: impressions ? {
      format: 'default',
      stat: impressions,
    } : undefined,
    Engagements: engagements ? {
      format: 'default',
      stat: engagements,
    } : undefined,
  }), [
    engagements,
    impressions,
    reach,
  ]);
  const stats = useGetComplexStatsFromLabelMap(statsLabelMap);
  const emptyStats = isEmpty(stats);
  if (!isInstagramConnected && emptyStats) {
    const customContent = (
      <ConnectInstagram onClickConnect={onClickConnectInstagram} />
    );
    return (
      <StatisticsCard
        customContent={customContent}
        icon={<MessagesIcon />}
        links={EMPTY_LIST}
        loading={false}
        smallStats={EMPTY_LIST}
        stats={EMPTY_LIST}
        subtitle={subtitle}
        title={CARD_TITLE}
        tooltip={SOCIAL_TOOLTIP}
      />
    );
  }

  return (
    <StatisticsCard
      icon={<MessagesIcon />}
      links={links}
      loading={loading}
      smallStats={smallStats}
      stats={stats}
      subtitle={subtitle}
      title={CARD_TITLE}
      tooltip={SOCIAL_TOOLTIP}
      alertComponent={reconnectIgAlert(!isInstagramConnected, onClickConnectInstagram)}
    />
  );
});
SocialCard.displayName = 'SocialCard';
