/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import cx from 'classnames';
import {
 some, isEmpty, find, omit, keyBy,
} from 'lodash';
import { NavLink, useLocation } from 'react-router-dom';
import { Button as FreshButton } from '@revfluence/fresh';

import { MemberInput } from '@frontend/app/types/globalTypes';
import {
  IActivation, ITag,
  useGetMemberByEmail,
  useCommunitiesQuery,
  useProgramsForCommunityQuery,
  useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';

import { useMessagingContext } from '@frontend/hooks';
import {
  MemberProgramsAndCommunitiesQuery_member_communities as ICommunity,
  MemberProgramsAndCommunitiesQuery_member_programs as IProgram,
} from '@frontend/app/queries/types/MemberProgramsAndCommunitiesQuery';

import { useAuth } from '@frontend/context/authContext';
import { UpsertMemberAgentVariables } from '@frontend/app/queries/types/UpsertMemberAgent';
import { useSaveMember } from './hooks/useSaveMember';
import { useMemberContactInfo } from './hooks/useMemberContactInfo';
import { NoContactModal } from './NoContactModal';
import { useMemberPageContext } from '../hooks';

import styles from './AddMember.scss';
import { removeExtraSpaces } from '../../MemberDetail/MemberInfo/utils';
import { MemberDetails } from '../../MemberDetail/components/MemberDetails';
import { NewAddMemberHeader } from './NewAddMemberHeader';

const { useState, useEffect, useMemo } = React;

interface IProps {
  visible?: boolean;
  onSuccess?();
  onClose?();
  className?: string;
}

export const AddMember: React.FC<IProps> = React.memo((props) => {
  const [newMember, setNewMember] = useState<MemberInput>({
    name: '',
    fields: {},
    source: 'app',
  });

  const { user } = useAuth();

  const [ownerIds, setOwnerIds] = useState<string[]>([user.sub]);
  const [isImportant, setIsImportant] = useState<boolean>(false);
  const [selectedCommunities, setSelectedCommunities] = useState<ICommunity[]>([]);
  const [selectedPrograms, setSelectedPrograms] = useState<IProgram[]>([]);
  const [selectedActivations, setSelectedActivations] = useState<IActivation[]>([]);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);
  const [selectedAgents, setSelectedAgents] = useState<UpsertMemberAgentVariables[]>([]);

  const {
    data: {
      schemas: memberFieldSchemas = null,
    } = {},
  } = useMemberFieldSchemasQuery();

  const schemaByName = useMemo(() => (
    memberFieldSchemas && keyBy(memberFieldSchemas, 'name')
  ), [memberFieldSchemas]);

  const isDataSufficient = useMemo(() => (
    newMember.fields && schemaByName
    && !isEmpty(newMember.fields[schemaByName['First Name']?.id]?.trim())
    && !isEmpty(newMember.fields[schemaByName['Last Name']?.id]?.trim())
  ), [newMember, schemaByName]);

  const {
    programId,
    communityId,
  } = useMemberPageContext();

  const {
    data: {
      communities = null,
    } = {},
  } = useCommunitiesQuery({
    skip: !communityId || !props.visible,
  });

  const {
    data: {
      programs = null,
    } = {},
  } = useProgramsForCommunityQuery(communityId, {
    skip: !communityId || !programId || !props.visible,
  });

  const {
    getMemberByEmail,
  } = useGetMemberByEmail();

  const selectedCommunity = useMemo(() => find(communities, { id: communityId }), [communityId, communities]);

  const selectedProgram = useMemo(() => find(programs, { id: programId }), [programId, programs]);

  useEffect(() => {
    if (selectedCommunity && isEmpty(selectedCommunities)) {
      setSelectedCommunities([selectedCommunity]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCommunity]);

  useEffect(() => {
    if (selectedProgram && isEmpty(selectedPrograms)) {
      setSelectedPrograms([selectedProgram]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProgram]);

  const {
    showMessage,
    showError,
    showErrorMessage,
  } = useMessagingContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clearMemberData = () => {
    setOwnerIds([]);
    setIsImportant(false);
    setSelectedPrograms(selectedProgram ? [selectedProgram] : []);
    setSelectedCommunities(selectedCommunity ? [selectedCommunity] : []);
    setSelectedActivations([]);
    setSelectedTags([]);
    setSelectedAgents([]);
    setNewMember({
      name: '',
      fields: {},
    });
  };

  const [showNoContactInfoModal, setShowNoContactInfoModal] = useState<boolean>(false);

  const location = useLocation();

  const saveMember = useSaveMember({
    onError(err: any) {
      showError(err);
      setIsLoading(false);
    },
    onSuccess(member: { id: number }) {
      setIsLoading(false);
      setTimeout(clearMemberData, 400);
      showMessage({
        type: 'success',
        content: (
          <div className={styles.successToast}>
            New member added
            <NavLink
              to={{ ...location, pathname: `members/${member.id}` }}
              className={styles.viewLink}
            >
              View
            </NavLink>
          </div>
        ),
      });

      if (props.onSuccess) {
        props.onSuccess();
      }
    },
  });

  const {
    values: contactInfo,
    schemas: {
      firstNameSchema,
      lastNameSchema,
    },
  } = useMemberContactInfo(newMember, { skip: !props.visible });

  const handleSaveMember = async () => {
    setShowNoContactInfoModal(false);

    const { email } = newMember;
    if (email) {
      const member = await getMemberByEmail(email);
      if (member) {
        showErrorMessage(`Member with email ${email} already exists`);
        return;
      }
    }

    if (newMember.fields[firstNameSchema?.id]) {
      newMember.fields[firstNameSchema.id] = removeExtraSpaces(newMember.fields[firstNameSchema?.id]);
    }
    if (newMember.fields[lastNameSchema?.id]) {
      newMember.fields[lastNameSchema?.id] = removeExtraSpaces(newMember.fields[lastNameSchema?.id]);
    }
    if (newMember.name) {
      newMember.name = removeExtraSpaces(newMember.name);
    }

    setIsLoading(true);
    saveMember({
      member: newMember,
      activations: selectedActivations,
      tags: selectedTags,
      communities: selectedCommunities,
      programs: selectedPrograms,
      isImportant,
      agents: selectedAgents,
      ownerIds,
    });
  };

  const handleClickSave = () => {
    const contactInfoWithoutName = omit(contactInfo, 'firstName', 'lastName');
    if ((!firstName && !lastName) || !some(contactInfoWithoutName)) {
      setShowNoContactInfoModal(true);
    } else {
      handleSaveMember();
    }
  };

  const {
    firstName,
    lastName,
  } = contactInfo;

  useEffect(() => {
    let fields = newMember.fields;

    if (firstNameSchema && !fields[firstNameSchema.id]) {
      fields = {
        ...fields,
        [firstNameSchema.id]: firstName,
      };
    }

    if (lastNameSchema && !fields[lastNameSchema.id]) {
      fields = {
        ...fields,
        [lastNameSchema.id]: lastName,
      };
    }

    if (fields !== newMember.fields) {
      setNewMember({
        ...newMember,
        fields,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName]);

  return (
    <div className={cx(styles.AddMember, props.className)}>
      <div className={styles.newMemberDetails}>
        <NewAddMemberHeader
          member={newMember}
          ownerIds={ownerIds}
          isImportant={isImportant}
          onUpdateMember={setNewMember}
          onChangeOwners={setOwnerIds}
          onChangeIsImportant={setIsImportant}
          onClose={props.onClose}
        />
        <MemberDetails
          member={newMember}
          onUpdateMember={setNewMember}
          selectedTags={selectedTags}
          selectedCommunities={selectedCommunities}
          selectedPrograms={selectedPrograms}
          onChangeTags={setSelectedTags}
          selectedAgents={selectedAgents}
          onChangeCommunities={setSelectedCommunities}
          onChangePrograms={setSelectedPrograms}
          onChangeAgents={setSelectedAgents}
          isAddingMember
        />
        <div className={styles.footer}>
          <FreshButton
            type="primary"
            block
            onClick={handleClickSave}
            loading={isLoading}
            disabled={isLoading || !isDataSufficient}
          >
            Save
          </FreshButton>
        </div>
      </div>
      <NoContactModal
        open={showNoContactInfoModal}
        onCancel={setShowNoContactInfoModal.bind(this, false)}
        onContinue={handleSaveMember}
      >
        <></>
      </NoContactModal>
    </div>
  );
});

AddMember.defaultProps = {
  visible: false,
};
