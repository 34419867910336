import {
  Skeleton,
  Alert,
  Button,
} from '@revfluence/fresh';
import React from 'react';
import Helmet from 'react-helmet';
import { useGetEmbeddedReportById } from '@frontend/app/hooks/useGetEmbeddedReportById';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { toNumber } from 'lodash';
import styles from './EmbeddedReporting.scss';
import { Header } from './components/Header';

// Make sure that <tableau-viz> is accepted as a valid top-level DOM element in JSX/TSX without errors
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any
      'tableau-viz': any;
    }
  }
}

interface IMatchParams {
  id: string;
}

export const EmbeddedReporting: React.FC<RouteComponentProps> = () => {
  const { id } = useParams<IMatchParams>();
  const {
    data, loading, error, refetch,
  } = useGetEmbeddedReportById(toNumber(id));

  if (loading) {
    // Return Skeleton if loading
    return (
      <Skeleton
        active
        paragraph
      />
    );
  }

  if (!loading && error) {
    // Return error message if error
    return (
      <Alert
        action={(
          <Button
            onClick={() => refetch()}
            size="small"
            type="ghost"
          >
            Retry
          </Button>
        )}
        message="Oops! Something went wrong fetching this report."
        showIcon
        type="error"
      />
    );
  }

  return (
    <div>
      <Header title={data?.embeddedReportingItemById?.name} />
      <Helmet>
        <script async defer type="module" src="https://us-west-2b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js" />
      </Helmet>
      <div className={styles.tableauContainer}>
        <tableau-viz
          id="tableau-viz"
          src={data?.embeddedReportingItemById?.url}
          hide-tabs
          toolbar="hidden"
          height="100%"
          token={data?.embeddedReportingItemById?.token}
        />
      </div>
    </div>
  );
};

EmbeddedReporting.defaultProps = {};

EmbeddedReporting.displayName = 'EmbeddedReporting';

export default EmbeddedReporting;
