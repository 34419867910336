import {
  Button,
  Checkbox,
  CheckboxChangeEvent,
  Col,
  Collapse,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from '@revfluence/fresh';
import {
 CheckIcon, PenIcon, PlusIcon, XmarkIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { CaretDownIcon, CaretRightIcon } from '@revfluence/fresh-icons/solid/esm';
import React, { useEffect, useMemo, useState } from 'react';
import {
  GetProductAttributesByClientId_getProductAttributesByClientId_collections,
  GetProductAttributesByClientId_getProductAttributesByClientId_options,
} from '@frontend/applications/ProductFulfillmentApp/queries/types/GetProductAttributesByClientId';
import { v4 as uuidv4 } from 'uuid';
import {
  CatalogSelectionRuleConditionOperationType,
  CatalogSelectionRuleConditionQuantityType,
} from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import styles from './AdvancedRules.scss';
import { useGetProductAttributesByClientId } from '../hooks/useGetProductAttributesByClientId';
import { Condition, KeyAndValue, useSelectionCriteriaContext } from './SelectionCriteriaContext';
import { getCleanedTag } from '../../utils';

const { Text } = Typography;

enum DropdownLabels {
  collections = 'Collection',
  vendors = 'Vendor',
  productTypes = 'Product Type',
  tags = 'Tag',
  categories = 'Category',
  options = 'Option',
  price = 'Price',
}

interface SubConditionType {
  id: string;
  name: keyof typeof DropdownLabels;
  optionName?: string;
}

function isCollectionValues(
  param:
    | string[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_collections[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_options[],
): param is GetProductAttributesByClientId_getProductAttributesByClientId_collections[] {
  return (
    param.length > 0
    && (param[0] as GetProductAttributesByClientId_getProductAttributesByClientId_collections).__typename
      === 'CollectionPicklist'
  );
}

function isOptionValues(
  param:
    | string[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_collections[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_options[],
): param is GetProductAttributesByClientId_getProductAttributesByClientId_options[] {
  return (
    param.length > 0
    && (param[0] as GetProductAttributesByClientId_getProductAttributesByClientId_options).__typename === 'OptionPicklist'
  );
}

function isStringValues(
  param:
    | string[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_collections[]
    | GetProductAttributesByClientId_getProductAttributesByClientId_options[],
): param is string[] {
  return typeof param[0] === 'string';
}

export const SingleCondition = (
  {
    condition, metaData, removeCondition, localConditions, setLocalConditions,
  }:
  {
    condition: Condition;
    metaData: SubConditionType,
    removeCondition: () => void,
    localConditions: SubConditionType[],
    setLocalConditions: React.Dispatch<React.SetStateAction<SubConditionType[]>>
  },
) => {
  const { productAttributes } = useGetProductAttributesByClientId();

  const { addConditionField, setOptionConditionField, removeConditionField } = useSelectionCriteriaContext();

  const [selectedDropdown, setSelectedDropdown] = useState<keyof typeof DropdownLabels>(null);
  const [selectedOptionValue, setSelectedOptionValue] = useState<string>(null);

  const dropdownOptions = Object.entries({
    ...(productAttributes || {}),
    price: [],
  })
    .map(([key]) => ({
      label: DropdownLabels[key as keyof typeof DropdownLabels],
      value: key,
    }))
    .filter((option) => !!option.label);

  const selectedDropdownOption = useMemo(() => {
    let tempSelectedDropdownOption = productAttributes?.[selectedDropdown] || [];
    if (isCollectionValues(tempSelectedDropdownOption)) {
      const uniqueValues = tempSelectedDropdownOption.filter(
        (value, index, self) => self.findIndex((t) => t.id === value.id) === index,
      );
      tempSelectedDropdownOption = uniqueValues;
    }
    if (isStringValues(tempSelectedDropdownOption)) {
      const uniqueValues = tempSelectedDropdownOption.filter((value, index, self) => self.indexOf(value) === index);
      tempSelectedDropdownOption = uniqueValues;
    }
    return tempSelectedDropdownOption;
  }, [productAttributes, selectedDropdown]);

  const selectedOptionUniqueValues = useMemo(() => {
    if (isOptionValues(selectedDropdownOption)) {
      return selectedDropdownOption
        .find((option) => option.name === selectedOptionValue)
        ?.values.filter((value, index, self) => self.indexOf(value) === index);
    }
    return [];
  }, [selectedDropdownOption, selectedOptionValue]);

  const handleCollectionValuesChange = (values: string[]) => {
    const availableCollections = selectedDropdownOption as GetProductAttributesByClientId_getProductAttributesByClientId_collections[];
    addConditionField(condition.conditionId, {
      collections: availableCollections.filter((c) => values.includes(c.id)),
    });
  };

  const handleConditionFieldChange = (newField: keyof typeof DropdownLabels) => {
    if (selectedDropdown) {
      if (selectedDropdown === 'price') {
        removeConditionField(condition.conditionId, {
          priceMin: null,
          priceMax: null,
        } as KeyAndValue);
      } else if (selectedDropdown === 'options') {
        setOptionConditionField(condition.conditionId, selectedOptionValue, []);
      } else {
        removeConditionField(condition.conditionId, {
          [selectedDropdown]: [],
        } as KeyAndValue);
      }
    }
    setSelectedDropdown(newField);
    setLocalConditions((prev) => prev.map((c) => (c.id === metaData.id ? { ...c, name: newField } : c)));
  };

  const handleOptionValuesChange = (selectedOptionValue: string, values: string[]) => {
    setOptionConditionField(condition.conditionId, selectedOptionValue, values);
  };

  const handleStringValuesChange = (values: string[]) => {
    addConditionField(condition.conditionId, { [selectedDropdown]: values } as KeyAndValue);
  };

  const handlePriceValuesChange = (key: 'priceMin' | 'priceMax', value: number) => {
    addConditionField(condition.conditionId, { [key]: value } as KeyAndValue);
  };

  const handleRemoveCondition = () => {
    if (selectedDropdown) {
      if (selectedDropdown === 'price') {
        removeConditionField(condition.conditionId, {
          priceMin: null,
          priceMax: null,
        } as KeyAndValue);
      } else if (selectedDropdown === 'options') {
        setOptionConditionField(condition.conditionId, selectedOptionValue, []);
      } else {
        removeConditionField(condition.conditionId, {
          [selectedDropdown]: [],
        } as KeyAndValue);
      }
    }
    removeCondition();
  };

  const handleSelectedOptionValueChange = (value: string) => {
    setOptionConditionField(condition.conditionId, selectedOptionValue, []);
    setSelectedOptionValue(value);
    setLocalConditions((prev) => prev.map((c) => (c.id === metaData.id ? { ...c, optionName: value } : c)));
  };

  useEffect(() => {
    if (metaData.name) {
      setSelectedDropdown(metaData.name);
    }
    if (metaData.optionName) {
      setSelectedOptionValue(metaData.optionName);
    }
  }, [metaData]);

  const checkConditionOptionDisabled = (option: string) => {
    if (option === 'options') {
      return productAttributes?.options?.every((o) => localConditions.some((c) => c.name === 'options' && c.optionName === o.name));
    }
    return localConditions.some((c) => c.name === option);
  };

  const checkConditionOptionDisabledForOption = (optionName: string) => localConditions.some((c) => c.name === 'options' && c.optionName === optionName);

  return (
    <>
      <Col span={6}>
        <Select
          placeholder="Select"
          value={selectedDropdown}
          onChange={handleConditionFieldChange}
          className={styles.conditionSelect}
        >
          {dropdownOptions.map((option) => (
            <Select.Option
              key={option.value}
              value={option.value}
              disabled={checkConditionOptionDisabled(option.value)}
            >
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col flex={1}>
        {
          selectedDropdown === 'price' && (
            <Space className={styles.priceConditionContainer}>
              <InputNumber
                placeholder="Min"
                min={0}
                max={1000000}
                value={condition.priceMin}
                onChange={(value) => handlePriceValuesChange('priceMin', Number(value))}
              />
              <Text>to</Text>
              <InputNumber
                placeholder="Max"
                min={0}
                max={1000000}
                value={condition.priceMax}
                onChange={(value) => handlePriceValuesChange('priceMax', Number(value))}
              />
            </Space>
          )
        }
        {isCollectionValues(selectedDropdownOption) && (
          <Select
            mode="tags"
            placeholder="Value"
            style={{ width: '100%' }}
            value={condition.collections.map((c) => c.id)}
            onChange={handleCollectionValuesChange}
          >
            {selectedDropdownOption.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        )}
        {isOptionValues(selectedDropdownOption) && (
          <Row align="middle" gutter={8} wrap={false}>
            <Col>
              <Select placeholder="Select" value={selectedOptionValue} onChange={handleSelectedOptionValueChange}>
                {selectedDropdownOption.map((option) => (
                  <Select.Option key={option.name} value={option.name} disabled={checkConditionOptionDisabledForOption(option.name)}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col flex={1}>
              <Select
                mode="tags"
                placeholder="Value"
                style={{ width: '100%' }}
                value={condition.options.find((o) => o.name === selectedOptionValue)?.values}
                onChange={(values) => handleOptionValuesChange(selectedOptionValue, values)}
              >
                {selectedOptionUniqueValues
                  ?.map((option) => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
              </Select>
            </Col>
          </Row>
        )}
        {isStringValues(selectedDropdownOption) && (
          <Select
            mode="tags"
            placeholder="Value"
            style={{ width: '100%' }}
            value={condition[selectedDropdown]}
            onChange={handleStringValuesChange}
          >
            {selectedDropdownOption.map((option) => (
              <Select.Option key={option} value={option}>
                {getCleanedTag(option)}
              </Select.Option>
            ))}
          </Select>
        )}
        {!selectedDropdown && <Select mode="tags" placeholder="Value" style={{ width: '100%' }} />}
      </Col>
      {removeCondition && <Button icon={<XmarkIcon />} type="text" onClick={handleRemoveCondition} />}
    </>
  );
};

export const Conditions = ({ condition }: { condition: Condition }) => {
  const { productAttributes } = useGetProductAttributesByClientId();

  const [conditions, setConditions] = useState<SubConditionType[]>([]);

  const { removeConditionField, setOptionConditionField } = useSelectionCriteriaContext();

  const removeCondition = (conditionToRemove: SubConditionType) => {
    setConditions((prev) => prev.filter((condition) => condition.id !== conditionToRemove.id));
    if (conditionToRemove.name === 'options') {
      setOptionConditionField(condition.conditionId, conditionToRemove.optionName, []);
      return;
    }
    removeConditionField(condition.conditionId, {
      [conditionToRemove.name]: [],
    } as KeyAndValue);
  };

  const checkAddConditionDisabled = () => {
    const allConditionsUsed = Object.keys(DropdownLabels).every((key) => conditions.some((c) => c.name === key));
    const allOptionsUsed = productAttributes?.options?.every((o) => conditions.some((c) => c.name === 'options' && c.optionName === o.name));
    return allConditionsUsed && allOptionsUsed;
  };

  useEffect(() => {
    const savedConditions = [];
    if (conditions.length > 0) {
      return;
    }
    if (condition.categories.length) {
      savedConditions.push({
        id: uuidv4(),
        name: 'categories',
      });
    }
    if (condition.tags.length) {
      savedConditions.push({
        id: uuidv4(),
        name: 'tags',
      });
    }
    if (condition.vendors.length) {
      savedConditions.push({
        id: uuidv4(),
        name: 'vendors',
      });
    }
    if (condition.productTypes.length) {
      savedConditions.push({
        id: uuidv4(),
        name: 'productTypes',
      });
    }
    if (condition.options.length) {
      condition.options.forEach((option) => {
        if (option.values.length) {
          savedConditions.push({
            id: uuidv4(),
            name: 'options',
            optionName: option.name,
          });
        }
      });
    }
    if (condition.collections.length) {
      savedConditions.push({
        id: uuidv4(),
        name: 'collections',
      });
    }
    if (condition.priceMin || condition.priceMax) {
      savedConditions.push({
        id: uuidv4(),
        name: 'price',
      });
    }
    setConditions(savedConditions.length > 0 ? savedConditions : [{ id: uuidv4(), name: null }]);
  }, [condition, conditions.length]);

  return (
    <Space direction="vertical">
      {conditions.map((c, i) => (
        <Row align="middle" key={c.id} wrap={false}>
          <SingleCondition
            condition={condition}
            localConditions={conditions}
            setLocalConditions={setConditions}
            metaData={c}
            removeCondition={
              i !== 0 ? () => removeCondition(c) : null
            }
          />
        </Row>
      ))}
      <Button
        icon={<PlusIcon />}
        size="small"
        type="link"
        onClick={() =>
          setConditions((prev) => [
            ...prev,
            {
              id: uuidv4(),
              name: null,
            },
          ])}
        disabled={checkAddConditionDisabled()}
      >
        Add more
      </Button>
    </Space>
  );
};

export const ConditionAccordion = ({ condition }: { condition: Condition }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [title, setTitle] = useState(condition.conditionName);
  const [isEditing, setIsEditing] = useState(false);

  const { removeCondition, updateCondition } = useSelectionCriteriaContext();

  const handleNameChange = (conditionName: string) => {
    updateCondition(condition.conditionId, { conditionName });
  };

  const handleRequiredChange = (e: CheckboxChangeEvent) => {
    e.stopPropagation();
    updateCondition(condition.conditionId, { isRequired: e.target.checked });
  };

  const handleQuantityTypeChange = (value: CatalogSelectionRuleConditionQuantityType) => {
    updateCondition(condition.conditionId, { productQuantityType: value });
  };

  const handleQuantityChange = (value: number) => {
    updateCondition(condition.conditionId, { quantity: value });
  };

  const handleOperationChange = (value: CatalogSelectionRuleConditionOperationType) => {
    updateCondition(condition.conditionId, { operation: value });
  };

  return (
    <Collapse defaultActiveKey={isOpen ? ['1'] : []} ghost onChange={() => setIsOpen((prev) => !prev)}>
      <Collapse.Panel
        header={(
          <div className={styles.conditionPanelHeader}>
            {isOpen ? <CaretDownIcon /> : <CaretRightIcon />}
            <div
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              {isEditing ? (
                <Row align="middle" gutter={8} wrap={false}>
                  <Col>
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                  </Col>
                  <Col>
                    <Button
                      icon={<XmarkIcon />}
                      onClick={() => {
                      setIsEditing(false);
                    }}
                      type="text"
                      size="small"
                    />
                  </Col>
                  <Col>
                    <Button
                      icon={<CheckIcon />}
                      onClick={() => {
                      setIsEditing(false);
                      handleNameChange(title);
                    }}
                      type="text"
                      size="small"
                    />
                  </Col>
                </Row>
              ) : (
                <Row align="middle" gutter={8} wrap={false}>
                  <Col>
                    <Text weight="semibold" className={styles.conditionName}>
                      {condition.conditionName}
                    </Text>
                  </Col>
                  <Col>
                    <Button
                      icon={<PenIcon fontSize={12} />}
                      onClick={() => {
                      setTitle(condition.conditionName);
                      setIsEditing(true);
                    }}
                      type="text"
                      size="small"
                    />
                  </Col>
                </Row>
              )}
            </div>
            <Checkbox
              className={styles.requiredCheckbox}
              onClick={(e) => e.stopPropagation()}
              checked={condition.isRequired}
              onChange={handleRequiredChange}
            >
              <span onClick={(e) => e.stopPropagation()}>Required</span>
            </Checkbox>
            <Button
              icon={<XmarkIcon />}
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                removeCondition(condition.conditionId);
              }}
            />
          </div>
        )}
        showArrow={false}
        key="1"
        className={styles.conditionPanelContent}
      >
        <Space direction="vertical">
          <Space align="center">
            <Text>Creator can select a</Text>
            <Select
              size="small"
              defaultValue={CatalogSelectionRuleConditionQuantityType.MINIMUM}
              value={condition.productQuantityType}
              onChange={handleQuantityTypeChange}
            >
              <Select.Option value={CatalogSelectionRuleConditionQuantityType.MINIMUM}>minimum</Select.Option>
              <Select.Option value={CatalogSelectionRuleConditionQuantityType.MAXIMUM}>maximum</Select.Option>
            </Select>
            <Text>of</Text>
            <InputNumber size="small" value={condition.quantity} min={1} max={1000} onChange={handleQuantityChange} />
            <Text>products.</Text>
          </Space>
          <Space align="center">
            <Text>Products should follow</Text>
            <Select
              size="small"
              defaultValue={CatalogSelectionRuleConditionOperationType.AND}
              bordered={false}
              value={condition.operation}
              onChange={handleOperationChange}
            >
              <Select.Option value={CatalogSelectionRuleConditionOperationType.AND}>all conditions</Select.Option>
              <Select.Option value={CatalogSelectionRuleConditionOperationType.OR}>any condition</Select.Option>
            </Select>
          </Space>
          <Conditions condition={condition} />
        </Space>
      </Collapse.Panel>
    </Collapse>
  );
};

export const AdvancedRules = () => {
  const { conditions, addCondition } = useSelectionCriteriaContext();

  const disabledAddCondition = conditions.length === 10;

  return (
    <Space direction="vertical" size="middle" className={styles.AdvancedRules}>
      <Row align="middle" justify="space-between">
        <Col>
          <Text weight="semibold">Conditions</Text>
        </Col>
        <Col>
          <Tooltip title={disabledAddCondition ? 'At most 10 conditions can be added in a selection rule' : ''}>
            <Button icon={<PlusIcon />} type="link" onClick={addCondition} disabled={disabledAddCondition}>
              Add Condition
            </Button>
          </Tooltip>
        </Col>
      </Row>
      {conditions.map((condition) => (
        <div key={condition.conditionId} className={styles.conditionWrapper}>
          <ConditionAccordion condition={condition} />
        </div>
      ))}
      <Tooltip title={disabledAddCondition ? 'At most 10 conditions can be added in a selection rule' : ''}>
        <Button icon={<PlusIcon />} type="dashed" block onClick={addCondition} disabled={disabledAddCondition}>
          Add Condition
        </Button>
      </Tooltip>
    </Space>
  );
};
