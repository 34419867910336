import React from 'react';
import { Button, Space } from '@revfluence/fresh';
import { useProductFulfillmentContext } from '@frontend/applications/ProductFulfillmentApp/context';
import { useCreateOrderRequestCreateInteraction } from '@frontend/applications/ProductFulfillmentApp/hooks/useCreateOrderRequestCreatorInteraction';
import { useApplication } from '@frontend/applications/Shared/context/applicationContext';
import { useMessagingContext } from '@frontend/hooks';
import styles from './SendEmail.scss';
import getInitialEmailState from '../../utils/initialEmailStateSendOrderRequest';
import { CPSEmailComposer } from '../EmailComposer/EmailComposer';
import { isEmailAddressValid } from '../../../../../../../common/Utils';
import { useSendCatalogContext } from './SendCatalogContext';

export const SendEmail = ({ handleScreenChange }: { handleScreenChange: () => void }) => {
  const { shopifyResource, workItems, allMembers } = useProductFulfillmentContext();

  const { workflowActionParameters, closeModal } = useApplication();

  const {
 selectedCatalog, selectedCatalogs, selectedRule, selectedRules, selectionType,
} = useSendCatalogContext();

  const programId = workflowActionParameters.programId;

  const { showMessage } = useMessagingContext();

  const { createOrderRequestCreatorInteraction } = useCreateOrderRequestCreateInteraction({
    onCompleted: () => {
      closeModal();
      showMessage({
        type: 'success',
        content: 'Order request sent',
      });
    },
  });

  const beforeShopifyOrderRequestEmailCreateInteraction = async () => {
    for (const member of allMembers) {
      const selectedRuleAsNumber = selectedRule ? Number(selectedRule) : undefined;
      const selectedRulesAsNumber = selectedRules[member.id] ? Number(selectedRules[member.id]) : undefined;
      await createOrderRequestCreatorInteraction({
        variables: {
          resourceId: shopifyResource.id,
          params: {
            programId,
            member: {
              id: member.id,
              name: member.name,
              email: member.email,
            },
            workItemId: workItems?.find((wi) => wi?.data?.memberId === member.id)?.id,
            specURI: workflowActionParameters.workletSpecUri,
            brandCatalogId: selectionType === 'bulk' ? selectedCatalog : selectedCatalogs[member.id],
            selectionRuleId: selectionType === 'bulk' ? selectedRuleAsNumber : selectedRulesAsNumber,
          },
        },
      });
    }
  };

  const validMembers = allMembers ? allMembers.filter((m) => isEmailAddressValid(m.email)) : [];
  const memberIds = validMembers.map((m) => m.id);

  const CPSEmailComposerParams = {
    className: styles.EmailComposer,
    memberIds,
    beforeMessageSent: beforeShopifyOrderRequestEmailCreateInteraction,
    getInitialState: getInitialEmailState,
  };

  return (
    <div className={styles.EmailContainer}>
      <div className={styles.MessageComposer}>
        <CPSEmailComposer {...CPSEmailComposerParams} />
      </div>
      <Space>
        <Button onClick={closeModal}>Cancel</Button>
        <Button type="default" onClick={handleScreenChange}>
          Back
        </Button>
      </Space>
    </div>
  );
};
