import * as React from 'react';
import { OfferFormToolTips } from '@affiliates/components';
import { OFFER_TRACKING_TYPE } from '@frontend/applications/AffiliatesApp/types/globalTypes';
import {
  Button, Checkbox, Form, Collapse, Alert,
} from '@revfluence/fresh';
import { GraphQLObjectType } from 'graphql/type';
import { PlusOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import {
  OfferConversionTracking,
  OfferExpirationDate,
  OfferStatus,
  OfferUrl,
} from './FormElements';
import { FormAsyncAction, IAffiliateLinksFormValues, TDisabledMap } from '../../types';

import styles from '../../OfferForm.scss';
import { UtmFields } from './FormElements/UtmFields';
import { UtmCustomFields } from './FormElements/UtmCustomFields';
import { AllowMultipleDomain } from './FormElements/AllowMultipleDomain';

const { useMemo } = React;
const { Panel } = Collapse;

interface IProps {
  disabled: TDisabledMap<IAffiliateLinksFormValues>;
  onFieldFocused: (type: string, selected: boolean) => void;
  showStatus: boolean;
  values: IAffiliateLinksFormValues;
  initialUrl: string;
  utmFields?: GraphQLObjectType,
  hasMembers: boolean,
  handleFormAsyncActions?: (value: FormAsyncAction) => void;
  migrateToGraphQL: boolean;
  initialAllowedDomains: string[];
}

export const AffiliateLinkSection: React.FC<Readonly<IProps>> = ({
  disabled,
  onFieldFocused,
  showStatus,
  values,
  initialUrl,
  hasMembers,
  handleFormAsyncActions,
  migrateToGraphQL,
  initialAllowedDomains,
}) => {
  const urlEvents = useMemo(() => ({
    onFocus: () => onFieldFocused(OfferFormToolTips.urlInfo, true),
    onBlur: () => onFieldFocused(null, true),
    onMouseEnter: () => onFieldFocused(OfferFormToolTips.urlInfo, false),
    onMouseLeave: () => onFieldFocused(null, false),
  }), [onFieldFocused]);
  const conversionTrackingTypeEvents = useMemo(() => ({
    onFocus: () => onFieldFocused(OfferFormToolTips.conversionTrackingInfo, true),
    onBlur: () => onFieldFocused(null, true),
    onMouseEnter: () => onFieldFocused(OfferFormToolTips.conversionTrackingInfo, false),
    onMouseLeave: () => onFieldFocused(OfferFormToolTips.conversionTrackingInfo, false),
  }), [onFieldFocused]);
  const isPostBack = values.conversionTrackingType === OFFER_TRACKING_TYPE.SERVER_POSTBACK_AFFILIATE_ID
    || values.conversionTrackingType === OFFER_TRACKING_TYPE.SERVER_POSTBACK_TRANSACTION_ID;
  const utmSource = useMemo(() => ({
    onFocus: () => onFieldFocused(OfferFormToolTips.utmSource, true),
    onBlur: () => onFieldFocused(null, true),
    onMouseEnter: () => onFieldFocused(OfferFormToolTips.utmSource, false),
    onMouseLeave: () => onFieldFocused(null, false),
  }), [onFieldFocused]);
  const utmMedium = useMemo(() => ({
    onFocus: () => onFieldFocused(OfferFormToolTips.utmMedium, true),
    onBlur: () => onFieldFocused(null, true),
    onMouseEnter: () => onFieldFocused(OfferFormToolTips.utmMedium, false),
    onMouseLeave: () => onFieldFocused(null, false),
  }), [onFieldFocused]);
  const utmCampaign = useMemo(() => ({
    onFocus: () => onFieldFocused(OfferFormToolTips.utmCampaign, true),
    onBlur: () => onFieldFocused(null, true),
    onMouseEnter: () => onFieldFocused(OfferFormToolTips.utmCampaign, false),
    onMouseLeave: () => onFieldFocused(null, false),
  }), [onFieldFocused]);
  const utmContent = useMemo(() => ({
    onFocus: () => onFieldFocused(OfferFormToolTips.utmContent, true),
    onBlur: () => onFieldFocused(null, true),
    onMouseEnter: () => onFieldFocused(OfferFormToolTips.utmContent, false),
    onMouseLeave: () => onFieldFocused(null, false),
  }), [onFieldFocused]);
  const utmTerm = useMemo(() => ({
    onFocus: () => onFieldFocused(OfferFormToolTips.utmTerm, true),
    onBlur: () => onFieldFocused(null, true),
    onMouseEnter: () => onFieldFocused(OfferFormToolTips.utmTerm, false),
    onMouseLeave: () => onFieldFocused(null, false),
  }), [onFieldFocused]);
  const customUtmFields = useMemo(() => ({
    onFocus: () => onFieldFocused(OfferFormToolTips.utmTerm, true),
    onBlur: () => onFieldFocused(null, true),
    onMouseEnter: () => onFieldFocused(OfferFormToolTips.utmTerm, false),
    onMouseLeave: () => onFieldFocused(null, false),
  }), [onFieldFocused]);
  const isProjectNameExistsInUtm = useCallback(() => {
    const keysToCheck = ['utmSource', 'utmMedium', 'utmCampaign', 'utmContent', 'utmTerm'];
    const customKeysToCheck = values?.customUTMParameters?.map((param) => param.value);
    return keysToCheck.some((key) => values[key] === '{project_name}') || customKeysToCheck && customKeysToCheck.includes('{project_name}');
  }, [values])();

  return (
    <Collapse defaultActiveKey={['1', '2', '4']} ghost>
      <Panel className={styles.formSection} key={1} header="Conversion & Tracking">
        <OfferConversionTracking
          disabled={disabled.conversionTrackingType}
          events={conversionTrackingTypeEvents}
          name="conversionTrackingType"
        />
        <OfferUrl
          charCount={values.url?.length || 0}
          conversionTrackingType={values.conversionTrackingType}
          validateSameDomain={disabled.conversionTrackingType}
          validateSameParam={disabled.conversionTrackingType && isPostBack}
          currentUrl={initialUrl}
          disabled={disabled.url}
          events={urlEvents}
          name="url"
        />
        <div className={styles.utmWrapper}>
          <div className={styles.urlwrapper}>
            <Form.Item className="mb-0">
              <div className={styles.boxwrapper}>
                {' '}
                <Checkbox onChange={(e) => handleFormAsyncActions({ action: 'UpdateField', payload: { key: 'isAdvanceUrlEnable', value: e.target.checked } })} checked={values.isAdvanceUrlEnable} disabled={hasMembers} />
                {' '}
                <span className={styles.urltitle}>UTM Parameters Settings</span>
              </div>
              <div className={styles.subheading}>
                Append and manage the UTM parameters in to the Offer URL.
              </div>
            </Form.Item>
          </div>
          {values.isAdvanceUrlEnable && (
            <div className={styles.urlwrapper}>
              {isProjectNameExistsInUtm && (
                <div className={styles.AlertWrapper}>
                  <Alert
                    message="Please ensure that your project name does not include the special characters '%' and '&'. If your project name currently contains these characters, kindly update it before generating links for your creator to prevent any issues."
                    description="If you've already generated links for project name with these characters and encountered issues, contact support for help"
                    type="warning"
                  />
                </div>
              )}
              <UtmFields
                name="advanceUrlSettingType"
                disabled={hasMembers}
                label="UTM Source"
                keyName="utmSource"
                events={utmSource}
              />
              <UtmFields
                name="advanceUrlSettingType"
                disabled={hasMembers}
                label="UTM Medium"
                keyName="utmMedium"
                events={utmMedium}
              />
              <UtmFields
                name="advanceUrlSettingType"
                disabled={hasMembers}
                label="UTM Campaign"
                keyName="utmCampaign"
                events={utmCampaign}
              />
              <UtmFields
                name="advanceUrlSettingType"
                disabled={hasMembers}
                label="UTM Content"
                keyName="utmContent"
                events={utmContent}
              />
              <UtmFields
                name="advanceUrlSettingType"
                disabled={hasMembers}
                label="UTM Term"
                keyName="utmTerm"
                events={utmTerm}
              />
              <div className="ant-form-item-label">
                <label>Custom Parameters</label>
              </div>
              <Form.List
                name="customUTMParameters"
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({
                      key, name,
                    }, index) => (
                      <UtmCustomFields
                        name="advanceUrlSettingType"
                        key={key}
                        customName={name}
                        disabled={hasMembers}
                        events={customUtmFields}
                        index={index}
                        remove={remove}
                      />
                    ))}
                    {
                      fields.length < 3 ? (
                        <Form.Item name="button">
                          <Button size="large" onClick={() => add({ key: '', value: '' })} disabled={hasMembers}>
                            <PlusOutlined />
                            Add Parameter
                          </Button>
                        </Form.Item>
                      ) : (
                        <div className={styles.customText}>You can add up to 3 custom parameters only</div>
                      )
                    }
                  </>
                )}
              </Form.List>
            </div>
          )}
        </div>
        {
          !migrateToGraphQL && (
            <>
              <OfferExpirationDate disabled={disabled.expirationDate} name="expirationDate" />
              {showStatus && <OfferStatus disabled={disabled.status} name="status" />}
            </>
          )
        }
      </Panel>
      {
        migrateToGraphQL && (
          <Panel className={styles.formSection} key={2} header="Expiration date">
            <OfferExpirationDate disabled={disabled.expirationDate} name="expirationDate" />
          </Panel>
        )
      }
      <Panel className={styles.formSection} key={4} header="Advanced Options">
        <AllowMultipleDomain disabled={false} name="multipleDomain" isCreatorDeeplinkAllowed={values.creatorDeeplink} initialAllowedDomains={initialAllowedDomains} handleFormAsyncActions={handleFormAsyncActions} />
      </Panel>
    </Collapse>
  );
};
