import React, { useEffect, useState } from 'react';
import {
  ImportStatus,
  Operation,
  Priority,
  Source,
} from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';
import {
 Button, Popconfirm, Space, Spinner, Tag,
 Tooltip,
} from '@revfluence/fresh';
import {
  ArrowsRotateIcon,
 CheckIcon, PlusIcon, SpinnerThirdIcon, XmarkIcon,
} from '@revfluence/fresh-icons/regular/esm';
import { GetShopifyCollections_getShopifyCollections_collections } from '@frontend/applications/ProductFulfillmentApp/queries/types/GetShopifyCollections';
import { RotateLeftIcon } from '@revfluence/fresh-icons/regular/esm';
import { ObservableQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useMessagingContext } from '@frontend/hooks';
import { GET_IMPORTED_PRODUCTS } from '@frontend/applications/ProductFulfillmentApp/queries/importedProducts';
import { GET_IMPORTED_PRODUCTS_STATS } from '@frontend/applications/ProductFulfillmentApp/queries';
import { GET_PRODUCT_DETAILS } from '@frontend/applications/ProductFulfillmentApp/queries/productDetails';
import { GET_COLLECTION_DETAILS } from '@frontend/applications/ProductFulfillmentApp/queries/collectionDetails';
import { GET_IMPORTED_COLLECTIONS_STATS_QUERY } from '@frontend/applications/ProductFulfillmentApp/queries/shopifyCollections';
import { useCreateImporterTask } from '../hooks/useCreateImporterTask';
import styles from './ImportStatusRenderer.scss';
import { useDeleteImporterTask } from '../hooks/useDeleteImporterTask';
import { pfaV2Routes } from '../../routes';
import { useResyncImportedCollectionById } from '../hooks/useResyncImportedCollectionById';

export interface ImportStatusRendererProps<TData, TVariables> {
  collection: Omit<GetShopifyCollections_getShopifyCollections_collections, 'description' | '__typename'>;
  statusInfo?: {
    id: number;
    externalId: string;
    status: ImportStatus;
  };
  refetch?: ObservableQuery<TData, TVariables>['refetch'];
  showGoToCollection?: boolean;
  productLimit?: number;
  showActions?: boolean;
  startPollingStatuses?: (interval: number) => void;
  lastSyncDate?: string;
}
const checkResyncAllowed = (lastSyncDateInput: string): { allowResync: boolean, message: string } => {
  if (!lastSyncDateInput) {
    return { allowResync: true, message: 'Sync Collection' };
  }
  const lastSyncDate = new Date(lastSyncDateInput);
  if (isNaN(lastSyncDate.getTime())) {
    return null;
  }
  const now = new Date();
  const diffInMilliseconds = now.getTime() - lastSyncDate.getTime();
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  let message = '';
  if (diffInHours < 1) {
    // If the difference is less than 1 hour, calculate the time left in minutes
    const timeLeft = Math.round((1 - diffInHours) * 60);
    message = `You can re-sync this collection after ${timeLeft} mins`;
    return { allowResync: false, message };
  } else {
    message = 'Sync Collection';
    return { allowResync: true, message };
  }
};

export const ImportStatusRenderer = <TData, TVariables>({
  collection,
  statusInfo,
  refetch,
  showGoToCollection,
  productLimit,
  startPollingStatuses,
  showActions = true,
  lastSyncDate = null,
}: ImportStatusRendererProps<TData, TVariables>) => {
  const [status, setStatus] = useState(statusInfo?.status ?? collection.currentStatus);

  const resyncAllowedCheck = checkResyncAllowed(lastSyncDate);
  const [isRefetching, setIsRefetching] = useState(false);

  const history = useHistory();

  const { showMessage } = useMessagingContext();

  useEffect(() => {
    setStatus(statusInfo?.status ?? collection.currentStatus);
  }, [collection.currentStatus, statusInfo?.status]);

  const { mutate: startImport, loading: startImportLoading } = useCreateImporterTask({
    onCompleted: (data) => {
      if (!refetch) {
        setStatus(data.createImporterTask.status);
      }
      startPollingStatuses?.(3000);
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        content: error.message,
      });
    },
    refetchQueries: [GET_IMPORTED_PRODUCTS, GET_IMPORTED_PRODUCTS_STATS, GET_PRODUCT_DETAILS, GET_COLLECTION_DETAILS, GET_IMPORTED_COLLECTIONS_STATS_QUERY],
  });

  const { mutate: stopImport, loading: cancelImportLoading } = useDeleteImporterTask();

  const { mutate: resyncImport, loading: resyncImportLoading } = useResyncImportedCollectionById({
    onCompleted: (data) => {
      setStatus(data.resyncImportedCollectionById.status);
      startPollingStatuses?.(3000);
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        content: error.message,
      });
    },
    refetchQueries: [GET_IMPORTED_PRODUCTS, GET_IMPORTED_PRODUCTS_STATS, GET_PRODUCT_DETAILS, GET_COLLECTION_DETAILS, GET_IMPORTED_COLLECTIONS_STATS_QUERY],
  });

  const isLoading = startImportLoading || cancelImportLoading || isRefetching || resyncImportLoading;

  const retryImport = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await startImport({
      variables: {
        params: {
          collectionData: {
            id: collection.id,
            image: collection.image,
            title: collection.title,
            productsCount: collection.productsCount,
            source: Source.SHOPIFY,
            handle: collection.handle,
          },
          priority: Priority.HIGH,
          operation: Operation.IMPORT,
        },
      },
    });
    setIsRefetching(true);
    await refetch?.();
    setIsRefetching(false);
  };

  const handleStopImport = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await stopImport({
      variables: {
        collectionId: statusInfo?.id,
      },
    });
    setStatus(ImportStatus.CANCELLED);
  };

  const handleResyncImport = async (e: React.MouseEvent) => {
    e.stopPropagation();
    resyncImport({ variables: { collectionId: statusInfo.id } });
  };

  if (isLoading) {
    return (
      <Spinner
        className={styles.ImportStatusRenderer}
        indicator={<SpinnerThirdIcon fontSize={12} />}
        size="small"
        data-testid="data-spinner"
      />
    );
  }

  if (status === ImportStatus.INPROGRESS) {
    return (
      <Space className={styles.ImportStatusRenderer} size={0}>
        <Tag color="processing">Importing</Tag>
        <Spinner indicator={<SpinnerThirdIcon fontSize={12} />} size="small" />
      </Space>
    );
  }

  if (status === ImportStatus.WAITING) {
    return (
      <Space className={styles.ImportStatusRenderer} size={0}>
        <Tag color="warning">Waiting</Tag>
        {showActions && (
        <Popconfirm
          title="Are you sure you want to cancel this import?"
          okText="Yes"
          cancelText="No"
          onConfirm={handleStopImport}
        >
          <Button type="text" icon={<XmarkIcon />} size="small" onClick={(e) => e.stopPropagation()} />
        </Popconfirm>
)}
      </Space>
    );
  }

  if (status === ImportStatus.COMPLETED) {
    if (showGoToCollection) {
      return (
        <Space className={styles.ImportStatusRenderer}>
          <Button
            type="link"
            size="small"
            onClick={() =>
              history.push(`${pfaV2Routes.settings.collectionDetails.replace(':collectionId', String(statusInfo?.id))}`)}
          >
            Go To Collection
          </Button>
          <Button icon={<CheckIcon />} disabled size="small">
            Imported
          </Button>
          {showActions && (
            <Tooltip title={resyncAllowedCheck?.message}>
              <Button
                type="text"
                disabled={!resyncAllowedCheck?.allowResync}
              >
                <ArrowsRotateIcon onClick={handleResyncImport} />
                {' '}
              </Button>
            </Tooltip>
          )}
          {' '}
        </Space>
      );
    }
    return (
      <>
        <Tag color="success" icon={<CheckIcon />}>
          Imported
          {' '}
        </Tag>
        {showActions && (
          <Tooltip title={resyncAllowedCheck?.message}>
            <Button
              type="text"
              disabled={!resyncAllowedCheck?.allowResync}
            >
              <ArrowsRotateIcon onClick={handleResyncImport} />
              {' '}
            </Button>
          </Tooltip>
        )}
      </>
    );
  }

  if (status === ImportStatus.FAILED) {
    return (
      <Space className={styles.ImportStatusRenderer} size={0}>
        <Tag color="error">Failed</Tag>
        {showActions && <Button type="text" icon={<RotateLeftIcon />} size="small" onClick={retryImport} />}
      </Space>
    );
  }

  if (status === ImportStatus.PARTIALLY_IMPORTED) {
    return (
      <Space className={styles.ImportStatusRenderer} size={0}>
        <Tag color="error">Partially Imported</Tag>
        {showActions && <Button type="text" icon={<RotateLeftIcon />} size="small" onClick={retryImport} />}
      </Space>
    );
  }

  if (status === ImportStatus.CANCELLED) {
    return (
      <Space className={styles.ImportStatusRenderer} size={0}>
        <Tag color="error">Cancelled</Tag>
        {showActions && <Button type="text" icon={<RotateLeftIcon />} size="small" onClick={retryImport} />}
      </Space>
    );
  }

  return (
    <Button
      icon={<PlusIcon />}
      size="small"
      type="primary"
      onClick={(e) => {
        e.stopPropagation();
        if (productLimit && collection.productsCount > productLimit) {
          showMessage({
            type: 'error',
            content: 'Importing failed due to available limit reached',
          });
          return;
        }
        startImport({
          variables: {
            params: {
              collectionData: {
                id: collection.id,
                image: collection.image,
                title: collection.title,
                productsCount: collection.productsCount,
                source: Source.SHOPIFY,
                handle: collection.handle,
              },
              priority: Priority.HIGH,
              operation: Operation.IMPORT,
            },
          },
        });
      }}
      className={styles.ImportStatusRenderer}
    >
      Import
    </Button>
  );
};
