import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from '@revfluence/fresh';
import cx from 'classnames';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { find, toLower } from 'lodash';

import { logger } from '@common';
import { LoadSpinner } from '@frontend/app/components';
import {
  TProject,
  TSpecificationTemplate,
} from '@frontend/app/containers/Projects/types';
import {
  useGetAllWorkletsQuery,
  useGetSpecificationTemplateByNameQuery,
  useParsedRouterSearch,
  useClientFeatureEnabled,
} from '@frontend/app/hooks';
import { useMessagingContext } from '@frontend/hooks';
import { ClientFeature } from '@frontend/app/constants';
import { useProjectsApp } from '../hooks';

import { ProjectDetailsForm } from './ProjectDetailsForm';
import { ProjectsRouteRoot } from '../constants';
import { TEMPLATES_FOR_SALES } from '../TemplatesPage/TemplatesPage';

import styles from './AddPage.scss';

const { useState, useMemo, useEffect } = React;

interface IRouteSearchParams {
  templateName?: TSpecificationTemplate['templateName'];
}

export const AddPage: React.FC = () => {
  const history = useHistory();
  const { templateName } = useParsedRouterSearch() as IRouteSearchParams;
  const isDemoAccount = useClientFeatureEnabled(ClientFeature.DEMO);

  const {
    showGenericErrorMessage,
    showSuccessMessage,
  } = useMessagingContext();
  const demoTemplate = useMemo(() => {
    if (isDemoAccount) {
      return find(
        TEMPLATES_FOR_SALES,
        (t) => toLower(t.templateName) === toLower(templateName),
      );
    }

    return null;
  }, [isDemoAccount, templateName]);

  const { refetchData, projects } = useProjectsApp();
  const [newProjectId, setNewProjectId] = useState<number>();
  const [hideConfirmation, setHideConfirmation] = useState(false);

  /**
   * Get spec template
   */

  const {
    loading: isTemplateLoading,
    data: {
      template = undefined,
    } = {},
  } = useGetSpecificationTemplateByNameQuery({
    variables: { templateName },
    skip: !!demoTemplate || !templateName,
  });

  const loadedTemplate = demoTemplate || template;
  const {
    loading: isWorkletsLoading,
    data: {
      worklets: defaultWorklets = undefined,
    } = {},
  } = useGetAllWorkletsQuery({
    variables: {
      specKeys: loadedTemplate?.workletSpecKeys,
    },
    skip: !loadedTemplate?.workletSpecKeys,
  });

  /**
   * Submit form
   */
  const [isSubmitting, setSubmitting] = useState(false);
  const handleSubmit = (project: TProject) => {
    const projectId = project?.id;
    if (!projectId) {
      throw new Error('Project is empty');
    }
    refetchData()
      .then(() => {
        logger.debug('Navigating to project:', { projectId });
        setNewProjectId(projectId);
        setHideConfirmation(true);
      })
      .catch((error) => {
        logger.error(error);
        showGenericErrorMessage();
      });
  };

  useEffect(() => {
    const newProject = find(projects, { id: newProjectId });
    if (newProject) {
      const pathname = `${ProjectsRouteRoot}/${newProjectId}/overview`;
      history.push({ ...history.location, pathname, search: undefined });
      showSuccessMessage(`Successfully created "${newProject.title}"`, 6000);
      setNewProjectId(undefined);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, newProjectId]);

  const isLoading = (
    isTemplateLoading
    || isWorkletsLoading
    || (!!loadedTemplate && !defaultWorklets)
  );

  return (
    <div
      className={cx(
        styles.AddPage,
        { [styles.submitting]: isSubmitting },
      )}
    >
      <header className={styles.header}>
        <Button
          className={styles.backButton}
          onClick={history.goBack}
          icon={<ArrowLeftOutlined />}
        >
          Back
        </Button>
        <h1 className={styles.formTitle}>Create a Project</h1>
      </header>
      {isLoading
        ? <LoadSpinner className={styles.loadSpinner} />
        : (
          <ProjectDetailsForm
            defaultWorklets={defaultWorklets}
            isLoading={isLoading || isSubmitting}
            mode="add"
            onSubmit={handleSubmit}
            onSubmitting={setSubmitting}
            templateName={loadedTemplate?.templateName}
            hideConfirmation={hideConfirmation}
          />
        )}
    </div>
  );
};
