import * as React from 'react';
import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';

import ContentAppReport from './ContentAppReport';

const application = {
  displayName: 'Content',
  iconUrl: 'https://storage.googleapis.com/aspirex-static-files/content.svg',
};

const ContentAppDashboard: React.FunctionComponent = () => {
  const navSettings = [
    {
      route: 'reports',
      displayName: 'My Content',
      component: ContentAppReport,
    },
  ];

  return (
    <AppDashboardNav
      application={application}
      navLinks={navSettings}
      hideTabs
      defaultRoute="reports"
      fixToScreenHeight
    />
  );
};

export default ContentAppDashboard;
