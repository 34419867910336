import { isString } from 'lodash';

type TypeUrl = string | URL;

export const createUrlObj = (url: TypeUrl): URL | string => {
  try {
    return new URL(url);
  } catch (e) {
    return url || '';
  }
};

export const getParamKeyFromValue = (url: URL, paramValue: string): string | null => {
  let paramKey: string | null = null;
  url.searchParams.forEach((value, key) => {
    if (value === paramValue) {
      paramKey = key;
    }
  });
  return paramKey;
};

export const checkUrlsHasSameParam = (firstUrl: URL, secondUrl: TypeUrl, paramValue: string) => {
  const paramKey = getParamKeyFromValue(firstUrl, paramValue);
  if (paramKey === null) {
    return {
      valid: false,
      paramString: undefined,
    };
  }

  const paramString = `${paramKey}=${firstUrl.searchParams.get(paramKey)}`;
  if (isString(secondUrl)) {
    return {
      paramString,
      valid: secondUrl.includes(paramString),
    };
  }
  return {
    paramString,
    valid: secondUrl.searchParams.get(paramKey) === firstUrl.searchParams.get(paramKey),
  };
};

export const checkUrlsHasSameDomain = (firstUrl: TypeUrl, secondUrl: TypeUrl) => {
  const firstURL = createUrlObj(firstUrl);
  const secondURL = createUrlObj(secondUrl);
  if (isString(firstURL) || isString(secondURL)) {
    return false;
  }
  return firstURL.origin.replace('www.', '') === secondURL.origin.replace('www.', '');
};

export const checkMultipleDomainHasDeepLink = (deepLink: TypeUrl, domains: string[]) => {
  const deepLinkUrl = createUrlObj(deepLink);
  if (isString(deepLinkUrl)) {
    return false;
  }
  for (const domain of domains) {
    const domainUrl = createUrlObj(domain);
    if (checkUrlsHasSameDomain(domainUrl, deepLinkUrl)) {
      return true;
    }
  }
  return false;
};
