import * as React from 'react';
import {
  map,
  chain,
  flatten,
  filter,
  size,
  sumBy,
  reduce,
} from 'lodash';
import { THandleTaskSelected } from '@frontend/app/containers/Projects/ProjectsPage/ProjectsPage';
import { TTask } from '@frontend/app/containers/Projects/types';
import {
  ICounts,
} from '@frontend/app/containers/Projects/hooks';

import { useHistory } from 'react-router-dom';

import {
  AngleRightIcon,
  FileCheckIcon,
  AddressCardIcon,
  LinkIcon,
  ImageIcon,
  FilePdfIcon,
  BoxCheckIcon,
  FolderUserIcon,
  CalendarImageIcon,
  WrenchIcon,
  CertificateIcon,
  EnvelopeIcon,
  TagsIcon,
  DollarSignIcon,
} from '@revfluence/fresh-icons/regular/esm';

import {
  Row,
  Col,
  List,
  Typography,
  Skeleton,
} from '@revfluence/fresh';

import { ModalType } from '@frontend/app/containers/Projects/ProjectsPage/AddMembersToProgramModal/AddMembersToCollectionModal';

import styles from './Stages.module.scss';

import { ProjectsRouteRoot } from '../../../../constants';
import { TWorklet } from '../../../../types';
import { EmptyNoCreators } from '../../../Empty';

const {
  useState,
  useEffect,
  useMemo,
  useCallback,
} = React;

const { Text } = Typography;

type IStageItem = {
  workletSpecUri?: string;
  taskId: string;
  name: string;
  value: number;
  color?: string;
  icon: React.ReactElement;
  onTaskSelected?: THandleTaskSelected;
  customLink?: string;
};

const Icon = ({ icon, color }) => (
  <div className={styles.icon} style={{ background: color }}>
    {icon}
  </div>
);

const StageItem: React.FC<IStageItem> = ({
  name, icon, value, color, workletSpecUri, taskId, onTaskSelected, customLink,
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (customLink) {
      history.push({
        ...location,
        pathname: customLink,
      });
    } else {
      onTaskSelected(workletSpecUri, taskId);
    }
  };

  return (
    <List.Item key={name} onClick={handleClick}>
      <Row justify="space-between" align="middle" gutter={16} wrap={false}>
        <Col>
          <Icon icon={icon} color={color} />
        </Col>
        <Col flex="auto">
          <Text
            className={styles.stageName}
            ellipsis={{
              tooltip: name,
            }}
          >
            {name}
          </Text>
        </Col>
        <Col className={styles.actions}>
          <Text className={styles.stageCount}>
            {value}
          </Text>
          <AngleRightIcon />
        </Col>
      </Row>
    </List.Item>
  );
};

interface IProps {
  countsNeedAttention: ICounts;
  tasks: TTask[];
  onTaskSelected: THandleTaskSelected;
  setActualNeedsAttentionCount: (number) => void;
  openAddToCollectionModal?(modalType: ModalType): void;
  applicantsCount: number;
  projectId: number;
  worklets: TWorklet[];
}

const Stages: React.FC<IProps> = React.memo((props) => {
  const {
    tasks,
    countsNeedAttention,
    onTaskSelected,
    setActualNeedsAttentionCount,
    openAddToCollectionModal,
    applicantsCount,
    projectId,
    worklets,
  } = props;
  const getTask = useCallback((task: TTask, value: number) => {
    const taskProps = {
      workletSpecUri: task.workletSpecUri,
      taskId: task.taskId,
      name: task
        && worklets?.find(
          (worklet) => worklet.specURI === task.workletSpecUri,
        )?.specTitle,
      value,
    };
    switch (taskProps.name) {
      case 'Send Intro Message':
      case 'Send Welcome Email':
        return {
          ...taskProps,
          color: '#006462',
          icon: <EnvelopeIcon />,
        };
      case 'Terms':
        return {
          ...taskProps,
          color: '#923562',
          icon: <FileCheckIcon />,
        };
      case 'Contract':
        return {
          ...taskProps,
          color: '#91D5FF',
          icon: <FilePdfIcon />,
        };
      case 'Member Information':
        return {
          ...taskProps,
          color: '#01213A',
          icon: <AddressCardIcon />,
        };
      case 'Sales Links':
        return {
          ...taskProps,
          color: '#F58984',
          icon: <LinkIcon />,
        };
      case 'Generate Promo Codes':
        return {
          ...taskProps,
          color: '#FFCD5B',
          icon: <CertificateIcon />,
        };
      case 'Sales links + promo codes':
        return {
          ...taskProps,
          color: '#D48806',
          icon: <TagsIcon />,
        };
      case 'Product Fulfillment':
        return {
          ...taskProps,
          color: '#09375A',
          icon: <BoxCheckIcon />,
        };
      case 'Content':
        return {
          ...taskProps,
          color: '#5A1937',
          icon: <ImageIcon />,
        };
      case 'Track Posts':
        return {
          ...taskProps,
          color: '#FCBBAE',
          icon: <CalendarImageIcon />,
        };
      case 'Payment':
        return {
          ...taskProps,
          color: '#113C0B',
          icon: <DollarSignIcon />,
        };
      default:
        // TODO: Throw an error once all types have been handle
        // and do not match any of them
        return {
          ...taskProps,
          color: '#09375A',
          icon: <WrenchIcon />,
        };
    }
  }, [worklets]);

  const [isLoading, setIsLoading] = useState<boolean>(!!countsNeedAttention);
  const [activeStageCount, setActiveStageCount] = useState<number>(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customTasks = {
    applicants: {
      icon: <FolderUserIcon />,
      color: '#389E0D',
      value: applicantsCount,
      name: 'Applicants',
      taskId: 'applicants',
      customLink: `${ProjectsRouteRoot}/${projectId}/applicants`,
      workletSpecUri: undefined,
    },
  };

  const getDataSource = useCallback(
    (taskGroup: TTask[]) =>
      map(taskGroup, (task: TTask) => {
        const taskCount = countsNeedAttention?.[task.workletSpecUri]?.[task.taskId] || 0;
        return getTask(task, taskCount);
      })
      .filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getTask,
      countsNeedAttention,
      worklets,
    ],
  );

  const combineTasksByStage = useCallback((taskArray: IStageItem[]) => {
    if (size(taskArray) === 1) {
      return taskArray;
    }
    const firstTask = taskArray[0];
    const computedTotal = reduce(taskArray, (sum, task) => (sum + task.value), 0);
    firstTask.value = computedTotal;
    return firstTask;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countsNeedAttention]);

  const result = useMemo(() => (chain(tasks)
    .groupBy((taskArg: TTask) => taskArg.workletSpecKey)
    .toPairs()
    .map(([_name, taskGroup]) => getDataSource(taskGroup))
    .map((taskArray) => combineTasksByStage(taskArray))
    .value()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [countsNeedAttention]);

  const filteredStages = useMemo(() => {
    const fetchedStages = [
      ...filter(
        flatten(result),
        (item) => item.value !== 0,
      ),
    ];
    if (customTasks.applicants.value !== 0) {
      fetchedStages.unshift(customTasks.applicants);
    }
    return fetchedStages;
  }, [
    result,
    customTasks,
  ]);

  const renderStages = () => {
    if (activeStageCount > 0) {
      return (
        <>
          <Text
            weight="semibold"
            className={styles.headline}
          >
            {`Creators in ${activeStageCount} of ${size(tasks)} project stages need attention.`}
          </Text>
          <List
            dataSource={filteredStages}
            renderItem={
              (item) => (
                <StageItem
                  {...item}
                  onTaskSelected={onTaskSelected}
                />
              )
            }
            className={styles.List}
          />
        </>
        );
    }
    return (
      <EmptyNoCreators
        openAddToCollectionModal={openAddToCollectionModal}
        projectId={projectId}
      />
    );
  };

  useEffect(() => {
    const tempFilteredStages = filteredStages.filter(
      (stage) => (
        stage.taskId !== 'completed' && stage.value !== 0
      ),
    );
    const totalNeedsAttention = sumBy(tempFilteredStages, (stage) => stage.value);
    setActualNeedsAttentionCount(totalNeedsAttention);
    setActiveStageCount(size(tempFilteredStages));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredStages]);

  useEffect(() => {
    setIsLoading(!countsNeedAttention && !!worklets);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countsNeedAttention]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Skeleton
          active
          loading
          paragraph
        />
      ) : renderStages()}
    </div>
  );
});

export default Stages;
Stages.displayName = 'Stages';
