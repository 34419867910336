import React, {
 createContext, useContext, useMemo, useReducer,
} from 'react';
import { InventorySource } from '@frontend/applications/ProductFulfillmentApp/types/globalTypes';

export interface SelectedLocation {
  id: string;
  name: string;
}
export interface ImportSettingsContextProps {
  autoSyncFromShopify?: boolean;
  inventorySource: InventorySource;
  selectedLocations: SelectedLocation[];
  stockAvailabilityCheck: boolean;
  maintainMinimumInventory: boolean;
  minimumStockLevel: number;
  setAutoSyncFromShopify?: (autoSyncFromShopify: boolean) => void;
  setSelectedLocations: (locations: SelectedLocation[]) => void;
  setInventorySource: (inventorySource: InventorySource) => void;
  setStockAvailabilityCheck: (stockAvailabilityCheck: boolean) => void;
  setState: (state: ImportSettingsContextState) => void;
  setMaintainMinimumInventory: (maintainMinimumInventory: boolean) => void;
  setMinimumStockLevel: (minimumStockLevel: number) => void;
}

export type ImportSettingsContextState = Omit<
  ImportSettingsContextProps,
  | 'setSelectedLocations'
  | 'setInventorySource'
  | 'setStockAvailabilityCheck'
  | 'setState'
  | 'setMaintainMinimumInventory'
  | 'setMinimumStockLevel'
  | 'setAutoSyncFromShopify'
>;

const initialState: ImportSettingsContextState = {
  inventorySource: InventorySource.ONLINE_SELLABLE_INVENTORY,
  selectedLocations: [],
  stockAvailabilityCheck: false,
  maintainMinimumInventory: false,
  minimumStockLevel: 0,
  autoSyncFromShopify: false,
};

export const ImportSettingsContext = createContext<ImportSettingsContextProps>({
  ...initialState,
  setSelectedLocations: () => {},
  setInventorySource: () => {},
  setStockAvailabilityCheck: () => {},
  setState: () => {},
  setMaintainMinimumInventory: () => {},
  setMinimumStockLevel: () => {},
  setAutoSyncFromShopify: () => {},
});

export enum ActionType {
  SetInventorySource = 'SetInventorySource',
  SetSelectedLocations = 'SetSelectedLocations',
  SetStockAvailabilityCheck = 'SetStockAvailabilityCheck',
  SetState = 'SetState',
  SetMaintainMinimumInventory = 'SetMaintainMinimumInventory',
  SetMinimumStockLevel = 'SetMinimumStockLevel',
  SetAutoSyncFromShopify = 'SetAutoSyncFromShopify',
}

export type Action =
  | { type: ActionType.SetInventorySource; payload: InventorySource }
  | { type: ActionType.SetSelectedLocations; payload: SelectedLocation[] }
  | { type: ActionType.SetState; payload: Partial<ImportSettingsContextState> }
  | { type: ActionType.SetStockAvailabilityCheck; payload: boolean }
  | { type: ActionType.SetMaintainMinimumInventory; payload: boolean }
  | { type: ActionType.SetMinimumStockLevel; payload: number }
  | { type: ActionType.SetAutoSyncFromShopify; payload: boolean };

export const ImportSettingsProvider: React.FC = ({ children }) => {
  const reducer = (state: ImportSettingsContextState, action: Action): ImportSettingsContextState => {
    switch (action.type) {
      case ActionType.SetInventorySource:
        return { ...state, inventorySource: action.payload };
      case ActionType.SetSelectedLocations:
        return { ...state, selectedLocations: action.payload };
      case ActionType.SetState:
        return { ...state, ...action.payload };
      case ActionType.SetStockAvailabilityCheck:
        return { ...state, stockAvailabilityCheck: action.payload };
      case ActionType.SetMaintainMinimumInventory:
        return { ...state, maintainMinimumInventory: action.payload };
      case ActionType.SetMinimumStockLevel:
        return { ...state, minimumStockLevel: action.payload };
      case ActionType.SetAutoSyncFromShopify:
        return { ...state, autoSyncFromShopify: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const actions = useMemo(
    () => ({
      setSelectedLocations: (locations: SelectedLocation[]) => dispatch({ type: ActionType.SetSelectedLocations, payload: locations }),
      setInventorySource: (inventorySource: InventorySource) => dispatch({ type: ActionType.SetInventorySource, payload: inventorySource }),
      setState: (newState: Partial<ImportSettingsContextState>) => dispatch({ type: ActionType.SetState, payload: newState }),
      setStockAvailabilityCheck: (stockAvailabilityCheck: boolean) =>
        dispatch({ type: ActionType.SetStockAvailabilityCheck, payload: stockAvailabilityCheck }),
      setMaintainMinimumInventory: (maintainMinimumInventory: boolean) =>
        dispatch({ type: ActionType.SetMaintainMinimumInventory, payload: maintainMinimumInventory }),
      setMinimumStockLevel: (minimumStockLevel: number) => dispatch({ type: ActionType.SetMinimumStockLevel, payload: minimumStockLevel }),
      setAutoSyncFromShopify: (autoSyncFromShopify: boolean) =>
        dispatch({ type: ActionType.SetAutoSyncFromShopify, payload: autoSyncFromShopify }),
    }),
    [],
  );

  const memoizedValue = useMemo<ImportSettingsContextProps>(
    () => ({
      ...state,
      ...actions,
    }),
    [actions, state],
  );

  return <ImportSettingsContext.Provider value={memoizedValue}>{children}</ImportSettingsContext.Provider>;
};

export const useImportSettingsContext = () => useContext(ImportSettingsContext);
