import gql from 'graphql-tag';
import { PRODUCT_VARIANT_FRAGMENT } from './fragments/productVariant';
import { PRODUCT_V2_FRAGMENT } from './fragments/productV2';

export const GET_PRODUCT_DETAILS = gql`
  query GetProductDetails($productId: Float!) {
    getProductVariantsByProductId(productId: $productId) {
      ...ProductV2Fragment
      productVariants {
        ...ProductVariantFragment
      }
    }
  }
  ${PRODUCT_V2_FRAGMENT}
  ${PRODUCT_VARIANT_FRAGMENT}
`;

export const SYNC_PRODUCT_INVENTORY_BY_PRODUCT_ID = gql`
  mutation SyncProductInventoryByProductId($productId: Int!) {
    syncProductInventoryByProductId(id: $productId)
  }
`;
