import * as React from 'react';
import * as qs from 'querystring';

import { useApplication } from '@frontend/applications/Shared/context/applicationContext';

import { IPaymentSources } from './models';

const {
 useState, useEffect, useMemo, useCallback,
} = React;

interface IPaymentSourceFetchParams {
  client_id: string;
}

const fetchPaymentSourceData = async (url: string) => {
  try {
    const resp = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
    });

    const json = await resp.json();
    return json.data;
  } catch (err) {
    console.log(err);

    throw err;
  }
};

export function useFetchPaymentSourceData() {
  const { backendServerApiEndpoint, clientId } = useApplication();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPaymentSources>(null);
  const [error, setError] = useState<Error>(null);

  const finalUrl = useMemo(() => {
    const url = `${backendServerApiEndpoint}/payment_source`;

    const params: IPaymentSourceFetchParams = {
      client_id: clientId,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filterString = qs.stringify(params as any);

    return `${url}?${filterString}`;
  }, [backendServerApiEndpoint, clientId]);

  const fetchPaymentSources = useCallback(async () => {
    setLoading(true);

    let result;

    try {
      result = await fetchPaymentSourceData(finalUrl);
      setData(result);
    } catch (err) {
      setError(err);
    }

    setLoading(false);

    return result;
  }, [finalUrl]);

  useEffect(() => {
    fetchPaymentSources();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalUrl]);

  return {
    loading,
    data,
    error,
    refetch: fetchPaymentSources,
  };
}
