import * as React from 'react';
import { Tooltip } from '@revfluence/fresh';

import { InfoIcon } from '@components';

import styles from './AccessLimitHeader.scss';

export const AccessLimitHeader: React.FC = () => (
  <h2 className={styles.AccessLimitHeader}>
    Current permissions this months: unlimited
    <Tooltip
      placement="right"
      title="You can request unlimited ad permissions from creators"
    >
      <InfoIcon className={styles.infoIcon} />
    </Tooltip>
  </h2>
);
