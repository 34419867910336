import { LazyImage } from '@components';
import { Button } from '@revfluence/fresh';
import { LoadSpinner } from '@components';
import { isFunction, map } from 'lodash';
import * as React from 'react';
import omitDeep from 'omit-deep-lodash';
import cx from 'classnames';
import {
  Link,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useGetAllProjectsQuery } from '@frontend/app/hooks';

import { ProjectByIdQuery_project } from '@frontend/app/queries/types/ProjectByIdQuery';

import styles from './ProjectApplicationPagePicker.scss';
import { isApplicationPageEditable, onOpenTemplate } from '../utils';
import { useGetProjectLandingPageUrl } from '../../Onboarding/OnboardingWizard/hooks/useGetProjectLandingPageUrl';

import { ProjectsRouteRoot } from '../constants';
import { ApplicationPageTemplatesData, ProjectApplicationPageTemplateName } from '../applicationPageUtils';
import { useSaveProgramMutation } from '../../Communities/AddOrEditCommunity/hooks/useSaveProgramMutation';

const { useCallback, useMemo } = React;

const previewBtnSvg = require('@frontend/app/assets/svgs/preview_btn.svg');
const duplicateBtnSvg = require('@frontend/app/assets/svgs/duplicate_btn.svg');

const PreviewButton: React.FC<{ customLandingPagePath: string }> = ({ customLandingPagePath }) => {
  const { getFullPageUrl } = useGetProjectLandingPageUrl();

  const url = useMemo(() => (
    isFunction(getFullPageUrl) && getFullPageUrl(customLandingPagePath, '')
  ), [getFullPageUrl, customLandingPagePath]);

  const openTemplate = () => {
    onOpenTemplate(url);
  };

  return (
    <Button type="default" onClick={openTemplate}>
      <img src={previewBtnSvg} />
    </Button>
  );
};

interface IProjectApplicationPagePicker {
  project: ProjectByIdQuery_project
}

const ProjectApplicationPagePicker: React.FC<IProjectApplicationPagePicker> = React.memo((props) => {
  const {
    project,
  } = props;

  const history = useHistory();

  const [
    saveProject,
    {
      loading: isSavingProject,
    },
  ] = useSaveProgramMutation(undefined);

  const {
    data: {
      projects = [],
    } = {},
    loading: isLoadingProjects,
  } = useGetAllProjectsQuery();

  const onTemplatePick = useCallback(async (templateName: ProjectApplicationPageTemplateName) => {
    await saveProject({
      variables: {
        program: {
          id: project.id,
          applicationPageTemplateName: templateName,
          onboardingTemplateConfig: {
            ...{
              intro: {
                hero_image: project.splashImageUrl,
                title: project.title,
              },
            },
            ...omitDeep(project.onboardingTemplateConfig, '__typename'),
          },
        },
      },
    });

    history.push(`${ProjectsRouteRoot}/${project?.id}/settings/landing_page`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, history]);

  if (isApplicationPageEditable(project)) {
    return <Redirect to={`${ProjectsRouteRoot}/${project?.id}/settings/landing_page`} />;
  }

  if (isLoadingProjects) return <LoadSpinner />;

  return (
    <div
      className={cx(
        styles.ProjectApplicationPagePicker,
        {
          [styles.alignedLeftContent]: projects.length > 0,
        },
      )}
    >
      <div className={styles.content}>
        <div className={styles.container}>

          <div className={styles.section}>
            <div className={styles.title}>
              Select a Template
            </div>
            <div className={styles.listGridView}>
              {
                map(ApplicationPageTemplatesData, (template) => (
                  <div key={template.templateName} className={styles.listItem}>
                    <div className={styles.ctaBtns}>
                      <Button
                        type="primary"
                        loading={isSavingProject}
                        onClick={() => onTemplatePick(template.templateName)}
                      >
                        Create
                      </Button>
                    </div>
                    <LazyImage
                      className={styles.image}
                      src={template.placeholderSrc}
                    />
                    <div className={styles.listItemTitle}>
                      {template.name}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>

          {
            projects.length > 0
              && (
                <div className={styles.section}>
                  <div className={styles.title}>
                    Duplicate an existing application page from a project
                  </div>
                  <div className={styles.listGridView}>
                    {
                      map(projects, (availableProject) => (
                        <div key={availableProject.id} className={styles.listItem}>
                          <div className={styles.ctaBtns}>
                            <PreviewButton customLandingPagePath={availableProject.customLandingPagePath} />
                            <Link to={{
                                pathname: `${ProjectsRouteRoot}/${project?.id}/settings/landing_page`,
                                state: {
                                  applicationPageTemplateName: availableProject.applicationPageTemplateName,
                                  onboardingTemplateConfig: availableProject.onboardingTemplateConfig,
                                  applicationFormFields: availableProject.applicationFormFields,
                                },
                              }}
                            >
                              <Button type="primary">
                                <img src={duplicateBtnSvg} />
                              </Button>
                            </Link>
                          </div>
                          <LazyImage
                            className={styles.image}
                            src={availableProject.splashImageUrl}
                          />
                          <div className={styles.listItemTitle}>
                            {availableProject.title}
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
          }
        </div>
      </div>
    </div>
  );
});

ProjectApplicationPagePicker.displayName = 'ProjectApplicationPagePicker';

export { ProjectApplicationPagePicker };
