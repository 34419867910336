import React from 'react';
import { Button, ITableProps } from '@revfluence/fresh';
import { ChevronLeftIcon, ChevronRightIcon } from '@revfluence/fresh-icons/regular/esm';
import styles from './styles.scss';

export const getPaginationProps = <T extends {}>({
  total,
  pageSize,
  current,
  className = styles.PfaV2Pagination,
  onNextPage = () => {},
  onPreviousPage = () => {},
  disabled = false,
}: {
  total: number;
  pageSize: number;
  current?: number;
  className?: string;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  disabled?: boolean;
}): ITableProps<T>['pagination'] => ({
  total,
  pageSize,
  showSizeChanger: false,
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
  itemRender: (_current, type, originalElement) => {
    if (type === 'prev') {
      return <Button icon={<ChevronLeftIcon />} type="text" disabled={disabled} />;
    }
    if (type === 'next') {
      return <Button icon={<ChevronRightIcon />} type="text" disabled={disabled} />;
    }
    return originalElement;
  },
  showLessItems: true,
  position: ['topRight'],
  className,
  current,
  onChange: (page) => {
    if (page > current) {
      onNextPage?.();
    } else {
      onPreviousPage?.();
    }
  },
  disabled,
});

export const getCleanedTag = (tag: string) => tag.replace('ASPIRE_', '');

export const getCleanedTags = (tags: string[]) => tags.map((tag) => tag.replace('ASPIRE_', ''));

export const getAspireTags = (tags: string[]) => getCleanedTags(tags.filter((tag) => tag.startsWith('ASPIRE_')));

export const getNonAspireTags = (tags: string[]) => tags.filter((tag) => !tag.startsWith('ASPIRE_'));

export const toAspireTags = (tags: string[]) => tags.map((tag) => `ASPIRE_${tag}`);

export const simpleHash = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash;
};
