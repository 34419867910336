import * as React from 'react';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';
import ContractsListPage from './ContractsListPage';
import ContractSignersPage from './ContractSignersPage';

const ContractAppDashboard: React.FunctionComponent = () => {
  const navSettings = [
    {
      route: 'reports',
      displayName: 'My Contracts',
      component: ContractsListPage,
    },
    {
      route: 'settings',
      displayName: 'Settings',
      component: ContractSignersPage,
    },
  ];

  const application = {
    displayName: 'Contracts',
    iconUrl: 'https://storage.googleapis.com/aspirex-static-files/contracts.svg',
  };

  return (
    <AppDashboardNav
      application={application}
      navLinks={navSettings}
      hideTabs
      defaultRoute="reports"
    />
  );
};

export default ContractAppDashboard;
