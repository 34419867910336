import { map, filter } from 'lodash';

import {
  useMemberFieldSchemasQuery,
} from '@frontend/app/hooks';
import { IMemberSearchQuery } from '@frontend/app/types/MemberSearch';

interface IMemberSearchOptions {
  memberColumns?: string[];
  memberFields?: string[];
}

const defaultSearchOptions = {
  memberColumns: ['name', 'email'],
  memberFields: ['Instagram'],
};

export function useSearchTextQuery(options?: IMemberSearchOptions) {
  const memberColumns = options?.memberColumns || defaultSearchOptions.memberColumns;
  const memberFields = options?.memberFields || defaultSearchOptions.memberFields;

  const {
    data: {
      schemas = null,
    } = {},
  } = useMemberFieldSchemasQuery();

  const getSearchTextQuery = (value) => {
    const columnSearch = map(
      memberColumns,
      (column) => ({ contains: value, column }),
    );

    const memberFieldSet = new Set(memberFields);
    const filteredSchemas = filter(
      schemas,
      (schema) => memberFieldSet.has(schema.name),
    );
    const fieldSearch = map(filteredSchemas, (schema) => ({
      contains: value,
      memberFieldSchemaId: schema.id,
    }));

    const query: IMemberSearchQuery = {
      fields: {
        or: [
          ...columnSearch,
          ...fieldSearch,
        ],
      },
    };

    return query;
  };

  return { getSearchTextQuery, schemas };
}
