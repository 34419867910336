import { MutationHookOptions, MutationTuple } from '@apollo/client';

import { useMemberMutation } from '@frontend/app/hooks';
import {
  ADD_MEMBERS_TO_PROGRAMS_MUTATION,
  REMOVE_MEMBERS_FROM_PROGRAMS_MUTATION,
} from '@frontend/app/queries';
import {
  AddMembersToProgramsMutation,
  AddMembersToProgramsMutationVariables,
} from '@frontend/app/queries/types/AddMembersToProgramsMutation';
import {
  RemoveMembersFromProgramsMutation,
  RemoveMembersFromProgramsMutationVariables,
} from '@frontend/app/queries/types/RemoveMembersFromProgramsMutation';

type TAddType = 'add';
type TRemoveType = 'remove';
type TModifyType = TAddType | TRemoveType;
type IMutation<T extends TModifyType> = T extends TAddType ? AddMembersToProgramsMutation : RemoveMembersFromProgramsMutation;
type IMutationVariables<T extends TModifyType> = T extends TAddType ? AddMembersToProgramsMutationVariables : RemoveMembersFromProgramsMutationVariables;
type IOptions<T extends TModifyType> = MutationHookOptions<IMutation<T>, IMutationVariables<T>>;
type ITuple<T extends TModifyType> = MutationTuple<IMutation<T>, IMutationVariables<T>>;

export function useModifyProgramMembersMutation(type: TAddType, options?: IOptions<TAddType>): ITuple<TAddType>;
export function useModifyProgramMembersMutation(type: TRemoveType, options?: IOptions<TRemoveType>): ITuple<TRemoveType>;

export function useModifyProgramMembersMutation(type: TModifyType, options: IOptions<TAddType> | IOptions<TRemoveType> = {}) {
  if (type === 'add') {
    return useMemberMutation<IMutation<TAddType>, IMutationVariables<TAddType>>(
      ADD_MEMBERS_TO_PROGRAMS_MUTATION,
      options,
    );
  } else {
    return useMemberMutation<IMutation<TRemoveType>, IMutationVariables<TRemoveType>>(
      REMOVE_MEMBERS_FROM_PROGRAMS_MUTATION,
      options,
    );
  }
}
