import { filter, isEmpty, map } from 'lodash';

import { IContentReview, TMediaType } from '@components';

interface IContent {
  type: TMediaType;
  url: string;
}

export function getContentReviewMedia(review: IContentReview): IContent[] {
  const { content, post } = review;
  const mediaList = !isEmpty(content?.saved_media_list)
    ? content?.saved_media_list
    : (!isEmpty(content?.media) ? content?.media : post?.media);

  const contents = map(filter(mediaList, (media) => media.category === 'downloadable_media'), (m) => ({
    type: m.media_type,
    url: m.url,
  }));

  if (isEmpty(contents) && !!content?.image_link) {
    contents.push({
      type: 'image',
      url: content.image_link,
    });
  }

  return contents;
}
