import * as React from 'react';
import Helmet from 'react-helmet';

import { AppDashboardNav } from '@frontend/applications/Shared/components/AppDashboardNav';
import { AdvertiserAccessPage } from '../../containers/AdvertiserAccessPage';

export const InfluencerWhitelistAppDashboard: React.FunctionComponent = () => {
  const displayName = 'Ads Permissions';
  const navSettings = [
    {
      route: '',
      displayName,
      component: AdvertiserAccessPage,
    },
  ];

  const application = {
    displayName,
    iconUrl: 'https://storage.googleapis.com/aspirex-static-files/influencer_whitelist_icon.svg',
  };

  return (
    <>
      <Helmet title={displayName} />
      <AppDashboardNav
        application={application}
        navLinks={navSettings}
        hideTabs
        defaultRoute=""
      />
    </>
  );
};
