import * as React from 'react';

import {
  compact,
  isEmpty,
  map,
} from 'lodash';

import { AngleDownIcon } from '@revfluence/fresh-icons/regular/esm';
import {
  Menu,
  Dropdown,
  Space,
  Button,
} from '@revfluence/fresh';

import { useHistory } from 'react-router-dom';

import {
  TERMS_APP_ID,
  SOCIAL_DISCOVERY_APP_ID,
} from '@frontend/app/constants/applicationIds';

import { ProjectsRouteRoot } from '@frontend/app/containers/Projects/constants';

import { useGetProgramById } from '@frontend/app/hooks';
import { useOverviewPage } from '@frontend/app/containers/Projects/hooks';
import { formatApplicationPageURL } from '@frontend/app/containers/Projects/utils';
import { TProject } from '@frontend/app/containers/Projects/types';

import styles from './QuickLinks.module.scss';

const {
  useMemo,
  useCallback,
} = React;

const QuickLinks: React.FC<{ projectId: number }> = React.memo(
  ({ projectId }) => {
    const { handleCheckScreenSize } = useOverviewPage();

    const history = useHistory();

    const { isMobile } = handleCheckScreenSize();

    const {
      data: {
        program = null,
      } = {},
    } = useGetProgramById({
      variables: {
        id: projectId,
      },
      skip: !projectId,
    });

    const linkItems = useMemo(() => compact([
      {
        href: `${ProjectsRouteRoot}/${projectId}/settings/details`,
        linkText: 'Project Settings',
        search: '',
      },
      program?.gcrEnabled && {
        href: `${ProjectsRouteRoot}/${projectId}/gcr`,
        linkText: 'Group Content Review',
        search: '',
      },
      {
        href: formatApplicationPageURL(ProjectsRouteRoot, program as TProject),
        linkText: 'Application Page',
        search: '',
      },
      {
        href: `/app/${SOCIAL_DISCOVERY_APP_ID}`,
        linkText: 'Find Creators',
        search: `?projectId=${projectId}`,
      },
      {
        href: `${ProjectsRouteRoot}/${projectId}/settings/invite_email`,
        linkText: 'Project Invite Template',
        search: '',
      },
      {
        href: `${ProjectsRouteRoot}/${projectId}/settings/automation`,
        linkText: 'Automations',
        search: '',
      },
      {},
      {
        href: '/settings/messageTemplates',
        linkText: 'All Message Templates',
        search: '',
      },
      {
        href: `/settings/${TERMS_APP_ID}`,
        linkText: 'All Content Guidelines',
        search: `?projectId=${projectId}`,
      },
    ]), [projectId, program]);

    const handleNavigateToLink = useCallback((targetLink: string, search: string) => {
      history.push({
        pathname: targetLink,
        search,
        state: {
          prevLocation: history.location.pathname,
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [linkItems]);

    const menu = useMemo(() => (
      <Menu>
        {
          map(
            linkItems,
            (item) => (
              !isEmpty(item) ? (
                <Menu.Item key={item.linkText}>
                  <Button
                    type="link"
                    className={styles.linkText}
                    onClick={() => handleNavigateToLink(`${item.href}`, `${item.search}`)}
                    block
                  >
                    {item.linkText}
                  </Button>
                </Menu.Item>
              ) : <Menu.Divider />
            ),
          )
        }
      </Menu>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [projectId, linkItems]);

    return (
      <Dropdown
        overlay={menu}
        placement="bottomRight"
      >
        <Button>
          <Space>
            { !isMobile && <>Quick Links</>}
            <AngleDownIcon />
          </Space>
        </Button>
      </Dropdown>
    );
  },
);

export default QuickLinks;
