import * as React from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';

import { Route, useRouteMatch } from 'react-router-dom';

import { EventName } from '@common';
import { ErrorBoundary } from '@frontend/utils';
import { CommunitySwitcherProvider, useClientFeatureEnabled } from '@frontend/app/hooks';

import { useEventContext } from '@frontend/app/context/EventContext';
import { Drawer } from '@frontend/app/components';
import { ClientFeature } from '@frontend/app/constants';
import { MessagingProvider } from './context/MessagingAppContext';
import { ThreadList } from './ThreadList';
import { MessageList } from './MessageList';
import { ThreadFolderSection } from './ThreadFolderSection/ThreadFolderSection';
import { EmptyState } from './EmptyState/EmptyState';

const { useState, useEffect, useCallback } = React;

import styles from './MessagingApp.scss';
import { LegacyThreadFolderSection } from './ThreadFolderSection';
import { SearchFilter } from './SearchFilter';

interface IProps {
  className?: string;
}

/**
 * @type {React.FunctionComponent}
 */
const MessagingApp: React.FunctionComponent<IProps> = React.memo((props) => {
  const match = useRouteMatch();
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  // TODO: DT-4844 Remove me once Inbox Revamp for folders is GA
  const isInboxFoldersV2 = useClientFeatureEnabled(ClientFeature.INBOX_FOLDERS_V2);
  const isShowSearchFilter = useClientFeatureEnabled(ClientFeature.INBOX_SEARCH_FILTER);

  const addEvent = useEventContext();

  const handleWindowErrors = useCallback((e) => {
    if (e.message.includes('ResizeObserver loop')) {
      // prevent ResizeObserver loop error from crashing inbox
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('error', handleWindowErrors);
    return () => {
      window.removeEventListener('error', handleWindowErrors);
    };
  }, [handleWindowErrors]);

  useEffect(() => {
    addEvent(EventName.OpenApp, { source: 'nav', app: 'messages' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx(
      styles.MessagingApp,
      props.className,
      {
        // TODO: DT-4844 Remove me
        [styles.legacy]: !isInboxFoldersV2,
      },
    )}
    >
      <Helmet title="Inbox" />
      <CommunitySwitcherProvider useQueryParams={false}>
        <MessagingProvider>
          {
            isInboxFoldersV2
              ? (
                <>
                  <ThreadFolderSection
                    isExpanded={showSidebar}
                    onExpandToggle={() => { setShowSidebar((x) => !x); }}
                  />
                  <div className={styles.rightPanel}>
                    {isShowSearchFilter && <SearchFilter />}
                    <div className={styles.content}>
                      <ErrorBoundary>
                        <ThreadList className={styles.threadList} />
                      </ErrorBoundary>
                      <Route
                        path={match.url}
                        exact
                        render={() => <EmptyState className={styles.messageList} />}
                      />
                      <Route
                        path={`${match.url}/:threadId`}
                        render={(props) => (
                          <ErrorBoundary>
                            <MessageList {...props} className={styles.messageList} />
                          </ErrorBoundary>
                      )}
                      />
                    </div>
                  </div>
                </>
              )
              : (
                <>
                  <LegacyThreadFolderSection
                    visible={showSidebar}
                    onClick={() => {
                      if (!showSidebar) {
                        setShowSidebar(true);
                      }
                    }}
                  />
                  <Drawer
                    className={styles.rightPanel}
                    expanded={showSidebar}
                    onToggle={setShowSidebar}
                    openWidth={240}
                  >
                    <ErrorBoundary>
                      <ThreadList className={styles.threadList} />
                    </ErrorBoundary>
                    <Route
                      path={match.url}
                      exact
                      render={() => <EmptyState className={styles.messageList} />}
                    />
                    <Route
                      path={`${match.url}/:threadId`}
                      render={(props) => (
                        <ErrorBoundary>
                          <MessageList {...props} className={styles.messageList} />
                        </ErrorBoundary>
                    )}
                    />
                  </Drawer>
                </>
              )
          }

        </MessagingProvider>
      </CommunitySwitcherProvider>
    </div>
  );
});

MessagingApp.displayName = 'MessagingApp';

export default MessagingApp;
