import * as React from 'react';
import {
 size, map, includes, lowerCase,
} from 'lodash';

import { Button, Notice } from '@components';
import { IEmailEditorState } from '@frontend/app/components';
import { ConfirmationPage } from './ConfirmationPage';
import MessageComposer from '../components/MessageComposer';

import { IProduct, IOrder } from '../hooks';

export interface IBulkCreateOrderResult {
  orders: Array<IOrder>;
  errors: Array<{
    params: {
      member: {
        id: number;
        email: string;
        name: string;
      };
    };
    error: Error;
  }>;
}
interface IProps {
  editorState: IEmailEditorState;
  subject: string;
  closeModal(): void;
  selectedProducts: { product: IProduct, variant, quantity: number }[];
  skipMessage: boolean;
  result: IBulkCreateOrderResult;
  memberCount: number;
}

import styles from './SuccessPage.scss';

function createErrorMessage(
  member: {
    id: number;
    email: string;
    name: string;
  },
  error: Error,
): string {
  const { message } = error;

  // 429 is returned by shopify when reaching the api limit
  // https://shopify.dev/api/usage/rate-limits#rest-admin-api-rate-limits
  if (includes(message, '429') || includes(lowerCase(message), 'rate limit')) {
    return `We were unable to create an order for ${member.name} due to shop API limit, please try again later.`;
  }

  if (message.includes('required permissions to create orders')) {
    return 'You do not have the required permissions to create orders. Please re-authenticate with Shopify and try again.';
  }

  return `There was an unexpected error creating order for ${member.name}: ${message}.`;
}

export const SuccessPage: React.FunctionComponent<IProps> = (props) => {
  const {
    result, memberCount,
    editorState, subject, selectedProducts,
    closeModal, skipMessage,
  } = props;

  if (!result) {
    return null;
  }

  const { orders, errors } = result;
  const ordersCreated = size(orders) > 0;
  const hasErrors = size(errors) > 0;

  return (
    <div className={styles.SuccessPage}>
      <p className={styles.header}>
        {hasErrors && (
          <>
            {ordersCreated && `Caution! We created ${size(orders)} of ${memberCount} orders, but had errors with ${size(errors)} member(s)`}
            {!ordersCreated && `We were unable to create orders for ${memberCount} members`}
          </>
        )}
        {!hasErrors && 'Success! We are processing your orders'}
      </p>
      <p className={styles.subheader}>
        {hasErrors && (
          <>
            {ordersCreated && 'Please address the following errors, and refresh before trying again'}
            {!ordersCreated && 'Please address the following errors and try again'}
          </>
        )}
        {!hasErrors && 'It could take a few minutes before your products and messages are sent.'}
      </p>
      <div className={styles.summary}>
        <div className={styles.summarySection}>
          {size(errors) > 0 && (
            <Notice showDivider type="error" className={styles.errorNotice}>
              <div className={styles.content}>
                <div className={styles.noticeTitle}>
                  We were unable to create
                  {' '}
                  {size(errors)}
                  /
                  {memberCount}
                  {' '}
                  orders
                </div>
                <ul className={styles.noticeText}>
                  {map(errors, (e, index) => {
                    const { error, params } = e;

                    return (
                      <li key={index}>
                        {createErrorMessage(params.member, error)}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Notice>
          )}
          <ConfirmationPage
            embedded
            memberCount={memberCount}
            selectedProducts={selectedProducts}
          />
        </div>
        <div className={styles.summarySection}>
          {!skipMessage
            && (
            <>
              <p className={styles.stepTitle}>
                Sending
                {' '}
                {memberCount}
                {' '}
                {memberCount === 1 && 'email'}
                {memberCount > 1 && 'emails'}
              </p>
              {editorState && (
              <MessageComposer
                readOnly
                subject={subject}
                editorState={editorState}
                memberCount={memberCount}
              />
              )}
            </>
)}
          <Button label="Done" onClick={closeModal} />
        </div>
      </div>
    </div>
  );
};
